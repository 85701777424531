import React, { useState, useEffect, useRef } from "react";
import img1 from "../../../assets/images/features/feature-01.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import {
    convertTo12HourFormat,
    convertTo12HourFormatFromSlot,
    daysOfWeek,
    findObjectById,
    formatTimeDate,
    getAvailableSlots,
    getClinicProfilePicture,
    months,
    setTimeFromDate
} from "../../../assets/functions/functions";
import { useDispatch } from 'react-redux'
import { setDateRedux } from "../../../../hooks/appointmentSlice";
import { formatDateMMDDYYYY } from "../../doctors/dashboard/prototype-calendar/helper-functions";
import { chevron_down, filled_star, sample_doctor } from "../../home/image";
import { GetDoctor } from "../../../../api/patientAPIs";
import LoadingSpinner from "../bookingv2/spinner";

const Appointment = (props) => {
    const { appointmentModalFirstDate, closeAppointmentModal, appointmentModalData, appointmentSlotDictionary, reason, doctor, isNewPatient=false } = props
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const isPreview = searchParams.get("preview")
    const dispatch = useDispatch()
    const appointmentModalRef = useRef(null)
    const [isFirstClick, setFirstClick] = useState(true)

    //Date
    const today = appointmentModalFirstDate
    const [dateRange, setDateRange] = useState([])
    const [lastDate, setLastDate] = useState(new Date())
    const [isLoading, setIsLoading] = useState(false)

    //Doctor
    const doctorDropdownRef = useRef(null)
    const [isDoctorDropdownOpen, setDoctorDropdownOpen] = useState(false)
    const [doctorFilter, setDoctorFilter] = useState(null)
    const [doctorList, setDoctorList] = useState([])

    //Reason
    const [selectedReason, setSelectedReason] = useState(null);
    const [isReasonOpen, setReasonOpen] = useState(false);
    const reasonDropdownRef = useRef(null);

    
    
    useEffect(() => {
        console.log("Slot Dictionary: ", appointmentSlotDictionary)
        setIsLoading(true)
        var doctorPromises = [];
        
        appointmentModalData.doctors.forEach((doc) => {
            doctorPromises.push(GetDoctor(doc))
        })
        console.log(doctorPromises)
        Promise.all(doctorPromises).then((docResults) => {
            const tempDoctorList = [];
            docResults.forEach((docResults) => tempDoctorList.push(docResults.data));
            setDoctorList(tempDoctorList);
            setIsLoading(false)
        })

        doctor && doctor !== "Any Doctor" ? setDoctorFilter(doctor) : setDoctorFilter(null)
        reason ? setSelectedReason(reason) : setSelectedReason(null)

        const tempDateRange = Array.from({ length: 14 }, (_, index) => {
            const date = new Date(today.getTime());
            date.setDate(today.getDate() + index);
            return date
        });
        setDateRange(tempDateRange)
        setLastDate(tempDateRange[tempDateRange.length - 1]);

        const handleClickDoctorOutside = (event) => {
            if (doctorDropdownRef.current && !doctorDropdownRef.current.contains(event.target)) {
                setDoctorDropdownOpen(false);
            }
        };
        const handleClickReasonOutside = (event) => {
            if (reasonDropdownRef.current && !reasonDropdownRef.current.contains(event.target)) {
                setReasonOpen(false);
            }
        };

        function handleModalClickOutside(event) {
            if (appointmentModalRef.current && !appointmentModalRef.current.contains(event.target) && !isFirstClick) {
                setFirstClick(true)
                console.log("click")
              closeAppointmentModal()
            } else {
                setFirstClick(false)
            }
          }
        // Attach the event listener
        window.addEventListener('click', handleModalClickOutside);
        window.addEventListener('click', handleClickDoctorOutside);
        window.addEventListener('click', handleClickReasonOutside);


        return () => {
            window.removeEventListener("click", handleModalClickOutside);
            window.removeEventListener("click", handleClickDoctorOutside);
            window.removeEventListener("click", handleClickReasonOutside);
        };
    }, [])

    const slot = (slot, fullDate) => {
        const reason = selectedReason ? selectedReason : ""
        const newPatient = isNewPatient ? isNewPatient : false
        return (
            <button
                onClick={() => {
                    if (!isPreview) {
                        console.log(slot.time)
                        navigate(`/booking`, {state: {
                            did: appointmentModalData.external_id, 
                            t_id: slot.time, 
                            s_id:fullDate.getTime(), 
                            reason: reason, 
                            isNewPatient: newPatient, 
                            schedule_id: slot.schedule_internal_id, 
                            d_id:slot.doctor_id,
                            duration: slot.duration,
                        }});
                    }
                }}>
                {convertTo12HourFormatFromSlot(String(slot.time))}
            </button>
        )
    }

    function removeDuplicatesByTime(array) {
        const uniqueMap = new Map();
        for (const item of array) {
            if (!uniqueMap.has(item.time)) {
            uniqueMap.set(item.time, item);
            }
        }
        return Array.from(uniqueMap.values());
    }

    const day = (d) => {
        const openSlots = appointmentSlotDictionary[formatDateMMDDYYYY(d)] ? appointmentSlotDictionary[formatDateMMDDYYYY(d)].available_slots : []
        const openSlotsWithDoctorFiltered = openSlots.filter(slot => !doctorFilter || (doctorFilter && slot.doctor_id === doctorFilter.external_id))
        const openSlotsWithPatientStatus = openSlotsWithDoctorFiltered.filter(slot => {
            
            const doctorForSlot = findObjectById(slot.doctor_id, doctorList)
            if(isNewPatient && doctorList.length > 0 && !doctorForSlot.allow_new_patient){
                return false
            }
            return true
        })
        const openSlotsWithPatientStatusAndNoDuplicates = removeDuplicatesByTime(openSlotsWithPatientStatus)
        const numSlotsOfDoctor = doctorFilter ? openSlotsWithPatientStatusAndNoDuplicates.filter(slot => slot.doctor_id === doctorFilter.external_id || doctorFilter === "Any Doctor").length : openSlotsWithPatientStatusAndNoDuplicates.length
        const sortedOpenSlots = openSlotsWithPatientStatusAndNoDuplicates.sort((a, b) => a.time - b.time);
        return (
            <div>
                <h5>{daysOfWeek[d.getDay()]}, {months[d.getMonth()]} {d.getDate()}</h5>
                <div className="apt-modal-slots-container">
                    {numSlotsOfDoctor > 0 ? sortedOpenSlots.map(s => slot(s, d)) : "No slots available for this day"}
                </div>
            </div>
        )
    }

    const toggleDoctorFilter = () => {
        setDoctorDropdownOpen(!isDoctorDropdownOpen)
    };

    const handleDoctorSelected = (doc) => {
        if(doc === "Any Doctor") {
            setDoctorFilter(null)
            setDoctorDropdownOpen(false)
        } else {
            setDoctorFilter(doc)
            setDoctorDropdownOpen(false)
        }
    };

    const toggleReasonDropdown = () => {
        setReasonOpen(!isReasonOpen)
    };

    const handleReasonSelected = (reason) => {
        setSelectedReason(reason)
        setReasonOpen(false)
    };

    return (
        <div className="appointment-modal-container" ref={appointmentModalRef}>
            <button className="modal-close-btn" onClick={closeAppointmentModal}><i className="fa fa-times" /></button>
            <div className="appointment-modal-top-section">
                <div className="d-flex flex-col">
                    <div className="appointment-modal-img">
                        <img src={getClinicProfilePicture(appointmentModalData)} alt="Doc" />
                    </div>

                    <div className="appointment-modal-txt">
                        <h1>{appointmentModalData.hospital_name}</h1>
                        <h3>{appointmentModalData.main_specialty_title}</h3>
                        <p>{appointmentModalData.hospital_name}, {appointmentModalData.location_info.city} - {appointmentModalData.location_info.street_address}</p>
                        <span>
                            <img alt="Clinic Rating" src={filled_star} />
                            {
                                appointmentModalData.rating == -1 ?
                                <h5 style={{color:'grey'}}>No reviews</h5>
                                :
                                <>
                                <h4>{appointmentModalData.rating.toFixed(2)}</h4>
                                <h5>({appointmentModalData.num_reviews})</h5>
                                </>
                            }
                        </span>

                        <div className="search-list-profile-subsection">
                        </div>
                    </div>
                </div>
                <h2>{daysOfWeek[today.getDay()]}, {months[today.getMonth()]} {today.getDate()} - {daysOfWeek[lastDate.getDay()]}, {months[lastDate.getMonth()]} {lastDate.getDate()}</h2>
                <p className="choose-time-label">Choose a doctor from {appointmentModalData.hospital_name} that works for you</p>
                {!isLoading ? <div
                    className="booker-reason-input-container"
                    ref={doctorDropdownRef}
                >
                    <div className="booker-body-dropdown" onClick={toggleDoctorFilter}>
                        <h1>
                            {!doctorFilter ? "Any Doctor" : `Dr. ${doctorFilter.doctor_name_first} ${doctorFilter.doctor_name_last}`}
                        </h1>
                        <img alt="chevron down" src={chevron_down} />
                    </div>
                    {isDoctorDropdownOpen && (
                        <ul className="booker-dropdown-menu">
                            <li onClick={() => handleDoctorSelected("Any Doctor")}>
                                Any Doctor
                            </li>
                            {doctorList.map((doctor, index) => (
                                <li key={index} onClick={() => handleDoctorSelected(doctor)}>
                                    Dr. {doctor.doctor_name_first} {doctor.doctor_name_last}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                :
                <div style={{marginTop:'20px'}}><LoadingSpinner message={"Loading Doctors"}/></div>
                }
                {doctorFilter && <div
                    className="booker-reason-input-container"
                    ref={reasonDropdownRef}
                >
                    <div className="booker-body-dropdown" onClick={toggleReasonDropdown}>
                    {selectedReason != null
                    ? selectedReason
                    : "Choose a reason for your visit"}
                        <img alt="chevron down" src={chevron_down} />
                    </div>
                    {isReasonOpen && doctorFilter && (
                        <ul className="booker-dropdown-menu">
                            {doctorFilter.services.map((option, index) => (
                                <li key={index} onClick={() => handleReasonSelected(option)}>
                                {option}
                              </li>
                            ))}
                        </ul>
                    )}
                </div>}
            </div>
            <div className="appointment-modal-list">
                {dateRange.map(date => {
                    return (day(date))
                })}
            </div>
        </div>
    )
}

export default Appointment;