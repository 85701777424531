import React, { useEffect, useReducer, useRef, useState } from 'react';
import AchewHeader from '../../achew-header';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import LoadingSpinner from '../bookingv2/spinner';
import AWS from "aws-sdk";
import { getHospitalInfoByExternalID, getIntakeByToken, updateIntakeByID } from '../../../../api/patientAPIs';
import Loading from '../../loading';


const PatientIntake = ({}) => {

    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token'); 
    const MAX_FILE_SIZE = 30 * 1024 * 1024 //10MB max size
  

    //File States
    const [insuranceCardFront, setInsuranceCardFront] = useState(null)
    const [insuranceCardBack, setInsuranceCardBack] = useState(null)
    const [idCardFront, setIDCardFront] = useState(null)
    const [idCardBack, setIDCardBack] = useState(null)

    const insuranceCardFrontRef = useRef(null)
    const insuranceCardBackRef = useRef(null)
    const idCardFrontRef = useRef(null)
    const idCardBackRef = useRef(null)

    const [intakeData, setIntakeData] = useState(null)
    const [currentHospitalData, setCurrentHospitalData] = useState(null)

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isPageLoading, setIsPageLoading] = useState(true)
    const [showValidationMessage, setShowValidationMessage] = useState(false)
    const [validationMessages, setValidationMessages] = useState([])

    const S3_BUCKET = process.env.REACT_APP_S3_PATIENTBUCKETNAME;
    const REGION = process.env.REACT_APP_S3_REGION;
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_S3_ACCESSKEYID,
        secretAccessKey: process.env.REACT_APP_S3_SECRETACCESSKEY,
    });
    
    const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    const openNewTab = (path) => {
     window.open(path, '_blank');
    };

    useEffect(() => {
        console.log(token)
        if(token && token !== undefined) {
            
            getIntakeByToken(token).then(result => {
                const tempData = result.data.results[0]
                setIntakeData(tempData)
                if(tempData.status === "completed") {
                    navigate('/patient-intake/success', {state:{uploadSuccess: true}})
                    return
                }
                if(tempData.status === "cancelled") {
                    navigate('/error')
                    return
                }
                getHospitalInfoByExternalID(tempData.hospital_id).then(hospitalResponse => {
                    console.log(hospitalResponse.data)
                    setCurrentHospitalData(hospitalResponse.data)
                    setIsPageLoading(false)
                }).catch(err => {
                    console.log(err)
                })
                
            }).catch(err => {
                console.log(err)
                navigate('/error')
            })
        } else {
            navigate('/error')
        }
    },[]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }; 

    function convertToJpg(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
      
          reader.onload = function (event) {
            const imgElement = document.createElement('img');
            imgElement.src = event.target.result;
      
            imgElement.onload = function () {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
      
              canvas.width = imgElement.width;
              canvas.height = imgElement.height;
              ctx.drawImage(imgElement, 0, 0);
      
              canvas.toBlob((blob) => {
                const jpgFile = new File([blob], file.name.replace(/\.[^.]+$/, '.jpg'), { type: 'image/jpeg' });
                resolve(jpgFile);
              }, 'image/jpeg', 0.95); // 0.95 is the quality of the output image
            };
      
            imgElement.onerror = function (error) {
              reject(error);
            };
          };
      
          reader.onerror = function (error) {
            reject(error);
          };
        });
      }
    const handleFileChange = async (e, setter) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            try {
                const pngFile = await convertToJpg(selectedFile);
                setter(pngFile); // Store the converted file
              } catch (error) {
                console.error('Error converting to PNG:', error);
              }
        }
    };

    const convertToPNG = (file) => {
        const reader = new FileReader();
      
        return new Promise((resolve, reject) => {
          reader.onload = (e) => {
            const img = document.createElement('img');
            img.src = e.target.result;
      
            img.onload = () => {
              // Create a canvas and draw the image
              const canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
      
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0);
      
              // Convert the canvas content to a PNG blob
              canvas.toBlob(
                (blob) => {
                  // Preserve the original file name but change the extension to .png
                  const originalName = file.name.split('.')[0]; // Get the file name without extension
                  const newFile = new File([blob], `${originalName}`, { type: 'image/png' });
      
                  resolve(newFile); // Return the new PNG file
                },
                'image/png',
                1.0 // Quality setting (1.0 for max quality)
              );
            };
      
            img.onerror = reject;
          };
      
          reader.onerror = reject;
      
          reader.readAsDataURL(file); // Read the file as a data URL
        });
      };

    const submitIntake = () => {
        setIsSubmitting(true)
        setValidationMessages([])
        const tempValidationMessages = []
        if((intakeData.is_insurance_required && !(insuranceCardFront && insuranceCardBack))) {
            tempValidationMessages.push("Please upload images of your Insurance card")
        }
        if((intakeData.is_id_required && !(idCardFront && idCardBack))) {
            tempValidationMessages.push("Please upload images of your ID card")
        }
        if(insuranceCardFront && insuranceCardFront.size > MAX_FILE_SIZE) {
            tempValidationMessages.push("Insurance card frontside exceeds our file size limit of 5MB")
        }
        if(insuranceCardBack && insuranceCardBack.size > MAX_FILE_SIZE) {
            tempValidationMessages.push("Insurance card backside exceeds our file size limit of 5MB")
        }
        if(idCardFront && idCardFront.size > MAX_FILE_SIZE) {
            tempValidationMessages.push("ID card frontside exceeds our file size limit of 5MB")
        }
        if(idCardBack && idCardBack.size > MAX_FILE_SIZE) {
            tempValidationMessages.push("ID card backside exceeds our file size limit of 5MB")
        }

        if(tempValidationMessages.length > 0 ){
            setIsSubmitting(false)
            setValidationMessages(tempValidationMessages)
            setShowValidationMessage(true)
            return
        } 
        
        var uploadPromises = []
        if(insuranceCardFront && insuranceCardBack) {
            uploadPromises.push(s3.upload({
                Bucket: S3_BUCKET,
                Key: `hospitals/${currentHospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-Insurance_Card_Front.jpg`,
                Body: insuranceCardFront,
            }).promise())
            uploadPromises.push(s3.upload({
                Bucket: S3_BUCKET,
                Key: `hospitals/${currentHospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-Insurance_Card_Back.jpg`,
                Body: insuranceCardBack,
            }).promise())
        }

        if(idCardFront && idCardBack) {
            uploadPromises.push(s3.upload({
                Bucket: S3_BUCKET,
                Key: `hospitals/${currentHospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-ID_Card_Front.jpg`,
                Body: idCardFront,
            }).promise())
            uploadPromises.push(s3.upload({
                Bucket: S3_BUCKET,
                Key: `hospitals/${currentHospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-ID_Card_Back.jpg`,
                Body: idCardBack,
            }).promise())
        }

        Promise.all(uploadPromises).then(results => {
            console.log(results)
            const intakePatch = {
                "status":"completed",
                "did_upload_insurance": insuranceCardFront != null && insuranceCardBack != null,
                "did_upload_id": (idCardFront != null && idCardBack != null)
            }
            updateIntakeByID(intakePatch, intakeData.external_id, token).then(result => {
                console.log(result)
                navigate(`/patient-intake/success/`, {state:{uploadSuccess: true}})
                scrollToTop()
            }).catch(err => {
                console.log(err)
                setShowValidationMessage(true)
            })
        }).catch(err => {
            setIsSubmitting(false)
            setShowValidationMessage(true)
        })
    }

    if(isPageLoading) {
        return <Loading/>
    }

    return (
        <div className='patient-intake-container'>
            <div>
                <div className='patient-intake-header'>
                    <AchewHeader/>

                    <h3>Hi {intakeData.patient_name_first}, we need some forms for your upcoming visit to {currentHospitalData.hospital_name}</h3>
                    <h2>Please upload the following documents before your visit to help you and your provider save time.</h2>
                    {/* <div className='patient-intake-header-banner'>
                        <h4><b>Request</b>: Smiles Dental Clinic has requested your <b>insurance card</b> and your <b> id card</b>.</h4>
                    </div> */}
                </div>
                <div className='patient-intake-body'> 
                    <div className='patient-intake-form-group'>
                        {intakeData.is_insurance_required ? <h1>Insurance Card (Required)</h1> : <h1>Insurance Card (Optional)</h1>}
                        <div className='patient-intake-form-subgroup'>
                            <h2>Front Side</h2>
                            <h3>This will help your provider verify your insurance information</h3>
                            <div className='patient-intake-file-upload-container'>
                                <button onClick={() => insuranceCardFrontRef.current.click()}>
                                    <i class="fas fa-shield-alt"></i>
                                   {insuranceCardFront ? "Edit file" : "Choose file"}
                                </button>
                                <h4>
                                    {insuranceCardFront ? insuranceCardFront.name : "No file chosen"}
                                </h4>
                            </div>
                            <input 
                                type="file"
                                id="fileInput"
                                style={{display:'none'}}
                                onChange={(e) => handleFileChange(e, setInsuranceCardFront)}
                                accept=".png, .jpg, .jpeg"
                                multiple={false}
                                ref={insuranceCardFrontRef}
                            />
                        </div>
                        <div className='patient-intake-form-subgroup2'>
                            <h2>Back Side</h2>
                            <h3>Accept forms of ID include your driver's license and other state issued ID cards. Your name and photo must be visible on your ID card.</h3>
                            <div className='patient-intake-file-upload-container'>
                                <button onClick={() => insuranceCardBackRef.current.click()}>
                                    <i class="fas fa-shield-alt"></i>
                                  {insuranceCardBack ? "Edit file" : "Choose file"}
                                </button>
                                <h4>
                                    {insuranceCardBack ? insuranceCardBack.name : "No file chosen"}
                                </h4>
                            </div>
                            <input 
                                type="file"
                                id="fileInput"
                                style={{display:'none'}}
                                onChange={(e) => handleFileChange(e, setInsuranceCardBack)}
                                accept=".png, .jpg, .jpeg"
                                multiple={false}
                                ref={insuranceCardBackRef}
                            />
                        </div>
                    </div>

                    <div className='patient-intake-form-group'>
                    {intakeData.is_id_required ? <h1>ID Card (Required)</h1> : <h1>ID Card (Optional)</h1>}
                        <div className='patient-intake-form-subgroup'>
                            <h2>Front Side</h2>
                            <h3>This will help your provider verify your insurance information</h3>
                            <div className='patient-intake-file-upload-container'>
                                <button onClick={() => idCardFrontRef.current.click()}>
                                <i class="fas fa-id-card"></i>
                                   {idCardFront ? "Edit file" : "Choose file"}
                                </button>
                                <h4>
                                    {idCardFront ? idCardFront.name : "No file chosen"}
                                </h4>
                            </div>
                            <input 
                                type="file"
                                id="fileInput"
                                style={{display:'none'}}
                                onChange={(e) => handleFileChange(e, setIDCardFront)}
                                accept=".png, .jpg, .jpeg"
                                multiple={false}
                                ref={idCardFrontRef}
                            />
                        </div>
                        <div className='patient-intake-form-subgroup2'>
                            <h2>Back Side</h2>
                            <h3>Accept forms of ID include your driver's license and other state issued ID cards. Your name and photo must be visible on your ID card.</h3>
                            <div className='patient-intake-file-upload-container'>
                                <button onClick={() => idCardBackRef.current.click()}>
                                <i class="fas fa-id-card"></i>
                                   {idCardBack ? "Edit file" : "Choose file"}
                                </button>
                                <h4>
                                    {idCardBack ? idCardBack.name : "No file chosen"}
                                </h4>
                            </div>
                            <input 
                                type="file"
                                id="fileInput"
                                style={{display:'none'}}
                                onChange={(e) => handleFileChange(e, setIDCardBack)}
                                accept=".png, .jpg, .jpeg"
                                multiple={false}
                                ref={idCardBackRef}
                            />
                        </div>
                    </div>
                    {validationMessages.length > 0 && 
                        <h2 className='achew-validation-message-text2'>
                            {validationMessages.map(message => 
                            <h5>* {message}</h5>
                            )}
                        </h2>
                    }
                    <button className='patient-intake-submit-btn' onClick={() => submitIntake()}>
                        {isSubmitting ? <LoadingSpinner/> : "Submit"}
                    </button>
                </div>
            
            </div>
            
            
            <div className="doctor-landing-copyright-container no-mobile">
                <h2>© 2024 Achew Inc. All Rights Reserved.</h2>
                <div className="d-flex align-items-center">
                <h1 onClick={() => {openNewTab("/support/terms"); scrollToTop();}}>Terms & Conditions</h1>
                <h1 onClick={() => {openNewTab("/support/private-policy"); scrollToTop();}}>Privacy Policy</h1>
                </div>
            </div>
        </div>
    );
};

export default PatientIntake;