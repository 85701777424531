import React from "react";
import { useNavigate } from "react-router-dom";
import {
  arrow_top_right,
  arrow_top_right_grey,
  filled_star,
  geo_black,
  sample_doctor,
  default_profile,
} from "../../home/image";

const DoctorCard = ({ doctorData, handleDoctorClicked }) => {

  return (
    <div className="doc-profile-card" onClick={() => handleDoctorClicked()}>
      <div className="doc-profile-card-profile-img">
        <img
          alt="Clinic"
          src={doctorData.profile_image_url || default_profile}
        />
      </div>

      <div className="doc-profile-info-container">
        {doctorData.degrees.degree && doctorData.degrees.degree.length > 0 ? (
          <h3>
            Dr. {doctorData.doctor_name_first} {doctorData.doctor_name_last},{" "}
            {doctorData.degrees.degree}
          </h3>
        ) : (
          <h3>
            Dr. {doctorData.doctor_name_first} {doctorData.doctor_name_last}
          </h3>
        )}
      </div>
    </div>
  );
};

export default DoctorCard;
