import React, { useState, useEffect, useRef } from "react";
import insuranceSampleData from "./../../../../sampledata/insurance.json";
import { chevron_down, shield_icon } from "../../home/image.jsx";
import { useMediaQuery } from "react-responsive";
const InsurancePicker = ({
  setInsuranceID,
  setPlanID,
  handleInsuranceChosen,
  canChooseAllInsurances = false,
  handleSelectAllInsurancePlansChosen,
  shouldRemainOpenOnChoose = false,
  insuranceList = [],
  removeInsurance
}) => {
  const dropdownRef = useRef(null);
  const [filterValue, setFilterValue] = useState(null);
  const [filteredInsuranceData, setFilteredInsuranceData] =
    useState(insuranceSampleData);
  const [validInsuranceChosen, setValidInsuranceChosen] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState(null)
  const [selectedPlan,  setSelectedPlan] = useState(null)
  const [step, setStep] = useState(-1)
  const [insuranceFilterText, setInsuranceFilterText] = useState("")
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
  useEffect(() => {
    const closeDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setStep(-1)
      }
    };

    window.addEventListener('click', closeDropdown);
    return () => {
      window.removeEventListener('click', closeDropdown);
    };
  }, []);

  const handleInsuranceSelect = (insurance) => {
    setSelectedInsurance(insurance);
    setStep(1)
  };

  const closeInsurancePicker = () => {
    setStep(-1)
  }

  const handlePlanSelect = (plan) => {
    setInsuranceID(selectedInsurance.id);
    setPlanID(plan.id);
    setSelectedPlan(plan)
    if(!shouldRemainOpenOnChoose){
      setStep(-1)
    }
    if(handleBackToInsurance !== undefined){
      handleInsuranceChosen(selectedInsurance.id, plan.id)
    }
  };

  const handleBackToInsurance = () => {
    setFilterValue("");
    setSelectedPlan(null);
    setSelectedInsurance(null);
    setInsuranceID("");
    setPlanID("");
    setValidInsuranceChosen(false);
    setFilteredInsuranceData(insuranceSampleData);
    setStep(0)
  };

  const filterProvidersByNamePrefix = (data, prefix) => {
    // Filter popular providers by name
    const filteredPopular = data.popular.filter(provider =>
      provider.name.toLowerCase().startsWith(prefix.toLowerCase())
    );
  
    // Filter all providers by name
    const filteredAll = data.all.filter(provider =>
      provider.name.toLowerCase().startsWith(prefix.toLowerCase())
    );
  
    // Return the filtered results
    return {
      popular: filteredPopular,
      all: filteredAll
    };
  };

  const renderPlanItem = (option, index) => {
    var isSelected = false
    insuranceList.forEach(insurance => {
      console.log(JSON.stringify(option))
      if(insurance.planID === option.id) {
        
        isSelected = true
      }
    })
    return (
      <li className={isSelected ? "insurance-dropdown-plan-selected" : ""} key={index} onClick={() => handlePlanSelect(option)}>
        <h2>{option.title}</h2>
        {isSelected && <i class="fas fa-check"></i>}
      </li>
    )
  }

  const renderScreen = () => {
    console.log(insuranceFilterText)
    const filteredInsurances = filterProvidersByNamePrefix(insuranceSampleData, insuranceFilterText)
      return (
        <ul className="insurance-dropdown-content">
          <input
            value={insuranceFilterText}
            onChange={(e) => setInsuranceFilterText(e.target.value)}
            className="email-input"
            placeholder="Search insurance"
          />
          <p className="insurance-dropdown-section-label">Popular</p>
          {filteredInsurances.popular.map((option, index) => (
            <li key={index} onClick={() => handleInsuranceSelect(option)}>
              {option.name}
            </li>
          ))}

          <p className="insurance-dropdown-section-label">All insurances</p>
          {filteredInsurances.all.map((option, index) => (
            <li key={index} onClick={() => handleInsuranceSelect(option)}>
              {option.name}
            </li>
          ))}
        </ul>
      );
  };

  const renderScreen2 =  () => {
    return (
      <ul className="insurance-dropdown-content">
        <div
          className="insurance-dropdown-header"
          onClick={() => handleBackToInsurance()}
        >
          <i class="fas fa-arrow-left"></i>
          <p className="insurance-dropdown-section-label" style={{cursor:'pointer'}}>
            Back to Insurance Carriers
          </p>
        </div>
        <p className="insurance-dropdown-section-label">
          {selectedInsurance.name}
        </p>
        {canChooseAllInsurances &&
          <li onClick={() =>handleSelectAllInsurancePlansChosen(selectedInsurance.id)}>
              Select All Plans from {selectedInsurance.name}
          </li>
        }
        {selectedInsurance.plans.map((plan, index) => 
          renderPlanItem(plan, index)
        )}
      </ul>
    );
  }

  return (
    <div>
      <div
      className="insurance-picker-input-container"
    >
      <div className="insurance-picker-search-container" onClick={() => {step === -1 ? setStep(0) : setStep(-1)}}>
        <div className="d-flex align-items-center" >
          <div className={isTabletOrMobile ? "home-search-bar-img-container-tablet" : "home-search-bar-img-container"}>
            <img alt="Shield" src={shield_icon} />
          </div>
          {selectedInsurance === null || selectedPlan === null ? <h1>Pick your insurance and plan</h1> : <h1>{selectedInsurance.name} {selectedPlan.title}</h1>}
        </div>
        <div className={isTabletOrMobile ? "home-search-bar-img-container-tablet" : "home-search-bar-img-container"}>
          <img alt="Shield" src={chevron_down} />
        </div>
      </div>
      {
        !isTabletOrMobile && step === 0 && renderScreen()
      }
      {
        !isTabletOrMobile && step === 1 && renderScreen2()
      }
    </div>
      {
        isTabletOrMobile && step === 0 && renderScreen()
      }
      {
        isTabletOrMobile && step === 1 && renderScreen2()
      }
    </div>
    
  );
};

export default InsurancePicker;
