import React, { useState } from 'react';
import Header from '../patientheader';
import { useNavigate } from "react-router-dom";
import img1 from "../../assets/images/login-banner.png";
import Footer from "../footer";

const ContactUs = (props) => {
    const navigate = useNavigate()

    return (
        <div className="contact-us-wrapper">
            <Header />
            <div className="contact-us-box">
                <div className="contact-us-info">
                    <h1>Get the info you're looking for right now</h1>
                    <h2>Get answers to common questions and access service support</h2>
                </div>
                <img src={img1} class='contact-us-pic' />
            </div>
            <div className="contact-us-bottom">
                <h1>Get in touch with a member of our friendly Service team</h1>
                <h2>We’re available 365 days a year! Monday – Friday from 8am-8pm EST, and weekends and holidays from 9am-6pm EST.</h2>
                <div className='contact-us-email'>
                    <div className="contact-us-img">
                        <img src="" alt="Doc" />
                    </div>
                    <div className='contact-email'>
                        <h1>Send an email</h1>
                        <h2>We’ll get back to you within a day</h2>
                        <a href="">626-123-4567</a>
                    </div>
                </div>
                <h3>Our top FAQs</h3>
                <h4>How does Doccure work?</h4>
                <h4>How much will my doctor's appointment cost?</h4>
                <div className='contact-us-interest'>
                    <h1>Are you a practice interested in joining Doccure?</h1>
                    <button className="contact-us-book-btn" onClick={() => navigate("/doctor-registration")}>
                        Get started
                    </button>
                </div>
            </div>
            <Footer {...props} />
        </div>

    );
}

export default ContactUs;
