import React, { useState, useEffect } from 'react';
import Header from '../../patientheader';
import { Link } from 'react-router-dom'
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AchewHeader from '../../achew-header';
import AchewFooter from '../../achew-footer';
const PaymentConfirmed = () => {
    const navigate = useNavigate()
    const location = useLocation()
    console.log(location.state)
    const order_amount = location.state ? location.state.order_amount : 0
    const currentHospitalID = location.state ? location.state.currentHospitalID : ""
    useEffect(() => {
  }, []);
  return (
    <div className="reset-password-success-supercontainer">
      <AchewHeader />

      <div className="reset-password-success-container" >
          <div className="success-cont">
            <div className='d-flex flex-row align-items-center'>
                <i className="fas fa-check"></i>
                <h3>Thank you, your payment has been processed.</h3>
            </div>
            <p>Your order of ${order_amount} is complete. Please allow up to one hour for your balance to update.</p>
            <button onClick={() => navigate(`/plan?hospital=${currentHospitalID}`)}>Return</button>
          </div>
      </div>
      <AchewFooter/>
    </div>
  );
};

export default PaymentConfirmed;