import React, {useState} from 'react';
import { black_chevron_left, black_chevron_right, double_chevron_left, double_chevron_right } from '../../home/image';
import { useNavigate } from 'react-router-dom';
import { getAppointmentsByStatus } from '../../../../api/patientAPIs';

const AppointmentPagination = ({ currentHospitalID, paginationData, tab, setRefresh, refresh}) => {
    const navigate = useNavigate()
    const numPages = paginationData.lastPage
    const currentPage = paginationData.currentPage
    const navLink = `/appointment-manager?hospital=${currentHospitalID}&tab=${tab}`;
    
    const handlePageClicked = (pageNumber) => {
        if(numPages <= 1 || pageNumber === currentPage) {
            return
        }

        navigate(navLink + `&page_num=${pageNumber}`)
        setRefresh(!refresh)
        window.scrollTo({ top: 0, behavior: "smooth" });
        
    }
    
    const renderPage = (pageNumber) => {
        return(
            <button onClick={() => handlePageClicked(pageNumber)} className={pageNumber === currentPage ? 'achew-pagination-selected' : ''}>
                {pageNumber}
            </button>
        )
    }

    const renderPages = () => {
        if(numPages - currentPage === 1){
            if(numPages === 2){
                return(
                    <div className="d-flex align-items-center">
                        {renderPage(currentPage)}
                        {renderPage(currentPage + 1)}
                    </div>
                )
            } else if(numPages > 2){
                return(
                    <div className="d-flex align-items-center">
                        {renderPage(1)}
                        <h1>...</h1>
                        {renderPage(currentPage)}
                        {renderPage(currentPage + 1)}
                    </div>
                )
            }
            
        } else if(numPages - currentPage === 0){
            if(numPages === 1){
                return(
                    <div className="d-flex align-items-center">
                        {renderPage(currentPage)}
                    </div>
                )
            } else if (numPages === 2){
                return(
                    <div className="d-flex align-items-center">
                        {renderPage(currentPage - 1)}
                        {renderPage(currentPage)}
                    </div>
                )
            } else if (numPages > 2){
                return(
                    <div className="d-flex align-items-center">
                        {renderPage(1)}
                        <h1>...</h1>
                        {renderPage(currentPage - 1)}
                        {renderPage(currentPage)}
                    </div>
                )
            }
            
        } else if (numPages - currentPage > 1){
            return(
                <div className="d-flex align-items-center">
                    {renderPage(currentPage)}
                    {renderPage(currentPage + 1)}
                    <h1>...</h1>
                    {renderPage(numPages)}
                </div>
            )
        }
    }

  return (
    <div className="achew-pagination-container">
      <button onClick={() => handlePageClicked(1)}><img alt="double left" src={double_chevron_left}/></button>
      <button onClick={() => handlePageClicked(Math.max(currentPage - 1, 1))}><img alt="single left" src={black_chevron_left}/></button>

        {renderPages()}

        <button onClick={() => handlePageClicked(Math.min(numPages, currentPage + 1))}><img alt="single right" src={black_chevron_right}/></button>
      <button onClick={() => handlePageClicked(numPages)}><img alt="double right" src={double_chevron_right}/></button>
    </div>
  );
};

export default AppointmentPagination;