
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "@mui/material";
//icon

import { faHospital } from "@fortawesome/free-regular-svg-icons";
import logo from "../assets/images/logo.png";
import IMG01 from "../assets/images/doctors/doctor-thumb-02.jpg";
import Dropdown from "react-bootstrap/Dropdown";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

const Header = (props) => {



  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true
    });

  }, []);

  //mobile menu  
  const [isSideMenu, setSideMenu] = useState("")
  const [isSideMenuone, setSideMenuone] = useState("")
  const [isSideMenutwo, setSideMenutwo] = useState("")
  const navigate = useNavigate()

  const toggleSidebar = (value) => {
    console.log(value);
    setSideMenu(value)

  }
  const toggleSidebarone = (value) => {
    console.log(value);
    setSideMenuone(value)

  }
  const toggleSidebartwo = (value) => {
    console.log(value);
    setSideMenutwo(value)

  }



  let pathnames = window.location.pathname

  const [active, setActive] = useState(false);
  const url = pathnames.split("/").slice(0, -1).join("/");

  const onHandleMobileMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.add("menu-opened");
  };

  const onhandleCloseMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("menu-opened");
  };



  return (
    <>
      {true &&
        <header className="header">
          <nav className={`navbar-expand-lg header-nav`}>
            <div className="navbar-header">
              <a href="#0" id="mobile_btn" onClick={() => onHandleMobileMenu()}>
                <span className="bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </a>
              <Link to="/" className="navbar-brand logo">
                <img src={logo} className="img-fluid" alt="Logo" />
              </Link>
            </div>

            <div className="menu-header">
              <Link to="/" className="menu-logo">
                <img src={logo} className="img-fluid" alt="Logo" />
              </Link>
              <a
                href="#0"
                id="menu_close"
                className="menu-close"
                onClick={() => onhandleCloseMenu()}
              >
                <i className="fas fa-times"></i>
              </a>
            </div>

            <ul className="nav header-navbar-rht">

              <li className="nav-item contact-item">
                <div className="header-contact-detail">
                  <Link to={props.searchURL}>Browse</Link>
                </div>
                <Divider orientation="vertical" style={{ minHeight: "100%", color: "black ", }} />
                <div className="header-contact-detail">
                  <Link to="/contactus" onClick={() => onhandleCloseMenu()}>Help</Link>
                </div>
                <Divider orientation="vertical" style={{ minHeight: "100%", color: "black ", }} />
                <div className="header-contact-detail">
                  <Link to="/appointment-search
                  ">Find your Appointment</Link>
                </div>
                <Divider orientation="vertical" style={{ minHeight: "100%", color: "black ", }} />
                <div className="header-contact-detail">
                  <Link to="/welcome" onClick={()=>onhandleCloseMenu()}>List your Practice</Link>
                </div>
                <Divider orientation="vertical" style={{ minHeight: "100%", color: "black ", }} />
                <div className="header-contact-detail">
                  <Link to="/doctor-login">Doctor Login / Signup</Link>
                </div>
              </li>
            </ul>
          </nav>
        </header>
      }

    </>
  );
};

export default Header;

