
import React, { useState, useRef} from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "@googlemaps/js-api-loader";
//icon
import { geo_icon, location, search_icon, specialty_icon } from "../../home/image";
import { useEffect } from "react";
import specialtiesData from '../../../../sampledata/specialties.json'
import InsuranceDropdown from "../../home/insurance-dropdown";
const SearchResultsSearchBar = (props) => {
  const navigate = useNavigate()
  const procedureDropdownRef = useRef(null);
  const insuranceDropdownRef = useRef(null);
  const placeAutoCompleteRef = useRef(null);

  const [isProcedureOpen, setProcedureOpen] = useState(false);
  const [mapsAddress, setMapsAddress] = useState("");
  const [mapsLocation, setMapsLocation] = useState(null);
  const [currentLatitude, setCurrentLatitude] = useState(null);
  const [currentLongitude, setCurrentLongitude] = useState(null);

  const [selectedProcedure, setSelectedProcedure] = useState('');
  const [selectedInsurance, setSelectedInsurance] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [insuranceID, setInsuranceID] = useState(null);
  const [planID, setPlanID] = useState(null);
  const [procedureFilterText, setProcedureFilterText] = useState('');
  const [insuranceFilterText, setInsuranceFilterText] = useState("")
  const [isInsuranceOpen, setInsuranceOpen] = useState(false)

  const handleProcedureInputChange = (e) => {
    setSelectedProcedure(e.target.value);
    setProcedureFilterText(e.target.value);
  };
  const toggleProcedureDropdown = () => {
    setProcedureOpen(!isProcedureOpen);
  };
  const handleProcedureSelect = (option) => {
    setSelectedProcedure(option);
    setProcedureOpen(false);
  };

  const handleLocationChanged = (e) => {
    setMapsAddress(e.target.value);
  };

  const filteredPopSpecialties = specialtiesData.popular_specialties
    .filter((item) =>
        item.toLowerCase().includes(procedureFilterText.toLowerCase())
      )
 
  const filteredAllSpecialties = specialtiesData.all_specialties
    .filter((item) =>
        item.toLowerCase().includes(procedureFilterText.toLowerCase())
      )
const search = (event) => {
    event.preventDefault();
    if (mapsLocation) {
      const latitude = mapsLocation.geometry.location.lat();
      const longitude = mapsLocation.geometry.location.lng();
      const searchURL = `/search?specialty=${selectedProcedure}&distance=0&latitude=${latitude}&longitude=${longitude}&insurance=${insuranceID}&plan=${planID}&date=${(new Date()).getTime()}`;
      navigate(searchURL);
    } else {
      const searchURL = `/search?specialty=${selectedProcedure}&distance=0&latitude=${currentLatitude}&longitude=${currentLongitude}&insurance=${insuranceID}&plan=${planID}&date=${(new Date()).getTime()}`;
      navigate(searchURL);
    }
  };
useEffect(() => { 
  //Set up google maps autocomplete
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    version: "weekly",
    libraries: ["geometry", "places"],
  });

  loader.load().then((google) => {
    const autocomplete = new google.maps.places.Autocomplete(
      placeAutoCompleteRef.current
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("Selected Place:", place);
      setMapsAddress(place.formatted_address);
      setMapsLocation(place);
    });

    //Getting user's location
    
  });
  const handleClickOutside = (event) => {
    if (procedureDropdownRef.current && !procedureDropdownRef.current.contains(event.target)) {
      setProcedureOpen(false);
    }
  };
  const handleClickOutsideIns = (event) => {
    if (insuranceDropdownRef.current && !insuranceDropdownRef.current.contains(event.target)) {
      setInsuranceOpen(false);
    }
  };

  window.addEventListener('click', handleClickOutside);
  window.addEventListener('click', handleClickOutsideIns);

  return () => {
    window.removeEventListener('click', handleClickOutside);
    window.removeEventListener('click', handleClickOutsideIns);
  };
}, []);


  

  return ( 
    <form className="header-search-bar-container desktop-only" onSubmit={search}>
        <div className="header-search-bar-fields-container">
          <div
            className="header-search-bar-input-container"
            ref={procedureDropdownRef}
          >
            <div className="header-search-bar-img-container">
              <img alt="Specialty" src={specialty_icon} />
            </div>
            <input
              type="text"
              placeholder="Keyword"
              value={selectedProcedure}
              onChange={handleProcedureInputChange}
              onClick={toggleProcedureDropdown}
            />
            {isProcedureOpen && (
              <ul className="search-dropdown-menu">
                <p className="search-dropdown-section-label">
                  Popular Specialties
                </p>
                {filteredPopSpecialties.map((option, index) => (
                  <li key={index} onClick={() => handleProcedureSelect(option)}>
                    {option}
                  </li>
                ))}

                <p className="search-dropdown-section-label">All Specialties</p>
                {filteredAllSpecialties.map((option, index) => (
                  <li key={index} onClick={() => handleProcedureSelect(option)}>
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="header-search-bar-input-container">
            <div className="header-search-bar-img-container">
              <img alt="Geo" src={geo_icon} />
            </div>

            <input
              type="text"
              placeholder="Location"
              ref={placeAutoCompleteRef}
              value={mapsAddress}
              onChange={handleLocationChanged}
            />
          </div>
          
          <InsuranceDropdown isInsuranceOpen={isInsuranceOpen} setInsuranceOpen={setInsuranceOpen} filterValue={insuranceFilterText} setFilterValue={setInsuranceFilterText} selectedInsurance={selectedInsurance} setSelectedInsurance={setSelectedInsurance} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} setInsuranceID={setInsuranceID} setPlanID={setPlanID} />
          
        </div>

        <button className="header-search-bar-btn" type="submit">
          <img alt="Search" src={search_icon} />
        </button>
      </form>
  );
};

export default SearchResultsSearchBar;

