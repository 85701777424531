import React, { useState, useEffect } from 'react';

function PopupAlert({showAlert, setShowAlert, alertMessage, isErrorState = false}) {

  useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 4000); // Set the duration in milliseconds

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);


  return (
    <div className={`popup-alert-dashboard ${showAlert ? 'show' : ''}`}>
      
      <p>{
        !isErrorState ? <i className='fas fa-check'></i> : <i className='fas fa-exclamation'></i>
      } {alertMessage}</p>
    </div>
  );
}

export default PopupAlert;

