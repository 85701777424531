import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Create the chart when the component mounts
    const ctx = chartRef.current.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [{
          label: 'Clicks',
          data: data,
          backgroundColor: '#48bae8', // Adjust the color as needed
          borderWidth: 0,
          barPercentage: 0.25, // Adjust the width of the bars
            categoryPercentage: 0.8, // Adjust the space between bars
          borderRadius: "4px",
        }],
      },
      options: {
        scales: {
          x: {
            grid: {
                display: false, // Turn off X-axis grid lines
              },
            zeroLineColor: 'transparent',
            title: {
              display: false,
              text: 'Months',
            },
            ticks: {
                display: true,
                font: {
                  size: 13, // Adjust the font size
                  weight: '600', // Adjust the font weight
                  family: "Sharp Sans",
                },
              },
          },
          y: {
            title: {
              display: false,
              text: 'Clicks',
            },
            ticks: {
                display: true,
                font: {
                  size: 13, // Adjust the font size
                  weight: '600', // Adjust the font weight
                  family: "Sharp Sans",
                },
              },
          },
        },
      },
    });

    // Clean up the chart when the component unmounts
    return () => myChart.destroy();
  }, [data]);

  return (
      <canvas ref={chartRef} style={{ width: '100%', height: '100%' }}/>
  );
};

export default BarChart;
