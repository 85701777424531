import React, { useRef } from "react";

const AboutAchew = () => {
    return (
        <div className="tac-container">
            <h1>About Achew</h1>
            <h4>Achew revolutionizes the way individuals access and manage healthcare by offering a seamless, free platform to discover and book top-tier healthcare services tailored to their needs. This innovative platform provides comprehensive details on clinics and doctors throughout the United States, ensuring users can make informed decisions. With Achew, users experience the convenience of swift appointment scheduling, effortless modifications or cancellations, and the added benefit of receiving reminders for upcoming appointments via text and email. This holistic approach not only streamlines the healthcare process but also empowers users to stay engaged and informed about their healthcare journey.</h4>
            <h4>Achew offers complimentary hosting for clinic and doctor profile pages, extending an invitation to healthcare practices nationwide to amplify their online presence. By crafting a detailed profile accessible to all Achew users, practices can significantly enhance their visibility on the internet. Additionally, Achew provides sophisticated scheduling and appointment software solutions, enabling practices to effortlessly open and manage appointment slots. This seamless integration facilitates efficient organization and management of appointments, streamlining the process for both healthcare providers and their patients.</h4>
        </div>
    );
};

export default AboutAchew;
