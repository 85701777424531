import React from "react";
import "./calendar.css";
import CalendarStructure from "./calendar_month.json";
import DateSlot from "./date-slot.jsx";
import { get42DaysOfMonth } from "./helper-functions.jsx";


const Calendar = ({ year, month, appointmentDataDictionary, setPopupOpen, setPopupModalData, schedulePreviewConfig }) => {

    const daysOfCalendar = get42DaysOfMonth(year, month)
  return (
    <div className="calendar-body">
      <div className="calendar-day-header-row">
        <div className="calendar-day-container">Sun</div>
        <div className="calendar-day-container">Mon</div>
        <div className="calendar-day-container">Tue</div>
        <div className="calendar-day-container">Wed</div>
        <div className="calendar-day-container">Thu</div>
        <div className="calendar-day-container">Fri</div>
        <div className="calendar-day-container">Sat</div>
      </div>
      <div className="calendar-row">
        {daysOfCalendar.slice(0, 7).map((day) => <DateSlot date={day} appointments={[1, 2, 3]} weekIndex={0} setPopupOpen={setPopupOpen} appointmentDataDictionary={appointmentDataDictionary} setPopupModalData={setPopupModalData} schedulePreviewConfig={schedulePreviewConfig}/>)}
      </div>
      <div className="calendar-row">
        {daysOfCalendar.slice(7, 14).map((day) => <DateSlot date={day} appointments={[1, 2, 3]} weekIndex={1} setPopupOpen={setPopupOpen} appointmentDataDictionary={appointmentDataDictionary} setPopupModalData={setPopupModalData} schedulePreviewConfig={schedulePreviewConfig}/>)}
      </div>
      <div className="calendar-row">
      {daysOfCalendar.slice(14, 21).map((day) => <DateSlot date={day} appointments={[1, 2, 3]} weekIndex={2} setPopupOpen={setPopupOpen} appointmentDataDictionary={appointmentDataDictionary} setPopupModalData={setPopupModalData} schedulePreviewConfig={schedulePreviewConfig}/>)}
      </div>
      <div className="calendar-row">
      {daysOfCalendar.slice(21, 28).map((day) => <DateSlot date={day} appointments={[1, 2, 3]} weekIndex={3} setPopupOpen={setPopupOpen} appointmentDataDictionary={appointmentDataDictionary} setPopupModalData={setPopupModalData} schedulePreviewConfig={schedulePreviewConfig}/>)}
      </div>
      <div className="calendar-row">
      {daysOfCalendar.slice(28, 35).map((day) => <DateSlot date={day} appointments={[1, 2, 3]} weekIndex={4} setPopupOpen={setPopupOpen} appointmentDataDictionary={appointmentDataDictionary} setPopupModalData={setPopupModalData} schedulePreviewConfig={schedulePreviewConfig}/>)}
      </div>
      <div className="calendar-row">
      {daysOfCalendar.slice(35, 42).map((day) => <DateSlot date={day} appointments={[1, 2, 3]} weekIndex={5} setPopupOpen={setPopupOpen} appointmentDataDictionary={appointmentDataDictionary} setPopupModalData={setPopupModalData} schedulePreviewConfig={schedulePreviewConfig}/>)}
      </div>
    </div>
  );
};

export default Calendar;
