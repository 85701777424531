import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  RegisterDoctor,
  checkNPIRegistry,
  getHospitalInfoByExternalID,
  requestEmailVerification,
  requestSMSCode,
  setAppointments,
  testapi,
  verifyEmail,
  verifySMSCode,
} from "../../../../../api/patientAPIs.js";
import specialtiesData from '../../../../../sampledata/specialties.json'
import {
  getLast4DigitsAsString,
  isValidPassword,
  setMidnight,
  usStatesAbbreviations,
  validateNPI,
} from "../../../../assets/functions/functions.js";
import Loading from "../../../loading.jsx";
import AchewHeader from "../../../patients/bookingv2/booking-header.jsx";
import {
  appointment_background,
  booking_doctor,
  chevron_down,
  sample_doctor,
} from "../../../home/image.jsx";
import AchewFooter from "../../../patients/bookingv2/booking-footer.jsx";
import Carousel from "react-bootstrap/Carousel";
import LoadingSpinner from "../../../patients/bookingv2/spinner.jsx";
import { RolesAnywhere } from "aws-sdk";
import { RequestHospitalRegistration, RequestLogin, RequestRegister } from "../../../../../api/auth.js";
import { Loader } from '@googlemaps/js-api-loader';
import CircularSlider from "./circular-slider.jsx";
import { error } from "pdf-lib";

const Booking = () => {
  /* Universal */
  const navigate = useNavigate();
  const location = useLocation();
  const [missingFields, setMissingFields] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [geocoder, setGeocoder] = useState(null)

  /* First Page */
  const [isValidEma, setIsValidEma] = useState(true);
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [phone, setPhone] = useState(null);
  const [isRoleDropdownOpen, setIsRoleDropdownOpen] = useState(false);
  const [isSizeDropdownOpen, setIsSizeDropdownOpen] = useState(false);
  const roleDropdownRef = useRef(null);
  const sizeDropdownRef = useRef(null);
  const [isSubmittingPageOne, setIsSubmittingPageOne] = useState(false)
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false
  });

  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [selectedRoleError, setSelectedRoleError] = useState(false);
  const [selectedSizeError, setSelectedSizeError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const emailRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const selectedRoleRef = useRef();
  const selectedSizeRef = useRef();
  const phoneRef = useRef();

  /* Second Page */

  const [code, setCode] = useState(null);
  const [isVerifyingEmail, setVerifyingEmail] = useState(false)
  const [codeError, setCodeError] = useState(false);
  const codeRef = useRef();
  /* Third Page */
  const [practiceName, setPracticeName] = useState(null);
  const [practiceEmail, setPracticeEmail] = useState(null)
  const [practiceSpecialty, setPracticeSpecialty] = useState(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [npi, setNPI] = useState(null);
  const [zip, setZip] = useState(null);
  const [address, setAddress] = useState("");
  const [apt, setApt] = useState("");
  const [city, setCity] = useState("");
  const [statee, setStatee] = useState("");
  const stateDropdownRef = useRef(null);
  const [isStateDropdownOpen, setStateDropdownOpen] = useState(false)
  const [providerFirstName, setProviderFirstName] = useState(null);
  const [providerLastName, setProviderLastName] = useState(null);
  const [providerSpecialty, setProviderSpecialty] = useState(null)
  const [providerAbout, setProviderAbout] = useState(null)
  const [practicePhone, setPracticePhone] = useState(null)
  const specialtyDropdownRef = useRef(null)
  const [isSpecialtyDropdownOpen, setSpecialtyDropdownOpen] = useState(false)
  const practiceSpecialtyDropdownRef = useRef(null)
  const [isPracticeSpecialtyDropdownOpen, setPracticeSpecialtyDropdownOpen] = useState(false)
  // Error States
  const [practiceNameError, setPracticeNameError] = useState(false);
  const [practiceEmailError, setPracticeEmailError] = useState(false);
  const [practiceSpecialtyError, setPracticeSpecialtyError] = useState(false);
  const [npiError, setNPIError] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [aptError, setAptError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateeError, setStateeError] = useState(false);

  // Refs
  const practiceNameRef = useRef();
  const practiceEmailRef = useRef();
  const practiceSpecialtyRef = useRef();
  const npiRef = useRef();
  const zipRef = useRef();
  const addressRef = useRef();
  const aptRef = useRef();
  const cityRef = useRef();
  const stateeRef = useRef();

  // Error States
  const [providerFirstNameError, setProviderFirstNameError] = useState(false);
  const [providerLastNameError, setProviderLastNameError] = useState(false);
  const [providerSpecialtyError, setProviderSpecialtyError] = useState(false);
  const [providerAboutError, setProviderAboutError] = useState(false);
  const [practicePhoneError, setPracticePhoneError] = useState(false);

  // Refs
  const providerFirstNameRef = useRef();
  const providerLastNameRef = useRef();
  const providerSpecialtyRef = useRef();
  const providerAboutRef = useRef();
  const practicePhoneRef = useRef();

  const toggleRoleDropdown = () => {
    setIsRoleDropdownOpen(!isRoleDropdownOpen);
  };

  const handleRoleDropdownSelected = (role) => {
    setSelectedRole(role);
    setIsRoleDropdownOpen(false);
  };

  const toggleSizeDropdown = () => {
    setIsSizeDropdownOpen(!isSizeDropdownOpen);
  };

  const handleSizeDropdownSelected = (size) => {
    setSelectedSize(size);
    setIsSizeDropdownOpen(false);
  };

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_KEY,
      version: 'weekly',
      libraries: ['geometry', 'places'],
    });
    loader.load().then((google) => {
      setGeocoder(new google.maps.Geocoder())

    });
    const handleClickRoleOutside = (event) => {
      if (
        roleDropdownRef.current &&
        !roleDropdownRef.current.contains(event.target)
      ) {
        setIsRoleDropdownOpen(false);
      }
    };
    const handleClickSizeOutside = (event) => {
      if (
        sizeDropdownRef.current &&
        !sizeDropdownRef.current.contains(event.target)
      ) {
        setIsSizeDropdownOpen(false);
      }
    };

    const handleClickStateOutside = (event) => {
      if (
        stateDropdownRef.current &&
        !stateDropdownRef.current.contains(event.target)
      ) {
        setStateDropdownOpen(false);
      }
    };
    const handleClickSpecialtyOutside = (event) => {
      if (specialtyDropdownRef.current && !specialtyDropdownRef.current.contains(event.target)) {
        setSpecialtyDropdownOpen(false);
      }
    };
    const handleClickPracticeSpecialtyOutside = (event) => {
      if (practiceSpecialtyDropdownRef.current && !practiceSpecialtyDropdownRef.current.contains(event.target)) {
        setPracticeSpecialtyDropdownOpen(false);
      }
    };
    // Attach the event listener
    window.addEventListener('click', handleClickSpecialtyOutside);
    window.addEventListener('click', handleClickPracticeSpecialtyOutside);
    window.addEventListener("click", handleClickRoleOutside);
    window.addEventListener("click", handleClickSizeOutside);
    window.addEventListener("click", handleClickStateOutside);
    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener('click', handleClickPracticeSpecialtyOutside);
      window.removeEventListener("click", handleClickRoleOutside);
      window.removeEventListener("click", handleClickSizeOutside);
      window.removeEventListener("click", handleClickStateOutside);
      window.removeEventListener('click', handleClickSpecialtyOutside);
    };
  }, []);

  const validatePassword = (password) => {
    const requirements = {
      length: password.length >= 8 && password.length <= 16,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*]/.test(password)
    };

    setPasswordValidation(requirements);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    // Basic email format validation
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const isValidEmail = emailRegex.test(inputValue);

    setIsValidEma(isValidEmail);
  };

  const handlePhoneChange = (e) => {
    // Remove non-numeric characters and limit to 10 digits
    const cleanedInput = e.target.value.replace(/\D/g, "").substring(0, 10);

    // Format the phone number
    let formattedPhoneNumber = "";

    if (cleanedInput.length >= 1) {
      formattedPhoneNumber = `(${cleanedInput.substring(0, 3)}`;

      if (cleanedInput.length >= 4) {
        formattedPhoneNumber += `) ${cleanedInput.substring(3, 6)}`;

        if (cleanedInput.length >= 7) {
          formattedPhoneNumber += `-${cleanedInput.substring(6, 10)}`;
        }
      }
    }

    setPhone(formattedPhoneNumber);
  };

  const handlePracticePhoneChange = (e) => {
    // Remove non-numeric characters and limit to 10 digits
    const cleanedInput = e.target.value.replace(/\D/g, "").substring(0, 10);

    // Format the phone number
    let formattedPhoneNumber = "";

    if (cleanedInput.length >= 1) {
      formattedPhoneNumber = `(${cleanedInput.substring(0, 3)}`;

      if (cleanedInput.length >= 4) {
        formattedPhoneNumber += `) ${cleanedInput.substring(3, 6)}`;

        if (cleanedInput.length >= 7) {
          formattedPhoneNumber += `-${cleanedInput.substring(6, 10)}`;
        }
      }
    }

    setPracticePhone(formattedPhoneNumber);
  };

  const shiftCarouselLeft = () => {
    setCurrentPage(currentPage - 1);
  };

  const shiftCarouselRight = () => {
    setCurrentPage(currentPage + 1);
  };

  const firstPage = () => {
    return (
      <div className="booking-modal-container">
        <div className="booking-container-header">
          <div className="booking-container-header-txt">
            <h1 className="booking-intro-label">
              Register your practice with Achew
            </h1>
            <p className="booking-intro-labelp">
              Promote your practice and manage your bookings
            </p>
          </div>
          <img alt="Booking" src={booking_doctor} />
        </div>
        <form
          className="booking-basic-info-section booking-basic-info-section-less-padding"
          onSubmit={submitRegistrationOne}
        >
          <h1 className="booking-intro-label">Let's set up your Achew Clinic account</h1>
          <p className="booking-intro-labelp">
            Fill out some basic information about who you are.
          </p>
          <h3 className="booking-email-label">Email</h3>
          <input
            className={emailError ? "email-input error" : "email-input"}
            name="email"
            value={email}
            placeholder="Enter your email"
            onChange={handleEmailChange}
            ref={emailRef}
          />
          <h3 className="booking-email-label">Phone number</h3>
          <input
            className={phoneError ? "email-input error" : "email-input"}
            name="phone"
            value={phone}
            placeholder="(012) 345-6789"
            onChange={handlePhoneChange}
            ref={phoneRef}
          />
  
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Legal first name</h3>
              <input
                className={firstNameError ? "name-input error" : "name-input"}
                name="first"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(c) => setFirstName(c.currentTarget.value)}
                ref={firstNameRef}
              />
            </div>
  
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Legal last name</h3>
              <input
                className={lastNameError ? "name-input2 error" : "name-input2"}
                name="last"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(c) => setLastName(c.currentTarget.value)}
                ref={lastNameRef}
              />
            </div>
          </span>
  
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Your role</h3>
  
              <div className="booker-input-container" ref={roleDropdownRef}>
                <div className={selectedRoleError ? "booker-dropdown error" : "booker-dropdown"} onClick={toggleRoleDropdown}>
                  <h2>{selectedRole ? selectedRole : "Choose"}</h2>
                  <img alt="chevron down" src={chevron_down} />
                </div>
                {isRoleDropdownOpen && (
                  <ul className="booker-dropdown-menu">
                    {["Receptionist", "Owner", "Management", "Other"].map(
                      (option, index) => (
                        <li
                          key={index}
                          onClick={() => handleRoleDropdownSelected(option)}
                        >
                          {option}
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
            </div>
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Practice size</h3>
  
              <div className="booker-input-container" ref={sizeDropdownRef}>
                <div className={selectedSizeError ? "booker-dropdown error" : "booker-dropdown"} onClick={toggleSizeDropdown}>
                  <h2>{selectedSize ? selectedSize : "Choose"}</h2>
                  <img alt="chevron down" src={chevron_down} />
                </div>
                {isSizeDropdownOpen && (
                  <ul className="booker-dropdown-menu">
                    {["1-5 providers", "6-10 providers", "10+ providers"].map(
                      (option, index) => (
                        <li
                          key={index}
                          onClick={() => handleSizeDropdownSelected(option)}
                        >
                          {option}
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
            </div>
          </span>
          <h3 className="booking-email-label">Password</h3>
          <input
            type="password"
            className={passwordError ? "email-input error" : "email-input"}
            value={password}
            placeholder="Enter your password"
            onChange={handlePasswordChange}
            ref={passwordRef}
          />
          <p className={passwordValidation.length ? "booking-password-req passed" : "booking-password-req failed"}> Length: Must be between 8 and 16 characters.</p>
          <p className={passwordValidation.uppercase ? "booking-password-req passed" : "booking-password-req failed"}> Uppercase Letters: At least one uppercase letter (A-Z).</p>
          <p className={passwordValidation.lowercase ? "booking-password-req passed" : "booking-password-req failed"}> Lowercase Letters: At least one lowercase letter (a-z).</p>
          <p className={passwordValidation.number ? "booking-password-req passed" : "booking-password-req failed"}> Numbers: At least one number (0-9).</p>
          <p className={passwordValidation.specialChar ? "booking-password-req passed" : "booking-password-req failed"}> Special Characters: At least one special character (e.g., !, @, #, $, %, ^, &, *).</p>
          <h3 className="booking-email-label">Confirm password</h3>
          <input
            type="password"
            className={confirmPasswordError ? "email-input error" : "email-input"}
            value={confirmPassword}
            placeholder="Confirm your password"
            onChange={(c) => setConfirmPassword(c.currentTarget.value)}
            ref={confirmPasswordRef}
          />
          {missingFields.length > 0 && (
            <h1 className="missingFields">
              * Please check these fields for errors: {missingFields.toString()}
            </h1>
          )}
          <button className="continue-booking-btn">{isSubmittingPageOne ? <LoadingSpinner /> : "Continue"}</button>
        </form>
      </div>
    );
  };

  const secondPage = () => {
    return (
      <div className="booking-modal-container">
        <div className="booking-container-header">
          <div className="booking-container-header-txt">
            <h1 className="booking-intro-label">
              Register your practice with Achew
            </h1>
            <p className="booking-intro-labelp">
              Promote your practice and manage your bookings
            </p>
          </div>
          <img alt="Booking" src={booking_doctor} />
        </div>
        <div className="booking-basic-info-section booking-basic-info-section-less-padding">
          <h1 className="booking-intro-label">We sent an email to {email}.</h1>
          <p className="booking-intro-labelp">
            Please enter the 6 digit confirmation code in the email.
          </p>
          <h3 className="booking-email-label">Confirmation code</h3>
          <input
            className={codeError ? "email-input error" : "email-input"}
            name="code"
            value={code}
            placeholder="XXXXXX"
            onChange={(e) => setCode(e.currentTarget.value)}
            maxLength={6}
            ref={codeRef}
          />
          {missingFields.length > 0 && (
            <h1 className="missingFields">
              * Please check these fields for errors: {missingFields.toString()}
            </h1>
          )}
          <button
            className="continue-booking-btn"
            onClick={() => submitRegistrationTwo()}
          >
            {isVerifyingEmail ? <LoadingSpinner /> : "Continue"}
          </button>
          <button
            className="back-booking-btn"
            onClick={() => shiftCarouselLeft()}
          >
            Back
          </button>
        </div>
      </div>
    );
  };

  const thirdPage = () => {
    return (
      <div className="booking-modal-container">
        <div className="booking-container-header">
          <div className="booking-container-header-txt">
            <h1 className="booking-intro-label">
              Register your practice with Achew
            </h1>
            <p className="booking-intro-labelp">
              Promote your practice and manage your bookings
            </p>
          </div>
          <img alt="Booking" src={booking_doctor} />
        </div>
        <div className="booking-basic-info-section booking-basic-info-section-less-padding">
          <h1 className="booking-intro-label">Tell us about your practice</h1>
          <p className="booking-intro-labelp">
            Create your clinic profile
          </p>
          <h3 className="booking-email-label">Practice name</h3>
          <input
            className={practiceNameError ? "email-input error": "email-input"}
            ref={practiceNameRef}
            value={practiceName}
            placeholder="Ex. Smiles Dental Clinic"
            onChange={(c) => setPracticeName(c.currentTarget.value)}
          />
          <h3 className="booking-email-label">Practice email</h3>
          <input
            className={practiceEmailError ? "email-input error": "email-input"}
            ref={practiceEmailRef}
            value={practiceEmail}
            placeholder="Ex. smiledental@gmail.com"
            onChange={(c) => setPracticeEmail(c.currentTarget.value)}
          />
          <h3 className="booking-email-label">Clinic Specialty</h3>
          <div
            className="booker-reason-input-container"
            ref={practiceSpecialtyDropdownRef}
            style={{ marginBottom: '20px' }}
          >
            <div className={practiceSpecialtyError ? "booker-body-dropdown error" : "booker-body-dropdown"} onClick={() => setPracticeSpecialtyDropdownOpen(!isPracticeSpecialtyDropdownOpen)}>
              <h1>
                {practiceSpecialty != null
                  ? practiceSpecialty
                  : "Choose this clinic's specialty"}
              </h1>
              <img alt="chevron down" src={chevron_down} />
            </div>
            {isPracticeSpecialtyDropdownOpen && (
              <ul className="booker-dropdown-menu">
                {specialtiesData.all_specialties.map((option, index) => (
                  <li key={index} onClick={() => { setPracticeSpecialty(option); setPracticeSpecialtyDropdownOpen(false) }}>
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <h3 className="booking-email-label">National Provider Identifier (NPI)</h3>
          <input
            className={npiError ? "email-input error": "email-input"}
            ref={npiRef}
            value={npi}
            maxLength={10}
            placeholder="Enter your NPI"
            onChange={(c) => setNPI(c.currentTarget.value)}
          />
        </div>

        <div className="booking-basic-info-section-secondary">
          <h1 className="booking-intro-label">Practice location</h1>
          <p className="booking-intro-labelp">
            Where are you located? You can add more locations later if needed.
          </p>
          <h3 className="booking-email-label">Street address</h3>
          <input
            className={addressError ? "email-input error": "email-input"}
            ref={addressRef}
            name="street address"
            value={address}
            placeholder="Enter your street address"
            onChange={(c) => setAddress(c.currentTarget.value)}
          />
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">
                Apt, suite, building (optional)
              </h3>
              <input
                className="name-input"
                name="street address 2"
                placeholder="Enter apt, suite, or building"
                value={apt}
                onChange={(c) => setApt(c.currentTarget.value)}
              />
            </div>

            <div className="email-input-wrapper">
              <h3 className="booking-email-label">City</h3>
              <input
                className={cityError ? "name-input2 error": "name-input2"}
                ref={cityRef}
                name="city"
                placeholder="Enter city"
                value={city}
                onChange={(c) => setCity(c.currentTarget.value)}
              />
            </div>
          </span>
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">State</h3>

              <div className="booker-input-container" ref={stateDropdownRef}>
                <div className={stateeError ? "booker-dropdown error" : "booker-dropdown"} onClick={() => setStateDropdownOpen(!isStateDropdownOpen)}>
                  <h2>{statee && statee !== "" ? statee : "Choose your state"}</h2>
                  <img alt="chevron down" src={chevron_down} />
                </div>
                {isStateDropdownOpen && (
                  <ul className="booker-dropdown-menu">
                    {usStatesAbbreviations.map(
                      (option, index) => (
                        <li
                          key={index}
                          onClick={() => { setStatee(option); setStateDropdownOpen(false) }}
                        >
                          {option}
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
            </div>

            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Zip code</h3>
              <input
                className={zipError ? "name-input2 error": "name-input2"}
                ref={zipRef}
                name="zip code"
                placeholder="Enter your zip code"
                value={zip}
                onChange={(c) => setZip(c.currentTarget.value)}
              />
            </div>
          </span>
          <h3 className="booking-email-label">Phone number</h3>
          <input
            className={practicePhoneError ? "email-input error": "email-input"}
            ref={practicePhoneRef}
            name="phone number"
            value={practicePhone}
            placeholder="(012) 345-6789"
            onChange={handlePracticePhoneChange}
          />
        </div>

        <form
          className="booking-basic-info-section-third"
          onSubmit={submitRegistrationOne}
        >
          <h1 className="booking-intro-label">Healthcare Provider</h1>
          <p className="booking-intro-labelp">
            Who is the healthcare provider in your practice. You will have the chance to add more providers later.
          </p>
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">
                First name
              </h3>
              <input
                className={providerFirstNameError ? "name-input error" : "name-input"}
                ref={providerFirstNameRef}
                name="first"
                placeholder="Enter provider's first name"
                value={providerFirstName}
                onChange={(c) => setProviderFirstName(c.currentTarget.value)}
              />
            </div>

            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Last name</h3>
              <input
                className={providerLastNameError ? "name-input2 error" : "name-input2"}
                ref={providerLastNameRef}
                name="last"
                placeholder="Enter provider's last name"
                value={providerLastName}
                onChange={(c) => setProviderLastName(c.currentTarget.value)}
              />
            </div>
          </span>

          <h3 className="booking-email-label">Specialty</h3>
          <div
            className="booker-reason-input-container"
            ref={specialtyDropdownRef}
            style={{ marginBottom: '20px' }}
          >
            <div className={providerSpecialtyError ? "booker-body-dropdown error" : "booker-body-dropdown"} onClick={() => setSpecialtyDropdownOpen(!isSpecialtyDropdownOpen)}>
              <h1>
                {providerSpecialty != null
                  ? providerSpecialty
                  : "Choose this provider's main specialty"}
              </h1>
              <img alt="chevron down" src={chevron_down} />
            </div>
            {isSpecialtyDropdownOpen && (
              <ul className="booker-dropdown-menu">
                {specialtiesData.all_specialties.map((option, index) => (
                  <li key={index} onClick={() => { setProviderSpecialty(option); setSpecialtyDropdownOpen(false) }}>
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <h3 className="booking-email-label">About this provider</h3>
          <textarea
            className="email-input email-textarea"
            placeholder="Enter a description of this provider. Education, years of experience, certifications, degrees."
            value={providerAbout}
            onChange={(c) => setProviderAbout(c.currentTarget.value)}
          />

        </form>

        <div className="booking-basic-info-section-banner">
          <h1>
            Reminder: Your practice will be verified by Achew based on the NPI number you provided.
          </h1>
        </div>
        <div className="booking-basic-info-section-terms" style={{ marginTop: '15px' }}>
          <input
            type="checkbox"
            checked={termsChecked}
            onChange={(c) => setTermsChecked(!termsChecked)}
          />
          <h1>
            Before continuing, please review and accept our Terms and Conditions
            for Achew, as your use of our services is subject to these rules and
            regulations. By checking the box, you signify your agreement to be
            bound by these<button className="interal-link-button" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}/terms`, '_blank')}>Terms and Conditions</button>and<button onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}/private-policy`, '_blank')} className="interal-link-button">Privacy Policy</button>.
          </h1>
        </div>
        {missingFields.length > 0 && (
          <h1 className="missingFields" style={{marginLeft: '20px', marginRight:'20px'}}>
            * Please check these fields for errors: {missingFields.toString()}
          </h1>
        )}
        <button className="finish-booking-btn" onClick={() => submitRegistrationThree()}>
          {isBooking ? <LoadingSpinner /> : "Create Account"}
        </button>
        <button
          className="back-booking-btn"
          onClick={() => shiftCarouselLeft()}
        >
          Back
        </button>
      </div>
    );
  };
  const submitRegistrationOne = (e) => {
    e.preventDefault();
    setIsSubmittingPageOne(true)
    const tempMissingFields = [];
    // Check email
    if (email == null || !isValidEma) {
      tempMissingFields.push(" Email");
      setEmailError(true);
      emailRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      setEmailError(false);
    }

    // Check first name
    if (firstName === "" || firstName == null) {
      tempMissingFields.push(" First Name");
      setFirstNameError(true);
      firstNameRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      setFirstNameError(false);
    }

    // Check last name
    if (lastName === "" || firstName == null) {
      tempMissingFields.push(" Last Name");
      setLastNameError(true);
      lastNameRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      setLastNameError(false);
    }

    // Check selected role
    if (selectedRole == null || selectedRole === "") {
      tempMissingFields.push(" Role");
      setSelectedRoleError(true);
      roleDropdownRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      setSelectedRoleError(false);
    }

    // Check selected size
    if (selectedSize == null || selectedSize === "") {
      tempMissingFields.push(" Practice Size");
      setSelectedSizeError(true);
      sizeDropdownRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      setSelectedSizeError(false);
    }

    // Check phone
    if (phone == null || phone === "") {
      tempMissingFields.push(" Phone");
      setPhoneError(true);
      phoneRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      setPhoneError(false);
    }

    // Check password
    if (password == null || !(passwordValidation.length && passwordValidation.lowercase && passwordValidation.uppercase && passwordValidation.number && passwordValidation.specialChar)) {
      tempMissingFields.push(" Your password must be at least 8 characters long with at least one special character, number, and upper case letter");
      setPasswordError(true);
      passwordRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      setPasswordError(false);
    }

    // Check password confirmation
    if (password !== confirmPassword) {
      tempMissingFields.push(" Please check that your passwords match");
      setConfirmPasswordError(true);
      confirmPasswordRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      setConfirmPasswordError(false);
    }
    if (tempMissingFields.length !== 0) {
      setMissingFields(tempMissingFields);
      setIsSubmittingPageOne(false)
      return;
    } else {
      setMissingFields([]);
    }
    requestEmailVerification(email).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        console.log("Successful email verification")
        shiftCarouselRight();
      } else {
        const tempMissingFields = [];
        tempMissingFields.push(" A user with the provided email already exists");
        setMissingFields(tempMissingFields);
      }
      setIsSubmittingPageOne(false)
    })
  };

  const submitRegistrationTwo = () => {
    setVerifyingEmail(true)
    verifyEmail(email, code).then((response) => {
      if(response.status >= 200 && response.status < 300){
        const status = response.data.msg;
        console.log("Verify Email Response: ", response)
        if (status === "Email verified") {
          shiftCarouselRight();
          setCodeError(false)
          setMissingFields([])
        }
      } else {
        setCodeError(true)
          setMissingFields(["Confirmation Code is incorrect"]);
      }
      setVerifyingEmail(false)
    });
  };

  const submitRegistrationThree = async (e) => {
    const tempMissingFields = [];
    if (practiceName == null || practiceName === "") {
      setPracticeNameError(true);
      practiceNameRef.current.scrollIntoView({ behavior: 'smooth' });
      tempMissingFields.push("Name of practice");
    }
    if (practiceEmail == null || practiceEmail === "") {
      setPracticeEmailError(true);
      practiceEmailRef.current.scrollIntoView({ behavior: 'smooth' });
      tempMissingFields.push("Practice email");
    }
    if (practicePhone == null || practicePhone === "") {
      setPracticePhoneError(true);
      practicePhoneRef.current.scrollIntoView({ behavior: 'smooth' });
      tempMissingFields.push("Practice email");
    }
    if (practiceSpecialty == null || practiceSpecialty === "") {
      setPracticeSpecialtyError(true);
      practiceSpecialtyDropdownRef.current.scrollIntoView({ behavior: 'smooth' });
      tempMissingFields.push("Practice Specialty");
    }
    if (npi == null || npi === "") {
      setNPIError(true);
      npiRef.current.scrollIntoView({ behavior: 'smooth' });
      tempMissingFields.push("Please enter a valid NPI");
    }
    if (address == null || address.length == 0) {
      setAddressError(true);
      addressRef.current.scrollIntoView({ behavior: 'smooth' });
      tempMissingFields.push("Address");
    }
    if (city === "" || city == null) {
      setCityError(true);
      cityRef.current.scrollIntoView({ behavior: 'smooth' });
      tempMissingFields.push("City");
    }
    if (statee === "" || statee == null) {
      setStateeError(true);
      stateDropdownRef.current.scrollIntoView({ behavior: 'smooth' });
      tempMissingFields.push("State");
    }
    if (zip == null || !zip) {
      setZipError(true);
      zipRef.current.scrollIntoView({ behavior: 'smooth' });
      tempMissingFields.push("Zip");
    }
    if (providerFirstName == null || providerFirstName === "") {
      setProviderFirstNameError(true);
      providerFirstNameRef.current.scrollIntoView({ behavior: 'smooth' });
      tempMissingFields.push("Provider's first name");
    }
    if (providerLastName == null || providerLastName === "") {
      setProviderLastNameError(true);
      providerLastNameRef.current.scrollIntoView({ behavior: 'smooth' });
      tempMissingFields.push("Provider's last name");
    }
    if (providerSpecialty == null || providerSpecialty === "") {
      setProviderSpecialtyError(true);
      specialtyDropdownRef.current.scrollIntoView({ behavior: 'smooth' });
      tempMissingFields.push("Provider's specialty");
    }
    if (termsChecked == null || termsChecked === false) {
      //setTermsCheckedError(true);
      //termsCheckedRef.current.scrollIntoView({ behavior: 'smooth' });
      tempMissingFields.push("Please agree to Achew's terms and conditions");
    }
    const isValidNPI = await checkNPIRegistry(npi);
    if (npi == null || npi === "") {
      setNPIError(true);
      npiRef.current.scrollIntoView({ behavior: 'smooth' });
      tempMissingFields.push("We couldn't find your NPI number");
    }
    if (tempMissingFields.length !== 0) {
      setMissingFields(tempMissingFields);
      setIsBooking(false)
      return;
    } else {
      setMissingFields([]);
    }
    setIsBooking(true)

    const accountData = {
      "account_email": email,
      "password": password,
      "first_name": firstName,
      "phone": phone,
      "last_name": lastName,
      "verified": true,
      "verify_code": code,
    }



    RequestRegister(accountData).then(response => {
      if (response.status === 201) {
        console.log(response)
        RequestLogin(accountData.account_email, accountData.password).then(async loginResponse => {
          await geocoder.geocode({ address: `${apt}, ${address} , ${city}, ${statee} ${zip}, United States` }, (results, status) => {
            if (results) {
              const location = results[0].geometry.location;
              const latitude = location.lat();
              const longitude = location.lng();
              const coordinates = {
                "longitude": longitude,
                "latitude": latitude,
              }
              const registrationData = {
                "doctors": [],
                "is_profile_active": true,
                "hospital_email": practiceEmail,
                "hospital_images": [],
                "hospital_name": practiceName,
                "location_info": {
                  "street_address": address,
                  "street_address_2": apt,
                  "city": city,
                  "state": statee,
                  "zip": zip,
                  "phone": phone,
                  "coordinates": coordinates
                },
                "latitude": coordinates.latitude,
                "longitude": coordinates.longitude,
                "presentation_mode": "hospital",
                "profile_image_url": "",
                "main_specialty_title": practiceSpecialty,
                "npi_number": npi,
                "rating": 0,
                "num_reviews": 0,
                "verified": true,
                "accepted_insurance": [],
                "about": "",
                "services": [],
                "timeline": [],
                "promotions": [],
              }

              RequestHospitalRegistration(registrationData).then(hospitalResult => {
                console.log(hospitalResult)

                const doctorData = {
                  "doctor_name_first": providerFirstName,
                  "doctor_name_last": providerLastName,
                  "doctor_name_middle": "",
                  "education": {},
                  "work_experience": {},
                  "awards": {},
                  "degrees": {},
                  "services": ["General Appointment"],
                  "gender": "NA",
                  "about": providerAbout,
                  "owner": 0
                }

                RegisterDoctor(doctorData, hospitalResult.data).then(doctorResult => {
                  if (doctorResult.status === 200 || doctorResult.status === 201) {
                    navigate(`/summary?hospital=${hospitalResult.data.external_id}`)
                  }
                })

              })
            } else {
              console.log("Location not found")
            }

          })

        })
      } else {
        const tempMissingFields = [...missingFields];
        tempMissingFields.push(" An error has occurred while creating your account.");
        setMissingFields(tempMissingFields);

      }
      setIsBooking(false)
    })
  };

  return (
    <div className="booking-container">
      <AchewHeader />
      <div className="booking-background-container">
        <img alt="background" src={appointment_background} />
      </div>
      <Carousel controls={false} indicators={false} activeIndex={currentPage}>
        <Carousel.Item>{firstPage()}</Carousel.Item>
        <Carousel.Item>{secondPage()}</Carousel.Item>
        <Carousel.Item>{thirdPage()}</Carousel.Item>
      </Carousel>
      <AchewFooter />
    </div>
  );
};

export default Booking;
