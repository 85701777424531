import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
const ProfileImageModal = ({ currentHospitalData, currentImage, setCurrentImage, setImageModalOpen, currentImageIndex }) => {
    const [currentIndex, setCurrentIndex] = useState(currentImageIndex);
    const modalContainerRef = useRef(null);
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });

    const changeImage = (direction) => {
        const totalImages = currentHospitalData.hospital_images.length;

        if (totalImages <= 1) {
            return;
        }

        let newIndex;

        if (direction === 'left') {
            newIndex = currentIndex === 0 ? totalImages - 1 : currentIndex - 1;
        } else {
            newIndex = currentIndex === totalImages - 1 ? 0 : currentIndex + 1;
        }

        setCurrentImage(currentHospitalData.hospital_images[newIndex]);
        setCurrentIndex(newIndex);

        const selectedImageElement = document.getElementById(`profile-image-img-container-${newIndex}`);
        if (selectedImageElement) {
            selectedImageElement.scrollIntoView({
                behavior: 'instant',
                block: 'nearest',
            });
        }
    };

    useEffect(() => {
        const handleClickOutsideModal = (event) => {
            if (modalContainerRef.current && !modalContainerRef.current.contains(event.target)) {
                setImageModalOpen(false);
            }
        };

        const handleKeyDown = (event) => {
            // Check if the pressed key is the Escape key (key code 27)
            if (event.keyCode === 27) {
                setImageModalOpen(false);
            } else if (event.keyCode === 37) {
                // Left arrow key
                changeImage('left');
            } else if (event.keyCode === 39) {
                // Right arrow key
                changeImage('right');
            }
        };

        document.body.classList.add('modal-open');

        window.addEventListener('mousedown', handleClickOutsideModal);
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('mousedown', handleClickOutsideModal);
            window.removeEventListener('keydown', handleKeyDown);
            document.body.classList.remove('modal-open');
        };
    }, [changeImage]);

    const renderImage = (imageURL, index) => {
        return (
            <div
                key={index}
                id={`profile-image-img-container-${index}`}
                className='profile-image-img-container'
                onClick={() => {
                    setCurrentImage(imageURL);
                    setCurrentIndex(index);
                }}
            >
                <img
                    src={imageURL}
                    alt="Square Image"
                    style={{ opacity: imageURL === currentImage ? 1.0 : 0.5 }}
                    className={imageURL === currentImage ? 'profile-image-img-container-active' : ''}
                />
            </div>
        );
    };


    return (
        <div className="profile-image-modal-overlay">
            <button className='profile-image-close-btn' onClick={() => setImageModalOpen(false)}>
                <div className='profile-image-close-btn-text'>Close</div>
                <i className="fas fa-times"></i>
            </button>
            <div ref={modalContainerRef} className="profile-image-modal">
                <div className='profile-image-modal-image'>
                    <div className='profile-image-button-left' onClick={() => changeImage('left')}>
                        <i className="fas fa-chevron-left"></i>
                    </div>
                    <div className='profile-image-button-right' onClick={() => changeImage('right')}>
                        <i className="fas fa-chevron-right"></i>
                    </div>
                    <img
                        className='profile-image-selected'
                        src={currentImage}
                        alt="Square"
                    />
                </div>
                {!isTabletOrMobile && <div className='profile-image-modal-list-container'>
                    <div className='d-flex align-items-center'>
                        <h1>{currentHospitalData.hospital_name}</h1>
                    </div>
                    <h2><u>Photos</u> ({currentHospitalData.hospital_images.length})</h2>
                    <div className='doc-profile-images-gallery'>
                        {currentHospitalData.hospital_images && currentHospitalData.hospital_images.map((image, index) => renderImage(image, index))}
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default ProfileImageModal;
