import React, { useState, useEffect, useRef } from "react";
import CircularSlider from "../../register/registerv2/circular-slider";
import { checkDefaultPayment } from "../../../../../api/patientAPIs";
import LoadingSpinner from "../../../patients/bookingv2/spinner";

const PaymentSetupModal = ({currentHospitalData, handleEditRechargePlan, isPaymentSetupModalOpen, setPaymentSetupModalOpen}) => {

    const [currentTab, setCurrentTab] = useState(0)
    const [missingFields, setMissingFields] = useState(false)
    const [planLimit, setPlanLimit] = useState(0)
    const [planAmount, setPlanAmount] = useState(0)
    const [planCondition, setPlanCondition] = useState(0)
    const [didAgreeTerms, setDidAgreeTerms] = useState(false)
    const [isUpdatingAutoCharge, setIsUpdatingAutoCharge] = useState(false)
    const [showValidationError, setShowValidationError] = useState(false)
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState("")
    const handleDefaultPaymentMethod = (e) => {
        e.preventDefault()
        setCurrentTab(1)
        window.open(`https://billing.stripe.com/p/login/${process.env.REACT_APP_STRIPE_SETUP_KEY}`, "_blank");
    };

    function handleAmountInput(event, setValue) {
        const input = event.target;
        // Remove non-numeric characters from the input value
        input.value = input.value.replace(/[^0-9]/g, '');
        setValue(event.currentTarget.value)
    }

    const verifyPaymentMethod = async (e) => {
        e.preventDefault()
        const isVerified = await checkDefaultPayment().then(response => {
            return response.status
        })

        if(isVerified){
            setCurrentTab(2)
            setMissingFields(false)
            checkDefaultPayment().then(response => {
                if(response.status) {
                  if(response.type === "link"){
                    setDefaultPaymentMethod(`Stripe Link`)
                  }
                  else if(response.type === "card"){
                    setDefaultPaymentMethod(`Card ending in ${response.last4}`)
                  }else if (response.type === "us_bank_account"){
                    setDefaultPaymentMethod(`Bank account ending in ${response.last4}`)
                  }else{
                    setDefaultPaymentMethod("Other payment method")
                  }
                } else {
                  setDefaultPaymentMethod("No payment method set")
                }
            })
        } else {
            setMissingFields(true)
        }
    }

    const closeModalWithDone = async () => {
        await handleEditRechargePlan(true, planLimit, planAmount, planCondition, true)
        setPaymentSetupModalOpen(false)
        setCurrentTab(0)
    }

    const formatAmount = (value) => {
        // Ensure centAmount is a valid number
        const parsedCents = parseFloat(value);
        if (isNaN(parsedCents)) {
            return ""
        }

        // Convert cents to dollars with two decimal places
        const dollars = (parsedCents / 100).toFixed(2);
        return "$" + dollars;
    };

    const handleDefaultPaymentMethod2 = (e) => {
        e.preventDefault()
        window.open(`https://billing.stripe.com/p/login/${process.env.REACT_APP_STRIPE_SETUP_KEY}`, "_blank");
    };

    const renderHeaderBlock = (step, name, isComplete ) => {
        
        return(
            <div className="payment-setup-header-block">
                <div className="payment-setup-header-block-icon" style={{backgroundColor: isComplete ? '#12b76a' : '#f0f0f0'}}>
                    <i class={isComplete ? 'fas fa-check' : 'fas fa-clock'} style={{color:isComplete ? 'white' : 'black'}}></i>
                </div>
                <h1>STEP {step}</h1>
                <h2 >{name}</h2>
                <h3 style={{color: isComplete ? '#12b76a' : '#333'}}>{isComplete ? 'Complete' : 'Not Started'}</h3>
            </div>
        )
    }

    const renderPage = () => {
        if(currentTab === 0) {
            return renderFirstPage()
        } else if (currentTab === 1){
            return renderSecondPage()
        }else if (currentTab === 2){
            return renderThirdPage()
        }
    }

    const renderFirstPage = () => {
        return(
            <div className="payment-setup-body">
                <i class="far fa-credit-card"></i>
                <h1>Click the button below to set up your default payment method. You will be redirected to a new page.</h1>
                <button className="plan-container-default-payment-container" style={{marginRight:'0'}} onClick={(e) => handleDefaultPaymentMethod(e)}>
                    <h2>Get Started</h2>
                </button>
            </div> 
        )
    }

    const renderSecondPage = () => {
        return(
            <div className="payment-setup-body">
                <i class="far fa-link"></i>
                <h1>Click the button below after adding your default payment method. We will verify that it's set.</h1>
                {missingFields && (
                    <h1 className="missingFields">
                        We couldn't verify your default payment method. Please try again.
                    </h1>
                )}
                <div className="payment-method-buttons-container">
                    <button className="plan-container-default-payment-container" style={{backgroundColor:'white'}} onClick={(e) => handleDefaultPaymentMethod2(e)}>
                        <h2 style={{color:'black'}}>Edit Default Payment</h2>
                    </button>
                    <button className="plan-container-default-payment-container" style={{marginRight:'0'}} onClick={(e) => verifyPaymentMethod(e)}>
                        <h2>Continue</h2>
                    </button>
                </div>
                
            </div> 
        )
    }
 

    const renderThirdPage = () => {
        return(
            <div className="payment-setup-body">
                <div className="plan-container-right-info" style={{marginTop:'-30px'}}>
                        <h2>Minimum Balance </h2>
                        <input
                          className="plan-container-auto-recharge-input"
                          value={formatAmount(planCondition)}
                          placeholder="$0.00"
                          onChange={(e) => handleAmountInput(e, setPlanCondition)}
                        />
                      </div>
                      <div className="plan-container-right-info">
                        <h2>Amount to add</h2>
                        <input
                          className="plan-container-auto-recharge-input"
                          value={formatAmount(planAmount)}
                          placeholder="$0.00"
                          onChange={(e) => handleAmountInput(e, setPlanAmount)}
                        />
                      </div>
                      <div className="plan-container-right-info">
                        <h2>Monthly recharge limit</h2>
                        <input
                          className="plan-container-auto-recharge-input"
                          value={formatAmount(planLimit)}
                          placeholder="$0.00"
                          onChange={(e) => handleAmountInput(e, setPlanLimit)}
                        />
                      </div>
                      <div className="plan-container-right-info">
                        <h2>Default Payment Method</h2>
                        <input
                          className="plan-container-auto-recharge-input"
                          style={{color:"#aaa"}}
                          value={defaultPaymentMethod}
                          contentEditable={false}
                          disabled={true}
                        />
                      </div>
                      <h1>Set rules for when your account balance is charged.</h1>
                      <button className="plan-container-default-payment-container" onClick={() => closeModalWithDone()}>{isUpdatingAutoCharge ? <LoadingSpinner /> : <h2>Submit</h2>}</button>
            </div> 
        )
    }

    const handleOverlayClicked = (e) => {
        e.stopPropagation()
        setPaymentSetupModalOpen(false)
    }

    if (!isPaymentSetupModalOpen) {
        return null;
    }

    return (
        <div className="create-appointment-modal-overlay" onClick={handleOverlayClicked}>
            <form className="payment-setup" onClick={(e) => e.stopPropagation()}>
                <div className="payment-setup-header">
                    {renderHeaderBlock(1, "Setup", currentTab === 1 || currentTab === 2)}
                    {renderHeaderBlock(2, "Verify", currentTab === 2)}
                    {renderHeaderBlock(3, "Plan", false)}
                </div>
                {renderPage()}
            </form>
        </div>

    );
};

export default PaymentSetupModal;