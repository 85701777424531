import React from 'react';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';

const GeneralTooltip = ({ message, children }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const currentHospitalID = searchParams.get("hospital");
    return (
      <div className="tooltip-container">
        <div className='tooltip-children'>
            {children}
            <span className="tooltip-text">{message}
            <button onClick={() => navigate(`/plan?hospital=${currentHospitalID}`)}>Recharge now</button>
            </span>
        </div>
      </div>
    );
  };

export default GeneralTooltip;
