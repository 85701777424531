import React, { useState, useEffect, useRef } from "react";
import { Divider } from "@mui/material";
import { completeAppointment, declineAppointment, getAppointments, getAppointmentsByStatus, getIntakeByID, getIntakeByToken } from "../../../../api/patientAPIs";
import sampleIMG from '../../../assets/images/login-banner.png'
import DoctorHeader from '../doctorheader'
import DoctorSideBar from './dashboardsidebar'
import { getAccountInfo, getHospitalInfoByInternalID, testapi } from '../../../../api/patientAPIs';
import { refreshToken } from '../../../../api/auth';
import { useNavigate, useLocation, json } from 'react-router-dom';
import { setAccountInfo } from '../../../../hooks/accountSlice';
import { useDispatch, useSelector } from 'react-redux';
import PatientModal from './patient-modals/patient-modal'
import Loading from '../../loading';
import {
    formatDateToLongString,
    findObjectById,
    isSlotExpired
} from '../../../assets/functions/functions'
import AppointmentView from "./appointment-view";
import Pagination from "../../search/pagination";
import { achew_logo_main, achew_logo_side } from "../../home/image";
import AppointmentPagination from "./appointment-pagination";
import CreateAppointmentModal from "./patient-modals/create-appointment-modal";
import CreateIntakeModal from "./patient-modals/create-intake-modal";
import PopupAlert from "./popup-alert";
import CreditWarningPopup from "./credit-warning-popup";


const AppointmentManager = () => {
    /* Appointment Lists */
    const [pendingAppointmentData, setPendingAppointmentData] = useState([])
    const [confirmedAppointmentData, setConfirmedAppointmentData] = useState([])
    const [completedAppointmentData, setCompletedAppointmentData] = useState([])
    const [declinedAppointmentData, setDeclinedAppointmentData] = useState([])

    // Appointment Pagination Data
    const [pendingPaginationData, setPendingPaginationData] = useState(null)
    const [confirmedPaginationData, setConfirmedPaginationData] = useState(null)
    const [completedPaginationData, setCompletedPaginationData] = useState(null)
    const [declinedPaginationData, setDeclinedPaginationData] = useState(null)

    const [currentIndex, setCurrentIndex] = useState(0)
    const [expandedAppointmentId, setExpandedAppointmentId] = useState(null);

    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    //Params
    const searchParams = new URLSearchParams(location.search);
    const currentHospitalID = searchParams.get('hospital')
    const appointmentParam = searchParams.get('appointment')
    const pageNumParam = searchParams.get('page_num') ? parseInt(searchParams.get('page_num')) : 1
    const tabParam = searchParams.get('tab') ? parseInt(searchParams.get('tab')) : 0
    const [refresh, setRefresh] = useState(false)
    const [accountData, setAccountData] = useState({})

    // API Data
    const [hospitalData, setHospitalData] = useState(null)
    const [currentHospitalData, setCurrentHospitalData] = useState({ hospital_name: "" })
    const [isPageLoading, setPageLoading] = useState(true)

    //Create Appointment
    const [isCreateAppointmentModalOpen, setCreateAppointmentModalOpen] = useState(false)

    //Intake Support
    const [appointmentIntakeDictionary, setAppointmentIntakeDictionary] = useState({})
    const [isCreateIntakeModalOpen, setCreateIntakeModalOpen] = useState(false)
    const [intakeModalData, setIntakeModalData] = useState(null)

    //Alert
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [creditWarningMessageOpen, setCreditWarningMessageOpen] = useState(false)

    useEffect(() => {
        //testapi("c0f70af5-0a01-493b-b4a1-1e3d4004e2fa")
        console.log(pageNumParam)
        var parallelAppointmentPromises
        if(currentIndex === 0){
            parallelAppointmentPromises = [
                getAppointmentsByStatus(currentHospitalID, "PENDING", pageNumParam),
                getAppointmentsByStatus(currentHospitalID, "CONFIRMED", 1),
                getAppointmentsByStatus(currentHospitalID, "COMPLETED", 1),
                getAppointmentsByStatus(currentHospitalID, "DECLINED", 1),
            ]
        } else if(currentIndex === 1){
            parallelAppointmentPromises = [
                getAppointmentsByStatus(currentHospitalID, "PENDING", 1),
                getAppointmentsByStatus(currentHospitalID, "CONFIRMED", pageNumParam),
                getAppointmentsByStatus(currentHospitalID, "COMPLETED", 1),
                getAppointmentsByStatus(currentHospitalID, "DECLINED", 1),
            ]
        } else if(currentIndex === 2){
            parallelAppointmentPromises = [
                getAppointmentsByStatus(currentHospitalID, "PENDING", 1),
                getAppointmentsByStatus(currentHospitalID, "CONFIRMED", 1),
                getAppointmentsByStatus(currentHospitalID, "COMPLETED", pageNumParam),
                getAppointmentsByStatus(currentHospitalID, "DECLINED", 1),
            ]
        } else {
            parallelAppointmentPromises = [
                getAppointmentsByStatus(currentHospitalID, "PENDING", 1),
                getAppointmentsByStatus(currentHospitalID, "CONFIRMED", 1),
                getAppointmentsByStatus(currentHospitalID, "COMPLETED", 1),
                getAppointmentsByStatus(currentHospitalID, "DECLINED", pageNumParam),
            ]
        }

        Promise.all(parallelAppointmentPromises).then(parallelResponses => {
            console.log(parallelResponses[0])
            const pendingAppointments = parallelResponses[0].data.results
            const confirmedAppointments = parallelResponses[1].data.results
            const completedAppointments = parallelResponses[2].data.results
            const declinedAppointments = parallelResponses[3].data.results
            setPendingAppointmentData(pendingAppointments)
            setConfirmedAppointmentData(confirmedAppointments)
            setCompletedAppointmentData(completedAppointments)
            setDeclinedAppointmentData(declinedAppointments)

            setPendingPaginationData(parallelResponses[0].data)
            setConfirmedPaginationData(parallelResponses[1].data)
            setCompletedPaginationData(parallelResponses[2].data)
            setDeclinedPaginationData(parallelResponses[3].data)

            const allAppointments = pendingAppointments.concat(confirmedAppointments, completedAppointments, declinedAppointments)
            const parallelIntakePromises = []
            const tempAppointmentIntakeDictionary = {}
            allAppointments.forEach(apt => {
                parallelIntakePromises.push(getIntakeByID(currentHospitalID, apt.external_id))
            })
            Promise.all(parallelIntakePromises).then(parallelResponses => {
                parallelResponses.forEach(response => {
                    if(response.status >= 200 && response.status < 300 && response.data.results.length === 1){
                        tempAppointmentIntakeDictionary[response.data.results[0].appointment_id] = response.data.results[0]
                    }
                })
                setAppointmentIntakeDictionary(tempAppointmentIntakeDictionary)
                console.log("Dictionary: ",tempAppointmentIntakeDictionary )
            })
        })

        getAccountInfo().then(res => {
            if (res.status === 200) {
                dispatch(setAccountInfo(res.data))
                const hospitalList = res.hospital
                setAccountData(res)
                var promises = []
                hospitalList.forEach(hID => {
                    promises.push(getHospitalInfoByInternalID(hID))
                });

                Promise.all(promises).then(results => {
                    const tempHospitalList = []
                    results.forEach(res => tempHospitalList.push(res.data))
                    setHospitalData(tempHospitalList)
                    const tempHospitalData = findObjectById(currentHospitalID, tempHospitalList)
                    setCurrentHospitalData(tempHospitalData)
                    setCurrentIndex(tabParam)
                    setPageLoading(false)
                })
            }
        })

    }, [appointmentParam, refresh]);

    const toggleAlertWithMessage = (message, withDuration) => {
        setAlertMessage(message)
        setShowAlert(true)
    }

    const renderNoAppointmentMessage = () => {
        return (
            <div className="apt-no-appointment-message">
                <img alt="Logo" src={achew_logo_side} className='achew-header-logo'/>
                <h1>No appointments found with this status</h1>
            </div>
        )
    }

    const renderCreateAppointmentButton = () => {
        return (
            <button className="apt-create-apppointment-button" onClick={() => setCreateAppointmentModalOpen(true)}>
                <i className="fas fa-plus" style={{marginRight:'10px'}}></i>
                Create Appointment
            </button>
        )
    }

    const handleTabPressed = (index) => {
        navigate(`/appointment-manager?hospital=${currentHospitalID}&tab=${index}&page_num=${1}`)
        setCurrentIndex(index)
    }

    if (isPageLoading) {
        return (<Loading/>)
    }
    return (
        <div className='apt-grey-supercontainer'>
            <CreditWarningPopup isOpen={creditWarningMessageOpen} onClose={()=>setCreditWarningMessageOpen(false)}/>
            <PopupAlert showAlert={showAlert} setShowAlert={setShowAlert} alertMessage={alertMessage} />
            {isCreateIntakeModalOpen && <CreateIntakeModal appointmentIntakeDictionary={appointmentIntakeDictionary} setAppointmentIntakeDictionary={setAppointmentIntakeDictionary} intakeModalData={intakeModalData} currentHospitalData={currentHospitalData} onRequestClose={() => setCreateIntakeModalOpen(false)} toggleAlertWithMessage={toggleAlertWithMessage}/>}
            <CreateAppointmentModal hospitalData={currentHospitalData} isCreateAppointmentModalOpen={isCreateAppointmentModalOpen} setCreateAppointmentModalOpen={setCreateAppointmentModalOpen}/>
            <DoctorHeader hospitalData={hospitalData} currentHospitalData={currentHospitalData} />
            <DoctorSideBar currentTab={2} hospitalData={hospitalData} currentHospitalData={currentHospitalData} />
            <div className="apt-supercontainer">
                <div className="apt-status-pickers">
                    <button onClick={() => handleTabPressed(0)} className={currentIndex === 0 ? "apt-status-pickers-selected" : ""}>
                        Pending Confirmation ({pendingPaginationData.totalCount})
                    </button>
                    <button onClick={() => handleTabPressed(1)} className={currentIndex === 1 ? "apt-status-pickers-selected" : ""}>
                        Confirmed ({confirmedPaginationData.totalCount})
                    </button>
                    <button onClick={() => handleTabPressed(2)} className={currentIndex === 2 ? "apt-status-pickers-selected" : ""}>
                        Completed ({completedPaginationData.totalCount})
                    </button>
                    <button onClick={() => handleTabPressed(3)} className={currentIndex === 3 ? "apt-status-pickers-selected" : ""}>
                        Declined ({declinedPaginationData.totalCount})
                    </button>
                </div>
                <div className="appointment-view-list">
                    {currentIndex === 0 && <div className="appointment-view-list-group">
                        <div className="appointment-view-list-group-title-group">
                            <p className="appointment-view-list-title">
                                Pending Response
                            </p>
                            {renderCreateAppointmentButton()}
                             
                        </div>
                        <div className="appointment-view-list-views">
                            {pendingAppointmentData.length > 0 ? pendingAppointmentData.map((appointmentData, index) => <AppointmentView accountData={accountData} setCreditWarningMessageOpen={setCreditWarningMessageOpen} setIntakeModalData={setIntakeModalData} setCreateIntakeModalOpen={setCreateIntakeModalOpen} appointmentIntakeDictionary={appointmentIntakeDictionary} appointmentData={appointmentData} hospitalData={currentHospitalData} index={index} isFromNotification={appointmentData.external_id === expandedAppointmentId}/>) : renderNoAppointmentMessage()}
                        </div>
                        {pendingPaginationData.totalCount > 0 && <div className="appointment-view-list-pagination-container">
                            <AppointmentPagination paginationData={pendingPaginationData} currentHospitalID={currentHospitalID} tab={currentIndex} setRefresh={setRefresh} refresh={refresh}/>
                        </div>}
                                            
                    </div>}
                    {currentIndex === 1 && <div className="appointment-view-list-group">
                        <div className="appointment-view-list-group-title-group">
                            <p className="appointment-view-list-title">
                                Confirmed
                            </p>
                            {renderCreateAppointmentButton()}
                        </div>
                        <div className="appointment-view-list-views">
                            {confirmedAppointmentData.length > 0 ? confirmedAppointmentData.map((appointmentData, index) => <AppointmentView accountData={accountData} setCreditWarningMessageOpen={setCreditWarningMessageOpen} setIntakeModalData={setIntakeModalData} setCreateIntakeModalOpen={setCreateIntakeModalOpen} appointmentIntakeDictionary={appointmentIntakeDictionary} appointmentData={appointmentData} hospitalData={currentHospitalData} index={index} isFromNotification={appointmentData.external_id === expandedAppointmentId}/>) : renderNoAppointmentMessage()}
                        </div>
                        {confirmedPaginationData.totalCount > 0 && <div className="appointment-view-list-pagination-container">
                            <AppointmentPagination paginationData={confirmedPaginationData} currentHospitalID={currentHospitalID} tab={currentIndex} setRefresh={setRefresh} refresh={refresh}/>
                        </div>}       
                    </div>}
                    {currentIndex === 2 && <div className="appointment-view-list-group">
                        <div className="appointment-view-list-group-title-group">
                            <p className="appointment-view-list-title">
                                Completed
                            </p>
                            {renderCreateAppointmentButton()}
                        </div>
                        <div className="appointment-view-list-views">
                            {completedAppointmentData.length > 0 ? completedAppointmentData.map((appointmentData, index) => <AppointmentView accountData={accountData} setCreditWarningMessageOpen={setCreditWarningMessageOpen} setIntakeModalData={setIntakeModalData} setCreateIntakeModalOpen={setCreateIntakeModalOpen} appointmentIntakeDictionary={appointmentIntakeDictionary} appointmentData={appointmentData} hospitalData={currentHospitalData} index={index} isFromNotification={appointmentData.external_id === expandedAppointmentId}/>) : renderNoAppointmentMessage()}
                        </div>
                        {completedPaginationData.totalCount > 0 && <div className="appointment-view-list-pagination-container">
                            <AppointmentPagination paginationData={completedPaginationData} currentHospitalID={currentHospitalID} tab={currentIndex} setRefresh={setRefresh} refresh={refresh}/>
                        </div>}
                    </div>}
                    {currentIndex === 3 && <div className="appointment-view-list-group">
                        <div className="appointment-view-list-group-title-group">
                            <p className="appointment-view-list-title">
                                Declined
                            </p>
                            {renderCreateAppointmentButton()}
                        </div>
                        <div className="appointment-view-list-views">
                            {declinedAppointmentData.length > 0 ? declinedAppointmentData.map((appointmentData, index) => <AppointmentView accountData={accountData} setCreditWarningMessageOpen={setCreditWarningMessageOpen} setIntakeModalData={setIntakeModalData} setCreateIntakeModalOpen={setCreateIntakeModalOpen} appointmentIntakeDictionary={appointmentIntakeDictionary} appointmentData={appointmentData} hospitalData={currentHospitalData} index={index} isFromNotification={appointmentData.external_id === expandedAppointmentId}/>) : renderNoAppointmentMessage()}
                        </div>
                        {declinedPaginationData.totalCount > 0 && <div className="appointment-view-list-pagination-container">
                            <AppointmentPagination paginationData={declinedPaginationData} currentHospitalID={currentHospitalID} tab={currentIndex} setRefresh={setRefresh} refresh={refresh}/>
                        </div>}
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default AppointmentManager