

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { arrow_top_right } from './image';

const SpecialtyCarousel = ({title, description, imageObject, navLink}) => {
    const navigate = useNavigate()
  return (
    <div className="home-specialties-carousel" onClick={() => navigate(navLink)}>
        <div>
            <h1>{title}</h1>
            <h2>{description}</h2>
        </div>
        
        <div className="home-specialties-carousel-images">
            <div className="home-specialties-img-container">
                <img alt="arrow" src={arrow_top_right}/>    
            </div>
            
            <img alt="specialty" src={imageObject} className='home-specialties-carousel-specialty-img'/>
        </div>
        
    </div>
  );
};

export default SpecialtyCarousel;
