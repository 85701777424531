import React, { useRef } from "react";

const TermsAndAgreements = () => {

    const ourServicesRef = useRef(null);
    const intellectualPropertyRightsRef = useRef(null);
    const userRepresentationsRef = useRef(null);
    const userRegistrationRef = useRef(null);
    const purchasesAndPaymentRef = useRef(null);
    const prohibitedActivitiesRef = useRef(null);
    const userGeneratedContributionsRef = useRef(null);
    const contributionLicenseRef = useRef(null);
    const guidelinesForReviewsRef = useRef(null);
    const thirdPartyWebsitesAndContentRef = useRef(null);
    const advertisersRef = useRef(null);
    const servicesManagementRef = useRef(null);
    const privacyPolicyRef = useRef(null);
    const dmcaNoticeAndPolicyRef = useRef(null);
    const termAndTerminationRef = useRef(null);
    const modificationsAndInterruptionsRef = useRef(null);
    const governingLawRef = useRef(null);
    const disputeResolutionRef = useRef(null);
    const correctionsRef = useRef(null);
    const disclaimerRef = useRef(null);
    const limitationsOfLiabilityRef = useRef(null);
    const indemnificationRef = useRef(null);
    const userDataRef = useRef(null);
    const electronicCommunicationsTransactionsAndSignaturesRef = useRef(null);
    const smsTextMessagingRef = useRef(null);
    const californiaUsersAndResidentsRef = useRef(null);
    const miscellaneousRef = useRef(null);
    const contactUsRef = useRef(null);

    const scrollToRef = (ref) => {
        if (ref && ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }

    return (
        <div className="tac-container">
            <h1>Achew Terms and Conditions</h1>
            <h5>Last updated April 08, 2024</h5>
            <h2>Agreement to our Legal Terms</h2>
            <h4>We are Achew Inc. ("<b>Company</b>," "<b>we</b>," "<b>us</b>," "<b>our</b>"), a company registered in
 California, United States at 420 Elizabeth Ave Unit B, Apt B, Monterey Park, CA
 91755.</h4>
 <h4>We operate the website https://www.achew.net (the "<b>Site</b>"), as well as any other
 related products and services that refer or link to these legal terms (the "<b>Legal Terms</b>") (collectively, the "Services").</h4>
 <h4>Achew revolutionizes the way individuals access and manage healthcare by offering
 a seamless, free platform to discover and book top-tier healthcare services tailored to
 their needs. This innovative platform provides comprehensive details on clinics and
 doctors throughout the United States, ensuring users can make informed decisions.
 With Achew, users experience the convenience of swift appointment scheduling,
 effortless modifications or cancellations, and the added benefit of receiving reminders
 for upcoming appointments via text and email. This holistic approach not only
 streamlines the healthcare process but also empowers users to stay engaged and
 informed about their healthcare journey. Achew offers complimentary hosting for
 clinic and doctor profile pages, extending an invitation to healthcare practices
 nationwide to amplify their online presence. By crafting a detailed profile accessible
 to all Achew users, practices can significantly enhance their visibility on the internet.
 Additionally, Achew provides sophisticated scheduling and appointment software
 solutions, enabling practices to effortlessly open and manage appointment slots. This
 seamless integration facilitates efficient organization and management of
 appointments, streamlining the process for both healthcare providers and their
 patients.</h4>
 <h4>You can contact us by phone at  (626) 788-0644, email at
 jasonzhao1562@achew.net, or by mail to 420 Elizabeth Ave Unit B, Apt B, Monterey
 Park, CA 91755, United States.</h4>
 <h4>These Legal Terms constitute a legally binding agreement made between you,
 whether personally or on behalf of an entity ("<b>you</b>"), and Achew Inc, concerning
 your access to and use of the Services. You agree that by accessing the Services,
 you have read, understood, and agreed to be bound by all of these Legal Terms. IF
 YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE
 EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
 DISCONTINUE USE IMMEDIATELY</h4>
 <h4>We will provide you with prior notice of any scheduled changes to the Services you
 are using. The modified Legal Terms will become effective upon posting or notifying
 you by info@achew.net, as stated in the email message. By continuing to use the
 Services after the effective date of any changes, you agree to be bound by the
 modified terms.</h4>
 <h4>The Services are intended for users who are at least 13 years of age. All users who
 are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian
 to use the Services. If you are a minor, you must have your parent or guardian read
 and agree to these Legal Terms prior to you using the Services.</h4>
 <h4>We recommend that you print a copy of these Legal Terms for your records.</h4>
 <h2>Table of Contents</h2>
 <h6 onClick={() => scrollToRef(ourServicesRef)}>Our Services</h6>
<h6 onClick={() => scrollToRef(intellectualPropertyRightsRef)}>Intellectual Property Rights</h6>
<h6 onClick={() => scrollToRef(userRepresentationsRef)}>User Representations</h6>
<h6 onClick={() => scrollToRef(userRegistrationRef)}>User Registration</h6>
<h6 onClick={() => scrollToRef(purchasesAndPaymentRef)}>Purchases And Payment</h6>
<h6 onClick={() => scrollToRef(prohibitedActivitiesRef)}>Prohibited Activities</h6>
<h6 onClick={() => scrollToRef(userGeneratedContributionsRef)}>User Generated Contributions</h6>
<h6 onClick={() => scrollToRef(contributionLicenseRef)}>Contribution License</h6>
<h6 onClick={() => scrollToRef(guidelinesForReviewsRef)}>Guidelines For Reviews</h6>
<h6 onClick={() => scrollToRef(thirdPartyWebsitesAndContentRef)}>Third-Party Websites And Content</h6>
<h6 onClick={() => scrollToRef(advertisersRef)}>Advertisers</h6>
<h6 onClick={() => scrollToRef(servicesManagementRef)}>Services Management</h6>
<h6 onClick={() => scrollToRef(privacyPolicyRef)}>Privacy Policy</h6>
<h6 onClick={() => scrollToRef(dmcaNoticeAndPolicyRef)}>DMCA Notice And Policy</h6>
<h6 onClick={() => scrollToRef(termAndTerminationRef)}>Term And Termination</h6>
<h6 onClick={() => scrollToRef(modificationsAndInterruptionsRef)}>Modifications And Interruptions</h6>
<h6 onClick={() => scrollToRef(governingLawRef)}>Governing Law</h6>
<h6 onClick={() => scrollToRef(disputeResolutionRef)}>Dispute Resolution</h6>
<h6 onClick={() => scrollToRef(correctionsRef)}>Corrections</h6>
<h6 onClick={() => scrollToRef(disclaimerRef)}>Disclaimer</h6>
<h6 onClick={() => scrollToRef(limitationsOfLiabilityRef)}>Limitations Of Liability</h6>
<h6 onClick={() => scrollToRef(indemnificationRef)}>Indemnification</h6>
<h6 onClick={() => scrollToRef(userDataRef)}>User Data</h6>
<h6 onClick={() => scrollToRef(electronicCommunicationsTransactionsAndSignaturesRef)}>Electronic Communications, Transactions, And Signatures</h6>
<h6 onClick={() => scrollToRef(smsTextMessagingRef)}>SMS Text Messaging</h6>
<h6 onClick={() => scrollToRef(californiaUsersAndResidentsRef)}>California Users And Residents</h6>
<h6 onClick={() => scrollToRef(miscellaneousRef)}>Miscellaneous</h6>
<h6 onClick={() => scrollToRef(contactUsRef)}>Contact Us</h6>

<h2 ref={ourServicesRef}>1. Our Services</h2>
<h4> The information provided when using the Services is not intended for distribution to
 or use by any person or entity in any jurisdiction or country where such distribution or
 use would be contrary to law or regulation or which would subject us to any
 registration requirement within such jurisdiction or country. Accordingly, those
 persons who choose to access the Services from other locations do so on their own
 initiative and are solely responsible for compliance with local laws, if and to the extent
 local laws are applicable.</h4>
<h4> The Services are not tailored to comply with industry-specific regulations (Health
 Insurance Portability and Accountability Act (HIPAA), Federal Information Security
 Management Act (FISMA), etc.), so if your interactions would be subjected to such
 laws, you may not use the Services. You may not use the Services in a way that
 would violate the Gramm-Leach-Bliley Act (GLBA).</h4>
<h2 ref={intellectualPropertyRightsRef}>2. Intellectual Property Rights</h2>
<h3>Our intellectual property</h3>
<h4>We are the owner or the licensee of all intellectual property rights in our Services,
 including all source code, databases, functionality, software, website designs, audio,
 video, text, photographs, and graphics in the Services (collectively, the "Content"), as
 well as the trademarks, service marks, and logos contained therein (the "Marks").</h4>
<h4>Our Content and Marks are protected by copyright and trademark laws (and various
 other intellectual property rights and unfair competition laws) and treaties in the
 United States and around the world.</h4>
<h4>The Content and Marks are provided in or through the Services "AS IS" for your
 personal, non-commercial use or internal business purpose only.</h4>
<h4>Subject to your compliance with these Legal Terms, including the "PROHIBITED
 ACTIVITIES" section below, we grant you a non-exclusive, non-transferable,
 revocable license to:</h4>
<p>access the Services; and</p>
<p>download or print a copy of any portion of the Content to which you have
 properly gained access.</p>
 <h4>solely for your personal, non-commercial use or internal business purpose.</h4>
 <h4> Except as set out in this section or elsewhere in our Legal Terms, no part of the
 Services and no Content or Marks may be copied, reproduced, aggregated,
 republished, uploaded, posted, publicly displayed, encoded, translated, transmitted,
 distributed, sold, licensed, or otherwise exploited for any commercial purpose
 whatsoever, without our express prior written permission.</h4>
 <h4>If you wish to make any use of the Services, Content, or Marks other than as set out
 in this section or elsewhere in our Legal Terms, please address your request to:
 jasonzhao1562@achew.net. If we ever grant you the permission to post, reproduce,
 or publicly display any part of our Services or Content, you must identify us as the
 owners or licensors of the Services, Content, or Marks and ensure that any copyright
 or proprietary notice appears or is visible on posting, reproducing, or displaying our
 Content.</h4>
 <h4>We reserve all rights not expressly granted to you in and to the Services, Content,
 and Marks.</h4>
 <h4> Any breach of these Intellectual Property Rights will constitute a material breach of
 our Legal Terms and your right to use our Services will terminate immediately.</h4>
<h3> Your submissions and contributions</h3>
<h4>Please review this section and the "<h6>PROHIBITED ACTIVITIES</h6>" section carefully prior
 to using our Services to understand the (a) rights you give us and (b) obligations you
 have when you post or upload any content through the Services.</h4>
 <h4>Submissions: By directly sending us any question, comment, suggestion, idea,
 feedback, or other information about the Services ("<b>Submissions</b>"), you agree to
 assign to us all intellectual property rights in such Submission. You agree that we
 shall own this Submission and be entitled to its unrestricted use and dissemination
 for any lawful purpose, commercial or otherwise, without acknowledgment or
 compensation to you.</h4>
 <h4> Contributions: The Services may invite you to chat, contribute to, or participate in
 blogs, message boards, online forums, and other functionality during which you may
 create, submit, post, display, transmit, publish, distribute, or broadcast content and
 materials to us or through the Services, including but not limited to text, writings,
 video, audio, photographs, music, graphics, comments, reviews, rating suggestions,
 personal information, or other material ("<b>Contributions</b>"). Any Submission that is
 publicly posted shall also be treated as a Contribution.</h4>
 <h4> You understand that Contributions may be viewable by other users of the Services
 and possibly through third-party websites.</h4>
 <h4>When you post Contributions, you grant us a license (including use of your
 name, trademarks, and logos): By posting any Contributions, you grant us an
 unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty
free, fully-paid, worldwide right, and license to: use, copy, reproduce, distribute, sell,
 resell, publish, broadcast, retitle, store, publicly perform, publicly display, reformat,
 translate, excerpt (in whole or in part), and exploit your Contributions (including,
 without limitation, your image, name, and voice) for any purpose, commercial,
 advertising, or otherwise, to prepare derivative works of, or incorporate into other
 works, your Contributions, and to sublicense the licenses granted in this section. Our
 use and distribution may occur in any media formats and through any media
 channels.</h4>
 <h4> This license includes our use of your name, company name, and franchise name, as
 applicable, and any of the trademarks, service marks, trade names, logos, and
 personal and commercial images you provide.</h4>
 <h4> You are responsible for what you post or upload: By sending us Submissions
 and/or posting Contributions through any part of the Services or making
 Contributions accessible through the Services by linking your account through the
 Services to any of your social networking accounts, you:</h4>
 <p>confirm that you have read and agree with our "<h6>PROHIBITED ACTIVITIES</h6>"
 and will not post, send, publish, upload, or transmit through the Services any
 Submission nor post any Contribution that is illegal, harassing, hateful,
 harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to
 any person or group, sexually explicit, false, inaccurate, deceitful, or
 misleading;</p>
 <p>to the extent permissible by applicable law, waive any and all moral rights to
 any such Submission and/or Contribution;</p>
 <p>warrant that any such Submission and/or Contributions are original to you or
 that you have the necessary rights and licenses to submit such Submissions
 and/or Contributions and that you have full authority to grant us the above
mentioned rights in relation to your Submissions and/or Contributions; and</p>
 <p>warrant and represent that your Submissions and/or Contributions do not
 constitute confidential information.</p>
 <h4>You are solely responsible for your Submissions and/or Contributions and you
 expressly agree to reimburse us for any and all losses that we may suffer because of
 your breach of (a) this section, (b) any third party’s intellectual property rights, or (c)
 applicable law.</h4>
 <h4> We may remove or edit your Content: Although we have no obligation to monitor
 any Contributions, we shall have the right to remove or edit any Contributions at any
 time without notice if in our reasonable opinion we consider such Contributions
 harmful or in breach of these Legal Terms. If we remove or edit any such
 Contributions, we may also suspend or disable your account and report you to the
 authorities.</h4>
 <h2 ref={userRepresentationsRef}>3. User Representations</h2>
 <h4>By using the Services, you represent and warrant that: (1) all registration information
 you submit will be true, accurate, current, and complete; (2) you will maintain the
 accuracy of such information and promptly update such registration information as
 necessary; (3) you have the legal capacity and you agree to comply with these Legal
 Terms; (4) you are not under the age of 13; (5) you are not a minor in the jurisdiction
 in which you reside, or if a minor, you have received parental permission to use the
 Services; (6) you will not access the Services through automated or non-human
 means, whether through a bot, script or otherwise; (7) you will not use the Services
 for any illegal or unauthorized purpose; and (8) your use of the Services will not
 violate any applicable law or regulation.</h4>
 <h4> If you provide any information that is untrue, inaccurate, not current, or incomplete,
 we have the right to suspend or terminate your account and refuse any and all
 current or future use of the Services (or any portion thereof).</h4>
 <h2 ref={userRegistrationRef}>4. User Registration</h2>
 You may be required to register to use the Services. You agree to keep your
 password confidential and will be responsible for all use of your account and
 password. We reserve the right to remove, reclaim, or change a username you select
 if we determine, in our sole discretion, that such username is inappropriate, obscene,
 or otherwise objectionable.
<h2 ref={purchasesAndPaymentRef}>5. Purchases And Payment</h2>
<h4> We accept the following forms of payment:-  -  -  -  -  
Visa
 Mastercard
 American Express
 Discover
 PayPal</h4>
 <h4>You agree to provide current, complete, and accurate purchase and account
 information for all purchases made via the Services. You further agree to promptly
 update account and payment information, including email address, payment method,
 and payment card expiration date, so that we can complete your transactions and
 contact you as needed. Sales tax will be added to the price of purchases as deemed
 required by us. We may change prices at any time. All payments shall be in US
 dollars.</h4>
 <h4>You agree to pay all charges at the prices then in effect for your purchases and any
 applicable shipping fees, and you authorize us to charge your chosen payment
 provider for any such amounts upon placing your order. We reserve the right to
 correct any errors or mistakes in pricing, even if we have already requested or
 received payment.</h4>
 <h4> We reserve the right to refuse any order placed through the Services. We may, in our
 sole discretion, limit or cancel quantities purchased per person, per household, or per
 order. These restrictions may include orders placed by or under the same customer
 account, the same payment method, and/or orders that use the same billing or
 shipping address. We reserve the right to limit or prohibit orders that, in our sole
 judgment, appear to be placed by dealers, resellers, or distributors.</h4>
<h2 ref={prohibitedActivitiesRef}>6. Prohibited Activities</h2>
<h4>You may not access or use the Services for any purpose other than that for which we
 make the Services available. The Services may not be used in connection with any
 commercial endeavors except those that are specifically endorsed or approved by
 us.</h4>
<h4> As a user of the Services, you agree not to:</h4>
<p>Systematically retrieve data or other content from the Services to create or
 compile, directly or indirectly, a collection, compilation, database, or directory
 without written permission from us.</p>
<p>Trick, defraud, or mislead us and other users, especially in any attempt to learn
 sensitive account information such as user passwords.</p>
<p> Circumvent, disable, or otherwise interfere with security-related features of the
 Services, including features that prevent or restrict the use or copying of any
 Content or enforce limitations on the use of the Services and/or the Content
 contained therein.</p>
<p> Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</p>
<p>Use any information obtained from the Services in order to harass, abuse, or
 harm another person.</p>
<p>Make improper use of our support services or submit false reports of abuse or
 misconduct.</p>
<p>Use the Services in a manner inconsistent with any applicable laws or
 regulations.</p>
<p> Engage in unauthorized framing of or linking to the Services.</p>
<p>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses,
 or other material, including excessive use of capital letters and spamming
 (continuous posting of repetitive text), that interferes with any party’s
 uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts,
 alters, or interferes with the use, features, functions, operation, or maintenance
 of the Services.</p>
<p>Engage in any automated use of the system, such as using scripts to send
 comments or messages, or using any data mining, robots, or similar data
 gathering and extraction tools.</p>
<p>Delete the copyright or other proprietary rights notice from any Content.</p>
<p>Attempt to impersonate another user or person or use the username of
 another user.</p>
<p>Upload or transmit (or attempt to upload or to transmit) any material that acts
 as a passive or active information collection or transmission mechanism,
 including without limitation, clear graphics interchange formats ("<b>gif</b>"), 1×1
 pixels, web bugs, cookies, or other similar devices (sometimes referred to as
 "spyware" or "passive collection mechanisms" or "pcms").</p>
<p>Interfere with, disrupt, or create an undue burden on the Services or the
 networks or services connected to the Services.</p>
<p>Harass, annoy, intimidate, or threaten any of our employees or agents
 engaged in providing any portion of the Services to you.</p>
<p> Attempt to bypass any measures of the Services designed to prevent or
 restrict access to the Services, or any portion of the Services.</p>
<p>Copy or adapt the Services' software, including but not limited to Flash, PHP,
 HTML, JavaScript, or other code.</p>
<p>Except as permitted by applicable law, decipher, decompile, disassemble, or
 reverse engineer any of the software comprising or in any way making up a
 part of the Services.</p>
<p>Except as may be the result of standard search engine or Internet browser
 usage, use, launch, develop, or distribute any automated system, including
 without limitation, any spider, robot, cheat utility, scraper, or offline reader that
 accesses the Services, or use or launch any unauthorized script or other
 software.</p>
<p>Use a buying agent or purchasing agent to make purchases on the Services.</p>
<p>Make any unauthorized use of the Services, including collecting usernames
 and/or email addresses of users by electronic or other means for the purpose
 of sending unsolicited email, or creating user accounts by automated means or
 under false pretenses.</p>
<p>Use the Services as part of any effort to compete with us or otherwise use the
 Services and/or the Content for any revenue-generating endeavor or
 commercial enterprise.</p>
<h2 ref={userGeneratedContributionsRef}>7. User Generated Contributions</h2>
<h4> The Services may invite you to chat, contribute to, or participate in blogs, message
 boards, online forums, and other functionality, and may provide you with the
 opportunity to create, submit, post, display, transmit, perform, publish, distribute, or
 broadcast content and materials to us or on the Services, including but not limited to
 text, writings, video, audio, photographs, graphics, comments, suggestions, or
 personal information or other material (collectively, "Contributions"). Contributions
 may be viewable by other users of the Services and through third-party websites. As
 such, any Contributions you transmit may be treated as non-confidential and non
proprietary. When you create or make available any Contributions, you thereby
 represent and warrant that:</h4>
 <p>The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.</p>
<p>You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Services, and other users of the Services to use your Contributions in any manner contemplated by the Services and these Legal Terms.</p>
<p>You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Services and these Legal Terms.</p>
<p>Your Contributions are not false, inaccurate, or misleading.</p>
<p>Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.</p>
<p>Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).</p>
<p>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</p>
<p>Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.</p>
<p>Your Contributions do not violate any applicable law, regulation, or rule.</p>
<p>Your Contributions do not violate the privacy or publicity rights of any third party.</p>
<p>Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.</p>
<p>Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.</p>
<p>Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal Terms, or any applicable law or regulation.</p>

<h4> Any use of the Services in violation of the foregoing violates these Legal Terms and
 may result in, among other things, termination or suspension of your rights to use the
 Services.</h4>
<h2 ref={contributionLicenseRef}>8. Contribution License</h2>
<h4>By posting your Contributions to any part of the Services, you automatically grant,
 and you represent and warrant that you have the right to grant, to us an unrestricted,
 unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid,
 worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell,
 publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display,
 reformat, translate, transmit, excerpt (in whole or in part), and distribute such
 Contributions (including, without limitation, your image and voice) for any purpose,
 commercial, advertising, or otherwise, and to prepare derivative works of, or
 incorporate into other works, such Contributions, and grant and authorize
 sublicenses of the foregoing. The use and distribution may occur in any media
 formats and through any media channels.</h4>
<h4> This license will apply to any form, media, or technology now known or hereafter
 developed, and includes our use of your name, company name, and franchise name,
 as applicable, and any of the trademarks, service marks, trade names, logos, and
 personal and commercial images you provide. You waive all moral rights in your
 Contributions, and you warrant that moral rights have not otherwise been asserted in
 your Contributions.</h4>
<h4>We do not assert any ownership over your Contributions. You retain full ownership of
 all of your Contributions and any intellectual property rights or other proprietary rights
 associated with your Contributions. We are not liable for any statements or
 representations in your Contributions provided by you in any area on the Services.
 You are solely responsible for your Contributions to the Services and you expressly
 agree to exonerate us from any and all responsibility and to refrain from any legal
 action against us regarding your Contributions.</h4>
<h4>We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise
 change any Contributions; (2) to re-categorize any Contributions to place them in
 more appropriate locations on the Services; and (3) to pre-screen or delete any
 Contributions at any time and for any reason, without notice. We have no obligation
 to monitor your Contributions.</h4>
<h2 ref={guidelinesForReviewsRef}>9. Guidelines For Reviews</h2>
<h4>We may provide you areas on the Services to leave reviews or ratings. When posting
 a review, you must comply with the following criteria: (1) you should have firsthand
 experience with the person/entity being reviewed; (2) your reviews should not contain
 offensive profanity, or abusive, racist, offensive, or hateful language; (3) your reviews
 should not contain discriminatory references based on religion, race, gender, national
 origin, age, marital status, sexual orientation, or disability; (4) your reviews should not
 contain references to illegal activity; (5) you should not be affiliated with competitors if
 posting negative reviews; (6) you should not make any conclusions as to the legality
 of conduct; (7) you may not post any false or misleading statements; and (8) you may
 not organize a campaign encouraging others to post reviews, whether positive or
 negative.</h4>
<h4> We may accept, reject, or remove reviews in our sole discretion. We have absolutely
 no obligation to screen reviews or to delete reviews, even if anyone considers
 reviews objectionable or inaccurate. Reviews are not endorsed by us, and do not
 necessarily represent our opinions or the views of any of our affiliates or partners. We
 do not assume liability for any review or for any claims, liabilities, or losses resulting
 from any review. By posting a review, you hereby grant to us a perpetual, non
exclusive, worldwide, royalty-free, fully paid, assignable, and sublicensable right and license to reproduce, modify, translate, transmit by any means, display, perform,
 and/or distribute all content relating to review.</h4>
<h2 ref={thirdPartyWebsitesAndContentRef}>10. Third-Party Websites And Content</h2>
<h4> The Services may contain (or you may be sent via the Site) links to other websites
 ("<b>Third-Party Websites</b>") as well as articles, photographs, text, graphics, pictures,
 designs, music, sound, video, information, applications, software, and other content
 or items belonging to or originating from third parties ("<b>Third-Party Content</b>"). Such
 Third-Party Websites and Third-Party Content are not investigated, monitored, or
 checked for accuracy, appropriateness, or completeness by us, and we are not
 responsible for any Third-Party Websites accessed through the Services or any
 Third-Party Content posted on, available through, or installed from the Services,
 including the content, accuracy, offensiveness, opinions, reliability, privacy practices,
 or other policies of or contained in the Third-Party Websites or the Third-Party
 Content. Inclusion of, linking to, or permitting the use or installation of any Third-Party
 Websites or any Third-Party Content does not imply approval or endorsement thereof
 by us. If you decide to leave the Services and access the Third-Party Websites or to
 use or install any Third-Party Content, you do so at your own risk, and you should be
 aware these Legal Terms no longer govern. You should review the applicable terms
 and policies, including privacy and data gathering practices, of any website to which
 you navigate from the Services or relating to any applications you use or install from
 the Services. Any purchases you make through Third-Party Websites will be through
 other websites and from other companies, and we take no responsibility whatsoever
 in relation to such purchases which are exclusively between you and the applicable
 third party. You agree and acknowledge that we do not endorse the products or
 services offered on Third-Party Websites and you shall hold us blameless from any
 harm caused by your purchase of such products or services. Additionally, you shall
 hold us blameless from any losses sustained by you or harm caused to you relating
 to or resulting in any way from any Third-Party Content or any contact with Third
Party Websites.</h4>
<h2 ref={advertisersRef}>11. Advertisers</h2>
<h4>We allow advertisers to display their advertisements and other information in certain
 areas of the Services, such as sidebar advertisements or banner advertisements. We
 simply provide the space to place such advertisements, and we have no other
 relationship with advertisers.</h4>
<h2>12. Services Management</h2>
<h4>We reserve the right, but not the obligation, to: (1) monitor the Services for violations
 of these Legal Terms; (2) take appropriate legal action against anyone who, in our
 sole discretion, violates the law or these Legal Terms, including without limitation,
 reporting such user to law enforcement authorities; (3) in our sole discretion and
 without limitation, refuse, restrict access to, limit the availability of, or disable (to the
 extent technologically feasible) any of your Contributions or any portion thereof; (4) in
 our sole discretion and without limitation, notice, or liability, to remove from the
 Services or otherwise disable all files and content that are excessive in size or are in
 any way burdensome to our systems; and (5) otherwise manage the Services in a
 manner designed to protect our rights and property and to facilitate the proper
 functioning of the Services.</h4>
<h2 ref={privacyPolicyRef}>13. Privacy Policy</h2>
<h4>We care about data privacy and security. By using the Services, you agree to be
 bound by our Privacy Policy posted on the Services, which is incorporated into these
 Legal Terms. Please be advised the Services are hosted in the United States. If you
 access the Services from any other region of the world with laws or other
 requirements governing personal data collection, use, or disclosure that differ from
 applicable laws in the United States, then through your continued use of the
 Services, you are transferring your data to the United States, and you expressly
 consent to have your data transferred to and processed in the United States. Further,
 we do not knowingly accept, request, or solicit information from children or knowingly
 market to children. Therefore, in accordance with the U.S. Children’s Online Privacy
 Protection Act, if we receive actual knowledge that anyone under the age of 13 has
 provided personal information to us without the requisite and verifiable parental consent, we will delete that information from the Services as quickly as is reasonably
 practical.</h4>
<h2 ref={dmcaNoticeAndPolicyRef}>14. Digital Millennium Copyright Act (Dmca) Notice And Policy</h2>
<h3>Notifications</h3>
<h4>We respect the intellectual property rights of others. If you believe that any material
 available on or through the Services infringes upon any copyright you own or control,
 please immediately notify our Designated Copyright Agent using the contact
 information provided below (a "Notification"). A copy of your Notification will be sent to
 the person who posted or stored the material addressed in the Notification. Please be
 advised that pursuant to federal law you may be held liable for damages if you make
 material misrepresentations in a Notification. Thus, if you are not sure that material
 located on or linked to by the Services infringes your copyright, you should consider
 first contacting an attorney.</h4>
<h4> All Notifications should meet the requirements of DMCA 17 U.S.C. § 512(c)(3) and
 include the following information: (1) A physical or electronic signature of a person
 authorized to act on behalf of the owner of an exclusive right that is allegedly
 infringed; (2) identification of the copyrighted work claimed to have been infringed, or,
 if multiple copyrighted works on the Services are covered by the Notification, a
 representative list of such works on the Services; (3) identification of the material that
 is claimed to be infringing or to be the subject of infringing activity and that is to be
 removed or access to which is to be disabled, and information reasonably sufficient
 to permit us to locate the material; (4) information reasonably sufficient to permit us to
 contact the complaining party, such as an address, telephone number, and, if
 available, an email address at which the complaining party may be contacted; (5) a
 statement that the complaining party has a good faith belief that use of the material in
 the manner complained of is not authorized by the copyright owner, its agent, or the
 law; and (6) a statement that the information in the notification is accurate, and under
 penalty of perjury, that the complaining party is authorized to act on behalf of the
 owner of an exclusive right that is allegedly infringed upon.</h4>
 <h3>Counter Notification</h3>
 <h4>If you believe your own copyrighted material has been removed from the Services as
 a result of a mistake or misidentification, you may submit a written counter
 notification to [us/our Designated Copyright Agent] using the contact information
 provided below (a "Counter Notification"). To be an effective Counter Notification
 under the DMCA, your Counter Notification must include substantially the following:
 (1) identification of the material that has been removed or disabled and the location
 at which the material appeared before it was removed or disabled; (2) a statement
 that you consent to the jurisdiction of the Federal District Court in which your address
 is located, or if your address is outside the United States, for any judicial district in
 which we are located; (3) a statement that you will accept service of process from the
 party that filed the Notification or the party's agent; (4) your name, address, and
 telephone number; (5) a statement under penalty of perjury that you have a good
 faith belief that the material in question was removed or disabled as a result of a
 mistake or misidentification of the material to be removed or disabled; and (6) your
 physical or electronic signature.</h4>
 <h4>If you send us a valid, written Counter Notification meeting the requirements
 described above, we will restore your removed or disabled material, unless we first
 receive notice from the party filing the Notification informing us that such party has
 filed a court action to restrain you from engaging in infringing activity related to the
 material in question. Please note that if you materially misrepresent that the disabled
 or removed content was removed by mistake or misidentification, you may be liable
 for damages, including costs and attorney's fees. Filing a false Counter Notification
 constitutes perjury.</h4>
 <h4>Designated Copyright Agent
 Jason Zhao
 Attn: Copyright Agent
 420 Elizabeth Ave
 Monterey Park, CA 91755
 United States
 jasonzhao1562@achew.net</h4>
<h2 ref={termAndTerminationRef}>15. Term And Termination</h2>
<h4> These Legal Terms shall remain in full force and effect while you use the Services.
 WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE
 RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE
 OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING
 BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR
 FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
 REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL
 TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE
 YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR
 ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY
 TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</h4>
 <h4>If we terminate or suspend your account for any reason, you are prohibited from
 registering and creating a new account under your name, a fake or borrowed name,
 or the name of any third party, even if you may be acting on behalf of the third party.
 In addition to terminating or suspending your account, we reserve the right to take
 appropriate legal action, including without limitation pursuing civil, criminal, and
 injunctive redress.</h4>
<h2 ref={modificationsAndInterruptionsRef}>16. Modifications And Interruptions</h2>
<h4>We reserve the right to change, modify, or remove the contents of the Services at any
 time or for any reason at our sole discretion without notice. However, we have no
 obligation to update any information on our Services. We will not be liable to you or
 any third party for any modification, price change, suspension, or discontinuance of
 the Services.</h4>
<h4>We cannot guarantee the Services will be available at all times. We may experience
 hardware, software, or other problems or need to perform maintenance related to the
 Services, resulting in interruptions, delays, or errors. We reserve the right to change,
 revise, update, suspend, discontinue, or otherwise modify the Services at any time or
 for any reason without notice to you. You agree that we have no liability whatsoever
 for any loss, damage, or inconvenience caused by your inability to access or use the
 Services during any downtime or discontinuance of the Services. Nothing in these
 Legal Terms will be construed to obligate us to maintain and support the Services or
 to supply any corrections, updates, or releases in connection therewith.</h4>
<h2 ref={governingLawRef}>17. Governing Law</h2>
<h4> These Legal Terms and your use of the Services are governed by and construed in
 accordance with the laws of the State of California applicable to agreements made
 and to be entirely performed within the State of California, without regard to its
 conflict of law principles.</h4>
<h2 ref={disputeResolutionRef}>18. Dispute Resolution</h2>
<h3>Binding Arbitration</h3>
<h4> If the Parties are unable to resolve a Dispute through informal negotiations, the
 Dispute (except those Disputes expressly excluded below) will be finally and
 exclusively resolved by binding arbitration. YOU UNDERSTAND THAT WITHOUT
 THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE
 A JURY TRIAL. The arbitration shall be commenced and conducted under the
 Commercial Arbitration Rules of the American Arbitration Association ("<b>AAA</b>") and,
 where appropriate, the AAA’s Supplementary Procedures for Consumer Related
 Disputes ("<b>AAA Consumer Rules</b>"), both of which are available at the American
 Arbitration Association (AAA) website. Your arbitration fees and your share of
 arbitrator compensation shall be governed by the AAA Consumer Rules and, where
 appropriate, limited by the AAA Consumer Rules. If such costs are determined by the
 arbitrator to be excessive, we will pay all arbitration fees and expenses. The
 arbitration may be conducted in person, through the submission of documents, by
 phone, or online. The arbitrator will make a decision in writing, but need not provide a
 statement of reasons unless requested by either Party. The arbitrator must follow
 applicable law, and any award may be challenged if the arbitrator fails to do so.
 10/13
 https://app.termly.io/dashboard/website/dc5996e2-b0c4-43e9-90ff-cbd1562778d6/terms-of-service
4/11/24, 4:45 PM
 Termly
 Except where otherwise required by the applicable AAA rules or applicable law, the
 arbitration will take place in Los Angeles, California. Except as otherwise provided
 herein, the Parties may litigate in court to compel arbitration, stay proceedings
 pending arbitration, or to confirm, modify, vacate, or enter judgment on the award
 entered by the arbitrator</h4>
 <h4>If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute shall
 be commenced or prosecuted in the state and federal courts located in Los
 Angeles, California, and the Parties hereby consent to, and waive all defenses of lack
 of personal jurisdiction, and forum non conveniens with respect to venue and
 jurisdiction in such state and federal courts. Application of the United Nations
 Convention on Contracts for the International Sale of Goods and the Uniform
 Computer Information Transaction Act (UCITA) are excluded from these Legal Terms.</h4>
 <h4> In no event shall any Dispute brought by either Party related in any way to the
 Services be commenced more than one (1) years after the cause of action arose. If
 this provision is found to be illegal or unenforceable, then neither Party will elect to
 arbitrate any Dispute falling within that portion of this provision found to be illegal or
 unenforceable and such Dispute shall be decided by a court of competent jurisdiction
 within the courts listed for jurisdiction above, and the Parties agree to submit to the
 personal jurisdiction of that court.</h4>
 <h3>Restrictions</h3>
 <h4>The Parties agree that any arbitration shall be limited to the Dispute between the
 Parties individually. To the full extent permitted by law, (a) no arbitration shall be
 joined with any other proceeding; (b) there is no right or authority for any Dispute to
 be arbitrated on a class-action basis or to utilize class action procedures; and (c)
 there is no right or authority for any Dispute to be brought in a purported
 representative capacity on behalf of the general public or any other persons.</h4>
 <h3>Exceptions to Arbitration</h3>
 <h4> The Parties agree that the following Disputes are not subject to the above provisions
 concerning binding arbitration: (a) any Disputes seeking to enforce or protect, or
 concerning the validity of, any of the intellectual property rights of a Party; (b) any
 Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or
 unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be
 illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling
 within that portion of this provision found to be illegal or unenforceable and such
 Dispute shall be decided by a court of competent jurisdiction within the courts listed
 for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of
 that court.</h4>
<h2 ref={correctionsRef}>19. Corrections</h2>
<h4>There may be information on the Services that contains typographical errors,
 inaccuracies, or omissions, including descriptions, pricing, availability, and various
 other information. We reserve the right to correct any errors, inaccuracies, or
 omissions and to change or update the information on the Services at any time,
 without prior notice.</h4>
<h2 ref={disclaimerRef}>20. Disclaimer</h2>
<h4> THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
 AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO
 THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
 EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE
 THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
 MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON
INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT
 THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE
 CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE
 SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR
 ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND
 MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
 WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
 SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
 11/13
 https://app.termly.io/dashboard/website/dc5996e2-b0c4-43e9-90ff-cbd1562778d6/terms-of-service
4/11/24, 4:45 PM
 Termly
 SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
 FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
 CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
 VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO
 OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS
 OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR
 DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
 CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
 THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
 RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
 OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED
 WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY
 BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN
 ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
 YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS
 WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM
 OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
 EXERCISE CAUTION WHERE APPROPRIATE.</h4>
<h2 ref={limitationsOfLiabilityRef}>21. Limitations Of Liability</h2>
<h4>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
 LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
 CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
 DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
 OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE
 HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</h4>
<h2 ref={indemnificationRef}>22. Indemnification</h2>
<h4>You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
 affiliates, and all of our respective officers, agents, partners, and employees, from
 and against any loss, damage, liability, claim, or demand, including reasonable
 attorneys’ fees and expenses, made by any third party due to or arising out of: (1)
 your Contributions; (2) use of the Services; (3) breach of these Legal Terms; (4) any
 breach of your representations and warranties set forth in these Legal Terms; (5) your
 violation of the rights of a third party, including but not limited to intellectual property
 rights; or (6) any overt harmful act toward any other user of the Services with whom
 you connected via the Services. Notwithstanding the foregoing, we reserve the right,
 at your expense, to assume the exclusive defense and control of any matter for
 which you are required to indemnify us, and you agree to cooperate, at your
 expense, with our defense of such claims. We will use reasonable efforts to notify
 you of any such claim, action, or proceeding which is subject to this indemnification
 upon becoming aware of it.</h4>
<h2 ref={userDataRef}>23. User Data</h2>
<h4> We will maintain certain data that you transmit to the Services for the purpose of
 managing the performance of the Services, as well as data relating to your use of the
 Services. Although we perform regular routine backups of data, you are solely
 responsible for all data that you transmit or that relates to any activity you have
 undertaken using the Services. You agree that we shall have no liability to you for
 any loss or corruption of any such data, and you hereby waive any right of action
 against us arising from any such loss or corruption of such data.</h4>
<h2 ref={electronicCommunicationsTransactionsAndSignaturesRef}>24. Electronic Communications, Transactions, And Signatures</h2>
<h4> Visiting the Services, sending us emails, and completing online forms constitute
 electronic communications. You consent to receive electronic communications, and
 you agree that all agreements, notices, disclosures, and other communications we
 provide to you electronically, via email and on the Services, satisfy any legal
 requirement that such communication be in writing. YOU HEREBY AGREE TO THE
 USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
 RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
 RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
 SERVICES. You hereby waive any rights or requirements under any statutes,
 regulations, rules, ordinances, or other laws in any jurisdiction which require an
 12/13
 https://app.termly.io/dashboard/website/dc5996e2-b0c4-43e9-90ff-cbd1562778d6/terms-of-service
4/11/24, 4:45 PM
 Termly
 original signature or delivery or retention of non-electronic records, or to payments or
 the granting of credits by any means other than electronic means.</h4>
<h2 ref={smsTextMessagingRef}>25. Sms Text Messaging</h2>
<h3>Opting Out</h3>
<h4> If at any time you wish to stop receiving SMS messages from us, simply reply to the
 text with "STOP.” You may receive an SMS message confirming your opt out.</h4>
 <h3>Message and Data Rates</h3>
 <h4>Please be aware that message and data rates may apply to any SMS messages sent
 or received. The rates are determined by your carrier and the specifics of your mobile
 plan.</h4>
 <h3>Support</h3>
 <h4> If you have any questions or need assistance regarding our SMS communications,
 please email us at jasonzhao1562@achew.net or call at  (626) 788-0644.</h4>
<h2 ref={californiaUsersAndResidentsRef}>26. California Users And Residents</h2>
<h4>If any complaint with us is not satisfactorily resolved, you can contact the Complaint
 Assistance Unit of the Division of Consumer Services of the California Department of
 Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento,
 California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.</h4>
<h2 ref={miscellaneousRef}>27. Miscellaneous</h2>
<h4>These Legal Terms and any policies or operating rules posted by us on the Services
 or in respect to the Services constitute the entire agreement and understanding
 between you and us. Our failure to exercise or enforce any right or provision of these
 Legal Terms shall not operate as a waiver of such right or provision. These Legal
 Terms operate to the fullest extent permissible by law. We may assign any or all of
 our rights and obligations to others at any time. We shall not be responsible or liable
 for any loss, damage, delay, or failure to act caused by any cause beyond our
 reasonable control. If any provision or part of a provision of these Legal Terms is
 determined to be unlawful, void, or unenforceable, that provision or part of the
 provision is deemed severable from these Legal Terms and does not affect the
 validity and enforceability of any remaining provisions. There is no joint venture,
 partnership, employment or agency relationship created between you and us as a
 result of these Legal Terms or use of the Services. You agree that these Legal Terms
 will not be construed against us by virtue of having drafted them. You hereby waive
 any and all defenses you may have based on the electronic form of these Legal
 Terms and the lack of signing by the parties hereto to execute these Legal Terms.</h4>
<h2 ref={contactUsRef}>28. Contact Us</h2>
<h4> In order to resolve a complaint regarding the Services or to receive further
 information regarding use of the Services, please contact us at:
 Achew Inc.
 420 Elizabeth Ave Unit B
 Apt B
 Monterey Park, CA 91755
 United States
 Phone:  (626) 788-0644
 jasonzhao1562@achew.net</h4>
        </div>
    );
};

export default TermsAndAgreements;
