import React, { useState, useEffect, useRef } from "react";
import ReactToggle from 'react-toggle'
import Alert from "../../alert";
import "react-toggle/style.css"
import { useNavigate, useLocation } from "react-router-dom";
import DoctorHeader from "../doctorheader";
import DoctorSideBar from "./dashboardsidebar";
import specialtiesData from '../../../../sampledata/specialties.json'
import LoadingSpinner from "../../patients/bookingv2/spinner";
import { refreshToken } from "../../../../api/auth";
import {
  getAccountInfo,
  getAppointmentByID,
  getHospitalInfoByInternalID,
  updateHospitalActive,
  updateHospitalPresentationMode,
  updateHospitalImages,
  deleteHospitalImage,
  RegisterDoctor,
  GetDoctor,
  EditDoctor,
  DeleteDoctor,
  updateDoctorAcceptingNewPatients,
  testapi,
  getSchedules,
  GetNextSchedulePage,
} from "../../../../api/patientAPIs";
import { useDispatch, useSelector } from "react-redux";
import { setAccountInfo } from "../../../../hooks/accountSlice";
import AWS from "aws-sdk";
import Loading from '../../loading';
import { areArraysEqual, convertUTCToLocal, ensureHttps, findObjectById, formatDateToLongString, frequencyMap, genders, getDoctorProfilePicture, getInsuranceById, languages, replaceObjectByExternalId } from "../../../assets/functions/functions";
import { calendar_art, chevron_down, insurance_pe, personal_pe, sample_doctor, services_pe, specialty_pe, default_profile, connect_art} from "../../home/image";
import EditSchedulePopup from "./edit-schedule-popup";
import PopupAlert from "./popup-alert";
import DeleteDoctorModal from "./patient-modals/delete-doctor-modal";
import AddDoctorModal from "./patient-modals/add-doctor-modal";
import CreateSchedulePopup from "./create-schedule-popup";

const ProfileEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const currentHospitalID = searchParams.get("hospital");
  const [hospitalData, setHospitalData] = useState(null);
  const [currentHospitalData, setCurrentHospitalData] = useState({
    hospital_name: "",
    services: [],
  });
  const fileInputRef = useRef(null)
  const alertRef = useRef();

  const [allowNewPatients, setAllowNewPatients] = useState(false)
  const [presentationMode, setPresentationMode] = useState("")

  const [imageToUpload, setImageToUpload] = useState(null)
  const [hospitalImages, setHospitalImages] = useState([]);
  const [profilePicture, setProfilePicture] = useState("")
  const [progressBar, setProgressBar] = useState(0);
  const [isPageLoading, setPageLoading] = useState(true)

  /* Doctor Picker */
  const doctorDropdownRef = useRef(null);
  const [isDoctorDropdownOpen, setDoctorDropdownOpen] = useState(false)
  const [currentDoctorData, setCurrentDoctorData] = useState(null)
  const [doctorList, setDoctorList] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showAddDoctorModal, setShowAddDoctorModal] = useState(false)

  /* Refs for Scrolling */
  const personalInfoRef = useRef(null)
  const specialtyRef = useRef(null)
  const serviceRef = useRef(null)
  const scheduleRef = useRef(null)

  /* Personal Info */
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [education, setEducation] = useState(null)
  const [degree, setDegree] = useState(null)
  const [about, setAbout] = useState("")

  /* Languages */
  const languagesDropdownRef = useRef(null);
  const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState(false)
  const [languageList, setLanguageList] = useState([])

  /* Gender */
  const genderDropdownRef = useRef(null);
  const [isGenderDropdownOpen, setGenderDropdownOpen] = useState(false)
  const [gender, setGender] = useState([])

  /* Specialty */
  const specialtyDropdownRef = useRef(null);
  const [isSpecialtyDropdownOpen, setSpecialtyDropdownOpen] = useState(false)
  const [specialty, setSpecialty] = useState(null)

  /* Service */
  const [serviceList, setServiceList] = useState(null)
  const [serviceInputText, setServiceInputText] = useState(null)
  const [didChangeServices, setDidChangeServices] = useState(false)

  /* Schedule */
  const [scheduleList, setScheduleList] = useState([])
  const scheduleDropdownRef = useRef(null);
  const [isScheduleDropdownOpen, setScheduleDropdownOpen] = useState(false)
  const [assignedSchedules, setAssignedSchedules] = useState([])
  const [editScheduleModalOpen, setEditScheduleModalOpen] = useState(false)
  const [currentSchedule, setCurrentSchedule] = useState(null)
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("")
  const [isCreateScheduleOpen, setCreateScheduleOpen] = useState(false)
  const [isPaginationLoading, setIsPaginationLoading] = useState(false)
  const [paginationData, setPaginationData] = useState(null)

  const canSaveUpdates = (
    !currentDoctorData ||
    firstName !== currentDoctorData.doctor_name_first || 
    lastName !== currentDoctorData.doctor_name_last ||
    education !== currentDoctorData.education.school || 
    degree !== currentDoctorData.degrees.degree || 
    didChangeServices ||
    specialty !== currentDoctorData.specialty ||
    about !== currentDoctorData.about ||
    !areArraysEqual(languageList, currentDoctorData.language) || 
    gender !== currentDoctorData.gender ||
    (imageToUpload !== currentDoctorData.profile_image_url &&
      imageToUpload !== "" && imageToUpload !== null
      )
  )

  // S3 Bucket Name
  const S3_BUCKET = process.env.REACT_APP_S3_BUCKETNAME;

  // S3 Region
  const REGION = process.env.REACT_APP_S3_REGION;

  // S3 Credentials
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_S3_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_S3_SECRETACCESSKEY,
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  useEffect(() => {
    getAccountInfo().then((res) => {
      if (res.status == 200) {
        dispatch(setAccountInfo(res.data));
        const hospitalList = res.hospital;
        if (hospitalList.length < 1) {
          navigate(`/create-hospital-welcome?user=${res.id}`)
        } else {
          var promises = [];
          hospitalList.forEach((hID) => {
            promises.push(getHospitalInfoByInternalID(hID));
          });

          Promise.all(promises).then((results) => {
            const tempHospitalList = [];
            results.forEach((res) => tempHospitalList.push(res.data));
            setHospitalData(tempHospitalList)
            const tempHospitalData = findObjectById(currentHospitalID, tempHospitalList)
            setScheduleList(tempHospitalData.schedules)
            setCurrentHospitalData(
              tempHospitalData
            );

            var doctorPromises = [];
            tempHospitalData.doctors.forEach((doc) => {
              doctorPromises.push(GetDoctor(doc))
            })
            
            

            Promise.all(doctorPromises).then((docResults) => {
              const tempDoctorList = [];
              docResults.forEach((docResults) => tempDoctorList.push(docResults.data));
              const tempFirstDoctor = tempDoctorList[0]
              getSchedules(currentHospitalID, tempFirstDoctor.external_id).then(scheduleResponse => {
                console.log(tempFirstDoctor)
                setDoctorList(tempDoctorList);
                setCurrentDoctorData(tempFirstDoctor)
                setFirstName(tempFirstDoctor.doctor_name_first)
                setLastName(tempFirstDoctor.doctor_name_last)
                setProfilePicture(tempFirstDoctor.profile_image_url)
                setEducation(tempFirstDoctor.education.school)
                setDegree(tempFirstDoctor.degrees.degree)
                setServiceList(tempFirstDoctor.services)
                setSpecialty(tempFirstDoctor.specialty)
                setAbout(tempFirstDoctor.about)
                setGender(tempFirstDoctor.gender)
                setLanguageList(tempFirstDoctor.language)
                setAllowNewPatients(tempFirstDoctor.allow_new_patient)
                console.log(scheduleResponse)
                setPaginationData(scheduleResponse.data)
                setAssignedSchedules(scheduleResponse.data.results.filter(schedule => schedule.d_id === tempFirstDoctor.external_id))
                setPageLoading(false)
              })
              
            }).catch(err => {
              console.log(err)
            })
            console.log(profilePicture)

            // RegisterDoctor({
            //   "doctor_name_first": "Jason",
            //   "doctor_name_last": "Zhao",
            //   "doctor_name_middle": "Smith",
            //   "education":{"school": "Carnegie Mellon"},
            //   "work_experience":{"years": 10},
            //   "awards":{},
            //   "degrees":{"degree": "MD"},
            //   "gender":"Male",
            // }, tempHospitalData)
          });
        }
      }

      if (res.status == 401 || res.status == 403) {
        refreshToken().then((response) => {
          if (response.status == 200) {
            console.log("Successfully refreshed token");
            window.location.reload();
          } else {
            navigate("/doctor-login");
          }
        });
      }
    });


    const handleClickOutside = (event) => {
      if (specialtyDropdownRef.current && !specialtyDropdownRef.current.contains(event.target)) {
        setSpecialtyDropdownOpen(false);
      }
    };
    window.addEventListener('click', handleClickOutside);

    const handleClickScheduleOutside = (event) => {
      if (scheduleDropdownRef.current && !scheduleDropdownRef.current.contains(event.target)) {
        setScheduleDropdownOpen(false);
      }
    };
    window.addEventListener('click', handleClickScheduleOutside);

    const handleClickGenderOutside = (event) => {
      if (genderDropdownRef.current && !genderDropdownRef.current.contains(event.target)) {
        setGenderDropdownOpen(false);
      }
    };

    const handleClickLanguageOutside = (event) => {
      if (languagesDropdownRef.current && !languagesDropdownRef.current.contains(event.target)) {
        setLanguageDropdownOpen(false);
      }
    };
    window.addEventListener('click', handleClickGenderOutside);
    window.addEventListener('click', handleClickLanguageOutside);
    return () => {
      window.removeEventListener('click', handleClickGenderOutside);
      window.removeEventListener('click', handleClickLanguageOutside);
      window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('click', handleClickScheduleOutside);
    };

  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Check if there are unsaved changes
      if (canSaveUpdates) {
        // Display confirmation message
        event.preventDefault();
        event.returnValue = ''; // Required for Chrome
        return 'You have unsaved changes. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [canSaveUpdates])


  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  const renderService = (serv, index) => {
    if (serv == undefined) {
      return
    }
    return (
      <button className="pe-tag-container">
        <h3>{serv}</h3>
        {serv !== "General Appointment" && <i className="fas fa-times" onClick={() => removeService(index)}></i>}
      </button>
    )
  }

  const renderSchedule = (schedule) => {
    const formattedStartDate = convertUTCToLocal(new Date(schedule.start_date * 1000)).getTime()
    const formattedEndDate = convertUTCToLocal(new Date(schedule.end_date * 1000)).getTime()

    return (
      <tr>
        <td><a  target="_blank" rel="noopener noreferrer">{schedule.schdeulde_name}</a></td>
        <td>{frequencyMap[schedule.frequency_type]} ({schedule.timeslots.length} time slots)</td>

        <td>
          <div className="plan-container-left-table-status">
          {formatDateToLongString(formattedStartDate)} - {formatDateToLongString(formattedEndDate)}
          </div>
        </td>
        <td><button className="pe-schedule-item-button1" onClick={() => toggleSchedulePopup(schedule)}><i className="fas fa-edit"></i></button></td>

      </tr>
    )
  }

  const toggleSchedulePopup = (schedule) => {
    setCurrentSchedule(schedule)
    setEditScheduleModalOpen(true)
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setImageToUpload(selectedFile)
    }
  };

  const toggleAlertWithMessage = (message) => {
    setAlertMessage(message)
    setShowAlert(true)
  }

  const handleShowMoreSchedules = () => {
    setIsPaginationLoading(true)
    GetNextSchedulePage(ensureHttps(paginationData.next)).then(response => {
      console.log(response)
      const tempSchedules = [...assignedSchedules, ...response.data.results]
      setAssignedSchedules(tempSchedules)
      setPaginationData(response.data)
      setIsPaginationLoading(false)
    })
    .catch(err => {
      console.log(err)
      setIsPaginationLoading(false)
    })
  }

  const uploadProfilePic = async (file) => {
    const MAX_FILE_SIZE = 1024 * 1024 * 30
    try {
      const fileName = file.name;
      if(file.size > MAX_FILE_SIZE) {
        toggleAlertWithMessage("Please upload files less than 20 MB")
        return
      }
      const objectKey = `doctors/${currentDoctorData.id}/profile_picture/${fileName}`

      const params = {
        Bucket: S3_BUCKET,
        Key: objectKey,
        Body: file,
      };

      if (profilePicture) {
        const profilePictureKey = `doctors/${currentDoctorData.id}/profile_picture/${profilePicture.split('/').pop()}`
        const deleteParams = {
          Bucket: S3_BUCKET,
          Key: profilePictureKey,
        };

        await s3.deleteObject(deleteParams).promise();
      }
      const data = await s3.upload(params).promise();
      return data
    } catch (error) {
      console.error('Error during file upload:', error);
      // Handle the error appropriately
      throw error; // Rethrow the error if needed
    }
  };

  const updateAllowNewPatient = (shouldAllowNewPatients) => {
    setAllowNewPatients(shouldAllowNewPatients)
    updateDoctorAcceptingNewPatients(shouldAllowNewPatients, currentDoctorData.external_id).then(res => {
      if (res.status == 200) {
        setAllowNewPatients(shouldAllowNewPatients)
        if (shouldAllowNewPatients) {
          toggleAlertWithMessage("This doctor is now accepting new patients")
        } else {
          toggleAlertWithMessage("This doctor is not accepting new patients")
        }
      } else {
        setAllowNewPatients(!shouldAllowNewPatients)
      }
    })
  }

  const handleEnterPressedService = (e) => {
    if (e.key === 'Enter' && serviceInputText != null && serviceInputText.length > 0) {
      addService()
    }
  }
  const addService = () => {
    if(serviceList.length < 50 && serviceInputText !== "General Appointment" && serviceInputText && serviceInputText.length > 0) {
      setDidChangeServices(true)
      const tempServices = serviceList
      tempServices.push(serviceInputText)
      setServiceList(tempServices)
      setServiceInputText("")
    } else if(serviceList.length >= 50){
      toggleAlertWithMessage("You have reached the limit for the amount of services you can register")
    }

  }

  const removeService = (index) => {
    const tempServices = [...serviceList]
    if (index >= 0 && index < tempServices.length) {
      setDidChangeServices(true)
      console.log(tempServices, index + 1)
      tempServices.splice(index, 1);
    } 
    setServiceList(tempServices)
  }

  const addImages = (image_url) => {
    const tempImages = currentHospitalData.hospital_images
    tempImages.push(image_url)
    updateHospitalImages(tempImages, currentHospitalData.id).then(res => {
      console.log("Added ", image_url, " to list of images")
    })
    alertRef.current.showAlert("Update successful!");
    setProgressBar(0);
  }

  const uploadFile = async () => {
    const imageUrl = `https://${S3_BUCKET}.s3.amazonaws.com/hospitals/${currentHospitalData.id}/hospital_images/${imageToUpload.name}`;

    if (hospitalImages.includes(imageUrl)) {
      alert("Image is already uploaded");
      return;
    }
    // Files Parameters

    const objectKey = `hospitals/${currentHospitalData.external_id}/hospital_images/${imageToUpload.name}`
    const params = {
      Bucket: S3_BUCKET,
      Key: objectKey,
      Body: imageToUpload,
    };

    // Uploading file to s3

    try {
      const data = await s3.putObject(params)
        .on("httpUploadProgress", (evt) => {
          // File uploading progress
          setProgressBar(Math.round(100 * evt.loaded) / evt.total);
        })
        .promise();
      // Update the state with the modified image list
      setHospitalImages([...hospitalImages, imageUrl]);
      // Make an API call to update the images in your backend as well
      addImages(imageUrl);
    } catch (err) {
      console.error('Error uploading object:', err);
      alertRef.current.showAlert('Error!');
      // Handle the error accordingly
    }
  };

  const deleteImage = (index) => {
    const tempImages = [...hospitalImages];
    const deletedImageUrl = tempImages.splice(index, 1)[0]; // Get the deleted image URL

    // Specify the bucket name and the object key (file path) to delete
    const objectKey = `hospitals/${currentHospitalData.id}/hospital_images/${deletedImageUrl.split('/').pop()}`
    console.log(objectKey);

    const params = {
      Bucket: S3_BUCKET,
      Key: objectKey,
    };

    s3.deleteObject(params, (err, data) => {
      if (err) {
        console.error('Error deleting object:', err);
        // Handle the error accordingly
      } else {
        console.log('Successfully deleted object');
        // Update the state with the modified image list
        setHospitalImages(tempImages);
        // Make an API call to update the images in your backend as well
        updateHospitalImages(tempImages, currentHospitalData.id).then((res) => {
          console.log('Updated images on the server');
          alertRef.current.showAlert('Image deleted successfully!');
        });
      }
    });
  };

  const addNewDoctor = (first, last, specialty, setModalSpinner) => {
    setModalSpinner(true)

    if(
      !first || first.length === 0 ||
      !last || last.length === 0 ||
      !specialty || !specialty.length === 0
    ) {
      return
    }

    const newDoctorData = {
      "doctor_name_first": first,
      "doctor_name_last": last,
      "doctor_name_middle": "",
      "education": { "school": "" },
      "work_experience": { "years": 0 },
      "awards": {},
      "degrees": { "degree": "" },
      "gender": "Not Selected",
      "specialty": specialty,
      "about": "No description given",
      "services": ["General Appointment"],
      "allow_new_patient": true,
      "language": []
    }

    const tempDoctorList = [...doctorList]

    RegisterDoctor(newDoctorData, currentHospitalData).then(res => {
      console.log(res)
      tempDoctorList.push(res.data)
      setDoctorList(tempDoctorList)
      const doctorData = newDoctorData
      setCurrentDoctorData(res.data)
      setProfilePicture("")
      setFirstName(doctorData.doctor_name_first)
      setLastName(doctorData.doctor_name_last)
      setEducation(doctorData.education.school)
      setDegree(doctorData.degrees.degree)
      setServiceList(doctorData.services)
      setSpecialty(doctorData.specialty)
      setAbout(doctorData.about)
      setAllowNewPatients(doctorData.allow_new_patient)
      setGender(doctorData.gender)
      setLanguageList(doctorData.language)
      setModalSpinner(false)
      setShowAddDoctorModal(false)
      setAssignedSchedules([])
      toggleAlertWithMessage(doctorData.doctor_name_first + " " + doctorData.doctor_name_last + " has been added.")
    }).catch(err => {
      toggleAlertWithMessage("An error occurred while adding doctor.")
    })
  }

  const handleChangeDoctor = async (doctorData) => {
    const tempAssignedSchedules = await getSchedules(currentHospitalID, doctorData.external_id)
    console.log(doctorData)
    setCurrentDoctorData(doctorData)
    setFirstName(doctorData.doctor_name_first)
    setLastName(doctorData.doctor_name_last)
    setEducation(doctorData.education != undefined ? doctorData.education.school : "")
    setDegree(doctorData.degrees != undefined ? doctorData.degrees.degree : "")
    setServiceList(doctorData.services)
    setSpecialty(doctorData.specialty)
    setAbout(doctorData.about)
    setAllowNewPatients(doctorData.allow_new_patient)
    setGender(doctorData.gender)
    setLanguageList(doctorData.language)
    setProfilePicture(getDoctorProfilePicture(doctorData))
    setAssignedSchedules(tempAssignedSchedules.data.results)
    setDoctorDropdownOpen(false)
  }


  const deleteCurrentDoctor = (setModalSpinner) => {
    if (doctorList.length > 1) {
      setModalSpinner(true)
      setIsDeleting(true)
      const newDoctorIDList = currentHospitalData.doctors.filter(item => item !== currentDoctorData.external_id)
      console.log(newDoctorIDList)
      DeleteDoctor(currentDoctorData.external_id, currentHospitalData.id, newDoctorIDList).then(res => {
        if (res.status >= 200 && res.status < 300) {
          const newDoctorList = doctorList.filter(item => item.external_id !== currentDoctorData.external_id);
          setDoctorList(newDoctorList)
          handleChangeDoctor(newDoctorList[0])
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        setIsDeleting(false)
        setModalSpinner(false)
        setShowDeleteModal(false)
      })
    }
  }

  const handleLanguageChanged = (language) => {
    const tempLanguageList = [...languageList]
    if(languageList.includes(language)) {
      setLanguageList(tempLanguageList.filter(lang => language !== lang))
    } else {
      tempLanguageList.push(language)
      tempLanguageList.sort()
      setLanguageList(tempLanguageList)
    }
  }

  const prettyPrintLanguages = () => {
    var str = ""
    if(languageList.length > 3) {
      str += languageList[0] + ", " + languageList[1] + ", " + languageList[2] + ", +" + String(languageList.length - 3) + " more languages"
    } else {
      languageList.forEach(language => {
        str += language + ", "
      })
    }
    return str
  }

  const saveUpdate = () => {
    if ( specialty == null || specialty === ""){
      toggleAlertWithMessage("Error: Please select a specialty for this provider.")
      return
    }
    if (
      firstName == null || firstName === "" ||
      lastName == null || lastName === "" ||
      about == null || about === ""
    ) {
      toggleAlertWithMessage("Error: Please check that all required fields are filled.")
      return
    }
    setIsSaving(true)
    var promiselist = [];
    let imageUrl = profilePicture
    if (imageToUpload) {
      imageUrl = `https://${S3_BUCKET}.s3.amazonaws.com/doctors/${currentDoctorData.id}/profile_picture/${imageToUpload.name}`;
      promiselist.push(uploadProfilePic(imageToUpload));
    }
    const doctorData = {
      "doctor_name_first": firstName,
      "doctor_name_last": lastName,
      "doctor_name_middle": "Smith",
      "education": { "school": education },
      "work_experience": { "years": 10 },
      "awards": {},
      "degrees": { "degree": degree },
      "gender": gender,
      "profile_image_url": imageUrl,
      "specialty": specialty,
      "about": about,
      "services": serviceList,
      "language": languageList,
    }
    promiselist.push(EditDoctor(currentDoctorData.external_id, doctorData))
    Promise.all(promiselist)
      .then((values) => {
        console.log(values)
        const updatedDoctor = imageToUpload ? values[1].data : values[0].data
        const tempDoctorList = [...doctorList]
        console.log(tempDoctorList, updatedDoctor, values)
        setDoctorList(replaceObjectByExternalId(tempDoctorList, updatedDoctor))
        handleChangeDoctor(updatedDoctor)
        setImageToUpload(null)
        setIsSaving(false);
        setDidChangeServices(false)
        toggleAlertWithMessage("Successfully updated doctor profile")
        //window.location.reload();
      })
      .catch((error) => {
        console.log(error)
        setIsSaving(false);
        toggleAlertWithMessage("Couldn't update changes, please check for errors.");
      });
  }

  if (isPageLoading) {
    return (<Loading />)
  }

  if (editScheduleModalOpen) {
    return (
      <div className="pe-supercontainer">
        <DoctorHeader hospitalData={hospitalData} currentHospitalData={currentHospitalData} />
        <DoctorSideBar currentTab={4} hospitalData={hospitalData} currentHospitalData={currentHospitalData} />
        <div className="pe-container">
          <EditSchedulePopup 
            doctorList={doctorList} 
            setModalOpen={setEditScheduleModalOpen} 
            currentHospitalData={currentHospitalData} 
            toggleAlertWithMessage={toggleAlertWithMessage} 
            scheduleData={currentSchedule} 
            setScheduleList={setAssignedSchedules}
            scheduleList={assignedSchedules}
          />
        </div>
      </div >
    );
  }

  return (
    <div className="pe-supercontainer">
      <PopupAlert showAlert={showAlert} setShowAlert={setShowAlert} alertMessage={alertMessage} />
      {showDeleteModal && <DeleteDoctorModal deleteDoctor={deleteCurrentDoctor} onRequestClose={() => setShowDeleteModal(false)} doctorData={currentDoctorData} />}
      {showAddDoctorModal && <AddDoctorModal addDoctor={addNewDoctor} onRequestClose={() => setShowAddDoctorModal(false)} currentHospitalData={currentHospitalData} />}
      <DoctorHeader hospitalData={hospitalData} currentHospitalData={currentHospitalData} />
      <DoctorSideBar currentTab={4} hospitalData={hospitalData} currentHospitalData={currentHospitalData} />
      {isCreateScheduleOpen && <div className="calendar-container" style={{ width: '100%' }}><CreateSchedulePopup toggleAlertWithMessage={toggleAlertWithMessage} currentHospitalData={currentHospitalData} setCreateScheduleOpen={setCreateScheduleOpen} doctorList={doctorList} scheduleList={assignedSchedules} setScheduleList={setAssignedSchedules}/></div>}
      {!isCreateScheduleOpen && <><div className="pe-save-footer">
        <button className={`pe-left-delete-btn ${doctorList.length <= 1 ? "disabled" : ""}`} onClick={() => setShowDeleteModal(true)}>
          {isDeleting ? <LoadingSpinner /> : "Delete Doctor"}
        </button>
        {canSaveUpdates ? 
         <button className="pe-left-save-btn" onClick={() => saveUpdate()}>
          {isSaving ? <LoadingSpinner /> : "Save Changes"}
        </button>
        : 
        <button className="pe-left-save-btn disabled">
          Save Changes
        </button>
        }
      </div>

        <div className="pe-container">
          <div className="pe-left-container">
            <div className="pe-left-doctor-picker">
              <h2>Switch Doctor</h2>
              <div
                className="booker-reason-input-container"
                ref={doctorDropdownRef}
                style={{ marginBottom: '20px', width: "60%" }}
              >
                <div className="booker-body-dropdown" onClick={() => setDoctorDropdownOpen(!isDoctorDropdownOpen)}>
                  <h1>
                    {currentDoctorData != null
                      ? currentDoctorData.doctor_name_first + " " + currentDoctorData.doctor_name_last
                      : ""}
                  </h1>
                  <img alt="chevron down" src={chevron_down} />
                </div>
                {isDoctorDropdownOpen && (
                  <ul className="booker-dropdown-menu" >
                    {doctorList.map((doctor, index) => (
                      <li key={index} onClick={() => handleChangeDoctor(doctor)}>
                        Dr. {doctor.doctor_name_first} {doctor.doctor_name_last}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <h4 className="pe-new-doctor-btn" onClick={() => {
              if(doctorList.length < 20){
                setShowAddDoctorModal(true)
              } else {
                toggleAlertWithMessage("You have reached the maximum number of doctors you can register")
              }
              }}><u>ADD NEW DOCTOR +</u></h4>
            <div className="pe-left-subcontainer">
              <div className="pe-left-section" onClick={() => scrollToRef(personalInfoRef)}>
                <img alt="Icon" src={personal_pe} />
                <div className="d-flex flex-column">
                  <h1>Personal Info</h1>
                  <h2>Add basic information about the selected provider at your clinic.</h2>
                </div>
              </div>
              <div className="pe-left-section"  onClick={() => scrollToRef(specialtyRef)}>
                <img alt="Icon" src={specialty_pe} />
                <div className="d-flex flex-column">
                  <h1>Specialty</h1>
                  <h2>Add and remove specialties of your the provider</h2>
                </div>
              </div>
              <div className="pe-left-section"  onClick={() => scrollToRef(serviceRef)}>
                <img alt="Icon" src={services_pe} />
                <div className="d-flex flex-column">
                  <h1>Services</h1>
                  <h2>Add and remove services that patients can book for this provider</h2>
                </div>
              </div>
              <div className="pe-left-section"  onClick={() => scrollToRef(scheduleRef)}>
                <img alt="Icon" src={insurance_pe} />
                <div className="d-flex flex-column">
                  <h1>Schedule</h1>
                  <h2>Manage appointment slots for the provider</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="pe-right-container">
            <div className="pe-right-section" ref={personalInfoRef}>
              <div className="pe-right-personal-header">
                <div className="pe-right-personal-header-img">
                  <img
                    alt=""
                    src={imageToUpload ? URL.createObjectURL(imageToUpload) :
                      (profilePicture ? profilePicture : default_profile)
                    }
                  />
                </div>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept="image/*"
                />
                <div className="pe-right-personal-header-edit-img" onClick={() => fileInputRef.current.click()}><i class="fas fa-camera"></i></div>
              </div>

              <div className="pe-right-personal-body">
                <div className="pe-right-personal-top">
                  <h3>Dr. {currentDoctorData.doctor_name_first} {currentDoctorData.doctor_name_last}</h3>
                  <div className="pe-right-profile-visible-container">
                    <h4>Accepting New Patients</h4>
                    <ReactToggle
                      className="pe-visible-toggle achew-toggle"
                      checked={allowNewPatients}
                      color
                      onChange={(e) => updateAllowNewPatient(e.target.checked)}
                    />
                  </div>
                </div>

                <h1 className="booking-intro-label">Personal Info</h1>
                <p className="booking-intro-labelp">
                  Fill out some basic information about this provider.
                </p>

                <span className="d-flex flex-col justify-content-between">
                  <div className="email-input-wrapper">
                    <h3 className="booking-email-label">Legal first name</h3>
                    <input
                      className="name-input"
                      name="first"
                      placeholder="Enter your first name"
                      value={firstName}
                      onChange={(c) => setFirstName(c.currentTarget.value)}
                    />
                  </div>

                  <div className="email-input-wrapper">
                    <h3 className="booking-email-label">Legal last name</h3>
                    <input
                      className="name-input2"
                      name="last"
                      placeholder="Enter your last name"
                      value={lastName}
                      onChange={(c) => setLastName(c.currentTarget.value)}
                    />
                  </div>
                </span>

                <span className="d-flex flex-col justify-content-between">
                  <div className="email-input-wrapper">
                    <h3 className="booking-email-label">Education</h3>
                    <input
                      className="name-input"
                      name="first"
                      placeholder="Ex. Johns Hopkins Medical School"
                      value={education}
                      onChange={(c) => setEducation(c.currentTarget.value)}
                    />
                  </div>

                  <div className="email-input-wrapper">
                    <h3 className="booking-email-label">Degree</h3>
                    <input
                      className="name-input2"
                      name="last"
                      placeholder="Ex. MD, BD"
                      value={degree}
                      onChange={(c) => setDegree(c.currentTarget.value)}
                    />
                  </div>
                </span>
                <h3 className="booking-email-label">About this provider</h3>
                <textarea
                  className="email-input email-textarea"
                  placeholder="Enter a description of this provider. Education, years of experience, certifications, degrees."
                  value={about}
                  onChange={(c) => setAbout(c.currentTarget.value)}
                  maxLength={2000}
                />

                <h3 className="booking-email-label">Languages</h3>
                <div
                  className="booker-reason-input-container"
                  ref={languagesDropdownRef}
                >
                  <div className="booker-body-dropdown" onClick={() => setLanguageDropdownOpen(!isLanguageDropdownOpen)}>
                    <h1>
                      {languageList && languageList.length > 0
                        ? prettyPrintLanguages()
                        : "Choose spoken languages"}
                    </h1>
                    <img alt="chevron down" src={chevron_down} />
                  </div>
                  {isLanguageDropdownOpen && (
                    <ul className="booker-dropdown-menu">
                      {languages.map((option, index) => {
                        const isLanguageSelected = languageList.includes(option)
                        return (
                        <li key={index} onClick={() => handleLanguageChanged(option) } className={isLanguageSelected ? "pe-language-list-item-selected" : "pe-language-list-item"}>
                          <h4>{option}</h4>
                          {isLanguageSelected && <i className="fas fa-check"></i>}
                        </li>
                      )})}
                    </ul>
                  )}
                </div>
                <h3 className="booking-email-label">Gender</h3>
                <div
                  className="booker-reason-input-container"
                  ref={genderDropdownRef}
                >
                  <div className="booker-body-dropdown" onClick={() => setGenderDropdownOpen(!isGenderDropdownOpen)}>
                    <h1>
                      {gender && gender.length > 0
                        ? gender
                        : "Choose provider's gender"}
                    </h1>
                    <img alt="chevron down" src={chevron_down} />
                  </div>
                  {isGenderDropdownOpen && (
                    <ul className="booker-dropdown-menu">
                      {genders.map((option, index) => {
                        return (
                        <li key={index} onClick={() =>{ setGender(option); setGenderDropdownOpen()} } >
                          <h4>{option}</h4>
                        </li>
                      )})}
                    </ul>
                  )}
                </div>
              </div>
              
            </div>

            <div className="pe-right-section" ref={specialtyRef}>
              <div className="pe-specialty-section">
                <h1 className="booking-intro-label">Main Specialty</h1>
                <p className="booking-intro-labelp">
                  Choose this provider's main specialty
                </p>
                <h3 className="booking-email-label">Choose Specialty</h3>
                <div
                  className="booker-reason-input-container"
                  ref={specialtyDropdownRef}
                  style={{ marginBottom: '20px' }}
                >
                  <div className="booker-body-dropdown" onClick={() => setSpecialtyDropdownOpen(!isSpecialtyDropdownOpen)}>
                    <h1>
                      {specialty != null && specialty != ""
                        ? specialty
                        : "Choose this provider's main specialty"}
                    </h1>
                    <img alt="chevron down" src={chevron_down} />
                  </div>
                  {isSpecialtyDropdownOpen && (
                    <ul className="booker-dropdown-menu">
                      {specialtiesData.all_specialties.map((option, index) => (
                        <li key={index} onClick={() => { setSpecialty(option); setSpecialtyDropdownOpen(false) }}>
                          {option}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {/* <div className="pe-specialty-list">
                {renderSpecialty("Family Doctor", 1)}
              </div> */}
              </div>
            </div>

            <div className="pe-right-section"  ref={serviceRef}>
              <div className="pe-specialty-section" >
                <h1 className="booking-intro-label">Service</h1>
                <p className="booking-intro-labelp">
                  Enter services for this provider.
                </p>
                <h3 className="booking-email-label">Enter Service</h3>
                <div className="d-flex align-items-center">
                  <input
                    className="email-input"
                    value={serviceInputText}
                    placeholder="Enter a service and press enter to add"
                    onChange={(e) => setServiceInputText(e.currentTarget.value)}
                    onKeyDown={handleEnterPressedService}
                  />
                  <button className="pe-add-service-button" onClick={() => addService()}>
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
                
                <h1 className="pe-service-limit">{serviceList.length}/50</h1>
                <h3 className="booking-email-label" style={{marginBottom: 0}}>Registered Services</h3>
                <div className="pe-specialty-list">
                  {serviceList.length > 0 && serviceList.map((service, index) => renderService(service, index))}
                </div>
                
              </div>
            </div>

            <div className="pe-right-section" ref={scheduleRef}>
              <div className="pe-specialty-section" >
                <div className="pe-section-header">
                  <div className="d-flex flex-column">
                    <h1 className="booking-intro-label">Schedule</h1>
                    <p className="booking-intro-labelp">
                      Assign schedules and open time slots
                    </p>
                  </div> 
                  <button className="profile-edit-create-schedule-button" onClick={() => setCreateScheduleOpen(true)}>
                    <i className="far fa-calendar-alt"></i> Create Schedule
                  </button>
                </div>
                {
                  assignedSchedules.length > 0 ? (
                  <table className="plan-container-left-invoice-table">
                  <thead>
                    <tr>
                      <th>Schedule</th>
                      <th>Type/Slots</th>
                      <th>Effective Date</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignedSchedules.map(invoice => renderSchedule(invoice))}
                  </tbody>
                </table>)
                :
                <div className="d-flex flex-column">
                  <h2 className="plan-container-no-invoice-history">No schedules</h2>
                  <h2 className="plan-container-no-invoice-history-2">Create a schedule to open appointment slots</h2>
                </div>
                }
                {isPaginationLoading ? 
                  <LoadingSpinner/> 
                  :
                  (paginationData.next && <button className="plan-container-show-more-button" onClick={() => handleShowMoreSchedules()}>Show more</button>)
                }

              </div>
            </div>

          </div>
        </div></>}

    </div >
  );
};

export default ProfileEdit;

