import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const CreditWarningPopup = ({ onClose, isOpen }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const currentHospitalID = searchParams.get('hospital')
    if(isOpen){
        return (
            <div className="credit-popup-overlay" onClick={onClose}>
              <div className="credit-popup" onClick={(e) => e.stopPropagation()}>
                <div className="credit-popup-header">
                  <i className='fas fa-exclamation-triangle'></i>
                  <h2>Insufficient Account Balance</h2>
                  
                </div>
                <div className="credit-popup-body">
                  <p>Please charge your account balance to use Achew's intake and online booking system.</p>
                  <div className='credit-popup-button-container'>
                    <button className="credit-popup-cancel" onClick={onClose}>
                        Cancel 
                    </button>
                    <button className="credit-popup-action" onClick={() => navigate(`/plan?hospital=${currentHospitalID}`)}>
                        Go to recharge
                    </button>
                  </div>
                  
                </div>
              </div>
            </div>
          );
    }
  
};

export default CreditWarningPopup;
