import React, { useState, useEffect } from 'react';
import Header from '../../patientheader';
import { Link } from 'react-router-dom'
import { useNavigate, useParams } from "react-router-dom";
import AchewHeader from '../../achew-header';
import AchewFooter from '../../achew-footer';
const ResetPasswordSuccess = () => {
    const navigate = useNavigate()
    const { token } = useParams();
    useEffect(() => {
      if(!token || token.length <= 0){
          navigate('/error')
      }
  }, []);
  return (
    <div className="reset-password-success-supercontainer">
      <AchewHeader />

      <div className="reset-password-success-container" >
          <div className="success-cont">
            <div className='d-flex flex-row align-items-center'>
                <i className="fas fa-check"></i>
                <h3>You've successfully reset your password!</h3>
            </div>
            <p>Sign in with your new password.</p>
            <button onClick={() => navigate(`/doctor-login`)}>Return</button>
          </div>
      </div>
      <AchewFooter/>
    </div>
  );
};

export default ResetPasswordSuccess;