import React, { useState, useEffect, useRef } from 'react';
import insuranceSampleData from '../../../sampledata/insurance.json'
import {
    getCombinedName, getInsuranceById, getPlanByIds, isValidUUID
  } from "../../assets/functions/functions.js";
import { useNavigate, useLocation } from 'react-router-dom';
const InsuranceFilter = ({setInsuranceID, setPlanID, insuranceID, planID, handleInsuranceChanged, isInsuranceModalOpen, setInsuranceModalOpen, isBottomSheet}) => {
  const navigate = useNavigate()
  const location = useLocation();
  const dropdownRef = useRef(null);
  const [validInsuranceChosen, setValidInsuranceChosen] = useState(false)

  const [selectedInsurance, setSelectedInsurance] = useState(null)
  const [selectedPlan, setSelectedPlan] = useState(null)
  const searchParams = new URLSearchParams(location.search);
  const insuranceParam = searchParams.get('insurance')
  const planParam = searchParams.get('plan')
  const [insuranceFilterText, setInsuranceFilterText] = useState("")

  useEffect(() => {
    console.log(insuranceID, planID)
    setSelectedInsurance(getInsuranceById(insuranceID, insuranceSampleData))
    setSelectedPlan(getPlanByIds(insuranceID, planID, insuranceSampleData))

    const closeDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setInsuranceModalOpen(false);
      }
    };
    const handleFilterKeyStroke = (event) => {
      if (event.key === "Backspace") {
        setInsuranceFilterText(prevKeys => prevKeys.slice(0, -1));
      } else if (event.key.length === 1) {
        setInsuranceFilterText(prevKeys => prevKeys + event.key);
      }
    }
    document.addEventListener('keydown', handleFilterKeyStroke);
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
      document.removeEventListener('keydown', handleFilterKeyStroke);
    };
  }, []);

  

  const handleInsuranceSelect = (insurance) => {
    setSelectedInsurance(insurance)
  }

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan)
    setInsuranceID(selectedInsurance.id)
    setPlanID(plan.id)
    setInsuranceModalOpen(false)
    setValidInsuranceChosen(true)
    handleInsuranceChanged(selectedInsurance.id, plan.id)
    
  }

  const filterProvidersByNamePrefix = (data, prefix) => {
    // Filter popular providers by name
    const filteredPopular = data.popular.filter(provider =>
      provider.name.toLowerCase().startsWith(prefix.toLowerCase())
    );
  
    // Filter all providers by name
    const filteredAll = data.all.filter(provider =>
      provider.name.toLowerCase().startsWith(prefix.toLowerCase())
    );
  
    // Return the filtered results
    return {
      popular: filteredPopular,
      all: filteredAll
    };
  };

  const handleClearInsuranceFilter = () => {
    setSelectedPlan(null)
    setSelectedInsurance(null)
    setInsuranceID("")
    setPlanID("")
    handleInsuranceChanged(null, null)
    setInsuranceFilterText("")
  }

  const handleCloseDropdown = () => {
    setInsuranceModalOpen(false)
    setInsuranceFilterText("")
  }
  const handleBackToInsurance = () => {
    setSelectedPlan(null)
    setSelectedInsurance(null)
    setInsuranceID("")
    setPlanID("")
    setValidInsuranceChosen(false)
    setInsuranceFilterText("")
  }
 
  const renderScreen = () => {
    const filteredInsurances = filterProvidersByNamePrefix(insuranceSampleData, insuranceFilterText)
    if (!selectedInsurance) {
      return (
        <ul className={`insurance-dropdown-content ${isBottomSheet ? "bottomsheet" : ""}`}>
          <div className='insurance-dropdown-header'>
            <button onClick={() => handleCloseDropdown()} className='insurance-dropdown-cancel-button'>
              Cancel
            </button>

            <button onClick={() => handleClearInsuranceFilter()} className='insurance-dropdown-clear-button'>
              Clear
            </button>
            
          </div>
          <p className="insurance-dropdown-section-label">Popular</p>
          {filteredInsurances.popular.map((option, index) => (
            <li
              key={index}
              onClick={() => handleInsuranceSelect(option)}
            >
              {option.name}
            </li>
          ))}

          <p className="insurance-dropdown-section-label">All insurances</p>
          {filteredInsurances.all.map((option, index) => (
            <li
              key={index}
              onClick={() => handleInsuranceSelect(option)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )
    } else {
      return (
        <ul className={`insurance-dropdown-content ${isBottomSheet ? "bottomsheet" : ""}`}>
          <div className='insurance-dropdown-header' onClick={() => handleBackToInsurance()}>
            <i class="fas fa-arrow-left"></i>
            <p className="insurance-dropdown-section-label">Change carrier</p>
          </div>
          <p className="insurance-dropdown-section-label">{selectedInsurance.name}</p>
          {selectedInsurance.plans.map((plan, index) => (
            <li
              key={index}
              onClick={() => handlePlanSelect(plan)}
            >
              {plan.title}
            </li>
          ))}
        </ul>
      )

    }
  }

  if(isValidUUID(insuranceParam) && isValidUUID(planParam)){
    const label = getInsuranceById(insuranceParam, insuranceSampleData).name + " - " + getPlanByIds(insuranceParam, planParam, insuranceSampleData).title
    const truncatedLabel = label.length > 20 ? label.slice(0, 20) + '...' : label;

    if(isBottomSheet){
      return renderScreen()
    } else {
      return (
        <div className="position-relative">
          <button className="search-result-filter-button" onClick={() => setInsuranceModalOpen(true)}>{truncatedLabel}</button>
          {isInsuranceModalOpen && renderScreen()}
        </div>
      )
    }
    
  } else {
    if(isBottomSheet){
      return renderScreen()
    } else {
      return (
        <div className="position-relative">
          <button className="search-result-filter-button" onClick={() => setInsuranceModalOpen(true)}>Choose Insurance</button>
          {isInsuranceModalOpen && renderScreen()}
        </div>
      )
    }
  }
  
}

export default InsuranceFilter;