import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Header from "../../patientheader";
import { login } from "../../../../hooks/authSlice";
import { setAccountInfo } from "../../../../hooks/accountSlice";
import { useSelector, useDispatch } from 'react-redux'
import { RequestLogin, sendPasswordChangeEmail } from "../../../../api/auth"
import { getAccountInfo, getHospitalInfoByInternalID } from "../../../../api/patientAPIs"
import { achew_logo_main, login_art } from "../../home/image";
import LoadingSpinner from "../../patients/bookingv2/spinner";
import Loading from "../../loading";

const DoctorLogin = (props) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [tryingLogIn, setTryingLogIn] = useState(false)

  //States for Login Screen
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [shouldRememberUser, setShouldRememberUser] = useState(false)
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  //States for Password Change Screen
  const [emailPasswordChange, setEmailPasswordChange] = useState("")
  const [showEmailValid, setShowEmailInvalid] = useState(false)
  const [sendingEmail, setSendingEmail] = useState(false)

  //Used to show login, password change, password change successful screen states.
  const [screenController, setScreenController] = useState(0)

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth.isAuth)

  const handleEmailChanged = (e) => {
    setEmail(e.target.value)
  }
  const handlePwdChanged = (e) => {
    setPassword(e.target.value)
  }
  const handleRememberMeChanged = (e) => {
    if(shouldRememberUser){
      localStorage.setItem('rememberLogin', "no")
    } else {
      localStorage.setItem('rememberLogin', "yes")
    }
    setShouldRememberUser(!shouldRememberUser)
  }
  const handleLoginRequest = async (e) => {
    e.preventDefault();
    try {
      setIsLoggingIn(true)
      const response = await RequestLogin(email, password);
      if (response.status === 200) {
        // Successful login
        const res = await getAccountInfo();
  
        if (res.status === 200) {
          dispatch(setAccountInfo(res.data));
          dispatch(login());
          console.log(res)
          const internalResponse = await getHospitalInfoByInternalID(res.hospital[0]);
          if (internalResponse.status === 200) {
            navigate(`/summary?hospital=${internalResponse.data.external_id}`);
          } else {
            navigate(`/create-hospital-welcome?user=${res.id}`);
          }
        }
      } else if (response.status === 401) {
        // Unauthorized access (handle accordingly)
        setShowErrorMessage(true)
        setIsLoggingIn(false)
        console.error('Unauthorized access. Please check your credentials.');
      } else {
        setShowErrorMessage(true)
        setIsLoggingIn(false)
        // Handle other status codes if needed
        console.error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      setShowErrorMessage(true)
      console.error('An error occurred:', error);
    }
  }

  const handleSendPasswordChangeEmail = () => {
    setSendingEmail(true)
    sendPasswordChangeEmail(emailPasswordChange).then(res => {
      if(res.status === 200){
        setScreenController(2)
      } else {
        setShowEmailInvalid(true)
      }
      setSendingEmail(false)
    })
  }

  const renderLoginScreen = () => {
    return (
    <form className="doctor-login-container-right" onSubmit={handleLoginRequest}>
      <img alt="Achew logo" src ={achew_logo_main} className="doctor-login-container-right-img"/>
      <h1>Sign in to your Achew Clinic account to continue...</h1>
      <input
        type='text'
        placeholder="Email"
        value={email}
        onChange={handleEmailChanged}
        className="doctor-login-container-right-input"
      />
      <input
        type='password'
        placeholder="Password"
        value={password}
        onChange={handlePwdChanged}
        className="doctor-login-container-right-input"
      />
      {showErrorMessage && <p>* Incorrect email and/or password</p>}
      <div className="doctor-login-right-forgot">
        <div className="doctor-login-right-forgot-container">
          <input
            type='checkbox'
            checked={shouldRememberUser}
            onChange={() => handleRememberMeChanged()}
          />
          <h4>Remember me</h4>
        </div>
        <h5 onClick={() => setScreenController(1)}>Forgot Password?</h5>
      </div>
      <button  className="doctor-login-signin-btn" type="submit">{isLoggingIn ? <LoadingSpinner /> : "Log in"}</button>

      <div className="doctor-login-alternative-signup">
        <h2>
          or
        </h2>
        <h3>
          new to Achew?
        </h3>
        <button className="login-continue-with-google-btn" onClick={() => navigate("/welcome")}>
          Join for free
        </button>
      </div>
    </form>)
  }

  const renderPasswordChangeScreen = () => {
    return (<div className="doctor-login-container-right">
      <h4>Create a new password</h4>
      <h5>We'll email you a link to reset your password</h5>
      <input
        type='text'
        placeholder="Email"
        value={emailPasswordChange}
        onChange={(e) => setEmailPasswordChange(e.currentTarget.value)}
        className="doctor-login-container-right-input"
      />
      {showEmailValid && <h1 style={{alignSelf:"flex-start"}} className="missingFields">*The email you provided couldn't be found</h1>}
      
      
      <button className="doctor-login-signin-btn" onClick={() => handleSendPasswordChangeEmail()}>
        {
          sendingEmail ? 
          <LoadingSpinner/>
          :
          "Send"
        }
      </button>

      <div className="doctor-login-alternative-signup">
        <button className="login-continue-with-google-btn" onClick={() => setScreenController(0)}>
          Back to login
        </button>
      </div>
    </div>)
  }

  const renderPasswordChangeEmailSentScreen = () => {
    return (<div className="doctor-login-container-right">
      <h4>We've sent an email to <b>{emailPasswordChange}</b></h4>
      <h5>Please follow the instructions in the email to reset your password</h5>
      
      <button className="doctor-login-signin-btn" onClick={() => setScreenController(0)}>Back to login</button>
    </div>)
  }

  useEffect(() => {
    const shouldRememberUserStorage = localStorage.getItem('rememberLogin')
    setShouldRememberUser(shouldRememberUser === "yes" ? true : false)

    if(shouldRememberUserStorage === "yes"){
      setTryingLogIn(true)
      getAccountInfo().then(res => {
        if(res.status == 200) {
          getHospitalInfoByInternalID(res.hospital[0]).then(hospitalResponse => {
            if (hospitalResponse.status === 200) {
              navigate(`/summary?hospital=${hospitalResponse.data.external_id}`);
              setTryingLogIn(false)
            }
          })
        } else {
          setTryingLogIn(false)
        }
      })
    }
  }, []);

  if(tryingLogIn){
    return <Loading/>
  }

  return (
    <div className="doctor-login-background"> 
        <div className="doctor-login-container">
          <div className="doctor-login-container-left">
            <div className="doctor-login-container-left-img"/>
            {/* <div>
              <h2>Welcome to <b>Doccure</b></h2>
              <h3>Find new patients, manage your appointments</h3>
              <button className="doctor-login-signup-btn" onClick={() => navigate(`/doctor-registration`)}>Create a Doccure Account</button>
            </div> */}
          </div>
          {screenController === 0 && renderLoginScreen()}
          {screenController === 1 && renderPasswordChangeScreen()}
          {screenController === 2 && renderPasswordChangeEmailSentScreen()}
        </div>
    </div>
    
  );
};

export default DoctorLogin;
