import React, { useState, useEffect, useRef } from "react";
import AchewHeader from "../achew-header.jsx";
import InsuranceDropdown from "./insurance-dropdown.jsx";
import specialtiesData from "../../../sampledata/specialties.json";
import { Loader } from "@googlemaps/js-api-loader";
import { useNavigate } from "react-router-dom";
import {
  arrow_left,
  arrow_right,
  arrow_top_right_white,
  blue_check,
  calendar_icon2,
  dentist,
  dermatologist,
  eye_doctor,
  geo_icon,
  mesh_arrow_left,
  mesh_arrow_right,
  obgyn,
  primary_care,
  psychiatrist,
  search_filled,
  search_icon,
  shield_icon,
  single_doc2background,
  single_doctor1,
  single_doctor2,
  specialty_icon,
  swirl_arrow,
  three_doctors,
  two_users,
} from "./image.jsx";
import SpecialtyCarousel from "./specialty-carousel.jsx";
import AchewFooter from "../achew-footer.jsx";
import ClinicCard from "./clinic-card.jsx";
import { useMediaQuery } from "react-responsive";
import MobileDropdownBottomSheet from "../doctors/dashboard/patient-modals/mobile-dropdown-modal.jsx";
import { checkNPIRegistry } from "../../../api/patientAPIs.js";

const carouselWidth = 280;

const Home = () => {
  const navigate = useNavigate();

  //Refs
  const procedureDropdownRef = useRef(null);
  const insuranceDropdownRef = useRef(null);
  const placeAutoCompleteRef = useRef(null);
  const carouselRef = useRef(null);

  const [blueGradientHeight, setBlueGradientHeight] = useState(0);
  const [carouselOffsetIndex, setCarouselOffsetIndex] = useState(0);

  //Procedure
  const [isProcedureOpen, setProcedureOpen] = useState(false);
  const [procedureFilterText, setProcedureFilterText] = useState("");
  const procedureSearchInputRef = useRef(null);
  //Insurance
  const [isInsuranceOpen, setInsuranceOpen] = useState(false);
  const [selectedProcedure, setSelectedProcedure] = useState("");
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [insuranceID, setInsuranceID] = useState(null);
  const [planID, setPlanID] = useState(null);
  const [insuranceFilterText, setInsuranceFilterText] = useState("");
  const [filterValue, setFilterValue] = useState("");

  //Geolocation states
  const [mapsLocation, setMapsLocation] = useState(null);
  const [mapsAddress, setMapsAddress] = useState("");
  const [currentLatitude, setCurrentLatitude] = useState(null);
  const [currentLongitude, setCurrentLongitude] = useState(null);

  //Responsive
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPureMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const handleProcedureInputChange = (e) => {
    setSelectedProcedure(e.target.value);
    setProcedureFilterText(e.target.value);
  };
  const toggleProcedureDropdown = () => {
    setProcedureOpen(!isProcedureOpen);
  };
  const handleProcedureSelect = (option) => {
    setSelectedProcedure(option);
    setProcedureOpen(false);
  };

  const handleLocationChanged = (e) => {
    setMapsAddress(e.target.value);
  };

  const shiftCarouselLeft = () => {
    if (carouselOffsetIndex > -6) {
      setCarouselOffsetIndex(carouselOffsetIndex - 1);
    }
  };

  const shiftCarouselRight = () => {
    if (carouselOffsetIndex < 0) {
      setCarouselOffsetIndex(carouselOffsetIndex + 1);
    }
  };

  const filteredPopSpecialties = specialtiesData.popular_specialties.filter(
    (item) => item.toLowerCase().includes(procedureFilterText.toLowerCase())
  );

  const filteredAllSpecialties = specialtiesData.all_specialties.filter(
    (item) => item.toLowerCase().includes(procedureFilterText.toLowerCase())
  );

  const search = (event) => {
    event.preventDefault();
    if (mapsLocation) {
      const latitude = mapsLocation.geometry.location.lat();
      const longitude = mapsLocation.geometry.location.lng();
      const searchURL = `/search?specialty=${selectedProcedure}&distance=0&latitude=${latitude}&longitude=${longitude}&insurance=${insuranceID}&plan=${planID}&date=${new Date().getTime()}`;
      navigate(searchURL);
    } else {
      const searchURL = `/search?specialty=${selectedProcedure}&distance=0&latitude=${currentLatitude}&longitude=${currentLongitude}&insurance=${insuranceID}&plan=${planID}&date=${new Date().getTime()}`;
      navigate(searchURL);
    }
  };

  useEffect(() => {
    if (procedureSearchInputRef.current) {
      procedureSearchInputRef.current.focus();
    }
  }, [procedureFilterText]);

  const SpecialtyBottomSheetContent = () => {
    return (
      <div className="date-dropdown-menu bottomsheet">
        <div className="mobile-bottomsheet-header">
          <div className="mobile-bottomsheet-search-bar">
            <i className="fa fa-search"></i>
            <input
              ref={procedureSearchInputRef}
              value={procedureFilterText}
              onChange={(e) => setProcedureFilterText(e.currentTarget.value)}
              type="text"
              placeholder="Condition, procedure, doctor . . ."
              autoFocus="false"
            />
          </div>
          <button onClick={() => setProcedureOpen(false)}>
            <i className="fa fa-times"></i>
          </button>
        </div>

        <div className="insurance-dropdown-content bottomsheet">
          <p className="search-dropdown-section-label">Popular Specialties</p>
          {filteredPopSpecialties.map((option, index) => (
            <li key={index} onClick={() => handleProcedureSelect(option)}>
              {option}
            </li>
          ))}

          <p className="search-dropdown-section-label">All Specialties</p>
          {filteredAllSpecialties.map((option, index) => (
            <li key={index} onClick={() => handleProcedureSelect(option)}>
              {option}
            </li>
          ))}
        </div>
      </div>
    );
  };

  const InsuranceBottomSheetContent = () => {
    return (
      <div className="date-dropdown-menu bottomsheet">
        <div className="mobile-bottomsheet-header">
          <div className="mobile-bottomsheet-search-bar">
            <i className="fa fa-search"></i>
            <input
              value={procedureFilterText}
              onChange={(e) => setProcedureFilterText(e.currentTarget.value)}
              type="text"
              placeholder="Search insurance"
            />
          </div>
          <button onClick={() => setInsuranceOpen(false)}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <InsuranceDropdown
          selectedInsurance={selectedInsurance}
          setSelectedInsurance={setSelectedInsurance}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          setInsuranceID={setInsuranceID}
          setPlanID={setPlanID}
          isInsuranceOpen={isInsuranceOpen}
          setInsuranceOpen={setInsuranceOpen}
          isBottomSheet={true}
          insuranceFilterText={insuranceFilterText}
          setInsuranceFilterText={setInsuranceFilterText}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
        />
      </div>
    );
  };

  useEffect(() => {
    checkNPIRegistry("1407112030").then(response => {
      console.log(response)
    })
    //Set the correct height of the blue gradient
    if (carouselRef.current) {
      const rect = carouselRef.current.getBoundingClientRect();
      const middleY = rect.top + rect.height / 2;
      setBlueGradientHeight(middleY);
    }

    //Set up google maps autocomplete
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_KEY,
      version: "weekly",
      libraries: ["geometry", "places"],
    });

    loader.load().then((google) => {
      const autocomplete = new google.maps.places.Autocomplete(
        placeAutoCompleteRef.current
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        console.log("Selected Place:", place);
        setMapsAddress(place.formatted_address);
        setMapsLocation(place);
      });

      //Getting user's location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setCurrentLatitude(position.coords.latitude);
            setCurrentLongitude(position.coords.longitude);
            const geocoder = new google.maps.Geocoder();
            const latLng = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );

            geocoder.geocode({ location: latLng }, (results, status) => {
              if (status === "OK" && results[0]) {
                var address;
                results[0].address_components.forEach((component) => {
                  const types = component.types;
                  if (types.includes("locality")) {
                    address = component.long_name;
                  } else if (types.includes("administrative_area_level_1")) {
                    address += ", " + component.long_name;
                  }
                });
                setMapsAddress(address);
              } else {
                console.error(
                  "Geocode was not successful for the following reason: " +
                    status
                );
              }
            });
          },
          (error) => {
            console.error("Error getting the user location:", error);
          }
        );
      } else {
        console.log("Geolocation is not supported");
      }
    }, []);

    const handleClickOutside = (event) => {
      if (
        procedureDropdownRef.current &&
        !procedureDropdownRef.current.contains(event.target) &&
        !isTabletOrMobile
      ) {
        setProcedureOpen(false);
      }
    };
    const handleClickOutsideIns = (event) => {
      if (
        insuranceDropdownRef.current &&
        !insuranceDropdownRef.current.contains(event.target) &&
        !isTabletOrMobile
      ) {
        setInsuranceOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    window.addEventListener("click", handleClickOutsideIns);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("click", handleClickOutsideIns);
    };
  }, [carouselRef]);

  if (isTabletOrMobile && isProcedureOpen) {
    return (
      <MobileDropdownBottomSheet
        dropdownComponent={<SpecialtyBottomSheetContent />}
        isMobileBottomSheetOpen={isProcedureOpen}
        setMobileBottomSheetOpen={setProcedureOpen}
        isFullScreen={true}
      />
    );
  } else if (isTabletOrMobile && isInsuranceOpen) {
    return (
      <MobileDropdownBottomSheet
        dropdownComponent={<InsuranceBottomSheetContent />}
        isMobileBottomSheetOpen={isInsuranceOpen}
        setMobileBottomSheetOpen={setInsuranceOpen}
        isFullScreen={true}
      />
    );
  }

  return (
    <div
      className="home-container"
      style={{
        overflowY:
          isTabletOrMobile && (isInsuranceOpen || isProcedureOpen)
            ? "hidden"
            : "hidden",
        height:
          isTabletOrMobile && (isInsuranceOpen || isProcedureOpen)
            ? "0"
            : "auto",
      }}
    >
      <div className="home-mobile-header-background">
      <AchewHeader />
      <div
        className="home-container-background-art tablet-only"
        style={{ height: `${blueGradientHeight}px` }}
      ></div>
      <div className="home-banner-container">
        <div className="home-banner-slogan">
          <h1>Find and Book</h1>
          <h2>Top Quality Healthcare</h2>
          <h3>Find providers and book appointments with Achew</h3>
        </div>
        <img alt="Three Doctors" src={three_doctors} className="desktop-only" />
      </div>

      {isTabletOrMobile ? (
        <form className="home-search-bar-container-tablet" onSubmit={search}>
          <div className="home-search-bar-fields-container-tablet">
            <div
              className="home-search-bar-input-container-tablet"
              ref={procedureDropdownRef}
            >
              <div className="home-search-bar-img-container-tablet">
                <img alt="Specialty" src={specialty_icon} />
              </div>
              <input
                type="text"
                placeholder="Condition, procedure, doctor . . ."
                value={selectedProcedure}
                onChange={handleProcedureInputChange}
                onClick={toggleProcedureDropdown}
                autoFocus={isTabletOrMobile ? "false" : "true"}
              />
              {!isTabletOrMobile && isProcedureOpen && (
                <ul className="search-dropdown-menu">
                  <p className="search-dropdown-section-label">
                    Popular Specialties
                  </p>
                  {filteredPopSpecialties.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => handleProcedureSelect(option)}
                    >
                      {option}
                    </li>
                  ))}

                  <p className="search-dropdown-section-label-tablet">
                    All Specialties
                  </p>
                  {filteredAllSpecialties.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => handleProcedureSelect(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="home-search-bar-input-container-tablet">
              <div className="home-search-bar-img-container-tablet">
                <img alt="Geo" src={geo_icon} />
              </div>

              <input
                type="text"
                placeholder="City, state, zip . . ."
                ref={placeAutoCompleteRef}
                value={mapsAddress}
                onChange={handleLocationChanged}
              />
            </div>

            <InsuranceDropdown
              selectedInsurance={selectedInsurance}
              setSelectedInsurance={setSelectedInsurance}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              setInsuranceID={setInsuranceID}
              setPlanID={setPlanID}
              isInsuranceOpen={isInsuranceOpen}
              setInsuranceOpen={setInsuranceOpen}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
            <button className="home-search-bar-btn" type="submit">
              <h1 className="desktop-only">Search</h1>
              <img alt="Search" src={search_icon} />
            </button>
          </div>
        </form>
      ) : (
        <form className="home-search-bar-container" onSubmit={search}>
          <div className="home-search-bar-fields-container">
            <div
              className="home-search-bar-input-container"
              ref={procedureDropdownRef}
            >
              <div className="home-search-bar-img-container">
                <img alt="Specialty" src={specialty_icon} />
              </div>
              <input
                type="text"
                placeholder="Condition, procedure, doctor . . ."
                value={selectedProcedure}
                onChange={handleProcedureInputChange}
                onClick={toggleProcedureDropdown}
                autoFocus={isTabletOrMobile ? "false" : "true"}
              />
              {isProcedureOpen && (
                <ul className="search-dropdown-menu">
                  <p className="search-dropdown-section-label">
                    Popular Specialties
                  </p>
                  {filteredPopSpecialties.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => handleProcedureSelect(option)}
                    >
                      {option}
                    </li>
                  ))}

                  <p className="search-dropdown-section-label">
                    All Specialties
                  </p>
                  {filteredAllSpecialties.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => handleProcedureSelect(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="home-search-bar-input-container">
              <div className="home-search-bar-img-container">
                <img alt="Geo" src={geo_icon} />
              </div>

              <input
                type="text"
                placeholder="City, state, zip . . ."
                ref={placeAutoCompleteRef}
                value={mapsAddress}
                onChange={handleLocationChanged}
              />
            </div>

            <InsuranceDropdown
              selectedInsurance={selectedInsurance}
              setSelectedInsurance={setSelectedInsurance}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              setInsuranceID={setInsuranceID}
              setPlanID={setPlanID}
              setInsuranceOpen={setInsuranceOpen}
              isInsuranceOpen={isInsuranceOpen}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          </div>

          <button className="home-search-bar-btn" type="submit">
            <h1>Search</h1>
            <img alt="Search" src={search_icon} />
          </button>
        </form>
      )}
      </div>
      

      <div className="home-specialties-container">
        <div className="home-specialties-slogan">
          {isTabletOrMobile ? (
            <h1>
              Consult <b>Top Doctors</b>{" "}
            </h1>
          ) : (
            <h1>
              Consult <b>Top Doctors</b> for <b>Any Health </b>Concern
            </h1>
          )}
        </div>
        {!isPureMobile && (
          <div className="home-specialties-arrows-container">
            <button
              className="home-specialties-arrow"
              onClick={() => shiftCarouselRight()}
            >
              <img alt="arrow right" src={arrow_left} />
            </button>
            <button
              className="home-specialties-arrow"
              onClick={() => shiftCarouselLeft()}
            >
              <img alt="arrow left" src={arrow_right} />
            </button>
          </div>
        )}
      </div>
      <div
        className={isPureMobile ? "home-specialties-carousel-container-mobile" : "home-specialties-carousel-container"}
        ref={carouselRef}
        style={{
          transform: `translateX(${carouselWidth * carouselOffsetIndex}px)`,
        }}
      >
        <SpecialtyCarousel
          title="Primary Care"
          description="Your Wellness, our priority: Primary care excellence every step of the way."
          imageObject={primary_care}
          navLink={`/search?specialty=Primary%20Care%20Physician%20(PCP)&distance=0&latitude=${currentLatitude}&longitude=${currentLongitude}&insurance=${insuranceID}&plan=${planID}&date=${new Date().getTime()}`}
        />
        <SpecialtyCarousel
          title="Dentist"
          description="Smiles shine brighter here: Where dental care meets excellence."
          imageObject={dentist}
          navLink={`/search?specialty=Dentist&distance=0&latitude=${currentLatitude}&longitude=${currentLongitude}&insurance=${insuranceID}&plan=${planID}&date=${new Date().getTime()}`}
        />
        <SpecialtyCarousel
          title="OB-GYN"
          description="Empathy in every stage: Exceptional OBGYN care for women's health."
          imageObject={obgyn}
          navLink={`/search?specialty=OB-GYN%20(Obstetrician-Gynecologist)&distance=0&latitude=${currentLatitude}&longitude=${currentLongitude}&insurance=${insuranceID}&plan=${planID}&date=${new Date().getTime()}`}
        />
        <SpecialtyCarousel
          title="Dermatologist"
          description="Glowing skin, radiant health: Your dermatology partner for life."
          imageObject={dermatologist}
          navLink={`/search?specialty=Dermatologist&distance=0&latitude=${currentLatitude}&longitude=${currentLongitude}&insurance=${insuranceID}&plan=${planID}&date=${new Date().getTime()}`}
        />
        <SpecialtyCarousel
          title="Psychiatrist"
          description="Mindful healing, expert care: Where mental well-being matters most."
          imageObject={psychiatrist}
          navLink={`/search?specialty=Psychiatrist&distance=0&latitude=${currentLatitude}&longitude=${currentLongitude}&insurance=${insuranceID}&plan=${planID}&date=${new Date().getTime()}`}
        />
        <SpecialtyCarousel
          title="Eye Doctor"
          description="Clarity in sight, precision in care: Your vision, our commitment."
          imageObject={eye_doctor}
          navLink={`/search?specialty=Eye%20Doctor&distance=0&latitude=${currentLatitude}&longitude=${currentLongitude}&insurance=${insuranceID}&plan=${planID}&date=${new Date().getTime()}`}
        />
      </div>

      {/* <div className="home-recommended-container">
        <h1>Recommended Clinics & Providers</h1>
        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>
        <div className="home-recommended-carousel">
          <ClinicCard/>
          <ClinicCard/>
          <ClinicCard/>
          <ClinicCard/>
        </div>

      </div> */}

      <div className="home-find-appointment-container">
        <div className="home-find-appointment-mesh-container desktop-only">
          <img alt="mesh left" src={mesh_arrow_left} className="desktop-only" />
          <img
            alt="mesh right"
            src={mesh_arrow_right}
            className="desktop-only"
          />
        </div>
        <div className="home-find-appointment-main-container">
          <div className="home-find-appointment-title-section">
            <h1>Manage your Appointment</h1>
            <h2>
              Did you make an appointment with Achew? You can monitor and manage
              the status of you appointment.
            </h2>
            <div className="home-find-appointment-title-section-images desktop-only">
              <img
                alt="swirl arrow"
                src={swirl_arrow}
                className="home-find-appointment-title-section-swirl"
              />
              <img
                alt="single doctor"
                src={single_doctor1}
                className="home-find-appointment-title-section-doctor"
              />
            </div>
          </div>
          <div className="home-find-appointment-steps-section">
            <div className="home-find-appointment-step">
              <div className="home-find-appointment-img-container">
                <img alt="Search" src={search_filled} />
              </div>
              <div className="home-find-appointment-step-txt">
                <h1>STEP 1</h1>
                <h2>Search the Best Doctor</h2>
                <h3>
                  Search from top rated doctors in your neighborhood. Choose
                  from Achew's vast network.
                </h3>
              </div>
            </div>
            <div className="home-find-appointment-step">
              <div className="home-find-appointment-img-container">
                <img alt="Search" src={two_users} />
              </div>
              <div className="home-find-appointment-step-txt">
                <h1>STEP 2</h1>
                <h2>Match With Your Requirement</h2>
                <h3>
                  Find clinics that can best serve your needs. Discover
                  in-network providers and save on visits.
                </h3>
              </div>
            </div>
            <div className="home-find-appointment-step">
              <div className="home-find-appointment-img-container">
                <img alt="Search" src={calendar_icon2} />
              </div>
              <div className="home-find-appointment-step-txt">
                <h1>STEP 3</h1>
                <h2>Schedule An Appointment</h2>
                <h3>
                  Creating an appointment is fast and simple using Achew. Avoid
                  the hassle and inconsistencies of calling.
                </h3>
              </div>
            </div>
            <button
              className="home-find-appointment-btn"
              onClick={() => navigate("/appointment-search")}
            >
              <h1>Find your Appointment</h1>{" "}
              <img alt="arrow white" src={arrow_top_right_white} />
            </button>

            {isTabletOrMobile && (
              <img
                alt="single doctor"
                src={single_doctor1}
                className="home-find-appointment-title-section-doctor"
              />
            )}
          </div>
        </div>
      </div>
      <div className="home-practice-container">
        <div className="home-practice-txt-container">
          <h1>Promote your hospital with Achew</h1>
          <h2>
            Achew provides powerful, easy-to-use tools for providers to manager
            their appointments.
          </h2>
          <div className="home-practice-point">
            <img alt="check" src={blue_check} />
            <h3>
              Promote your internet presence with a free profile page tailored
              for your practice.
            </h3>
          </div>
          <div className="home-practice-point">
            <img alt="check" src={blue_check} />
            <h3>
              Find new patients and provide an easy way for your patients to
              book appointments
            </h3>
          </div>
          <div className="home-practice-point">
            <img alt="check" src={blue_check} />
            <h3>
              Join the fast growing network of providers and patients using
              Achew!
            </h3>
          </div>
          {/* <div className="home-practice-point">
            <img alt="check" src={blue_check} />
            <h3>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit nunc ut
              massa ultrices.
            </h3>
          </div> */}

          <button
            className="home-practice-btn"
            onClick={() => navigate("/welcome")}
          >
            <h1>List your practice on Achew for free</h1>{" "}
            <img alt="arrow white" src={arrow_top_right_white} />
          </button>
        </div>
        <div className="home-practice-image-container desktop-only">
          <img
            alt="Background "
            src={single_doc2background}
            className="home-practice-background"
          />
          <img
            alt="Doc2"
            src={single_doctor2}
            className="home-practice-doctorimg"
          />
        </div>
      </div>

      <AchewFooter />
    </div>
  );
};

export default Home;
