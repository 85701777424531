import { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

const debuggingMode = true
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

const RequestLogin = async (email, password) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/account/login/',
            data: {
                email: email,
                password: password,
            }
        })

        if(response.status == 200){
            console.log("Login", response)
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`
            localStorage.setItem("exp", (response.data.creation * 1000 + 290000).toString())
        }
        return response

    } catch (err) {
        return err
    }
}

const RequestGoogleLogin = async (access_token) => {
    try {
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

        const response = await axios({
            method: 'post',
            url: '/account/google/',
            data: {
                "google-token": access_token,
            }
        })

        return response

    } catch (err) {
        return err
    }
}

const invalidateRefreshToken = async () => {
    try {
        const response = await axios({
            method: 'post',
            url: '/account/logout/',
        })
        return response

    } catch (err) {
        return err
    }
}

const RequestRegister = async (formData) => {
    try {

        const response = await axios({
            method: 'post',
            url: '/account/register/',
            data: {
                email: formData.account_email,
                password: formData.password,
                date_of_birth: "2000-12-12",
                password2: formData.password,
                first_name: formData.first_name,
                last_name: formData.last_name,
                verify_code: formData.verify_code
            }
        })
        return response


    } catch (err) {
        console.log(err)
        return err
    }
}


const RequestHospitalRegistration = async (onboardingData) => {

    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }
        const response = await axios({
            method: 'post',
            url: '/testapi/hospital-internal/',
            data: onboardingData
        })
        console.log("res", response)
        return response


    } catch (err) {
        console.log("erre", err)
        return err
    }
}

const UpdateAppointmentSlotSeries = async (hospitalID, series) => {
    try {

        const scheduleData = {
            "tags": series
        }
        console.log(JSON.stringify(scheduleData))
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-external/${hospitalID}/`,
            data: scheduleData
        })

        return response


    } catch (err) {
        return err
    }
}

const changePasswordInternal = async (oldPassword, newPassword) => {
    try {

        const passwordData = {
            "current_password": oldPassword,
            "new_password": newPassword
        }
        const response = await axios({
            method: 'post',
            url: `/account/internal-change-password/`,
            data: passwordData
        })

        return response

    } catch (err) {
        return err
    }
} 
const sendPasswordChangeEmail = async (email) => {
    try {

        const passwordData = {
            'email': email
        }
        const response = await axios({
            method: 'post',
            url: `/account/request-external-change-password/`,
            data: passwordData
        })
        console.log(response)
        return response

    } catch (err) {
        console.log(err)
        return err
    }
} 

const changePasswordExternal = async (newPassword, token) => {
    try {

        const passwordData = {
            "new_password": newPassword,
            "token": token
        }
        const response = await axios({
            method: 'post',
            url: `/account/handle-external-change-password/`,
            data: passwordData
        })

        return response

    } catch (err) {
        return err
    }
} 

const refreshToken = async () => {
    try {

        const response = await axios({
            method: 'post',
            url: `/account/refresh-login/`,
        })
        if(response.status == 200){
            console.log("Refreshing Access Token", response.data)
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`
            localStorage.setItem("exp", (response.data.creation * 1000 + 290000).toString())
        }

        return response

    } catch (err) {
        return err.response
    }
}

export { 
    RequestLogin, 
    RequestGoogleLogin, 
    RequestRegister, 
    RequestHospitalRegistration, 
    UpdateAppointmentSlotSeries,
    changePasswordInternal,
    changePasswordExternal,
    sendPasswordChangeEmail,
    invalidateRefreshToken,
    refreshToken 
}