import React, { useState, useEffect, useRef } from "react";
import ReactToggle from 'react-toggle'
import Alert from "../../alert";
import "react-toggle/style.css"
import { useNavigate, useLocation } from "react-router-dom";
import DoctorHeader from "../doctorheader";
import DoctorSideBar from "./dashboardsidebar";
import { refreshToken } from "../../../../api/auth";
import {
  getAccountInfo,
  getAppointmentByID,
  getHospitalInfoByInternalID,
  getDoctorAccountInfo,
  GetAccountBillingHistory,
  updateAutoChargeInfo,
  updateHospitalActive,
  GetNextBillingHistoryPage,
  deleteAutoChargeInfo,
  checkDefaultPayment
} from "../../../../api/patientAPIs";
import { useDispatch, useSelector } from "react-redux";
import { setAccountInfo } from "../../../../hooks/accountSlice";
import Loading from '../../loading';
import { ensureHttps, findObjectById, formatDateToLongString } from "../../../assets/functions/functions";
import PaymentModal from "./payment-modal";
import { autopay_enabled_icon } from "../../home/image";
import PopupAlert from "./popup-alert";
import LoadingSpinner from "../../patients/bookingv2/spinner";
import { OnboardingModal, OnboardingTypes } from "./patient-modals/onboarding-popup-modal";
import PricingModal from "./patient-modals/pricing-modal";
import PaymentSetupModal from "./patient-modals/payment-setup-modal";

const PlanBilling = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const currentHospitalID = searchParams.get("hospital");
  const successfulPaymentParam = searchParams.get("success_redirect")
  const [hospitalData, setHospitalData] = useState(null);
  const [accountData, setAccountData] = useState(null)
  const [currentHospitalData, setCurrentHospitalData] = useState({
    hospital_name: "",
    services: [],
  });
  const [isPageLoading, setPageLoading] = useState(true)
  const [paymentModalOpen, setPaymentModalOpen] = useState(null)
  const [hospitalActiveSpinnerStates, setHospitalActiveSpinnerStates] = useState([])

  // Auto Recharge States
  const [isAutopayEnabled, setIsAutoPayEnabled] = useState(null)
  const [planLimit, setPlanLimit] = useState(0)
  const [planAmount, setPlanAmount] = useState(0)
  const [planCondition, setPlanCondition] = useState(0)
  const [isEdittingRecharge, setIsEdittingRecharge] = useState(false)
  const [setupSpinnerLoading, setSetupSpinnerLoading] = useState(false)
  const [didAgreeTerms, setDidAgreeTerms] = useState(false)
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("")
  const [isUpdatingAutoCharge, setIsUpdatingAutoCharge] = useState(false)
  const [showValidationError, setShowValidationError] = useState(false)
  const [defaultPaymentExists, setDefaultPaymentExists] = useState(false)

  // Billing History
  const [billingHistory, setBillingHistory] = useState([])
  const [isPaginationLoading, setIsPaginationLoading] = useState(false)
  const [paginationData, setPaginationData] = useState(null)

  //Pricing
  const [isPricingModalOpen, setPricingModalOpen] = useState(false)

  //Payment Setup
  const [isPaymentSetupModalOpen, setPaymentSetupModalOpen] = useState(false)
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState("")

  const renderPaymentModal = () => {
    if (paymentModalOpen) {
      return (
        <PaymentModal setPaymentModalOpen={setPaymentModalOpen} accountData={accountData} currentHospitalID={currentHospitalID} />
      )
    }
  }

  function convertCentsToDollars(cents) {
    const dollars = cents / 100;
    const formattedDollars = dollars.toFixed(2);
    return formattedDollars;
  }

  const formatAmount = (value) => {
    // Ensure centAmount is a valid number
    const parsedCents = parseFloat(value);
    if (isNaN(parsedCents)) {
      return ""
    }

    // Convert cents to dollars with two decimal places
    const dollars = (parsedCents / 100).toFixed(2);
    return "$" + dollars;

  };

  function handleAmountInput(event, setValue) {
    const input = event.target;
    // Remove non-numeric characters from the input value
    input.value = input.value.replace(/[^0-9]/g, '');
    setValue(event.currentTarget.value)
  }

  const toggleAlertWithMessage = (message, withDuration) => {
    setAlertMessage(message)
    setShowAlert(true)
  }

  useEffect(() => {
    getDoctorAccountInfo().then(response => {
      console.log(response)
    });

    GetAccountBillingHistory().then(response => {
      if (response.status === 200) {
        setBillingHistory(response.data.results)
        setPaginationData(response.data)
      }
    })
    getAccountInfo().then((res) => {
      if (res.status == 200) {
        dispatch(setAccountInfo(res.data));
        setAccountData(res)
        const hospitalList = res.hospital;
        var promises = [];
        hospitalList.forEach((hID) => {
          promises.push(getHospitalInfoByInternalID(hID));
        });

        Promise.all(promises).then((results) => {
          const tempHospitalList = [];
          results.forEach((res) => tempHospitalList.push(res.data));
          setHospitalData(tempHospitalList);
          const tempHospitalData = findObjectById(currentHospitalID, tempHospitalList)
          setCurrentHospitalData(
            tempHospitalData
          );

          setPageLoading(false)
          if (successfulPaymentParam) {
            toggleAlertWithMessage(`Your payment of ${successfulPaymentParam} was successful`)
          }

        });

        setIsAutoPayEnabled(res.payment_rule === "N")
        setPlanAmount(res.plan_amount)
        setPlanCondition(res.plan_condition)
        setPlanLimit(res.plan_limit)

        checkDefaultPayment().then(response => {
          setDefaultPaymentExists(response.status)
          if(response.status) {
            if(response.type === "link"){
              setDefaultPaymentMethod(`Stripe Link`)
            }
            else if(response.type === "card"){
              setDefaultPaymentMethod(`Card ending in ${response.last4}`)
            }else if (response.type === "us_bank_account"){
              setDefaultPaymentMethod(`Bank account ending in ${response.last4}`)
            }else{
              setDefaultPaymentMethod("Other payment method")
            }
          } else {
            setDefaultPaymentMethod("No payment method set")
          }
        })

      }

      if (res.status == 401 || res.status == 403) {
        refreshToken().then((response) => {
          if (response.status == 200) {
            console.log("Successfully refreshed token");
            window.location.reload();
          } else {
            navigate("/doctor-login");
          }
        });
      }
    });

  }, []);

  const handleDeleteRechargePlan = () => {
    setIsUpdatingAutoCharge(true)
    deleteAutoChargeInfo().then(response => {
      setIsAutoPayEnabled(false)
      setIsUpdatingAutoCharge(false)
    })
      .catch(err => {
        console.log(err)
        setIsUpdatingAutoCharge(false)
      })
  }

  const handleEditRechargePlan = (shouldCheck, planLimit, planAmount, planCondition, didAgreeTerms) => {
    setIsUpdatingAutoCharge(true)
    if (planLimit > 500000 || planAmount > 500000 || planCondition < 0 || !(didAgreeTerms || shouldCheck)) {
      setShowValidationError(true)
      setIsUpdatingAutoCharge(false)
      return
    }
    updateAutoChargeInfo(planAmount, planLimit, planCondition).then(response => {
      console.log(response)
      if (response.status >= 200 && response.status < 300) {
        setIsAutoPayEnabled(true)
        setIsEdittingRecharge(false)
        setIsUpdatingAutoCharge(false)
      } else {
        toggleAlertWithMessage(response.response.data.msg)
        setIsUpdatingAutoCharge(false)
      }
    })
      .catch(err => {
        setIsUpdatingAutoCharge(false)
      })
  }

  const handleShowMoreBilling = () => {
    setIsPaginationLoading(true)
    GetNextBillingHistoryPage(ensureHttps(paginationData.next)).then(response => {
      const tempBillingHistory = [...billingHistory, ...response.data.results]
      setBillingHistory(tempBillingHistory)
      setPaginationData(response.data)
      setIsPaginationLoading(false)
    })
      .catch(err => {
        console.log(err)
        setIsPaginationLoading(false)
      })
  }

  const handleToggleHospitalFunds = (hospital) => {
    if (hospitalActiveSpinnerStates.length > 0) {
      return
    }
    const tempHospitalActiveList = [...hospitalActiveSpinnerStates]
    tempHospitalActiveList.push(hospital.external_id)
    setHospitalActiveSpinnerStates(tempHospitalActiveList)



    updateHospitalActive(!hospital.is_profile_active, hospital.external_id)
      .then(response => {
        const tempHospitalList = hospitalData.map(tempHospital => {
          if (hospital.external_id === tempHospital.external_id) {
            tempHospital.is_profile_active = !tempHospital.is_profile_active
            return tempHospital
          } else {
            return tempHospital
          }
        })
        console.log(response)
        setHospitalData(tempHospitalList)
        setHospitalActiveSpinnerStates(hospitalActiveSpinnerStates.filter(h => h.external_id !== hospital.external_id))
      })
      .catch(err => {
        console.log(err)
        setHospitalActiveSpinnerStates(hospitalActiveSpinnerStates.filter(h => h.external_id !== hospital.external_id))
      })
  }

  const renderManageHospitalRow = (hospital) => {
    return (
      <div className="plan-container-right-hospital">
        <div className="plan-container-right-hospital-header">
          <h2>{hospital.hospital_name}</h2>
          <h3>{hospital.location_info.street_address}, {hospital.location_info.zip}</h3>
        </div>
        <div className="d-flex">
          {hospitalActiveSpinnerStates.indexOf(hospital.external_id) !== -1 && <LoadingSpinner />}
          <ReactToggle
            className="pe-visible-toggle-margin-right achew-toggle"
            checked={hospital.is_profile_active}
            color
            onChange={() => handleToggleHospitalFunds(hospital)}
          />
        </div>


      </div>
    )
  }

  const renderInvoiceStatus = (status) => {
    if (status === "CREATED") {
      return (
        <div className="plan-container-left-table-status pending">
          Pending
        </div>
      )
    } else if (status === "paid") {
      return (
        <div className="plan-container-left-table-status accepted">
          Paid
        </div>
      )
    } else {
      return (
        <div className="plan-container-left-table-status declined">
          Void
        </div>
      )
    }
  }

  const renderInvoiceRow = (invoice) => {
    return (
      <tr>
        <td><a href={invoice.invoice_url} target="_blank" rel="noopener noreferrer">Invoice #{invoice.id}-({formatDateToLongString(new Date(invoice.created))})</a></td>
        <td>{formatDateToLongString(new Date(invoice.created))}</td>

        <td>
          <div className="plan-container-left-table-status">
            {renderInvoiceStatus(invoice.status)}
          </div>
        </td>
        <td>${(invoice.amount / 100.0).toFixed(2)}</td>

      </tr>
    )
  }


  const handleDefaultPaymentMethod = () => {
    // Open a new tab with the Google Maps URL
    window.open(`https://billing.stripe.com/p/login/${process.env.REACT_APP_STRIPE_SETUP_KEY}`, "_blank");
  };

  if (isPageLoading) {
    return (<Loading />)
  }

  const pastInvoices = billingHistory.filter(invoice => invoice.status === "paid").sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));
  const ongoingInvoices = billingHistory.filter(invoice => invoice.status === "CREATED").sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));
  console.log(ongoingInvoices, pastInvoices, billingHistory)

  return (
    <div className="plan-supercontainer">
      <PopupAlert showAlert={showAlert} setShowAlert={setShowAlert} alertMessage={alertMessage} />
      <PaymentSetupModal handleEditRechargePlan={handleEditRechargePlan} currentHospitalData={currentHospitalData} isPaymentSetupModalOpen={isPaymentSetupModalOpen} setPaymentSetupModalOpen={setPaymentSetupModalOpen}/>
      <PricingModal isPricingModalOpen={isPricingModalOpen} setPricingModalOpen={setPricingModalOpen} />
      <DoctorHeader
        hospitalData={hospitalData}
        currentHospitalData={currentHospitalData}
      />
      <DoctorSideBar
        currentTab={5}
        hospitalData={hospitalData}
        currentHospitalData={currentHospitalData}
      />
      <OnboardingModal onboardingInfoToShow={[OnboardingTypes.AUTOPAY]} />
      {renderPaymentModal()}
      <div className="plan-container">
        <div className="plan-container-left-right">
          <div className="plan-container-left">
            <div className="plan-container-left-subcontainer">
              <div className="plan-container-left-header">
                <h1>Your Achew Balance</h1>
                <div className="d-flex">
                  <button className="plan-container-balance-container" onClick={() => setPricingModalOpen(true)}>
                    <h2>View Pricing</h2>
                  </button>
                  <button className="plan-container-default-payment-container" onClick={() => handleDefaultPaymentMethod()}>
                    <h2>Edit Payment Method</h2>
                  </button>
                </div>
              </div>
              <div className="plan-container-left-plan">
                <div className="plan-container-left-plan-col">
                  <h2>Plan</h2>
                  <h3>Achew Standard</h3>
                </div>
                {/* <div className="plan-container-left-plan-col">
                  <h2>Sponsored Time</h2>
                  <h3>30 days</h3>
                </div> */}

              </div>
              <div className="plan-container-left-account-summary">
                <div className="plan-container-left-account-summary-header">
                  <h2>Plan usage</h2>
                  {/* <div className="plan-container-left-account-summary-header-updated">Updated 22 hours ago</div> */}
                </div>
                {/* <h3>Usage resets on Apr 2, 2020</h3> */}
                <div className="plan-container-left-account-summary-header2">
                  <h2>Achew Balance</h2>
                  <h3>${convertCentsToDollars(accountData.credits)}</h3>
                </div>
                {/* <div className="plan-container-left-progress-bar">
                  <div className="plan-container-left-progress-bar-inner" style={{ width: `${convertCentsToDollars(accountData.credits) / 1000.0 * 100}%` }}></div>
                </div> */}

                <div className="plan-container-left-footer">
                  <div className="plan-container-left-progress-stats">
                    <div className="plan-container-left-progress-stats-dot"></div>
                    <h2><b>${convertCentsToDollars(accountData.credits)}</b> remaining</h2>
                  </div>
                  <button className="plan-container-send-invoice-button" onClick={() => setPaymentModalOpen(true)}>Add Funds</button>
                </div>
              </div>


            </div>
            <div className="plan-container-left-subcontainer plan-container-left-subcontainer-invoices">
              <div className="plan-container-right-header">
                <h1>Invoices</h1>
              </div>
              <div className="plan-container-left-table-container">
                {
                  (ongoingInvoices && ongoingInvoices.length > 0 || pastInvoices && pastInvoices.length > 0) ?
                    <div className="d-flex flex-column">
                      <table className="plan-container-left-invoice-table">
                        <thead>
                          <tr>
                            <th>Invoice</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ongoingInvoices.map(invoice => renderInvoiceRow(invoice))}
                          {pastInvoices.map(invoice => renderInvoiceRow(invoice))}
                          {/* Add more rows as needed */}
                        </tbody>
                      </table>
                      {isPaginationLoading ?
                        <LoadingSpinner />
                        :
                        (paginationData.next && <button className="plan-container-show-more-button" onClick={() => handleShowMoreBilling()}>Show more</button>)
                      }
                    </div>


                    :
                    <div className="d-flex flex-column">
                      <h2 className="plan-container-no-invoice-history">No invoice history</h2>
                      <h2 className="plan-container-no-invoice-history-2">Your invoices will be listed here once you make a payment</h2>
                    </div>

                }

              </div>


            </div>

            {/* <div className="plan-container-left-subcontainer">
              <div className="plan-container-left-header">
                <h1>Invoices</h1>
                <h2>Access all your previous invoices.</h2>
              </div>

              <div className="plan-container-left-history">
                {pastInvoices.length > 0 ? pastInvoices.map(invoice => renderBilling(invoice)) : <h2 className="plan-container-left-subcontainer-h2">You have no past invoices</h2>}
              </div>
            </div> */}
          </div>
          {
            defaultPaymentExists ?
              <div className="plan-container-right">
                {
                  isAutopayEnabled && !isEdittingRecharge ?
                    <div className="plan-container-right-subcontainer">
                      <div className="plan-container-right-header">
                        <h1>Auto Charge</h1>
                        <h2 onClick={() => setIsEdittingRecharge(true)}>Edit plan</h2>
                      </div>
                      <img
                        src={autopay_enabled_icon}
                        alt="Auto pay"
                      />
                      <div className="plan-container-right-info">
                        <h2>Auto Charge when my Balance Falls Below</h2>
                        <h3>{formatAmount(planCondition)}</h3>
                      </div>
                      <div className="plan-container-right-info">
                        <h2>Amount to add </h2>
                        <h3>{formatAmount(planAmount)}</h3>
                      </div>
                      <div className="plan-container-right-info">
                        <h2>Monthly recharge limit </h2>
                        <h3>{formatAmount(planLimit)}</h3>
                      </div>
                      <button className="plan-container-auto-recharge-button-delete" onClick={() => handleDeleteRechargePlan()}>Remove plan</button>
                    </div>
                    :
                    <div className="plan-container-right-subcontainer">
                      <div className="plan-container-right-header plan-container-right-header2">
                        <h1>Auto Charge</h1>
                        <h4>We highly recommend that you set up auto recharge balance so that your booking feature will always be available.</h4>
                      </div>
                      <div className="plan-container-right-info">
                        <h2>Auto Charge when my balance falls below</h2>
                        <input
                          className="plan-container-auto-recharge-input"
                          value={formatAmount(planCondition)}
                          placeholder="$0.00"
                          onChange={(e) => handleAmountInput(e, setPlanCondition)}
                        />
                      </div>
                      <div className="plan-container-right-info">
                        <h2>Amount to add</h2>
                        <input
                          className="plan-container-auto-recharge-input"
                          value={formatAmount(planAmount)}
                          placeholder="$0.00"
                          onChange={(e) => handleAmountInput(e, setPlanAmount)}
                        />
                      </div>
                      <div className="plan-container-right-info">
                        <h2>Monthly recharge limit</h2>
                        <input
                          className="plan-container-auto-recharge-input"
                          value={formatAmount(planLimit)}
                          placeholder="$0.00"
                          onChange={(e) => handleAmountInput(e, setPlanLimit)}
                        />
                      </div>
                      <div className="plan-container-right-info">
                        <h2>Default Payment Method</h2>
                        <input
                          className="plan-container-auto-recharge-input"
                          style={{color:"#aaa"}}
                          value={defaultPaymentMethod}
                          contentEditable={false}
                          disabled={true}
                        />
                      </div>
                      <div className="plan-container-left-autocharge">
                        <input
                          type="checkbox"
                          checked={didAgreeTerms}
                          onChange={() => setDidAgreeTerms(!didAgreeTerms)}
                        />
                        <h2>I understand that I will be charged automatically based on the set rules.</h2>
                      </div>
                      {
                        showValidationError &&
                        <h3 className="missingFields">*Please check the box above to continue.</h3>
                      }
                      <button className="plan-container-auto-recharge-button" onClick={() => handleEditRechargePlan(false, planLimit, planAmount, planCondition, didAgreeTerms)}>{isUpdatingAutoCharge ? <LoadingSpinner /> : "Set up AutoCharge"}</button>
                    </div>
                }

                <div className="plan-container-right-subcontainer">
                  <div className="plan-container-right-header plan-container-right-header2">
                    <h1>Hospital plans</h1>
                    <h4>We highly recommend that you set up auto recharge balance so that your booking feature will always be available.</h4>
                  </div>
                  <div className="plan-container-right-hospital-list">
                    {hospitalData.map(hospital => renderManageHospitalRow(hospital))}
                  </div>
                </div>
              </div>
              :
              <div className="plan-container-right">
                <div className="plan-container-right-subcontainer">
                  <div className="plan-container-right-header plan-container-right-header2">
                    <h1>Auto Charge</h1>
                    <h4>To autocharge your account whenever your account balance is low, please first set a default payment method.</h4>
                  </div>
                  <button className="plan-container-default-payment-container2" onClick={() => setPaymentSetupModalOpen(true)}>
                    <h2>Set Payment Method</h2>
                  </button>
                </div>
                <div className="plan-container-right-subcontainer">
                  <div className="plan-container-right-header plan-container-right-header2">
                    <h1>Hospital plans</h1>
                    <h4>We highly recommend that you set up auto recharge balance so that your booking feature will always be available.</h4>
                  </div>
                  <div className="plan-container-right-hospital-list">
                    {hospitalData.map(hospital => renderManageHospitalRow(hospital))}
                  </div>
                </div>
              </div>
          }
          

        </div>
      </div>
    </div >
  );
};

export default PlanBilling;

