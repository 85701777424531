import React, { useState, useRef } from 'react';
import { useDrag } from 'react-use-gesture';
import { useSpring, animated } from 'react-spring';

const CircularSlider = ({sliderPrice, setSliderPrice}) => {
    const [value, setValue] = useState(0);
  const [xPos, setXPos] = useState(0);
  const sliderRef = useRef(null);
  const handleWidth = 25; // Width of the handle
  const [{ x }, set] = useSpring(() => ({ x: 0 }));

  const bind = useDrag(({ movement: [mx], memo = xPos }) => {
    const sliderWidth = sliderRef.current ? sliderRef.current.clientWidth : 200;
    const maxPos = sliderWidth - handleWidth;
    const clampedX = Math.max(0, Math.min(mx + memo, maxPos)); // Ensure handle stays within the bar width
    const normalizedValue = Math.round((clampedX / maxPos) * 100);
    setValue(normalizedValue);
    set({ x: clampedX });
    setXPos(clampedX);
    setSliderPrice(parseInt(normalizedValue * 0.01 * 500 * 0.75))
    return memo;
  });

  return (
    <div className="c-slider-container">
      <div className="c-slider-bar" ref={sliderRef}>
        <animated.div className="c-slider-bar-overlay" style={{ width: x }} />
        <animated.div {...bind()} className="c-slider-handle" style={{ x }} />
      </div>
      <animated.div className="c-value-dialog" style={{ x }}>
        {parseInt(value * 0.01 * 500)}
      </animated.div>
        <div className="c-value-min">
        0
        </div>
        <div className="c-value-max">
        500
        </div>
    </div>
  );
};

export default CircularSlider;
