import React, { useState, useEffect, useRef } from 'react';
import LoadingSpinner from '../../../patients/bookingv2/spinner';
const DeleteDoctorModal = ({ onRequestClose, deleteDoctor, doctorData}) => {
    const [isDeleting, setIsDeleting] = useState(false)
    return (
        <div className="confirmation-modal-overlay">
            <div className="confirmation-modal">
            <h2 className="confirmation-modal-title">Are you sure you want to delete Dr. {doctorData.doctor_name_first} {doctorData.doctor_name_last}</h2>
                    <p className="confirmation-modal-description">Appointments made for this doctor will still be active until cancelled or completed. Appointments for this doctor will not be shown in calendar but will be available in appointments manager.</p>
                    
                <div className="confirmation-modal-buttons">
                    <button className="confirmation-modal-button cancel" onClick={() => onRequestClose()}>
                        Cancel
                    </button>
                    <button className="confirmation-modal-button decline" onClick={() => deleteDoctor(setIsDeleting)}>
                        {isDeleting ? <LoadingSpinner /> : "Delete"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteDoctorModal;