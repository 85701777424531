import React from 'react';
import LoadingSpinner from './patients/bookingv2/spinner';

function LoaderWrapper({ isLoading, children, message }) {
  return (
    <div className={`loader-wrapper ${isLoading ? 'loading' : ''}`}>
      {children}
      {isLoading && <div className="loading-overlay">
        <LoadingSpinner message={message}/></div>}
    </div>
  );
}

export default LoaderWrapper;