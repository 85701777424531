import React, { useState, useRef, useEffect } from "react";
import "./calendar.css";
import WeeklyDateSlot from "./calendar-weekly-slot.jsx";
import { addDaysToDate, areDatesEqual, sortAppointmentsByStartTime,groupSameStartTime, formatDateMMDDYYYY } from "./helper-functions.jsx";

const CalendarWeekly = ({
  year,
  month,
  appointmentDataDictionary,
  setPopupOpen,
  setPopupModalData,
  displayedDateWeekly,
  appointmentList,
}) => {
  const today = new Date();
  const [distance, setDistance] = useState(null);
  const component1Ref = useRef(null);
  const component2Ref = useRef(null);
  const [height, setHeight] = useState(null);
  const componentHeightRef = useRef(null);
  const sunday = displayedDateWeekly;
  const monday = addDaysToDate(displayedDateWeekly, 1);
  const tuesday = addDaysToDate(displayedDateWeekly, 2);
  const wednesday = addDaysToDate(displayedDateWeekly, 3);
  const thursday = addDaysToDate(displayedDateWeekly, 4);
  const friday = addDaysToDate(displayedDateWeekly, 5);
  const saturday = addDaysToDate(displayedDateWeekly, 6);

  useEffect(() => {
    const calculateDistance = () => {
      if (component1Ref.current && component2Ref.current) {
        const rect1 = component1Ref.current.getBoundingClientRect();
        const rect2 = component2Ref.current.getBoundingClientRect();

        // Calculate distance using Pythagorean theorem
        const distance = Math.sqrt(
          Math.pow(rect2.x - rect1.x, 2) + Math.pow(rect2.y - rect1.y, 2)
        );

        setDistance(distance);
      }
    };

    // Call calculateDistance whenever the components are mounted or updated
    calculateDistance();

    const updateHeight = () => {
      if (componentHeightRef.current) {
        const rect = componentHeightRef.current.getBoundingClientRect();
        setHeight(rect.height);
      }
    };

    // Update the height whenever the component is mounted or updated
    updateHeight();

    // You can also add event listeners or other dependencies here
  }, [component1Ref, component2Ref, componentHeightRef, appointmentDataDictionary, appointmentList]);

  const renderTodayRedLine = () => {
    const hours = today.getHours();
    const minutes = today.getMinutes();

    // Calculate total minutes since midnight
    const totalMinutes = hours * 60 + minutes;

    // Calculate the percentage of the day
    const percentageOfDay = (totalMinutes / (24 * 60)) * distance * 24;
    return <div className="calendar-red-line" style={{top:percentageOfDay}}></div>;
  };

  const calculateWeeklyWithOverlap = (dayAppointmentList) => {
    if(dayAppointmentList == null || dayAppointmentList.length === 0){
      return []
    }
    // Sort Appointments by start time
    const sortedAppointmentList = sortAppointmentsByStartTime(dayAppointmentList)

    //Greedily group appointments
    const groupedSortedAppointmentList = groupSameStartTime(sortedAppointmentList)
    return groupedSortedAppointmentList
  }

  const renderSlotsForDay = (day) => {
    const dictKey = formatDateMMDDYYYY(day)
    if(dictKey in appointmentDataDictionary){
      const groupSortedAppointmentListForDay = calculateWeeklyWithOverlap(appointmentDataDictionary[dictKey])
      return(
        groupSortedAppointmentListForDay.map(appointmentGroup => appointmentGroup.map(apt => <WeeklyDateSlot hourHeight={distance} appointmentData={apt} setPopupOpen={setPopupOpen} setPopupModalData={setPopupModalData}/>))
      )
    }
  }
  if(appointmentDataDictionary && appointmentList)
  return (
    <div className="calendar-weekly-container">
      <div className="calendar-weekly-header">
        <div className="calendar-weekly-header-day">
          <h1>SUN</h1>
          <h2 className={areDatesEqual(today, sunday) ? "calendar-weekly-header-day-today" : ""}>{sunday.getDate()}</h2>
        </div>
        <div className="calendar-weekly-header-day">
          <h1>MON</h1>
          <h2 className={areDatesEqual(today, monday) ? "calendar-weekly-header-day-today" : ""}>{monday.getDate()}</h2>
        </div>
        <div className="calendar-weekly-header-day">
          <h1>TUE</h1>
          <h2 className={areDatesEqual(today, tuesday) ? "calendar-weekly-header-day-today" : ""}>{tuesday.getDate()}</h2>
        </div>
        <div className="calendar-weekly-header-day">
          <h1>WED</h1>
          <h2 className={areDatesEqual(today, wednesday) ? "calendar-weekly-header-day-today" : ""}>{wednesday.getDate()}</h2>
        </div>
        <div className="calendar-weekly-header-day">
          <h1>THU</h1>
          <h2 className={areDatesEqual(today, thursday) ? "calendar-weekly-header-day-today" : ""}>{thursday.getDate()}</h2>
        </div>
        <div className="calendar-weekly-header-day">
          <h1>FRI</h1>
          <h2 className={areDatesEqual(today, friday) ? "calendar-weekly-header-day-today" : ""}>{friday.getDate()}</h2>
        </div>
        <div className="calendar-weekly-header-day">
          <h1>SAT</h1>
          <h2 className={areDatesEqual(today, saturday) ? "calendar-weekly-header-day-today" : ""}>{saturday.getDate()}</h2>
        </div>
      </div>
      <div className="calendar-weekly-body">
        <div className="calendar-weekly-columns-container" style={{height:`${distance * 24}px`}}>
          <div className="calendar-weekly-column">
            {areDatesEqual(today, sunday) && renderTodayRedLine()}
            {renderSlotsForDay(sunday)}
          </div>
          <div className="calendar-weekly-column">
            {areDatesEqual(today, monday) && renderTodayRedLine()}
            {renderSlotsForDay(monday)}
          </div>
          <div className="calendar-weekly-column">
            {areDatesEqual(today, tuesday) && renderTodayRedLine()}
            {renderSlotsForDay(tuesday)}
          </div>
          <div className="calendar-weekly-column">
            {areDatesEqual(today, wednesday) && renderTodayRedLine()}
            {renderSlotsForDay(wednesday)}
          </div>
          <div className="calendar-weekly-column">
            {areDatesEqual(today, thursday) && renderTodayRedLine()}
            {renderSlotsForDay(thursday)}
          </div>
          <div className="calendar-weekly-column">
            {areDatesEqual(today, friday) && renderTodayRedLine()}
            {renderSlotsForDay(friday)}
          </div>
          <div className="calendar-weekly-column">
            {areDatesEqual(today, saturday) && renderTodayRedLine()}
            {renderSlotsForDay(saturday)}
          </div>
        </div>

        <div className="calendar-weekly-rows-container">
          <div
            className="calendar-weekly-row"
            style={{ paddingTop: `${35 + height / 2}px` }}
          >
            <h3 ref={componentHeightRef}>1 AM</h3>
            <div
              className="calendar-weekly-row-gridline"
              ref={component1Ref}
            ></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>2 AM</h3>
            <div
              className="calendar-weekly-row-gridline"
              ref={component2Ref}
            ></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>3 AM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>4 AM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>5 AM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>6 AM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>7 AM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>8 AM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>9 AM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>10 AM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>11 AM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>12 PM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>1 PM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>2 PM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>3 PM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>4 PM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>5 PM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>6 PM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>7 PM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>8 PM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>9 PM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>10 PM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
          <div className="calendar-weekly-row">
            <h3>11 PM</h3>
            <div className="calendar-weekly-row-gridline"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarWeekly;
