import { createSlice } from '@reduxjs/toolkit';

export const onboardSlice = createSlice({
  name: 'onboard',
  initialState: {
    first_name: null,
    last_name: null,
    practice_name: null,
    practice_size: null,
    role: null,
    email: null,
    phone: null,
    zip: null,
    isPhoneVerified: null,
    password: null,
  },
  reducers: {
    setFirstNameRedux: (state, action) => {
      state.first_name = action.payload;
    },
    setLastNameRedux: (state, action) => {
      state.last_name = action.payload;
    },
    setPracticeNameRedux: (state, action) => {
      state.practice_name = action.payload;
    },
    setPracticeSizeRedux: (state, action) => {
      state.practice_size = action.payload;
    },
    setRoleRedux: (state, action) => {
      state.role = action.payload;
    },
    setEmailRedux: (state, action) => {
      state.email = action.payload;
    },
    setPhoneRedux: (state, action) => {
      state.phone = action.payload;
    },
    setZipRedux: (state, action) => {
      state.zip = action.payload;
    },
    setIsPhoneVerifiedRedux: (state, action) => {
      state.isPhoneVerified = action.payload;
    },
    setPasswordRedux: (state, action) => {
      state.password = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFirstNameRedux,
  setLastNameRedux,
  setPracticeNameRedux,
  setPracticeSizeRedux,
  setRoleRedux,
  setEmailRedux,
  setPhoneRedux,
  setZipRedux,
  setIsPhoneVerifiedRedux,
  setPasswordRedux,
} = onboardSlice.actions;

export default onboardSlice.reducer;
