import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { chevron_down } from "../../../home/image";
import {
  areDatesEqual,
  convertDateStringToDateObject,
  convertTimeToInt,
  convertTo12HourFormatFromSlot,
  extractTimeInfo,
  formatDateToLongString,
  formatDateUTCKey,
  formatPhoneNumber2,
  getMidnightUTCTimestamp,
  halfHourTimeIntervals,
  indexOfAvailableSlots,
  isDateInRange,
  isTimeInArray,
  usStatesAbbreviations,
} from "../../../../assets/functions/functions";
import InsurancePicker from "../../../patients/bookingv2/insurance-dropdown-picker";
import { GetDoctor, createAppointmentsFromHospitalSide, setAppointments } from "../../../../../api/patientAPIs";
import MiniCalendar from "../../../patients/doctorprofile/mini-calendar/datepicker";
import LoadingSpinner from "../../../patients/bookingv2/spinner";
import {
  convertDOBToDateObject,
  formatDateMMDDYYYY,
} from "../prototype-calendar/helper-functions";

const dayCorrectionMapping = {
  0: 1,
  1: 2,
  2: 3,
  3: 4,
  4: 5,
  5: 6,
  6: 7,
};

const CreateAppointmentModal = ({
  hospitalData,
  isCreateAppointmentModalOpen,
  setCreateAppointmentModalOpen,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const stateDropdownRef = useRef(null);
  const reasonDropdownRef = useRef(null);
  const [isStateDropdownOpen, setStateDropdownOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [gender, setGender] = useState(null);
  const [reason, setReason] = useState("");
  const [isReasonOpen, setReasonOpen] = useState(false);
  const [isNewPatient, setIsNewPatient] = useState(false);
  const [isUsingExistingAppointments, setIsUsingExistingAppointments] =
    useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [currentDoctorData, setCurrentDoctorData] = useState(null);
  const [dob, setDob] = useState("");
  const [isGenderDropdownOpen, setIsGenderDropdownOpen] = useState(false);
  const [phone, setPhone] = useState(null);
  const genderDropdownRef = useRef(null);
  const [address, setAddress] = useState("");
  const [apt, setApt] = useState("");
  const [city, setCity] = useState("");
  const [state, setStatee] = useState("");
  const [zip, setZip] = useState(null);
  const [memberID, setMemberID] = useState(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [insuranceID, setInsuranceID] = useState(null);
  const [planID, setPlanID] = useState(null);
  const [isBooking, setIsBooking] = useState(false);

  // Doctor Dropdown
  const doctorDropdownRef = useRef(null);
  const [isDoctorDropdownOpen, setDoctorDropdownOpen] = useState(false);
  const [doctorFilter, setDoctorFilter] = useState(null);
  const [doctorList, setDoctorList] = useState([]);
  const [isModalLoading, setModalLoading] = useState(false);

  // Time Selection
  const [displayedDate, setDisplayedDate] = useState(new Date());
  const [isRescheduleOpen, setRescheduleOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isTimeOpen, setTimeOpen] = useState(false);
  const [duration, setDuration] = useState(null);
  const timeDropdownRef = useRef(null);
  const dateDropdownRef = useRef(null);

  //Section Dropdown
  const [requiredSectionOpen, setRequiredSectionOpen] = useState(true)
  const [addressSectionOpen, setAddressSectionOpen] = useState(false)
  const [insuranceSectionOpen, setInsuranceSectionOpen] = useState(false)

  //CheckBoxes
  const [smsChecked, setSMSChecked] = useState(true)
  const [insuranceChecked, setInsuranceChecked] = useState(true)
  const [idChecked, setIDChecked] = useState(false)

  useEffect(() => {
    setModalLoading(true);
    var doctorPromises = [];
    hospitalData.doctors.forEach((doc) => {
      doctorPromises.push(GetDoctor(doc));
    });

    Promise.all(doctorPromises).then((docResults) => {
      const tempDoctorList = [];
      docResults.forEach((docResults) => tempDoctorList.push(docResults.data));
      setDoctorList(tempDoctorList);
      setModalLoading(false);
    });

    const handleClickDoctorOutside = (event) => {
      if (
        doctorDropdownRef.current &&
        !doctorDropdownRef.current.contains(event.target)
      ) {
        setDoctorDropdownOpen(false);
      }
    };
    const handleClickReasonOutside = (event) => {
      if (
        reasonDropdownRef.current &&
        !reasonDropdownRef.current.contains(event.target)
      ) {
        setReasonOpen(false);
      }
    };
    const handleClickTimeOutside = (event) => {
      if (
        timeDropdownRef.current &&
        !timeDropdownRef.current.contains(event.target)
      ) {
        setTimeOpen(false);
      }
    };
    const handleClickDateOutside = (event) => {
      if (
        dateDropdownRef.current &&
        !dateDropdownRef.current.contains(event.target)
      ) {
        setRescheduleOpen(false);
      }
    };

    const handleClickGenderOutside = (event) => {
      if (
        genderDropdownRef.current &&
        !genderDropdownRef.current.contains(event.target)
      ) {
        setIsGenderDropdownOpen(false);
      }
    };

    window.addEventListener("click", handleClickDoctorOutside);
    window.addEventListener("click", handleClickReasonOutside);
    window.addEventListener("click", handleClickTimeOutside);
    window.addEventListener("click", handleClickDateOutside);
    window.addEventListener("click", handleClickGenderOutside);

    return () => {
      window.removeEventListener("click", handleClickDoctorOutside);
      window.removeEventListener("click", handleClickReasonOutside);
      window.removeEventListener("click", handleClickTimeOutside);
      window.removeEventListener("click", handleClickDateOutside);
      window.removeEventListener("click", handleClickGenderOutside);
    };
  }, []);

  const getAvailableSlotsForDate = () => {
    const day = displayedDate;
    const today = new Date();
    const tempAppointmentSlotDictionary = {};
    hospitalData.schedules.forEach((schedule) => {
      if (schedule.frequency_type === "O") {
        //Go through each day and see if rule applies
        const appointmentDate = new Date(schedule.start_date);
        const dateKey = formatDateMMDDYYYY(day);
        if (
          areDatesEqual(day, appointmentDate) &&
          day.getTime() >= today.getTime()
        ) {
          if (dateKey in tempAppointmentSlotDictionary) {
            tempAppointmentSlotDictionary[dateKey].total_slots +=
              schedule.total_num_slots;
          } else {
            tempAppointmentSlotDictionary[dateKey] = {
              total_slots: schedule.total_num_slots,
              available_slots: [],
            };
          }
          schedule.timeslots.forEach((time) => {
            if (
              !isTimeInArray(
                tempAppointmentSlotDictionary[dateKey].available_slots,
                time
              )
            ) {
              tempAppointmentSlotDictionary[dateKey].available_slots.push({
                time: time,
                schedule_id: schedule.id,
                doctor_id: schedule.d_id,
                duration: schedule.duration,
              });
            }
          });
        }
      } else if (schedule.frequency_type === "D") {
        const dateKey = formatDateMMDDYYYY(day);
        if (
          isDateInRange(
            new Date(schedule.start_date * 1000),
            new Date(schedule.end_date * 1000),
            day
          ) &&
          day.getTime() >= today.getTime()
        ) {
          if (dateKey in tempAppointmentSlotDictionary) {
            tempAppointmentSlotDictionary[dateKey].total_slots +=
              schedule.total_num_slots;
          } else {
            tempAppointmentSlotDictionary[dateKey] = {
              total_slots: schedule.total_num_slots,
              available_slots: [],
            };
          }

          schedule.timeslots.forEach((time) => {
            if (
              !isTimeInArray(
                tempAppointmentSlotDictionary[dateKey].available_slots,
                time
              )
            ) {
              tempAppointmentSlotDictionary[dateKey].available_slots.push({
                time: time,
                schedule_id: schedule.id,
                doctor_id: schedule.d_id,
                duration: schedule.duration,
              });
            }
          });
        } else {
          if (!(dateKey in tempAppointmentSlotDictionary)) {
            tempAppointmentSlotDictionary[dateKey] = {
              total_slots: 0,
              available_slots: []
            }
          }
        }
      } else if (schedule.frequency_type === "W") {
        const dateKey = formatDateMMDDYYYY(day);
        if (
          day.getDay() === dayCorrectionMapping[schedule.frequency_interval] &&
          isDateInRange(
            new Date(schedule.start_date * 1000),
            new Date(schedule.end_date * 1000),
            day
          ) &&
          day.getTime() >= today.getTime()
        ) {
          if (dateKey in tempAppointmentSlotDictionary) {
            tempAppointmentSlotDictionary[dateKey].total_slots +=
              schedule.total_num_slots;
          } else {
            tempAppointmentSlotDictionary[dateKey] = {
              total_slots: schedule.total_num_slots,
              available_slots: [],
            };
          }

          schedule.timeslots.forEach((time) => {
            if (
              !isTimeInArray(
                tempAppointmentSlotDictionary[dateKey].available_slots,
                time
              )
            ) {
              tempAppointmentSlotDictionary[dateKey].available_slots.push({
                time: time,
                schedule_id: schedule.id,
                doctor_id: schedule.d_id,
                duration: schedule.duration,
              });
            }
          });
        } else {
          if (!(dateKey in tempAppointmentSlotDictionary)) {
            tempAppointmentSlotDictionary[dateKey] = {
              total_slots: 0,
              available_slots: []
            }
          }
        }
      }
    });

    hospitalData.schedules.forEach((schedule) => {
      const takenAppointments = schedule.appointments
        ? schedule.appointments
        : [];
      takenAppointments.forEach((slot) => {
        const dateKey = formatDateUTCKey(new Date(slot.slot_date * 1000));
        if (tempAppointmentSlotDictionary[dateKey] !== undefined) {
          const indexToRemove = indexOfAvailableSlots(
            tempAppointmentSlotDictionary[dateKey].available_slots,
            slot.slot_time,
            slot.schedule_id
          );
          if (indexToRemove !== -1) {
            tempAppointmentSlotDictionary[dateKey].total_num_slots -= 1;
            tempAppointmentSlotDictionary[dateKey].available_slots.splice(
              indexToRemove,
              1
            );
          }
        }
      });
    });
    return tempAppointmentSlotDictionary[
      Object.keys(tempAppointmentSlotDictionary)[0]
    ].available_slots;
  };

  const renderTime = (data) => {
    return (
      <div
        className="confirmation-timeslot"
        onClick={() => {
          setSelectedTime(data);
          setTimeOpen(false);
        }}
      >
        <h3>{data}</h3>
      </div>
    );
  };

  const renderTimeSlot = (data) => {
    return (
      <div
        className="confirmation-timeslot"
        onClick={() => {
          setSelectedSlot(data);
          setTimeOpen(false);
        }}
      >
        <h3>{convertTo12HourFormatFromSlot(String(data.time))}</h3>
      </div>
    );
  };

  const renderSlotPicker = () => {
    if (isUsingExistingAppointments) {
      const existingSlots = getAvailableSlotsForDate();
      console.log(existingSlots);
      return (
        <span className="create-appointment-time-selection-container">
          <div
            ref={dateDropdownRef}
            className="confirmation-modal-date-dropdown-container"
          >
            <div
              className="confirmation-modal-date-dropdown"
              onClick={() => setRescheduleOpen(!isRescheduleOpen)}
              style={{ marginRight: "40px" }}
            >
              <i className="far fa-calendar"></i>
              <div className="d-flex flex-column">
                <h4>Date</h4>
                <h5>
                  {formatDateToLongString(displayedDate) != null
                    ? formatDateToLongString(displayedDate)
                    : "Choose how frequently your slots are open"}
                </h5>
              </div>
            </div>
            {isRescheduleOpen && (
              <ul className="create-schedule-dropdown-menu">
                <MiniCalendar
                  useExactDate={true}
                  displayedDate={displayedDate}
                  setDisplayedDate={setDisplayedDate}
                  increaseMonth={increaseMonth}
                  decreaseMonth={decreaseMonth}
                  setPopupOpen={() => setRescheduleOpen(false)}
                />
              </ul>
            )}
          </div>
          <div
            ref={timeDropdownRef}
            className="confirmation-modal-date-dropdown-container"
          >
            <div
              className="confirmation-modal-date-dropdown"
              onClick={() => setTimeOpen(!isTimeOpen)}
            >
              <i className="far fa-clock"></i>
              <div className="d-flex flex-column">
                <h4>Slot</h4>
                <h5>
                  {selectedSlot != null && selectedSlot !== ""
                    ? convertTo12HourFormatFromSlot(String(selectedSlot.time))
                    : "Choose existing slot"}
                </h5>
              </div>
              {isTimeOpen && (
                <div className="confirmation-time-dropdown-menu ">
                  {existingSlots && existingSlots.length > 0 ? (
                    existingSlots.map((elem) => renderTimeSlot(elem))
                  ) : (
                    <div className="confirmation-timeslot">
                      <h3>No open time slot found</h3>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </span>
      );
    } else {
      return (
        <div>
          <span className="create-appointment-time-selection-container">
            <div
              ref={dateDropdownRef}
              className="confirmation-modal-date-dropdown-container"
            >
              <div
                className="confirmation-modal-date-dropdown"
                onClick={() => setRescheduleOpen(!isRescheduleOpen)}
                style={{ marginRight: "40px" }}
              >
                <i className="far fa-calendar"></i>
                <div className="d-flex flex-column">
                  <h4>Date</h4>
                  <h5>
                    {formatDateToLongString(displayedDate) != null
                      ? formatDateToLongString(displayedDate)
                      : "Choose how frequently your slots are open"}
                  </h5>
                </div>
              </div>
              {isRescheduleOpen && (
                <ul className="create-schedule-dropdown-menu">
                  <MiniCalendar
                    useExactDate={true}
                    displayedDate={displayedDate}
                    setDisplayedDate={setDisplayedDate}
                    increaseMonth={increaseMonth}
                    decreaseMonth={decreaseMonth}
                    setPopupOpen={() => setRescheduleOpen(false)}
                  />
                </ul>
              )}
            </div>
            <div
              ref={timeDropdownRef}
              className="confirmation-modal-date-dropdown-container"
            >
              <div
                className="confirmation-modal-date-dropdown"
                onClick={() => setTimeOpen(!isTimeOpen)}
              >
                <i className="far fa-clock"></i>
                <div className="d-flex flex-column">
                  <h4>Time</h4>
                  <h5>
                    {selectedTime != null && selectedTime !== ""
                      ? selectedTime
                      : "Choose time"}
                  </h5>
                </div>
                {isTimeOpen && (
                  <div
                    className="confirmation-time-dropdown-menu "
                  >
                    {halfHourTimeIntervals.map((elem) => renderTime(elem))}
                  </div>
                )}
              </div>
            </div>
          </span>

          <h3 className="booking-email-label">Appointment Length</h3>
          <input
            className="confirmation-modal-date-dropdown"
            style={{ width: "47%" }}
            value={duration}
            placeholder="Length in minutes"
            onChange={handleDurationChanged}
          />
        </div>
      );
    }
  };
  const handleDurationChanged = (event) => {
    // Remove non-numeric characters
    const newValue = event.target.value.replace(/[^0-9]/g, "");
    setDuration(newValue);
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    // Basic email format validation
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const isValidEmail = emailRegex.test(inputValue);

    //setIsValidEma(isValidEmail);
  };

  const handlePhoneChange = (e) => {
    // Remove non-numeric characters and limit to 10 digits
    const cleanedInput = e.target.value.replace(/\D/g, "").substring(0, 10);

    // Format the phone number
    let formattedPhoneNumber = "";

    if (cleanedInput.length >= 1) {
      formattedPhoneNumber = `(${cleanedInput.substring(0, 3)}`;

      if (cleanedInput.length >= 4) {
        formattedPhoneNumber += `) ${cleanedInput.substring(3, 6)}`;

        if (cleanedInput.length >= 7) {
          formattedPhoneNumber += `-${cleanedInput.substring(6, 10)}`;
        }
      }
    }

    setPhone(formattedPhoneNumber);
  };

  const handleDobChange = (event) => {
    let inputValue = event.target.value;

    // Remove non-digit characters
    inputValue = inputValue.replace(/\D/g, "");

    // Format the date as MM/DD/YYYY
    if (inputValue.length <= 2) {
      setDob(inputValue);
    } else if (inputValue.length <= 4) {
      setDob(`${inputValue.slice(0, 2)}/${inputValue.slice(2)}`);
    } else {
      setDob(
        `${inputValue.slice(0, 2)}/${inputValue.slice(2, 4)}/${inputValue.slice(
          4,
          8
        )}`
      );
    }
  };

  const increaseMonth = () => {
    const newDate = new Date(displayedDate.getTime());

    const currentDay = newDate.getDate();
    newDate.setDate(1);
    newDate.setMonth(newDate.getMonth() + 1);

    const daysInNewMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
    newDate.setDate(Math.min(currentDay, daysInNewMonth));

    setDisplayedDate(newDate);
  };

  const decreaseMonth = () => {
    const newDate = new Date(displayedDate.getTime());
    newDate.setMonth(newDate.getMonth() - 1);

    setDisplayedDate(newDate);
  };

  const toggleGenderDropdown = () => {
    setIsGenderDropdownOpen(!isGenderDropdownOpen);
  };

  const handleGenderDropdownSelected = (gender) => {
    setGender(gender);
    setIsGenderDropdownOpen(false);
  };

  const toggleReasonDropdown = () => {
    setReasonOpen(!isReasonOpen);
  };

  const handleReasonSelected = (reason) => {
    setReason(reason);
    setReasonOpen(false);
  };

  const toggleDoctorFilter = () => {
    setDoctorDropdownOpen(!isDoctorDropdownOpen);
  };

  const handleDoctorSelected = (doc) => {
    setDoctorFilter(doc);
    setDoctorDropdownOpen(false);
  };

  const submitAppointment = (e) => {
    const tempDob = convertDOBToDateObject(dob);
    const tempMissingFields = [];
    if (doctorFilter == null) {
      tempMissingFields.push(" Doctor");
    }
    if (
      isUsingExistingAppointments &&
      (selectedSlot == null || displayedDate == null)
    ) {
      tempMissingFields.push(" Date and Time");
    }
    if (
      !isUsingExistingAppointments &&
      (selectedTime === "" ||
        selectedTime == null ||
        displayedDate == null ||
        duration == null ||
        duration === "")
    ) {
      tempMissingFields.push(" Date and Time");
    }
    if (firstName === "" || firstName == null) {
      tempMissingFields.push(" First Name");
    }
    if (lastName === "" || lastName == null) {
      tempMissingFields.push(" Last Name");
    }
    if (tempMissingFields.length !== 0) {
      setMissingFields(tempMissingFields);
      return;
    } else {
      setMissingFields([]);
    }

    const phoneNumber =
      phone && phone.length > 0 ? formatPhoneNumber2(phone) : "";
      
    const slot_time = isUsingExistingAppointments
      ? convertTimeToInt(selectedSlot.time)
      : convertTimeToInt(selectedTime);

    const slot_date = getMidnightUTCTimestamp(displayedDate) / 1000;
    const slot_duration = isUsingExistingAppointments
      ? selectedSlot.duration
      : parseFloat(duration) / 60.0;

    const appointmentData = {
      h_id: hospitalData.external_id,
      d_id: doctorFilter.external_id,
      locationID: 1697847429996,
      reviewID: "",
      patient_info: {
        patient_name_first: firstName,
        patient_name_last: lastName,
        dob: convertDateStringToDateObject(dob),
        email: email,
        phone: phoneNumber,
        reason_visit: reason,
        insurance: insuranceID,
        plan: planID,
        member_id: memberID,
        gender: gender,
        message: "",
        street_address: address,
        apt: apt,
        city: city,
        state: state,
        zip: zip,
        reason: reason,
        date: extractTimeInfo(displayedDate, slot_time, hospitalData.timezone),
      },
      schedule_time: 0,
      slot_time: slot_time,
      slot_date: slot_date,
      schedule_rule: isUsingExistingAppointments
        ? selectedSlot.schedule_id
        : null,
      duration: parseInt(slot_duration),
      status: "CONFIRMED",
      price: 0.0,
      reminders_sent: 0,
      insurance: "Ins",
      id_card: "id",
      last_updated: new Date(),
      isNewPatient: isNewPatient,
      appointment_result: "Res",
      timezone: hospitalData.timezone,
      timeline: {},
    };

    setIsBooking(true);
    createAppointmentsFromHospitalSide(hospitalData.external_id, appointmentData, insuranceChecked, idChecked).then(
      (response) => {

        setIsBooking(false);
        setCreateAppointmentModalOpen(false)
      }
    ).catch(err => {
      console.log(err)
      setIsBooking(false);
    });
  };

  const handleOverlayClicked = () => {
    setCreateAppointmentModalOpen(false)
  }

  if (!isCreateAppointmentModalOpen) {
    return null;
  }

  return (
    <div className="create-appointment-modal-overlay" onClick={handleOverlayClicked}>
      <div className="create-appointment-modal" onClick={(e) => e.stopPropagation()}>
        <div className="create-appointment-modal-header">
          <div className="d-flex align-items-center">
            <div className="create-appointment-modal-banner" />
            <h2 className="create-appointment-modal-title">
              Create Appointment
            </h2>
          </div>
          <button onClick={() => setCreateAppointmentModalOpen(false)}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        <div className="create-appointment-section-header" onClick={() => setRequiredSectionOpen(!requiredSectionOpen)}>
          <h2>Patient Info/Contacts (required)</h2>
          {requiredSectionOpen ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>}
        </div>

        {requiredSectionOpen && <div className="create-appointment-section-body">
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Patient first name</h3>
              <input
                className="name-input"
                placeholder="Ex. John"
                value={firstName}
                onChange={(c) => setFirstName(c.currentTarget.value)}
              />
            </div>

            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Patient last name</h3>
              <input
                className="name-input2"
                placeholder="Ex. Smith"
                value={lastName}
                onChange={(c) => setLastName(c.currentTarget.value)}
              />
            </div>
          </span>
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Patient email</h3>
              <input
                className="name-input"
                placeholder="Ex. johnsmith@gmail.com"
                value={email}
                onChange={handleEmailChange}
              />
            </div>

            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Patient phone</h3>
              <input
                className="name-input2"
                placeholder="Ex. (012) 345-6789"
                value={phone}
                onChange={handlePhoneChange}
              />
            </div>
          </span>

          <h3 className="booking-email-label less-padding">Assigned Doctor</h3>
          <div className="booker-reason-input-container" ref={doctorDropdownRef}>
            <div className="booker-body-dropdown" onClick={toggleDoctorFilter}>
              <h1>
                {!doctorFilter
                  ? "Choose a doctor"
                  : `Dr. ${doctorFilter.doctor_name_first} ${doctorFilter.doctor_name_last}`}
              </h1>
              <img alt="chevron down" src={chevron_down} />
            </div>
            {isDoctorDropdownOpen && (
              <ul className="create-appointment-dropdown-menu">
                {doctorList.map((doctor, index) => (
                  <li key={index} onClick={() => handleDoctorSelected(doctor)}>
                    Dr. {doctor.doctor_name_first} {doctor.doctor_name_last}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {doctorFilter && (
            <>
              <h3 className="booking-email-label less-padding">Reason for Visit (optional)</h3>
              <div
                className="booker-reason-input-container"
                ref={reasonDropdownRef}
              >
                <div
                  className="booker-body-dropdown"
                  onClick={toggleReasonDropdown}
                >
                  <h1>
                    {reason && reason !== "" ? reason : "Choose care"}
                  </h1>
                  <img alt="chevron down" src={chevron_down} />
                </div>
                {isReasonOpen && (
                  <ul className="create-appointment-dropdown-menu">
                    {doctorFilter.services.map((option, index) => (
                      <li
                        key={index}
                        onClick={() => handleReasonSelected(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </>
          )}
          {doctorFilter && (
            <>
              <h3 className="booking-email-label">Date and Time</h3>
              <div className="create-appointment-slotortime-container">
                <div className="booker-new-patient">
                  <input
                    type="radio"
                    onChange={() => setIsUsingExistingAppointments(true)}
                    checked={isUsingExistingAppointments}
                  />
                  <h1>Choose from existing slots</h1>
                </div>
                <div className="booker-new-patient">
                  <input
                    type="radio"
                    onChange={() => setIsUsingExistingAppointments(false)}
                    checked={!isUsingExistingAppointments}
                  />
                  <h1>Create new appointment slot</h1>
                </div>
              </div>

              {renderSlotPicker()}
            </>
          )}
        </div>}



        {/* <span className="d-flex flex-col justify-content-between">
          <div className="email-input-wrapper">
            <h3 className="booking-email-label">Date of birth (optional)</h3>
            <input
              className="name-input"
              name="dob"
              placeholder="MM/DD/YYYY"
              value={dob}
              onChange={handleDobChange}
            />
          </div>
          <div className="email-input-wrapper">
            <h3 className="booking-email-label">Gender</h3>

            <div className="booker-input-container" ref={genderDropdownRef}>
              <div className="booker-dropdown" onClick={toggleGenderDropdown}>
                <h2>{gender ? gender : "Choose"}</h2>
                <img alt="chevron down" src={chevron_down} />
              </div>
              {isGenderDropdownOpen && (
                <ul className="booker-dropdown-menu">
                  {["Male", "Female", "Other"].map((option, index) => (
                    <li
                      key={index}
                      onClick={() => handleGenderDropdownSelected(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </span>
        <div className="booker-new-patient-container">
          <div className="booker-new-patient">
            <input
              type="radio"
              onChange={() => setIsNewPatient(true)}
              checked={isNewPatient}
            />
            <h1>New patient</h1>
          </div>
          <div className="booker-new-patient">
            <input
              type="radio"
              onChange={() => setIsNewPatient(false)}
              checked={isNewPatient === false}
            />
            <h1>Returning patient</h1>
          </div>
        </div> */}
        <div className="create-appointment-section-header" style={{ marginTop: '20px' }} onClick={() => setAddressSectionOpen(!addressSectionOpen)}>
          <h2>Patient Address (optional)</h2>
          {addressSectionOpen ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>}
        </div>
        {addressSectionOpen && <form className="create-appointment-section-body">
          <h3 className="booking-email-label">Street address</h3>
          <input
            className="email-input"
            type="text"
            name="address-line1"
            autoComplete="address-line1"
            value={address}
            placeholder="Street address"
            onChange={(c) => setAddress(c.currentTarget.value)}
          />
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">
                Apt, suite, building (optional)
              </h3>
              <input
                className="name-input"
                name="address-line2"
                autoComplete="address-line2"
                placeholder="Apt, suite, or building"
                value={apt}
                onChange={(c) => setApt(c.currentTarget.value)}
              />
            </div>

            <div className="email-input-wrapper">
              <h3 className="booking-email-label">City</h3>
              <input
                className="name-input2"
                name="city"
                autoComplete="address-level2"
                placeholder="City"
                value={city}
                onChange={(c) => setCity(c.currentTarget.value)}
              />
            </div>
          </span>
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">State</h3>

              <div className="booker-input-container" ref={stateDropdownRef}>
                <div
                  className="booker-dropdown"
                  onClick={() => setStateDropdownOpen(!isStateDropdownOpen)}
                >
                  <h2>{state ? state : "Choose"}</h2>
                  <img alt="chevron down" src={chevron_down} />
                </div>
                {isStateDropdownOpen && (
                  <ul className="booker-dropdown-menu">
                    {usStatesAbbreviations.map((option, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setStatee(option);
                          setStateDropdownOpen(false);
                        }}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Zip code</h3>
              <input
                className="name-input2"
                name="postal-code"
                autoComplete="postal-code"
                placeholder="Zip code"
                value={zip}
                onChange={(c) => setZip(c.currentTarget.value)}
              />
            </div>
          </span>
        </form>}
        <div className="create-appointment-section-header" style={{ marginTop: '20px' }} onClick={() => setInsuranceSectionOpen(!insuranceSectionOpen)}>
          <h2>Patient Insurance (optional)</h2>
          {insuranceSectionOpen ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>}
        </div>
        {insuranceSectionOpen && <form className="create-appointment-section-body">
          <InsurancePicker
            setInsuranceID={setInsuranceID}
            setPlanID={setPlanID}
            setSelectedInsurance={() => { }}
            setSelectedPlan={() => { }}
            handleInsuranceChosen={() => { }}
          />
          <h3 className="booking-email-label">Insurance member ID</h3>
          <input
            className="email-input"
            name="email"
            value={memberID}
            placeholder="Enter your insurance member ID"
            onChange={(c) => setMemberID(c.currentTarget.value)}
          />
        </form>}
        <div className="create-appointment-basic-info-section-banner">
          <h1>
            Recommended: Enable SMS reminders and request patient documents for this appointment.
          </h1>
          <div className="create-appointment-checkbox-container">
          <input
            type="checkbox"
            checked={smsChecked}
            onChange={(c) => setSMSChecked(!smsChecked)}
          />
          <h2>
            Send SMS appointment reminders to patient for this appointment.
          </h2>
        </div>
        <div className="create-appointment-checkbox-container">
          <input
            type="checkbox"
            checked={insuranceChecked}
            onChange={(c) => setInsuranceChecked(!insuranceChecked)}
          />
          <h2>
            Send request for patient insurance card.
          </h2>
        </div>
        <div className="create-appointment-checkbox-container">
          <input
            type="checkbox"
            checked={idChecked}
            onChange={(c) => setIDChecked(!idChecked)}
          />
          <h2>
            Send request for patient ID card.
          </h2>
        </div>
        </div>
        {missingFields.length > 0 && (
          <h1 className="missingFields">
            * Please check these fields for errors: {missingFields.toString()}
          </h1>
        )}
        <button
          className="create-appointment-modal-button create"
          onClick={() => submitAppointment()}
        >
          {isBooking ? <LoadingSpinner /> : "Create"}
        </button>
      </div>
    </div>
  );
};

export default CreateAppointmentModal;
