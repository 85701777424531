import React, { useState, useEffect, useRef } from 'react';
import { IMG01, achew_logo_main, achew_logo_side } from '../../home/image';
import { Divider } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import { invalidateRefreshToken } from '../../../../api/auth';
import { logout } from "../../../../hooks/authSlice";
import { useSelector, useDispatch } from 'react-redux'
import { faHourglassEmpty } from '@fortawesome/free-regular-svg-icons';
import LoadingSpinner from '../../patients/bookingv2/spinner';

const Sidebar = (props) => {
    const navigate = useNavigate()
    const location = useLocation();
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(props.currentTab);
    const searchParams = new URLSearchParams(location.search);
    const currentHospitalID = searchParams.get('hospital')
    const [isSigningOut, setIsSigningOut] = useState(false)

    const changeTab = (buttonIndex) => {
        if(buttonIndex == 0){
            navigate(`/summary?hospital=${currentHospitalID}`)
        }else if(buttonIndex == 1){
            navigate(`/calendar?hospital=${currentHospitalID}`)
        }else if(buttonIndex == 2){
            navigate(`/appointment-manager?hospital=${currentHospitalID}`)
        }else if(buttonIndex == 3){
            navigate(`/intake?hospital=${currentHospitalID}`)
        }else if(buttonIndex == 4){
            navigate(`/profile-edit?hospital=${currentHospitalID}`)
        }else if(buttonIndex == 5){
            navigate(`/plan?hospital=${currentHospitalID}`)
        }
        else if(buttonIndex == 6){
            navigate(`/settings?hospital=${currentHospitalID}`)
        }
    };

    const handleSignOut = () => {
        setIsSigningOut(true)
        invalidateRefreshToken().then(response => {
            console.log(response)
           
            console.log("Successfullly logged out")
            localStorage.setItem("exp", ((new Date()).getTime()).toString())
            localStorage.setItem("rememberLogin", "no")
            dispatch(logout())
            navigate('/doctor-login')
            setIsSigningOut(false)
            
        })
    }
    
    return (
        <div className="sidebar-container">

            <div className="sidebar-profile-icon">

                <h5 className="search-results-mobile-logo-dashboard">
                    Achew 
                </h5>
            </div>
    
            <div className="sidebar-tabs-container">
                <h3 className='sidebar-section-label'>CLINIC</h3>
                <button
                    key={0}
                    className={`sidebar-tab-button ${activeTab === 0 ? 'active' : ''}`}
                    onClick={() => changeTab(0)}
                >
                    <i class="fas fa-chart-bar"></i>
                    <div className='sidebar-txt'>Summary</div>
                </button>
                <button
                    key={1}
                    className={`sidebar-tab-button ${activeTab === 1 ? 'active' : ''}`}
                    onClick={() => changeTab(1)}
                >
                    <i class="far fa-calendar"></i>
                    <div className='sidebar-txt'>Calendar</div>
                </button>
                <button
                    key={2}
                    className={`sidebar-tab-button ${activeTab === 2 ? 'active' : ''}`}
                    onClick={() => changeTab(2)}
                >
                    <i class="fas fa-book"></i>
                    <div className='sidebar-txt'>Appointments</div>
                </button>
                <button
                    key={3}
                    className={`sidebar-tab-button ${activeTab === 3 ? 'active' : ''}`}
                    onClick={() => changeTab(3)}
                >
                    <i className="fas fa-id-card"></i>
                    <div className='sidebar-txt'>Intake</div>
                </button>
                <h3 className='sidebar-section-label' style={{marginTop:'30px'}}>MANAGEMENT</h3>

                <button
                    key={6}
                    className={`sidebar-tab-button ${activeTab === 4 ? 'active' : ''}`}
                    onClick={() => changeTab(4)}
                >
                    <i class="fas fa-user-md"></i>
                    <div className='sidebar-txt'>Your Doctors</div>
                </button>
                <button
                    key={3}
                    className={`sidebar-tab-button ${activeTab === 5 ? 'active' : ''}`}
                    onClick={() => changeTab(5)}
                >
                    <i class="fas fa-file-invoice-dollar"></i>
                    <div className='sidebar-txt'>Billing</div>
                </button>
                <button
                    key={4}
                    className={`sidebar-tab-button ${activeTab === 6 ? 'active' : ''}`}
                    onClick={() => changeTab(6)}
                >
                    <i class="fas fa-hospital"></i>
                    <div className='sidebar-txt'>Your Clinic</div>
                </button>
                <button
                    key={5}
                    className={`sidebar-logout-button`}
                    onClick={() => handleSignOut()}
                >
                    {isSigningOut ? <LoadingSpinner message={"Signing out"}/> 
                        : 
                    <>
                        <i class="fas fa-sign-out-alt"></i>
                        <div className='sidebar-txt'>Sign out</div>
                    </>}
                    
                </button>

                <div className='sidebar-bottom-txt'>
                    <h3>You're in a single hospital organization</h3>
                    <h4>© 2024 Achew Inc. All Rights Reserved.</h4>
                </div>

            </div>




        </div>
    );
};

export default Sidebar;