import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import AchewHeader from '../../achew-header';
import AchewFooter from '../../achew-footer';
const PatientIntakeSuccess = () => {
    const navigate = useNavigate()
    const location = useLocation()
    
    useEffect(() => {
      // if(!(location.state && location.state.uploadSuccess)){
      //   navigate('/error')
      // }
    }, []);

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }; 

  return (
    <div className="booking-success-supercontainer">
      <AchewHeader />

      <div className="booking-success-container" >
          <div className="success-cont">
            <div className='d-flex flex-row align-items-center'>
                <i className="fas fa-check"></i>
                <h3>We've received your documents!</h3>
            </div>
            <p>Thank you for submitting your documents. You are all set!</p>
            <button onClick={() => navigate(`/`)}>Return to Achew</button>
          </div>
      </div>
      <AchewFooter/>
    </div>
  );
};

export default PatientIntakeSuccess;