function get42DaysOfMonth(year, month) {
  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);

  // Find the first Sunday on or before the first day of the month
  const firstSunday = new Date(firstDayOfMonth);
  firstSunday.setDate(1 - firstSunday.getDay());

  // Find the last Saturday on or after the last day of the month
  const lastSaturday = new Date(lastDayOfMonth);
  lastSaturday.setDate(lastDayOfMonth.getDate() + (6 - lastSaturday.getDay()));

  const daysArray = [];
  let currentDate = new Date(firstSunday);

  // Generate 42 days
  for (let i = 0; i < 42; i++) {
    daysArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return daysArray;
}
function isToday(inputDate) {
  const today = new Date();
  const input = new Date(inputDate);

  return (
    input.getDate() === today.getDate() &&
    input.getMonth() === today.getMonth() &&
    input.getFullYear() === today.getFullYear()
  );
}
const convertDOBToDateObject = (dob) => {
  const [month, day, year] = dob.split('/').map(Number);

  // Check if the date is valid
  const isValidDate = !isNaN(month) && !isNaN(day) && !isNaN(year);
  if (!isValidDate) {
    console.error('Invalid date format');
    return null;
  }

  // Months are 0-indexed in JavaScript Date object, so subtract 1 from the month
  const dateObject = new Date(year, month - 1, day);

  // Check if the resulting date is valid
  if (isNaN(dateObject.getTime())) {
    console.error('Invalid date');
    return null;
  }

  return dateObject;
};
function formatDateMMDDYYYY(date) {
  const newDate = new Date(date);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return newDate.toLocaleDateString("en-US", options).replace(/\//g, "");
}
const getFirstDayOfWeek = () => {
  const currentDate = new Date();

  // Calculate the difference between the current day and Sunday (assuming Sunday is the first day of the week)
  const daysUntilSunday = currentDate.getDay();

  // Subtract the days until Sunday to get the first day of the current week
  const firstDayOfThisWeek = new Date(currentDate);
  firstDayOfThisWeek.setDate(currentDate.getDate() - daysUntilSunday);

  return firstDayOfThisWeek;
};
const getFirstDayOfNextWeek = (currentDate) => {
  const nextSunday = new Date(currentDate);

  // Calculate the difference between the current day and Sunday (assuming Sunday is the first day of the week)
  const daysUntilNextSunday = (7 - currentDate.getDay()) % 7;

  // Add the days until the next Sunday to get the Sunday of the next week
  nextSunday.setDate(currentDate.getDate() + daysUntilNextSunday + 7);

  return nextSunday;
};
function subtractWeekFromDate(date) {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() - 7);
  return newDate;
}

// Function to add a week to a date
function addWeekToDate(date) {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + 7);
  return newDate;
}
const getFirstDayOfPreviousWeek = (currentDate) => {
  const previousSunday = new Date(currentDate);

  // Calculate the difference between the current day and Sunday (assuming Sunday is the first day of the week)
  const daysUntilPreviousSunday = (currentDate.getDay() + 6) % 7;

  // Subtract the days until the previous Sunday to get the Sunday of the previous week
  previousSunday.setDate(currentDate.getDate() - daysUntilPreviousSunday - 7);

  return previousSunday;
};
const getLastDayOfWeek = (firstDayOfWeek) => {
  const lastDayOfWeek = new Date(firstDayOfWeek);

  // Calculate the last day of the week by adding 6 days to the first day
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  return lastDayOfWeek;
};
const areDatesEqual = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
const addDaysToDate = (startDate, numberOfDays) => {
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + numberOfDays);

  return endDate;
};
function sortAppointmentsByStartTime(appointments) {
  // Custom comparator function to compare two Date objects without considering seconds and milliseconds
  const compareAppointments = (a, b) => {
    const timeA = new Date(a.schedule_time);
    const timeB = new Date(b.schedule_time);

    // Set seconds and milliseconds to 0 for comparison
    timeA.setSeconds(0, 0);
    timeB.setSeconds(0, 0);

    if (timeA < timeB) {
      return -1;
    } else if (timeA > timeB) {
      return 1;
    } else {
      return 0;
    }
  };

  // Use the comparator function to sort the appointments array
  const sortedAppointments = appointments.slice().sort(compareAppointments);
  sortedAppointments.map((appointment, index) => {
    appointment["zIndex"] = index
    return appointment
  })

  return sortedAppointments;
}
function groupSameStartTime(sortedAppointmentArray) {
  if(sortedAppointmentArray.length === 0){
    return []
  }

  const groupedAppointmentsArray = []
  var currentGroup = []
  var currentStartTime = sortedAppointmentArray[0].schedule_time
  sortedAppointmentArray.forEach(appointment => {
    if(appointment.schedule_time === currentStartTime){
      currentGroup.push(appointment)
    }else{
      currentGroup.map((apt, gIndex) => {
        apt["groupSize"] = currentGroup.length
        apt["groupIndex"] = gIndex
        return apt
      })
      groupedAppointmentsArray.push(currentGroup)
      currentStartTime = appointment.schedule_time
      currentGroup = [appointment]
    }
  })
  currentGroup.map((apt, gIndex) => {
    apt["groupSize"] = currentGroup.length
    apt["groupIndex"] = gIndex
    return apt
  })
  groupedAppointmentsArray.push(currentGroup)
  return groupedAppointmentsArray
}
const sampleAppointmentDictionary = {
  12242023: [1, 2, 3],
  12082023: [1, 2, 3],
  12222023: [1, 2, 3],
  12182023: [1, 2, 3],
  12012023: [1, 2, 3],
};

const dayMapping = {
  0: "SUN",
  1: "MON",
  2: "TUE",
  3: "WED",
  4: "THU",
  5: "FRI",
  6: "SAT",
};

const monthDictionary = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export {
  isToday,
  get42DaysOfMonth,
  dayMapping,
  monthDictionary,
  sampleAppointmentDictionary,
  formatDateMMDDYYYY,
  getFirstDayOfWeek,
  getFirstDayOfNextWeek,
  getFirstDayOfPreviousWeek,
  getLastDayOfWeek,
  addDaysToDate,
  areDatesEqual,
  sortAppointmentsByStartTime,
  groupSameStartTime,
  subtractWeekFromDate,
  addWeekToDate,
  convertDOBToDateObject,
};
