import React, { useState, useRef, useEffect } from "react";
import "./calendar.css";
import {} from "./helper-functions";
import { convertTo12HourFormatFromSlot, convertTo12HourFormatList, extractTimeInfo, extractTimeInfo3 } from "../../../../assets/functions/functions";

const WeeklyDateSlot = ({ appointmentData, hourHeight, date, appointmentDataDictionary, setPopupOpen, setPopupModalData  }) => {
  const startDateTime = new Date(appointmentData.schedule_time * 1000);
  const endDateTime = new Date();
  endDateTime.setHours(endDateTime.getHours() + 1);

  // Calculate the duration in milliseconds

  // Convert the duration to hours
  const durationInHours = appointmentData.duration / (1000.0 * 60.0 * 60.0);

  // Calculate the height in pixels (assuming 35px per hour)
  const heightInPixels = Math.max(durationInHours * hourHeight, 35);

  const hours = startDateTime.getHours();
  const minutes = startDateTime.getMinutes();

  // Calculate total minutes since midnight
  const totalMinutes = hours * 60 + minutes;

  // Calculate the percentage of the day
  const percentageOfDay = (totalMinutes / (24 * 60)) * hourHeight * 24;

  const slotWidthInPercent = ((appointmentData.groupSize - appointmentData.groupIndex) / appointmentData.groupSize) * 95
  
  // Style object to set the height
  const containerStyle = {
    height: `${heightInPixels}px`,
    top: percentageOfDay,
    zIndex: appointmentData.zIndex,
    width: `${slotWidthInPercent}%`,
    borderLeft: appointmentData.groupIndex === 0 ? 'none' : '1px solid white'
  };
  var buttonBackgroundColor = "pending"
  if(appointmentData.status === "PENDING"){
      buttonBackgroundColor = "pending"
  } else if(appointmentData.status === "CONFIRMED"){
      buttonBackgroundColor = "confirmed"
  } else if(appointmentData.status === "COMPLETED"){
      buttonBackgroundColor = "completed"
  } else if(appointmentData.status === "DECLINED"){
      buttonBackgroundColor = "declined"
  }

  return (
    <div className={`weekly-date-slot-container ${buttonBackgroundColor}`} style={containerStyle} onClick={() => {setPopupOpen(true); setPopupModalData(appointmentData)}}>
      <h1>{appointmentData.patient_info.reason}({appointmentData.patient_info.patient_name_first} {appointmentData.patient_info.patient_name_last})</h1>
      <h2>{extractTimeInfo3(startDateTime)}</h2>
    </div>
  );
};

export default WeeklyDateSlot;
