import React, { useState, useRef, useEffect } from "react";
import { IMG01, check_circle_blue, check_circle_green, check_circle_red, check_circle_yellow } from "../home/image";
import { getAppointments, addEvent, getHospitalInfoByExternalID, testapi, GetAccountNotifications, GetNextAccountNotificationsPage, getAccountInfo } from "../../../api/patientAPIs";
import { useNavigate, useLocation } from "react-router-dom";
import { combineDateAndTimeInTimezone, ensureHttps, extractTimeInfo2, formatDateToLongString, timeAgo } from "../../assets/functions/functions";
import LoadingSpinner from "../patients/bookingv2/spinner";
import GeneralTooltip from "../tooltip";
import { refreshToken } from "../../../api/auth";
import CreateAppointmentModal from "./dashboard/patient-modals/create-appointment-modal";
import PricingModal from "./dashboard/patient-modals/pricing-modal";


const DoctorHeader = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const [activeHospital, setActiveHospital] = useState(
    searchParams.get("hospital")
  );
  const [isHospitalDropdownOpen, setHospitalDropdownOpen] = useState(false);
  const hospitalDropdownRef = useRef(null);
  const modalDropdownRef = useRef(null);
  const [presentationMode, setPresentationMode] = useState("all");
  const [hospitalData, setHospitalData] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [accountData, setAccountData] = useState({})
  const [isHeaderLoading, setHeaderLoading] = useState(true)

  //Notifications
  const [isPaginationLoading, setIsPaginationLoading] = useState(false)
  const [paginationData, setPaginationData] = useState(null)
  const [notification, setNotification] = useState(null);
  const notificationDropdownRef = useRef(null);
  const [isNotificationOpen, setNotificationOpen] = useState(false);

  //Create Appointment
  const [isCreateAppointmentModalOpen, setCreateAppointmentModalOpen] = useState(false)

  useEffect(() => {
    setHeaderLoading(true)
    getAccountInfo().then(res => {
      if (res.status == 200) {
        setAccountData(res)

        if (res.status == 401 || res.status == 403) {
          refreshToken().then(response => {
            if (response.status == 200) {
              console.log("Successfully refreshed token")
              window.location.reload()
            } else {
              navigate('/doctor-login')
            }
          })
        }

      }
      GetAccountNotifications().then(response => {

        var tempNotifications = response.data.results.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        )
        setNotification(tempNotifications)
        setPaginationData(response.data)
        if (tempNotifications) {
          const unreadNotifications = tempNotifications.filter((row) => row.is_read);
          setUnreadCount(unreadNotifications.length);
        }
      })
      getHospitalInfoByExternalID(activeHospital).then(response => {
        console.log(response)
        setHospitalData(response.data)
        setHeaderLoading(false)
      })
    })

    const handleClickOutsideHospitalModal = (event) => {
      if (
        hospitalDropdownRef.current &&
        !hospitalDropdownRef.current.contains(event.target)
      ) {
        setHospitalDropdownOpen(false);
      }
    };

    const handleClickOutsideNotificationlModal = (event) => {
      if (
        notificationDropdownRef.current &&
        !notificationDropdownRef.current.contains(event.target)
      ) {
        setNotificationOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutsideHospitalModal);
    //window.addEventListener("click", handleClickOutsideNotificationlModal);
    return () => {
      window.removeEventListener("click", handleClickOutsideHospitalModal);
      //window.removeEventListener("click", handleClickOutsideNotificationlModal);
    };
  }, []);

  const switchHospital = (data) => {
    navigate(`${location.pathname}?hospital=${data.external_id}`);
    window.location.reload()
  };

  const toAppointment = (row) => {
    navigate(`/appointment-manager?hospital=${activeHospital}&appointment=${row.metadata.appointment_id}`)
    if (row.is_read) {
      const tempArr = notification.map((data) => {
        if (data === row) {
          return {
            ...data,
            "unread": false
          }
        }
        return data;
      });
      addEvent(activeHospital, tempArr).then(res => {
        console.log("Read")
      })
    }
  }

  const handleShowMoreNotifications = () => {
    setIsPaginationLoading(true)
    GetNextAccountNotificationsPage(ensureHttps(paginationData.next)).then(response => {
      const tempNotifications = [...notification, ...response.data.results]
      setNotification(tempNotifications)
      setPaginationData(response.data)
      setIsPaginationLoading(false)
    })
      .catch(err => {
        console.log(err)
        setIsPaginationLoading(false)
      })
  }


  const renderHospital = (data) => {
    //Don't show the current hospital in the list
    if (data.external_id === activeHospital) {
      return null;
    }

    return (
      <div
        className="sidebar-hospital-picker-group2"
        onClick={() => switchHospital(data)}
      >
        <div className="sidebar-hospital-picker-txt">
          <h3>{data.hospital_name}</h3>
        </div>
      </div>
    );
  };

  const renderNotification = () => {
    let filteredNotifications = notification;

    if (presentationMode === "unread") {
      filteredNotifications = notification.filter((row) => row.is_read);
    }
    console.log(filteredNotifications)
    return (
      <div className="notification-box">
        <div className="notifications">
          <h1>Notifications</h1>
          {/* <a className="notification-right" href="">
            <i class="fas fa-ellipsis-v"></i>
          </a> */}
        </div>
        <div className="notification-body">

          <div>
            {filteredNotifications.length > 0 && filteredNotifications.map((row) => {
              const formattedTimeString = extractTimeInfo2(new Date(row.metadata.schedule_time * 1000), hospitalData.timezone).fullDateTimeAndZone
              var icon = ""
              var notificationMessage = ""
              if (row.metadata.status === "PENDING") {
                icon = check_circle_yellow
                notificationMessage = `New appointment on ${formattedTimeString} booked by ${row.metadata.patient_name_first} ${row.metadata.patient_name_last}.`
              } else if (row.metadata.status === "CONFIRMED") {
                icon = check_circle_green
              } else if (row.metadata.status === "COMPLETED") {
                icon = check_circle_blue
              } else if (row.metadata.status === "DECLINED") {
                icon = check_circle_red
                notificationMessage = `${row.metadata.patient_name_first} ${row.metadata.patient_name_last} has cancelled their appointment on ${formattedTimeString}`
              }
              return (
                <div className="notification-message" onClick={() => toAppointment(row)}>

                  <img alt={row.metadata.status} src={icon} />

                  <div className="notification-information">
                    <h2>{notificationMessage} </h2>
                    <h3>{timeAgo(row.created)}</h3>
                  </div>
                  {row.is_read && <i className="fas fa-circle notification-unread-button"></i>}
                  {!row.is_read && <i className="fas fa-circle notification-read-button"></i>}
                </div>)
            }
            )}
            {filteredNotifications.length < 1 && <h5>No recent notifications</h5>}
          </div>
          {isPaginationLoading ?
            <LoadingSpinner />
            :
            (paginationData.next && <button className="plan-container-show-more-button" onClick={() => handleShowMoreNotifications()}>Show more</button>)

          }
        </div>
      </div>
    );
  };

  const handleRegisterClicked = () => {
    if (props.hospitalData.length < 5) {
      navigate(`/create-hospital`)
    }
  }


    return (
      <div>
        {!isHeaderLoading && <CreateAppointmentModal hospitalData={hospitalData} isCreateAppointmentModalOpen={isCreateAppointmentModalOpen} setCreateAppointmentModalOpen={setCreateAppointmentModalOpen} />}
        
        <div className="dheader-container">

          <div
            className="sidebar-hospital-picker-container"
            ref={hospitalDropdownRef}
            onClick={() => setHospitalDropdownOpen(!isHospitalDropdownOpen)}
          >
            <div className="sidebar-hospital-choice">
              <div className="sidebar-hospital-picker-txt">
                <h5>{props.currentHospitalData.hospital_name}</h5>
              </div>
              <i class="fas fa-chevron-down"></i>
            </div>

            {isHospitalDropdownOpen && (
              <div className="sidebar-hospital-dropdown-view">
                <h4 className="sidebar-hospital-dropdown-view-h4">YOUR HOSPITALS</h4>
                {props.hospitalData.map((data) => renderHospital(data))}
                <div
                  className="sidebar-hospital-picker-group"
                  onClick={() => { }}
                >
                  <div className="sidebar-hospital-picker-txt2">
                    <p>Reigster a new clinic or location with Achew. Each location will have it's own profile page, doctors, and customization.</p>
                    <h3 onClick={() => handleRegisterClicked()} className={`${props.hospitalData.length >= 5 && "sidebar-hospital-picker-txt2-h3-disabled"}`}>Register a new clinic</h3>
                  </div>

                </div>
                <p>{props.hospitalData.length} / 5 Clinics Created</p>
              </div>
            )}
          </div>

          <div className="dheader-right-container " ref={notificationDropdownRef}>
            {
              accountData.credits <= 200 &&
              <GeneralTooltip message="Intake and patient online bookings will be disabled if your balance isn't recharged.">
                <button className="dheader-warning-container">
                  <i className="fas fa-exclamation-circle"></i>
                  <h2>Low Account Balance</h2>
                </button>
              </GeneralTooltip>
            }
            {
              accountData && 
              <button className="dheader-balance-container" onClick={() => navigate(`/plan?hospital=${activeHospital}`)}>
                <i className="fas fa-wallet"></i>
                <h2>Balance: ${(accountData.credits / 100).toFixed(2)}</h2>
              </button>
            }
            

            <button className="dheader-create-appointment-container" onClick={() => setCreateAppointmentModalOpen(true)}>
              <i className="fas fa-calendar-plus"></i>
              <h2>Create appointment</h2>
            </button>

            <div
              onClick={() => setNotificationOpen(!isNotificationOpen)}
              className="icon-link"
              style={{ position: "relative" }}
            >

              <i class="far fa-bell dheader-bell-icon"></i>
              {unreadCount > 0 && (
                <span
                  className="notification-badge"
                >
                  {unreadCount}
                </span>
              )}
            </div>
            {isNotificationOpen && renderNotification()}
            {/* <div class="dheader-profile-icon">
            <i class="fas fa-user"></i>
          </div> */}
          </div>
        </div>
      </div>

    );

};

export default DoctorHeader;

