import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { changePasswordExternal } from '../../../../api/auth';
import AchewHeader from '../../achew-header';
import AchewFooter from '../../achew-footer';
import LoadingSpinner from '../../patients/bookingv2/spinner';
import { validatePassword } from '../../../assets/functions/functions';
import { validate } from 'uuid';
function isValidPassword(password) {
    // Check if the password is at least 8 characters long
    if (password.length < 8) {
      return false;
    }
  
    // Check if the password contains at least one special character
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;
    if (!specialCharacterRegex.test(password)) {
      return false;
    }
  
    // Check if the password contains at least one digit (number)
    const digitRegex = /\d/;
    if (!digitRegex.test(password)) {
      return false;
    }
  
    // Check if the password contains at least one uppercase letter
    const uppercaseRegex = /[A-Z]/;
    if (!uppercaseRegex.test(password)) {
      return false;
    }
  
    // If all conditions are met, the password is valid
    return true;
  }
const NewPassword = () => {
    const navigate = useNavigate()
    const { token } = useParams();
    const [password, setPassword] = useState("")
    const [repassword, setRePassword] = useState("")
    const isSaveDisabled = !password || !repassword;
    const [isChangingPassword, setIsChangingPassword] = useState(false)
    

    const handleSaveClick = () => {
        if(!validatePassword(password).passwordValid || password !== repassword) {
            return 
        }
        setIsChangingPassword(true)
        if(password === repassword && isValidPassword(password)){
            changePasswordExternal(password, token).then(response => {
                if(response.status === 200){
                    navigate(`/reset-password/success/${token}`)
                }
            })
        }
        
    };

    useEffect(() => {
        if(!token || token.length <= 0){
            navigate('/error')
        }
    }, []);

    const passwordValidation = validatePassword(password)

    return (
        <div className="contact-us-wrapper">
            <AchewHeader />
            <div className="password-container">
                <h2>Create a new password</h2>
                <div className='password-box'>
                    <input
                        type='password'
                        className="password-input"
                        placeholder='Enter a new password'
                        onChange={(c) => setPassword(c.currentTarget.value)}
                    />
                    <h1 className={passwordValidation.isLengthValid ? "password-box-h1 accepted" : "password-box-h1"}>• Use at least 8 characters</h1>
                    <h1 className={passwordValidation.hasLetters ? "password-box-h1 accepted" : "password-box-h1"}>• Use letters</h1>
                    <h1 className={passwordValidation.hasNumbers ? "password-box-h1 accepted" : "password-box-h1"}>• Use numbers</h1>
                    <h1 className={passwordValidation.hasSpecialCharacters ? "password-box-h1 accepted" : "password-box-h1"}>• Use special characters, like @#$%&*</h1>
                    <input
                        type='password'
                        className="password-input2"
                        placeholder='Re-enter new password'
                        onChange={(c) => setRePassword(c.currentTarget.value)}
                    />
                    <button 
                        className={passwordValidation.passwordValid && repassword === password ? "password-save-btn" : "password-save-btn password-save-btn-disabled"}
                        disabled={!passwordValidation.passwordValid || repassword !== password}
                        onClick={handleSaveClick}   
                    >
                        {isChangingPassword ? <LoadingSpinner/> : "Confirm"}
                    </button>
                </div>
            </div>
            <AchewFooter  />
        </div>
    );
}

export default NewPassword;
