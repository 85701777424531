import React, { useState, useEffect } from 'react'
import DonutChart from './doughnut'
import { useDispatch, useSelector } from 'react-redux';
import DoctorHeader from '../doctorheader'
import DoctorSideBar from './dashboardsidebar'
import { getAccountInfo, getAccountMetrics, getHospitalInfoByInternalID, testapi } from '../../../../api/patientAPIs';
import { refreshToken } from '../../../../api/auth';
import { useNavigate, useLocation, json } from 'react-router-dom';
import { setAccountInfo } from '../../../../hooks/accountSlice';
import Loading from '../../loading';
import { centsToDollars, findMetricById, findObjectById, sumValuesByDate } from '../../../assets/functions/functions';
import { metric_calendar, metric_clicks, metric_existing_patient, metric_impressions, metric_new_patient, metric_spending, metric_views } from '../../home/image';
import BarChart from './bar-chart';
import {OnboardingModal, OnboardingTypes} from './patient-modals/onboarding-popup-modal';

const Summary = (props) => {
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const searchParams = new URLSearchParams(location.search);
    const currentHospitalID = searchParams.get('hospital')

    const [hospitalData, setHospitalData] = useState(null)
    const [currentHospitalData, setCurrentHospitalData] = useState({ hospital_name: "" })
    const [metricsData, setMetricsData] = useState(null)
    const [isPageLoading, setPageLoading] = useState(true)
    const [onboardingModalOpen, setOnboardingModalOpen] = useState(false)

    useEffect(() => {
        getAccountInfo().then(res => {
            if (res.status == 200) {
                dispatch(setAccountInfo(res.data))
                const hospitalList = res.hospital

                
                if(hospitalList.length < 1){
                    navigate(`/create-hospital-welcome?user=${res.id}`)
                } else {
                    var promises = []
                    hospitalList.forEach(hID => {
                        promises.push(getHospitalInfoByInternalID(hID))
                    });
                    Promise.all(promises).then(results => {
                        const tempHospitalList = []
                        results.forEach(res => tempHospitalList.push(res.data))
                        setHospitalData(tempHospitalList)
                        const tempCurrentHospital = findObjectById(currentHospitalID, tempHospitalList)
                        setCurrentHospitalData(tempCurrentHospital)
                        getAccountMetrics(tempCurrentHospital.external_id).then(metricsResponse => {
                            setMetricsData(metricsResponse.data)
                            setPageLoading(false)
                            console.log(metricsResponse.data)
                        })
                    })
                }
            }

            if (res.status == 401 || res.status == 403) {
                refreshToken().then(response => {
                    if (response.status == 200) {
                        console.log("Successfully refreshed token")
                        window.location.reload()
                    } else {
                        navigate('/doctor-login')
                    }
                })
            }

        })
    }, []);

    if(isPageLoading){
        return(<Loading/>)
    }

    const parseMetricsData = () => {
        const numTotalAppointments = metricsData.num_total_appointments
        const numNewPatients = metricsData.num_new_patient
        const numExistingPatients = numTotalAppointments - numNewPatients
        
        const profileViewsMetrics = findMetricById("hprofile" + String(currentHospitalData.id), metricsData.MetricDataResults)
        const totalProfileViews = profileViewsMetrics.Values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        
        const profileImpressionsMetrics = findMetricById("himpression" + String(currentHospitalData.id), metricsData.MetricDataResults)
        const totalProfileImpressions = profileImpressionsMetrics.Values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        const percentagePendingAppointments = metricsData.num_pending_appointments / metricsData.num_total_appointments
        const percentageConfirmedAppointments = metricsData.num_confirmed_appointments / metricsData.num_total_appointments
        const percentageDeclinedAppointments = metricsData.num_declined_appointments / metricsData.num_total_appointments
        const percentageCompletedAppointments = 1.00 - percentageConfirmedAppointments - percentagePendingAppointments - percentageDeclinedAppointments
        const numCompletedAppointments = numTotalAppointments - metricsData.num_pending_appointments - metricsData.num_confirmed_appointments - metricsData.num_declined_appointments
        var achewPlanName = ""
        if(metricsData.account_payment_plan === "O"){
            achewPlanName = "$1.00 per appointment"
        }

        const barChartMonthlyViews = sumValuesByDate(profileViewsMetrics.Timestamps, profileViewsMetrics.Values)
        const barChartMonthlyImpressions = sumValuesByDate(profileImpressionsMetrics.Timestamps, profileImpressionsMetrics.Values)

        const accountBalance = centsToDollars(metricsData.account_balance)

        const responseTimeMetrics = findMetricById("response_time", metricsData.MetricDataResults)
        console.log(responseTimeMetrics)
        const averageResponseTime = responseTimeMetrics.Values.length > 0 ? ((responseTimeMetrics.Values.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / responseTimeMetrics.Values.length)) : 0
        const averageResponseTimeInDays = averageResponseTime / 60.0 / 60.0 / 24.0
        const averageResponseTimeString = averageResponseTimeInDays >= 1 ? `${averageResponseTimeInDays.toFixed(0)} days` : "< 1 day"

        const ratingAverage = metricsData.rating_avg
        return {
            numTotalAppointments,
            numNewPatients,
            numExistingPatients,
            totalProfileViews,
            totalProfileImpressions,
            percentagePendingAppointments,
            percentageConfirmedAppointments,
            percentageDeclinedAppointments,
            achewPlanName,
            barChartMonthlyImpressions,
            barChartMonthlyViews,
            accountBalance,
            percentageCompletedAppointments,
            numCompletedAppointments,
            averageResponseTimeString,
            ratingAverage,
        }
    }   

    const parsedMetricsData = parseMetricsData()

    return (
        <div className='grey-supercontainer'>
            <DoctorHeader hospitalData={hospitalData} currentHospitalData={currentHospitalData} />
            <DoctorSideBar currentTab={0} hospitalData={hospitalData} currentHospitalData={currentHospitalData} />
            <OnboardingModal onboardingInfoToShow={[OnboardingTypes.DASHBOARD]} />
            <div className='dashboard-container'>
                <div className='dashboard-single-stat-container'>
                    <div className='dashboard-single-stat'>
                        <img alt="" src={metric_calendar}/>
                        <div className='d-flex flex-column'>
                            <h1>{parsedMetricsData.numTotalAppointments}</h1>
                            <h2>Total Appointments</h2>
                        </div>
                    </div>
                    <div className='dashboard-single-stat'>
                        <img alt="" src={metric_new_patient}/>
                        <div className='d-flex flex-column'>
                            <h1>{parsedMetricsData.numNewPatients}</h1>
                            <h2>New Patients</h2>
                        </div>
                    </div>
                    <div className='dashboard-single-stat'>
                        <img alt="" src={metric_existing_patient}/>
                        <div className='d-flex flex-column'>
                            <h1>{parsedMetricsData.numExistingPatients}</h1>
                            <h2>Existing Patients</h2>
                        </div>
                    </div>
                    <div className='dashboard-single-stat'>
                        <div className='dashboard-single-stat-icon'>
                            <i class="fas fa-star"></i>
                        </div>
                        <div className='d-flex flex-column'>
                            <h1>{parsedMetricsData.ratingAverage.toFixed(1)}</h1>
                            <h2>Rating</h2>
                        </div>
                    </div>
                    
                </div>

                <div className='d-flex justify-content-between mt-4'>
                    <div className='total-booking-container'>
                        <div className='total-booking-container-left'>
                            <div className='total-booking-top-section'>
                                <div className="dashboard-tab"></div>
                                <h1>Total Appointments Booked</h1>
                            </div>
                            <h2>{parsedMetricsData.numTotalAppointments}</h2>

                            <div className='total-booking-row'>
                                <div className="d-flex align-items-center">
                                    <div className="total-booking-row-point total-booking-row-point-pending"></div>
                                    <h3>Pending Appointments</h3>
                                </div>
                                <h4>{metricsData.num_pending_appointments}</h4>
                            </div>
                            <div className='total-booking-row'>
                                <div className="d-flex align-items-center">
                                    <div className="total-booking-row-point total-booking-row-point-confirmed"></div>
                                    <h3>Confirmed Appointments</h3>
                                </div>
                                <h4>{metricsData.num_confirmed_appointments}</h4>
                            </div>
                            <div className='total-booking-row'>
                                <div className="d-flex align-items-center">
                                    <div className="total-booking-row-point total-booking-row-point-completed"></div>
                                    <h3>Completed Appointments</h3>
                                </div>
                                <h4>{parsedMetricsData.numCompletedAppointments}</h4>
                            </div>
                            <div className='total-booking-row'>
                                <div className="d-flex align-items-center">
                                    <div className="total-booking-row-point total-booking-row-point-declined"></div>
                                    <h3>Cancelled Appointments</h3>
                                </div>
                                <h4>{metricsData.num_declined_appointments}</h4>
                            </div>
                        </div>
                        <div className='total-booking-row-chart-container'>
                            <DonutChart percentages={[parsedMetricsData.percentagePendingAppointments, parsedMetricsData.percentageConfirmedAppointments, parsedMetricsData.percentageCompletedAppointments, parsedMetricsData.percentageDeclinedAppointments]}/>
                        </div>
                    </div>
                    <div className='value-new-container'>
                        <div className='value-new-top-section'>
                            <h2>${parsedMetricsData.accountBalance}</h2>
                            <h3>Achew Account Balance</h3>
                        </div>
                        <div className='value-new-bottom-section'>
                            <div className='d-flex justify-content-between'><h1>Price per appointment</h1><h2>$1.00</h2></div>
                            <div className='d-flex justify-content-between'><h1>Price per intake request</h1><h2>$0.50</h2></div>
                            {/* <div className='d-flex justify-content-between'><h1>Last updated</h1><h2>2 hours ago</h2></div> */}
                            <button onClick={() => navigate(`/plan?hospital=${currentHospitalID}`)}>
                                Manage your payments <i class="fas fa-money-bill"></i>
                            </button>
                        </div>
                        
                    </div>
                </div>

                <div className='d-flex justify-content-between mt-4' >
                    <div className='visits-booking-container'>
                        <div className='total-booking-top-section'>
                            <div className="dashboard-tab"></div>
                            <h1>People Viewed Your Profile</h1>
                        </div>
                        <div className='visits-booking-impressions'>
                            <img alt="" src={metric_impressions}/>
                            <div className='d-flex flex-column'>
                                <h1>{parsedMetricsData.totalProfileImpressions}</h1>
                                <h2>Impressions</h2>
                            </div>
                        </div>
                        <div className='visit-booking-bottom-section'>
                            <div className='visits-booking-clicks'>
                                <img alt="" src={metric_clicks}/>
                                <h1>{parsedMetricsData.totalProfileViews}</h1>
                                <h2>Clicks</h2>
                            </div>
                            <div className='visits-booking-clicks'>
                                <img alt="" src={metric_views}/>
                                <h1>{parsedMetricsData.averageResponseTimeString}</h1>
                                <h2>Response Time</h2>
                            </div>
                        </div>
                        <h3>Times your Listing has shown up in search results</h3>
                    </div>
                    <div className='dashboard-chart-container'>
                        <div className='total-booking-top-section'>
                            <div className="dashboard-tab"></div>
                            <h1>Profile Clicks Over Time</h1>
                        </div>
                        <div className='dashboard-chat-wrapper'>
                            <BarChart data={parsedMetricsData.barChartMonthlyViews}/>
                        </div>
                        
                    </div>
                </div>


                
                
            </div>
        </div>

    )
}

export default Summary