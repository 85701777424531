import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { arrow_top_right_grey, chevron_down } from "../../home/image";
import ReactModal from "react-modal";
import AppointmentModal from "../appointment-modal/appointment-modal.jsx";
import MiniCalendar from "./mini-calendar/datepicker.jsx";
import { formatDateMMDDYYYY, monthDictionary } from "../../doctors/dashboard/prototype-calendar/helper-functions.jsx";
import { areDatesEqual, areDatesEqualInUTC, ensureHttps, ensureHttpsWww, formatDateUTCKey, getDaysOfWeek, getStartOfWeekAndEndOfNextWeek, getWeekAndNextWeek, indexOfAvailableSlots, isDateInRange, isDayOfWeekIncluded, isTimeInArray } from "../../../assets/functions/functions.js";
import { useMediaQuery } from 'react-responsive'

const Booker = ({ isBookerFixed, bookerPosition, currentHospitalData, doctorList }) => {
  const navigate = useNavigate();
  const today = new Date();
  const reasonDropdownRef = useRef(null);
  const dateDropdownRef = useRef(null);
  const slotListRef = useRef(null)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 886px)' })

  const [isDateDropdownOpen, setDateDropdownOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [isReasonOpen, setReasonOpen] = useState(false);
  const [isNewPatient, setIsNewPatient] = useState(null);
  const [isAppointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [displayedDate, setDisplayedDate] = useState(today)
  const [appointmentSlotDictionary, setAppointmentSlotDictionary] = useState({})
  const [didAdjustScroll, setDidAdjustScroll] = useState(false)

  const doctorDropdownRef = useRef(null)
  const [selectedDoctor, setSelectedDoctor] = useState("Any Doctor");
  const [isDoctorOpen, setDoctorOpen] = useState(false);

  const { startOfWeek, endOfNextWeek } = getStartOfWeekAndEndOfNextWeek(displayedDate)

  const daysOfWeek = {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
  };

  const dayCorrectionMapping = {
    "0": 1,
    "1": 2,
    "2": 3,
    "3": 4,
    "4": 5,
    "5": 6,
    "6": 0,
  }

  const toggleReasonDropdown = () => {
    setReasonOpen(!isReasonOpen)
  };

  const handleReasonSelected = (reason) => {
    setSelectedReason(reason)
    setReasonOpen(false)
  };

  const toggleDoctorDropdown = () => {
    setDoctorOpen(!isDoctorOpen)
  };

  const handleDoctorSelected = (doc) => {
    setSelectedDoctor(doc)
    setDoctorOpen(false)
  };

  const openNewTab = (path) => {
    console.log(path)
    window.open(path, '_blank');
  };

  const increaseMonth = () => {
    const newDate = new Date(displayedDate.getTime());
    const originalDay = newDate.getDate();

    // Set the date to the first of the month to prevent overflow
    newDate.setDate(1);

    // Increment the month by 1
    newDate.setMonth(newDate.getMonth() + 1);

    // Set the date to the original day or the last day of the new month if the original day is too large
    const daysInNewMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
    newDate.setDate(Math.min(originalDay, daysInNewMonth));

    setDisplayedDate(newDate);
  };

  const decreaseMonth = () => {
    const newDate = new Date(displayedDate.getTime());
    newDate.setMonth(newDate.getMonth() - 1);

    setDisplayedDate(newDate);
  };

  const handleSlotClicked = (appointmentSlotDictionary) => {
    setAppointmentModalOpen(true);
    setAppointmentSlotDictionary(appointmentSlotDictionary)
  };

  const renderAppointmentSlot = (date) => {

    const dateKey = formatDateMMDDYYYY(date)
    if (dateKey in appointmentSlotDictionary) {
      const numOpenSlots = appointmentSlotDictionary[formatDateMMDDYYYY(date)].available_slots.length
      if (numOpenSlots > 0) {
        return renderAppointmentSlotDayRegular(date)
      } else {
        return renderAppointmentSlotDayDisabled(date)
      }
    }

  }

  const renderAppointmentSlotDayRegular = (date) => {
    const numOpenSlots = appointmentSlotDictionary[formatDateMMDDYYYY(date)].available_slots.length
    const iconStyle = "fas fa-circle " + (numOpenSlots < 4 ? "search-result-dayslot-i-limited" : "")
    return (
      <div className="search-result-dayslot" onClick={() => handleSlotClicked(appointmentSlotDictionary)}>
        <h1>{daysOfWeek[date.getDay()]}</h1>
        <h2>{date.getDate()}</h2>
        <div className="d-flex align-items-center ">
          <i className={iconStyle}></i>
          <h3>{numOpenSlots} slots</h3>
        </div>
      </div>
    );
  };

  const renderAppointmentSlotDayDisabled = (date) => {
    return (
      <div className="search-result-dayslot-disabled">
        <h1>{daysOfWeek[date.getDay()]}</h1>
        <h2>{date.getDate()}</h2>
        <div className="d-flex align-items-center">
          <i className="fas fa-circle"></i>
          <h3>No slots</h3>
        </div>
      </div>
    );
  };

  const renderAppointmentModal = () => {
    let heightOffset = 100 / 2;
    let offsetPx = heightOffset + "px";

    const desktopStyle = {
      content: {
        border: "0",
        borderRadius: "4px",
        bottom: "auto",
        left: "50%",
        position: "fixed",
        right: "auto",
        top: "10%", // start from center
        transform: "translate(-50%,-" + offsetPx + ")", // adjust top "up" based on height
        width: "600px",
        height: "90%",
      },
      overlay: {
        backgroundColor: "rgb(0,0,0,0.5)",
        overflowY: "auto",
      },
    };

    const tabletStyle = {
      content: {
        border: "0",
        borderRadius: "0px",
        bottom: "auto",
        left: "50%",
        position: "fixed",
        right: "auto",
        transform: "translate(-50%,-" + offsetPx + ")", // adjust top "up" based on height
        width: "100%",
        height: "100%",
      },
      overlay: {
        backgroundColor: "rgb(0,0,0,0.5)",
        overflowY: "hidden",
      },
    };

    return (
      <div>
        <ReactModal
          isOpen={isAppointmentModalOpen}
          contentLabel="Minimal Modal Example"
          style={isTabletOrMobile ? tabletStyle : desktopStyle}
          onRequestClose={() => setAppointmentModalOpen(false)}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
        >
          <AppointmentModal
            appointmentModalData={currentHospitalData}
            appointmentModalFirstDate={startOfWeek}
            closeAppointmentModal={() => setAppointmentModalOpen(false)}
            appointmentSlotDictionary={appointmentSlotDictionary}
            reason={selectedReason}
            isNewPatient={isNewPatient}
            doctor={selectedDoctor}
          />
        </ReactModal>
      </div>
    );
  };

  useEffect(() => {
    if (slotListRef.current) {
      // Select the first child with the specific class name
      const firstChild = slotListRef.current.querySelector('.search-result-dayslot');
      // If the child exists, scroll to it
      if (firstChild && !didAdjustScroll) {
        setDidAdjustScroll(true)
        const scrollPosition = firstChild.offsetLeft;
        const adjustedScrollPosition = scrollPosition - (firstChild.offsetWidth / 2);
        // Scroll the container to the calculated position
        slotListRef.current.scrollLeft = adjustedScrollPosition;
      }

    }
  })

  useEffect(() => {
    //Initialize displayed days and the slot dictionary
    const thisWeekNextWeek = getWeekAndNextWeek(displayedDate).currentWeek.concat(getWeekAndNextWeek(displayedDate).nextWeek)
    const tempAppointmentSlotDictionary = {}

    //Go through each schedule and case on their frequency type
    console.log(currentHospitalData.schedules, selectedDoctor.allow_new_patient)
    currentHospitalData.schedules.forEach(schedule => {
      if((selectedDoctor !== "Any Doctor" && 
         selectedDoctor.external_id !== schedule.d_id) || 
         (selectedDoctor !== "Any Doctor" && !selectedDoctor.allow_new_patient && isNewPatient)
        ){
        return
      }
      if (schedule.frequency_type === "O") {
        //Go through each day and see if rule applies
        
        thisWeekNextWeek.forEach(day => {
          const appointmentDate = new Date(schedule.start_date * 1000)
          const dateKey = formatDateMMDDYYYY(day)
          if (areDatesEqualInUTC(day, appointmentDate) && day.getTime() >= today.getTime()) {
            console.log("Once")
            if (dateKey in tempAppointmentSlotDictionary) {
              tempAppointmentSlotDictionary[dateKey].total_slots += schedule.total_num_slots
            } else {
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: schedule.total_num_slots,
                available_slots: []
              }
            }

            schedule.timeslots.forEach(time => {
              if (!isTimeInArray(tempAppointmentSlotDictionary[dateKey].available_slots, time)) {
                tempAppointmentSlotDictionary[dateKey].available_slots.push({
                  "time": time,
                  "schedule_id": schedule.external_id,
                  "schedule_internal_id": schedule.id,
                  "doctor_id": schedule.d_id,
                  "duration": schedule.duration,
                })
              }
            })
          } else {
            if (!(dateKey in tempAppointmentSlotDictionary)) {
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: 0,
                available_slots: []
              }
            }
          }
        })
      } else if (schedule.frequency_type === "D") {
        thisWeekNextWeek.forEach(day => {
          const dateKey = formatDateMMDDYYYY(day)
          if (!isDayOfWeekIncluded(schedule.frequency_interval, day) && isDateInRange(schedule.start_date * 1000, schedule.end_date * 1000, day) && day.getTime() >= today.getTime()) {
            if (dateKey in tempAppointmentSlotDictionary) {
              tempAppointmentSlotDictionary[dateKey].total_slots += schedule.total_num_slots
            } else {
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: schedule.total_num_slots,
                available_slots: []
              }
            }

            schedule.timeslots.forEach(time => {
              if (!isTimeInArray(tempAppointmentSlotDictionary[dateKey].available_slots, time)) {
                tempAppointmentSlotDictionary[dateKey].available_slots.push({
                  "time": time,
                  "schedule_id": schedule.external_id,
                  "schedule_internal_id": schedule.id,
                  "doctor_id": schedule.d_id,
                  "duration": schedule.duration,
                })
              }
            })

          } else {
            if (!(dateKey in tempAppointmentSlotDictionary)) {
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: 0,
                available_slots: []
              }
            }
          }
        })
      } else if (schedule.frequency_type === "W") {
        thisWeekNextWeek.forEach(day => {
          const dateKey = formatDateMMDDYYYY(day)
          if (day.getDay() === dayCorrectionMapping[schedule.frequency_interval] && isDateInRange(schedule.start_date * 1000, schedule.end_date * 1000, day) && day.getTime() >= today.getTime()) {
            if (dateKey in tempAppointmentSlotDictionary) {
              tempAppointmentSlotDictionary[dateKey].total_slots += schedule.total_num_slots
            } else {
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: schedule.total_num_slots,
                available_slots: []
              }
            }
            
              schedule.timeslots.forEach(time => {
                if (!isTimeInArray(tempAppointmentSlotDictionary[dateKey].available_slots, time)) {
                  tempAppointmentSlotDictionary[dateKey].available_slots.push({
                    "time": time,
                    "schedule_id": schedule.external_id,
                    "schedule_internal_id": schedule.id,
                    "doctor_id": schedule.d_id,
                    "duration": schedule.duration,
                  })
                }
              })
            
          } else {
            if(!(dateKey in tempAppointmentSlotDictionary)){
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: 0,
                available_slots: []
              }
            }
            
          }
        })
      }
    })
    currentHospitalData.schedules.forEach(schedule => {
      const takenAppointments = schedule.appointments ? schedule.appointments : []
      takenAppointments.forEach(slot => {
        const dateKey = formatDateUTCKey(new Date(slot.slot_date * 1000))
        console.log(dateKey)
        if (tempAppointmentSlotDictionary[dateKey] != undefined) {
          const indexToRemove = indexOfAvailableSlots(tempAppointmentSlotDictionary[dateKey].available_slots, slot.slot_time, schedule.external_id)
          console.log(indexToRemove, tempAppointmentSlotDictionary[dateKey].available_slots)
          if (indexToRemove !== -1) {
            console.log("Removing: " + indexToRemove)
            tempAppointmentSlotDictionary[dateKey].total_num_slots -= 1
            tempAppointmentSlotDictionary[dateKey].available_slots.splice(indexToRemove, 1);
          }
        }
      })
    })

    setAppointmentSlotDictionary(tempAppointmentSlotDictionary)

    //Handle outside click event for dropdown
    const handleClickReasonOutside = (event) => {
      if (
        reasonDropdownRef.current &&
        !reasonDropdownRef.current.contains(event.target)
      ) {
        setReasonOpen(false);
      }
    };

    const handleClickDoctorOutside = (event) => {
      if (
        doctorDropdownRef.current &&
        !doctorDropdownRef.current.contains(event.target)
      ) {
        setDoctorOpen(false);
      }
    };
    window.addEventListener("click", handleClickReasonOutside);
    window.addEventListener("click", handleClickDoctorOutside);
    return () => {
      window.removeEventListener("click", handleClickReasonOutside);
      window.removeEventListener("click", handleClickDoctorOutside);
    };
  }, [displayedDate, selectedDoctor, isNewPatient])
 
  useEffect(() => {
    //Initialize displayed days and the slot dictionary
    const thisWeekNextWeek = getWeekAndNextWeek(displayedDate).currentWeek.concat(getWeekAndNextWeek(displayedDate).nextWeek)
    const tempAppointmentSlotDictionary = {}

    //Go through each schedule and case on their frequency type
    console.log(currentHospitalData.schedules, selectedDoctor.allow_new_patient)
    currentHospitalData.schedules.forEach(schedule => {
      if((selectedDoctor !== "Any Doctor" && 
         selectedDoctor.external_id !== schedule.d_id) || 
         (selectedDoctor !== "Any Doctor" && !selectedDoctor.allow_new_patient && isNewPatient)
        ){
        return
      }
      if (schedule.frequency_type === "O") {
        //Go through each day and see if rule applies
        
        thisWeekNextWeek.forEach(day => {
          const appointmentDate = new Date(schedule.start_date * 1000)
          const dateKey = formatDateMMDDYYYY(day)
          if (areDatesEqualInUTC(day, appointmentDate) && day.getTime() >= today.getTime()) {
            console.log("Once")
            if (dateKey in tempAppointmentSlotDictionary) {
              tempAppointmentSlotDictionary[dateKey].total_slots += schedule.total_num_slots
            } else {
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: schedule.total_num_slots,
                available_slots: []
              }
            }

            schedule.timeslots.forEach(time => {
              if (!isTimeInArray(tempAppointmentSlotDictionary[dateKey].available_slots, time)) {
                tempAppointmentSlotDictionary[dateKey].available_slots.push({
                  "time": time,
                  "schedule_id": schedule.external_id,
                  "schedule_internal_id": schedule.id,
                  "doctor_id": schedule.d_id,
                  "duration": schedule.duration,
                })
              }
            })
          } else {
            if (!(dateKey in tempAppointmentSlotDictionary)) {
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: 0,
                available_slots: []
              }
            }
          }
        })
      } else if (schedule.frequency_type === "D") {
        thisWeekNextWeek.forEach(day => {
          const dateKey = formatDateMMDDYYYY(day)
          if (!isDayOfWeekIncluded(schedule.frequency_interval, day) && isDateInRange(schedule.start_date * 1000, schedule.end_date * 1000, day)  && day.getTime() >= today.getTime()) {
            if (dateKey in tempAppointmentSlotDictionary) {
              tempAppointmentSlotDictionary[dateKey].total_slots += schedule.total_num_slots
            } else {
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: schedule.total_num_slots,
                available_slots: []
              }
            }

            schedule.timeslots.forEach(time => {
              if (!isTimeInArray(tempAppointmentSlotDictionary[dateKey].available_slots, time)) {
                tempAppointmentSlotDictionary[dateKey].available_slots.push({
                  "time": time,
                  "schedule_id": schedule.external_id,
                  "schedule_internal_id": schedule.id,
                  "doctor_id": schedule.d_id,
                  "duration": schedule.duration,
                })
              }
            })

          } else {
            if (!(dateKey in tempAppointmentSlotDictionary)) {
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: 0,
                available_slots: []
              }
            }
          }
        })
      } else if (schedule.frequency_type === "W") {
        thisWeekNextWeek.forEach(day => {
          const dateKey = formatDateMMDDYYYY(day)
          if (day.getDay() === dayCorrectionMapping[schedule.frequency_interval] && isDateInRange(schedule.start_date * 1000, schedule.end_date * 1000, day) && day.getTime() >= today.getTime()) {
            if (dateKey in tempAppointmentSlotDictionary) {
              tempAppointmentSlotDictionary[dateKey].total_slots += schedule.total_num_slots
            } else {
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: schedule.total_num_slots,
                available_slots: []
              }
            }
            
              schedule.timeslots.forEach(time => {
                if (!isTimeInArray(tempAppointmentSlotDictionary[dateKey].available_slots, time)) {
                  tempAppointmentSlotDictionary[dateKey].available_slots.push({
                    "time": time,
                    "schedule_id": schedule.external_id,
                    "schedule_internal_id": schedule.id,
                    "doctor_id": schedule.d_id,
                    "duration": schedule.duration,
                  })
                }
              })
            
          } else {
            if(!(dateKey in tempAppointmentSlotDictionary)){
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: 0,
                available_slots: []
              }
            }
            
          }
        })
      }
    })
    currentHospitalData.schedules.forEach(schedule => {
      const takenAppointments = schedule.appointments ? schedule.appointments : []
      takenAppointments.forEach(slot => {
        const dateKey = formatDateUTCKey(new Date(slot.slot_date * 1000))
        console.log(dateKey)
        if (tempAppointmentSlotDictionary[dateKey] != undefined) {
          const indexToRemove = indexOfAvailableSlots(tempAppointmentSlotDictionary[dateKey].available_slots, slot.slot_time, schedule.external_id)
          console.log(indexToRemove, tempAppointmentSlotDictionary[dateKey].available_slots)
          if (indexToRemove !== -1) {
            console.log("Removing: " + indexToRemove)
            tempAppointmentSlotDictionary[dateKey].total_num_slots -= 1
            tempAppointmentSlotDictionary[dateKey].available_slots.splice(indexToRemove, 1);
          }
        }
      })
    })

    setAppointmentSlotDictionary(tempAppointmentSlotDictionary)
  }, [])

  const fixedStyle = { position: isBookerFixed ? 'fixed' : 'fixed', left: bookerPosition.xPosition, top: bookerPosition.yPosition }

  if(currentHospitalData.schedules.length === 0 && !currentHospitalData.hospital_website){
    return null
  }

  return (
    <div className={`booker-container ${isBookerFixed ? 'fixed' : ''}`} style={isTabletOrMobile ? {} : fixedStyle}>
      {renderAppointmentModal()}
      {
        currentHospitalData.schedules.length > 0 &&
        <div className="booker-header">
          <h1>Book an appointment for free</h1>
          <h2>The office partners with Achew to schedule appointments</h2>
        </div>
      }
      <div className="booker-body">
        {currentHospitalData.schedules.length > 0 && <><h1>Scheduling details</h1>
          <div
            className="booker-reason-input-container"
            ref={doctorDropdownRef}
          >
            <div className="booker-body-dropdown" onClick={toggleDoctorDropdown}>
              <h1>
                {selectedDoctor !== "Any Doctor"
                  ? `Dr. ${selectedDoctor.doctor_name_first} ${selectedDoctor.doctor_name_last}`
                  : "Any Doctor"}
              </h1>
              <img alt="chevron down" src={chevron_down} />
            </div>
            {isDoctorOpen && (
              <ul className="booker-dropdown-menu">
                <li onClick={() => handleDoctorSelected("Any Doctor")}>
                  Any Doctor
                </li>
                {doctorList.map((doctor, index) => (
                  <li key={index} onClick={() => handleDoctorSelected(doctor)}>
                    Dr. {doctor.doctor_name_first} {doctor.doctor_name_last}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {selectedDoctor && selectedDoctor !== "Any Doctor" && <div
            className="booker-reason-input-container"
            ref={reasonDropdownRef}
          >
            <div className="booker-body-dropdown" onClick={toggleReasonDropdown}>
              <h1>
                {selectedReason != null
                  ? selectedReason
                  : "Choose a reason for your visit"}
              </h1>
              <img alt="chevron down" src={chevron_down} />
            </div>
            {isReasonOpen && (
              <ul className="booker-dropdown-menu">
                {selectedDoctor.services.map((option, index) => (
                  <li key={index} onClick={() => handleReasonSelected(option)}>
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>}
          <div className="booker-new-patient-container">
            <div className="booker-new-patient">
              <input
                type="radio"
                onChange={() => setIsNewPatient(true)}
                checked={isNewPatient}
              />
              <h1>New patient</h1>
            </div>
            <div className="booker-new-patient">
              <input
                type="radio"
                onChange={() => setIsNewPatient(false)}
                checked={isNewPatient === false}
              />
              <h1>Returning patient</h1>
            </div>
          </div>

          {/* <h1>Available appointments</h1>
        <div className="booker-visit-container">
            <button className={isVideoVisit ? "booker-visit-btn-inactive" : "booker-visit-btn-active"} onClick={() => setIsVideoVisit(false)}>
                In-person
            </button>
            <button className={isVideoVisit ? "booker-visit-btn-active" : "booker-visit-btn-inactive"} onClick={() => setIsVideoVisit(true)}>
                Video visit
            </button>
        </div> */}

          <h1 style={{ marginBottom: '8px' }}>Choose a time</h1>
          <div className="position-relative" ref={dateDropdownRef}>
            <button
              className="search-result-filter-button"
              onClick={() => setDateDropdownOpen(!isDateDropdownOpen)}
            >
              {monthDictionary[startOfWeek.getMonth()]} {startOfWeek.getDate()}, {startOfWeek.getFullYear()} - {monthDictionary[endOfNextWeek.getMonth()]} {endOfNextWeek.getDate()}, {endOfNextWeek.getFullYear()}
              <img alt="chevron" src={chevron_down} />
            </button>
            {isDateDropdownOpen && (
              <div className="date-dropdown-menu">
                <MiniCalendar displayedDate={displayedDate} setDisplayedDate={setDisplayedDate} increaseMonth={increaseMonth} decreaseMonth={decreaseMonth} setPopupOpen={setDateDropdownOpen} />
              </div>
            )}
          </div>
          {
            isTabletOrMobile ?
              <div className="booker-appointment-slots-container" ref={slotListRef}>

                <div className="booker-appointment-slots-row">
                  {getWeekAndNextWeek(displayedDate).currentWeek.map(date => renderAppointmentSlot(date, currentHospitalData))}
                  {getWeekAndNextWeek(displayedDate).nextWeek.map(date => renderAppointmentSlot(date, currentHospitalData))}
                </div>
              </div>
              :
              <div className="booker-appointment-slots-container">

                <div className="booker-appointment-slots-row">
                  {getWeekAndNextWeek(displayedDate).currentWeek.map(date => renderAppointmentSlot(date, currentHospitalData))}
                </div>
                <div className="booker-appointment-slots-row">
                  {getWeekAndNextWeek(displayedDate).nextWeek.map(date => renderAppointmentSlot(date, currentHospitalData))}
                </div>
              </div>

          }</>}
        {
          currentHospitalData.hospital_website && currentHospitalData.hospital_website.length > 0 &&
          <div className="booker-appointment-website-container">
            <h1>Clinic Webpage</h1>
            <h2>For more appointment slots and information on {currentHospitalData.hospital_name} please visit the link below.</h2>
            <div className="d-flex align-items-center">
              <i className="fas fa-link"></i>
              <a className="booker-website-link" onClick={() => openNewTab(ensureHttpsWww(currentHospitalData.hospital_website))} target="_blank" rel="noopener noreferrer">{currentHospitalData.hospital_website}</a>
            </div>

          </div>
        }

      </div>
    </div>
  );
};

export default Booker;
