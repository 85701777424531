import React from 'react';

const LoadingSpinner = ({message}) => {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner"></div>
      {message && message !== "" && <h1>{message}</h1>}
    </div>
  );
};

export default LoadingSpinner;