import React, { useState, useEffect, useRef } from 'react';

const IntakeActionDropdown = ({currentHospitalData}) => {

    const [isActionDropdownOpen, setActionDropdownOpen] = useState(false);
    const actionDropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (actionDropdownRef.current && !actionDropdownRef.current.contains(event.target)) {
                setActionDropdownOpen(false);
            }
        }
        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);
    
    return (
        <div
            className="intake-input-container"
            ref={actionDropdownRef}
        >
            <div
                className="intake-dropdown"
                onClick={() => setActionDropdownOpen(!isActionDropdownOpen)}
            >
                <i class="fas fa-ellipsis-h"></i>
            </div>
            {isActionDropdownOpen && (
                <ul className="intake-dropdown-menu">
                    <li>
                        Cancel
                    </li>
                </ul>
            )}
        </div>
    );
};

export default IntakeActionDropdown;