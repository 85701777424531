import React from "react";
import {
  getInsuranceById,
  getPlanName,
  isValidImageUrl,
} from "../../../assets/functions/functions";
import insuranceSampleData from "../../../../sampledata/insurance.json";
const InsuranceElement = ({
  insuranceID,
  planID,
  onDeleteInsurance,
  canDeleteInsurance = true,
}) => {
    
  const insuranceObject = getInsuranceById(insuranceID, insuranceSampleData);
  const planObject = getPlanName(insuranceID, planID, insuranceSampleData);

  return (
    <button className="insurance-element-button">
      {isValidImageUrl(insuranceObject.logo_url) && (
        <div className="insurance-aspect-ratio-container">
          <img
            src={insuranceObject.logo_url}
            alt="None"
            className="insurance-aspect-ratio-img"
          />
        </div>
      )}
      <div className="insurance-element-button-txt">
        <h1>{insuranceObject.name}</h1>
        <h2>{planObject.title}</h2>
      </div>
      {canDeleteInsurance && (
        <i className="fas fa-times" onClick={() => onDeleteInsurance()}></i>
      )}
    </button>
  );
};

export default InsuranceElement;
