import React, {useEffect, useRef} from 'react'
import img1 from "../../../assets/images/features/feature-01.jpg";
import { findDoctorById } from "../../../assets/functions/functions";
import { sample_doctor, default_profile } from '../../home/image';
import { useMediaQuery } from 'react-responsive';
const ProfileDoctorModal = ({ currentDoctor, currentHospitalData, setCurrentDoctor, setDoctorModalOpen, doctorList }) => {
    const modalContainerRef = useRef(null);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1050px)' })
    useEffect(() => {
        document.body.classList.add('modal-open');

        const handleClickOutsideModal = (event) => {
            if (modalContainerRef.current && !modalContainerRef.current.contains(event.target)) {
                setDoctorModalOpen(false);
            }
        };
        window.addEventListener('mousedown', handleClickOutsideModal);
        
        return () => {
            window.removeEventListener('mousedown', handleClickOutsideModal);
            document.body.classList.remove('modal-open');
        };
    }, []);

    const switchDoctors = (doctor) => {
        console.log(doctor)
        setCurrentDoctor(doctor)

    }

    const renderService = (serv, index) => {
        return (
            <button className="pe-tag-container"><h3>{serv}</h3></button>
        )
    }

    const renderDoctor = (doctor) => {
        return (
            <div className="profile-doctor-modal-container-top-section" onClick={() => switchDoctors(doctor)}>
                <div className="profile-doctor-modal-img2">
                    <img src={doctor.profile_image_url || default_profile} alt="Doc" className='profile-doctor-modal-im-img2' />
                </div>

                <div className="profile-doctor-modal-profile">
                    <h1>Dr. {doctor.doctor_name_first} {doctor.doctor_name_last}, {doctor.degrees.degree}</h1>
                    <h3>{currentHospitalData.hospital_name}</h3>
                    <h3>{doctor.specialty}</h3>
                </div>
            </div>
        )
    }

    return (
        <div className="profile-doctor-modal-overlay">
            <button className='profile-image-close-btn' onClick={() => setDoctorModalOpen(false)}>
                <div className='profile-image-close-btn-text'>Close</div>
                <i className="fas fa-times"></i>
            </button>
            <div className="profile-doctor-modal" ref={modalContainerRef}>
                <div className="profile-doctor-modal-left">
                    <div className='profile-doctor-modal-left-header'>
                        <div className="profile-doctor-modal-img">
                            <img src={currentDoctor.profile_image_url || default_profile} alt="Doc" className='profile-doctor-modal-im-img ' />
                        </div>
                        <div className="profile-doctor-modal-txt">
                            <h1>Dr. {currentDoctor.doctor_name_first} {currentDoctor.doctor_name_last}, {currentDoctor.degrees.degree}</h1>
                            <h3>{currentHospitalData.hospital_name}</h3>
                            <span>
                                <i class="fas fa-stethoscope"></i>
                                <h4>{currentDoctor.specialty}</h4>
                            </span>
                            {currentDoctor.gender && currentDoctor.gender.length > 0 && <span>
                                <i class="fas fa-genderless"></i>
                                <h4>{currentDoctor.gender}</h4>
                            </span>}
                            {currentDoctor.education.school && currentDoctor.education.school.length > 0 && <span>
                                <i class="fas fa-book"></i>
                                <h4>{currentDoctor.education.school}</h4>
                            </span>}
                        </div>
                    </div>

                    <div className="profile-doctor-contents">
                        <div className="profile-doctor-about">
                            <h1>About Dr. {currentDoctor.doctor_name_first} {currentDoctor.doctor_name_last}</h1>
                            <h2>{currentDoctor.about}</h2>
                        </div>
                    </div>
                    {currentDoctor.languages && currentDoctor.languages.length > 0 && <div className="profile-doctor-contents">
                        <div className="profile-doctor-about">
                            <h1>Spoken Languages</h1>
                            <div className='profile-doctor-service-list'>
                                {(currentDoctor.languages) && currentDoctor.languages.map(serv => renderService(serv))}
                            </div>
                        </div>
                    </div>}
                    <div className="profile-doctor-contents">
                        <div className="profile-doctor-about">
                            <h1>Services and Care</h1>
                            <div className='profile-doctor-service-list'>
                                {(currentDoctor.services != {}) && currentDoctor.services.map(serv => renderService(serv))}
                            </div>
                        </div>
                    </div>
                    
                </div>
                {
                    !isTabletOrMobile && (
                        <div className='profile-doctor-modal-right'>
                            <h1>All Doctors</h1>
                            {doctorList.map(doctor => renderDoctor(doctor))}
                        </div>
                    )
                }
                

            </div>
        </div>
    )
}

export default ProfileDoctorModal