import React, { useState, useEffect, useRef } from 'react';
import { chevron_down, sample_doctor } from '../../home/image';
import Calendar from './prototype-calendar/calendar';
import AchewHeader from '../../achew-header';
import MiniCalendar from '../../patients/doctorprofile/mini-calendar/datepicker';
import { formatDateMMDDYYYY, monthDictionary } from './prototype-calendar/helper-functions';
import { convertTimeToInt, convertTo12HourFormatList, convertUTCToLocal, formatDateToLongString, frequencyMap, getDoctorProfilePicture, getMidnightUTCTimestamp, halfHourTimeIntervals, replaceObjectByExternalId } from '../../../assets/functions/functions';
import LoadingSpinner from '../../patients/bookingv2/spinner';
import { DeleteSchedule, DisableSchedule, EditSchedule, RegisterSchedule } from '../../../../api/patientAPIs';
const EditSchedulePopup = ({ doctorList, setModalOpen, currentHospitalData, scheduleData, scheduleList, setScheduleList, toggleAlertWithMessage }) => {
  const frequencyDropdownRef = useRef(null)
  const today = new Date()
  const [displayedDate, setDisplayedDate] = useState(today)
  const [isFrequencyDropdownOpen, setFrequencyDropdownOpen] = useState(false)
  const startDateDropdownRef = useRef(null)
  const [isStartDateDropdownOpen, setStartDateDropdownOpen] = useState(false)
  const dayDropdownRef = useRef(null)
  const [isDayDropdownOpen, setDayDropdownOpen] = useState(false)
  const endDateDropdownRef = useRef(null)
  const [isEndDateDropdownOpen, setEndDateDropdownOpen] = useState(false)
  const [name, setName] = useState(null)
  const [startDate, setStartDate] = useState(displayedDate)
  const [endDate, setEndDate] = useState(displayedDate)
  const [frequency, setFrequency] = useState(null)
  const [timeSlots, setTimeSlots] = useState("")
  const [timeSlotsList, setTimeSlotsList] = useState([])
  const [ampm, setAMPM] = useState("AM")
  const [dayOfWeek, setDayOfWeek] = useState(null)
  const [schedulePreviewConfig, setSchedulePreviewConfig] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(null)
  const [assignedDoctors, setAssignedDoctors] = useState([])
  const [duration, setDuration] = useState(null)
  const [isTimeOpen, setIsTimeOpen] = useState(false);
  const [originalSchedule, setOriginalSchedule] = useState(null)
  const timeDropdownRef = useRef(null);

  const frequencyList = ["Once", "Daily", "Weekly"]
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const frequencyDictionary = {
    "Once": "O",
    "Daily": "D",
    "Weekly": "W",
  }

  // Which days are open
  const [daysSchedule, setDaysSchedule] = useState({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true
  });

  useEffect(() => {
    setName(scheduleData.schdeulde_name)
    setStartDate(convertUTCToLocal(new Date(scheduleData.start_date * 1000)))
    setEndDate(convertUTCToLocal(new Date(scheduleData.end_date * 1000)))
    setFrequency(frequencyMap[scheduleData.frequency_type])
    setDuration(scheduleData.duration / 60)
    if (scheduleData.frequency_type === "W") {
      setDayOfWeek(scheduleData.frequency_interval)
    } else if (scheduleData.frequency_type === "D") {
      const tempFreqInterval = String(scheduleData.frequency_interval)
      const tempDaysSchedule = {

        monday: !tempFreqInterval.includes("0"),
        tuesday: !tempFreqInterval.includes("1"),
        wednesday: !tempFreqInterval.includes("2"),
        thursday: !tempFreqInterval.includes("3"),
        friday: !tempFreqInterval.includes("4"),
        saturday: !tempFreqInterval.includes("5"),
        sunday: !tempFreqInterval.includes("6"),
      }
      setDaysSchedule(tempDaysSchedule)
    }
    console.log(scheduleData)
    const formattedTimeSlots = scheduleData.timeslots.map(time => convertTo12HourFormatList(time))
    setTimeSlotsList(formattedTimeSlots)


    const tempTimeSlots = formattedTimeSlots
    var tempConfig = null
    if (frequencyMap[scheduleData.frequency_type] === "Once") {
      tempConfig = {
        frequency: "Once",
        time: timeSlots,
        date: new Date(scheduleData.start_date * 1000),
      }
    } else if (frequencyMap[scheduleData.frequency_type] === "Daily") {
      tempConfig = {
        frequency: "Daily",
        time: tempTimeSlots,
        startDate: new Date(scheduleData.start_date * 1000),
        endDate: new Date(scheduleData.end_date * 1000),
      }
      console.log(tempConfig)
    } else if (frequencyMap[scheduleData.frequency_type] === "Weekly") {
      tempConfig = {
        frequency: "Weekly",
        time: tempTimeSlots,
        day: scheduleData.frequency_interval,
        startDate: new Date(scheduleData.start_date * 1000),
        endDate: new Date(scheduleData.end_date * 1000),
      }
    }
    setSchedulePreviewConfig(tempConfig)

    const handleClickStartOutside = (event) => {
      if (startDateDropdownRef.current && !startDateDropdownRef.current.contains(event.target)) {
        setStartDateDropdownOpen(false);
      }
    };
    const handleClickEndOutside = (event) => {
      if (endDateDropdownRef.current && !endDateDropdownRef.current.contains(event.target)) {
        setEndDateDropdownOpen(false);
      }
    };
    const handleClickTimeOutside = (event) => {
      if (
        timeDropdownRef.current &&
        !timeDropdownRef.current.contains(event.target)
      ) {
        setIsTimeOpen(false);
      }
    };
    // Attach the event listener
    window.addEventListener('click', handleClickEndOutside);
    window.addEventListener('click', handleClickStartOutside);
    window.addEventListener("click", handleClickTimeOutside);
    return () => {
      window.removeEventListener('click', handleClickEndOutside);
      window.removeEventListener('click', handleClickStartOutside);
      window.removeEventListener("click", handleClickTimeOutside);
    };
  }, []);

  useEffect(() => {
    updateAppointmentDictionary()
  }, [startDate, endDate, daysSchedule, frequency, dayOfWeek])

  const toggleDay = (day) => {
    setDaysSchedule(prevSchedule => ({
      ...prevSchedule,
      [day]: !prevSchedule[day]
    }));
  };

  const increaseMonthCalendar = () => {
    const newDate = new Date(displayedDate.getTime());

    const currentDay = newDate.getDate();
    newDate.setDate(1);
    newDate.setMonth(newDate.getMonth() + 1);

    const daysInNewMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
    newDate.setDate(Math.min(currentDay, daysInNewMonth));

    setDisplayedDate(newDate);
  };

  const decreaseMonthCalendar = () => {
    const newDate = new Date(displayedDate.getTime());
    newDate.setMonth(newDate.getMonth() - 1);

    setDisplayedDate(newDate);
  };

  const increaseMonth = () => {
    const newDate = new Date(displayedDate.getTime());
  const originalDay = newDate.getDate();

  // Set the date to the first of the month to prevent overflow
  newDate.setDate(1);

  // Increment the month by 1
  newDate.setMonth(newDate.getMonth() + 1);

  // Set the date to the original day or the last day of the new month if the original day is too large
  const daysInNewMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
  newDate.setDate(Math.min(originalDay, daysInNewMonth));

  setDisplayedDate(newDate);
  };

  const decreaseMonth = () => {
    const newDate = new Date(startDate.getTime());
    newDate.setMonth(newDate.getMonth() - 1);

    setStartDate(newDate);
  }

  const handleDurationChanged = (event) => {
    // Remove non-numeric characters
    const newValue = event.target.value.replace(/[^0-9]/g, '');
    setDuration(newValue);
  };

  const validateSchedule = () => {
    if (
      !name || name === '' ||
      !duration || duration === undefined || duration === "" ||
      !frequency || frequency === undefined || frequency === "" ||
      !startDate || !endDate || (frequency !== "Once" && startDate.getTime() > endDate.getTime()) ||
      (frequency !== "Once" && (!timeSlotsList || timeSlotsList.length === 0)) ||
      (frequency === "Weekly" && (dayOfWeek == null || dayOfWeek === ""))
    ) {
      return false
    }

    return true
  }

  const handleDisableSchedule = (shouldEnable) => {
    const originalSchedule = { ...scheduleData }
    originalSchedule["is_enabled"] = shouldEnable

    DisableSchedule(scheduleData.id, currentHospitalData.external_id, originalSchedule).then(response => {
      const tempScheduleList = [...scheduleList]
      console.log(replaceObjectByExternalId(tempScheduleList, response.data))
      setScheduleList(replaceObjectByExternalId(tempScheduleList, response.data))
      setModalOpen(false)
      toggleAlertWithMessage("Successfully disabled schedule.")
    })
      .catch(err => {
        setModalOpen(false)
        console.log(err)
        toggleAlertWithMessage("An error occurred while trying to disable this schedule.")
      })
  }

  const handleEnableSchedule = () => {
    const originalSchedule = { ...scheduleData }
    originalSchedule["is_enabled"] = true

    DisableSchedule(scheduleData.id, currentHospitalData.external_id, originalSchedule).then(response => {
      const tempScheduleList = [...scheduleList]
      console.log(replaceObjectByExternalId(tempScheduleList, response.data))
      setScheduleList(replaceObjectByExternalId(tempScheduleList, response.data))
      setModalOpen(false)
      toggleAlertWithMessage("Successfully enabled schedule.")
    })
      .catch(err => {
        setModalOpen(false)
        console.log(err)
        toggleAlertWithMessage("An error occurred while trying to enable this schedule.")
      })
  }

  const submitSchedule = () => {
    if (!validateSchedule()) {
      toggleAlertWithMessage("Error modifying schedule: Please check each field carefully")
      return
    }
    const midnightStartDate = getMidnightUTCTimestamp(startDate) / 1000
    const midnightEndDate = frequency === "Once" ? (getMidnightUTCTimestamp(startDate) / 1000) + 86400 : getMidnightUTCTimestamp(endDate) / 1000

    const durationInSeconds = parseInt(duration) * 60
    const formattedTimeSlotsList = timeSlotsList.map(time => convertTimeToInt(time))
    var freqInternval = 7;
    if (frequency === "Daily") {
      if (!daysSchedule["monday"]) { freqInternval += "0" }
      if (!daysSchedule["tuesday"]) { freqInternval += "1" }
      if (!daysSchedule["wednesday"]) { freqInternval += "2" }
      if (!daysSchedule["thursday"]) { freqInternval += "3" }
      if (!daysSchedule["friday"]) { freqInternval += "4" }
      if (!daysSchedule["saturday"]) { freqInternval += "5" }
      if (!daysSchedule["sunday"]) { freqInternval += "6" }
    } else if (frequency === "Weekly") {
      freqInternval = dayOfWeek ? dayOfWeek : 0
    } else if (freqInternval === "Once") {
      freqInternval = 0
    }
    const updatedScheduleData = {
      "schdeulde_name": name,
      "is_enabled": true,
      "start_date": midnightStartDate,
      "end_date": midnightEndDate,
      "appointment_type": "General",
      "frequency_type": frequencyDictionary[frequency],
      "timeslots": formattedTimeSlotsList,
      "frequency_interval": freqInternval,
      "duration": durationInSeconds,
      "owner": currentHospitalData.id,

    }
    console.log(updatedScheduleData)
    EditSchedule(updatedScheduleData, scheduleData.id, currentHospitalData.external_id)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          console.log(scheduleList)
          const tempScheduleList = [...scheduleList]
          console.log(replaceObjectByExternalId(tempScheduleList, response.data))
          setScheduleList(replaceObjectByExternalId(tempScheduleList, response.data))
          setModalOpen(false)
          toggleAlertWithMessage("Successfully updated schedule")
        }
      })
      .catch(err => {
        console.log(err)
        setModalOpen(false)
        toggleAlertWithMessage("An error has occurred while updating your schedule.")
      })
  }

  const updateAppointmentDictionary = (ts) => {
    const tempTimeSlots = ts ? ts : timeSlotsList
    var tempConfig = null
    if (frequency === "Once" && timeSlots != null) {
      tempConfig = {
        frequency: "Once",
        time: timeSlots,
        date: startDate,
      }
    } else if (frequency === "Daily" && timeSlots != null && tempTimeSlots.length > 0) {
      tempConfig = {
        frequency: "Daily",
        time: tempTimeSlots,
        startDate: startDate,
        endDate: endDate,
        daysOfWeeks: daysSchedule
      }
      console.log(tempConfig)
    } else if (frequency === "Weekly" && timeSlots != null && tempTimeSlots.length > 0 && dayOfWeek != null) {
      tempConfig = {
        frequency: "Weekly",
        time: tempTimeSlots,
        day: dayOfWeek,
        startDate: startDate,
        endDate: endDate,
      }
    }
    setSchedulePreviewConfig(tempConfig)
  }

  const updateAppointmentDictionaryWithCustomTimeSlot = (ts, timeSlots) => {
    const tempTimeSlots = ts ? ts : timeSlotsList
    var tempConfig = null
    if (frequency === "Once" && timeSlots != null) {
      tempConfig = {
        frequency: "Once",
        time: timeSlots,
        date: startDate,
      }
    } else if (frequency === "Daily" && timeSlots != null && tempTimeSlots.length > 0) {
      tempConfig = {
        frequency: "Daily",
        time: tempTimeSlots,
        startDate: startDate,
        endDate: endDate,
        daysOfWeeks: daysSchedule,
      }
      console.log(tempConfig)
    } else if (frequency === "Weekly" && timeSlots != null && tempTimeSlots.length > 0 && dayOfWeek != null) {
      tempConfig = {
        frequency: "Weekly",
        time: tempTimeSlots,
        day: dayOfWeek
      }
    }
    setSchedulePreviewConfig(tempConfig)
  }

  const handleTimeSlotList = (selectedTimeSlot) => {

    if (frequency === "Once") {
      const tempTimeSlotList = [selectedTimeSlot]
      setTimeSlotsList(tempTimeSlotList);
      updateAppointmentDictionary(tempTimeSlotList);
      setIsTimeOpen(false)
      return
    }

    if (timeSlotsList.includes(selectedTimeSlot)) {
      handleDeleteTimeSlot(selectedTimeSlot);
    } else {
      handleAddTimeSlots(selectedTimeSlot);
    }
  };

  const handleAddTimeSlots = (selectedTimeSlot) => {
    const tempTimeSlots = [...timeSlotsList];
    tempTimeSlots.push(selectedTimeSlot);
    setTimeSlotsList(tempTimeSlots);
    updateAppointmentDictionary(tempTimeSlots);
  };

  const handleDeleteTimeSlot = (time) => {
    const tempTimeSlots = [...timeSlotsList];
    const index = tempTimeSlots.indexOf(time);

    if (index !== -1) {
      tempTimeSlots.splice(index, 1);
    }

    setTimeSlotsList(tempTimeSlots);
    updateAppointmentDictionary(tempTimeSlots);
  };

  const renderTimeSlot = (time) => {
    return (
      <div className="create-schedule-popup-time-slot">
        <h1>{time}</h1>
      </div>
    );
  };

  const renderTimeSlotDropdownItem = (selectedTimeSlot) => {
    console.log(selectedTimeSlot)
    const isSlotSelected = timeSlotsList.includes(selectedTimeSlot);
    return (
      <div
        className={`confirmation-timeslot withCheck ${isSlotSelected && "selected-confirmation-slot"
          }`}
        onClick={() => {
          handleTimeSlotList(selectedTimeSlot);
        }}
      >
        <h3>{selectedTimeSlot}</h3>
        {isSlotSelected && <i className="fa fa-check"></i>}
      </div>
    );
  };

  const renderEditorOnce = () => {
    return (
      <>
        <span
          className="d-flex justify-content-between"
          style={{ marginTop: "40px" }}
        >
          <div className="email-input-wrapper">
            <h3>Time Slots</h3>
            <h4>Add time slots to this schedule.</h4>

            <div
              ref={timeDropdownRef}
              className="position-relative booker-reason-input-container create-schedule-popup-padding"
            >
              <div
                className="confirmation-modal-date-dropdown"
                onClick={() => setIsTimeOpen(!isTimeOpen)}
              >
                <i className="far fa-clock"></i>
                <div className="d-flex flex-column">
                  <h4>Time</h4>
                  <h5>
                    {timeSlotsList && timeSlotsList.length === 1
                      ? timeSlotsList[0]
                      : "Choose time slot"}
                  </h5>
                </div>
              </div>
              {isTimeOpen && (
                <div className="confirmation-time-dropdown-menu ">
                  {halfHourTimeIntervals.map((elem) =>
                    renderTimeSlotDropdownItem(elem)
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="email-input-wrapper">
            <h3>Start Date</h3>
            <h4>
              Choose a date that your appointment slots series will start on.
            </h4>
            <div
              ref={startDateDropdownRef}
              className="booker-reason-input-container create-schedule-popup-padding"
              style={{ marginTop: "-10px" }}
            >
              <div
                className="confirmation-modal-date-dropdown"
                onClick={() =>
                  setStartDateDropdownOpen(!isStartDateDropdownOpen)
                }
              >
                <i className="far fa-calendar"></i>
                <div className="d-flex flex-column">
                  <h4>Date</h4>
                  <h5>
                    {formatDateToLongString(startDate) != null
                      ? formatDateToLongString(startDate)
                      : "Choose how frequently your slots are open"}
                  </h5>
                </div>
              </div>
              {isStartDateDropdownOpen && (
                <ul className="create-schedule-dropdown-menu">
                  <MiniCalendar
                    useExactDate={true}
                    displayedDate={startDate}
                    setDisplayedDate={setStartDate}
                    increaseMonth={increaseMonth}
                    decreaseMonth={decreaseMonth}
                    setPopupOpen={() => setStartDateDropdownOpen(false)}
                  />
                </ul>
              )}
            </div>
          </div>
        </span>
        <button
          className="pe-left-disable-btn"
          onClick={() => handleDisableSchedule()}
        >
          {isSaving ? <LoadingSpinner /> : "Disable Schedule"}
        </button>
        <button
          className="pe-left-save-btn"
          onClick={() => setConfirmationModalOpen(true)}
        >
          {isSaving ? <LoadingSpinner /> : "Continue"}
        </button>
      </>
    );
  };

  const renderEditorDaily = () => {
    return (
      <>
        <h3>Time Slots</h3>
        <h4>Add time slots to this schedule.</h4>

        <div ref={timeDropdownRef} className="position-relative">
          <div
            className="confirmation-modal-date-dropdown"
            onClick={() => setIsTimeOpen(!isTimeOpen)}
          >
            <i className="far fa-clock"></i>
            <div className="d-flex flex-column">
              <h4>Time</h4>
              <h5>
                {timeSlotsList && timeSlotsList.length > 0
                  ? `(${timeSlotsList.length} time slots)`
                  : "Choose time slots"}
              </h5>
            </div>
          </div>
          {isTimeOpen && (
            <div className="confirmation-time-dropdown-menu ">
              {halfHourTimeIntervals.map((elem) =>
                renderTimeSlotDropdownItem(elem)
              )}
            </div>
          )}
        </div>

        {/* {timeSlotsList.length > 0 && (
              <div className="create-schedule-popup-time-slots-list">
                {timeSlotsList.map((time) => renderTimeSlot(time))}
              </div>
            )} */}

        <span
          className="d-flex flex-col justify-content-between"
          style={{ marginTop: "40px" }}
        >
          <div className="email-input-wrapper">
            <h3>Start Date</h3>
            <h4>
              Choose a date that your appointment slots series will start on.
            </h4>
            <div
              ref={startDateDropdownRef}
              className="booker-reason-input-container create-schedule-popup-padding"
              style={{ marginTop: "-10px" }}
            >
              <div
                className="confirmation-modal-date-dropdown"
                onClick={() =>
                  setStartDateDropdownOpen(!isStartDateDropdownOpen)
                }
              >
                <i className="far fa-calendar"></i>
                <div className="d-flex flex-column">
                  <h4>Date</h4>
                  <h5>
                    {formatDateToLongString(startDate) != null
                      ? formatDateToLongString(startDate)
                      : "Choose how frequently your slots are open"}
                  </h5>
                </div>
              </div>
              {isStartDateDropdownOpen && (
                <ul className="create-schedule-dropdown-menu">
                  <MiniCalendar
                    useExactDate={true}
                    displayedDate={startDate}
                    setDisplayedDate={setStartDate}
                    increaseMonth={increaseMonth}
                    decreaseMonth={decreaseMonth}
                    setPopupOpen={() => setStartDateDropdownOpen(false)}
                  />
                </ul>
              )}
            </div>
          </div>
          <div className="email-input-wrapper">
            <h3>End Date</h3>
            <h4>
              Choose a date that your appointment slots series will end on.
            </h4>
            <div
              className="booker-reason-input-container create-schedule-popup-padding"
              ref={endDateDropdownRef}
              style={{ marginTop: "-10px" }}
            >
              <div
                className="confirmation-modal-date-dropdown"
                onClick={() => setEndDateDropdownOpen(!isEndDateDropdownOpen)}
              >
                <i className="far fa-calendar"></i>
                <div className="d-flex flex-column">
                  <h4>Date</h4>
                  <h5>
                    {formatDateToLongString(endDate) != null
                      ? formatDateToLongString(endDate)
                      : "Choose how frequently your slots are open"}
                  </h5>
                </div>
              </div>
              {isEndDateDropdownOpen && (
                <ul className="create-schedule-dropdown-menu">
                  <MiniCalendar
                    useExactDate={true}
                    displayedDate={endDate}
                    setDisplayedDate={setEndDate}
                    increaseMonth={increaseMonth}
                    decreaseMonth={decreaseMonth}
                    setPopupOpen={() => setEndDateDropdownOpen(false)}
                  />
                </ul>
              )}
            </div>
          </div>
        </span>

        <h3 style={{ marginTop: "30px" }}>Days of Week</h3>
        <h4>Exclude days when your clinic is closed.</h4>
        <div className="create-schedule-popup-daysofweek-list">
          {daysOfWeek.map((day) => (
            <div key={day} className="create-schedule-popup-daysofweek">
              <input
                type="checkbox"
                checked={daysSchedule[day.toLowerCase()]}
                onChange={() => toggleDay(day.toLowerCase())}
              />
              <h4>{day}</h4>
            </div>
          ))}
        </div>
        <button
          className="pe-left-disable-btn"
          onClick={() => handleDisableSchedule()}
        >
          {isSaving ? <LoadingSpinner /> : "Disable Schedule"}
        </button>
        <button
          className="pe-left-save-btn"
          onClick={() => setConfirmationModalOpen(true)}
        >
          {isSaving ? <LoadingSpinner /> : "Continue"}
        </button>
      </>
    );
  };

  const renderEditorWeekly = () => {
    return (
      <>
        <h3>Day of Week</h3>
        <h4>Add time slots to this schedule.</h4>
        <div
          className="booker-reason-input-container create-schedule-popup-padding"
          ref={dayDropdownRef}
          style={{ marginTop: "-10px" }}
        >
          <div
            className="booker-body-dropdown"
            onClick={() => setDayDropdownOpen(!isDayDropdownOpen)}
          >
            <h5>
              {dayOfWeek != null
                ? daysOfWeek[dayOfWeek]
                : "Choose how frequently your slots are open"}
            </h5>
            <img alt="chevron down" src={chevron_down} />
          </div>
          {isDayDropdownOpen && (
            <ul className="booker-dropdown-menu">
              {daysOfWeek.map((option, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setDayOfWeek(daysOfWeek.indexOf(option));
                    setDayDropdownOpen(false);
                  }}
                >
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>

        <h3>Time Slots</h3>
        <h4>Add time slots to this schedule.</h4>

        <div ref={timeDropdownRef} className="position-relative">
          <div
            className="confirmation-modal-date-dropdown"
            onClick={() => setIsTimeOpen(!isTimeOpen)}
          >
            <i className="far fa-clock"></i>
            <div className="d-flex flex-column">
              <h4>Time</h4>
              <h5>
                {timeSlotsList && timeSlotsList.length > 0
                  ? `(${timeSlotsList.length} time slots)`
                  : "Choose time slots"}
              </h5>
            </div>
          </div>
          {isTimeOpen && (
            <div className="confirmation-time-dropdown-menu ">
              {halfHourTimeIntervals.map((elem) =>
                renderTimeSlotDropdownItem(elem)
              )}
            </div>
          )}
        </div>

        {/* {timeSlotsList.length > 0 && (
              <div className="create-schedule-popup-time-slots-list">
                {timeSlotsList.map((time) => renderTimeSlot(time))}
              </div>
            )} */}

        <span
          className="d-flex flex-col justify-content-between"
          style={{ marginTop: "40px" }}
        >
          <div className="email-input-wrapper">
            <h3>Start Date</h3>
            <h4>
              Choose a date that your appointment slots series will start on.
            </h4>
            <div
              ref={startDateDropdownRef}
              className="booker-reason-input-container create-schedule-popup-padding"
              style={{ marginTop: "-10px" }}
            >
              <div
                className="confirmation-modal-date-dropdown"
                onClick={() =>
                  setStartDateDropdownOpen(!isStartDateDropdownOpen)
                }
              >
                <i className="far fa-calendar"></i>
                <div className="d-flex flex-column">
                  <h4>Date</h4>
                  <h5>
                    {formatDateToLongString(startDate) != null
                      ? formatDateToLongString(startDate)
                      : "Choose how frequently your slots are open"}
                  </h5>
                </div>
              </div>
              {isStartDateDropdownOpen && (
                <ul className="create-schedule-dropdown-menu">
                  <MiniCalendar
                    useExactDate={true}
                    displayedDate={startDate}
                    setDisplayedDate={setStartDate}
                    increaseMonth={increaseMonth}
                    decreaseMonth={decreaseMonth}
                    setPopupOpen={() => setStartDateDropdownOpen(false)}
                  />
                </ul>
              )}
            </div>
          </div>
          <div className="email-input-wrapper">
            <h3>End Date</h3>
            <h4>
              Choose a date that your appointment slots series will end on.
            </h4>
            <div
              className="booker-reason-input-container create-schedule-popup-padding"
              ref={endDateDropdownRef}
              style={{ marginTop: "-10px" }}
            >
              <div
                className="confirmation-modal-date-dropdown"
                onClick={() => setEndDateDropdownOpen(!isEndDateDropdownOpen)}
              >
                <i className="far fa-calendar"></i>
                <div className="d-flex flex-column">
                  <h4>Date</h4>
                  <h5>
                    {formatDateToLongString(endDate) != null
                      ? formatDateToLongString(endDate)
                      : "Choose how frequently your slots are open"}
                  </h5>
                </div>
              </div>
              {isEndDateDropdownOpen && (
                <ul className="create-schedule-dropdown-menu">
                  <MiniCalendar
                    useExactDate={true}
                    displayedDate={endDate}
                    setDisplayedDate={setEndDate}
                    increaseMonth={increaseMonth}
                    decreaseMonth={decreaseMonth}
                    setPopupOpen={() => setEndDateDropdownOpen(false)}
                  />
                </ul>
              )}
            </div>
          </div>
        </span>
        {
          !scheduleData.is_enabled ?
            <button
              className="pe-left-enable-btn"
              onClick={() => handleDisableSchedule(true)}
            >
              {isSaving ? <LoadingSpinner /> : "Enable Schedule"}
            </button>
            :
            <button
              className="pe-left-disable-btn"
              onClick={() => handleDisableSchedule(false)}
            >
              {isSaving ? <LoadingSpinner /> : "Disable Schedule"}
            </button>
        }
        <button
          className="pe-left-save-btn"
          onClick={() => setConfirmationModalOpen(true)}
        >
          {isSaving ? <LoadingSpinner /> : "Continue"}
        </button>
      </>
    );
  };

  const renderConfirmationModal = () => {
    return (
      <div className="confirmation-modal-overlay">
        <div className="confirmation-modal">
          <div className='confirmation-modal-title'>Are you sure that you want to change this schedule?</div>
          <div className="confirmation-modal-buttons">
            <button className="confirmation-modal-button cancel" onClick={() => setConfirmationModalOpen(false)}>
              Cancel
            </button>
            <button className="confirmation-modal-button accept" onClick={() => submitSchedule()}>
              Confirm
            </button>

          </div>
        </div>
      </div>
    )
  }

  if (!currentHospitalData || !scheduleData || !doctorList) {
    return null
  }

  return (
    <div className='edit-schedule-overlay'>
      {confirmationModalOpen && renderConfirmationModal()}
      <div className='create-calendar-left'>
        <button className='create-schedule-back-button' onClick={() => setModalOpen(false)}>
          <i class="fas fa-arrow-left back-arrow"></i>
          <h1>Back to Calendar</h1>
        </button>
        <div className="create-schedule-popup">

          <div className='create-schedule-popup-header'>
            <h1>Edit Schedule</h1>
            <h2>To open time slots for online appointments, please fill out the following information.</h2>
          </div>
          <div className='create-schedule-popup-body'>
            <h3>Name</h3>
            <h4>Give a name to identify your schedule.</h4>
            <input
              className="email-input create-schedule-popup-padding"
              name="email"
              value={name}
              placeholder="Ex. Normal Weekday Schedule"
              onChange={(e) => setName(e.currentTarget.value)}
            />
            <h3>Duration</h3>
            <h4>Approximately, how long will each appointment take in minutes?</h4>
            <input
              className="email-input create-schedule-popup-padding"
              value={duration}
              placeholder="Enter a time length in minutes"
              onChange={handleDurationChanged}
            />
            <h3>Slot Frequency</h3>
            <h4>How often should your appointment slots repeat?</h4>
            <div
              className="booker-reason-input-container create-schedule-popup-padding"
              ref={frequencyDropdownRef}
              style={{ marginTop: '-10px' }}
            >
              <div className="booker-body-dropdown" onClick={() => setFrequencyDropdownOpen(!isFrequencyDropdownOpen)}>
                <h5>
                  {frequency != null
                    ? frequency
                    : "Choose how frequently your slots are open"}
                </h5>
                <img alt="chevron down" src={chevron_down} />
              </div>
              {isFrequencyDropdownOpen && (
                <ul className="booker-dropdown-menu">
                  {frequencyList.map((option, index) => (
                    <li key={index} onClick={() => { setFrequency(option); setFrequencyDropdownOpen(false); setSchedulePreviewConfig(null) }}>
                      {option}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {frequency === "Once" && renderEditorOnce()}
            {frequency === "Daily" && renderEditorDaily()}
            {frequency === "Weekly" && renderEditorWeekly()}


          </div>

        </div>
      </div>

      <div className='create-schedule-calendar'>
        <div className="calendar-page-header">
          <div className="d-flex align-items-center">

            <div className="d-flex flex-col align-items-center">
              <h2 className="calendar-date-txt d-flex">
                {monthDictionary[displayedDate.getMonth()]}{" "}
                {displayedDate.getFullYear()}{" "}
              </h2>
              <button
                class="calendar-chevron-button-left"
                onClick={() => decreaseMonthCalendar()}
              >
                <i class="fas fa-chevron-left"></i>
              </button>
              <button
                class="calendar-chevron-button-right"
                onClick={() => increaseMonthCalendar()}
              >
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>

        </div>
        <Calendar
          year={displayedDate.getFullYear()}
          month={displayedDate.getMonth()}
          setPopupOpen={null}
          appointmentDataDictionary={{}}
          setPopupModalData={null}
          schedulePreviewConfig={schedulePreviewConfig}
        />
      </div>
    </div>

  );
};

export default EditSchedulePopup;