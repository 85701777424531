import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  useStripe,
  useElements,
  PaymentElement,
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { requestPaymentIntent } from '../../../../api/patientAPIs';
import { achew_logo_side } from "../../home/image";

const Payment = () => {
  // Publishable Key 
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const [clientSecret, setClientSecret] = useState("");
  const { state: { client_key, amount, accountData, currentHospitalID} = {} } = useLocation();
  var balance = (accountData.credits / 100).toFixed(2);
  var order_amount = (amount / 100).toFixed(2);
  const total = (parseFloat(balance) + parseFloat(order_amount)).toFixed(2)
  const full_name = accountData.first_name + " " + accountData.last_name;


  const options = {
    // passing the client secret obtained from the server
    clientSecret: client_key
  };

  const CheckoutForm = () => {
    const stripe = useStripe();
    
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      console.log(currentHospitalID)
      if (!stripe) {
        return;
      }

      if (!clientSecret) {
        return;
      }

      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
    }, [stripe]);

    const handleSubmit = async (e) => {
      e.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setIsLoading(true);
      stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${process.env.REACT_APP_BASE_URL}/plan?hospital=${currentHospitalID}&success_redirect=${amount}`,
        },
        redirect:"if_required"
      }).then(result => {
        if(result.error){
          setMessage(result.error);
        } else {
          console.log({
            balance: balance,
            order_amount: order_amount,
            total: total,
            currentHospitalID:currentHospitalID,
           })
          navigate(`/payment-success`,
           { state:{
            balance: balance,
            order_amount: order_amount,
            total: total,
            currentHospitalID:currentHospitalID,
           }
           })
        }
        setIsLoading(false);
      })
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      
      
      
    };

    const paymentElementOptions = {
      layout: "tabs"
    }

    return (
      <form id="payment-form" onSubmit={handleSubmit} className="payment-element">

        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button disabled={isLoading || !stripe || !elements} id="submit" className="pay-checkout-button">
          <span id="button-text">
            {isLoading ? <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div> : "Pay Now"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div className="payment-message text-danger" id="payment-message">{message}</div>}
      </form>
    );
  }
  const navigate = useNavigate()
  
  return (
    <div className="payment-container">
      <div className="payment-header">
        <div className="payment-back-navigate" onClick={() => navigate(`/plan?hospital=${currentHospitalID}`)}>
          <i class="fas fa-chevron-left"></i>
          <img src={achew_logo_side} alt="logo" className='achew-header-logo'/>
        </div>
        <h1>Order Summary</h1>
        <h6>${order_amount}</h6>
        <div className="payment-subtotal-container">
          <div className="d-flex justify-content-between payment-subtotal-subcontainer">
            <h2>Add Funds to Account</h2>
            <h3>${order_amount}</h3>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Total due</h4>
            <h5>${order_amount}</h5>
          </div>
        </div>
      
        <div className="payment-box">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <h1>
                New balance after payment
              </h1>
              
            </div>
            <h3>${total}</h3>
          </div>
        </div>
      </div>
      <div className="payment-right">
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      </div>
    </div>
  );
};

export default Payment;
