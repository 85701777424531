import React, { useState, useRef, useEffect } from 'react'
import calendarSampleData from '../../../../sampledata/sample-calendar.json'
import { Divider } from '@mui/material';
import AptModal from './apt-modal';
import PatientModal from './patient-modals/patient-modal'
import DoctorHeader from '../doctorheader'
import DoctorSideBar from './dashboardsidebar'
import { getAccountInfo, getAppointmentByID, getHospitalInfoByInternalID, getAppointments, testapi, GetDoctor, completeAppointment, declineAppointment, getSchedules } from '../../../../api/patientAPIs';
import { refreshToken } from '../../../../api/auth';
import { useNavigate, useLocation, json } from 'react-router-dom';
import { setAccountInfo } from '../../../../hooks/accountSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../loading';
import {
  getStartOfWeek,
  getDaysOfWeek,
  findObjectById,
  placeAppointmentsInCalendar,
  isDateBetween,
  printTimeAMPM,
  convertTo12HourFormat,
  isSlotExpired
} from '../../../assets/functions/functions';
import CalendarPage from './prototype-calendar';
import CreateSchedulePopup from './create-schedule-popup';
import EditSchedulePopup from './edit-schedule-popup';
import CreditWarningPopup from './credit-warning-popup';

const Calendar = (props) => {
  const [isViewTypeDropdownOpen, setViewTypeDropdownOpen] = useState(false);
  const viewTypeDropdownRef = useRef(null);
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const filterDropdownRef = useRef(null);

  const [dateRange, setDateRange] = useState(getDaysOfWeek(getStartOfWeek(new Date())))
  const [appointmentDataList, setAppointmentDataList] = useState([])
  const [originalAppointmentDataList, setOriginalAppointmentDataList] = useState(null)
  const [doctorList, setDoctorList] = useState([])

  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(location.search);
  const currentHospitalID = searchParams.get('hospital')
  const [hospitalData, setHospitalData] = useState(null)
  const [currentHospitalData, setCurrentHospitalData] = useState({ hospital_name: "" })
  const [isPageLoading, setPageLoading] = useState(true)
  const [isCreateScheduleOpen, setCreateScheduleOpen] = useState(false)
  const [isEditScheduleOpen, setEditScheduleOpen] = useState(false)
  const [currentSchedule, setCurrentSchedule] = useState(null)
  const [scheduleList, setScheduleList] = useState(null)
  const [accountData, setAccountData] = useState({})
  const [creditWarningMessageOpen, setCreditWarningMessageOpen] = useState(false)

  useEffect(() => {
    getAccountInfo().then(res => {
      if (res.status == 200) {
        dispatch(setAccountInfo(res.data))
        setAccountData(res)
        const hospitalList = res.hospital

        var promises = []
        hospitalList.forEach(hID => {
          promises.push(getHospitalInfoByInternalID(hID))
        });

        Promise.all(promises).then(results => {
          const tempHospitalList = []
          results.forEach(res => tempHospitalList.push(res.data))
          setHospitalData(tempHospitalList)
          const tempHospitalData = findObjectById(currentHospitalID, tempHospitalList)
          setCurrentHospitalData(tempHospitalData)
          
          
          
          var doctorPromises = [];
          tempHospitalData.doctors.forEach((doc) => {
            doctorPromises.push(GetDoctor(doc))
          })

          Promise.all(doctorPromises).then((docResults) => {
            const tempDoctorList = [];
            docResults.forEach((docResults) => tempDoctorList.push(docResults.data));
            setDoctorList(tempDoctorList);
            
          })

          const initialStartTime = (new Date()).getTime() / 1000
          getAppointments(currentHospitalID, initialStartTime).then(results => {
            const inScreenAppointmentList = []
            const tempAppointmentDataList = []
            if (results.status === 200) {

              var completePromises = []
              results.data.results.forEach(apt => {
                if (apt.status === "CONFIRMED" && isSlotExpired(apt.slot_date)) {
                  completePromises.push(completeAppointment(apt.id, "Appointment Completed", currentHospitalID))
                } else if (apt.status === "PENDING" && isSlotExpired(apt.slot_date)) {
                  completePromises.push(declineAppointment(apt.id, "The provider didn't accept your appointment in time", "Appointment Completed", currentHospitalID))
                }
              })
              Promise.all(completePromises).then(response => {

              })

              setOriginalAppointmentDataList(results.data.results)
              results.data.results.forEach(res => {
                console.log("Appointment", res)
                tempAppointmentDataList.push(res.data)
                if (isDateBetween(res.date, dateRange[0], dateRange[6])) {
                  inScreenAppointmentList.push(res)
                  console.log(res.date)
                }
              })
            }

            setAppointmentDataList(tempAppointmentDataList)
            setPageLoading(false)
          })
        })
      }

      if (res.status == 401 || res.status == 403) {
        refreshToken().then(response => {
          if (response.status == 200) {
            console.log("Successfully refreshed token")
            window.location.reload()
          } else {
            navigate('/doctor-login')
          }
        })
      }

    })

    const handleClickOutsideViewType = (event) => {
      if (viewTypeDropdownRef.current && !viewTypeDropdownRef.current.contains(event.target)) {
        setViewTypeDropdownOpen(false);
      }
    };
    const handleClickOutsideFilter = (event) => {
      if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target)) {
        setFilterModalOpen(false);
      }
    };


    window.addEventListener('click', handleClickOutsideViewType);
    window.addEventListener('click', handleClickOutsideFilter);

    return () => {
      window.removeEventListener('click', handleClickOutsideViewType);
      window.removeEventListener('click', handleClickOutsideFilter);
    };
  }, []);


  if (isPageLoading) {
    return (<Loading />)
  }

  const handleSetEditModalOpen = (isOpen, schedule = []) => {
    setEditScheduleOpen(isOpen)
    if(isOpen){
      setCurrentSchedule(schedule)
    }
  }

  return (
    <div className='calendar-grey-supercontainer'>
      <CreditWarningPopup isOpen={creditWarningMessageOpen} onClose={()=>setCreditWarningMessageOpen(false)}/>
      <DoctorHeader hospitalData={hospitalData} currentHospitalData={currentHospitalData} />
      <DoctorSideBar currentTab={1} hospitalData={hospitalData} currentHospitalData={currentHospitalData} />
      <div className='calendar-container'>
        {isCreateScheduleOpen && <CreateSchedulePopup toggleAlertWithMessage={() => {}} scheduleList={scheduleList} setScheduleList={setScheduleList} currentHospitalData={currentHospitalData} setCreateScheduleOpen={setCreateScheduleOpen} doctorList={doctorList} />}
        {isEditScheduleOpen && 
          <EditSchedulePopup 
            doctorList={doctorList} 
            setModalOpen={setEditScheduleOpen} 
            currentHospitalData={currentHospitalData} 
            toggleAlertWithMessage={() => {}}  
            scheduleData={currentSchedule} 
            setScheduleList={setScheduleList}
            scheduleList={scheduleList}
          />
          }
        {!isEditScheduleOpen && !isCreateScheduleOpen && <CalendarPage scheduleList={scheduleList} setScheduleList={setScheduleList} accountData={accountData} setCreditWarningMessageOpen={setCreditWarningMessageOpen} currentHospitalData={currentHospitalData} doctorList={doctorList} appointmentList={originalAppointmentDataList} setCreateScheduleOpen={setCreateScheduleOpen} setEditModalOpen={handleSetEditModalOpen} />}
      </div>
    </div>
  )
}

export default Calendar