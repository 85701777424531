import DoctorHeader from "../doctorheader";
import DoctorSideBar from "./dashboardsidebar";
import React, { useState, useEffect, useRef } from "react";
import AWS from "aws-sdk";
import specialtiesData from "../../../../sampledata/specialties.json";
import {
  getAccountInfo,
  getHospitalInfoByInternalID,
  EditClinic,
} from "../../../../api/patientAPIs";
import Loading from "../../loading";
import ReactToggle from "react-toggle";
import "react-toggle/style.css";
import { refreshToken } from "../../../../api/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAccountInfo } from "../../../../hooks/accountSlice";
import {
  areArraysEqual,
  areInsuranceArrayEqual,
  findObjectById,
  getInsuranceById,
  groupByInsuranceID,
  isInsuranceAlreadyPresent,
  usStatesAbbreviations,
} from "../../../assets/functions/functions";

import {
  add_image,
  chevron_down,
  placeholder_image,
} from "../../home/image";
import LoadingSpinner from "../../patients/bookingv2/spinner";
import InsurancePicker from "../../patients/bookingv2/insurance-dropdown-picker";
import InsuranceElement from "../../patients/doctorprofile/insurance-element";
import PopupAlert from "./popup-alert";
import { Loader } from "@googlemaps/js-api-loader";
import insuranceSampleData from "../../../../sampledata/insurance.json";
import InsuranceAccordian from "./patient-modals/insurance-accordian";
const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const currentHospitalID = searchParams.get("hospital");
  const [hospitalData, setHospitalData] = useState(null);
  const [currentHospitalData, setCurrentHospitalData] = useState({
    hospital_name: "",
    services: [],
  });
  const [currentAccountInfo, setCurrentAccountInfo] = useState({});
  const [isPageLoading, setPageLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  //Geocoder
  const [geocoder, setGeocoder] = useState(null);

  /* Practice Info */

  const [practiceName, setPracticeName] = useState(null);
  const [practiceEmail, setPracticeEmail] = useState(null);
  const [practiceSpecialty, setPracticeSpecialty] = useState(null);
  const specialtyDropdownRef = useRef(null);
  const [isSpecialtyDropdownOpen, setSpecialtyDropdownOpen] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [deletedPictures, setDeletedPictures] = useState([]);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [profilePicture, setProfilePicture] = useState("");
  const [practiceWebsite, setPracticeWebsite] = useState(null)
  const [about, setAbout] = useState(null)

  /* Location */
  const stateDropdownRef = useRef(null);
  const [isStateDropdownOpen, setStateDropdownOpen] = useState(false);
  const [zip, setZip] = useState(null);
  const [address, setAddress] = useState(null);
  const [apt, setApt] = useState(null);
  const [city, setCity] = useState(null);
  const [statee, setStatee] = useState(null);
  const [practicePhone, setPracticePhone] = useState(null);

  /* Insurance */

  const [insuranceList, setInsuranceList] = useState([]);
  const [insuranceID, setInsuranceID] = useState(null);
  const [planID, setPlanID] = useState(null);
  const [didChangeInsuranceList, setDidChangeInsuranceList] = useState(false);
  const [categorizedInsurances, setCategorizedInsurances] = useState([]);
  const [newInsuranceToAdd, setNewInsuranceToAdd] = useState([]);

  /* Account Information */

  const [accountEmail, setAccountEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);

  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);

  // S3 Bucket Name
  const S3_BUCKET = process.env.REACT_APP_S3_BUCKETNAME;

  // S3 Region
  const REGION = process.env.REACT_APP_S3_REGION;

  // S3 Credentials
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_S3_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_S3_SECRETACCESSKEY,
  });

  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const handlePracticePhoneChange = (e) => {
    // Remove non-numeric characters and limit to 10 digits
    const cleanedInput = e.target.value.replace(/\D/g, "").substring(0, 10);

    // Format the phone number
    let formattedPhoneNumber = "";

    if (cleanedInput.length >= 1) {
      formattedPhoneNumber = `(${cleanedInput.substring(0, 3)}`;

      if (cleanedInput.length >= 4) {
        formattedPhoneNumber += `) ${cleanedInput.substring(3, 6)}`;

        if (cleanedInput.length >= 7) {
          formattedPhoneNumber += `-${cleanedInput.substring(6, 10)}`;
        }
      }
    }

    setPracticePhone(formattedPhoneNumber);
  };

  const toggleAlertWithMessage = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
  };

  const handleSelectAllInsurancePlansChosen = (insuranceID) => {
    getInsuranceById(insuranceID, insuranceSampleData).plans.forEach((plan) => {
      addInsurance(insuranceID, plan.id);
    });
  };

  const handleInsuranceChosen = (insuranceID, planID) => {
    var isSelected = false
    insuranceList.forEach(insurance => {
      if (insurance.planID === planID) {

        isSelected = true
      }
    })
    if (isSelected) {
      removeInsurance(insuranceID, planID)
    } else {
      addInsurance(insuranceID, planID)
    }
  }

  const addInsurance = (insuranceID, planID) => {
    if (insuranceList.length >= 1000) {
      toggleAlertWithMessage(
        "You have reached the limit of the number of insurance plans you can register"
      );
      return;
    }
    if (isInsuranceAlreadyPresent(insuranceList, insuranceID, planID)) {
      return;
    }
    const tempInsurances = [...insuranceList];
    const tempNewInsuranceList = [...newInsuranceToAdd];
    tempInsurances.push({
      insuranceID: insuranceID,
      planID: planID,
    });
    tempNewInsuranceList.push({
      insuranceID: insuranceID,
      planID: planID,
    });
    setInsuranceList(tempInsurances);
    setNewInsuranceToAdd(tempNewInsuranceList);
    setDidChangeInsuranceList(true);
  };

  const removeInsurance = (insuranceID, planID) => {
    const tempInsurances = insuranceList.filter(
      (item) => !(item.insuranceID === insuranceID && item.planID === planID)
    );
    setInsuranceList(tempInsurances);

    const tempNewInsurances = newInsuranceToAdd.filter(
      (item) => !(item.insuranceID === insuranceID && item.planID === planID)
    );
    setNewInsuranceToAdd(tempNewInsurances);
    setDidChangeInsuranceList(true);
  };

  const handleFileChange = (event) => {

    const selectedFile = event.target.files[0];
    const MAX_FILE_SIZE = 1024 * 1024 * 30
    if (selectedFile.size > MAX_FILE_SIZE) {
      toggleAlertWithMessage("Please upload files less than 20 MB")
      return
    }
    const isDuplicate = pictures.some((file) => file === selectedFile);
    if (!isDuplicate) {
      setPictures([...pictures, selectedFile]);
    } else {
      setAlertMessage("Can't upload same file");
      setShowAlert(true);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleProfileFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setImageToUpload(selectedFile);
    }
  };

  const handleDeleteImage = (index) => {
    // Create a copy of the pictures array
    const updatedPictures = [...pictures];
    const deletedImage = updatedPictures[index];
    if (typeof deletedImage === "string") {
      // If it's an AWS URL link, add it to the deletedPictures array
      setDeletedPictures([...deletedPictures, deletedImage]);
    }
    // Remove the image at the specified index
    updatedPictures.splice(index, 1);
    // Update the state with the new array
    setPictures(updatedPictures);
  };

  const uploadImagesToS3 = async () => {
    const updatedPictures = await Promise.all(
      pictures.map(async (image) => {
        if (image instanceof File) {
          const timestamp = new Date().getTime();
          const fileName = `${timestamp}_${image.name}`;
          const objectKey = `hospitals/${currentHospitalData.external_id}/pictures/${fileName}`;
          const params = {
            Bucket: S3_BUCKET,
            Key: objectKey,
            Body: image,
          };

          try {
            const data = await s3.upload(params).promise();
            return data.Location;
          } catch (err) {
            setAlertMessage("Error uploading file to S3:");
            setShowAlert(true);
            return null; // Handle the error case
          }
        } else {
          return image; // If not a File, return the existing URL
        }
      })
    );
    return updatedPictures;
  };

  const deleteFromS3 = async () => {
    for (let i = 0; i < deletedPictures.length; i++) {
      const urlLink = deletedPictures[i];
      const urlObject = new URL(urlLink);

      const pathname = urlObject.pathname;
      const decodedPathname = decodeURIComponent(pathname);
      const picKey = decodedPathname.slice(1);

      const deleteParams = {
        Bucket: S3_BUCKET,
        Key: picKey,
      };

      try {
        await s3.deleteObject(deleteParams).promise();
        console.log("Successfully deleted object:", picKey);
      } catch (err) {
        console.error("Error deleting object:", err);
        alert("Error");
      }
    }
  };

  const uploadProfilePic = async (file) => {
    const MAX_FILE_SIZE = 1024 * 1024 * 30
    try {
      const fileName = file.name;
      if (file.size > MAX_FILE_SIZE) {
        toggleAlertWithMessage("Please upload files less than 20 MB")
        return
      }
      const objectKey = `hospitals/${currentHospitalData.external_id}/profile_picture/${fileName}`;

      const params = {
        Bucket: S3_BUCKET,
        Key: objectKey,
        Body: file,
      };

      if (profilePicture) {
        const profilePictureKey = `hospitals/${currentHospitalData.external_id}/profile_picture/${profilePicture.split("/").pop()}`;
        const deleteParams = {
          Bucket: S3_BUCKET,
          Key: profilePictureKey,
        };

        await s3.deleteObject(deleteParams).promise();
      }

      const data = await s3.upload(params).promise();
      return data;
      console.log("File uploaded successfully:", data);
    } catch (error) {
      console.error("Error during file upload:", error);
      // Handle the error appropriately
      throw error; // Rethrow the error if needed
    }
  };

  const saveUpdate = () => {
    if (
      firstName == null ||
      firstName === "" ||
      lastName == null ||
      lastName === "" ||
      practiceName == null ||
      practiceName === "" ||
      practiceEmail == null ||
      practiceEmail === "" ||
      practiceSpecialty == null ||
      practiceSpecialty === "" ||
      address == null ||
      address === "" ||
      city == null ||
      city === "" ||
      statee == null ||
      statee === "" ||
      zip == null ||
      zip === ""
    ) {
      toggleAlertWithMessage("Please check that all required fields are filled out.")
      return;
    }
    setIsSaving(true);
    var promiselist = [];
    let imageUrl = profilePicture;
    if (imageToUpload) {
      imageUrl = `https://${S3_BUCKET}.s3.amazonaws.com/hospitals/${currentHospitalData.external_id}/profile_picture/${imageToUpload.name}`;
      promiselist.push(uploadProfilePic(imageToUpload));
    }
    geocoder.geocode(
      {
        address: `${apt}, ${address} , ${city}, ${statee} ${zip}, United States`,
      },
      (results, status) => {
        if (results) {
          const location = results[0].geometry.location;
          const latitude = location.lat();
          const longitude = location.lng();
          const coordinates = {
            longitude: longitude,
            latitude: latitude,
          };
          uploadImagesToS3().then((updatedPics) => {
            const clinicData = {
              hospital_name: practiceName,
              hospital_email: practiceEmail,
              hospital_website: practiceWebsite,
              location_info: {
                zip: zip,
                city: city,
                phone: practicePhone,
                state: statee,
                coordinates: coordinates,
                street_address: address,
                street_address_2: apt,
              },
              latitude: latitude,
              longitude: longitude,
              accepted_insurance: insuranceList,
              hospital_images: updatedPics,
              profile_image_url: imageUrl,
              about: about,
            };

            //promiselist.push(EditAccount(currentAccountInfo.id, accountData));
            promiselist.push(
              EditClinic(currentHospitalData.external_id, clinicData)
            );

            Promise.all(promiselist)
              .then((values) => {
                const tempHospitalData = imageToUpload
                  ? values[1].data
                  : values[0].data;
                console.log(tempHospitalData.profile_image_url)
                setCurrentHospitalData(tempHospitalData);
                setPracticeName(tempHospitalData.hospital_name);
                setPracticeEmail(tempHospitalData.hospital_email);
                setPracticeSpecialty(tempHospitalData.main_specialty_title);
                setPracticePhone(tempHospitalData.location_info.phone);
                setAddress(tempHospitalData.location_info.street_address);
                setApt(tempHospitalData.location_info.street_address_2);
                setCity(tempHospitalData.location_info.city);
                setStatee(tempHospitalData.location_info.state);
                setZip(tempHospitalData.location_info.zip);
                setInsuranceList(tempHospitalData.accepted_insurance);
                setPictures(tempHospitalData.hospital_images);
                setProfilePicture(tempHospitalData.profile_image_url);
                setPracticeWebsite(tempHospitalData.hospital_website)
                setImageToUpload(null)
                setIsSaving(false);
                setAlertMessage("Successfully updated clinic profile");
                setShowAlert(true);
                setDidChangeInsuranceList(false);
                setNewInsuranceToAdd([]);
                //window.location.reload();
              })
              .catch((error) => {
                console.error("Error during promise execution:", error);
                // Handle error as needed
              });
            deleteFromS3();
          });
        }
      }
    );
  };

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_KEY,
      version: "weekly",
      libraries: ["geometry", "places"],
    });
    loader.load().then((google) => {
      setGeocoder(new google.maps.Geocoder());
    });

    getAccountInfo().then((res) => {
      if (res.status == 200) {
        dispatch(setAccountInfo(res.data));
        setCurrentAccountInfo(res);
        setAccountEmail(res.email);
        setFirstName(res.first_name);
        setLastName(res.last_name);
        const hospitalList = res.hospital;

        if (hospitalList.length < 1) {
          navigate(`/create-hospital-welcome?user=${res.id}`);
        } else {
          var promises = [];
          hospitalList.forEach((hID) => {
            promises.push(getHospitalInfoByInternalID(hID));
          });

          Promise.all(promises).then((results) => {
            const tempHospitalList = [];
            results.forEach((res) => tempHospitalList.push(res.data));
            setHospitalData(tempHospitalList);
            const tempHospitalData = findObjectById(
              currentHospitalID,
              tempHospitalList
            );
            setCurrentHospitalData(tempHospitalData);
            setPracticeName(tempHospitalData.hospital_name);
            setPracticeEmail(tempHospitalData.hospital_email);
            setPracticeSpecialty(tempHospitalData.main_specialty_title);
            setAbout(tempHospitalData.about)
            setPracticeWebsite(tempHospitalData.hospital_website)
            setPracticePhone(tempHospitalData.location_info.phone);
            setAddress(tempHospitalData.location_info.street_address);
            setApt(tempHospitalData.location_info.street_address_2);
            setCity(tempHospitalData.location_info.city);
            setStatee(tempHospitalData.location_info.state);
            setZip(tempHospitalData.location_info.zip);
            setInsuranceList(tempHospitalData.accepted_insurance);
            setPictures(tempHospitalData.hospital_images);
            setProfilePicture(tempHospitalData.profile_image_url);
            setPageLoading(false)
          });
        }
      }

      if (res.status === 401 || res.status === 403) {
        refreshToken().then((response) => {
          if (response.status == 200) {
            console.log("Successfully refreshed token");
            window.location.reload();
          } else {
            navigate("/doctor-login");
          }
        });
      }
    });

    const handleClickStateOutside = (event) => {
      if (
        stateDropdownRef.current &&
        !stateDropdownRef.current.contains(event.target)
      ) {
        setStateDropdownOpen(false);
      }
    };

    const handleClickSpecialtyOutside = (event) => {
      if (
        specialtyDropdownRef.current &&
        !specialtyDropdownRef.current.contains(event.target)
      ) {
        setSpecialtyDropdownOpen(false);
      }
    };

    window.addEventListener("click", handleClickSpecialtyOutside);
    window.addEventListener("click", handleClickStateOutside);
    return () => {
      window.removeEventListener("click", handleClickSpecialtyOutside);
      window.removeEventListener("click", handleClickStateOutside);
    };
  }, []);

  useEffect(() => {
    setCategorizedInsurances(Object.values(groupByInsuranceID(insuranceList)));
  }, [insuranceList]);

  if (isPageLoading) {
    return <Loading />;
  }

  const canSaveUpdates =
    practiceName !== currentHospitalData.hospital_name ||
    practiceSpecialty !== currentHospitalData.main_specialty_title ||
    practiceWebsite !== currentHospitalData.hospital_website ||
    practiceEmail !== currentHospitalData.hospital_email ||
    practicePhone !== currentHospitalData.location_info.phone ||
    about !== currentHospitalData.about ||
    address !== currentHospitalData.location_info.street_address ||
    apt !== currentHospitalData.location_info.street_address_2 ||
    city !== currentHospitalData.location_info.city ||
    statee !== currentHospitalData.location_info.state ||
    zip !== currentHospitalData.location_info.zip ||
    !areArraysEqual(pictures, currentHospitalData.hospital_images) ||
    (imageToUpload !== currentHospitalData.profile_image_url &&
      imageToUpload !== "" &&
      imageToUpload !== null) ||
    didChangeInsuranceList;

  return (
    <div className="pe-supercontainer">
      <PopupAlert
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        alertMessage={alertMessage}
      />
      <DoctorHeader
        hospitalData={hospitalData}
        currentHospitalData={currentHospitalData}
      />
      <DoctorSideBar
        currentTab={6}
        hospitalData={hospitalData}
        currentHospitalData={currentHospitalData}
      />
      <div className="pe-save-footer">
        {/* <button className="pe-left-cancel-btn" onClick={() => cancelUpdate()}>
          Cancel
        </button> */}
        {canSaveUpdates ? (
          <button className="pe-left-save-btn" onClick={() => saveUpdate()}>
            {isSaving ? <LoadingSpinner /> : "Save Changes"}
          </button>
        ) : (
          <button className="pe-left-save-btn disabled">Save Changes</button>
        )}
      </div>
      <div className="pe-container">
        {/* <div className="pe-left-container">
          <div className="pe-left-subcontainer">
            <div className="pe-left-section">
              <img alt="Icon" src={personal_pe} />
              <div className="d-flex flex-column">
                <h1>Practice Info</h1>
                <h2>Change basic information about your practice</h2>
              </div>
            </div>
            <div className="pe-left-section">
              <img alt="Icon" src={specialty_pe} />
              <div className="d-flex flex-column">
                <h1>Location</h1>
                <h2>Edit the practice's location and contact information</h2>
              </div>
            </div>
            <div className="pe-left-section">
              <img alt="Icon" src={insurance_pe} />
              <div className="d-flex flex-column">
                <h1>Insurance</h1>
                <h2>
                  Add insurance carriers and carriers that are accepted at your
                  practice
                </h2>
              </div>
            </div>
          </div>
        </div> */}

        <div className="pe-right-container noleft ">
          <div className="pe-right-section">
            <div className="pe-right-personal-header">
              <div className="pe-right-personal-header-img">
                <img
                  alt=""
                  src={
                    imageToUpload
                      ? URL.createObjectURL(imageToUpload)
                      : profilePicture
                        ? profilePicture
                        : placeholder_image
                  }
                />
              </div>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                ref={fileInputRef1}
                onChange={handleProfileFileChange}
                accept="image/*"
              />
              <div
                className="pe-right-personal-header-edit-img"
                onClick={() => fileInputRef1.current.click()}
              >
                <i class="fas fa-camera"></i>
              </div>
            </div>

            <div className="pe-right-personal-body">
              <div className="pe-right-personal-top">
                <h3>{currentHospitalData.practiceName}</h3>
              </div>

              <h1 className="booking-intro-label">Practice Info</h1>
              <p className="booking-intro-labelp">
                Fill out some basic information about your practice.
              </p>
              <h3 className="booking-email-label">Practice Name</h3>
              <input
                className="email-input"
                value={practiceName}
                placeholder="Enter name of the clinic"
                onChange={(e) => setPracticeName(e.currentTarget.value)}
              />
              <h3 className="booking-email-label">Practice Email</h3>
              <input
                className="email-input"
                value={practiceEmail}
                placeholder="Enter the clinic's email"
                onChange={(e) => setPracticeEmail(e.currentTarget.value)}
              />
              <h3 className="booking-email-label">Practice website (optional)</h3>
              <input
                className="email-input"
                value={practiceWebsite}
                placeholder="Enter the clinic's website"
                onChange={(e) => setPracticeWebsite(e.currentTarget.value)}
              />
              <h3 className="booking-email-label">Clinic Specialty</h3>
              <div
                className="booker-reason-input-container"
                ref={specialtyDropdownRef}
              >
                <div
                  className="booker-body-dropdown"
                  onClick={() =>
                    setSpecialtyDropdownOpen(!isSpecialtyDropdownOpen)
                  }
                >
                  <h1>
                    {practiceSpecialty != null
                      ? practiceSpecialty
                      : "Choose this provider's main specialty"}
                  </h1>
                  <img alt="chevron down" src={chevron_down} />
                </div>
                {isSpecialtyDropdownOpen && (
                  <ul className="booker-dropdown-menu">
                    {specialtiesData.all_specialties.map((option, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setPracticeSpecialty(option);
                          setSpecialtyDropdownOpen(false);
                        }}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <h3 className="booking-email-label">
                National Provider Identifier (NPI)
              </h3>
              <input
                className="email-input disabled"
                value={currentHospitalData.npi_number}
                placeholder="NPI"
                contentEditable={false}
                disabled
              />

              <h3 className="booking-email-label">About this Clinic (recommended)</h3>
              <textarea
                className="email-input email-textarea"
                placeholder="Enter a description of this provider. Education, years of experience, certifications, degrees."
                value={(about && about.length) > 0 ? about : "No description added."}
                onChange={(c) => setAbout(c.currentTarget.value)}
                maxLength={2000}
              />
            </div>
          </div>

          <div className="pe-right-section">
            <div className="pe-specialty-section">
              <h1 className="booking-intro-label">Location</h1>
              <p className="booking-intro-labelp">
                Enter the location of your practice and the phone number
                associated with it.
              </p>
              <h3 className="booking-email-label">Street address</h3>
              <input
                className="email-input"
                name="email"
                value={address}
                placeholder="Enter your street address"
                onChange={(c) => setAddress(c.currentTarget.value)}
              />
              <span className="d-flex flex-col justify-content-between">
                <div className="email-input-wrapper">
                  <h3 className="booking-email-label">
                    Apt, suite, building (optional)
                  </h3>
                  <input
                    className="name-input"
                    name="first"
                    placeholder="Enter apt, suite, or building"
                    value={apt}
                    onChange={(c) => setApt(c.currentTarget.value)}
                  />
                </div>

                <div className="email-input-wrapper">
                  <h3 className="booking-email-label">City</h3>
                  <input
                    className="name-input2"
                    name="last"
                    placeholder="Enter city"
                    value={city}
                    onChange={(c) => setCity(c.currentTarget.value)}
                  />
                </div>
              </span>
              <span className="d-flex flex-col justify-content-between">
                <div className="email-input-wrapper">
                  <h3 className="booking-email-label">State</h3>

                  <div
                    className="booker-input-container"
                    ref={stateDropdownRef}
                  >
                    <div
                      className="booker-dropdown"
                      onClick={() => setStateDropdownOpen(!isStateDropdownOpen)}
                    >
                      <h2>{statee}</h2>
                      <img alt="chevron down" src={chevron_down} />
                    </div>
                    {isStateDropdownOpen && (
                      <ul className="booker-dropdown-menu">
                        {usStatesAbbreviations.map((option, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setStatee(option);
                              setStateDropdownOpen(false);
                            }}
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>

                <div className="email-input-wrapper">
                  <h3 className="booking-email-label">Zip code</h3>
                  <input
                    className="name-input2"
                    name="last"
                    placeholder="Enter your zip code"
                    value={zip}
                    onChange={(c) => setZip(c.currentTarget.value)}
                  />
                </div>
              </span>
              <h3 className="booking-email-label">Phone number</h3>
              <input
                className="email-input"
                name="email"
                value={practicePhone}
                placeholder="(012) 345-6789"
                onChange={handlePracticePhoneChange}
              />
            </div>
          </div>

          <div className="pe-right-section">
            <div className="pe-specialty-section">
              <h1 className="booking-intro-label">In-network Insurance</h1>
              <p className="booking-intro-labelp">
                Choose which insurance carriers are accepted at your clinic
              </p>
              <InsurancePicker
                setInsuranceID={setInsuranceID}
                setPlanID={setPlanID}
                handleInsuranceChosen={handleInsuranceChosen}
                canChooseAllInsurances={true}
                handleSelectAllInsurancePlansChosen={
                  handleSelectAllInsurancePlansChosen
                }
                shouldRemainOpenOnChoose={true}
                insuranceList={insuranceList}
                removeInsurance={removeInsurance}
              />
              <h1 className="pe-service-limit">{insuranceList.length}/1000</h1>
              <div className="doc-profile-insurances-list-view">
                {newInsuranceToAdd.length > 0 && (
                  <InsuranceAccordian
                    isNewInsurances={true}
                    insuranceGroup={newInsuranceToAdd}
                    removeInsurance={removeInsurance}
                  />
                )}
                {categorizedInsurances.map((insuranceGroup) => {
                  return (
                    <InsuranceAccordian
                      insuranceGroup={insuranceGroup}
                      removeInsurance={removeInsurance}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          <div className="pe-right-section">
            <div className="pe-specialty-section">
              <h1 className="booking-intro-label">Clinic Images</h1>
              <p className="booking-intro-labelp">
                Upload images to show off your clinic and doctors.
              </p>
              <div className="doc-profile-images-list">
                {pictures.map((image, index) => (
                  <div className="doc-profile-image-container noleft" key={index}>
                    <img
                      key={index}
                      src={
                        image instanceof File
                          ? URL.createObjectURL(image)
                          : image
                      }
                      alt={` ${index}`}
                    />
                    <button
                      className="pe-delete-image-button"
                      onClick={() => handleDeleteImage(index)}
                    >
                      <i class="fas fa-times pe-delete-image-button-x"></i>
                    </button>
                  </div>
                ))}
                <div
                  className="doc-profile-image-container2 noleft"
                  onClick={() => fileInputRef.current.click()}
                >
                  <img alt="Add Image" src={add_image} />
                </div>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </div>
            </div>
          </div>

          {/* <div className="pe-right-section">
            <div className="pe-specialty-section">
              <h1 className="booking-intro-label">Achew Account</h1>
              <p className="booking-intro-labelp">
                Makes changes to your achew account and login information.
              </p>
              <span className="d-flex flex-col justify-content-between">
                <div className="email-input-wrapper">
                  <h3 className="booking-email-label">Legal first name</h3>
                  <input
                    className="name-input"
                    name="first"
                    placeholder="Enter your first name"
                    value={firstName}
                    onChange={(c) => setFirstName(c.currentTarget.value)}
                  />
                </div>

                <div className="email-input-wrapper">
                  <h3 className="booking-email-label">Legal last name</h3>
                  <input
                    className="name-input2"
                    name="last"
                    placeholder="Enter your last name"
                    value={lastName}
                    onChange={(c) => setLastName(c.currentTarget.value)}
                  />
                </div>
              </span>
              <h3 className="booking-email-label">Login Email</h3>
              <input
                className="email-input"
                name="email"
                value={accountEmail}
                placeholder="Enter your street address"
                onChange={(c) => setAccountEmail(c.currentTarget.value)}
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Settings;
