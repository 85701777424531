import { monthDictionary } from "../../components/doctors/dashboard/prototype-calendar/helper-functions";
import { default_profile, placeholder_image } from "../../components/home/image";

function formatDateToLongString(date) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return (new Date(date)).toLocaleDateString('en-US', options);
}
function convertTimeToInt(time12Hour) {
    const [time, period] = time12Hour.split(' ');

    let [hours, minutes] = time.split(':');

    hours = parseInt(hours, 10);

    if (period === 'PM' && hours !== 12) {
        hours += 12;
    } else if (period === 'AM' && hours === 12) {
        hours = 0;
    }

    // Pad single-digit hours and minutes with leading zeros
    hours = hours.toString().padStart(2, '0');
    console.log(hours)
    minutes = minutes.padStart(2, '0');

    const time24Hour = parseInt(`${hours}${minutes}`, 10);

    return time24Hour;
}
function ensureHttps(url) {
    // Check if the URL starts with 'http://' and replace it with 'https://'
    if (url.startsWith('http://')) {
      return url.replace('http://', 'https://');
    }
    // If the URL is already https or doesn't start with 'http://', return it as is
    return url;
  }
  function ensureHttpsWww(url) {
    // Remove any leading or trailing white spaces.
    url = url.trim();
  
    // Check if it starts with http or https
    if (!/^https?:\/\//i.test(url)) {
      // If not, add https://
      url = 'https://' + url;
    }
  
    // If it doesn't start with www. after https://, insert it.
    if (!/^https:\/\/www\./i.test(url)) {
      // Find where the protocol ends
      const protocolEnd = url.indexOf('://') + 3;
      url = url.slice(0, protocolEnd) + 'www.' + url.slice(protocolEnd);
    }
  
    return url;
  }
function formatTimestamp(timestamp) {
    // Convert the timestamp to milliseconds
    const date = new Date(timestamp);

    // Format time as HH:mm AM/PM
    const formattedTime = date.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    });

    // Format day of the week, day of the month, and month
    const formattedDate = date.toLocaleString('en-US', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
    });

    // Format the year
    const year = date.getFullYear();

    // Combine the formatted time, date, and year
    const formattedDateTime = `${formattedTime} - ${formattedDate}, ${year}`;

    return formattedDateTime;
}

function findObjectById(id, jsonArray) {
    for (let i = 0; i < jsonArray.length; i++) {

        if (jsonArray[i].external_id == id) {
            return jsonArray[i];
        }
    }
    // If no matching object is found, return null or an appropriate value.
    return null;
}
function deleteObjectById(id, jsonArray) {
    for (let i = 0; i < jsonArray.length; i++) {

        if (jsonArray[i].external_id == id) {
            return jsonArray[i];
        }
    }
    // If no matching object is found, return null or an appropriate value.
    return null;
}
function findMetricById(id, jsonArray) {
    for (let i = 0; i < jsonArray.length; i++) {

        if (jsonArray[i].Id == id) {
            return jsonArray[i];
        }
    }
    // If no matching object is found, return null or an appropriate value.
    return null;
}
function findObjectByInternalId(id, jsonArray) {
    for (let i = 0; i < jsonArray.length; i++) {

        if (jsonArray[i].id === id) {
            return jsonArray[i];
        }
    }
    // If no matching object is found, return null or an appropriate value.
    return null;
}
function getCombinedName(insuranceId, planId, insuranceData) {
    for (const insurance of insuranceData.popular) {
        if (insurance.id === insuranceId) {
            const insuranceName = insurance.name;
            for (const plan of insurance.plans) {
                if (plan.id === planId) {
                    const planName = plan.title;
                    return `${insuranceName} - ${planName}`;
                }
            }
        }
    }
    return "Choose Insurance Plan";
}

function getInsuranceById(insuranceId, insuranceData) {
    for (const insurance of insuranceData.all) {
        if (insurance.id === insuranceId) {
            return insurance;
        }
    }
}
function getTimeAsNumber(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Convert the time to a number in the format HHMM
    const timeNumber = hours * 100 + minutes;

    return timeNumber;
}
function getPlanByIds(insuranceId, planId, insuranceData) {
    const insurance = insuranceData.all.find(ins => ins.id === insuranceId);

    if (insurance) {
        const plan = insurance.plans.find(p => p.id === planId);
        return plan || null;
    }
}
const validateNPI = (npiNumber) => {
    if (!/^\d{10}$/.test(npiNumber)) {
      return false; // Not a 10-digit number
    }

    // Implementing the Luhn algorithm for validation
    let sum = 0;
    let isSecondDigit = false;
    for (let i = npiNumber.length - 1; i >= 0; i--) {
      let d = parseInt(npiNumber.charAt(i), 10);

      if (isSecondDigit) {
        d = d * 2;
        if (d > 9) {
          d = d - 9;
        }
      }
      sum += d;
      isSecondDigit = !isSecondDigit;
    }

    return (sum % 10) === 0;
  };
function sumValuesByDate(timestamps, values) {
    const result = Array(12).fill(0);

    timestamps.forEach((timestamp, index) => {
        const date = new Date(timestamp);
        const month = date.getMonth();

        result[month] += values[index];
    });

    return result;
}
function generateTimeSlots() {
    const timeSlots = [];
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0); // Set to 12:00:00 AM

    for (let i = 0; i < 48; i++) {
        // Create a new Date object for each time slot
        const currentTimeSlot = new Date(startTime.getTime() + i * 30 * 60 * 1000); // Increment by 30 minutes (in milliseconds)

        // Format the time slot as "HH:MM AM/PM"
        const formattedTime = currentTimeSlot.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

        timeSlots.push(formattedTime);
    }

    return timeSlots;
}

function convertMinutesToHoursAndMinutes(totalMinutes) {
    if (typeof totalMinutes !== 'number' || totalMinutes < 0) {
        throw new Error('Input must be a non-negative number of minutes.');
    }

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    console.log(hours, minutes)
    return { hours, minutes };
}

function formatTime(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

function generateTimeSlotsSchedule(schedule) {
    const daysOfWeek = Object.keys(schedule);
    const timeSlotsByDay = {};

    daysOfWeek.forEach((day) => {
        const { start, end, interval } = schedule[day];
        const timeSlots = [];
        let currentTime = parseTime(start);

        while (currentTime <= parseTime(end)) {
            timeSlots.push([formatTimeMinutes(currentTime), true]);
            currentTime += interval; // Update the time by adding minutes directly
        }

        timeSlotsByDay[day] = timeSlots;
    });

    return timeSlotsByDay;
}

function parseTime(timeString) {
    const [hours, minutes] = timeString.match(/\d+/g).map(Number);
    const isPM = /pm/i.test(timeString);
    return (isPM ? hours + 12 : hours) * 60 + minutes;
}

function formatTimeMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
}


function cleanUpTimeSlots(data) {
    const cleanedData = {};
    for (const day in data) {
        const slots = data[day];
        const cleanedSlots = slots
            .filter(slot => slot[1] === true) // Filter out unavailable slots
            .map(slot => slot[0]); // Extract the time value

        cleanedData[day] = cleanedSlots;
    }
    return cleanedData;
}

function parseTimeToDateTime(timeString) {
    // Split the time string into hours, minutes, and AM/PM parts
    const [time, ampm] = timeString.split(' ');
    const [hours, minutes] = time.split(':');

    // Create a base date object (you can set the date as per your requirements)
    const baseDate = new Date();
    // Set the hours and minutes
    baseDate.setHours(parseInt(hours, 10));
    baseDate.setMinutes(parseInt(minutes, 10));

    // Adjust for AM/PM
    if (ampm === 'PM' && baseDate.getHours() < 12) {
        baseDate.setHours(baseDate.getHours() + 12);
    } else if (ampm === 'AM' && baseDate.getHours() === 12) {
        baseDate.setHours(0);
    }

    return baseDate;
}

function convertToLocalTimeToUTC(scheduleObject) {
    const utcSchedule = {};

    for (const day in scheduleObject) {
        utcSchedule[day] = scheduleObject[day].map(localTime => {
            const localDate = parseTimeToDateTime(localTime)
            return localDate.getTime()
        });
    }

    return utcSchedule;
}

function getStartOfWeek(date) {
    const dayIndex = date.getDay();
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - dayIndex);
    return startOfWeek;
}

function getDaysOfWeek(startOfWeek) {
    const daysOfWeek = [];
    for (let i = 0; i < 7; i++) {
        const day = new Date(startOfWeek);
        day.setDate(startOfWeek.getDate() + i);
        daysOfWeek.push(day);
    }
    return daysOfWeek;
}

function placeAppointmentsInCalendar(appointments, calendar) {
    for (const appointment of appointments) {
        const appointmentDate = new Date(appointment.date);
        const dayOfWeek = appointmentDate.getDay().toString(); // Adjust day to match the calendar object (1-7).
        const hourOfDay = appointmentDate.getHours().toString();

        if (calendar[hourOfDay] && calendar[hourOfDay][dayOfWeek]) {
            calendar[hourOfDay][dayOfWeek].push(appointment);
        } else {
            // Handle invalid day or hour, or create slots if necessary.
            console.log(`Invalid day or hour for appointment: ${appointment.id}`);
        }
    }
}

function isDateBetween(dateToCheck, startOfDay, endOfDay) {
    const date = new Date(dateToCheck);
    const startDate = new Date(startOfDay);
    const endDate = new Date(endOfDay);

    // Check if the date is greater than or equal to the start of the day
    // and less than or equal to the end of the day.
    return date >= startDate && date <= endDate;
}

function printTimeAMPM(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format and ensure a single digit for hours if necessary
    const formattedHours = (hours % 12 || 12).toString();
    const formattedMinutes = minutes.toString().padStart(2, '0');

    const timeAMPM = `${formattedHours}:${formattedMinutes} ${ampm}`;
    return timeAMPM
}

function convertTo12HourFormat(hour) {
    if (typeof hour !== 'number' || hour < 0 || hour > 23) {
        return "Invalid hour";
    }

    if (hour === 0) {
        return "12:00 AM";
    } else if (hour === 12) {
        return "12:00 PM";
    } else if (hour < 12) {
        return `${hour}:00 AM`;
    } else {
        return `${hour - 12}:00 PM`;
    }
}

function findLocationById(id, jsonArray) {
    for (let i = 0; i < jsonArray.length; i++) {
        if (jsonArray[i].id == parseInt(id)) {
            return jsonArray[i];
        }
    }
    // If no matching object is found, return null or an appropriate value.
    return null;
}
function replaceByID(id, jsonArray, newObject) {
    for (let i = 0; i < jsonArray.length; i++) {
        if (jsonArray[i].id == parseInt(id)) {
            jsonArray[i] = newObject
        }
    }
    // If no matching object is found, return null or an appropriate value.
    return null;
}

function findDoctorById(id, jsonArray) {
    for (let i = 0; i < jsonArray.length; i++) {
        if (jsonArray[i].id == id) {
            return jsonArray[i];
        }
    }
    // If no matching object is found, return null or an appropriate value.
    return null;
}

function timeStringToDate(timeString) {
    // Split the time string into hours, minutes, and AM/PM parts
    var [time, period] = timeString.split(' ');
    var [hours, minutes] = time.split(':');

    // Parse hours and minutes as integers
    var hoursInt = parseInt(hours, 10);
    var minutesInt = parseInt(minutes, 10);

    // Adjust hours for 12-hour clock (AM/PM)
    if (period === 'PM' && hoursInt !== 12) {
        hoursInt += 12;
    } else if (period === 'AM' && hoursInt === 12) {
        hoursInt = 0;
    }

    // Create a new Date object with the adjusted time
    var date = new Date();
    date.setHours(hoursInt, minutesInt, 0, 0);

    return date;
}

function formatTimestampTo12HourClock(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

function formatPhoneNumber(phoneNumber) {
    // Remove any non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, '');

    // Check if the cleaned phone number has 10 or 11 digits
    if (cleaned.length === 10) {
        return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    } else if (cleaned.length === 11) {
        return `(${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
    } else {
        // Handle invalid phone numbers or other cases
        return 'Invalid phone number';
    }
}


function printDateNicely(date) {
    const options = {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    };
    const formattedDate = date.toLocaleDateString(undefined, options);
    return formattedDate
}

function convertTimestampsToTime(scheduleObject) {
    const convertedSchedule = {};

    for (const day in scheduleObject) {
        convertedSchedule[day] = scheduleObject[day].map(timestamp => {
            // Convert the timestamp to a Date object
            const date = new Date(timestamp);
            // Extract hours, minutes, and AM/PM from the Date object
            const ampm = date.getHours() < 12 ? 'AM' : 'PM';
            const hours = date.getHours() % 12 || 12; // Ensure 12-hour format
            const minutes = date.getMinutes();


            // Format the time as HH:MM AM/PM
            const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;

            return formattedTime;
        });
    }
    return convertedSchedule;
}

function getAvailableSlots(jsonData, targetDate) {
    if (jsonData == undefined || jsonData == null || jsonData == {} || jsonData.tags.length < 1) {
        return []
    }
    console.log(jsonData, "jsonData")
    const filteredMonday = []
    jsonData.tags.Monday.slots.forEach(slot => {
        if (slot.isActive) {
            filteredMonday.push(slot.timestamp)
        }
    });
    const filteredTuesday = []
    jsonData.tags.Tuesday.slots.forEach(slot => {
        if (slot.isActive) {
            filteredTuesday.push(slot.timestamp)
        }
    });
    const filteredWednesday = []
    jsonData.tags.Wednesday.slots.forEach(slot => {
        if (slot.isActive) {
            filteredWednesday.push(slot.timestamp)
        }
    });
    const filteredThursday = []
    jsonData.tags.Thursday.slots.forEach(slot => {
        if (slot.isActive) {
            filteredThursday.push(slot.timestamp)
        }
    });
    const filteredFriday = []
    jsonData.tags.Friday.slots.forEach(slot => {
        if (slot.isActive) {
            filteredFriday.push(slot.timestamp)
        }
    });
    const filteredSaturday = []
    jsonData.tags.Saturday.slots.forEach(slot => {
        if (slot.isActive) {
            filteredSaturday.push(slot.timestamp)
        }
    });
    const filteredSunday = []
    jsonData.tags.Sunday.slots.forEach(slot => {
        if (slot.isActive) {
            filteredSunday.push(slot.timestamp)
        }
    });

    const filteredActiveAppointments = {
        "Monday": filteredMonday,
        "Tuesday": filteredTuesday,
        "Wednesday": filteredWednesday,
        "Thursday": filteredThursday,
        "Friday": filteredFriday,
        "Saturday": filteredSaturday,
        "Sunday": filteredSunday

    }
    const convertedSchedule = convertTimestampsToTime(filteredActiveAppointments)

    const targetDayOfWeek = targetDate.getDay();
    const dayName = Object.keys(convertedSchedule)[targetDayOfWeek];
    if (convertedSchedule.hasOwnProperty(dayName)) {
        const availableSlots = convertedSchedule[dayName].filter(slot => slot !== 'unavailable');

        const dateObjects = availableSlots.map(slotTime => {
            // Split the time string into hours, minutes, and AM/PM
            const [time, ampm] = slotTime.split(' ');
            const [hours, minutes] = time.split(':').map(Number);

            // Calculate 24-hour hours value based on AM/PM
            let hours24 = hours;
            if (ampm === 'PM' && hours !== 12) {
                hours24 += 12;
            } else if (ampm === 'AM' && hours === 12) {
                hours24 = 0;
            }

            // Clone the target date and set the hours and minutes
            const slotDate = new Date(targetDate);
            slotDate.setHours(hours24);
            slotDate.setMinutes(minutes);

            return slotDate;
        });

        return dateObjects;
    } else {
        return [];
    }
}

function formatTimeDate(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
}
const usStatesAbbreviations = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];
function isValidPassword(password) {
    // Check if the password is at least 8 characters long
    if (password.length < 8) {
        return false;
    }

    // Check if the password contains at least one special character
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;
    if (!specialCharacterRegex.test(password)) {
        return false;
    }

    // Check if the password contains at least one digit (number)
    const digitRegex = /\d/;
    if (!digitRegex.test(password)) {
        return false;
    }

    // Check if the password contains at least one uppercase letter
    const uppercaseRegex = /[A-Z]/;
    if (!uppercaseRegex.test(password)) {
        return false;
    }

    // If all conditions are met, the password is valid
    return true;
}
function areDatesEqual(date1, date2) {
    const day1 = date1.getDate();
    const month1 = date1.getMonth();
    const year1 = date1.getFullYear();

    const day2 = date2.getDate();
    const month2 = date2.getMonth();
    const year2 = date2.getFullYear();

    return day1 === day2 && month1 === month2 && year1 === year2;
}
function areDatesEqualInUTC(date1, date2) {
    const day1 = date1.getDate();
    const month1 = date1.getMonth();
    const year1 = date1.getFullYear();

    const day2 = date2.getUTCDate();
    const month2 = date2.getUTCMonth();
    const year2 = date2.getUTCFullYear();

    return day1 === day2 && month1 === month2 && year1 === year2;
}
function convertDateStringToDateObject(dateString) {
    // Split the date string into an array [month, day, year]
    const dateParts = dateString.split('/');

    // Note: months in JavaScript are zero-based, so we subtract 1 from the month
    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[0], 10) - 1;
    const day = parseInt(dateParts[1], 10);

    // Create a Date object using the components
    const dateObject = new Date(year, month, day);

    return dateObject;
}

function getLast4DigitsAsString(number) {
    if (!number || number.length < 4) {
        return "XXXX"
    }
    const numberString = number.toString();
    const last4Digits = numberString.slice(-4);
    return last4Digits;
}

function formatPhoneNumberBooking(phoneNumber) {
    const cleanedNumber = phoneNumber.replace(/\D/g, '');
    return cleanedNumber;
}

function formatTimestampToISO(timestamp) {
    const date = new Date(parseInt(timestamp));

    // Get the components of the date
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    // Construct the ISO 8601 date string
    const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;

    return isoString;
}

function countRatings(reviews) {
    const ratingCount = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    let totalStars = 0.0;
    let totalReviews = 0.0;

    reviews.forEach((review) => {
        if (review.rating >= 1 && review.rating <= 5) {
            ratingCount[review.rating]++;
            totalStars += review.rating;
            totalReviews++;
        }
    });

    const averageRating = totalReviews === 0 ? "0.0" : (totalStars / parseFloat(totalReviews)).toFixed(1);

    return {
        ratingCount,
        averageRating,
        totalReviews,
    };
}
function setMidnight(date) {
    // Clone the date to avoid modifying the original object
    const midnightDate = new Date(date);

    // Set the time to midnight
    midnightDate.setHours(0, 0, 0, 0);

    // Convert the midnight date to a timestamp
    const timestamp = midnightDate.getTime();

    return timestamp;
}
function getWeekAndNextWeek(inputDate) {
    // Clone the input date to avoid modifying the original object
    var dateObj = new Date(inputDate);

    // Calculate the start of the current week
    var startOfWeek = new Date(dateObj.setDate(dateObj.getDate() - dateObj.getDay()));

    // Calculate the start of the next week
    var startOfNextWeek = new Date(startOfWeek);
    startOfNextWeek.setDate(startOfNextWeek.getDate() + 7);

    // Create arrays of dates for each week
    var currentWeekArray = [];
    var nextWeekArray = [];

    for (var i = 0; i < 7; i++) {
        currentWeekArray.push(new Date(startOfWeek));
        nextWeekArray.push(new Date(startOfNextWeek));
        startOfWeek.setDate(startOfWeek.getDate() + 1);
        startOfNextWeek.setDate(startOfNextWeek.getDate() + 1);
    }

    return { currentWeek: currentWeekArray, nextWeek: nextWeekArray };
}
function getStartOfWeekAndEndOfNextWeek(inputDate) {
    // Clone the input date to avoid modifying the original object
    var dateObj = new Date(inputDate);

    // Calculate the start of the current week
    var startOfWeek = new Date(dateObj.setDate(dateObj.getDate() - dateObj.getDay()));

    // Calculate the start of the next week
    var startOfNextWeek = new Date(startOfWeek);
    startOfNextWeek.setDate(startOfNextWeek.getDate() + 7);

    // Calculate the end of next week (Saturday)
    var endOfNextWeek = new Date(startOfNextWeek);
    endOfNextWeek.setDate(endOfNextWeek.getDate() + 6);

    return { startOfWeek, endOfNextWeek };
}
const isDateBeforeToday = (givenDate) => {
    // Get the current date
    const today = new Date();

    // Extract the year, month, and day from the given date
    const givenYear = givenDate.getFullYear();
    const givenMonth = givenDate.getMonth();
    const givenDay = givenDate.getDate();

    // Extract the year, month, and day from today
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth();
    const todayDay = today.getDate();

    // Compare the dates without considering the time
    return givenYear < todayYear || (givenYear === todayYear && (
        givenMonth < todayMonth || (givenMonth === todayMonth && givenDay < todayDay)
    ));
};
function formatWebsiteLink(url) {
    // Ensure the URL starts with https://
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url;
    } else if (url.startsWith('http://')) {
      url = 'https://' + url.substring(7);
    }
  
    // Ensure the URL contains www. after the protocol
    if (!url.includes('://www.')) {
      let protocolEndIndex = url.indexOf('://') + 3; // Position right after ://
      url = url.substring(0, protocolEndIndex) + 'www.' + url.substring(protocolEndIndex);
    }
  
    return url;
  }
function convertTo12HourFormatFromSlot(time24) {
    // Extract hours and minutes
    const timeRegex = /^(\d{1,2})(\d{2})$/;
    const match = time24.match(timeRegex);

    if(parseInt(time24) >= 0 && parseInt(time24) <= 9){
        return `12:0${time24} AM`
    }
    if(parseInt(time24) >= 10 && parseInt(time24) <= 59){
        return `12:${time24} AM`
    }

    // Check if the input matches the expected format
    if (!match) {
        return 'Invalid time format';
    }

    const [, hours, minutes] = match.map(Number);

    // Validate the input
    if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours >= 24 || minutes < 0 || minutes >= 60) {
        return 'Invalid time format';
    }

    // Convert to 12-hour format
    const meridiem = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12; // Handle midnight (0) as 12 AM

    // Format the result
    const time12 = `${hours12}:${minutes.toString().padStart(2, '0')} ${meridiem}`;

    return time12;
}
function setTimeFromDate(dateObj, timeInt) {
    // Extract hours and minutes from the time integer
    const hours = Math.floor(timeInt / 100);
    const minutes = timeInt % 100;

    // Set the time on the Date object
    dateObj.setHours(hours, minutes, 0, 0);

    return dateObj;
}

function getPlanName(insuranceID, planID, insuranceData) {
    const insuranceProvider = insuranceData.all.find(provider => provider.id === insuranceID);

    if (insuranceProvider) {
        const plan = insuranceProvider.plans.find(p => p.id === planID);

        if (plan) {
            return plan;
        } else {
            return "Plan not found for the given ID";
        }
    } else {
        return "Insurance provider not found for the given ID";
    }
}

function isDateInRange(startDate, endDate, targetDate) {
    // Convert all dates to UTC to ignore time zone differences
    const startUTC = startDate;
    const endUTC = endDate;
    const targetUTC = getMidnightUTCTimestamp(targetDate);

    console.log("Checking date range: ", startDate, endDate, targetDate, startUTC <= targetUTC && targetUTC <= endUTC)

    // Check if target date is within the range
    return startUTC <= targetUTC && targetUTC <= endUTC;
}

function formatPhoneNumber2(phoneNumber) {
    // Remove special characters and spaces
    const cleanedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');

    // Add +1 to the beginning
    const formattedPhoneNumber = '+1' + cleanedPhoneNumber;

    return formattedPhoneNumber;
}
function formatPhoneNumber3(phoneNumber) {
    // Remove special characters and spaces
    const cleanedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');

    return cleanedPhoneNumber;
}
function isTimeInArray(arrayOfObjects, targetTime) {
    return false
    return arrayOfObjects.some(obj => obj.time === targetTime);
}
function centsToDollars(cents) {
    if (typeof cents !== 'number') {
        throw new Error('Input must be a non-negative number');
    }

    const dollars = cents / 100.0;
    return dollars.toFixed(2);
}

function convertTo12HourFormatList(timeInt) {
    // Convert the integer to a string for easier manipulation
    const timeStr = timeInt.toString();
    if(parseInt(timeStr) >= 0 && parseInt(timeStr) <= 9){
        return `12:0${timeStr} AM`
    }
    if(parseInt(timeStr) >= 10 && parseInt(timeStr) <= 59){
        return `12:${timeStr} AM`
    }
    // Extract hours and minutes
    const hours = timeStr.length === 3 ? timeStr[0] : timeStr.slice(0, 2);
    const minutes = timeStr.slice(-2);

    // Convert hours to a number
    const hoursNum = parseInt(hours, 10);

    // Determine if it's AM or PM
    const period = hoursNum >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    const hours12 = hoursNum % 12 || 12;

    // Format the result
    const time12 = `${hours12}:${minutes} ${period}`;

    return time12;
}
function getFirstDayOfGivenWeek(date) {
    const clonedDate = new Date(date);

    // Calculate the difference between the current day and Sunday (0)
    const dayOfWeek = clonedDate.getDay();
    const daysToSubtract = dayOfWeek === 0 ? 0 : dayOfWeek;

    // Subtract the days to get the first day of the week (Sunday)
    clonedDate.setDate(clonedDate.getDate() - daysToSubtract);

    return clonedDate;
}

function indexOfAvailableSlots(availableSlots, time, scheduleID) {
    var i = -1
    availableSlots.forEach((slot, index) => {
        if (slot.time === time && slot.schedule_id === scheduleID) {
            console.log("Index found: " + index)
            i = index
            return
        }
    })

    return i
}

const frequencyMap = {
    "O": "Once",
    "D": "Daily",
    "W": "Weekly",
}

function extractTimeInfo3(date) {
    const hours = date.getHours() % 12 || 12; // Convert 0 to 12
    const minutes = ('0' + date.getMinutes()).slice(-2); // Ensure minutes are always 2 digits
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    return `${hours}:${minutes} ${ampm}`;
}

function getMidnightUTCTimestamp(date) {
    // Clone the date to avoid modifying the original date object
    const midnightUTC = new Date(date);

    // Set the time to 0:00:00.000 UTC
    midnightUTC.setUTCMonth(date.getMonth())
    midnightUTC.setUTCDate(date.getDate())
    midnightUTC.setUTCFullYear(date.getFullYear())
    midnightUTC.setUTCHours(0);
    midnightUTC.setUTCMinutes(0);
    midnightUTC.setUTCSeconds(0);
    midnightUTC.setUTCMilliseconds(0);

    // Get the timestamp in milliseconds
    const timestamp = midnightUTC.getTime();

    return timestamp;
}
function combineDateAndTimeInTimezone(timeInt, dateObject) {
    // Parse the input time integer
    const timeString = timeInt.toString().padStart(4, '0');
    const hours = parseInt(timeString.substring(0, 2), 10);
    const minutes = parseInt(timeString.substring(2), 10);

    // Get the user's timezone offset in minutes
    const timezoneOffset = new Date().getTimezoneOffset();

    // Create a new Date object with the given date and time
    const combinedDateTime = new Date(dateObject);
    combinedDateTime.setUTCHours(hours - timezoneOffset / 60);
    combinedDateTime.setUTCMinutes(minutes);

    return combinedDateTime;
}
function getUserTimeZone() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return userTimeZone;
}

const convertTo12HourClock = (time) => {
    // Convert the time to a string and ensure it has 4 digits
    const timeStr = time.toString().padStart(4, '0');

    // Extract hours and minutes
    const hours = parseInt(timeStr.slice(0, 2), 10);
    const minutes = parseInt(timeStr.slice(2), 10);

    // Determine AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    const hours12 = hours % 12 === 0 ? 12 : hours % 12;

    // Create formatted strings
    const hoursStr = hours12.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');

    return {
        hours: hoursStr,
        minutes: minutesStr,
        period,
    };
};

function extractTimeInfo(utcDate, timeInt, timezone) {
    const timeString = convertTo12HourClock(timeInt)

    const hours = timeString.hours
    const minutes = timeString.minutes;
    const ampm = timeString.period
    const month = utcDate.getMonth()
    const date = utcDate.getDate()
    const year = utcDate.getFullYear()

    const fullDate = monthDictionary[month] + "  " + date + ", " + year
    const fullDateTime = hours + ":" + minutes + ampm + " - " + fullDate
    const fullDateTimeAndZone = hours + ":" + minutes + ampm + " - " + fullDate + " " + timezone
    const extractedDate = {
        "month": month,
        "date": date,
        "year": year,
        "hour": hours,
        "ampm": ampm,
        "minute": minutes,
        "fullDate": fullDate,
        "fullDateTime": fullDateTime,
        "fullDateTimeAndZone": fullDateTimeAndZone
    }
    return extractedDate
}

function extractTimeInfo2(date, timezone) {

    const hour = date.getHours();
    console.log(date.getMinutes())
    const minute = ('0' + date.getMinutes()).slice(-2);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    var hour12 = hour % 12 || 12; // Convert 0 to 12
    const month = date.getMonth()
    const dates = date.getDate()
    const year = date.getFullYear()

    const fullDate = monthDictionary[month] + "  " + dates + ", " + year
    const fullDateTime =  fullDate + " - " + hour12 + ":" + minute + ampm
    const fullDateTimeAndZone = fullDate + " (" + hour12 + ":" + minute + ampm + " " + timezone + ")"
    const extractedDate = {
        "month": month,
        "date": dates,
        "year": year,
        "hour": hour12,
        "ampm": ampm,
        "minute": minute,
        "fullDate": fullDate,
        "fullDateTime": fullDateTime,
        "fullDateTimeAndZone": fullDateTimeAndZone
    }
    return extractedDate
}

function isSlotExpired(date) {
    const formattedDate = new Date(date * 1000)
    const threholdDate = new Date(formattedDate.getTime() + 86400000)
    const today = new Date()
    return today.getTime() > threholdDate
}

const formatDateUTCKey = (utcDate) => {
    // Create a new Date object from the UTC date string
    const dateObj = new Date(utcDate);

    // Extract the month, day, and year from the date object
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); // Months are zero indexed
    const day = String(dateObj.getUTCDate()).padStart(2, '0');
    const year = dateObj.getUTCFullYear();

    // Concatenate the formatted date parts into MMDDYYYY format
    const formattedDate = `${month}${day}${year}`;

    return formattedDate;
};
const isInBetween = (startDate, endDate, dateToCheck) => {
    // Extract date, month, and year components for the start and end date
    //console.log(startDate)
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const startDateOfMonth = startDate.getDate();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();
    const endDateOfMonth = endDate.getDate();

    // Extract date, month, and year components for the third date
    const checkYear = dateToCheck.getFullYear();
    const checkMonth = dateToCheck.getMonth();
    const checkDateOfMonth = dateToCheck.getDate();

    if ((checkYear > startYear || (checkYear === startYear && checkMonth > startMonth) || (checkYear === startYear && checkMonth === startMonth && checkDateOfMonth >= startDateOfMonth)) &&
        (checkYear < endYear || (checkYear === endYear && checkMonth < endMonth) || (checkYear === endYear && checkMonth === endMonth && checkDateOfMonth <= endDateOfMonth))) {
        //console.log(startDateOfMonth, checkDateOfMonth)
    }

    // Check if the third date falls between start and end dates
    return (
        (checkYear > startYear || (checkYear === startYear && checkMonth > startMonth) || (checkYear === startYear && checkMonth === startMonth && checkDateOfMonth >= startDateOfMonth)) &&
        (checkYear < endYear || (checkYear === endYear && checkMonth < endMonth) || (checkYear === endYear && checkMonth === endMonth && checkDateOfMonth <= endDateOfMonth))
    );
};

const getClinicProfilePicture = (data) => {
    return (data.profile_image_url && data.profile_image_url !== "") ? data.profile_image_url :
        (data.hospital_images && data.hospital_images.length > 0 ? data.hospital_images[0] : placeholder_image)

}

const getDoctorProfilePicture = (data) => {
    return (data.profile_image_url && data.profile_image_url !== "") ? data.profile_image_url : default_profile

}

const convertUTCToLocal = (date) => {
    const localDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    return localDate;
};

const getHospitalPromotions = (hospitalData) => {
    const promotions = [];

    promotions.push({
        slogan: "Nationally Verified Clinic (NPI)",
        description: "This hospital has achieved national verification, ensuring high standards of care and service."
    });

    if (hospitalData.response_time < 15000) {
        promotions.push({
            slogan: "Excellent response time",
            description: "Our clinic prides itself on rapid responses, ensuring your health concerns are addressed promptly."
        });
    }
    if (hospitalData.rating > 4) {
        promotions.push({
            slogan: "Patient approved, excellent rating",
            description: "With ratings above 4, our patients consistently acknowledge our exceptional care quality."
        });

        promotions.push({
            slogan: "Low appointment waiting time",
            description: "We value your time. Enjoy shorter waits and quicker access to our healthcare professionals."
        });

        promotions.push({
            slogan: "Accepting new patients",
            description: "Our doors are open to new patients. Begin your journey to wellness with us today."
        });

    }
    if (hospitalData.rating > 4.5) {
        promotions.push({
            slogan: "Achew recommended!",
            description: "Achieving a rating over 4.5, our clinic comes highly recommended for outstanding healthcare services."
        });
    }
    if (hospitalData.distance < 2) {
        promotions.push({
            slogan: "Close to your location",
            description: "Located within 2 miles, our clinic is conveniently close to you for all your healthcare needs."
        });
    }

    promotions.push({
        slogan: "Serving patients and providing excellent care.",
        description: "Our longstanding commitment to healthcare excellence has been making a difference since 2004."
    });
    promotions.push({
        slogan: "Appointment slots flexible and available",
        description: "We offer flexible scheduling to accommodate your busy life, ensuring you get the care you need when you need it."
    });
    promotions.push({
        slogan: "Completed profile ready to view",
        description: "Our fully completed profile provides all the information you need to know about our services and care."
    });
    return promotions;
}

function sumTotalSlots(data) {
    // Initialize total slots count
    let totalSlots = 0;
  
    // Iterate over each key in the data object
    Object.keys(data).forEach(key => {
      // Add the total_slots value from each item to the totalSlots
      totalSlots += data[key].total_slots;
    });
  
    // Return the total count of slots
    return totalSlots;
  }

  function replaceObjectByExternalId(arrayOfObjects, newObject) {
    // Check if the newObject has an "external_id" property
    if (!newObject.hasOwnProperty('external_id')) {
      console.error('The new object does not have an "external_id" property.');
      return arrayOfObjects;
    }
  
    // Map through the original array
    return arrayOfObjects.map(obj => {
      // If an object with the same "external_id" as the newObject is found, return the newObject instead
      if (obj.external_id === newObject.external_id) {
        return newObject;
      }
  
      // Otherwise, return the original object
      return obj;
    });
  }

  const timeAgo = (timestamp) => {
    const currentDate = new Date();
    const targetDate = new Date(timestamp);

    // Calculate the difference in milliseconds
    const difference = currentDate - targetDate;

    // Calculate days, hours, and minutes
    const daysAgo = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hoursAgo = Math.floor(difference / (1000 * 60 * 60));
    const minutesAgo = Math.floor(difference / (1000 * 60));

    if (daysAgo > 0) {
      return `${daysAgo} day${daysAgo === 1 ? '' : 's'} ago`;
    } else if (hoursAgo > 0) {
      return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
    } else if (minutesAgo > 0) {
      return `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago`;
    } else {
      return 'Just now';
    }
  };

  function areArraysEqual(array1, array2) {
    // Check if arrays have the same length
    if (array1.length !== array2.length) {
      return false;
    }
  
    // Check each element in the arrays
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
  
    // If all elements match and lengths are the same, arrays are equal
    return true;
  }

  function areInsuranceArrayEqual(array1, array2) {
    // Check if arrays have the same length
    if (array1.length !== array2.length) {
      return false;
    }
  
    // Check each element in the arrays
    for (let i = 0; i < array1.length; i++) {
        const first = array1[i];
        const second = array2[i];
      if (first.insuranceID !== second.insuranceID || first.planID !== second.planID) {
        return false;
      }
    }
  
    // If all elements match and lengths are the same, arrays are equal
    return true;
  }

  const daysOfWeek = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat'
};
const months = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec'
};

const halfHourTimeIntervals = 
    [
      "12:00 AM",
      "12:30 AM",
      "1:00 AM",
      "1:30 AM",
      "2:00 AM",
      "2:30 AM",
      "3:00 AM",
      "3:30 AM",
      "4:00 AM",
      "4:30 AM",
      "5:00 AM",
      "5:30 AM",
      "6:00 AM",
      "6:30 AM",
      "7:00 AM",
      "7:30 AM",
      "8:00 AM",
      "8:30 AM",
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
      "5:30 PM",
      "6:00 PM",
      "6:30 PM",
      "7:00 PM",
      "7:30 PM",
      "8:00 PM",
      "8:30 PM",
      "9:00 PM",
      "9:30 PM",
      "10:00 PM",
      "10:30 PM",
      "11:00 PM",
      "11:30 PM"
    ]
    function convertTo24Hour(time12h) {
        // Parse the input time string
        const timeParts = time12h.split(':');
        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1].split(' ')[0], 10);
        const period = timeParts[1].split(' ')[1].toUpperCase(); // AM or PM
    
        // Adjust hours for AM/PM
        let hours24;
        if (period === 'AM') {
            hours24 = hours === 12 ? 0 : hours;
        } else {
            hours24 = hours === 12 ? 12 : hours + 12;
        }
    
        // Return the time as an integer in 24-hour format
        return hours24 * 100 + minutes;
    }

    const isValidImageUrl = (url) => {
        if (!url) return false; // If URL is empty, return false
        const img = new Image();
        img.src = url;
        return img.complete || (img.width + img.height) > 0; // Check if image loaded successfully
      };

    const isValidLongitude = (longitude) => {
    return !isNaN(longitude) && longitude >= -180 && longitude <= 180;
    };
    
    const isValidLatitude = (latitude) => {
    return !isNaN(latitude) && latitude >= -90 && latitude <= 90;
    };

    function isValidUUID(uuid) {
        const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return uuidPattern.test(uuid);
      }

    function groupByInsuranceID(insuranceArray) {
    return insuranceArray.reduce((acc, insurance) => {
        const insuranceID = insurance.insuranceID;
        if (!acc[insuranceID]) {
        acc[insuranceID] = [];
        }
        acc[insuranceID].push(insurance);
        return acc;
    }, {});
    }

    function isInsuranceAlreadyPresent(insuranceArray, insuranceID, planID) {
        return insuranceArray.some(insurance => 
          insurance.insuranceID === insuranceID && 
          insurance.planID === planID
        );
      }

    function validatePassword(password) {
    // Check for a length of at least 8 characters
    const isLengthValid = password.length >= 8;
    
    // Check for the presence of letters
    const hasLetters = /[a-zA-Z]/.test(password);
    
    // Check for the presence of numbers
    const hasNumbers = /\d/.test(password);
    
    // Check for the presence of special characters (@#$%&*.)
    const hasSpecialCharacters = /[@#$%&*]/.test(password);
    
    const passwordValid = isLengthValid && hasLetters && hasNumbers && hasSpecialCharacters
    return {
        isLengthValid,
        hasLetters,
        hasNumbers,
        hasSpecialCharacters,
        passwordValid,
    };
    }

    function isDayOfWeekIncluded(dayInt, date) {
        // Validate input
        console.log("dayInt ", dayInt)
        if(dayInt === 7) {
            return false
        }
        
        const dayCorrectionMapping = {
            "0": 6,
            "1": 0,
            "2": 1,
            "3": 2,
            "4": 3,
            "5": 4,
            "6": 5,
        }

        if (dayInt < 0 || dayInt > 7654321 || !Number.isInteger(dayInt)) {
          return false
        }
      
        // Convert the integer to a string and split it into an array of digits
        const digitsArray = Array.from(String(dayInt), Number);
      
        // Get the day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
        const dayOfWeek = dayCorrectionMapping[date.getDay()];
      
        // Check if the day of the week matches any of the digits in the array
        return digitsArray.includes(dayOfWeek);
      }
      function truncateText(text, maxLength) {
      
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...'; // Truncate the string and add ellipsis
          }
        
          return text; // Return the original text if it's within the maximum length
      }
      const languages = [
        "Arabic",
        "Mandarin",
        "Cantonese",
        "Dutch",
        "English",
        "Farsi",
        "French",
        "German",
        "Greek",
        "Hebrew",
        "Hindi",
        "Italian",
        "Japanese",
        "Korean",
        "Polish",
        "Portuguese",
        "Russian",
        "Spanish",
        "Tagalog",
        "Thai",
        "Vietnamese"
    ]

    const genders = [
        "Male",
        "Female",
        "Other"
    ]

    function formatPhoneNumber5(phoneNumber) {
        // Ensure the phone number starts with '+1'
        if (!phoneNumber.startsWith('+1')) {
          throw new Error('The phone number must be in E.164 format and start with +1');
        }
      
        // Remove the '+1' prefix and get the rest of the digits
        const digits = phoneNumber.slice(2); // After '+1'
      
        // Extract the area code (first 3 digits), central office code (next 3), and line number (last 4)
        const areaCode = digits.slice(0, 3);
        const centralOfficeCode = digits.slice(3, 6);
        const lineNumber = digits.slice(6, 10);
      
        // Format the phone number as (XXX) XXX-XXXX
        return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
      }
export {
    ensureHttpsWww,
    formatPhoneNumber5,
    genders,
    languages,
    truncateText,
    isDayOfWeekIncluded,
    formatWebsiteLink,
    validatePassword,
    isInsuranceAlreadyPresent,
    groupByInsuranceID,
    isValidUUID,
    isValidLongitude,
    isValidLatitude,
    isValidImageUrl,
    convertTo24Hour,
    halfHourTimeIntervals,
    daysOfWeek,
    months,
    areInsuranceArrayEqual,
    areArraysEqual,
    timeAgo,
    replaceObjectByExternalId,
    sumTotalSlots,
    getHospitalPromotions,
    convertUTCToLocal,
    getClinicProfilePicture,
    getDoctorProfilePicture,
    isInBetween,
    formatDateUTCKey,
    extractTimeInfo2,
    isSlotExpired,
    getUserTimeZone,
    getMidnightUTCTimestamp,
    combineDateAndTimeInTimezone,
    formatDateToLongString,
    findObjectById,
    generateTimeSlots,
    convertMinutesToHoursAndMinutes,
    generateTimeSlotsSchedule,
    parseTime,
    formatTimeMinutes,
    cleanUpTimeSlots,
    parseTimeToDateTime,
    convertToLocalTimeToUTC,
    getStartOfWeek,
    getDaysOfWeek,
    placeAppointmentsInCalendar,
    isDateBetween,
    printTimeAMPM,
    convertTo12HourFormat,
    findLocationById,
    replaceByID,
    findDoctorById,
    timeStringToDate,
    formatTimestampTo12HourClock,
    formatPhoneNumber,
    printDateNicely,
    convertTimestampsToTime,
    getAvailableSlots,
    formatTimeDate,
    isValidPassword,
    getLast4DigitsAsString,
    formatPhoneNumberBooking,
    formatTimestampToISO,
    countRatings,
    getCombinedName,
    getInsuranceById,
    getPlanByIds,
    getTimeAsNumber,
    setMidnight,
    findObjectByInternalId,
    convertDateStringToDateObject,
    getWeekAndNextWeek,
    getStartOfWeekAndEndOfNextWeek,
    formatTimestamp,
    extractTimeInfo3,
    isDateBeforeToday,
    convertTimeToInt,
    usStatesAbbreviations,
    areDatesEqual,
    convertTo12HourFormatFromSlot,
    setTimeFromDate,
    getPlanName,
    isDateInRange,
    formatPhoneNumber2,
    isTimeInArray,
    findMetricById,
    sumValuesByDate,
    centsToDollars,
    convertTo12HourFormatList,
    frequencyMap,
    getFirstDayOfGivenWeek,
    indexOfAvailableSlots,
    extractTimeInfo,
    formatPhoneNumber3,
    validateNPI,
    ensureHttps,
    areDatesEqualInUTC,
}