import React, { useState, useEffect, useRef } from 'react';
import { requestPaymentIntent } from '../../../../api/patientAPIs';
import { useNavigate } from "react-router-dom";
import LoadingSpinner from '../../patients/bookingv2/spinner';

const PaymentModal = ({ setPaymentModalOpen, accountData, currentHospitalID }) => {
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [returnUrl, setReturnUrl] = useState("");
    const [error, setError] = useState(null);
    const [isAdding, setisAdding] = useState(false)
    const [amount, setAmount] = useState(null);
    const navigate = useNavigate();

    const formatAmount = () => {
        // Ensure centAmount is a valid number
        const parsedCents = parseFloat(amount);
        if (isNaN(parsedCents)) {
            return ""
        }

        // Convert cents to dollars with two decimal places
        const dollars = (parsedCents / 100).toFixed(2);
        console.log(amount, dollars)
        return "$" + dollars;

    };
    function handleAmountInput(event) {
        const input = event.target;
        // Remove non-numeric characters from the input value
        input.value = input.value.replace(/[^0-9]/g, '');
        setAmount(event.currentTarget.value)
      }

    const handleSubmit = () => {
        const total = (parseFloat(amount));
        if (total < 1000) {
            setError("The minimum amount you can add to your account is $10.");
            return;
        }
        setisAdding(true)
        requestPaymentIntent(total).then(response => {
            navigate("/payment", { state: { client_key: response.data.client_key, amount: total, accountData: accountData, currentHospitalID: currentHospitalID } });
            setisAdding(false)
        }).catch(error => {
            console.error("Error requesting payment intent:", error);
            setError("Error requesting payment intent. Please try again.");
        });
    };

    return (
        <div className="payment-modal-overlay">
            <div className="payment-modal">
                <h1>Add funds to your account</h1>
                <i class="fas fa-times payment-exit" onClick={() => setPaymentModalOpen(false)}></i>
                <div className='payment-modal-box'>
                    <h2>How much credit do you want to add to your account?</h2>
                    <h3>Type or select the amount below</h3>
                    <div className='d-flex flex-row justify-content-start align-items-center'>
                        <input
                            placeholder="$0.00"
                            value={formatAmount(amount)}
                            onChange={handleAmountInput}
                            className='email-input'
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                    <button
                        className="pay-balance-button"
                        onClick={() => handleSubmit()}
                    >
                        {isAdding ? <LoadingSpinner /> : "Continue to Payment"}
                    </button>
                    {error && <p class="payment-error-message text-danger">{error}</p>}
                </div>

            </div>
        </div>
    );
};

export default PaymentModal;