const distanceToMiles = {
    0: {
      "title": "50 miles",
      "km": 80.4672
    },
    1: {
      "title": "15 miles",
      "km": 24.1402
    },
    2: {
      "title": "25 miles",
      "km": 38.6243
    },
    3: {
      "title": "50 miles",
      "km": 80.4672
    },
    4: {
      "title": "5 miles",
      "km": 8.04672
    },
    5: {
      "title": "> 50 miles",
      "km": 10000
    },
  }

  const timeOfDay = {
    0:{
        "title": "Time of Day"
    },
    1:{
        "title": "Early morning"
    },
    2:{
        "title": "Morning"
    },
    3:{
        "title": "Afternoon"
    },
    4:{
        "title": "Evening"
    }
  }

  export {
    distanceToMiles,
    timeOfDay
}