import React, { useState, useEffect } from 'react';
import { achew_logo_main, achew_logo_side } from '../../home/image';
import { useNavigate } from 'react-router-dom';
const AchewHeader = ({backgroundColor}) => {
  const navigate = useNavigate()

  return (
    <div className="achew-header-container achew-header-container-booking">
        <img
          alt='Achew Logo'
          src={achew_logo_side}
          className='achew-header-logo'
          onClick={() => navigate("/")}
        />
        <div className="achew-header-link-container">
          <button className="achew-header-link-blue" onClick={() => navigate("")}>
            Help
          </button>
          <button className="achew-header-link-sign-up tablet-only" onClick={() => navigate("/doctor-login")}>
            Clinic Login
          </button>
        </div>
    </div>
  );
};

export default AchewHeader;