import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AchewHeader from '../../achew-header';
import AchewFooter from '../../achew-footer';
const BookingSuccess = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const hospitalData = location.state ? location.state.hospitalData : ""
    const currentDoctorData = location.state ? location.state.currentDoctorData : ""
    const appointmentTime = location.state ? location.state.appointmentTime : ""
    const [currentHospitalData, setCurrentHospitalData] = useState({})
    const [doctorData, setDoctorData] = useState({})
    useEffect(() => {
        console.log(typeof hospitalData)
        if(!hospitalData || hospitalData === "" || !currentDoctorData || currentDoctorData === ""){
            navigate('/error')
        } else {
            setCurrentHospitalData(JSON.parse(hospitalData))
            setDoctorData(JSON.parse(currentDoctorData))
        }
  }, []);
  return (
    <div className="booking-success-supercontainer">
      <AchewHeader />

      <div className="booking-success-container" >
          <div className="success-cont">
            <div className='d-flex flex-row align-items-center'>
                <i className="fas fa-check"></i>
                <h3>Your appointment has been requested.</h3>
            </div>
            <p>You have requested an appointment with <b>Dr.{doctorData.doctor_name_first} {doctorData.doctor_name_last}</b> at <b>{currentHospitalData.hospital_name}</b>. <br /> <br />If the request is approved by the clinic, the appointment will take place on <b>{appointmentTime}</b>.<br /> You can monitor the status and manage your appointment by clicking below.</p>
            <button onClick={() => navigate(`/patient-appointment?page_num=1`)}>View Status</button>
          </div>
      </div>
    </div>
  );
};

export default BookingSuccess;