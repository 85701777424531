import { createSlice } from '@reduxjs/toolkit';

export const hospitalSlice = createSlice({
    name: 'hospital',
    initialState: {
        hospital_name: null,
        hospital_email: null,
        hospital_specialty: null,
        hospital_website: null,
        location: null,
        doctor:null,
        npi: null,
    },
    reducers: {
        setHospitalNameRedux: (state, action) => {
            state.hospital_name = action.payload;
        },
        setHospitalEmailRedux: (state, action) => {
            state.hospital_email = action.payload;
        },
        setHospitalSpecialtyRedux: (state, action) => {
            console.log(action.payload)
            state.hospital_specialty = action.payload;
        },
        setHospitalWebsiteRedux: (state, action) => {
            state.hospital_website = action.payload;
        },
        setHospitalLocationRedux: (state, action) => {
            console.log(action.payload)
            state.location = action.payload;
        },
        setHospitalDoctorRedux: (state, action) => {
            state.doctor = action.payload;
        },
        setHospitalNPIRedux: (state, action) => {
            state.npi = action.payload;
        },
        resetHospitalSlice: (state, action) => {
            state.hospital_name = null
            state.hospital_email = null
            state.hospital_specialty = null
            state.hospital_website = null
            state.location = null
            state.doctor = null
            state.npi = null
        }
    },
});

// Action creators are generated for each case reducer function
export const {
    setHospitalNameRedux,
    setHospitalEmailRedux,
    setHospitalSpecialtyRedux,
    setHospitalWebsiteRedux,
    setHospitalLocationRedux,
    setHospitalDoctorRedux,
    setHospitalNPIRedux,
    resetHospitalSlice
} = hospitalSlice.actions;

export default hospitalSlice.reducer;
