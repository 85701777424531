import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import DoctorHeader from "../doctorheader";
import DoctorSideBar from "./dashboardsidebar";
import specialtiesData from '../../../../sampledata/specialties.json'
import { refreshToken } from "../../../../api/auth";
import {
    getAccountInfo,
    getAppointmentByID,
    getHospitalInfoByInternalID,
} from "../../../../api/patientAPIs";
import { useDispatch, useSelector } from "react-redux";
import { setAccountInfo } from "../../../../hooks/accountSlice";
import ActionConfirmationModal from './patient-modals/action-confirmation-modal'
import RescheduleAppointmentModal from './patient-modals/reschedule-appointment-modal';
import ReactModal from 'react-modal';
import { findObjectById } from '../../../assets/functions/functions';
import Loading from '../../loading';
const Appointment = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams(location.search);
    const currentHospitalID = searchParams.get("hospital");
    const currentAppointmentID = searchParams.get("appointment")
    const [hospitalData, setHospitalData] = useState(null);
    const [currentHospitalData, setCurrentHospitalData] = useState({
        organization: "",
    });
    const [selectedRescheduleDate, setSelectedRescheduleDate] = useState(null)
    const [currentAppointmentData, setCurrentAppointmentData] = useState({
        "id": 1,
        "created": "2023-09-17T04:11:54.224020Z",
        "h_id": "1",
        "patient_info": {
            "patient_name_first": "",
            "patient_name_last": "",
            "dob": "2023-09-28T06:00:00Z",
            "email": "",
            "phone": "",
            "reason_visit": "",
            "insurance": "",
            "plan": ""
        },
        "date": "2023-09-28T06:00:00Z",
        "status": "",
        "price": 0.0,
        "reminders_sent": 0,
        "insurance": "",
        "id_card": "",
        "last_updated": "2023-08-28T06:00:00Z",
        "isNewPatient": true,
        "appointment_result": "",
        "timeline": {}
    },)
    const [didFinishedLoading, setDidFinishingLoading] = useState(false)
    const [isActionModalOpen, setActionModelOpen] = useState(false)
    const [isRescheduleModalOpen, setRescheduleModalOpen] = useState(false)
    const [actionModalType, setActionModalType] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0);
        getAccountInfo().then((res) => {
            if (res.status == 200) {
                dispatch(setAccountInfo(res.data));
                const hospitalList = res.hospital;

                var promises = [];
                hospitalList.forEach((hID) => {
                    promises.push(getHospitalInfoByInternalID(hID));
                });

                Promise.all(promises).then((results) => {
                    const tempHospitalList = [];
                    results.forEach((res) => tempHospitalList.push(res.data));
                    setHospitalData(tempHospitalList);
                    const tempHospitalData = findObjectById(currentHospitalID, tempHospitalList)
                    setCurrentHospitalData(
                        tempHospitalData
                    );
                    getAppointmentByID(currentAppointmentID, currentHospitalID).then(aptResponse => {
                        setCurrentAppointmentData(aptResponse.data)
                        setDidFinishingLoading(true)
                        console.log(aptResponse.data)
                    })
                });
            }

            if (res.status == 401 || res.status == 403) {
                refreshToken().then((response) => {
                    if (response.status == 200) {
                        console.log("Successfully refreshed token");
                        window.location.reload();
                    } else {
                        navigate("/doctor-login");
                    }
                });
            }
        });

    }, []);

    const renderEmailStatus = () => {
        if (currentAppointmentData.status == "PENDING") {
            return (
                <div className='appointment-email-status-container appointment-email-status-container-pending'>
                    <div className='appointment-status-circle-icon appointment-status-circle-icon-pending'>
                        <i className="fa fa-exclamation"></i>
                    </div>
                    <h1>An email will be sent when you accept or decline the appointment</h1>
                </div>
            )
        }
        if (currentAppointmentData.status == "CONFIRMED") {
            return (
                <div className='appointment-email-status-container appointment-email-status-container-confirmed'>
                    <div className='appointment-status-circle-icon appointment-status-circle-icon-confirmed'>
                        <i className="fa fa-check"></i>
                    </div>
                    <h5 className='appointment-email-status-container-confirmedtxt'>An email with appointment details has been sent to the patient</h5>
                </div>
            )
        }
    }

    const renderAppointmentHeader = () => {
        if (currentAppointmentData.status == "PENDING") {
            return (
                <div className='appointment-booked-header'>
                    <div className='d-flex'>
                        <div className="appointment-booked-circle-icon appointment-booked-circle-icon-pending">
                            <i className="fa fa-exclamation"></i>
                        </div>
                        <div className='appointment-booked-header-txt '>
                            <h1 className='appointment-booked-header-txt-pending'>Appointment Pending</h1>
                            <h2 className='appointment-booked-header-txt-pending'>The patient is awaiting your reply.</h2>
                        </div>
                    </div>
                </div>
            )
        }
        if (currentAppointmentData.status == "DECLINED") {
            return (
                <div className='appointment-booked-header'>
                    <div className='d-flex'>
                        <div className="appointment-booked-circle-icon appointment-booked-circle-icon-declined">
                            <i className="fas fa-ban"></i>
                        </div>
                        <div className='appointment-booked-header-txt'>
                            <h1 className='appointment-booked-header-txt-declined'>Appointment Declined</h1>
                            <h2 className='appointment-booked-header-txt-declined'>The patient is awaiting your reply.</h2>
                        </div>
                    </div>
                </div>
            )
        }
        if (currentAppointmentData.status == "CONFIRMED") {
            return (
                <div className='appointment-booked-header'>
                    <div className='d-flex'>
                        <div className="appointment-booked-circle-icon appointment-booked-circle-icon-confirm">
                            <i className="fa fa-check"></i>
                        </div>
                        <div className='appointment-booked-header-txt'>
                            <h1 className='appointment-booked-header-txt-confirm'>Appointment Confirmed!</h1>
                            <h2 className='appointment-booked-header-txt-confirm'>The patient is awaiting your reply.</h2>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const renderAcceptAppointment = () => {
        if(currentAppointmentData.status == "CONFIRMED") {
            return(<div className='appointment-accepted-btn'>APPOINTMENT ACCEPTED</div>)
        }
        if(currentAppointmentData.status == "PENDING") {
            return(<button className='appointment-action-btn' onClick={() => {setActionModelOpen(true); setActionModalType("ACCEPT")}}>Accept Appointment</button>)
        }
        if(currentAppointmentData.status == "CONFIRMED") {
            return null
        }
    }

    const renderRescheduleAppointmentModal = () => {
        let heightOffset = 100 / 2;
        let offsetPx = heightOffset + "px";
      
        const style = {
          content: {
            border: "0",
            borderRadius: "4px",
            bottom: "auto",
            left: "50%",
            position: "fixed",
            right: "auto",
            top: "10%", // start from center
            transform: "translate(-50%,-" + offsetPx + ")", // adjust top "up" based on height
            width: "33%",
            height: "90%",
            zIndex: "1000"
          },
          overlay: {
            backgroundColor: "rgb(0,0,0,0.5)",
            overflowY: "auto",
            zIndex: "1000"
          },
        };
        return (
          <div>
            <ReactModal
              isOpen={isRescheduleModalOpen}
              style={style}
            >
              <RescheduleAppointmentModal
                appointmentModalData={currentHospitalData} 
                closeAppointmentModal={() => setRescheduleModalOpen(false)} 
                appointment={currentAppointmentData}
                setActionModelOpen={setActionModelOpen}
                setActionModalType={setActionModalType}
                setSelectedRescheduleDate={setSelectedRescheduleDate}
              />
            </ReactModal>
          </div>
        );
      };

    if(!didFinishedLoading){
        return(<Loading/>)
    }

    return (
        
        <div className='appointment-grey-supercontainer'>
            {renderRescheduleAppointmentModal()}
            <DoctorHeader
                hospitalData={hospitalData}
                currentHospitalData={currentHospitalData}
            />
            <DoctorSideBar
                currentTab={1}
                hospitalData={hospitalData}
                currentHospitalData={currentHospitalData}
            />
           
            <div className='appointment-container'>
                
            {isActionModalOpen && <ActionConfirmationModal onRequestClose={() => setActionModelOpen(false)} appointmentData={currentAppointmentData} modalType={actionModalType} selectedRescheduleDate={selectedRescheduleDate}/>}
                <div className='appointment-left-container'>
                    <div className='appointment-booked-container'>
                        {renderAppointmentHeader()}
                        <div className='appointment-patientinfo-group'>
                            <div className='appointment-patientinfo'>
                                <h1>Patient info:</h1>
                                <h2>{currentAppointmentData.patient_info.patient_name_first} {currentAppointmentData.patient_info.patient_name_last}</h2>
                                <h3><i className="fa fa-envelope"></i>  {currentAppointmentData.patient_info.email}</h3>
                                <h4><i className="fa fa-phone"></i>  {currentAppointmentData.patient_info.phone}</h4>
                                {renderEmailStatus()}
                            </div>
                            {renderAcceptAppointment()}
                        </div>
                        <div className='appointment-appointmentinfo-group'>
                            <div className='appointment-appointmentinfo'>
                                <h1>Booking details:</h1>
                                <h2>{currentAppointmentData.reason_visit} reason</h2>
                                <h3>{currentAppointmentData.patient_info.insurance} {currentAppointmentData.patient_info.plan}</h3>
                            </div>
                        </div>
                        <div className='appointment-appointmentinfo-group'>
                            <div className='appointment-appointmentinfo'>
                                <h1>Date & Time</h1>
                                <h2><i className="fa fa-calendar"></i>{(new Date(currentAppointmentData.date)).toLocaleString()}</h2>
                            </div>
                            <button className='appointment-reschedule-btn' onClick={() => {setRescheduleModalOpen(true)}}>Reschedule</button>
                        </div>
                    </div>
                    {currentAppointmentData.status != "DECLINED" && <button className='appointment-cancel-btn' onClick={() => {setActionModelOpen(true); setActionModalType("DECLINE")}}>Cancel appointment</button>}
                </div>

                <div className='appointment-right-container'>
                    <div className='appointment-location-container'>
                        <div className='appointment-location-header-container'>
                            <h1>Appointment location</h1>
                        </div>
                        <div className='appointment-location-content-container'>
                            <h2>Hospital name</h2>
                            <h4>{currentHospitalData.hospital_name}</h4>

                            <h2>Address</h2>
                            <h3>420 Elizabeth Ave Unit B</h3>
                            <h3>Monterey Park, CA</h3>
                            <h3>91755</h3>
                        </div>
                    </div>

                    <div className='appointment-help-container'>
                        <div className='appointment-help-content-container'>
                            <div className='d-flex'>
                                <div className='appointment-help-circle-icon'>
                                    <i className="fa fa-calendar"></i>
                                </div>
                                <div>
                                    <h2>Have questions? Contact us</h2>
                                    <h4>Call 190290509 or email us at jasonzhao1256@gmail.com for support</h4>
                                    <button className='appointment-contactinfo-btn'>Contact info</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Appointment;