import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AchewHeader from '../achew-header';
import AchewFooter from '../achew-footer';
import Privacy from '../../assets/support/private-policy.txt';
import FAQ from '../../assets/support/faqs.json';
import { calling_icon, message_icon } from './image';
import TermsAndAgreements from './terms-and-conditions';
import PrivacyPolicy from './privacy-policy';
import AboutAchew from './about-achew';

const Support = () => {
    const navigate = useNavigate();
    const [termsContent, setTermsContent] = useState('');
    const [privacyContent, setPrivacyContent] = useState('');
    const [selected, setSelected] = useState(null);
    const tab = useParams();
    const tabname = tab.tabname

    useEffect(() => {
        fetch(Privacy)
            .then((r) => r.text())
            .then((text) => {
                setPrivacyContent(text);
            });
    }, []);

    const changeTab = (newTab) => {
        navigate(`/support/${newTab}`);
    };

    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null);
        }
        setSelected(i);
    }

    const AboutContent = () => {
        return (
            <div className="support-terms-container">
                <AboutAchew/>
            </div>
        );
    };

    const TermsContent = () => {
        return (
            <div className="support-terms-container">
                <TermsAndAgreements/>
            </div>
        );
    };

    const PrivacyContent = () => {
        return (
            <div className="support-terms-container">
                <PrivacyPolicy/>
            </div>
        );
    };

    const FAQContent = () => {
        const faqs = FAQ.faqs || [];

        return (
            <div className='support-faqs-container'>
                <h1>Frequently Asked Questions</h1>
                {faqs.map((faq, index) => (
                    <div
                        key={index}
                        className={`support-faqs-item ${index === 0 ? 'first-item' : ''} ${index === faqs.length - 1 ? 'last-item' : ''} ${index === selected ? 'selected-item' : ''}`}
                        onClick={() => toggle(index)}
                    >
                        <div className='support-faqs-question'>
                            <h2>
                                {faq.question}
                            </h2>
                            <span>{selected === index ? '-' : '+'}</span>
                        </div>
                        <div className={selected === index ? 'support-faqs-answer show' : 'support-faqs-answer'}>
                            {faq.answer}
                        </div>
                    </div>
                ))}
            </div>
        )
    };

    const ContactContent = () => {
        return (
            <div className="support-contact-container">
                <h1>Contact Us</h1>
                <div className='support-contact-row'>
                    <div className="support-contact-icon flex-row">
                        <img alt="Achew Logo" src={calling_icon} />
                    </div>
                    <div className='support-contact-info'>
                        <h2>Phone:</h2>
                        <h3>+1 626-788-0644</h3>
                    </div>
                </div>
                <div className='support-contact-row'>
                    <div className="support-contact-icon flex-row">
                        <img alt="Achew Logo" src={message_icon} />
                    </div>
                    <div className='support-contact-info'>
                        <h2>Email:</h2>
                        <h3>info@achew.net</h3>
                    </div>
                </div>
            </div>
        );
    };

    const renderContent = () => {
        switch (tabname) {
            case 'about':
                return <AboutContent />;
            case 'terms':
                return <TermsContent />;
            case 'private-policy':
                return <PrivacyContent />;
            case 'faqs':
                return <FAQContent />;
            case 'contact':
                return <ContactContent />
            default:
                return null;
        }
    };

    return (
        <div className="support-supercontainer">
            <AchewHeader />
            <div className="support-container">
                <div className='support-buttons-container tablet-only'>
                    <button onClick={() => changeTab('about')} className={tabname === 'about' ? 'active' : ''}>About</button>
                    <button onClick={() => changeTab('terms')} className={tabname === 'terms' ? 'active' : ''}>Terms and Conditions</button>
                    <button onClick={() => changeTab('private-policy')} className={tabname === 'private-policy' ? 'active' : ''}>Privacy Policy</button>
                    <button onClick={() => changeTab('faqs')} className={tabname === 'faqs' ? 'active' : ''}>FAQs</button>
                    <button onClick={() => changeTab('contact')} className={tabname === 'contact' ? 'active' : ''}>Contact Us</button>
                </div>
                {renderContent()}
            </div>
            <AchewFooter />
        </div>
    );
};

export default Support;
