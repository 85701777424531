import React, { useState, useEffect, useRef } from "react";
import Alert from "../../alert";
import "react-toggle/style.css"
import { useNavigate, useLocation } from "react-router-dom";
import DoctorHeader from "../doctorheader";
import DoctorSideBar from "./dashboardsidebar";
import { refreshToken } from "../../../../api/auth";
import {
  getAccountInfo,
  getDownloadLinkFromPresignedURL,
  getHospitalInfoByInternalID,
  getIntake,
  getIntakeByToken,
  getIntakeMetric,
  queryIntake,
} from "../../../../api/patientAPIs";
import { useDispatch } from "react-redux";
import { setAccountInfo } from "../../../../hooks/accountSlice";
import Loading from '../../loading';
import { findObjectById, formatDateToLongString } from "../../../assets/functions/functions";
import PopupAlert from "./popup-alert";
import LoadingSpinner from "../../patients/bookingv2/spinner";
import CreateIntakeModal from "./patient-modals/create-intake-modal";
import IntakeActionDropdown from "./intake-action-dropdown";
import Pagination from "../../search/pagination";
import LoaderWrapper from "../../loader-wrapper";
import { intake_placeholder } from "../../home/image";
import CreditWarningPopup from "./credit-warning-popup";
import DownloadButton from "./components/download-button";
const { S3Client } = require('@aws-sdk/client-s3');
const { getSignedUrl } = require('@aws-sdk/s3-request-presigner');
const { GetObjectCommand, ListObjectsV2Command } = require('@aws-sdk/client-s3');

const Intake = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const currentHospitalID = searchParams.get("hospital");
  const [hospitalData, setHospitalData] = useState(null);
  const [accountData, setAccountData] = useState(null)
  const [currentHospitalData, setCurrentHospitalData] = useState({
    hospital_name: "",
    services: [],
  });
  const [isPageLoading, setPageLoading] = useState(true)
  const [isCreateIntakeModalOpen, setCreateIntakeModalOpen] = useState(false)

  const [intakeData, setIntakeData] = useState([])
  const [intakeDataWithQuery, setIntakeDataWithQuery] = useState([])
  const [intakeSearchText, setIntakeSearchText] = useState("")
  const [isQueryingIntake, setIsQueryingIntake] = useState(false)
  const [intakeMetric, setIntakeMetrics] = useState({})

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [refresh, setRefresh] = useState(false)
  const [isRefreshingIntakePage, setIsRefreshingIntakePage] = useState(false)

  //Alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("")
  const [creditWarningMessageOpen, setCreditWarningMessageOpen] = useState(false)

  //Query Pagination
  const [currentPageQuery, setCurrentPageQuery] = useState(1);
  const [numPagesQuery, setNumPagesQuery] = useState(1);
  const [refreshQuery, setRefreshQuery] = useState(false)

  //AWS Config
  const region = process.env.REACT_APP_S3_REGION; // e.g., 'us-west-2'
  const bucketName = process.env.REACT_APP_S3_PATIENTBUCKETNAME;
  const expiresInSeconds = 300; // Time for which the pre-signed URL is valid, e.g., 300 seconds
  const s3Client = new S3Client({
    region,
    credentials: {
      accessKeyId: process.env.REACT_APP_S3_ACCESSKEYID,
      secretAccessKey: process.env.REACT_APP_S3_SECRETACCESSKEY,
    }
  });

  useEffect(() => {
    setIsRefreshingIntakePage(true)
    getAccountInfo().then((res) => {
      if (res.status == 200) {
        dispatch(setAccountInfo(res.data));
        setAccountData(res)
        const hospitalList = res.hospital;
        var promises = [];
        hospitalList.forEach((hID) => {
          promises.push(getHospitalInfoByInternalID(hID));
        });

        Promise.all(promises).then((results) => {
          const tempHospitalList = [];
          results.forEach((res) => tempHospitalList.push(res.data));
          setHospitalData(tempHospitalList);
          const tempHospitalData = findObjectById(currentHospitalID, tempHospitalList)
          setCurrentHospitalData(tempHospitalData);
          setPageLoading(false)
        });
        const pageNum = searchParams.get("page_num");

        getIntake(currentHospitalID, pageNum).then(intakeResult => {
          console.log(intakeResult)
          setCurrentPage(intakeResult.data.currentPage);
          setNumPages(intakeResult.data.lastPage);
          setIntakeData(intakeResult.data.results)
          setIsRefreshingIntakePage(false)
        }).catch(err => {
          console.log(err)
        })

        getIntakeMetric(currentHospitalID).then(metricResult => {
          setIntakeMetrics(metricResult.data)
          console.log(metricResult.data)
        }).catch(err => {
          console.log(err)
        })
      }

      if (res.status == 401 || res.status == 403) {
        refreshToken().then((response) => {
          if (response.status == 200) {
            console.log("Successfully refreshed token");
            window.location.reload();
          } else {
            navigate("/doctor-login");
          }
        });
      }
    });

  }, [refresh]);

  const handleRequestPatientDocClicked = () => {
    if(accountData.credits >= 200) {
      setCreateIntakeModalOpen(true)
    } else {
      setCreditWarningMessageOpen(true)
    }
  }

  const renderIntakeStatus = (status) => {
    if (status === "pending") {
      return (
        <div className="intake-status pending">
          Pending
        </div>
      )
    } else if (status === "completed") {
      return (
        <div className="intake-status accepted">
          Completed
        </div>
      )
    } else {
      return (
        <div className="intake-status declined">
          Cancelled
        </div>
      )
    }
  }

  const renderIntakeRow = (intake) => {
    return (
      <tr>
        <td>
          <h1>{intake.patient_name_first} {intake.patient_name_middle} {intake.patient_name_last}</h1>
          <h2>Requested: {formatDateToLongString(intake.created)}</h2>
        </td>
        <td>
          <h1>{intake.phone}</h1>
          <h2>{intake.email}</h2>
        </td>

        <td>
          {
            intake.status === "completed" && intake.did_upload_insurance ?
              <>
                <DownloadButton onDownload={(setDownloadProgress) => handleDownloadDocument(
                  `hospitals/${currentHospitalData.external_id}/intake/${intake.external_id}/${intake.patient_name_first}-${intake.patient_name_last}-Insurance_Card_Front.jpg`,
                  `hospitals/${currentHospitalData.external_id}/intake/${intake.external_id}/${intake.patient_name_first}-${intake.patient_name_last}-Insurance_Card_Back.jpg`,
                  `${intake.patient_name_first}-${intake.patient_name_last}-Insurance_Card.pdf`,
                  setDownloadProgress
                )}/>
              </>
              :
              <>
                {intake.status === "pending" ? <h1>Pending upload</h1> : <h1>No upload</h1>}
              </>
          }

        </td>

        <td>
          {
            intake.status === "completed" && intake.did_upload_id ?
              <>
                <DownloadButton onDownload={(setDownloadProgress) => handleDownloadDocument(
                  `hospitals/${currentHospitalData.external_id}/intake/${intake.external_id}/${intake.patient_name_first}-${intake.patient_name_last}-ID_Card_Front.jpg`,
                  `hospitals/${currentHospitalData.external_id}/intake/${intake.external_id}/${intake.patient_name_first}-${intake.patient_name_last}-ID_Card_Back.jpg`,
                  `${intake.patient_name_first}-${intake.patient_name_last}-ID_Card.pdf`,
                  setDownloadProgress
                )}/>
              </>
              :
              <>
                {intake.status === "pending" ? <h1>Pending upload</h1> : <h1>No upload</h1>}
              </>
          }
        </td>

        <td>
          {renderIntakeStatus(intake.status)}
        </td>

        <td>
          <IntakeActionDropdown currentHospitalData={currentHospitalData} />
        </td>

      </tr>
    )
  }

  const renderIntakeData = () => {
    if ((!intakeSearchText || intakeSearchText.length === 0)) {
      return (
        intakeData.map(intake => renderIntakeRow(intake))
      )
    } else {
      if (!isQueryingIntake) {
        return (
          intakeDataWithQuery.map(intake => renderIntakeRow(intake))
        )
      }

    }
  }

  const toggleAlertWithMessage = (message) => {
    setAlertMessage(message)
    setShowAlert(true)
  }

  const handleNewIntakeQuery = (e) => {
    setIsQueryingIntake(true)
    const query = e.currentTarget.value
    setIntakeSearchText(query)
    queryIntake(currentHospitalID, query, 1).then(result => {

      setIsQueryingIntake(false)
      setIntakeDataWithQuery(result.data.results)
      setCurrentPageQuery(1)
      setNumPagesQuery(result.data.lastPage)
    }).catch(err => {
      console.log(err)
      setIsQueryingIntake(false)
      setIntakeDataWithQuery([])
    })
  }

  const handleChangeIntakeQueryPage = (page) => {
    setCurrentPageQuery(page)
    setIsQueryingIntake(true)
    queryIntake(currentHospitalID, intakeSearchText, page).then(result => {

      setIsQueryingIntake(false)
      setIntakeDataWithQuery(result.data.results)
      setCurrentPageQuery(page)
      setNumPagesQuery(result.data.lastPage)
    }).catch(err => {
      console.log(err)
      setIsQueryingIntake(false)
      setIntakeDataWithQuery([])
    })
  }

  const handleDownloadDocument = async (objectKey1, objectKey2, fileName, setDownloadProgress) => {
    console.log(objectKey1, objectKey2)
    const command1 = new GetObjectCommand({
      Bucket: bucketName,
      Key: objectKey1,
    });

    const presignedURL1 = await getSignedUrl(s3Client, command1, {
      expiresIn: expiresInSeconds,
    });

    setDownloadProgress(0.1)

    if (!presignedURL1) return;

    const command2 = new GetObjectCommand({
      Bucket: bucketName,
      Key: objectKey2,
    });

    const presignedURL2 = await getSignedUrl(s3Client, command2, {
      expiresIn: expiresInSeconds,
    });

    setDownloadProgress(0.2)

    if (!presignedURL2) return;

    const pdfDownloadURL = await getDownloadLinkFromPresignedURL(presignedURL1, presignedURL2, setDownloadProgress)
    const simulatedLink = document.createElement('a');
    simulatedLink.style.display = 'none';
    simulatedLink.href = pdfDownloadURL;
    simulatedLink.download = fileName;
    document.body.appendChild(simulatedLink);

    simulatedLink.click();

    document.body.removeChild(simulatedLink);
    URL.revokeObjectURL(pdfDownloadURL);
  };


  if (isPageLoading) {
    return (<Loading />)
  }

  return (
    <div className="intake-supercontainer">
      <CreditWarningPopup isOpen={creditWarningMessageOpen} onClose={()=>setCreditWarningMessageOpen(false)}/>
      <PopupAlert showAlert={showAlert} setShowAlert={setShowAlert} alertMessage={alertMessage} />
      <DoctorHeader
        hospitalData={hospitalData}
        currentHospitalData={currentHospitalData}
      />
      <DoctorSideBar
        currentTab={3}
        hospitalData={hospitalData}
        currentHospitalData={currentHospitalData}
      />
      {isCreateIntakeModalOpen && <CreateIntakeModal currentHospitalData={currentHospitalData} onRequestClose={setCreateIntakeModalOpen} toggleAlertWithMessage={toggleAlertWithMessage} />}
      <div className="intake-container">
        <div className="intake-stats-list">
          <div className="intake-stats-container">
            <i className="intake-stats-image fas fa-chart-bar"></i>
            <div className="intake-stats-txt">
              <h1>Total Requests</h1>
              <h2>{intakeMetric.total}</h2>
            </div>
          </div>

          <div className="intake-stats-container">
            <i className="intake-stats-image fas fa-clock" style={{color: "#f9d831", backgroundColor:"#FAF1C3"}}></i>
            <div className="intake-stats-txt">
              <h1>Pending Requests</h1>
              <h2>{intakeMetric.pending}</h2>
            </div>
          </div>

          <div className="intake-stats-container">
            <i className="intake-stats-image fas fa-check" style={{color: "#00e541", backgroundColor:"#AAF7C0"}}></i>
            <div className="intake-stats-txt">
              <h1>Completed Requests</h1>
              <h2>{intakeMetric.completed}</h2>
            </div>
          </div>

        </div>
        <div className="intake-searchbar-container">
          <div className="intake-search-bar">
            <i className="fas fa-search"></i>
            <input
              type="text"
              value={intakeSearchText}
              onChange={handleNewIntakeQuery}
              placeholder="Search example: 'completed ', 'pending', 'John Smith'"
            />
          </div>
          <button onClick={() => handleRequestPatientDocClicked()}>
            <i className="fas fa-plus"></i>
            Request patient docs
          </button>
        </div>
        {
          intakeData.length > 0 ?
            <LoaderWrapper isLoading={isRefreshingIntakePage || isQueryingIntake} message={"Getting your intakes..."}>
              <table className="intake-table">
                <thead>
                  <tr>
                    <th>Patient</th>
                    <th>Contact</th>
                    <th>Insurance</th>
                    <th>ID Card</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    renderIntakeData()
                  }
                </tbody>
              </table>
            </LoaderWrapper>
            :
            <div className="intake-placeholder-container">
              <img src={intake_placeholder} alt="No Intake" />
              <h2>Send patient document requests and save time at the office.</h2>
              <button onClick={() => setCreateIntakeModalOpen(true)}>
                Get Started
              </button>
            </div>
        }
        {
          intakeData.length > 0 &&
          <div className="intake-pagination-container">
                  {
                    !intakeSearchText || intakeSearchText.length === 0 ?
                      <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        numPages={numPages}
                        navLink={`/intake?hospital=${currentHospitalID}`}
                        refresh={refresh}
                        setRefresh={setRefresh}
                      />
                      :
                      <Pagination
                        currentPage={currentPageQuery}
                        setCurrentPage={setCurrentPageQuery}
                        numPages={numPagesQuery}
                        navLink={`/intake?hospital=${currentHospitalID}`}
                        alternativeNav={handleChangeIntakeQueryPage}
                        refresh={refreshQuery}
                        setRefresh={setRefreshQuery}
                      />
                  }
                </div>
        }
        
      </div>
    </div >
  );
};

export default Intake;
