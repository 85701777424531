import React, { useEffect, useCallback, useState, useRef } from "react";
import Header from "./header/search-bar.jsx";
import ReactModal from "react-modal";
import AppointmentModal from "../patients/appointment-modal/appointment-modal.jsx";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Doctor07,
  achew_logo_main,
  achew_logo_side,
  chevron_down,
  footer_logo,
  marker_icon,
} from "../home/image.jsx";
import IllnessesData from "../../../sampledata/illness.json";
import { searchHospital } from "../../../api/patientAPIs.js";
import { Loader } from "@googlemaps/js-api-loader";
import {
  getFirstDayOfGivenWeek,
  getStartOfWeekAndEndOfNextWeek,
  getStartWeekAndStartNextWeek,
  getWeekAndNextWeek,
} from "../../assets/functions/functions.js";
import Loading from "../loading.jsx";
import {
  distanceToMiles,
  timeOfDay,
} from "../../assets/functions/search-config.js";
import InsuranceFilter from "./insurance-filter.jsx";
import SearchResultsSearchBar from "./header/search-bar.jsx";
import DatePicker from "../patients/doctorprofile/mini-calendar/datepicker.jsx";
import { monthDictionary } from "../doctors/dashboard/prototype-calendar/helper-functions.jsx";
import Pagination from "./pagination.jsx";
import ClinicDetails from "./clinic-details.jsx";
import AchewFooter from "../achew-footer.jsx";
import { useMediaQuery } from "react-responsive";
import MobileDropdownBottomSheet from "../doctors/dashboard/patient-modals/mobile-dropdown-modal.jsx";
import FooterBottomSheetContent from "../../mobile-footer.jsx";
import SearchResultAdsense from "../adsense/search-result-adsense.jsx";

const Search = (props) => {
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const [isDateModalOpen, setDateModalOpen] = useState(false);
  const dateDropdownRef = useRef(null);
  const [isTimeModalOpen, setTimeModalOpen] = useState(false);
  const timeDropdownRef = useRef(null);
  const [isPhysicalModalOpen, setPhysicalModalOpen] = useState(false);
  const physicalDropdownRef = useRef(null);
  const [isSpecialtyModalVisible, setSpecialtyModalOpen] = useState(false);
  const specialtyDropdownRef = useRef(null);
  const [isDistanceModalOpen, setDistanceModalOpen] = useState(false);
  const distanceDropdownRef = useRef(null);
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const videoDropdownRef = useRef(null);
  const [isMoreModalOpen, setMoreModalOpen] = useState(false);
  const moreDropdownRef = useRef(null);
  const [isAppointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [appointmentModalData, setAppointmentModalData] = useState({});
  const [googleLoader, setGoogleLoader] = useState(null);
  const [isMapSticky, setIsMapSticky] = useState(false);
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const [isInsuranceModalOpen, setInsuranceModalOpen] = useState(false)
  const [isFooterModalOpen, setFooterModalOpen] = useState(false)

  const today = new Date();

  const [appointmentModalFirstDate, setAppointmentModalFirstDate] =
    useState(null);
  const [hospitalList, sethospitalList] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get("specialty");
  var latitudeParam = searchParams.get("latitude");
  latitudeParam = latitudeParam ? latitudeParam : "34.1397";
  var longitudeParam = searchParams.get("longitude");
  longitudeParam = longitudeParam ? longitudeParam : "-118.0353";
  var distanceParam = searchParams.get("distance");
  distanceParam = distanceParam ? distanceParam : "0"
  const timeOfDayParam = searchParams.get("timeOfDay");
  var insuranceParam = searchParams.get("insurance");
  const dateParam = searchParams.get("date");
  const [displayedDate, setDisplayedDate] = useState(
    getFirstDayOfGivenWeek(new Date(parseInt(dateParam)))
  );
  const planParam = searchParams.get("plan");
  const pageNumParam =
    searchParams.get("page_num") != null ? searchParams.get("page_num") : 1;
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [totalNumHosptials, setTotalNumHospitals] = useState(0);
  const [loadingNewSearch, setLoadingNewSearch] = useState(false);
  const [didRetrySearchWithGreaterDistance, setDidRetrySearchWithGreaterDistance] = useState(false)

  const [selectedDistance, setSelectedDistance] = useState(null);
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState(null);
  const [insurance, setInsurance] = useState(insuranceParam);
  const [plan, setPlan] = useState(planParam);
  const [selectedAddress, setSelectedAddress] = useState("");

  const [appointmentSlotDictionary, setAppointmentSlotDictionary] =
    useState(null);
  const { startOfWeek, endOfNextWeek } =
    getStartOfWeekAndEndOfNextWeek(displayedDate);

  useEffect(() => {
    handleNewDateSearch(dateParam ? parseInt(dateParam) : new Date().getTime());
    const insuranceParam = searchParams.get("insurance");
    const planParam = searchParams.get("plan");
    setInsurance(insuranceParam);
    setPlan(planParam);
    const handleClickOutsideDate = (event) => {
      if (
        dateDropdownRef.current &&
        !dateDropdownRef.current.contains(event.target) &&
        !isTabletOrMobile
      ) {
        setDateModalOpen(false);
      }
    };
    const handleClickOutsideTime = (event) => {
      if (
        timeDropdownRef.current &&
        !timeDropdownRef.current.contains(event.target) &&
        !isTabletOrMobile
      ) {
        setTimeModalOpen(false);
      }
    };
    const handleClickOutsidePhysical = (event) => {
      if (
        physicalDropdownRef.current &&
        !physicalDropdownRef.current.contains(event.target)
      ) {
        setPhysicalModalOpen(false);
      }
    };
    const handleClickOutsideSpecialty = (event) => {
      if (
        specialtyDropdownRef.current &&
        !specialtyDropdownRef.current.contains(event.target)
      ) {
        setSpecialtyModalOpen(false);
      }
    };
    const handleClickOutsideDistance = (event) => {
      if (
        distanceDropdownRef.current &&
        !distanceDropdownRef.current.contains(event.target) &&
        !isTabletOrMobile
      ) {
        setDistanceModalOpen(false);
      }
    };
    const handleClickOutsideVideo = (event) => {
      if (
        videoDropdownRef.current &&
        !videoDropdownRef.current.contains(event.target)
      ) {
        setVideoModalOpen(false);
      }
    };
    const handleClickOutsideMore = (event) => {
      if (
        moreDropdownRef.current &&
        !moreDropdownRef.current.contains(event.target)
      ) {
        setMoreModalOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutsideDate);
    window.addEventListener("click", handleClickOutsideTime);
    window.addEventListener("click", handleClickOutsidePhysical);
    window.addEventListener("click", handleClickOutsideSpecialty);
    window.addEventListener("click", handleClickOutsideDistance);
    window.addEventListener("click", handleClickOutsideVideo);
    window.addEventListener("click", handleClickOutsideMore);

    return () => {
      window.removeEventListener("click", handleClickOutsideDate);
      window.removeEventListener("click", handleClickOutsideTime);
      window.removeEventListener("click", handleClickOutsidePhysical);
      window.removeEventListener("click", handleClickOutsideSpecialty);
      window.removeEventListener("click", handleClickOutsideDistance);
      window.removeEventListener("click", handleClickOutsideVideo);
      window.removeEventListener("click", handleClickOutsideMore);
    };
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      if (mapContainerRef.current) {
        const { top } = mapContainerRef.current.getBoundingClientRect();
        if (top <= 0 && window.scrollY >= 100) {
          setIsMapSticky(true);
          return;
        }
        if (window.scrollY < 100) {
          setIsMapSticky(false);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNewDateSearch = (startDate) => {
    // navigate(
    //   `/search?q=${searchTerm}&distance=${distanceParam}&latitude=${latitudeParam}&longitude=${longitudeParam}&insurance=&date=${startDate}`
    // );

    setLoadingNewSearch(true);

    const timeOfDayIndex = parseInt(timeOfDayParam);
    if (timeOfDayIndex == null) {
      setSelectedTimeOfDay(0);
    } else if (
      timeOfDayIndex != 0 &&
      timeOfDayIndex != 1 &&
      timeOfDayIndex != 2 &&
      timeOfDayIndex != 3 &&
      timeOfDayIndex != 4
    ) {
      setSelectedTimeOfDay(3);
    } else {
      setSelectedTimeOfDay(timeOfDayIndex);
    }

    const distanceParamIndex = parseInt(distanceParam);
    if (distanceParam == null) {
      setSelectedDistance(0);
    } else if (
      distanceParamIndex != 0 &&
      distanceParamIndex != 1 &&
      distanceParamIndex != 2 &&
      distanceParamIndex != 3 &&
      distanceParamIndex != 4 &&
      distanceParamIndex != 5
    ) {
      setSelectedDistance(3);
    } else {
      setSelectedDistance(distanceParamIndex);
    }

    insuranceParam =
      insuranceParam && insuranceParam !== "null" ? insuranceParam : "";

    searchHospital(
      searchTerm,
      latitudeParam,
      longitudeParam,
      distanceToMiles[distanceParamIndex].km,
      pageNumParam,
      startDate,
      insuranceParam,
      true
    ).then((response) => {
      //set page number
      setCurrentPage(response[0].data.current_page);
      setNumPages(response[0].data.num_pages);
      setTotalNumHospitals(response[0].data.object_count);
      setDidRetrySearchWithGreaterDistance(response[1])

      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_KEY, // Replace with your Google Maps API key
        version: "weekly", // Specify the version of the API (e.g., "weekly" or a specific version)
        libraries: ["geometry", "places"],
      });
      const distancePromises = response[0].data.data.map((hospital) => {
        return new Promise((resolve, reject) => {
          loader.load().then((google) => {
            if (google.maps.geometry) {
              const distanceInMeters =
                google.maps.geometry.spherical.computeDistanceBetween(
                  new google.maps.LatLng(hospital.latitude, hospital.longitude),
                  new google.maps.LatLng(latitudeParam, longitudeParam)
                );

              // Convert meters to kilometers
              const distanceInMiles = (
                (distanceInMeters / 1000) *
                0.621371
              ).toFixed(1);
              resolve(distanceInMiles);
            } else {
              reject(new Error("Geometry library not loaded"));
            }
          });
        });
      });

      Promise.all(distancePromises)
        .then((results) => {
          const hospitalDataWithDistance = response[0].data.data.map(
            (hospital, index) => {
              const hospitalWithDistance = hospital;
              hospitalWithDistance.distance = results[index];
              return hospitalWithDistance;
            }
          );
          sethospitalList(hospitalDataWithDistance);
          setLoadingNewSearch(false);
        })
        .catch((error) => {
          console.error(error.message);
        });

      setGoogleLoader(loader);
    })
      .catch((error) => {
        const loader = new Loader({
          apiKey: process.env.REACT_APP_GOOGLE_KEY, // Replace with your Google Maps API key
          version: "weekly", // Specify the version of the API (e.g., "weekly" or a specific version)
          libraries: ["geometry", "places"],
        });

        setGoogleLoader(loader);
        setCurrentPage(1);
        setNumPages(1);
        setTotalNumHospitals(0);
        sethospitalList([]);
        setLoadingNewSearch(false);
      })
  };

  const handleNewDateSelected = (startDate) => {
    navigate(
      `/search?specialty=${searchTerm}&distance=${distanceParam}&latitude=${latitudeParam}&longitude=${longitudeParam}&insurance=${insuranceParam}&plan=${planParam}&date=${startDate}`
    );
    window.location.reload();
  };

  const increaseMonth = () => {
    const newDate = new Date(displayedDate.getTime());
  const originalDay = newDate.getDate();

  // Set the date to the first of the month to prevent overflow
  newDate.setDate(1);

  // Increment the month by 1
  newDate.setMonth(newDate.getMonth() + 1);

  // Set the date to the original day or the last day of the new month if the original day is too large
  const daysInNewMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
  newDate.setDate(Math.min(originalDay, daysInNewMonth));

  setDisplayedDate(newDate);
  };

  const decreaseMonth = () => {
    const newDate = new Date(displayedDate.getTime());
    newDate.setMonth(newDate.getMonth() - 1);

    setDisplayedDate(newDate);
  };

  const scrollToId = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSlotClicked = (
    date,
    appointmentData,
    appointmentSlotDictionary
  ) => {
    setAppointmentModalOpen(true);
    setAppointmentModalFirstDate(date);
    setAppointmentModalData(appointmentData);
    setAppointmentSlotDictionary(appointmentSlotDictionary);
  };

  const handleApplyClicked = () => {
    navigate(
      `/search?specialty=${searchTerm}&distance=${selectedDistance}&latitude=${latitudeParam}&longitude=${longitudeParam}&timeOfDay=${timeOfDay[selectedTimeOfDay].title}&insurance=${insuranceParam}&plan=${planParam}&date=${dateParam}`
    );
    window.location.reload();
  };

  const handleSearchTermDeleted = () => {
    navigate(
      `/search?specialty=&distance=${distanceParam}&latitude=${latitudeParam}&longitude=${longitudeParam}&insurance=${insuranceParam}&plan=${planParam}&date=${dateParam}`
    );
    window.location.reload();
  };

  const handleInsuranceChanged = (insuranceID, planID) => {
    navigate(
      `/search?specialty=${searchTerm}&distance=${distanceParam}&latitude=${latitudeParam}&longitude=${longitudeParam}&timeOfDay=${timeOfDay[selectedTimeOfDay].title}&insurance=${insuranceID}&plan=${planID}&date=${dateParam}`
    );
    window.location.reload();
  };

  const searchWithSpecialty = (specialty) => {
    navigate(
      `/search?specialty=${specialty}&distance=${selectedDistance}&latitude=${latitudeParam}&longitude=${longitudeParam}&timeOfDay=${timeOfDay[selectedTimeOfDay].title}&insurance=${insuranceParam}&plan=${planParam}&date=${dateParam}`
    );
    window.location.reload();
  }

  const renderAppointmentModal = () => {
    let heightOffset = 100 / 2;
    let offsetPx = heightOffset + "px";

    const desktopStyle = {
      content: {
        border: "0",
        borderRadius: "4px",
        bottom: "auto",
        left: "50%",
        position: "fixed",
        right: "auto",
        top: "10%", // start from center
        transform: "translate(-50%,-" + offsetPx + ")", // adjust top "up" based on height
        width: "600px",
        height: "90%",
      },
      overlay: {
        backgroundColor: "rgb(0,0,0,0.5)",
        overflowY: "auto",
      },
    };

    const tabletStyle = {
      content: {
        border: "0",
        borderRadius: "0px",
        bottom: "auto",
        left: "50%",
        position: "fixed",
        right: "auto",
        transform: "translate(-50%,-" + offsetPx + ")", // adjust top "up" based on height
        width: "100%",
        height: "100%",
      },
      overlay: {
        backgroundColor: "rgb(0,0,0,0.5)",
        overflowY: "hidden",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={isAppointmentModalOpen}
          style={isTabletOrMobile ? tabletStyle : desktopStyle}
          onRequestClose={() => setAppointmentModalOpen(false)}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
        >
          <AppointmentModal
            appointmentModalData={appointmentModalData}
            appointmentModalFirstDate={startOfWeek}
            closeAppointmentModal={() => setAppointmentModalOpen(false)}
            hospitalList={hospitalList}
            appointmentSlotDictionary={appointmentSlotDictionary}
          />
        </ReactModal>
      </div>
    );
  };

  const DistanceBottomSheetContent = () => {
    return (
      <div className="time-dropdown-menu bottomsheet">
        <div className="time-dropdown-menu-content-container">
          <h3>How far would you go?</h3>
          <span>
            <input
              type="checkbox"
              checked={selectedDistance === 4}
              onChange={() => setSelectedDistance(4)}
            />
            <h4>5 miles</h4>
            <h6></h6>
          </span>
          <span>
            <input
              type="checkbox"
              checked={selectedDistance === 1}
              onChange={() => setSelectedDistance(1)}
            />
            <h4>15 miles</h4>
            <h6></h6>
          </span>
          <span>
            <input
              type="checkbox"
              checked={selectedDistance === 2}
              onChange={() => setSelectedDistance(2)}
            />
            <h4>25 miles</h4>
            <h6></h6>
          </span>
          <span>
            <input
              type="checkbox"
              checked={selectedDistance === 3}
              onChange={() => setSelectedDistance(3)}
            />
            <h4>50 miles</h4>
            <h6></h6>
          </span>
          <span>
            <input
              type="checkbox"
              checked={selectedDistance === 5}
              onChange={() => setSelectedDistance(5)}
            />
            <h4>{">"} 50 miles</h4>
            <h6></h6>
          </span>
        </div>
        <div className="dropdown-buttons bottomsheet">
          <button
            className="dropdown-button-apply bottomsheet"
            onClick={() => handleApplyClicked()}
          >
            Apply
          </button>
          <button
            className="dropdown-button-clear bottomsheet"
            onClick={() => setDistanceModalOpen(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const DateBottomSheetContent = () => {
    return (
      <div className="date-dropdown-menu bottomsheet">
        <DatePicker
          displayedDate={displayedDate}
          setDisplayedDate={setDisplayedDate}
          increaseMonth={increaseMonth}
          decreaseMonth={decreaseMonth}
          setPopupOpen={setDateModalOpen}
          handleNewDateSearch={handleNewDateSelected}
        />
        <div className="dropdown-buttons bottomsheet">
          <button
            className="dropdown-button-clear bottomsheet"
            onClick={() => setDateModalOpen(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };
  const InsuranceBottomSheetContent = () => {
    return (
      <div className="date-dropdown-menu bottomsheet">
        <InsuranceFilter
          setInsuranceID={setInsurance}
          setPlanID={setPlan}
          insuranceID={insurance}
          planID={plan}
          handleInsuranceChanged={handleInsuranceChanged}
          isInsuranceModalOpen={isInsuranceModalOpen}
          setInsuranceModalOpen={setInsuranceModalOpen}
          isBottomSheet={true}
        />
      </div>
    );
  };

  if (
    appointmentModalData == {} ||
    !appointmentModalData ||
    hospitalList == undefined ||
    hospitalList == null ||
    loadingNewSearch
  ) {
    return <Loading />;
  } else {
    if (!isTabletOrMobile) {
      googleLoader.load().then((google) => {
        const map = new google.maps.Map(mapRef.current, {
          center: {
            lat: parseFloat(latitudeParam),
            lng: parseFloat(longitudeParam),
          },
          mapTypeControl: false,
          zoom: 15,
          maxZoom: 17,
          disableDefaultUI: true,
        });

        mapRef.current = map;

        const geocoder = new google.maps.Geocoder();
        const latLng = new google.maps.LatLng(
          parseFloat(latitudeParam),
          parseFloat(longitudeParam)
        );
        const bounds = new window.google.maps.LatLngBounds();
        hospitalList.forEach((hospital) => {
          const markerPosition = new window.google.maps.LatLng(
            hospital.latitude,
            hospital.longitude
          );
          bounds.extend(markerPosition);
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(
              parseFloat(hospital.latitude),
              parseFloat(hospital.longitude)
            ), // Set the marker position to the center of the map
            map: map,
            icon: marker_icon,
          });

          const infowindow = new google.maps.InfoWindow({
            content: `${hospital.hospital_name}`, // Replace with your label content
          });

          // Add click event listener to the marker
          google.maps.event.addListener(marker, "click", function () {
            infowindow.open(map, marker);
            scrollToId(hospital.external_id);
          });
        });

        map.fitBounds(bounds);

        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === "OK" && results[0]) {
            var address;
            results[0].address_components.forEach((component) => {
              const types = component.types;
              if (types.includes("locality")) {
                address = component.long_name;
              } else if (types.includes("administrative_area_level_1")) {
                address += ", " + component.long_name;
              }
            });
            setSelectedAddress(address);
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        });
      });
    } else {
      googleLoader.load().then((google) => {
        const geocoder = new google.maps.Geocoder();
        const latLng = new google.maps.LatLng(
          parseFloat(latitudeParam),
          parseFloat(longitudeParam)
        );

        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === "OK" && results[0]) {
            var address;
            results[0].address_components.forEach((component) => {
              const types = component.types;
              if (types.includes("locality")) {
                address = component.long_name;
              } else if (types.includes("administrative_area_level_1")) {
                address += ", " + component.long_name;
              }
            });
            setSelectedAddress(address);
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        });
      });
    }

    if (isTabletOrMobile && isDistanceModalOpen) {
      return (
        <MobileDropdownBottomSheet
          dropdownComponent={<DistanceBottomSheetContent />}
          isMobileBottomSheetOpen={isDistanceModalOpen}
          setMobileBottomSheetOpen={setDistanceModalOpen}
          isFullScreen={true}
        />
      )
    } else if (isTabletOrMobile && isDateModalOpen) {
      return (
        <MobileDropdownBottomSheet
          dropdownComponent={<DateBottomSheetContent />}
          isMobileBottomSheetOpen={isDateModalOpen}
          setMobileBottomSheetOpen={setDateModalOpen}
          isFullScreen={true}
        />
      )
    } else if (isTabletOrMobile && isInsuranceModalOpen) {
      return (
        <MobileDropdownBottomSheet
          dropdownComponent={<InsuranceBottomSheetContent />}
          isMobileBottomSheetOpen={isInsuranceModalOpen}
          setMobileBottomSheetOpen={setInsuranceModalOpen}
          isFullScreen={true}
        />
      )
    } else if (isTabletOrMobile && isFooterModalOpen) {
      return (
        <MobileDropdownBottomSheet
          dropdownComponent={<FooterBottomSheetContent searchWithSpecialty={searchWithSpecialty} setFooterModalOpen={setFooterModalOpen} />}
          isMobileBottomSheetOpen={isFooterModalOpen}
          setMobileBottomSheetOpen={setFooterModalOpen}
          isFullScreen={true}
        />
      )
    }

    return (
      <div className="search-result-container">

        {renderAppointmentModal()}

        <div className="search-result-header-container">
          <div className="search-result-header-left-container">
            {!isTabletOrMobile ?
              <img
                alt="Achew Logo"
                src={achew_logo_side}
                className="search-result-header-logo"
                onClick={() => navigate("/")}
              />
              :
              <div className="search-results-mobile-logo-container">
                <i class="fas fa-bars fa-lg" onClick={() => setFooterModalOpen(true)}></i>
                <h5 className="search-results-mobile-logo" onClick={() => navigate("/")}>
                  Achew
                </h5>
              </div>


            }
            <SearchResultsSearchBar />
          </div>

          <div className="search-result-header-link-container">
            <button
              className="search-result-header-link tablet-only"
              onClick={() => navigate("/welcome")}
            >
              List your practice on Achew
            </button>
            <button
              className="search-result-header-link-sign-up tablet-only"
              onClick={() => navigate("/doctor-login")}
            >
              Clinic Login
            </button>
          </div>
        </div>

        <div className="search-result-body">
          <div className="search-result-clinics-container">
            <div className="search-result-filters-container">
              {/* Keyword */}
              {searchTerm != "" && searchTerm != null && (
                <div className="position-relative">
                  <button className="search-result-filter-button">
                    "{searchTerm}"
                    <i
                      class="fas fa-times"
                      onClick={() => handleSearchTermDeleted()}
                    ></i>
                  </button>
                </div>
              )}
              {/* Date */}
              <div className="position-relative" ref={dateDropdownRef}>
                <button
                  className="search-result-filter-button"
                  onClick={() => setDateModalOpen(!isDateModalOpen)}
                >
                  {monthDictionary[startOfWeek.getMonth()]} {startOfWeek.getDate()},{" "}
                  {startOfWeek.getFullYear()} -{" "}
                  {monthDictionary[endOfNextWeek.getMonth()]}{" "}
                  {endOfNextWeek.getDate()}, {endOfNextWeek.getFullYear()}
                  {!isTabletOrMobile && <img alt="chevron" src={chevron_down} />}
                </button>

                {!isTabletOrMobile && isDateModalOpen && (
                  <div className="date-dropdown-menu">
                    <DatePicker
                      displayedDate={displayedDate}
                      setDisplayedDate={setDisplayedDate}
                      increaseMonth={increaseMonth}
                      decreaseMonth={decreaseMonth}
                      setPopupOpen={setDateModalOpen}
                      handleNewDateSearch={handleNewDateSelected}
                    />
                  </div>
                )}
              </div>
              {/* Time */}
              {/* <div className="position-relative" ref={timeDropdownRef}>
            <button
              className="search-result-filter-button"
              onClick={() => setTimeModalOpen(true)}
            >
              {timeOfDay[selectedTimeOfDay].title}
            </button>
            {isTimeModalOpen && (
              <div className="time-dropdown-menu">
                <div className="time-dropdown-menu-content-container">
                  <h3>Do you have a preferred time of Day?</h3>
                  <span>
                    <input
                      type="checkbox"
                      checked={selectedTimeOfDay === 1}
                      onChange={() => setSelectedTimeOfDay(1)}
                    />
                    <h4>Early morning</h4>
                    <h5>starts before 10 am</h5>
                    <h6></h6>
                  </span>
                  <span>
                    <input
                      type="checkbox"
                      checked={selectedTimeOfDay === 2}
                      onChange={() => setSelectedTimeOfDay(2)}
                    />
                    <h4>Morning</h4>
                    <h5>starts before 12 pm</h5>
                    <h6></h6>
                  </span>
                  <span>
                    <input
                      type="checkbox"
                      checked={selectedTimeOfDay === 3}
                      onChange={() => setSelectedTimeOfDay(3)}
                    />
                    <h4>Afternoon</h4>
                    <h5>starts after 12 pm</h5>
                    <h6></h6>
                  </span>
                  <span>
                    <input
                      type="checkbox"
                      checked={selectedTimeOfDay === 4}
                      onChange={() => setSelectedTimeOfDay(4)}
                    />
                    <h4>Evening</h4>
                    <h5>starts after 5 pm</h5>
                    <h6></h6>
                  </span>
                </div>

                <div className="dropdown-buttons">
                  <button
                    className="dropdown-button-clear"
                    onClick={() => setTimeModalOpen(false)}
                  >
                    Clear
                  </button>
                  <button
                    className="dropdown-button-apply"
                    onClick={() => handleApplyClicked()}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div> */}

              {/* Distance */}
              <div className="position-relative" ref={distanceDropdownRef}>
                <button
                  className="search-result-filter-button"
                  onClick={() => setDistanceModalOpen(!isDistanceModalOpen)}
                >
                  {distanceToMiles[selectedDistance].title}
                  {!isTabletOrMobile && <img alt="chevron" src={chevron_down} />}
                </button>
                {!isTabletOrMobile && isDistanceModalOpen && (
                  <div className="time-dropdown-menu">
                    <div className="time-dropdown-menu-content-container">
                      <h3>How far would you go?</h3>
                      <span>
                        <input
                          type="checkbox"
                          checked={selectedDistance === 4}
                          onChange={() => setSelectedDistance(4)}
                        />
                        <h4>5 miles</h4>
                        <h6></h6>
                      </span>
                      <span>
                        <input
                          type="checkbox"
                          checked={selectedDistance === 1}
                          onChange={() => setSelectedDistance(1)}
                        />
                        <h4>15 miles</h4>
                        <h6></h6>
                      </span>
                      <span>
                        <input
                          type="checkbox"
                          checked={selectedDistance === 2}
                          onChange={() => setSelectedDistance(2)}
                        />
                        <h4>25 miles</h4>
                        <h6></h6>
                      </span>
                      <span>
                        <input
                          type="checkbox"
                          checked={selectedDistance === 3}
                          onChange={() => setSelectedDistance(3)}
                        />
                        <h4>50 miles</h4>
                        <h6></h6>
                      </span>
                      <span>
                        <input
                          type="checkbox"
                          checked={selectedDistance === 5}
                          onChange={() => setSelectedDistance(5)}
                        />
                        <h4>{">"} 50 miles</h4>
                        <h6></h6>
                      </span>
                    </div>
                    <div className="dropdown-buttons">
                      <button
                        className="dropdown-button-clear"
                        onClick={() => setDistanceModalOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="dropdown-button-apply"
                        onClick={() => handleApplyClicked()}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <InsuranceFilter
                setInsuranceID={setInsurance}
                setPlanID={setPlan}
                insuranceID={insurance}
                planID={plan}
                handleInsuranceChanged={handleInsuranceChanged}
                isInsuranceModalOpen={!isTabletOrMobile && isInsuranceModalOpen}
                setInsuranceModalOpen={setInsuranceModalOpen}
              />
              {/* Address */}
              <div className="position-relative" ref={videoDropdownRef}>
                <div className="search-result-filter-button-static">
                  {selectedAddress && selectedAddress !== "" ? selectedAddress : "Arcadia, California"}
                </div>
              </div>
            </div>
            <div className="search-result-clinics-heading">
              <h1>{totalNumHosptials}</h1>
              <h2>{`providers found ${didRetrySearchWithGreaterDistance && totalNumHosptials > 0 ? "from further locations" : ""}`}</h2>
            </div>
            <div className="search-result-clinics-container-list">
              {hospitalList.map((hospital) => {
                return (
                  <ClinicDetails
                    id={hospital.external_id}
                    data={hospital}
                    handleSlotClicked={handleSlotClicked}
                    displayedDate={displayedDate}
                  />
                );
              })}
            </div>

            <div className="search-result-clinics-find-more-providers">
              <h2>Find more providers</h2>
              <h3>
                Adjust Achew's search filters to find more clinics with your
                preferences
              </h3>
            </div>
            <div className="search-result-pagination-container">
              <Pagination
                currentPage={currentPage}
                numPages={numPages}
                navLink={`/search?specialty=${searchTerm}&distance=${distanceParam}&latitude=${latitudeParam}&longitude=${longitudeParam}&insurance=${insuranceParam}&plan=${planParam}&date=${dateParam}`}
              />
            </div>
          </div>
          <div
            className={`search-result-map-wrapper ${isMapSticky ? "map-sticky" : ""
              } desktop-only`}
            ref={mapContainerRef}
          >
            <div className="search-content-map">
              <div ref={mapRef} style={{ width: "100%", height: "100%" }}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Search;
