import React, { useState, useEffect } from "react";
import {
  appointment_calendar,
  check_circle_blue,
  check_circle_green,
  check_circle_red,
  check_circle_yellow,
  chevron_down_circle,
  x_close,
} from "../../home/image";
import {
  extractTimeInfo,
  extractTimeInfo2,
  formatDateToLongString,
  formatTimestamp,
  getInsuranceById,
  getPlanByIds,
  getPlanName,
} from "../../../assets/functions/functions";
import { acceptAppointment, getDownloadLinkFromPresignedURL } from "../../../../api/patientAPIs";
import ConfirmationModal from "./patient-modals/action-confirmation-modal";
import insuranceData from "../../../../sampledata/insurance.json";
import DownloadButton from "./components/download-button";
const { S3Client } = require('@aws-sdk/client-s3');
const { getSignedUrl } = require('@aws-sdk/s3-request-presigner');
const { GetObjectCommand, ListObjectsV2Command } = require('@aws-sdk/client-s3');

const AppointmentView = ({
  appointmentData,
  hospitalData,
  index,
  isFromCalendar,
  isFromNotification,
  appointmentIntakeDictionary,
  setIntakeModalData,
  setCreateIntakeModalOpen,
  setCreditWarningMessageOpen,
  accountData
}) => {
  const [isExpanded, setIsExpanded] = useState(
    isFromCalendar || isFromNotification ? true : false
  );
  const [isDocumentExpanded, setDocumentExpanded] = useState(false)
  const [isMessagesExpanded, setMessagesExpanded] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalType, setConfirmationModalType] = useState(null);
  const [confirmationModalData, setConfirmationModalData] = useState(null);

  //Intake
  const tempIntakeData = appointmentData.external_id in appointmentIntakeDictionary ? appointmentIntakeDictionary[appointmentData.external_id] : null;
  const [intakeData, setIntakeData] = useState(tempIntakeData)
  const [appointmentIntakeDictionaryState, setAppointmentIntakeDictionaryState] = useState(appointmentIntakeDictionary)
  //AWS Config
  const region = process.env.REACT_APP_S3_REGION; // e.g., 'us-west-2'
  const bucketName = process.env.REACT_APP_S3_PATIENTBUCKETNAME;
  const expiresInSeconds = 5; // Time for which the pre-signed URL is valid, e.g., 300 seconds
  const s3Client = new S3Client({
    region,
    credentials: {
      accessKeyId: process.env.REACT_APP_S3_ACCESSKEYID,
      secretAccessKey: process.env.REACT_APP_S3_SECRETACCESSKEY,
    }
  });

  const smsReminderStatus = {
    "NA": "Reminder not yet sent.",
    "SENT": "Awaiting patient response.",
    "YES": "Patient replied YES to visit.",
    "NO": "Patient replied NO to visit"
  }

  const handleConfirmationModal = (modalData, type) => {
    setConfirmationModalOpen(true);
    setConfirmationModalType(type);
    setConfirmationModalData(modalData);
  };

  const toggleExpand = () => {
    if (!isFromCalendar) {
      setIsExpanded(!isExpanded);
    }
  };

  const formattedTimeString = extractTimeInfo2(
    new Date(appointmentData.schedule_time * 1000)
  );

  useEffect(() => {
    setAppointmentIntakeDictionaryState(appointmentIntakeDictionary)
    setIntakeData(appointmentData.external_id in appointmentIntakeDictionary ? appointmentIntakeDictionary[appointmentData.external_id] : null)
    if (isFromNotification && isExpanded) {
      const element = document.getElementById(`${appointmentData.external_id}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [tempIntakeData]);

  const handleDownloadDocument = async (objectKey1, objectKey2, fileName, setDownloadProgress) => {
    console.log(objectKey1, objectKey2)
    const command1 = new GetObjectCommand({
      Bucket: bucketName,
      Key: objectKey1,
    });

    const presignedURL1 = await getSignedUrl(s3Client, command1, {
      expiresIn: expiresInSeconds,
    });

    if (!presignedURL1) return;

    const command2 = new GetObjectCommand({
      Bucket: bucketName,
      Key: objectKey2,
    });

    const presignedURL2 = await getSignedUrl(s3Client, command2, {
      expiresIn: expiresInSeconds,
    });

    if (!presignedURL2) return;

    const pdfDownloadURL = await getDownloadLinkFromPresignedURL(presignedURL1, presignedURL2, setDownloadProgress)
    const simulatedLink = document.createElement('a');
    simulatedLink.style.display = 'none';
    simulatedLink.href = pdfDownloadURL;
    simulatedLink.download = fileName;
    document.body.appendChild(simulatedLink);

    simulatedLink.click();

    document.body.removeChild(simulatedLink);
    URL.revokeObjectURL(pdfDownloadURL);
  };


  const handleRequestIntakePressed = () => {
    if(accountData.credits >= 200){
      const newIntakeData = {
        patient_name_first: appointmentData.patient_info.patient_name_first,
        patient_name_last: appointmentData.patient_info.patient_name_last,
        phone: appointmentData.patient_info.phone,
        email: appointmentData.patient_info.email,
        appointment_id: appointmentData.external_id,
      }
      setIntakeModalData(newIntakeData)
      setCreateIntakeModalOpen(true)
    } else {
      setCreditWarningMessageOpen(true)
    }
    
  }

  const renderStatusIcon = () => {
    if(appointmentData.sms_state === "NA"){
      return (<i class="fas fa-clock appointment-view-status-icon" style={{color:'#ffd714'}}></i>)
    } else if(appointmentData.sms_state === "SENT") {
      return (<i class="fas fa-clock appointment-view-status-icon" style={{color:'#ffd714'}}></i>)
    }else if(appointmentData.sms_state === "YES") {
      return (<i class="fas fa-check appointment-view-status-icon" style={{color:'#02db3c'}}></i>)
    }else if(appointmentData.sms_state === "NO") {
      return (<i class="fas fa-ban appointment-view-status-icon" style={{color:'#e52317'}}></i>)
    }
  }


  const renderPending = () => {
    console.log(formattedTimeString)
    return (
      <div
        className="appointment-view-container"
        id={appointmentData.external_id}
      >
        <div className="appointment-view-header" onClick={() => toggleExpand()}>
          <div className="appointment-view-header-left">
            <div className="appointment-view-header-id">{index}</div>
            <div className="appointment-view-header-date">
              <h1>{appointmentData.patient_info.reason}</h1>
              <h2>
                {appointmentData.patient_info.patient_name_first}{" "}
                {appointmentData.patient_info.patient_name_last}
              </h2>
              <h2>
                Dr. {appointmentData.d_data[0].doctor_name_first}{" "}
                {appointmentData.d_data[0].doctor_name_last}
              </h2>
            </div>
            <div className="d-flex flex-column">
              <h3>{formattedTimeString.fullDateTime}</h3>
            </div>
          </div>
          <div className="appointment-view-header-right">
            <h4>STATUS:</h4>
            <h5>Pending Confirmation</h5>
            {!isFromCalendar && <img alt="chevron" src={chevron_down_circle} />}
          </div>
        </div>
        <div
          className={`appointment-view-body ${isExpanded ? "expanded" : ""}`}
        >
          <div className="appointment-view-left-container">
            <div className="appointment-view-left-top-section">
              <div className="appointment-view-title-section">
                <img alt="Check" src={check_circle_yellow} />
                <div className="d-flex flex-column">
                  <h1 style={{ color: "#ff9900" }}>Appointment Requested!</h1>
                  <h2>
                    {appointmentData.patient_info.patient_name_first} has
                    requested an appointment with you. Please respond to this
                    request.
                  </h2>
                  <div className="appointment-view-status-banner yellow">
                    Pending Clinic Response
                  </div>
                </div>
              </div>
              <div className="appointment-view-date-section">
                <img alt="Calendar" src={appointment_calendar} />
                <div className="d-flex flex-column">
                  <h1>Date & Time</h1>
                  <h2>{formattedTimeString.fullDateTime}</h2>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <button
                    className="appointment-view-date-section-btn-accept"
                    onClick={() =>
                      handleConfirmationModal(appointmentData, "ACCEPT")
                    }
                  >
                    Accept
                  </button>
                  <button
                    onClick={() =>
                      handleConfirmationModal(appointmentData, "MODIFY")
                    }
                  >
                    Reschedule
                  </button>
                  <button
                    className="appointment-view-date-section-btn-decline"
                    onClick={() =>
                      handleConfirmationModal(appointmentData, "DECLINE")
                    }
                  >
                    Decline
                  </button>
                </div>
              </div>
            </div>
            <div className="appointment-view-left-info-section">
              {
                intakeData ?
                  <div className="appointment-view-left-info-row">
                    <div className="appointment-view-left-info-row-inner">
                      <h1>Patient Documents</h1>
                      {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                    </div>
                    <div className="appointment-view-info-row-dropdown documents">
                      <div className="d-flex flex-column" style={{ marginRight: "20px" }}>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-shield-alt"></i>
                          <h2>Insurance Card</h2>
                        </div>
                        <h3>The front and back of the patient's insurance card</h3>
                      </div>
                      {
                        intakeData.status === "completed" && intakeData.did_upload_insurance ?
                          <div className="d-flex flex-column">
                            <DownloadButton onDownload={(setDownloadProgress) => handleDownloadDocument(
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-Insurance_Card_Front.jpg`,
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-Insurance_Card_Back.jpg`,
                              `${intakeData.patient_name_first}-${intakeData.patient_name_last}-Insurance_Card.pdf`,
                              setDownloadProgress
                            )}/>
                          </div>
                          :
                          <>
                            {intakeData.status === "pending" ? 
                            <h5>Pending upload</h5> 
                            : 
                            <h5>No upload</h5>}
                          </>
                      }
                    </div>
                    <div className="appointment-view-info-row-dropdown documents">
                      <div className="d-flex flex-column" style={{ marginRight: "20px" }}>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-id-card"></i>
                          <h2>ID Card</h2>
                        </div>
                        <h3>The front and back of the patient's ID card</h3>
                      </div>
                      {
                        intakeData.status === "completed" && intakeData.did_upload_id ?
                          <div className="d-flex flex-column">
                            <DownloadButton onDownload={(setDownloadProgress) => handleDownloadDocument(
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-ID_Card_Front.jpg`,
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-ID_Card_Back.jpg`,
                              `${intakeData.patient_name_first}-${intakeData.patient_name_last}-ID_Card.pdf`,
                              setDownloadProgress
                            )}/>
                          </div>
                          :
                          <>
                            {intakeData.status === "pending" ? <h1>Pending upload</h1> : <h1>No upload</h1>}
                          </>
                      }
                    </div>
                  </div>
                  :
                  <div className="appointment-view-left-info-row">
                    <div className="appointment-view-left-info-row-inner">
                      <h1>Patient Documents</h1>
                      <button onClick={() => handleRequestIntakePressed()} className="appointment-view-request-patient-docs">
                        Request patient docs
                      </button>
                    </div>
                  </div>
              }
              {
                appointmentData.allow_sms && 
                <div className="appointment-view-left-info-row">
                  <div className="appointment-view-left-info-row-inner">
                    <h1>Appointment Reminders</h1>
                    {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                  </div>
                  <div className="appointment-view-info-row-dropdown reminders">
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                          <i className="fas fa-id-card"></i>
                          <h2>SMS Confirmation</h2>
                        </div>
                        <h3>Achew will text patients to confirm their visit a day before their appointment.</h3>
                      </div>
                        <h4>
                          {renderStatusIcon()}
                          <b>Status:</b> {smsReminderStatus[appointmentData.sms_state]}
                        </h4>
                    </div>
                </div>
              }
                

              {
                appointmentData.patient_info.message && appointmentData.patient_info.message.length > 0 &&
                <div className="appointment-view-left-info-row">
                  <div className="appointment-view-left-info-row-inner">
                    <h1>Message to Clinic (from {appointmentData.patient_info.patient_name_first})</h1>
                    {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                  </div>
                  <div className="appointment-view-info-row-dropdown">
                    <h3>{appointmentData.patient_info.message}</h3>
                  </div>
                </div>
              }

              {
                appointmentData.has_referral &&
                <div className="appointment-view-left-info-row">
                  <div className="appointment-view-left-info-row-inner">
                    <h1>Referral</h1>
                    {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                  </div>
                  <div className="appointment-view-info-row-dropdown">
                    <div className="d-flex flex-column" style={{ marginRight: "20px" }}>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-id-card"></i>
                        <h2>Authorization Number</h2>
                      </div>
                      <h3>{appointmentData.auth_code.length > 0 ? appointmentData.auth_code : "This patient didn't provide an authorization number for their referral."}</h3>
                    </div>
                  </div>
                </div>
              }

            </div>
            <div className="appointment-view-left-bottom-section">
              <div className="appointment-view-left-bottom-info">
                <h1>Appointment Location:</h1>
                <h2>{hospitalData.hospital_name}</h2>
                <h3>
                  {hospitalData.location_info.street_address},{" "}
                  {hospitalData.location_info.street_address_2},{" "}
                  {hospitalData.location_info.city},{" "}
                  {hospitalData.location_info.state}{" "}
                  {hospitalData.location_info.zip}
                </h3>
              </div>
            </div>
          </div>

          <div className="appointment-view-right-container">
            <div className="appointment-view-right-top">
              <h1>Patient Details</h1>
              <div className="appointment-view-right-row">
                <h3>First Name</h3>
                <h4>{appointmentData.patient_info.patient_name_first}</h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Last Name</h3>
                <h4>{appointmentData.patient_info.patient_name_last}</h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Date of Birth</h3>
                <h4>{formatDateToLongString(appointmentData.patient_info.dob)}</h4>
              </div>

              <div className="appointment-view-right-row">
                <h3>Phone</h3>
                <h4>{appointmentData.patient_info.phone}</h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Email</h3>
                <h4>{appointmentData.patient_info.email}</h4>
              </div>

              <div className="appointment-view-right-row">
                <h3>Insurance Carrier</h3>
                <h4>
                  {getInsuranceById(
                    appointmentData.patient_info.insurance,
                    insuranceData
                  ) &&
                    getInsuranceById(
                      appointmentData.patient_info.insurance,
                      insuranceData
                    ).name}
                </h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Insurance Plan</h3>
                <h4>
                  {getPlanName(
                    appointmentData.patient_info.insurance,
                    appointmentData.patient_info.plan,
                    insuranceData
                  ) &&
                    getPlanName(
                      appointmentData.patient_info.insurance,
                      appointmentData.patient_info.plan,
                      insuranceData
                    ).title}
                </h4>
              </div>

              <div className="appointment-view-right-row">
                <h3>Member ID</h3>
                <h4>{appointmentData.patient_info.member_id}</h4>
              </div>

            </div>

          </div>
        </div>
      </div>
    );
  };
  const renderConfirmed = () => {
    return (
      <div
        className="appointment-view-container"
        id={appointmentData.external_id}
      >
        <div
          className="appointment-view-header"
          onClick={() => toggleExpand()}
        >
          <div className="appointment-view-header-left">
            <div className="appointment-view-header-id">{index}</div>
            <div className="appointment-view-header-date">
              <h1>{appointmentData.patient_info.reason}</h1>
              <h2>
                {appointmentData.patient_info.patient_name_first}{" "}
                {appointmentData.patient_info.patient_name_last}
              </h2>
              <h2>
                Dr. {appointmentData.d_data[0].doctor_name_first}{" "}
                {appointmentData.d_data[0].doctor_name_last}
              </h2>
            </div>
            <div className="d-flex flex-column">
              <h3>{formattedTimeString.fullDateTime}</h3>
            </div>
          </div>
          <div className="appointment-view-header-right">
            <h4>STATUS:</h4>
            <h5>Confirmed</h5>
            {!isFromCalendar && <img alt="chevron" src={chevron_down_circle} />}
          </div>
        </div>
        <div
          className={`appointment-view-body ${isExpanded ? "expanded" : ""}`}
        >
          <div className="appointment-view-left-container">
            <div className="appointment-view-left-top-section">
              <div className="appointment-view-title-section">
                <img alt="Check" src={check_circle_green} />
                <div className="d-flex flex-column">
                  <h1 style={{ color: "#12b76a" }}>Appointment Confirmed!</h1>
                  <h2>
                    {appointmentData.patient_info.patient_name_first} has
                    requested an appointment with you. Please respond to this
                    request.
                  </h2>
                  <div className="appointment-view-status-banner green">
                    Confirmed
                  </div>
                </div>
              </div>
              <div className="appointment-view-date-section">
                <img alt="Calendar" src={appointment_calendar} />
                <div className="d-flex flex-column">
                  <h1>Date & Time</h1>
                  <h2>{formattedTimeString.fullDateTime}</h2>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <button
                    onClick={() =>
                      handleConfirmationModal(appointmentData, "MODIFY")
                    }
                  >
                    Reschedule
                  </button>
                  <button
                    className="appointment-view-date-section-btn-decline"
                    onClick={() =>
                      handleConfirmationModal(appointmentData, "DECLINE")
                    }
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="appointment-view-left-info-section">
              {
                intakeData ?
                  <div className="appointment-view-left-info-row">
                    <div className="appointment-view-left-info-row-inner">
                      <h1>Patient Documents</h1>
                      {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                    </div>
                    <div className="appointment-view-info-row-dropdown documents">
                      <div className="d-flex flex-column" style={{ marginRight: "20px" }}>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-shield-alt"></i>
                          <h2>Insurance Card</h2>
                        </div>
                        <h3>The front and back of the patient's insurance card</h3>
                      </div>
                      {
                        intakeData.status === "completed" && intakeData.did_upload_insurance ?
                          <div className="d-flex flex-column">
                            <DownloadButton onDownload={(setDownloadProgress) => handleDownloadDocument(
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-Insurance_Card_Front.jpg`,
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-Insurance_Card_Back.jpg`,
                              `${intakeData.patient_name_first}-${intakeData.patient_name_last}-Insurance_Card.pdf`,
                              setDownloadProgress
                            )}/>
                          </div>
                          :
                          <>
                            {intakeData.status === "pending" ? 
                            <h5>Pending upload</h5> 
                            : 
                            <h5>No upload</h5>}
                          </>
                      }
                    </div>
                    <div className="appointment-view-info-row-dropdown documents">
                      <div className="d-flex flex-column" style={{ marginRight: "20px" }}>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-id-card"></i>
                          <h2>ID Card</h2>
                        </div>
                        <h3>The front and back of the patient's ID card</h3>
                      </div>
                      {
                        intakeData.status === "completed" && intakeData.did_upload_id ?
                          <div className="d-flex flex-column">
                            <DownloadButton onDownload={(setDownloadProgress) => handleDownloadDocument(
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-ID_Card_Front.jpg`,
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-ID_Card_Back.jpg`,
                              `${intakeData.patient_name_first}-${intakeData.patient_name_last}-ID_Card.pdf`,
                              setDownloadProgress
                            )}/>
                          </div>
                          :
                          <>
                            {intakeData.status === "pending" ? <h1>Pending upload</h1> : <h1>No upload</h1>}
                          </>
                      }
                    </div>
                  </div>
                  :
                  
                  <div className="appointment-view-left-info-row">
                    <div className="appointment-view-left-info-row-inner">
                      <h1>Patient Documents</h1>
                      <button onClick={() => handleRequestIntakePressed()} className="appointment-view-request-patient-docs">
                        Request patient docs
                      </button>
                    </div>
                  </div>
              }
              {
                appointmentData.allow_sms && 
                <div className="appointment-view-left-info-row">
                  <div className="appointment-view-left-info-row-inner">
                    <h1>Appointment Reminders</h1>
                    {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                  </div>
                  <div className="appointment-view-info-row-dropdown reminders">
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                          <i className="fas fa-id-card"></i>
                          <h2>SMS Confirmation</h2>
                        </div>
                        <h3>Achew will text patients to confirm their visit a day before their appointment.</h3>
                      </div>
                      <h4>
                          {renderStatusIcon()}
                          <b>Status:</b> {smsReminderStatus[appointmentData.sms_state]}
                        </h4>
                    </div>
                </div>
              }
              


              {
                appointmentData.patient_info.message && appointmentData.patient_info.message.length > 0 &&
                <div className="appointment-view-left-info-row">
                  <div className="appointment-view-left-info-row-inner">
                    <h1>Message to Clinic (from {appointmentData.patient_info.patient_name_first})</h1>
                    {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                  </div>
                  <div className="appointment-view-info-row-dropdown">
                    <h3>{appointmentData.patient_info.message}</h3>
                  </div>
                </div>
              }

              {
                appointmentData.has_referral &&
                <div className="appointment-view-left-info-row">
                  <div className="appointment-view-left-info-row-inner">
                    <h1>Referral</h1>
                    {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                  </div>
                  <div className="appointment-view-info-row-dropdown">
                    <div className="d-flex flex-column" style={{ marginRight: "20px" }}>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-id-card"></i>
                        <h2>Authorization Number</h2>
                      </div>
                      <h3>{appointmentData.auth_code.length > 0 ? appointmentData.auth_code : "This patient didn't provide an authorization number for their referral."}</h3>
                    </div>
                  </div>
                </div>
              }

            </div>
            <div className="appointment-view-left-bottom-section">
              <div className="appointment-view-left-bottom-info">
                <h1>Appointment Location:</h1>
                <h2>{hospitalData.hospital_name}</h2>
                <h3>
                  {hospitalData.location_info.street_address},{" "}
                  {hospitalData.location_info.street_address_2},{" "}
                  {hospitalData.location_info.city},{" "}
                  {hospitalData.location_info.state}{" "}
                  {hospitalData.location_info.zip}
                </h3>
              </div>
            </div>
          </div>

          <div className="appointment-view-right-container">
            <div className="appointment-view-right-top">
              <h1>Patient Details</h1>
              <h2>Package / Tests Added</h2>
              <div className="appointment-view-right-row">
                <h3>First Name</h3>
                <h4>{appointmentData.patient_info.patient_name_first}</h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Last Name</h3>
                <h4>{appointmentData.patient_info.patient_name_last}</h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Phone</h3>
                <h4>{appointmentData.patient_info.phone}</h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Email</h3>
                <h4>{appointmentData.patient_info.email}</h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Insurance Carrier</h3>
                <h4>
                  {getInsuranceById(
                    appointmentData.patient_info.insurance,
                    insuranceData
                  ) &&
                    getInsuranceById(
                      appointmentData.patient_info.insurance,
                      insuranceData
                    ).name}
                </h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Insurance Plan</h3>
                <h4>
                  {getPlanName(
                    appointmentData.patient_info.insurance,
                    appointmentData.patient_info.plan,
                    insuranceData
                  ).title &&
                    getPlanName(
                      appointmentData.patient_info.insurance,
                      appointmentData.patient_info.plan,
                      insuranceData
                    ).title}
                </h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Member ID</h3>
                <h4>{appointmentData.patient_info.member_id}</h4>
              </div>
            </div>
            {/* <div className='appointment-view-right-bottom'>
                            <h1>Achew Fee</h1>
                            <h2>$150.00</h2>
                        </div> */}
          </div>
        </div>
      </div>
    );
  };
  const renderCompleted = () => {
    return (
      <div
        className="appointment-view-container"
        id={appointmentData.external_id}
      >
        <div
          className="appointment-view-header"
          onClick={() => toggleExpand()}
        >
          <div className="appointment-view-header-left">
            <div className="appointment-view-header-id">{index}</div>
            <div className="appointment-view-header-date">
              <h1>{appointmentData.patient_info.reason}</h1>
              <h2>
                {appointmentData.patient_info.patient_name_first}{" "}
                {appointmentData.patient_info.patient_name_last}
              </h2>
              <h2>
                Dr. {appointmentData.d_data[0].doctor_name_first}{" "}
                {appointmentData.d_data[0].doctor_name_last}
              </h2>
            </div>
            <div className="d-flex flex-column">
              <h3>{formattedTimeString.fullDateTime}</h3>
            </div>
          </div>
          <div className="appointment-view-header-right">
            <h4>STATUS:</h4>
            <h5>Completed</h5>
            {!isFromCalendar && <img alt="chevron" src={chevron_down_circle} />}
          </div>
        </div>
        <div
          className={`appointment-view-body ${isExpanded ? "expanded" : ""}`}
        >
          <div className="appointment-view-left-container">
            <div className="appointment-view-left-top-section">
              <div className="appointment-view-title-section">
                <img alt="Check" src={check_circle_blue} />
                <div className="d-flex flex-column">
                  <h1 style={{ color: "#044a9f" }}>Appointment Completed!</h1>
                  <h2>
                    {appointmentData.patient_info.patient_name_first} has
                    requested an appointment with you. Please respond to this
                    request.
                  </h2>
                  <div className="appointment-view-status-banner blue">
                    Complete
                  </div>
                </div>
              </div>
              <div className="appointment-view-date-section">
                <img alt="Calendar" src={appointment_calendar} />
                <div className="d-flex flex-column">
                  <h1>Date & Time</h1>
                  <h2>{formattedTimeString.fullDateTime}</h2>
                </div>
              </div>
            </div>
            <div className="appointment-view-left-info-section">
              {
                intakeData ?
                  <div className="appointment-view-left-info-row">
                    <div className="appointment-view-left-info-row-inner">
                      <h1>Patient Documents</h1>
                      {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                    </div>
                    <div className="appointment-view-info-row-dropdown documents">
                      <div className="d-flex flex-column" style={{ marginRight: "20px" }}>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-shield-alt"></i>
                          <h2>Insurance Card</h2>
                        </div>
                        <h3>The front and back of the patient's insurance card</h3>
                      </div>
                      {
                        intakeData.status === "completed" && intakeData.did_upload_insurance ?
                          <div className="d-flex flex-column">
                            <DownloadButton onDownload={(setDownloadProgress) => handleDownloadDocument(
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-Insurance_Card_Front.jpg`,
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-Insurance_Card_Back.jpg`,
                              `${intakeData.patient_name_first}-${intakeData.patient_name_last}-Insurance_Card.pdf`,
                              setDownloadProgress
                            )}/>
                          </div>
                          :
                          <>
                            {intakeData.status === "pending" ? 
                            <h5>Pending upload</h5> 
                            : 
                            <h5>No upload</h5>}
                          </>
                      }
                    </div>
                    <div className="appointment-view-info-row-dropdown documents">
                      <div className="d-flex flex-column" style={{ marginRight: "20px" }}>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-id-card"></i>
                          <h2>ID Card</h2>
                        </div>
                        <h3>The front and back of the patient's ID card</h3>
                      </div>
                      {
                        intakeData.status === "completed" && intakeData.did_upload_id ?
                          <div className="d-flex flex-column">
                            <DownloadButton onDownload={(setDownloadProgress) => handleDownloadDocument(
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-ID_Card_Front.jpg`,
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-ID_Card_Back.jpg`,
                              `${intakeData.patient_name_first}-${intakeData.patient_name_last}-ID_Card.pdf`,
                              setDownloadProgress
                            )}/>
                          </div>
                          :
                          <>
                            {intakeData.status === "pending" ? <h1>Pending upload</h1> : <h1>No upload</h1>}
                          </>
                      }
                    </div>
                  </div>
                  :
                  null
              }
              {
                appointmentData.allow_sms && 
                <div className="appointment-view-left-info-row">
                    <div className="appointment-view-left-info-row-inner">
                      <h1>Appointment Reminders</h1>
                      {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                    </div>
                    <div className="appointment-view-info-row-dropdown reminders">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center">
                            <i className="fas fa-id-card"></i>
                            <h2>SMS Confirmation</h2>
                          </div>
                          <h3>Achew will text patients to confirm their visit a day before their appointment.</h3>
                        </div>
                        <h4>
                          {renderStatusIcon()}
                          <b>Status:</b> {smsReminderStatus[appointmentData.sms_state]}
                        </h4>
                      </div>
                  </div>
              }
                


              {
                appointmentData.patient_info.message && appointmentData.patient_info.message.length > 0 &&
                <div className="appointment-view-left-info-row">
                  <div className="appointment-view-left-info-row-inner">
                    <h1>Message to Clinic (from {appointmentData.patient_info.patient_name_first})</h1>
                    {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                  </div>
                  <div className="appointment-view-info-row-dropdown">
                    <h3>{appointmentData.patient_info.message}</h3>
                  </div>
                </div>
              }

              {
                appointmentData.has_referral &&
                <div className="appointment-view-left-info-row">
                  <div className="appointment-view-left-info-row-inner">
                    <h1>Referral</h1>
                    {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                  </div>
                  <div className="appointment-view-info-row-dropdown">
                    <div className="d-flex flex-column" style={{ marginRight: "20px" }}>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-id-card"></i>
                        <h2>Authorization Number</h2>
                      </div>
                      <h3>{appointmentData.auth_code.length > 0 ? appointmentData.auth_code : "This patient didn't provide an authorization number for their referral."}</h3>
                    </div>
                  </div>
                </div>
              }

            </div>
            <div className="appointment-view-left-bottom-section">
              <div className="appointment-view-left-bottom-info">
                <h1>Appointment Location:</h1>
                <h2>{hospitalData.hospital_name}</h2>
                <h3>
                  {hospitalData.location_info.street_address},{" "}
                  {hospitalData.location_info.street_address_2},{" "}
                  {hospitalData.location_info.city},{" "}
                  {hospitalData.location_info.state}{" "}
                  {hospitalData.location_info.zip}
                </h3>
              </div>
            </div>
          </div>

          <div className="appointment-view-right-container">
            <div className="appointment-view-right-top">
              <h1>Patient Details</h1>
              <h2>Package / Tests Added</h2>
              <div className="appointment-view-right-row">
                <h3>First Name</h3>
                <h4>{appointmentData.patient_info.patient_name_first}</h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Last Name</h3>
                <h4>{appointmentData.patient_info.patient_name_last}</h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Phone</h3>
                <h4>{appointmentData.patient_info.phone}</h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Email</h3>
                <h4>{appointmentData.patient_info.email}</h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Insurance Carrier</h3>
                <h4>
                  {getInsuranceById(
                    appointmentData.patient_info.insurance,
                    insuranceData
                  ) &&
                    getInsuranceById(
                      appointmentData.patient_info.insurance,
                      insuranceData
                    ).name}
                </h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Insurance Plan</h3>
                <h4>
                  {getPlanName(
                    appointmentData.patient_info.insurance,
                    appointmentData.patient_info.plan,
                    insuranceData
                  ) &&
                    getPlanName(
                      appointmentData.patient_info.insurance,
                      appointmentData.patient_info.plan,
                      insuranceData
                    ).title}
                </h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Member ID</h3>
                <h4>{appointmentData.patient_info.member_id}</h4>
              </div>
            </div>
            {/* <div className='appointment-view-right-bottom'>
                            <h1>Achew Fee</h1>
                            <h2>$150.00</h2>
                        </div> */}
          </div>
        </div>
      </div>
    );
  };
  const renderDeclined = () => {
    return (
      <div
        className="appointment-view-container"
        id={appointmentData.external_id}
      >
        <div
          className="appointment-view-header"
          onClick={() => toggleExpand()}
        >
          <div className="appointment-view-header-left">
            <div className="appointment-view-header-id">{index}</div>
            <div className="appointment-view-header-date">
              <h1>{appointmentData.patient_info.reason}</h1>
              <h2>
                {appointmentData.patient_info.patient_name_first}{" "}
                {appointmentData.patient_info.patient_name_last}
              </h2>
              <h2>
                Dr. {appointmentData.d_data[0].doctor_name_first}{" "}
                {appointmentData.d_data[0].doctor_name_last}
              </h2>
            </div>
            <div className="d-flex flex-column">
              <h3>{formattedTimeString.fullDateTime}</h3>
            </div>
          </div>
          <div className="appointment-view-header-right">
            <h4>STATUS:</h4>
            <h5>Declined</h5>
            {!isFromCalendar && <img alt="chevron" src={chevron_down_circle} />}
          </div>
        </div>
        <div
          className={`appointment-view-body ${isExpanded ? "expanded" : ""}`}
        >
          <div className="appointment-view-left-container">
            <div className="appointment-view-left-top-section">
              <div className="appointment-view-title-section">
                <img alt="Check" src={x_close} />
                <div className="d-flex flex-column">
                  <h1 style={{ color: "#e52317" }}>Appointment Declined!</h1>
                  <h2>
                    {appointmentData.patient_info.patient_name_first} has
                    requested an appointment with you. Please respond to this
                    request.
                  </h2>
                  <div className="appointment-view-status-banner red">
                    Declined
                  </div>
                </div>
              </div>
              <div className="appointment-view-date-section">
                <img alt="Calendar" src={appointment_calendar} />
                <div className="d-flex flex-column">
                  <h1>Date & Time</h1>
                  <h2>{formattedTimeString.fullDateTime}</h2>
                </div>
              </div>
            </div>
            <div className="appointment-view-left-info-section">
              {
                intakeData ?
                  <div className="appointment-view-left-info-row">
                    <div className="appointment-view-left-info-row-inner">
                      <h1>Patient Documents</h1>
                      {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                    </div>
                    <div className="appointment-view-info-row-dropdown documents">
                      <div className="d-flex flex-column" style={{ marginRight: "20px" }}>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-shield-alt"></i>
                          <h2>Insurance Card</h2>
                        </div>
                        <h3>The front and back of the patient's insurance card</h3>
                      </div>
                      {
                        intakeData.status === "completed" && intakeData.did_upload_insurance ?
                          <div className="d-flex flex-column">
                            <DownloadButton onDownload={(setDownloadProgress) => handleDownloadDocument(
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-Insurance_Card_Front.jpg`,
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-Insurance_Card_Back.jpg`,
                              `${intakeData.patient_name_first}-${intakeData.patient_name_last}-Insurance_Card.pdf`,
                              setDownloadProgress
                            )}/>
                          </div>
                          :
                          <>
                            {intakeData.status === "pending" ? 
                            <h5>Pending upload</h5> 
                            : 
                            <h5>No upload</h5>}
                          </>
                      }
                    </div>
                    <div className="appointment-view-info-row-dropdown documents">
                      <div className="d-flex flex-column" style={{ marginRight: "20px" }}>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-id-card"></i>
                          <h2>ID Card</h2>
                        </div>
                        <h3>The front and back of the patient's ID card</h3>
                      </div>
                      {
                        intakeData.status === "completed" && intakeData.did_upload_id ?
                          <div className="d-flex flex-column">
                            <DownloadButton onDownload={(setDownloadProgress) => handleDownloadDocument(
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-ID_Card_Front.jpg`,
                              `hospitals/${hospitalData.external_id}/intake/${intakeData.external_id}/${intakeData.patient_name_first}-${intakeData.patient_name_last}-ID_Card_Back.jpg`,
                              `${intakeData.patient_name_first}-${intakeData.patient_name_last}-ID_Card.pdf`,
                              setDownloadProgress
                            )}/>
                          </div>
                          :
                          <>
                            {intakeData.status === "pending" ? <h1>Pending upload</h1> : <h1>No upload</h1>}
                          </>
                      }
                    </div>
                  </div>
                  :
                  null
              }
              {
                appointmentData.allow_sms && 
                <div className="appointment-view-left-info-row">
                  <div className="appointment-view-left-info-row-inner">
                    <h1>Appointment Reminders</h1>
                    {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                  </div>
                  <div className="appointment-view-info-row-dropdown reminders">
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                          <i className="fas fa-id-card"></i>
                          <h2>SMS Confirmation</h2>
                        </div>
                        <h3>Achew will text patients to confirm their visit a day before their appointment.</h3>
                      </div>
                      <h4>
                        {renderStatusIcon()}
                        <b>Status:</b> {smsReminderStatus[appointmentData.sms_state]}
                      </h4>
                    </div>
                </div>
              }
              


              {
                appointmentData.patient_info.message && appointmentData.patient_info.message.length > 0 &&
                <div className="appointment-view-left-info-row">
                  <div className="appointment-view-left-info-row-inner">
                    <h1>Message to Clinic (from {appointmentData.patient_info.patient_name_first})</h1>
                    {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                  </div>
                  <div className="appointment-view-info-row-dropdown">
                    <h3>{appointmentData.patient_info.message}</h3>
                  </div>
                </div>
              }

              {
                appointmentData.has_referral &&
                <div className="appointment-view-left-info-row">
                  <div className="appointment-view-left-info-row-inner">
                    <h1>Referral</h1>
                    {isDocumentExpanded ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                  </div>
                  <div className="appointment-view-info-row-dropdown">
                    <div className="d-flex flex-column" style={{ marginRight: "20px" }}>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-id-card"></i>
                        <h2>Authorization Number</h2>
                      </div>
                      <h3>{appointmentData.auth_code.length > 0 ? appointmentData.auth_code : "This patient didn't provide an authorization number for their referral."}</h3>
                    </div>
                  </div>
                </div>
              }

            </div>
            <div className="appointment-view-left-bottom-section">
              <div className="appointment-view-left-bottom-info">
                <h1>Appointment Location:</h1>
                <h2>{hospitalData.hospital_name}</h2>
                <h3>
                  {hospitalData.location_info.street_address},{" "}
                  {hospitalData.location_info.street_address_2},{" "}
                  {hospitalData.location_info.city},{" "}
                  {hospitalData.location_info.state}{" "}
                  {hospitalData.location_info.zip}
                </h3>
              </div>
            </div>
          </div>

          <div className="appointment-view-right-container">
            <div className="appointment-view-right-top">
              <h1>Patient Details</h1>
              <h2>Package / Tests Added</h2>
              <div className="appointment-view-right-row">
                <h3>First Name</h3>
                <h4>{appointmentData.patient_info.patient_name_first}</h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Last Name</h3>
                <h4>{appointmentData.patient_info.patient_name_last}</h4>
              </div>

              <div className="appointment-view-right-row">
                <h3>Phone</h3>
                <h4>{appointmentData.patient_info.phone}</h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Email</h3>
                <h4>{appointmentData.patient_info.email}</h4>
              </div>

              <div className="appointment-view-right-row">
                <h3>Insurance Carrier</h3>
                <h4>
                  {getInsuranceById(
                    appointmentData.patient_info.insurance,
                    insuranceData
                  ) &&
                    getInsuranceById(
                      appointmentData.patient_info.insurance,
                      insuranceData
                    ).name}
                </h4>
              </div>
              <div className="appointment-view-right-row">
                <h3>Insurance Plan</h3>
                <h4>
                  {getPlanName(
                    appointmentData.patient_info.insurance,
                    appointmentData.patient_info.plan,
                    insuranceData
                  ) &&
                    getPlanName(
                      appointmentData.patient_info.insurance,
                      appointmentData.patient_info.plan,
                      insuranceData
                    ).title}
                </h4>
              </div>

              <div className="appointment-view-right-row">
                <h3>Member ID</h3>
                <h4>{appointmentData.patient_info.member_id}</h4>
              </div>

            </div>
            {/* <div className='appointment-view-right-bottom'>
                            <h1>Achew Fee</h1>
                            <h2>$150.00</h2>
                        </div> */}
          </div>
        </div>
      </div>
    );
  };

  if (appointmentData.status === "PENDING") {
    return (
      <>
        {confirmationModalOpen && (
          <ConfirmationModal
            confirmationModalData={confirmationModalData}
            onRequestClose={() => setConfirmationModalOpen(false)}
            appointmentData={appointmentData}
            patient={false}
            modalType={confirmationModalType}
          />
        )}
        {renderPending()}
      </>
    );
  } else if (appointmentData.status === "CONFIRMED") {
    return (
      <>
        {confirmationModalOpen && (
          <ConfirmationModal
            confirmationModalData={confirmationModalData}
            onRequestClose={() => setConfirmationModalOpen(false)}
            appointmentData={appointmentData}
            patient={false}
            modalType={confirmationModalType}
          />
        )}
        {renderConfirmed()}
      </>
    );
  } else if (appointmentData.status === "COMPLETED") {
    return (
      <>
        {confirmationModalOpen && (
          <ConfirmationModal
            confirmationModalData={confirmationModalData}
            onRequestClose={() => setConfirmationModalOpen(false)}
            appointmentData={appointmentData}
            patient={false}
            modalType={confirmationModalType}
          />
        )}
        {renderCompleted()}
      </>
    );
  } else if (appointmentData.status === "DECLINED") {
    return (
      <>
        {confirmationModalOpen && (
          <ConfirmationModal
            confirmationModalData={confirmationModalData}
            onRequestClose={() => setConfirmationModalOpen(false)}
            appointmentData={appointmentData}
            patient={false}
            modalType={confirmationModalType}
          />
        )}
        {renderDeclined()}
      </>
    );
  }
};

export default AppointmentView;
