import { useState } from "react";
import axios, { Axios } from "axios";
import { refreshToken } from "./auth";
import { PDFDocument } from 'pdf-lib';
import { convertTo24Hour, getMidnightUTCTimestamp, getTimeAsNumber, isValidLatitude, isValidLongitude, setMidnight } from "../client/assets/functions/functions";
const debuggingMode = true
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
const searchDoctorProfile = async (keyword) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/testapi/search/',
            params: {
                q: keyword
            }
        })

        return response

    } catch (err) {
        return err 
    }
}

const getAppointments = async (hid) => {
    const expirationTime = localStorage.getItem("exp")
    const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
    if (accessTokenExpired || expirationTime == null) {
        const refreshResponse = await refreshToken()
        if (refreshResponse.status == 401) {
            return refreshResponse
        }
    }

    try {
        const response = await axios({
            method: 'get',
            url: '/testapi/appointment/',
            params: {
                'hospital_id': hid
            }
        })
        
        return response

    } catch (err) {
        console.log(err)
        return err
    }
}

const getAppointmentsByStatus = async (hid, status, page) => {
    const expirationTime = localStorage.getItem("exp")
    const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
    if (accessTokenExpired || expirationTime == null) {
        const refreshResponse = await refreshToken()
        if (refreshResponse.status == 401) {
            return refreshResponse
        }
    }

    try {
        const response = await axios({
            method: 'get',
            url: '/testapi/appointment/',
            params: {
                'hospital_id': hid,
                'type': status,
                'page': page
            }
        })
        
        return response

    } catch (err) {
        console.log(err)
        return err
    }
}

const getAppointmentsByTime = async (hid, time) => {
    const expirationTime = localStorage.getItem("exp")
    const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
    if (accessTokenExpired || expirationTime == null) {
        const refreshResponse = await refreshToken()
        if (refreshResponse.status == 401) {
            return refreshResponse
        }
    }

    try {
        const response = await axios({
            method: 'get',
            url: '/testapi/calendar/',
            params: {
                'hospital_id': hid,
                'start_time': time
            }
        })
        
        return response

    } catch (err) {
        console.log(err)
        return err
    }
}


const RegisterSchedule = async (scheduleData) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }
        
        const response = await axios({
            method: 'post',
            url: '/testapi/schedule/',
            data: scheduleData
        })
        console.log(response)
        return response


    } catch (err) {
        console.log(err)
        return err
    }
}

const EditSchedule = async (scheduleData, scheduleID, hospitalID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }


        const response = await axios({
            method: 'patch',
            url: `/testapi/schedule/${scheduleID}/?hospital_id=${hospitalID}`,
            data: scheduleData
        })
        return response


    } catch (err) {
        console.log(err)
        return err
    }
}
const DisableSchedule = async (scheduleID, hospitalID, scheduleData) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }

        const response = await axios({
            method: 'patch',
            url: `/testapi/schedule/${scheduleID}/?hospital_id=${hospitalID}`,
            data: scheduleData
        })
        return response


    } catch (err) {
        console.log(err)
        return err
    }
}

const DeleteSchedule = async (scheduleID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }
        

        const response = await axios({
            method: 'delete',
            url: `/testapi/schedule/${scheduleID}/`,
        })
        return response


    } catch (err) {
        console.log(err)
        return err
    }
}

const getSchedules = async (hospital_id, doctor_id) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }
        if(doctor_id) {
            const response = await axios({
                method: 'get',
                url: `/testapi/schedule/?hospital_id=${hospital_id}&doctor=${doctor_id}`,
            })
            console.log(response.data)
            return response
        } else {
            const response = await axios({
                method: 'get',
                url: `/testapi/schedule/?hospital_id=${hospital_id}`,
            })
            console.log(response.data)
            return response
        }

        


    } catch (err) {
        console.log(err)
        return err
    }
}

const GetNextSchedulePage = async (url) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }
        const response = await axios({
            method: 'get',
            url: url,
        })
        return response
    } catch (err) {
        return err
    }
}

const RegisterDoctor = async (doctorData, hospitalData) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/testapi/doctor-internal/',
            data: doctorData
        })
        const insertResponse = await InsertDoctorToHospital(response.data, hospitalData)
        return response


    } catch (err) {
        console.log(err)
        return err
    }
}

const InsertDoctorToHospital = async (doctorData, hospitalData) => {
    try {
        const newDoctorList = hospitalData.doctors
        newDoctorList.push(doctorData.external_id)
        const update = {
            "doctors": newDoctorList
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-external/${hospitalData.external_id}/`,
            data: update
        })
        return response
    } catch (err) {
        console.log(err)
        return err
    }
} 

const GetDoctor = async (doctorID) => {
    try {
        const response = await axios({
            method: 'get',
            url: `/testapi/doctor-external/${doctorID}/`,
        })
        return response


    } catch (err) {
        console.log(err)
        return err
    }
}

const EditDoctor = async (doctorID, doctorData) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }

        const response = await axios({
            method: 'patch',
            url: `/testapi/doctor-external/${doctorID}/`,
            data: doctorData,
        })

        return response
    } catch (err) {
        console.log(err)
        return err.response
    }
}

const DeleteDoctor = async (doctorID, hospital_id, doctorList) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }
        console.log("deleting:" , doctorID)
        const response = await axios({
            method: 'delete',
            url: `/testapi/doctor-external/${doctorID}/`,
        })

        const update = {
            "doctors": doctorList
        }
        const response2 = await axios({
            method: 'patch',
            url: `/testapi/hospital-internal/${hospital_id}/`,
            data: update,
        })

        return response
    } catch (err) {
        console.log(err)
        return err.response
    }
}

const EditClinic = async (hospitalID, hospitalData) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }

        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-external/${hospitalID}/`,
            data: hospitalData,
        })

        return response
    } catch (err) {
        console.log(err)
        return err.response
    }
}

const EditAccount = async (accountID, accountData) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }

        const response = await axios({
            method: 'patch',
            url: `/testapi/users/${accountID}/`,
            data: accountData,
        })

        return response
    } catch (err) {
        console.log(err)
        return err.response
    }
}

const GetAccountNotifications = async () => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }
        const response = await axios({
            method: 'get',
            url: `/account/notification/`,
        })

        return response


    } catch (err) {
        console.log(err)
        return err
    }
}

const GetNextAccountNotificationsPage = async (url) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }

        const response = await axios({
            method: 'get',
            url: url,
        })
        return response


    } catch (err) {
        console.log(err)
        return err
    }
}

const ReadAccountNotifications = async (notificationID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }

        const update = {
            "read": true
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/notification/${notificationID}`,
            data: update
        })
        return response


    } catch (err) {
        console.log(err)
        return err
    }
}

const GetAccountBillingHistory = async () => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }

        const response = await axios({
            method: 'get',
            url: `/account/payment-history/`,
        })
        return response


    } catch (err) {
        console.log(err)
        return err
    }
}

const GetNextBillingHistoryPage = async (url) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }

        const response = await axios({
            method: 'get',
            url: url,
        })
        return response


    } catch (err) {
        console.log(err)
        return err
    }
}


const getAccountMetrics = async (h_id) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }

        const response = await axios({
            method: 'get',
            url: `/account/metrics/`,
            params:{
                "hospital_id": h_id
            },
        })

        return response
    } catch (err) {
        console.log(err)
        return err.response
    }
}

const setAppointments = async (hospitalID, patientData) => {
    
    try {
        const response = await axios({
            method: 'post',
            url: '/testapi/appointment/',
            data: patientData
        })
        return response
    } catch (err) {
        console.log(err)
        return err
    }
}

const setAppointmentsWithAuth = async (hospitalID, patientData, token) => {
    
    try {
        const response = await axios({
            method: 'post',
            url: '/testapi/appointment/',
            data: patientData,
            headers: {
                'FARTYAUTH': token,
                'Content-Type': 'application/json;charset=utf-8',
            }
        })
        return response
    } catch (err) {
        console.log(err)
        return err
    }
}

const requestSMSCode = async (phoneNumber) => {
    try {

        const formatedNumber = "+1" + phoneNumber
        console.log(formatedNumber)
        const response = await axios({
            method: 'post',
            url: '/account/sms-request/',
            data: {
                "phone-number": formatedNumber
            }
        })
        return response
    } catch (err) {
        return err
    }
}

const verifySMSCode = async (phoneNumber, code, lastName) => {
    console.log(phoneNumber, code)
    try {
        const formatedNumber = "+1" + phoneNumber
        const response = await axios({
            method: 'post',
            url: '/account/sms-verify/',
            data: {
                "phone-number": formatedNumber,
                "sms-code": code,
                "last_name": lastName
            }
        })
        return response
    } catch (err) {
        return err
    }
}

const requestEmailVerification = async (email) => {
    try {
        console.log(email)
        const response = await axios({
            method: 'post',
            url: '/account/email-verify/',
            data: {
                "email": email,
            }
        })
        return response
    } catch (err) {
        console.log(err)
        return err
    }
}

const verifyEmail = async (email, code) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/account/email-verify/',
            data: {
                "email": email,
                "verify_code": code
            }
        })
        return response
    } catch (err) {
        return err
    }
}

const getAccountInfo = async () => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }

        const response = await axios({
            method: 'get',
            url: '/testapi/users/',
        })
        const responseWithStatus = response.data.results[0]
        responseWithStatus["status"] = response.status
        return responseWithStatus
    } catch (err) {
        console.log(err)
        return err.response
    }
}

const getHospitalInfoByInternalID = async (hospitalID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const response = await axios({
            method: 'get',
            url: `/testapi/hospital-internal/${hospitalID}/`,
        })

        return response


    } catch (err) {
        
        return err
    }
}

const getHospitalInfoByExternalID = async (hospitalID, shouldRecordMetrics = false) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }
        if(shouldRecordMetrics){
            const response = await axios({
                method: 'get',
                url: `/testapi/hospital-external/${hospitalID}/`,
                params: {
                    "record_metrics": true
                }
            })
            return response
        } else {
            const response = await axios({
                method: 'get',
                url: `/testapi/hospital-external/${hospitalID}/`,
            })
    
            return response
        }
        


    } catch (err) {
        return err
    }
}


const getAppointmentByID = async (appointmentID, hospitalID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const response = await axios({
            method: 'get',
            url: `/testapi/appointment/${appointmentID}?hospital_id=${hospitalID}`,
        })

        return response

    } catch (err) {
        return err
    }
}

const acceptAppointment = async (appointmentID, message, hospital_id, should_notify=false) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const status = {
            "status": "CONFIRMED",
            "message": message
        }
        if(should_notify){
            status["should_notify"] = true
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/appointment/${appointmentID}/?hospital_id=${hospital_id}`,
            data: status
        })

        return response

    } catch (err) {
        return err
    }
}

const completeAppointment = async (appointmentID, message, hospital_id) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const status = {
            "status": "COMPLETED",
            "message": message
        }
        console.log(hospital_id)
        const response = await axios({
            method: 'patch',
            url: `/testapi/appointment/${appointmentID}/?hospital_id=${hospital_id}`,
            data: status
        })

        return response

    } catch (err) {
        return err
    }
}

const modifyAppointment = async (appointmentID, reason, message, hospital_id, slot_time, slot_date) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const formattedTimeSlot = convertTo24Hour(slot_time)
        const formattedDate = getMidnightUTCTimestamp(slot_date) / 1000
        console.log("Formatting to ", formattedTimeSlot, formattedDate , slot_date)
        const status = {
            "status": "CONFIRMED",
            "reason": reason,
            "message": message,
            "slot_time": formattedTimeSlot,
            "slot_date": formattedDate,
            "schedule_rule": null
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/appointment/${appointmentID}/?hospital_id=${hospital_id}`,
            data: status
        })

        return response

    } catch (err) {
        return err
    }
}

const declineAppointment = async (appointmentID, reason, message, hospital_id, should_notify=false) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const status = {
            "status": "DECLINED",
            "reason": reason,
            "message": message
        }
        if(should_notify){
            status["should_notify"] = true
        }
        console.log(appointmentID, hospital_id)
        const response = await axios({
            method: 'patch',
            url: `/testapi/appointment/${appointmentID}/?hospital_id=${hospital_id}`,
            data: status
        })

        return response

    } catch (err) {
        return err
    }
}

const createAppointmentsFromHospitalSide = async (hospitalID, patientData, shouldRequestInsurance, shouldRequestID) => {
    
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const appointmentresponse = await axios({
            method: 'post',
            url: '/testapi/appointment/',
            data: patientData
        })

        var intakeResponse
        
        if(shouldRequestID || shouldRequestInsurance) {
            const intakeRequest = {
                "hospital_id": hospitalID,
                "patient_name_first": patientData.patient_info.patient_name_first,
                "patient_name_middle": "",
                "patient_name_last": patientData.patient_info.patient_name_last,
                "appointment_id": appointmentresponse.data.external_id,
                "phone": patientData.patient_info.phone,
                "email": patientData.patient_info.email,
                "is_insurance_required": shouldRequestInsurance,
                "is_id_required": shouldRequestID,
                "didUploadInsurance": false,
                "didUploadID": false,
                "status": "pending"
            }
            intakeResponse = await postNewIntake(intakeRequest)
        }
        
        return appointmentresponse
    } catch (err) {
        console.log(err)
        return err
    }
}

const updateHospitalInformation = async (orgName, hospitalEmail, hospitalPhoneNumber, mainSpecialty, hospitalID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const update = {
            "hospital_name": orgName,
            "owner": hospitalEmail,
            "phone_number": hospitalPhoneNumber,
            "main_specialty_title": mainSpecialty,
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-external/${hospitalID}/`,
            data: update
        })

        return response

    } catch (err) {
        return err
    }
}

const updateHospitalName = async (name, hospitalID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const update = {
            "hospital_name": name,
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-external/${hospitalID}/`,
            data: update
        })

        return response

    } catch (err) {
        return err
    }
}
const updateHospitalEmail = async (email, hospitalID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const update = {
            "hospital_email": email,
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-external/${hospitalID}/`,
            data: update
        })

        return response

    } catch (err) {
        return err
    }
}

const updateHospitalAbout = async (about, hospitalID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const update = {
            "about": about
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-internal/${hospitalID}/`,
            data: update
        })

        return response

    } catch (err) {
        return err
    }
}

const updateHospitalAddress = async (address, hospitalID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const update = {
            "location_info": address
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-external/${hospitalID}/`,
            data: update
        })

        return response

    } catch (err) {
        return err
    }
}

const updateHospitalInsurance = async (insuranceList, hospitalID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const update = {
            "accepted_insurance": insuranceList
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-external/${hospitalID}/`,
            data: update
        })

        return response

    } catch (err) {
        return err
    }
}

const updateHospitalDoctor = async (doctor, hospitalID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const update = {
            "doctors": doctor
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-external/${hospitalID}/`,
            data: update
        })

        return response

    } catch (err) {
        return err
    }
}

const updateHospitalService = async (services, hospitalID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const update = {
            "services": services
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-external/${hospitalID}/`,
            data: update
        })

        return response

    } catch (err) {
        return err
    }
}

const updateDoctorAcceptingNewPatients = async (shouldAllowNewPatient, doctorID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const update = {
            "allow_new_patient": shouldAllowNewPatient
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/doctor-external/${doctorID}/`,
            data: update
        })

        return response

    } catch (err) {
        return err
    }
}

const updateHospitalActive = async (isActive, hospitalID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const update = {
            "is_profile_active": isActive
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-external/${hospitalID}/`,
            data: update
        })

        return response

    } catch (err) {
        return err
    }
}

const updateHospitalPresentationMode = async (presentation_mode, hospitalID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const update = {
            "presentation_mode": presentation_mode
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-external/${hospitalID}/`,
            data: update
        })

        return response

    } catch (err) {
        return err
    }
}

const updateAccountEmail = async (email, accountID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const update = {
            "email": email
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/users/${accountID}/`,
            data: update
        })

        return response

    } catch (err) {
        return err
    }
}

const requestPaymentIntent = async (amount) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }
        const response = await axios({
            method: 'post',
            url: `/account/stripe/payment/`,
            data: {
                "amount": amount
            }
        })
        return response
    } catch (err) {
        return err
    }
}


const deleteAutoChargeInfo = async () => {
    //condition = lower limit
    //limit = total limit
    //amount = amount to charget when condition is met
    // all in cents
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }
        const response = await axios({
            method: 'post',
            url: `/account/stripe/payment/`,
            data: {
                "plan_limit": 0,
                "plan_amount": 0,
                "plan_condition": 0,
                "should_reset": true
            }
        })
        return response
    } catch (err) {
        return err
    }
}

const checkDefaultPayment = async () => {
    //condition = lower limit
    //limit = total limit
    //amount = amount to charget when condition is met
    // all in cents
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }
        const response = await axios({
            method: 'post',
            url: `/account/stripe/payment/`,
            data: {
                "ping_pay_method": true
            }
        })

        console.log("Check response:", response)
        return {
            "status": true,
            "last4": response.data.last4,
            "type": response.data.type
        }
    } catch (err) {
        console.log("error:", err)
        return {"status": !(err.response.status === 400 && err.response.data.msg === "No default payment method")}
    }
}

const updateAutoChargeInfo = async (plan_amount, plan_limit, plan_condition) => {
    //condition = lower limit
    //limit = total limit
    //amount = amount to charget when condition is met
    // all in cents
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }
        const response = await axios({
            method: 'post',
            url: `/account/stripe/payment/`,
            data: {
                "plan_limit": plan_limit,
                "plan_amount": plan_amount,
                "plan_condition": plan_condition,
            }
        })
        return response
    } catch (err) {
        return err
    }
}

const getDoctorAccountInfo = async () => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            const refreshResponse = await refreshToken()
            if (refreshResponse.status == 401) {
                return refreshResponse
            }
        }

        const response = await axios({
            method: 'get',
            url: '/account/',
        })

        return response
    } catch (err) {
        console.log(err)
        return err.response
    }
}


const updateHospitalImages = async (image, hospitalID) => {
    try {
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-external/${hospitalID}/`,
            data: {
                "hospital_images": image
            }
        })
        return response
    } catch (err) {
        return err
    }
}

const updateAccountManager = async (manager, accountID) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const update = {
            "first_name": manager
        }

        const response = await axios({
            method: 'patch',
            url: `/testapi/users/${accountID}/`,
            data: update
        })

        return response

    } catch (err) {
        return err
    }
}

const requestPatientToken = async (code, phone) => {
    try {

        const encryptResponse = await axios({
            method: 'post',
            url: `/account/fartyauth/`,
            data: {
                "code": code,
                "phone_number": phone
            }
        })
        console.log(encryptResponse)
        if (encryptResponse.status == 200) {
            const appointmentResponse = await requestPatientAppointment(encryptResponse.data.encrypted)
            return appointmentResponse
        }

    } catch (err) {
        return err
    }
}

const requestPatientAppointment = async (access) => {
    try {

        const appointmentResponse = await axios({
            method: 'get',
            url: `/testapi/appointment/`,
            headers: {
                'FARTYAUTH': access,
                'Content-Type': 'application/json;charset=utf-8',
            }
        })
        return appointmentResponse
    } catch (err) {
        console.log(err)
        return err
    }
}

const requestPatientTokenV2 = async (code, phone, lastName) => {
    try {
        const encryptResponse = await axios({
            method: 'post',
            url: '/account/sms-verify/',
            data: {
                "phone-number": phone,
                "sms-code": code,
                "last_name": lastName
            }
        })
        if (encryptResponse.status == 200) {
            console.log(phone, code, lastName)
            axios.defaults.headers.common["FARTYAUTH"] = encryptResponse.data.token; // Sets the default header for all requests
            axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8'; // Sets the default header for all requests
            return encryptResponse
        }
        

    } catch (err) {
        console.log(err)
        return err
    }
}

const requestPatientAppointmentV2 = async (page, token) => {
    try {
        console.log(token)
        if(token) {
            axios.defaults.headers.common["FARTYAUTH"] = token; // Sets the default header for all requests
            axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8'; // Sets the default header for all requests
        }
        
        const appointmentResponse = await axios({
            method: 'get',
            url: `/testapi/appointment/`,
            params: {
                "page": page
            },
            headers: {
                'FARTYAUTH': token,
                'Content-Type': 'application/json;charset=utf-8',
            }
        })
        return appointmentResponse
    } catch (err) {
        console.log(err)
        return err
    }
}

const getHospitalReviews = async (hospitalID) => {
    try {

        const response = await axios({
            method: 'get',
            url: `/testapi/public-review/?hospital_id=${hospitalID}`,
        })
        return response
    } catch (err) {
        return err
    }
}

const GetNextHospitalReviewPage = async (url) => {
    try {

        const response = await axios({
            method: 'get',
            url: url,
        })
        return response
    } catch (err) {
        return err
    }
}

const addHospitalReviews = async (reviewData) => {
    try {
        console.log(reviewData)
        const response = await axios({
            method: 'post',
            url: `/testapi/patient-review/`,
            data: reviewData
        })

        // if(response.status >= 200 && response.status < 300) {
        //     const data = {
        //         "reviewID": response.data.external_id
        //     }
        //     const appointmentResponse = await axios({
        //         method: 'patch',
        //         url: `/testapi/appointment/${reviewData.appointment_id}/?hospital_id=${reviewData.hospital_id}`,
        //         data: data,
        //     })
        //     console.log(data, appointmentResponse, response)
        // }
        
        return response
    } catch (err) {
        console.log(err)
        return err
    }
}

const updateHospitalReview = async (reviewData, reviewID) => {
    try {

        const update = reviewData

        const response = await axios({
            method: 'patch',
            url: `/testapi/patient-review/${reviewID}/`,
            data: update
        })
        console.log(response)
        return response

    } catch (err) {
        console.log(err)
        return err
    }
}
const getHospitalReviewByID = async (reviewID) => {
    try {
        const response = await axios({
            method: 'get',
            url: `/testapi/public-review/${reviewID}/`,
        })
        console.log(response)
        return response
    } catch (err) {
        console.log(err)
        return err
    }
}

const getHospitalReviewByIDWithFartyToken = async (external_id) => {
    try {
        console.log(external_id)
        const response = await axios({
            method: 'get',
            url: `/testapi/patient-review/${external_id}/`,
        })
        return response
    } catch (err) {
        return err
    }
}

const searchHospital = async (keyword, latitude, longitude, distance, pageNum, date, insuranceID, shouldRetryWithGreaterDistance = false) => {
    try {
        
        const convertedDate = getMidnightUTCTimestamp(new Date(parseInt(date))) / 1000
        const isValidCoordinates = (isValidLatitude(parseFloat(latitude)) && isValidLongitude(parseFloat(longitude)))
        const formattedLatitude = isValidCoordinates ? latitude : 34.1332024
        const formattedLongitude = isValidCoordinates ? longitude : -118.1128202
       
        const firstResponse = await axios({
            method: 'get',
            url: `/testapi/search/?specialty=${keyword}&distance=${distance}&latitude=${formattedLatitude}&longitude=${formattedLongitude}&page_num=${pageNum}&date_start=${convertedDate}&insurance=${insuranceID}`,
        })

        if(firstResponse.data.object_count === 0 && shouldRetryWithGreaterDistance) {
            const retryResponse = await axios({
                method: 'get',
                url: `/testapi/search/?specialty=${keyword}&distance=${10000}&latitude=${formattedLatitude}&longitude=${formattedLongitude}&page_num=${pageNum}&date_start=${convertedDate}&insurance=${insuranceID}`,
            })
            return [retryResponse, true]
        } else {
            return [firstResponse, false]
        }
        
    } catch (err) {
        return err
    }
}

const addEvent = async (id, ev) => {
    try {
        const update = {
            "timeline": ev
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-external/${id}/`,
            data: update
        })
        return response
    } catch (err) {
        return err
    }
}

const checkNPIRegistry = async (npi) => {
    try {
        
        const url = `https://npiregistry.cms.hhs.gov/api/?version=2.1&number=${npi}&pretty=on`;
        const response = await axios({
            method: 'get',
            url: url,
            withCredentials: false,
        })
        if (response.data?.results?.length > 0) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('Error checking NPI:', error);
        return false;
      }
}

const postNewIntake = async (intakeData) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const response = await axios({
            method: 'post',
            url: `/testapi/hospital-intake/?hospital_id=${intakeData.hospital_id}`,
            data: intakeData
        })

        return response

    } catch (err) {
        return err
    }
}

const getIntake = async (hospitalID, page) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const response = await axios({
            method: 'get',
            url: `/testapi/hospital-intake/?hospital_id=${hospitalID}`,
            params: {
                "page": page
            }
        })

        return response

    } catch (err) {
        return err
    }
}

const getIntakeByToken = async (token) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const response = await axios({
            method: 'get',
            url: `/testapi/patient-intake/?token=${token}`,
        })
        console.log(response)
        return response

    } catch (err) {
        console.log(err)
        return err
    }
}

const getIntakeByID = async (hospital_id, appointment_id) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const response = await axios({
            method: 'get',
            url: `/testapi/hospital-intake/?hospital_id=${hospital_id}&appointment=${appointment_id}`,
        })

        return response

    } catch (err) {
        return err
    }
}

const remindIntake = async (hospital_id, appointment_id) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const response = await axios({
            method: 'get',
            url: `/testapi/hospital-intake/?hospital_id=${hospital_id}&appointment=${appointment_id}`,
            data:{
                notfiy: true,
            }
        })

        return response

    } catch (err) {
        return err
    }
}

const getIntakeMetric = async (hospital_id) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const response = await axios({
            method: 'get',
            url: `/testapi/intake-metrics/?hospital_id=${hospital_id}`,
        })

        return response

    } catch (err) {
        return err
    }
}


const updateIntakeByID = async (intakeData, intakeID, token) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const response = await axios({
            method: 'patch',
            url: `/testapi/patient-intake/${intakeID}/?token=${token}`,
            data: intakeData
        })

        return response

    } catch (err) {
        return err
    }
}

const getDownloadLinkFromPresignedURL =  async (presignedURL1, presignedURL2, setDownloadProgress) => {
    
    try {
        delete axios.defaults.headers.common["Authorization"];
        console.log("get download link",presignedURL1, presignedURL2)
        const downloadLinkResponse1 = await axios({
            url: presignedURL1,
            method: 'get',
            responseType: 'arraybuffer', // Ensure binary data is handled correctly
            withCredentials: false,
            
        });

        setDownloadProgress(0.4)

        const downloadLinkResponse2 = await axios({
            url: presignedURL2,
            method: 'get',
            responseType: 'arraybuffer', // Ensure binary data is handled correctly
            withCredentials: false,
        });
        console.log(downloadLinkResponse1, downloadLinkResponse2)

        setDownloadProgress(0.6)
        // Create a new PDF document
        const pdfDoc = await PDFDocument.create();
        const buffer1 = downloadLinkResponse1.data
        const buffer2 = downloadLinkResponse2.data

        // Embed the first image into the PDF document
        const firstImage = await pdfDoc.embedJpg(buffer1);
        const firstPage = pdfDoc.addPage(); // Add a new page to the PDF
        const { width: firstWidth, height: firstHeight } = firstImage.scale(1);
        firstPage.setSize(firstWidth, firstHeight); // Set the page size to match the image size
        firstPage.drawImage(firstImage, {
            x: 0,
            y: 0,
            width: firstWidth,
            height: firstHeight,
        });
        setDownloadProgress(0.7)
        // Embed the second image into the PDF document
        const secondImage = await pdfDoc.embedJpg(buffer2);
        const secondPage = pdfDoc.addPage(); // Add another new page to the PDF
        const { width: secondWidth, height: secondHeight } = secondImage.scale(1);
        secondPage.setSize(secondWidth, secondHeight); // Set the page size to match the image size
        secondPage.drawImage(secondImage, {
            x: 0,
            y: 0,
            width: secondWidth,
            height: secondHeight,
        });
        setDownloadProgress(0.8)
        // Serialize the PDF document to an ArrayBuffer
        const pdfBytes = await pdfDoc.save();

        // Create a Blob from the PDF bytes
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

        // Generate an object URL for the Blob
        const pdfObjectUrl = URL.createObjectURL(pdfBlob);
        setDownloadProgress(1.0)
        return pdfObjectUrl
    } catch (err) {
        console.log(err)
    }
}

const queryIntake = async (hospital_id, query, page=1) => {
    try {
        const expirationTime = localStorage.getItem("exp")
        const accessTokenExpired = (parseInt(expirationTime) < (new Date()).getTime())
        if (accessTokenExpired || expirationTime == null) {
            await refreshToken()
        }

        const response = await axios({
            method: 'get',
            url: `/testapi/hospital-intake/?hospital_id=${hospital_id}&query=${query}`,
            params: {
                "page": page 
            }
        })

        return response

    } catch (err) {
        return err
    }
}

const testapi = async () => {
    try {
        const update = {
            "doctors": [
                "1da47055-c369-4ffb-8496-653bceba9fb5",
                "57df6423-90ab-4686-ae8e-7f23a508e937",
                "90d1a47f-e2da-492c-94ac-8bfdf765de71",
            ],
        }
        const response = await axios({
            method: 'patch',
            url: `/testapi/hospital-internal/2/`,
            data: update
        })
        return response

    } catch (err) {
        return err
    }
}

export {
    GetDoctor,
    EditDoctor,

    EditClinic,
    EditAccount,

    checkNPIRegistry,
    getDownloadLinkFromPresignedURL,

    searchDoctorProfile,
    getAppointments,
    getAppointmentsByStatus,
    getAppointmentsByTime,
    requestSMSCode,
    verifySMSCode,
    requestEmailVerification,
    verifyEmail,
    getAccountInfo,
    getDoctorAccountInfo,
    getHospitalInfoByInternalID,
    getHospitalInfoByExternalID,
    setAppointments,
    setAppointmentsWithAuth,
    getAppointmentByID,
    acceptAppointment,
    modifyAppointment,
    completeAppointment,
    declineAppointment,

    updateHospitalInformation,
    updateHospitalAbout,
    updateHospitalAddress,
    updateHospitalDoctor,
    updateHospitalService,
    updateHospitalActive,
    updateHospitalPresentationMode,
    updateHospitalImages,
    updateHospitalName,
    updateHospitalEmail,
    updateHospitalInsurance,

    updateDoctorAcceptingNewPatients,

    updateAccountEmail,
    updateAccountManager,

    getHospitalReviews,
    addHospitalReviews,
    getHospitalReviewByID,
    getHospitalReviewByIDWithFartyToken,
    updateHospitalReview,
    GetNextHospitalReviewPage,

    requestPatientToken,
    requestPatientTokenV2,
    requestPatientAppointmentV2,
    requestPaymentIntent,

    searchHospital,
    addEvent,
    testapi,
    RegisterDoctor,
    DeleteDoctor,
    InsertDoctorToHospital,
    
    RegisterSchedule,
    EditSchedule,
    DeleteSchedule,
    getSchedules,
    GetNextSchedulePage,
    DisableSchedule,

    postNewIntake,
    getIntake,
    getIntakeByToken,
    getIntakeByID,
    updateIntakeByID,
    queryIntake,
    remindIntake,
    getIntakeMetric,
    
    createAppointmentsFromHospitalSide,
    getAccountMetrics,
    checkDefaultPayment,
    GetAccountNotifications,
    ReadAccountNotifications,
    GetNextAccountNotificationsPage,
    
    GetAccountBillingHistory,
    GetNextBillingHistoryPage,
    updateAutoChargeInfo,
    deleteAutoChargeInfo,
}