import React from "react";
import { useNavigate } from "react-router-dom";
const AchewFooter = ({styling}) => {
  const navigate = useNavigate();
  return (
      <div className="booking-footer">
        <h2>© 2023 (Achew) All Rights Reserved.</h2>
        <div className="d-flex align-items-center">
            <h1>Terms & Conditions</h1>
            <h1>Privacy Policy</h1>
        </div>
      </div>
  );
};

export default AchewFooter;
