import React, {useRef} from "react";
import { useNavigate } from "react-router-dom";
import { achew_logo_main, message_icon, calling_icon, facebook_footer, linkedin_footer, twitter_footer, instagram_footer } from "./home/image";
const AchewFooter = ({ styling, footerRef }) => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="achew-footer-container" ref={footerRef}>
      <div className="achew-footer-top-section-container">
        <div className="achew-footer-company-description">
          <img alt="Achew Logo" src={achew_logo_main} />
          <h1>
            Achew provides patients with a simple, efficient way to find and connect with the doctors they need. Book appointments with a click of a button and avoid the hassle of over the phone bookings.
          </h1>
          <div className="achew-footer-contact-container">
            <div className="achew-footer-call-container">
              <div className="achew-footer-image-padding">
                <img alt="Achew Logo" src={calling_icon} />
              </div>

              <div>
                <h2>Call Us</h2>
                <h3>(626) 788 0644</h3>
              </div>
            </div>
            <div className="achew-footer-mail-container">
              <div className="achew-footer-image-padding">
                <img alt="Achew Logo" src={message_icon} />
              </div>

              <div>
                <h2>Email Us</h2>
                <h3>info@achew.net</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="achew-footer-links-container">
          <div className="d-flex">
            <div className="achew-footer-links-col">
              <h4 onClick={() => { navigate(""); scrollToTop(); }}>Find Doctors</h4>
              <h4 onClick={() => { navigate("/support/contact"); scrollToTop(); }}>Contact Us</h4>
              <h4 onClick={() => { navigate("/support/faqs"); scrollToTop(); }}>FAQs</h4>
            </div>
            <div className="achew-footer-links-col">
              <h4 onClick={() => { navigate("/support/about"); scrollToTop(); }}>About Us</h4>
              <h4 onClick={() => { navigate("/welcome"); scrollToTop(); }}>List your practice on Achew</h4>
              
            </div>
          </div>
          {/* <div className="achew-footer-social-media-container">
            <img alt="facebook" src={facebook_footer} />
            <img alt="linkedin" src={linkedin_footer} />
            <img alt="twitter" src={twitter_footer} />
            <img alt="instagram" src={instagram_footer} />
          </div> */}
        </div>
      </div>
      <div className="achew-footer-copyright-container">
        <h2>© 2024 Achew Inc. All Rights Reserved.</h2>
        <div className="d-flex align-items-center">
          <h1 onClick={() => {navigate("/support/terms"); scrollToTop();}}>Terms & Conditions</h1>
          <h1 onClick={() => {navigate("/support/private-policy"); scrollToTop();}}>Privacy Policy</h1>
        </div>

      </div>
    </div>
  );
};

export default AchewFooter;
