import React, { useRef } from "react";

const PrivacyPolicy = () => {

    const infoCollectionRef = useRef(null);
    const processDataRef = useRef(null);
    const sharePersonalInfoRef = useRef(null);
    const useCookiesRef = useRef(null);
    const keepInfoRef = useRef(null);
    const keepInfoSafeRef = useRef(null);
    const privacyRightsRef = useRef(null);
    const doNotTrackFeaturesRef = useRef(null);
    const usPrivacyRightsRef = useRef(null);
    const updatesNoticeRef = useRef(null);
    const contactUsNoticeRef = useRef(null);
    const reviewUpdateDeleteDataRef = useRef(null);

    const scrollToRef = (ref) => {
        if (ref && ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }

    return (
        <div className="tac-container">
            <h1>Privacy Policy</h1>
            <h5>Last updated April 11, 2024</h5>
            <h4>This privacy notice for Achew Inc. ("<b>Company</b>," "<b>we</b>," "<b>us</b>," "<b>our</b>"), describes how and why we
 might collect, store, use, and/or share ("<b>process</b>") your information when you use our
 services ("<b>Services</b>"), such as when you:</h4>
 <p>Visit our website at http://www.achew.net, or any website of ours that links to
 this privacy notice</p>
 <p>Engage with us in other related ways, including any sales, marketing, or
 events</p>
            <h4>Questions or concerns? Reading this privacy notice will help you understand your
 privacy rights and choices. If you do not agree with our policies and practices, please
 do not use our Services. If you still have any questions or concerns, please contact
 us at info@achew.net.</h4>
 <h1>Summary of Key Points</h1>
 <h5>This summary provides key points from our privacy notice, but you can find
 out more details about any of these topics by clicking the link following each
 key point or by using our table of contents below to find the section you are
 looking for.</h5>
 <h4>What personal information do we process? When you visit, use, or navigate our
 Services, we may process personal information depending on how you interact with
 us and the Services, the choices you make, and the products and features you use.
 Learn more about personal information you disclose to us.</h4>
 <h4>Do we process any sensitive personal information? We may process sensitive
 personal information when necessary with your consent or as otherwise permitted by
 applicable law. Learn more about sensitive information we process.</h4>
 <h4> Do we receive any information from third parties? We do not receive any
 information from third parties.</h4>

 <h4> How do we process your information? We process your information to provide,
 improve, and administer our Services, communicate with you, for security and fraud
 prevention, and to comply with law. We may also process your information for other
 purposes with your consent. We process your information only when we have a valid
 legal reason to do so. Learn more about how we process your information.</h4>
 <h4> In what situations and with which parties do we share personal information?
 We may share information in specific situations and with specific third parties. Learn
 more about when and with whom we share your personal information.</h4>
 <h4> How do we keep your information safe? We have organizational and technical
 processes and procedures in place to protect your personal information. However, no
 electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
 cybercriminals, or other unauthorized third parties will not be able to defeat our
 security and improperly collect, access, steal, or modify your information. Learn more
 about how we keep your information safe.</h4>

 <h4> What are your rights? Depending on where you are located geographically, the
 applicable privacy law may mean you have certain rights regarding your personal
 information. Learn more about your privacy rights.</h4>
 <h4> How do you exercise your rights? The easiest way to exercise your rights is by
 visiting achew.net/doctor-login, or by contacting us. We will consider and act upon
 any request in accordance with applicable data protection laws.</h4>
 <h4>Want to learn more about what we do with any information we collect? Review the
 privacy notice in full.</h4>
 <h2>Table of Contents</h2>
 <h6 onClick={() => scrollToRef(infoCollectionRef)}>1. What Information Do We Collect?</h6>
            <h6 onClick={() => scrollToRef(processDataRef)}>2. How Do We Process Your Information?</h6>
            <h6 onClick={() => scrollToRef(sharePersonalInfoRef)}>3. When And With Whom Do We Share Your Personal Information?</h6>
            <h6 onClick={() => scrollToRef(useCookiesRef)}>4. Do We Use Cookies And Other Tracking Technologies?</h6>
            <h6 onClick={() => scrollToRef(keepInfoRef)}>5. How Long Do We Keep Your Information?</h6>
            <h6 onClick={() => scrollToRef(keepInfoSafeRef)}>6. How Do We Keep Your Information Safe?</h6>
            <h6 onClick={() => scrollToRef(privacyRightsRef)}>7. What Are Your Privacy Rights?</h6>
            <h6 onClick={() => scrollToRef(doNotTrackFeaturesRef)}>8. Controls For Do-Not-Track Features</h6>
            <h6 onClick={() => scrollToRef(usPrivacyRightsRef)}>9. Do United States Residents Have Specific Privacy Rights?</h6>
            <h6 onClick={() => scrollToRef(updatesNoticeRef)}>10. Do We Make Updates To This Notice?</h6>
            <h6 onClick={() => scrollToRef(contactUsNoticeRef)}>11. How Can You Contact Us About This Notice?</h6>
            <h6 onClick={() => scrollToRef(reviewUpdateDeleteDataRef)}>12. How Can You Review, Update, Or Delete The Data We Collect From You?</h6>

<h2 ref={infoCollectionRef}>1. What Information Do We Collect?</h2>
<h3> Personal information you disclose to us</h3>
<h4> In Short: We collect personal information that you provide to us.</h4>
<h4> We collect personal information that you voluntarily provide to us when you register
 on the Services, express an interest in obtaining information about us or our products
 and Services, when you participate in activities on the Services, or otherwise when
 you contact us.</h4>
<h4>Personal Information Provided by You. The personal information that we collect
 depends on the context of your interactions with us and the Services, the choices you
 make, and the products and features you use. The personal information we collect
 may include the following:</h4>
 <p>Names</p>
<p>Phone numbers</p>
<p>Email addresses</p>
<p>Mailing addresses</p>
<p>Job titles</p>
<p>Usernames</p>
<p>Passwords</p>
<p>Contact preferences</p>
<p>Contact or authentication data</p>
<p>Billing addresses</p>
<h4>Sensitive Information. When necessary, with your consent or as otherwise
 permitted by applicable law, we process the following categories of sensitive
 information:</h4>
 <p> health data</p>
<h4> Payment Data. We may collect data necessary to process your payment if you make
 purchases, such as your payment instrument number, and the security code
 associated with your payment instrument. All payment data is stored by Stripe. You
 may find their privacy notice link(s) here: https://stripe.com/privacy.</h4>
<h4> All personal information that you provide to us must be true, complete, and accurate,
 and you must notify us of any changes to such personal information.</h4>
 <h3>Information automatically collect</h3>
 <h4> In Short: Some information — such as your Internet Protocol (IP) address and/or
 browser and device characteristics — is collected automatically when you visit our
 Services.</h4>
 <h4>We automatically collect certain information when you visit, use, or navigate the
 Services. This information does not reveal your specific identity (like your name or
 contact information) but may include device and usage information, such as your IP
 address, browser and device characteristics, operating system, language
 preferences, referring URLs, device name, country, location, information about how
 and when you use our Services, and other technical information. This information is
 primarily needed to maintain the security and operation of our Services, and for our
 internal analytics and reporting purposes.</h4>
 <h4>Like many businesses, we also collect information through cookies and similar
 technologies.</h4>
 <h4> The information we collect includes:</h4>
 <p>Log and Usage Data. Log and usage data is service-related, diagnostic,
 usage, and performance information our servers automatically collect when
 you access or use our Services and which we record in log files. Depending on
 how you interact with us, this log data may include your IP address, device
 information, browser type, and settings and information about your activity in
 the Services (such as the date/time stamps associated with your usage, pages
 and files viewed, searches, and other actions you take such as which features
 you use), device event information (such as system activity, error reports
 (sometimes called "crash dumps"), and hardware settings).</p>
 <p>Location Data. We collect location data such as information about your
 device's location, which can be either precise or imprecise. How much
 information we collect depends on the type and settings of the device you use
 to access the Services. For example, we may use GPS and other technologies
 to collect geolocation data that tells us your current location (based on your IP
 address). You can opt out of allowing us to collect this information either by
 refusing access to the information or by disabling your Location setting on your
 device. However, if you choose to opt out, you may not be able to use certain
 aspects of the Services.</p>
<h2 ref={processDataRef}>2. How Do We Process Your Information?</h2>
<h4> In Short: We process your information to provide, improve, and administer our
 Services, communicate with you, for security and fraud prevention, and to comply
 with law. We may also process your information for other purposes with your consent.</h4>
<h4> We process your personal information for a variety of reasons, depending on
 how you interact with our Services, including:</h4>
 <p>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</p>
<p>To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</p>
<p>To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</p>
<p>To send administrative information to you. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</p>
<p>To fulfill and manage your orders. We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.</p>
<p>To enable user-to-user communications. We may process your information if you choose to use any of our offerings that allow for communication with another user.</p>
<p>To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them.</p>
<p>To determine the effectiveness of our marketing and promotional campaigns. We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</p>
<h2 ref={sharePersonalInfoRef}>3. When And With Whom Do We Share Your Personal Information?</h2>
<h4>In Short: We may share information in specific situations described in this section
 and/or with the following third parties.</h4>
 <h4>We may need to share your personal information in the following situations:</h4>
 <p>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
<p>When we use Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). We obtain and store on your device ("cache") your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.</p>
<p>Other Users. When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</p>

<h2 ref={useCookiesRef}>4. Do We Use Cookies And Other Tracking Technologies?</h2>
<h4>In Short: We may use cookies and other tracking technologies to collect and store
 your information.</h4>
<h4>We may use cookies and similar tracking technologies (like web beacons and pixels)
 to access or store information. Specific information about how we use such
 technologies and how you can refuse certain cookies is set out in our Cookie Notice.</h4>
<h2 ref={keepInfoRef}>5. How Long Do We Keep Your Information?</h2>
<h4> In Short: We keep your information for as long as necessary to fulfill the purposes
 outlined in this privacy notice unless otherwise required by law</h4>
<h4>We will only keep your personal information for as long as it is necessary for the
 purposes set out in this privacy notice, unless a longer retention period is required or
 permitted by law (such as tax, accounting, or other legal requirements). No purpose
 in this notice will require us keeping your personal information for longer than   the
 period of time in which users have an account with us. </h4>
<h4>When we have no ongoing legitimate business need to process your personal
 information, we will either delete or anonymize such information, or, if this is not
 possible (for example, because your personal information has been stored in backup
 archives), then we will securely store your personal information and isolate it from
 any further processing until deletion is possible.</h4>
<h2 ref={keepInfoSafeRef}>6. How Do We Keep Your Information Safe?</h2>
<h4>In Short: We aim to protect your personal information through a system of
 organizational and technical security measures.</h4>
<h4> We have implemented appropriate and reasonable technical and organizational
 security measures designed to protect the security of any personal information we
 process. However, despite our safeguards and efforts to secure your information, no
 electronic transmission over the Internet or information storage technology can be
 guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
 cybercriminals, or other unauthorized third parties will not be able to defeat our
 security and improperly collect, access, steal, or modify your information. Although
 we will do our best to protect your personal information, transmission of personal
 information to and from our Services is at your own risk. You should only access the
 Services within a secure environment.</h4>
<h4>In Short:  You may review, change, or terminate your account at any time.</h4>
<h4> Withdrawing your consent: If we are relying on your consent to process your
 personal information, which may be express and/or implied consent depending on
 the applicable law, you have the right to withdraw your consent at any time. You can
 withdraw your consent at any time by contacting us by using the contact details
 provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
 below.</h4>
 <h4>However, please note that this will not affect the lawfulness of the processing before
 its withdrawal nor, when applicable law allows, will it affect the processing of your
 personal information conducted in reliance on lawful processing grounds other than
 consent.</h4>
<h2 ref={privacyRightsRef}>7. What Are Your Privacy Rights?</h2>
<h4>In Short:  You may review, change, or terminate your account at any time.</h4>
<h4> Withdrawing your consent: If we are relying on your consent to process your
 personal information, which may be express and/or implied consent depending on
 the applicable law, you have the right to withdraw your consent at any time. You can
 withdraw your consent at any time by contacting us by using the contact details
 provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
 below.</h4>

<h4> However, please note that this will not affect the lawfulness of the processing before
 its withdrawal nor, when applicable law allows, will it affect the processing of your
 personal information conducted in reliance on lawful processing grounds other than
 consent.</h4>
 <h3>Account Information</h3>
<h4>If you would at any time like to review or change the information in your account or
 terminate your account, you can:</h4>
 <p>Contact us using the contact information provided.</p>
<h4> Upon your request to terminate your account, we will deactivate or delete your
 account and information from our active databases. However, we may retain some
 information in our files to prevent fraud, troubleshoot problems, assist with any
 investigations, enforce our legal terms and/or comply with applicable legal
 requirements.</h4>
<h4> If you have questions or comments about your privacy rights, you may email us at
 info@achew.net.</h4>
<h2 ref={doNotTrackFeaturesRef}>8. Controls For Do-Not-Track Features</h2>
<h4> Most web browsers and some mobile operating systems and mobile applications
 include a Do-Not-Track ("DNT") feature or setting you can activate to signal your
 privacy preference not to have data about your online browsing activities monitored
 and collected. At this stage no uniform technology standard for recognizing and
 implementing DNT signals has been finalized. As such, we do not currently respond
 to DNT browser signals or any other mechanism that automatically communicates
 your choice not to be tracked online. If a standard for online tracking is adopted that
 we must follow in the future, we will inform you about that practice in a revised
 version of this privacy notice.</h4>
<h2 ref={usPrivacyRightsRef}>9. Do United States Residents Have Specific Privacy Rights?</h2>
<h4>In Short: If you are a resident of California, Colorado, Connecticut, Utah or Virginia,
 you are granted specific rights regarding access to your personal information.</h4>
 <h5>What categories of personal information do we collect?</h5>
 <h4>We have collected Sensitive personal information - Health data dn account login information in the past twelve months</h4>
 <h4> We will use and retain the collected personal information as needed to provide the
 Services or for:</h4>
 <p>Category L - As long as the user has an account with us</p>
 <h4>Category L information may be used, or disclosed to a service provider or contractor,
 for additional, specified purposes. You have the right to limit the use or disclosure of
 your sensitive personal information.</h4>
 <h4>We may also collect other personal information outside of these categories through
 instances where you interact with us in person, online, or by phone or mail in the
 context of:</h4>
 <p> Receiving help through our customer support channels;</p>
 <p>Participation in customer surveys or contests; and</p>
 <p>Facilitation in the delivery of our Services and to respond to your inquiries.</p>
 <h5> How do we use and share your personal information?</h5>
 <h4> Learn about how we use your personal information in the section, "HOW DO WE
 PROCESS YOUR INFORMATION?"</h4>
 <h5> Will your information be shared with anyone else?</h5>
 <h4>We may disclose your personal information with our service providers pursuant to a
 written contract between us and each service provider. Learn more about how we
 disclose personal information to in the section, "WHEN AND WITH WHOM DO WE
 SHARE YOUR PERSONAL INFORMATION?"</h4>
 <h4>We may use your personal information for our own business purposes, such as for
 undertaking internal research for technological development and demonstration. This
 is not considered to be "selling" of your personal information.</h4>
 <h4>We have not disclosed, sold, or shared any personal information to third parties for a
 business or commercial purpose in the preceding twelve (12) months. We will not sell
 or share personal information in the future belonging to website visitors, users, and
 other consumers.</h4>
 <h3>California Residents</h3>
 <h4> California Civil Code Section 1798.83, also known as the "Shine The Light" law
 permits our users who are California residents to request and obtain from us, once a
 year and free of charge, information about categories of personal information (if any)
 we disclosed to third parties for direct marketing purposes and the names and
 addresses of all third parties with which we shared personal information in the
 immediately preceding calendar year. If you are a California resident and would like
 to make such a request, please submit your request in writing to us using the contact
 information provided below.</h4>
 <h4> If you are under 18 years of age, reside in California, and have a registered account
 with the Services, you have the right to request removal of unwanted data that you
 publicly post on the Services. To request removal of such data, please contact us
 using the contact information provided below and include the email address
 associated with your account and a statement that you reside in California. We will
 make sure the data is not publicly displayed on the Services, but please be aware
 that the data may not be completely or comprehensively removed from all our
 systems (e.g., backups, etc.).</h4>
 <h5> CCPA Privacy Notice</h5>
 <h4>This section applies only to California residents. Under the California Consumer
 Privacy Act (CCPA), you have the rights listed below.
 The California Code of Regulations defines a "residents" as:
 (1) every individual who is in the State of California for other than a temporary or
 transitory purpose and
 (2) every individual who is domiciled in the State of California who is outside the
 State of California for a temporary or transitory purpose
 All other individuals are defined as "non-residents."
 If this definition of "resident" applies to you, we must adhere to certain rights and
 obligations regarding your personal information.</h4>

 <h5> Your rights with respect to your personal data</h5>
 <h4>Right to request deletion of the data — Request to delete
 You can ask for the deletion of your personal information. If you ask us to delete your
 personal information, we will respect your request and delete your personal
 information, subject to certain exceptions provided by law, such as (but not limited to)
 the exercise by another consumer of his or her right to free speech, our compliance
 requirements resulting from a legal obligation, or any processing that may be
 required to protect against illegal activities.</h4>
 <h4>Right to be informed — Request to know
 Depending on the circumstances, you have a right to know:</h4>
 <p> whether we collect and use your personal information;</p>
 <p> the categories of personal information that we collect;</p>
 <p> the purposes for which the collected personal information is used;</p>
 <p>whether we sell or share personal information to third parties;</p>
 <p> the categories of personal information that we sold, shared, or disclosed for a
 business purpose;</p>
 <p>the categories of third parties to whom the personal information was sold,
 shared, or disclosed for a business purpose;</p>
 <p> the business or commercial purpose for collecting, selling, or sharing personal
 information; and</p>
 <p>the specific pieces of personal information we collected about you.</p>
 <h4> In accordance with applicable law, we are not obligated to provide or delete
 consumer information that is de-identified in response to a consumer request or to re
identify individual data to verify a consumer request.</h4>
 <h4> Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
 We will not discriminate against you if you exercise your privacy rights.</h4>
 <h4> Right to Limit Use and Disclosure of Sensitive Personal Information
 If the business collects any of the following: </h4>
 <p>social security information, drivers' licenses, state ID cards, passport numbers</p>
 <p> account login information</p>
 <p>credit card numbers, financial account information, or credentials allowing
 access to such accounts</p>
 <p>precise geolocation</p>
 <p> racial or ethnic origin, religious or philosophical beliefs, union membership</p>
 <p> the contents of email and text, unless the business is the intended recipient of
 the communication</p>
 <p> genetic data, biometric data, and health data</p>
 <p> data concerning sexual orientation and sex life</p>
 <h4>you have the right to direct that business to limit its use of your sensitive personal
 information to that use which is necessary to perform the Services.</h4>
 <h4> Once a business receives your request, they are no longer allowed to use or disclose
 your sensitive personal information for any other purpose unless you provide consent
 for the use or disclosure of sensitive personal information for additional purposes.</h4>
 <h4> Please note that sensitive personal information that is collected or processed without
 the purpose of inferring characteristics about a consumer is not covered by this right,
 as well as the publicly available information.</h4>
 <h4> To exercise your right to limit use and disclosure of sensitive personal information,
 please email info@achew.net or visit: achew.net/doctor-login.</h4>
 <h4>Verification process</h4>
 <h4>Upon receiving your request, we will need to verify your identity to determine you are
 the same person about whom we have the information in our system. These
 verification efforts require us to ask you to provide information so that we can match it
 with information you have previously provided us. For instance, depending on the
 type of request you submit, we may ask you to provide certain information so that we
 can match the information you provide with the information we already have on file,
 or we may contact you through a communication method (e.g., phone or email) that
 you have previously provided to us. We may also use other verification methods as
 the circumstances dictate.</h4>
 <h4> We will only use personal information provided in your request to verify your identity
 or authority to make the request. To the extent possible, we will avoid requesting
 additional information from you for the purposes of verification. However, if we cannot
 verify your identity from the information already maintained by us, we may request
 that you provide additional information for the purposes of verifying your identity and
 for security or fraud-prevention purposes. We will delete such additionally provided
 information as soon as we finish verifying you.</h4>
 <h4> Other privacy rights</h4>
 <p>You may object to the processing of your personal information.</p>
 <p> You may request correction of your personal data if it is incorrect or no longer
 relevant, or ask to restrict the processing of the information.</p>
 <p> You can designate an authorized agent to make a request under the CCPA on
 your behalf. We may deny a request from an authorized agent that does not
 submit proof that they have been validly authorized to act on your behalf in
 accordance with the CCPA.</p>
 <p>You may request to opt out from future selling or sharing of your personal
 information to third parties. Upon receiving an opt-out request, we will act upon
 the request as soon as feasibly possible, but no later than fifteen (15) days
 from the date of the request submission.</p>
 <h4> To exercise these rights, you can contact us by visiting achew.net/doctor-login, by
 email at info@achew.net, or by referring to the contact details at the bottom of
 this document. If you have a complaint about how we handle your data, we would like
 to hear from you.</h4>
 <h3>Colorado Residents</h3>
 <h4>This section applies only to Colorado residents. Under the Colorado Privacy Act
 (CPA), you have the rights listed below. However, these rights are not absolute, and
 in certain cases, we may decline your request as permitted by law.</h4>
 <p>Right to be informed whether or not we are processing your personal data</p>
 <p> Right to access your personal data</p>
 <p>Right to correct inaccuracies in your personal data</p>
 <p> Right to request deletion of your personal data</p>
 <p> Right to obtain a copy of the personal data you previously shared with us</p>
 <p> Right to opt out of the processing of your personal data if it is used for targeted
 advertising, the sale of personal data, or profiling in furtherance of decisions
 that produce legal or similarly significant effects ("profiling")</p>
 <h4> To submit a request to exercise these rights described above, please email
 info@achew.net or visit achew.net/doctor-login.</h4>
 <h4>If we decline to take action regarding your request and you wish to appeal our
 decision, please email us at info@achew.net. Within forty-five (45) days of receipt
 of an appeal, we will inform you in writing of any action taken or not taken in
 response to the appeal, including a written explanation of the reasons for the
 decisions.</h4>
 <h3>Connecticut Residents</h3>
 <h4>This section applies only to Connecticut residents. Under the Connecticut Data
 Privacy Act (CTDPA), you have the rights listed below. However, these rights are not
 absolute, and in certain cases, we may decline your request as permitted by law.</h4>
 <p>Right to be informed whether or not we are processing your personal data</p>
 <p>Right to access your personal data</p>
 <p> Right to correct inaccuracies in your personal data</p>
 <p> Right to request deletion of your personal data</p>
 <p>Right to obtain a copy of the personal data you previously shared with us</p>
 <p>Right to opt out of the processing of your personal data if it is used for targeted
 advertising, the sale of personal data, or profiling in furtherance of decisions
 that produce legal or similarly significant effects ("profiling")</p>
 <h4>To submit a request to exercise these rights described above, please email
 info@achew.net or visit achew.net/doctor-login.</h4>
 <h4> If we decline to take action regarding your request and you wish to appeal our
 decision, please email us at info@achew.net. Within sixty (60) days of receipt of
 an appeal, we will inform you in writing of any action taken or not taken in response
 to the appeal, including a written explanation of the reasons for the decisions.</h4>
 <h3>Utah Residents</h3>
 <h4>This section applies only to Utah residents. Under the Utah Consumer Privacy Act
 (UCPA), you have the rights listed below. However, these rights are not absolute, and
 in certain cases, we may decline your request as permitted by law.</h4>
 <p> Right to be informed whether or not we are processing your personal data</p>
 <p>Right to access your personal data</p>
 <p>Right to request deletion of your personal data</p>
 <p> Right to obtain a copy of the personal data you previously shared with us</p>
 <p> Right to opt out of the processing of your personal data if it is used for targeted
 advertising or the sale of personal data</p>
 <h4> To submit a request to exercise these rights described above, please email
 info@achew.net or visit achew.net/doctor-login.</h4>
 <h3>Virginia Residents</h3>
 <h4>Under the Virginia Consumer Data Protection Act (VCDPA):</h4>
 <h4>"Consumer" means a natural person who is a resident of the Commonwealth acting
 only in an individual or household context. It does not include a natural person acting
 in a commercial or employment context.</h4>
 <h4> "Personal data" means any information that is linked or reasonably linkable to an
 identified or identifiable natural person. "Personal data" does not include de-identified
 data or publicly available information.</h4>
 <h4> "Sale of personal data" means the exchange of personal data for monetary
 consideration.</h4>
 <h4> If this definition of "consumer" applies to you, we must adhere to certain rights and
 obligations regarding your personal data.</h4>
 <h4> Your rights with respect to your personal data</h4>
 <p> Right to be informed whether or not we are processing your personal data</p>
 <p> Right to access your personal data</p>
 <p> Right to correct inaccuracies in your personal data</p>
 <p> Right to request deletion of your personal data</p>
 <p> Right to obtain a copy of the personal data you previously shared with us</p>
 <p>Right to opt out of the processing of your personal data if it is used for targeted
 advertising, the sale of personal data, or profiling in furtherance of decisions
 that produce legal or similarly significant effects ("profiling")</p>
 <h4> Exercise your rights provided under the Virginia VCDPA</h4>
 <h4>You may contact us by email at info@achew.net or visit achew.net/doctor-login.
 If you are using an authorized agent to exercise your rights, we may deny a request if
 the authorized agent does not submit proof that they have been validly authorized to
 act on your behalf.</h4>
 <h4>Verification process</h4>
 <h4>We may request that you provide additional information reasonably necessary to
 verify you and your consumer's request. If you submit the request through an
 authorized agent, we may need to collect additional information to verify your identity
 before processing your request.</h4>
 <h4>Right to appeal</h4>
 <h4> If we decline to take action regarding your request, we will inform you of our decision
 and reasoning behind it. If you wish to appeal our decision, please email us at
 info@achew.net. Within sixty (60) days of receipt of an appeal, we will inform you
 in writing of any action taken or not taken in response to the appeal, including a
 written explanation of the reasons for the decisions. If your appeal is denied, you may
 contact the Attorney General to submit a complaint.</h4>



<h2 ref={updatesNoticeRef}>10. Do We Make Updates To This Notice?</h2>
<h4>In Short: Yes, we will update this notice as necessary to stay compliant with relevant
 laws.</h4>
<h4> We may update this privacy notice from time to time. The updated version will be
 indicated by an updated "Revised" date and the updated version will be effective as
 soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly
 sending you a notification. We encourage you to review this privacy notice frequently
 to be informed of how we are protecting your information.</h4>
<h4> If you have questions or comments about this notice, you may email us at
 info@achew.net or contact us by post at:
 Achew Inc.
 420 Elizabeth Ave Unit B
 Monterey Park, CA 91755
 United States</h4>
<h2 ref={contactUsNoticeRef}>11. How Can You Contact Us About This Notice?</h2>
<h4>If you have questions or comments about this notice, you may email us at
 info@achew.net or contact us by post at:
 Achew Inc.
 420 Elizabeth Ave Unit B
 Monterey Park, CA 91755
 United States</h4>
<h2 ref={reviewUpdateDeleteDataRef}>12. How Can You Review, Update, Or Delete The Data We Collect From You?</h2>
<h4> Based on the applicable laws of your country, you may have the right to request
 access to the personal information we collect from you, change that information, or
 delete it. To request to review, update, or delete your personal information, please
 visit: achew.net/doctor-login.</h4>
        </div>
        
    );
};

export default PrivacyPolicy;
