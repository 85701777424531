import React, { useState, useEffect, useRef } from "react";
import insuranceSampleData from "./../../../../sampledata/insurance.json";
import { chevron_down, shield_icon } from "../../home/image.jsx";
import { getInsuranceById, getPlanName } from "../../../assets/functions/functions.js";
const InsurancePicker = ({
  setInsuranceID,
  setPlanID,
  handleInsuranceChosen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [displayedInsurancePlan, setDisplayedInsurancePlan] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [filteredInsuranceData, setFilteredInsuranceData] =
    useState(insuranceSampleData);
  const [validInsuranceChosen, setValidInsuranceChosen] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState(null)
  const [selectedPlan,  setSelectedPlan] = useState(null)
  const [step, setStep] = useState(-1)
  useEffect(() => {
    const closeDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setStep(-1)
      }
    };

    window.addEventListener('click', closeDropdown);
    return () => {
      window.removeEventListener('click', closeDropdown);
    };
  }, []);

  const handleInsuranceSelect = (insurance) => {
    setSelectedInsurance(insurance);
    setStep(1)
  };

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan)
    setInsuranceID(selectedInsurance.id);
    setPlanID(plan.id);
    setStep(-1)
    if(handleBackToInsurance != undefined){
      handleInsuranceChosen(selectedInsurance.id, plan.id)
    }
  };

  const handleOpenDropdown = () => {
    setSelectedInsurance(null); 
    setSelectedPlan(null);
    setInsuranceID(null)
    setPlanID(null)
  }

  const filterProvidersByNamePrefix = (data, prefix) => {
    // Filter popular providers by name
    const filteredPopular = data.popular.filter((provider) =>
      provider.name.toLowerCase().startsWith(prefix.toLowerCase())
    );

    // Filter all providers by name
    const filteredAll = data.all.filter((provider) =>
      provider.name.toLowerCase().startsWith(prefix.toLowerCase())
    );

    // Return the filtered results
    return {
      popular: filteredPopular,
      all: filteredAll,
    };
  };

  const handleBackToInsurance = () => {
    setFilterValue("");
    setSelectedPlan(null);
    setSelectedInsurance(null);
    setInsuranceID("");
    setPlanID("");
    setValidInsuranceChosen(false);
    setFilteredInsuranceData(insuranceSampleData);
    setStep(0)
  };

  const renderScreen = () => {
      return (
        <ul className="insurance-dropdown-content">
          <p className="insurance-dropdown-section-label">Popular</p>
          {filteredInsuranceData.popular.map((option, index) => (
            <li key={index} onClick={() => handleInsuranceSelect(option)}>
              {option.name}
            </li>
          ))}

          <p className="insurance-dropdown-section-label">All insurances</p>
          {filteredInsuranceData.all.map((option, index) => (
            <li key={index} onClick={() => handleInsuranceSelect(option)}>
              {option.name}
            </li>
          ))}
        </ul>
      );
  };

  const renderScreen2 =  () => {
    return (
      <ul className="insurance-dropdown-content">
        <div
          className="insurance-dropdown-header"
          onClick={() => handleBackToInsurance()}
        >
          <i class="fas fa-arrow-left"></i>
          <p className="insurance-dropdown-section-label">
            Back to Insurance Carriers
          </p>
        </div>
        <p className="insurance-dropdown-section-label">
          {selectedInsurance.name}
        </p>
        {selectedInsurance.plans.map((plan, index) => (
          <li key={index} onClick={() => handlePlanSelect(plan)}>
            {plan.title}
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div
      className="insurance-picker-input-container"
    >
      <div className="insurance-picker-search-container" onClick={() => {step === -1 ? setStep(0) : setStep(-1); handleOpenDropdown()}}>
        <div className="d-flex align-items-center" >
          <div className="home-search-bar-img-container">
            <img alt="Shield" src={shield_icon} />
          </div>
          {selectedInsurance === null || selectedPlan === null ? <h2>Pick your insurance and plan</h2> : <h2>{selectedInsurance.name} {selectedPlan.title}</h2>}
        </div>
        
        <div className="home-search-bar-img-container">
          <img alt="Shield" src={chevron_down} />
        </div>
      </div>
      
      
      {step === 0 && renderScreen()}
      {step === 1 && renderScreen2()}
    </div>
  );
};

export default InsurancePicker;
