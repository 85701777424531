import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../patientheader';
import Footer from '../../footer';
import { requestPatientToken, requestPatientTokenV2, requestSMSCode } from '../../../../api/patientAPIs';
import AchewHeader from '../../achew-header';
import AchewFooter from '../../achew-footer';
import { formatPhoneNumber2, formatPhoneNumber3 } from '../../../assets/functions/functions';
import Carousel from "react-bootstrap/Carousel";
import LoadingSpinner from '../bookingv2/spinner';

const AppointmentRetrieval = () => {
    const [code, setCode] = useState(null)
    const [lastName, setLastName] = useState("")
    const [phone, setPhone] = useState("")
    const authToken = localStorage.getItem("fartyAuth")

    const [carouselPage, setCarouselPage] = useState(0)
    const [isErrorShowing, setErrorShowing] = useState(false)
    const [spinnerLoading, setSpinnerLoading] = useState(false)

    const navigate = useNavigate()

    const continueToSMSVerification = (e) => {
        e.preventDefault();
        const cleanedPhoneNumber = formatPhoneNumber3(phone)
        if(!cleanedPhoneNumber || cleanedPhoneNumber === "" || lastName === ""){
            setErrorShowing(true)
        } else {
            setCarouselPage(1)
            requestSMSCode(cleanedPhoneNumber).then(response => {
                if (response.status >= 200 && response.status < 300) {
    
                }
            })
        }
        
    }

    const submitRetrievalRequest = (e) => {
        e.preventDefault()
        setSpinnerLoading(true)
        window.scrollTo(0, 0)
        const cleanedPhoneNumber = formatPhoneNumber2(phone)
        requestPatientTokenV2(code, cleanedPhoneNumber, lastName).then(response => {
            if ( response.status >= 200 && response.status < 300) {
                localStorage.setItem("fartyAuth", response.data.token)
                navigate(`/patient-appointment?page_num=${1}`)
            } else {
                setErrorShowing(true)
            }

            setSpinnerLoading(false)
        })
    }

    const handlePhoneChange = (e) => {
        // Remove non-numeric characters and limit to 10 digits
        const cleanedInput = e.target.value.replace(/\D/g, "").substring(0, 10);

        // Format the phone number
        let formattedPhoneNumber = "";

        if (cleanedInput.length >= 1) {
            formattedPhoneNumber = `(${cleanedInput.substring(0, 3)}`;

            if (cleanedInput.length >= 4) {
                formattedPhoneNumber += `) ${cleanedInput.substring(3, 6)}`;

                if (cleanedInput.length >= 7) {
                    formattedPhoneNumber += `-${cleanedInput.substring(6, 10)}`;
                }
            }
        }

        setPhone(formattedPhoneNumber);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (authToken) {
            navigate(`/patient-appointment?page_num=${1}`)
        }
    }, []);

    const firstPage = () => {
        return (
            <form className='retrieval-subcontainer ' onSubmit={continueToSMSVerification}>
                <h2 className='retrieval-title'>
                    Find and manage your appointment
                </h2>
                <h3 className='retrieval-description'>
                    To search an existing appointment, please provide your last name and the phone number you used to book the appointment.
                </h3>
                <div className='retrieval-input-container'>
                    <div className='retrieval-input-subcontainer retrieval-input-subcontainer-margin'>
                        <h1>Last Name</h1>
                        <input
                            className="confirm-input"
                            placeholder='Enter your last name'
                            onChange={(e) => setLastName(e.currentTarget.value)}
                            value={lastName}
                        />
                    </div>
                    <div className='retrieval-input-subcontainer'>
                        <h1>Phone number</h1>
                        <input
                            className="confirm-input"
                            placeholder='(123) 456-7890'
                            onChange={handlePhoneChange}
                            value={phone}
                        />
                    </div>
                </div>
                {isErrorShowing && <h4 className='retrieval-error'>*Please enter a valid phone number and last name.</h4>}
                <button type="submit" className='retrieval-search-btn' >Continue</button>
            </form>
        )
    }

    const secondPage = () => {
        return (
            <form className='retrieval-subcontainer' onSubmit={submitRetrievalRequest} >
                <h2 className='retrieval-title'>
                    Find and manage your appointment
                </h2>
                <h3 className='retrieval-description'>
                    Enter the 6-digit confirmation code sent to {phone}
                </h3>
                <div className='retrieval-input-subcontainer' style={{marginTop:'40px'}}>
                    <h1>Confirmation Code</h1>
                    <input
                        className="confirm-input"
                        placeholder='XXXXXX'
                        onChange={(e) => setCode(e.currentTarget.value)}
                        value={code}
                    />
                </div>
                {isErrorShowing && <h4 className='retrieval-error'>*We couldn't find the appointments made with the given last name and phone number.</h4>}
                <button type='submit' className='retrieval-search-btn'>{spinnerLoading ? <LoadingSpinner/> : "Find Appointments"}</button>
            </form>
        )
    }

    return (
        <>
            <AchewHeader />
            <div className='retrieval-container'>
                <Carousel controls={false} indicators={false} activeIndex={carouselPage}>
                    <Carousel.Item>{firstPage()}</Carousel.Item>
                    <Carousel.Item>{secondPage()}</Carousel.Item>
                </Carousel>
            </div>
            <AchewFooter />
        </>
    );
}

export default AppointmentRetrieval;