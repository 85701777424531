import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  RegisterDoctor,
  checkNPIRegistry,
  requestSMSCode,
  verifySMSCode,
} from "../../../../api/patientAPIs.js";
import specialtiesData from '../../../../sampledata/specialties.json'
import {
  getLast4DigitsAsString,
  isValidPassword,
  usStatesAbbreviations,
  validateNPI,
} from "../../../assets/functions/functions.js";
import Loading from "../../loading.jsx";
import AchewHeader from "../../achew-header.jsx";
import {
  appointment_background,
  booking_doctor,
  chevron_down,
  sample_doctor,
} from "../../home/image.jsx";
import AchewFooter from "../../patients/bookingv2/booking-footer.jsx";
import Carousel from "react-bootstrap/Carousel";
import LoadingSpinner from "../../patients/bookingv2/spinner.jsx";
import { RequestHospitalRegistration, RequestLogin, RequestRegister } from "../../../../api/auth.js";
import { Loader } from '@googlemaps/js-api-loader';

const Booking = () => {
  /* Universal */
  const navigate = useNavigate();
  const location = useLocation();
  const [missingFields, setMissingFields] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [geocoder, setGeocoder] = useState(null)

  /* First Page */
  const [isValidEma, setIsValidEma] = useState(true);
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [password, setPassword] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [phone, setPhone] = useState(null);
  const [isRoleDropdownOpen, setIsRoleDropdownOpen] = useState(false);
  const [isSizeDropdownOpen, setIsSizeDropdownOpen] = useState(false);
  const roleDropdownRef = useRef(null);
  const sizeDropdownRef = useRef(null);
  const specialtyDropdownRefHospital = useRef(null)
  const [isSpecialtyDropdownOpenHospital, setSpecialtyDropdownOpenHospital] = useState(false)
  const [hospitalSpecialty, setHospitalSpecialty] = useState(null)

  /* Third Page */
  const stateDropdownRef = useRef(null);
  const [isStateDropdownOpen, setStateDropdownOpen] = useState(false)
  const [practiceName, setPracticeName] = useState(null);
  const [practiceEmail, setPracticeEmail] = useState(null)
  const [practiceWebsite, setPracticeWebsite] = useState(null)
  const [practiceSpecialty, setPracticeSpecialty] = useState(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [npi, setNPI] = useState(null);
  const [zip, setZip] = useState(null);
  const [address, setAddress] = useState("");
  const [apt, setApt] = useState("");
  const [city, setCity] = useState("");
  const [statee, setStatee] = useState(null);
  const [providerFirstName, setProviderFirstName] = useState(null);
  const [providerLastName, setProviderLastName] = useState(null);
  const [providerSpecialty, setProviderSpecialty] = useState(null)
  const [providerAbout, setProviderAbout] = useState(null)
  const [practicePhone, setPracticePhone] = useState(null)
  const specialtyDropdownRef = useRef(null)
  const [isSpecialtyDropdownOpen, setSpecialtyDropdownOpen] = useState(false)


  const toggleRoleDropdown = () => {
    setIsRoleDropdownOpen(!isRoleDropdownOpen);
  };

  const handleRoleDropdownSelected = (role) => {
    setSelectedRole(role);
    setIsRoleDropdownOpen(false);
  };

  const toggleSizeDropdown = () => {
    setIsSizeDropdownOpen(!isSizeDropdownOpen);
  };

  const handleSizeDropdownSelected = (size) => {
    setSelectedSize(size);
    setIsSizeDropdownOpen(false);
  };

  const openNewTab = (path) => {
    window.open(path, '_blank');
   };

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_KEY,
      version: 'weekly',
      libraries: ['geometry', 'places'],
    });
    loader.load().then((google) => {
      setGeocoder(new google.maps.Geocoder())

    });
    const handleClickRoleOutside = (event) => {
      if (
        roleDropdownRef.current &&
        !roleDropdownRef.current.contains(event.target)
      ) {
        setIsRoleDropdownOpen(false);
      }
    };
    const handleClickSizeOutside = (event) => {
      if (
        sizeDropdownRef.current &&
        !sizeDropdownRef.current.contains(event.target)
      ) {
        setIsSizeDropdownOpen(false);
      }
    };

    const handleClickStateOutside = (event) => {
      if (
        stateDropdownRef.current &&
        !stateDropdownRef.current.contains(event.target)
      ) {
        setStateDropdownOpen(false);
      }
    };
    const handleClickSpecialtyOutside = (event) => {
      if (specialtyDropdownRef.current && !specialtyDropdownRef.current.contains(event.target)) {
        setSpecialtyDropdownOpen(false);
      }
    };
    const handleClickSpecialtyHospitalOutside = (event) => {
      if (specialtyDropdownRefHospital.current && !specialtyDropdownRefHospital.current.contains(event.target)) {
        setSpecialtyDropdownOpenHospital(false);
      }
    };
    // Attach the event listener
    window.addEventListener('click', handleClickSpecialtyHospitalOutside);
    window.addEventListener('click', handleClickSpecialtyOutside);
    window.addEventListener("click", handleClickRoleOutside);
    window.addEventListener("click", handleClickSizeOutside);
    window.addEventListener("click", handleClickStateOutside);
    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener("click", handleClickRoleOutside);
      window.removeEventListener("click", handleClickSizeOutside);
      window.removeEventListener("click", handleClickStateOutside);
      window.removeEventListener('click', handleClickSpecialtyOutside);
    };
  }, []);

  const handlePracticePhoneChange = (e) => {
    // Remove non-numeric characters and limit to 10 digits
    const cleanedInput = e.target.value.replace(/\D/g, "").substring(0, 10);

    // Format the phone number
    let formattedPhoneNumber = "";

    if (cleanedInput.length >= 1) {
      formattedPhoneNumber = `(${cleanedInput.substring(0, 3)}`;

      if (cleanedInput.length >= 4) {
        formattedPhoneNumber += `) ${cleanedInput.substring(3, 6)}`;

        if (cleanedInput.length >= 7) {
          formattedPhoneNumber += `-${cleanedInput.substring(6, 10)}`;
        }
      }
    }

    setPracticePhone(formattedPhoneNumber);
  };

  const shiftCarouselLeft = () => {
    setCurrentPage(currentPage - 1);
  };

  const shiftCarouselRight = () => {
    setCurrentPage(currentPage + 1);
  };

  const firstPage = () => {
    return (
      <div className="booking-modal-container">
        <div className="booking-container-header">
          <div className="booking-container-header-txt">
            <h1 className="booking-intro-label">
              Register your practice with Achew
            </h1>
            <p className="booking-intro-labelp">
              Promote your practice and manage your bookings
            </p>
          </div>
          <img alt="Booking" src={booking_doctor} />
        </div>
        <div className="booking-basic-info-section booking-basic-info-section-less-padding">
          <h1 className="booking-intro-label">Tell us about your practice</h1>
          <p className="booking-intro-labelp">
            Create your clinic profile
          </p>
          <h3 className="booking-email-label">Practice name</h3>
          <input
            className="email-input"
            value={practiceName}
            placeholder="Ex. Smiles Dental Clinic"
            onChange={(c) => setPracticeName(c.currentTarget.value)}
          />
          <h3 className="booking-email-label">Practice email</h3>
          <input
            className="email-input"
            value={practiceEmail}
            placeholder="Ex. smiledental@gmail.com"
            onChange={(c) => setPracticeEmail(c.currentTarget.value)}
          />
          <h3 className="booking-email-label">Practice website (optional)</h3>
          <input
            className="email-input"
            value={practiceWebsite}
            placeholder="www.smiledentalclinic.com"
            onChange={(c) => setPracticeWebsite(c.currentTarget.value)}
          />
          <h3 className="booking-email-label">Specialty</h3>
          <div
            className="booker-reason-input-container"
            ref={specialtyDropdownRefHospital}
            style={{ marginBottom: '20px' }}
          >
            <div className="booker-body-dropdown" onClick={() => setSpecialtyDropdownOpenHospital(!isSpecialtyDropdownOpenHospital)}>
              <h1>
                {hospitalSpecialty != null
                  ? hospitalSpecialty
                  : "Choose this clinic's main specialty"}
              </h1>
              <img alt="chevron down" src={chevron_down} />
            </div>
            {isSpecialtyDropdownOpenHospital && (
              <ul className="booker-dropdown-menu">
                {specialtiesData.all_specialties.map((option, index) => (
                  <li key={index} onClick={() => { setHospitalSpecialty(option); setSpecialtyDropdownOpenHospital(false) }}>
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <h3 className="booking-email-label">National Provider Identifier (NPI)</h3>
          <input
            className="email-input"
            value={npi}
            maxLength={10}
            placeholder="Enter your NPI"
            onChange={(c) => setNPI(c.currentTarget.value)}
          />
        </div>

        <div className="booking-basic-info-section-secondary">
          <h1 className="booking-intro-label">Practice location</h1>
          <p className="booking-intro-labelp">
            Where are you located? You can add more locations later if needed.
          </p>
          <h3 className="booking-email-label">Street address</h3>
          <input
            className="email-input"
            name="address-line1"
            autoComplete="address-line1"
            value={address}
            placeholder="Enter your street address"
            onChange={(c) => setAddress(c.currentTarget.value)}
          />
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">
                Apt, suite, building (optional)
              </h3>
              <input
                className="name-input"
                name="address-line2"
                autoComplete="address-line2"
                placeholder="Enter apt, suite, or building"
                value={apt}
                onChange={(c) => setApt(c.currentTarget.value)}
              />
            </div>

            <div className="email-input-wrapper">
              <h3 className="booking-email-label">City</h3>
              <input
                className="name-input2"
                name="city"
                autoComplete="address-level2"
                placeholder="Enter city"
                value={city}
                onChange={(c) => setCity(c.currentTarget.value)}
              />
            </div>
          </span>
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">State</h3>

              <div className="booker-input-container" ref={stateDropdownRef}>
                <div className="booker-dropdown" onClick={() => setStateDropdownOpen(!isStateDropdownOpen)}>
                  <h2>{statee ? statee : "Choose your state"}</h2>
                  <img alt="chevron down" src={chevron_down} />
                </div>
                {isStateDropdownOpen && (
                  <ul className="booker-dropdown-menu">
                    {usStatesAbbreviations.map(
                      (option, index) => (
                        <li
                          key={index}
                          onClick={() => { setStatee(option); setStateDropdownOpen(false) }}
                        >
                          {option}
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
            </div>

            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Zip code</h3>
              <input
                className="name-input2"
                name="postal-code"
                autoComplete="postal-code"
                placeholder="Enter your zip code"
                value={zip}
                onChange={(c) => setZip(c.currentTarget.value)}
              />
            </div>
          </span>
          <h3 className="booking-email-label">Phone number</h3>
          <input
            className="email-input"
            value={practicePhone}
            placeholder="(012) 345-6789"
            onChange={handlePracticePhoneChange}
          />
        </div>

        <form
          className="booking-basic-info-section-third"
        >
          <h1 className="booking-intro-label">Healthcare Provider</h1>
          <p className="booking-intro-labelp">
            Who is the healthcare provider in your practice. You will have the chance to add more providers later.
          </p>
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">
                First name
              </h3>
              <input
                className="name-input"
                name="first"
                placeholder="Enter provider's first name"
                value={providerFirstName}
                onChange={(c) => setProviderFirstName(c.currentTarget.value)}
              />
            </div>

            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Last name</h3>
              <input
                className="name-input2"

                placeholder="Enter provider's last name"
                value={providerLastName}
                onChange={(c) => setProviderLastName(c.currentTarget.value)}
              />
            </div>
          </span>

          <h3 className="booking-email-label">Specialty</h3>
          <div
            className="booker-reason-input-container"
            ref={specialtyDropdownRef}
            style={{ marginBottom: '20px' }}
          >
            <div className="booker-body-dropdown" onClick={() => setSpecialtyDropdownOpen(!isSpecialtyDropdownOpen)}>
              <h1>
                {providerSpecialty != null
                  ? providerSpecialty
                  : "Choose this provider's main specialty"}
              </h1>
              <img alt="chevron down" src={chevron_down} />
            </div>
            {isSpecialtyDropdownOpen && (
              <ul className="booker-dropdown-menu">
                {specialtiesData.all_specialties.map((option, index) => (
                  <li key={index} onClick={() => { setProviderSpecialty(option); setSpecialtyDropdownOpen(false) }}>
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <h3 className="booking-email-label">About this provider (optional)</h3>
          <textarea
            className="email-input email-textarea"
            placeholder="Enter a description of this provider. Education, years of experience, certifications, degrees."
            value={providerAbout}
            onChange={(c) => setProviderAbout(c.currentTarget.value)}
          />

        </form>

        <div className="booking-basic-info-section-banner">
          <h1>
            Reminder: Your practice will be verified by Achew before your profile is made visible to potential patients.
          </h1>
          <h2>
            Please review Achew's FAQ to read about the full appointment process
          </h2>
        </div>
        <div className="booking-basic-info-section-terms">
          <input
            type="checkbox"
            checked={termsChecked}
            onChange={(c) => setTermsChecked(!termsChecked)}
          />
          <h1>
            Before continuing, please review and accept our <button onClick={() => openNewTab('/support/terms')} className="interal-link-button">Terms and Conditions</button> and <button onClick={() => openNewTab('/support/private-policy')} className="interal-link-button">Privacy Policy</button> 
            for Achew, as your use of our services is subject to these rules and
            regulations. By checking the box, you signify your agreement to be
            bound by these terms.
          </h1>
        </div>
        <div className="booking-missing-fields-container">
        {missingFields.length > 0 && (
          <h1 className="missingFields">
            * Please check these fields for errors: {missingFields.toString()}
          </h1>
        )}
        </div>
        
        <button className="finish-booking-btn" onClick={() => submitRegistration()}>
          {isBooking ? <LoadingSpinner /> : "Register Clinic"}
        </button>
      </div>
    );
  };

  const submitRegistration = async (e) => {
    const tempMissingFields = [];
    if (practiceName == null || practiceName === "") {
      tempMissingFields.push("  Name of practice");
    }
    if (practiceEmail == null || practiceEmail === "") {
      tempMissingFields.push("  Practice Email");
    }
    if (hospitalSpecialty == null || hospitalSpecialty === "") {
      tempMissingFields.push("  Hospital Specialty");
    }
    if (npi == null || npi === "" || !validateNPI(parseInt(npi))) {
      tempMissingFields.push("  Please enter a valid NPI");
    }

    if (address == null || address.length == 0) {
      tempMissingFields.push(" address");
    }
    if (city === "" || city == null) {
      tempMissingFields.push(" city");
    }
    if (statee === "" || statee == null) {
      tempMissingFields.push(" state");
    }
    if (zip == null || !zip) {
      tempMissingFields.push(" zip");
    }
    if (providerFirstName == null || providerFirstName === "") {
      tempMissingFields.push("  Provider's first name");
    }
    if (providerLastName == null || providerLastName === "") {
      tempMissingFields.push("  Provider's last name");
    }
    if (providerSpecialty == null || providerSpecialty === "") {
      tempMissingFields.push("  Provider's specialty");
    }
    if (termsChecked == null || termsChecked === false) {
      tempMissingFields.push("  Please agree to Achew's terms and conditions");
    }
    const isValidNPI = await checkNPIRegistry(npi) 
    // if(isValidNPI) {
    //   tempMissingFields.push(" We couldn't find your npi number");
    // }
    if (tempMissingFields.length !== 0) {
      setMissingFields(tempMissingFields);
      return;
    } else {
      setMissingFields([]);
    }
    setIsBooking(true)
    geocoder.geocode({ address: `${apt}, ${address} , ${city}, ${statee} ${zip}, United States` }, (results, status) => {
      if (results) {
        const location = results[0].geometry.location;
        const latitude = location.lat();
        const longitude = location.lng();
        const coordinates = {
          "longitude": longitude,
          "latitude": latitude,
        }
        const registrationData = {
          "doctors": [],
          "is_profile_active": true,
          "hospital_email": practiceEmail,
          "hospital_website": practiceWebsite,
          "hospital_images": [],
          "hospital_name": practiceName,
          "hospital_phone": "1123",
          "location_info": {
            "street_address": address,
            "street_address_2": apt,
            "city": city,
            "state": statee,
            "zip": zip,
            "phone": phone,
            "coordinates": coordinates
          },
          "latitude": coordinates.latitude,
          "longitude": coordinates.longitude,
          "main_doctor": "123",
          "presentation_mode": "hospital",
          "profile_image_url": "",
          "main_specialty_title": hospitalSpecialty,
          "npi_number": npi,
          "rating": 0,
          "num_reviews": 0,
          "verified": true,
          "accepted_insurance": [],
          "about": `${practiceName} hasn't yet added a description`,
          "services": [],
          "sample_reviews": [],
          "promotions": [],
          "timeline": [],
          "owner": []
        }
        const doctorData = {
          "doctor_name_first": providerFirstName,
          "doctor_name_last": providerLastName,
          "doctor_name_middle": "",
          "education": { "school": "" },
          "work_experience": { "years": 0 },
          "awards": {},
          "degrees": { "degree": "" },
          "gender": "Not Selected",
          "specialty": providerSpecialty,
          "about": "No description given",
          "services": ["General Appointment"],
          "allow_new_patient": true
        }
        RequestHospitalRegistration(registrationData).then(hospitalResult => {
          RegisterDoctor(doctorData, hospitalResult.data).then(doctorResult => {

            navigate(`/summary?hospital=${hospitalResult.data.external_id}`)

          })

        })
      } else {
        console.log("Location not found")
      }
    })
  };

  return (
    <div className="booking-container">
      <div className="booking-background-container">
        <img alt="background" src={appointment_background} />
      </div>
      <Carousel controls={false} indicators={false} activeIndex={currentPage}>
        <Carousel.Item>{firstPage()}</Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Booking;
