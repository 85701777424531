import React, { useState, useEffect, useRef } from "react";
import {
  acceptAppointment,
  modifyAppointment,
  declineAppointment,
  addEvent,
  getHospitalInfoByExternalID,
} from "../../../../../api/patientAPIs";
import reasons_to_cancel from "../../../../../sampledata/reasons_to_cancel.json";
import ReactModal from "react-modal";
import {
  daysOfWeek,
  formatDateToLongString,
  getClinicProfilePicture,
  halfHourTimeIntervals,
  months,
} from "../../../../assets/functions/functions";
import { chevron_down, filled_star } from "../../../home/image";
import MiniCalendar from "../../../patients/doctorprofile/mini-calendar/datepicker";
import LoadingSpinner from "../../../patients/bookingv2/spinner";
const ConfirmationModal = ({
  onRequestClose,
  appointmentData,
  modalType,
  selectedRescheduleDate,
  patient,
  confirmationModalData,
}) => {
  const [isReasonOpen, setReasonOpen] = useState(false);
  const reasonDropDownRef = useRef(null);
  const [reason, setReason] = useState("Select a reason for cancelling");
  const [message, setMessage] = useState("");
  const [hospitalData, setHospitalData] = useState(null);

  //Rescheduling
  const [displayedDate, setDisplayedDate] = useState(new Date());
  const [isRescheduleOpen, setRescheduleOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [isTimeOpen, setTimeOpen] = useState(false);
  const [isHandlingConfirmation, setHandlingConfirmation] = useState(false);
  const timeDropdownRef = useRef(null);
  const dateDropdownRef = useRef(null);

  const accept = () => {
    setHandlingConfirmation(true);
    acceptAppointment(
      appointmentData.id,
      "",
      appointmentData.h_id,
      patient
    ).then((response) => {
      setHandlingConfirmation(false);
      onRequestClose();
    });
    window.location.reload();
  };

  const modify = () => {
    setHandlingConfirmation(true);
    modifyAppointment(
      appointmentData.id,
      "",
      "",
      appointmentData.h_id,
      selectedTime,
      displayedDate,
      patient
    ).then((response) => {
      //console.log(response);
      if (response.status === 200) {
      }
      setHandlingConfirmation(false);
      onRequestClose();
    });

    if (patient) {
      patientReschedule(selectedRescheduleDate);
    }
    window.location.reload();
  };

  const decline = () => {
    setHandlingConfirmation(true);
    declineAppointment(
      appointmentData.id,
      "",
      "",
      appointmentData.h_id,
      patient
    ).then((response) => {
      if (response.status === 200) {
      }
      setHandlingConfirmation(false);
      onRequestClose();
    });
    if (patient) {
      patientDecline();
    }
    window.location.reload();
  };

  const patientDecline = () => {
    const patient = appointmentData.patient_info;
    const fullName = `${patient.patient_name_first} ${patient.patient_name_last}`;
    const evt = {
      alert_type: "Appointment",
      appointment_id: appointmentData.external_id,
      internal_id: appointmentData.id,
      message: `${fullName} has canceled the appointment scheduled on ${new Date(
        appointmentData.date
      ).toDateString()}, ${new Date(
        appointmentData.date
      ).toLocaleTimeString()}`,
      date: Date.now(),
      unread: true,
    };
    const tempTimeline = hospitalData.timeline;
    tempTimeline.push(evt);
    addEvent(appointmentData.h_id, tempTimeline).then((res) => {
      console.log("Canceled appointment");
    });
  };

  const patientReschedule = (date) => {
    const patient = appointmentData.patient_info;
    const fullName = `${patient.patient_name_first} ${patient.patient_name_last}`;
    const evt = {
      alert_type: "Appointment",
      appointment_id: appointmentData.external_id,
      internal_id: appointmentData.id,
      message: `${fullName} has rescheduled the appointment to ${new Date(
        date
      ).toDateString()}, ${new Date(date).toLocaleTimeString()}`,
      date: Date.now(),
      unread: true,
    };
    const tempTimeline = hospitalData.timeline;
    tempTimeline.push(evt);
    addEvent(appointmentData.h_id, tempTimeline).then((res) => {
      console.log("Reschedule appointment");
    });
  };

  const renderConfirmButton = () => {
    if (modalType === "ACCEPT") {
      return (
        <button
          className="confirmation-modal-button accept"
          onClick={() => accept()}
        >
          {isHandlingConfirmation ? <LoadingSpinner /> : "Accept"}
        </button>
      );
    }
    if (modalType === "DECLINE") {
      return (
        <button
          className="confirmation-modal-button decline"
          onClick={() => decline()}
        >
          {isHandlingConfirmation ? <LoadingSpinner /> : "Decline"}
        </button>
      );
    }
    if (modalType === "MODIFY") {
      return (
        <button
          className={selectedRescheduleDate && selectedTime ? "confirmation-modal-button confirm" : "confirmation-modal-button disabled"}
          disabled={selectedRescheduleDate && selectedTime}
          onClick={() => modify()}
        >
          {isHandlingConfirmation ? <LoadingSpinner /> : "Reschedule"}
        </button>
      );
    }
  };
  const renderReasonToCancel = (data) => {
    return (
      <div
        className="appointment-reason"
        onClick={() => {
          setReason(data);
          setReasonOpen(false);
        }}
      >
        <h3>{data}</h3>
      </div>
    );
  };

  const renderTimeSlot = (data) => {
    return (
      <div
        className="confirmation-timeslot"
        onClick={() => {
          setSelectedTime(data);
          setTimeOpen(false);
        }}
      >
        <h3>{data}</h3>
      </div>
    );
  };

  useEffect(() => {
    getHospitalInfoByExternalID(appointmentData.h_id).then((response) => {
      setHospitalData(response.data);
    });
    const handleClickReasonOutside = (event) => {
      if (
        reasonDropDownRef.current &&
        !reasonDropDownRef.current.contains(event.target)
      ) {
        setReasonOpen(false);
      }
    };

    const handleClickTimeOutside = (event) => {
      if (
        timeDropdownRef.current &&
        !timeDropdownRef.current.contains(event.target)
      ) {
        setTimeOpen(false);
      }
    };
    const handleClickDateOutside = (event) => {
      if (
        dateDropdownRef.current &&
        !dateDropdownRef.current.contains(event.target)
      ) {
        setRescheduleOpen(false);
      }
    };

    window.addEventListener("click", handleClickReasonOutside);
    window.addEventListener("click", handleClickTimeOutside);
    window.addEventListener("click", handleClickDateOutside);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("click", handleClickReasonOutside);
      window.removeEventListener("click", handleClickTimeOutside);
      window.removeEventListener("click", handleClickDateOutside);
    };
  }, []);

  const increaseMonth = () => {
    const newDate = new Date(displayedDate.getTime());
  const originalDay = newDate.getDate();

  // Set the date to the first of the month to prevent overflow
  newDate.setDate(1);

  // Increment the month by 1
  newDate.setMonth(newDate.getMonth() + 1);

  // Set the date to the original day or the last day of the new month if the original day is too large
  const daysInNewMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
  newDate.setDate(Math.min(originalDay, daysInNewMonth));

  setDisplayedDate(newDate);
  };

  const decreaseMonth = () => {
    const newDate = new Date(displayedDate.getTime());
    newDate.setMonth(newDate.getMonth() - 1);

    setDisplayedDate(newDate);
  };

  const renderBody = () => {
    if (modalType === "ACCEPT") {
      return (
        <>
          <div className="confirmation-modal-header">
            <div className="d-flex align-items-center">
              <div className="confirmation-modal-banner accept" />
              <h2 className="confirmation-modal-title">Accept</h2>
            </div>
            <button onClick={() => onRequestClose()}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <p className="confirmation-modal-description">
            The patient will be sent an email with a confirmation of this
            appointment
          </p>
        </>
      );
    }
    if (modalType === "DECLINE") {
      return (
        <>
          <div className="confirmation-modal-header">
            <div className="d-flex align-items-center">
              <div className="confirmation-modal-banner decline" />
              <h2 className="confirmation-modal-title">Decline</h2>
            </div>
            <button onClick={() => onRequestClose()}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <p className="confirmation-modal-description">
            The patient will be sent an email with a confirmation of this
            appointment
          </p>
          <div className="appointment-dropdown" ref={reasonDropDownRef}>
            <button onClick={() => setReasonOpen(!isReasonOpen)}>
              {reason} <i className="fas fa-chevron-down"></i>
            </button>
            {isReasonOpen && (
              <div className="appointment-dropdown-menu">
                {reasons_to_cancel.reason.map((elem) =>
                  renderReasonToCancel(elem)
                )}
              </div>
            )}
          </div>
          <textarea
            type="text"
            className="appointment-message"
            onChange={(e) => setMessage(e.target.value)}
            placeholder="(Optional) Write a message about the cancellation"
            value={message}
          />
        </>
      );
    }
    if (modalType === "MODIFY") {
      return (
        <div className="position-relative">
          <div className="confirmation-modal-header">
            <div className="d-flex align-items-center">
              <div className="confirmation-modal-banner reschedule" />
              <h2 className="confirmation-modal-title">Reschedule</h2>
            </div>
            <button onClick={() => onRequestClose()}>
              <i className="fas fa-times"></i>
            </button>
          </div>

          <h2 className="confirmation-modal-description">
            Choose a time and date in the future you want your appointment to be
            on
          </h2>
          <div
            ref={dateDropdownRef}
            className=""
          >
            <div
              className="confirmation-modal-date-dropdown"
              onClick={() => setRescheduleOpen(!isRescheduleOpen)}
            >
              <i className="far fa-calendar"></i>
              <div className="d-flex flex-column">
                <h4>Date</h4>
                <h5>
                  {formatDateToLongString(displayedDate) != null
                    ? formatDateToLongString(displayedDate)
                    : "Choose how frequently your slots are open"}
                </h5>
              </div>
            </div>
            {isRescheduleOpen && (
              <ul className="create-schedule-dropdown-menu">
                <MiniCalendar
                  useExactDate={true}
                  displayedDate={displayedDate}
                  setDisplayedDate={setDisplayedDate}
                  increaseMonth={increaseMonth}
                  decreaseMonth={decreaseMonth}
                  setPopupOpen={() => setRescheduleOpen(false)}
                />
              </ul>
            )}
          </div>
          <div
            ref={timeDropdownRef}
            className=""
          >
            <div
              className="confirmation-modal-date-dropdown"
              onClick={() => setTimeOpen(!isTimeOpen)}
            >
              <i className="far fa-clock"></i>
              <div className="d-flex flex-column">
                <h4>Time</h4>
                <h5>
                  {selectedTime != null && selectedTime !== ""
                    ? selectedTime
                    : "Choose time"}
                </h5>
              </div>
              {isTimeOpen && (
                <div className="confirmation-time-dropdown-menu ">
                  {halfHourTimeIntervals.map((elem) => renderTimeSlot(elem))}
                </div>
              )}
            </div>
          </div>

          {selectedRescheduleDate && (
            <p className="confirmation-modal-description">
              Your new requested appointment date is:{" "}
              <b>{selectedRescheduleDate.toLocaleString()}</b>
            </p>
          )}
        </div>
      );
    }
  };

  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal">
        {renderBody()}
        <div className="confirmation-modal-buttons">
          {/* <button
            className="confirmation-modal-button cancel"
            onClick={() => onRequestClose()}
          >
            Cancel
          </button> */}
          {renderConfirmButton()}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
