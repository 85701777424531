import React, { useEffect, useCallback, useState, useRef } from "react";
import {
  Doctor07,
  achew_logo_main,
  arrow_top_right_grey,
  filled_star,
  geo_black,
  sample_doctor,
  service_check,
  placeholder_image
} from "../home/image.jsx";
import { areDatesEqual, areDatesEqualInUTC, formatDateUTCKey, formatWebsiteLink, getClinicProfilePicture, getHospitalPromotions, getStartOfWeekAndEndOfNextWeek, getStartWeekAndStartNextWeek, getWeekAndNextWeek, indexOfAvailableSlots, isDateInRange, isDayOfWeekIncluded, isTimeInArray, sumTotalSlots, truncateText } from "../../assets/functions/functions.js";
import { useNavigate } from "react-router-dom";
import { formatDateMMDDYYYY } from "../doctors/dashboard/prototype-calendar/helper-functions.jsx";
import { useMediaQuery } from "react-responsive";
const daysOfWeek = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};
const ClinicDetails = ({id, data, handleSlotClicked, displayedDate }) => {
  const today = new Date()
  const navigate = useNavigate()
  const [appointmentSlotDictionary, setAppointmentSlotDictionary] = useState({})
  const [isHovered, setIsHovered] = useState(false);
  const [didAdjustScroll, setDidAdjustScroll] = useState(false)
  const slotListRef = useRef(null)
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const dayCorrectionMapping = {
    "0": 1,
    "1": 2,
    "2": 3,
    "3": 4,
    "4": 5,
    "5": 6,
    "6": 0,
}

  const handleMouseHovering = () => {
    setIsHovered(true);
  };

  const handleMouseUnHovering = () => {
    setIsHovered(false);
  };

  const handleOpenClinicLink = () => {
    try {
      window.open(formatWebsiteLink(data.hospital_website), '_blank', 'noopener,noreferrer');
    } catch {
      console.log("ignoring blur error")
    }
    
  };

  useEffect(() => {
    if (slotListRef.current) {
      // Select the first child with the specific class name
      const firstChild = slotListRef.current.querySelector('.search-result-dayslot');
      // If the child exists, scroll to it
      if(firstChild && !didAdjustScroll) {
        setDidAdjustScroll(true)
        const scrollPosition = firstChild.offsetLeft;
        const adjustedScrollPosition = scrollPosition - (firstChild.offsetWidth / 2);
        // Scroll the container to the calculated position
        slotListRef.current.scrollLeft = adjustedScrollPosition;
      }
    
    }
    console.log("Effect1")
  })

  useEffect(() => {
    //Initialize displayed days and the slot dictionary
    const thisWeekNextWeek = getWeekAndNextWeek(displayedDate).currentWeek.concat(getWeekAndNextWeek(displayedDate).nextWeek)
    const tempAppointmentSlotDictionary = {}

    //Go through each schedule and case on their frequency type
    
    data.target_schedules.forEach(schedule => {
      if (schedule.frequency_type === "O" && schedule.is_schedule_available) {
        console.log(data.hospital_name, "Once")
        //Go through each day and see if rule applies
        thisWeekNextWeek.forEach(day => {
          const appointmentDate = new Date(schedule.start_date * 1000)
          const dateKey = formatDateMMDDYYYY(day)
         
          if (areDatesEqualInUTC(day, appointmentDate) && day.getTime() >= today.getTime()) {
            if (dateKey in tempAppointmentSlotDictionary) {
              tempAppointmentSlotDictionary[dateKey].total_slots += schedule.total_num_slots
            } else {

              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: 0,
                available_slots: []
              }
              
            }
            
              schedule.timeslots.forEach(time => {
                if (!isTimeInArray(tempAppointmentSlotDictionary[dateKey].available_slots, time)) {
                  tempAppointmentSlotDictionary[dateKey].available_slots.push({
                    "time": time,
                    "schedule_id": schedule.external_id,
                    "schedule_internal_id": schedule.id,
                    "doctor_id": schedule.d_id,
                    "duration": schedule.duration,
                  })
                }
              }) 
          } else {
            if (!(dateKey in tempAppointmentSlotDictionary)) {
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: 0,
                available_slots: []
              }
            }
          }
        })
      } else if (schedule.frequency_type === "D") {
        
        thisWeekNextWeek.forEach(day => {
          const dateKey = formatDateMMDDYYYY(day)
          console.log(schedule, schedule.frequency_interval)
          //console.log(data.hospital_name, !isDayOfWeekIncluded(schedule.frequency_interval, day), isDateInRange(new Date(schedule.start_date * 1000), new Date(schedule.end_date * 1000), day), day.getTime() >= today.getTime())
          if (!isDayOfWeekIncluded(schedule.frequency_interval, day) && isDateInRange(schedule.start_date * 1000, schedule.end_date * 1000, day) && day.getTime() >= today.getTime()) {
            if (dateKey in tempAppointmentSlotDictionary) {
              tempAppointmentSlotDictionary[dateKey].total_slots += schedule.total_num_slots
            } else {
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: schedule.total_num_slots,
                available_slots: []
              }
            }
            
              schedule.timeslots.forEach(time => {
                if (!isTimeInArray(tempAppointmentSlotDictionary[dateKey].available_slots, time)) {
                  tempAppointmentSlotDictionary[dateKey].available_slots.push({
                    "time": time,
                    "schedule_id": schedule.external_id,
                    "schedule_internal_id": schedule.id,
                    "doctor_id": schedule.d_id,
                    "duration": schedule.duration,
                  })
                }
              })
            
          } else {
            if(!(dateKey in tempAppointmentSlotDictionary)){
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: 0,
                available_slots: []
              }
            }
          }
        })
      } else if (schedule.frequency_type === "W") {
        thisWeekNextWeek.forEach(day => {
          const dateKey = formatDateMMDDYYYY(day)
          if (day.getDay() === dayCorrectionMapping[schedule.frequency_interval] && isDateInRange(schedule.start_date * 1000, schedule.end_date * 1000, day) && day.getTime() >= today.getTime()) {
            if (dateKey in tempAppointmentSlotDictionary) {
              tempAppointmentSlotDictionary[dateKey].total_slots += schedule.total_num_slots
            } else {
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: schedule.total_num_slots,
                available_slots: []
              }
            }
            
              schedule.timeslots.forEach(time => {
                if (!isTimeInArray(tempAppointmentSlotDictionary[dateKey].available_slots, time)) {
                  tempAppointmentSlotDictionary[dateKey].available_slots.push({
                    "time": time,
                    "schedule_id": schedule.external_id,
                    "schedule_internal_id": schedule.id,
                    "doctor_id": schedule.d_id,
                    "duration": schedule.duration,
                  })
                }
              })
            
          } else {
            if(!(dateKey in tempAppointmentSlotDictionary)){
              tempAppointmentSlotDictionary[dateKey] = {
                total_slots: 0,
                available_slots: []
              }
            }
            
          }
        })
      }
    })
    console.log(data.target_schedules, data.hospital_name)
    data.target_schedules.forEach(schedule => {
      const takenAppointments = schedule.appointments ? schedule.appointments : []
      takenAppointments.forEach(slot => {
        const dateKey = formatDateUTCKey(new Date(slot.slot_date * 1000))

        if(tempAppointmentSlotDictionary[dateKey] != undefined){
          console.log(tempAppointmentSlotDictionary[dateKey].available_slots, slot.slot_time, slot.schedule_id)
          const indexToRemove = indexOfAvailableSlots(tempAppointmentSlotDictionary[dateKey].available_slots, slot.slot_time, schedule.external_id)
          if (indexToRemove !== -1) {
            tempAppointmentSlotDictionary[dateKey].total_num_slots -= 1
            tempAppointmentSlotDictionary[dateKey].available_slots.splice(indexToRemove, 1);
          }
        }
      })
    })
    setAppointmentSlotDictionary(tempAppointmentSlotDictionary)

  }, []);

  const renderAppointmentSlot = (date) => {

    const dateKey = formatDateMMDDYYYY(date)
    if (dateKey in appointmentSlotDictionary) {
      const numOpenSlots = appointmentSlotDictionary[formatDateMMDDYYYY(date)].available_slots.length
      if (numOpenSlots > 0) {
        return renderAppointmentSlotDayRegular(date)
      } else {
        return renderAppointmentSlotDayDisabled(date)
      }
    }

  }

  const renderAppointmentSlotDayRegular = (date) => {
    const numOpenSlots = appointmentSlotDictionary[formatDateMMDDYYYY(date)].available_slots.length
    const iconStyle = "fas fa-circle " + (numOpenSlots < 4 ? "search-result-dayslot-i-limited" : "")
    return (
      <div className="search-result-dayslot" onClick={() => handleSlotClicked(date, data, appointmentSlotDictionary)}>
        <h1>{daysOfWeek[date.getDay()]}</h1>
        <h2>{date.getDate()}</h2>
        <div className="d-flex align-items-center">
          <i className={iconStyle}></i>
          <h3>{numOpenSlots} slots</h3>
        </div>
      </div>
    );
  };

  const renderAppointmentSlotDayDisabled = (date) => {
    return (
      <div className="search-result-dayslot-disabled">
        <h1>{daysOfWeek[date.getDay()]}</h1>
        <h2>{date.getDate()}</h2>
        <div className="d-flex align-items-center">
          <i className="fas fa-circle"></i>
          <h3>No slots</h3>
        </div>
      </div>
    );
  };

  const containerStyle = isHovered ? "search-result-clinic-hovered" : "search-result-clinic"
  const promotions = getHospitalPromotions(data)
  
  return (
    <div id={id} className={containerStyle}>
      <div className="search-result-clinic-info-container" onClick={() => navigate(`/profile/${data.external_id}`)} onMouseEnter={handleMouseHovering} onMouseLeave={handleMouseUnHovering}>
        <div className="search-result-clinic-info-left">
          <div className="search-result-clinic-info-left-img">
            <img
              alt="Profile"
              src={getClinicProfilePicture(data)}
            />
          </div>

          <div className="search-result-clinic-info-left-txt">
            <div className="search-result-clinic-info-left-specialty">
              {data.main_specialty_title}
            </div>
            <h1>{data.hospital_name}</h1>
            <div className="home-clinic-rating-container">
              <img alt="Clinic Rating" src={filled_star} />
              {
                data.rating == -1 ?
                  <h3 style={{color:'grey'}}>No reviews</h3>
                :
                <>
                  <h2>{data.rating.toFixed(2)}</h2>
                  <h3>({data.num_reviews})</h3>
                </>
              }
            </div>
            {
              !isTabletOrMobile &&
              <div className="d-flex flex-column">
              <div className="home-clinic-location-container">
                <img alt="Location Icon" src={geo_black} />
                <h2>{data.location_info.city}, </h2>
                <h3>{data.location_info.state} - {data.distance} miles away</h3>
              </div>
              <div className="home-clinic-location-container2">
                <h3>{data.location_info.street_address} {data.location_info.street_address_2.length > 0 && `(${data.location_info.street_address_2})`}, {data.location_info.zip}</h3>
              </div>
            </div>
            }
            
          </div>
        </div>
        <div className="search-result-clinic-info-right tablet-only">
          <h4>
            {data.about && data.about.length > 0 ? truncateText(data.about, 400) : "No description given."}
          </h4>
          {/* <div className="search-result-clinic-info-right-subcontainer">
            <div className="d-flex align-items-center" style={{width:'45%'}}>
              <img alt="check" src={service_check} />
              <h5>{promotions[0].slogan}</h5>
            </div>
            <div className="d-flex align-items-center" style={{width:'45%'}}>
              <img alt="check" src={service_check} />
              <h5>{promotions[1].slogan}</h5>
            </div>
          </div>
          <div className="search-result-clinic-info-right-subcontainer">
            <div className="d-flex align-items-center" style={{width:'45%'}}>
              <img alt="check" src={service_check} />
              <h5>{promotions[2].slogan}</h5>
            </div>
            <div className="d-flex align-items-center" style={{width:'45%'}}>
              <img alt="check" src={service_check} />
              <h5>{promotions[3].slogan}</h5>
            </div>
          </div> */}
        </div>
      </div>
      {
          isTabletOrMobile &&
          <div className="d-flex flex-column search-result-clinic-info-left align-items-start" style={{marginLeft:'20px', width:"100%"}}>
            <div className="home-clinic-location-container">
              <img alt="Location Icon" src={geo_black} />
              <h2>{data.location_info.city}, </h2>
              <h3>{data.location_info.state} - {data.distance} miles away</h3>
            </div>
            <div className="home-clinic-location-container2">
              <h3>{data.location_info.street_address} {data.location_info.street_address_2.length > 0 && `(${data.location_info.street_address_2})`}, {data.location_info.zip}</h3>
            </div>
          </div>
        }
      {sumTotalSlots(appointmentSlotDictionary) > 0 ? 
      (!isTabletOrMobile ?
      <div className="search-result-clinic-apt-container">
        <div className="search-result-clinic-apt-heading">
          <h1>Choose a Time</h1>
        </div>
        <div className="search-result-clinic-apt-list" ref={slotListRef}>
          {getWeekAndNextWeek(displayedDate).currentWeek.map(date => renderAppointmentSlot(date))}
          {getWeekAndNextWeek(displayedDate).nextWeek.map(date => renderAppointmentSlot(date))}
        </div>
      </div> 
      :
      <div className="search-result-clinic-apt-list" ref={slotListRef}>
        {getWeekAndNextWeek(displayedDate).currentWeek.map(date => renderAppointmentSlot(date))}
        {getWeekAndNextWeek(displayedDate).nextWeek.map(date => renderAppointmentSlot(date))}
      </div>
      )
      :
      null
      // (
      //   data.hospital_website && data.hospital_website.length > 0 ?
      //     <button className="search-result-clinic-website-button" onClick={() => handleOpenClinicLink()}>
      //       <i class="fas fa-link" style={{marginRight:"10px"}}></i>
      //       Find appointment slots on clinic website
      //     </button>
      //   :
      //     <button className="search-result-clinic-noapt-button">
      //       Contact Clinic for Slots
      //     </button>
      // )
      }

      
    </div>
  );
};


export default ClinicDetails