import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from "../assets/images/footer-logo.png";
import AOS from "aos";
import "aos/dist/aos.css";



const Footer = (props) => {

  return (
    <> {true &&
      <div className="footer-box">
        <div className="footer-column">
          <h1>Doccure</h1>
          <a href="">Home</a>
          <a href="">About us</a>
          <a href="">Press</a>
          <a href="">Careers</a>
          <a href="">Help</a>
          <div className="footer-contact">
            <h1>Contact</h1>
            <a href="">farty@gmail.com</a>
            <a href="">626-127-3213</a>
          </div>
        </div>
        <div className="footer-column">
          <h1>Discover</h1>
          <a href="">The Paper Gown
            Stories for and about patients</a>
          <a href="">The Script
            Insights for doctors</a>
          <a href="">Community Standards</a>
          <a href="">Data and privacy</a>
          <a href="">Verified reviews</a>
        </div>
        <div className="footer-column">
          <h1>Insurance Carriers</h1>
          <a href="">Aetna</a>
          <a href="">Aetna Dental</a>
          <a href="">Ambetter</a>
          <a href="">Anthem Blue Cross Blue Shield</a>
          <a href="">Blue Cross Blue Shield</a>
          <a href="">Cigna</a>
          <a href="">Cigna Dental</a>
          <a href="">Delta Dental</a>
          <a href="">Florida Blue Cross Blue Shield</a>
          <a href="">Humana Dental</a>
          <a href="">Humana</a>
          <a href="">Kaiser Permanente</a>
          <a href="">Metlife</a>
          <a href="">Multiplan PHCS</a>
          <a href="">UnitedHealthcare</a>
          <a href="">UnitedHealthcare Dental</a>
          <a href="">UnitedHealthcare Oxford</a>
          <a href="">1199SEIU</a>
          <a href="">View all</a>
        </div>
        <div className="footer-column">
          <h1>Top Specialities</h1>
          <a href="">Primary Care Doctor</a>
          <a href="">Urgent Care</a>
          <a href="">Dermatologist</a>
          <a href="">OB-GYN</a>
          <a href="">Dentist</a>
          <a href="">Psychiatrist</a>
          <a href="">Ear, Nose & Throat Doctor</a>
          <a href="">Podiatrist</a>
          <a href="">Urologist</a>
          <a href="">Gastroenterologist</a>
          <a href="">Cardiologist</a>
          <a href="">Neurologist</a>
          <a href="">Orthopedic Surgeon</a>
          <a href="">Ophthalmologist</a>
          <a href="">Pediatrician</a>
          <a href="">Optometrist</a>
          <a href="">Eye Doctor</a>
          <a href="">Therapist Counselor</a>
          <a href="">Physical Therapist</a>
          <a href="">Psychologist</a>
          <a href="">View all</a>
        </div>
        <div className="footer-column">
          <h1>Are you a top doctor or health service?</h1>
          <a href="">List your practice on Zocdoc</a>
          <a href="">Become an EHR partner</a>
          <a href="">Access Zocdoc for Developers</a>
          <a href="">Learn about Zocdoc Enterprise Solutions</a>
          <div className="footer-contact">
            <h1>Dental Marketing</h1>
            <a href="">OB-GYN Marketing</a>
            <a href="">Dermatology Marketing</a>
            <a href="">Primary Care Marketing</a>
            <a href="">Psychiatry Marketing</a>
          </div>

        </div>

      </div>
    }
    </>
  );
};

export default Footer;
