import React, { useState, useEffect, useRef } from "react";
import AppointmentView from "../appointment-view";

const DateSlotPopup = ({ isOpen, onClose, modalData, currentHospitalData, setIntakeModalData, setCreateIntakeModalOpen, appointmentIntakeDictionary, accountData, setCreditWarningMessageOpen }) => {
  const overlayClickHandler = () => {
    onClose()
  };
  return (
    <div className={`calendar-modal ${isOpen ? "open" : ""}`}>
      <div className="calendar-overlay" onClick={overlayClickHandler}></div>
      <div className={`calendar-modal-content ${isOpen ? "open" : ""}`}>
       {modalData && 
       <AppointmentView 
          setIntakeModalData={setIntakeModalData} 
          setCreateIntakeModalOpen={setCreateIntakeModalOpen} 
          appointmentIntakeDictionary={appointmentIntakeDictionary}
          appointmentData={modalData} 
          hospitalData={currentHospitalData}
          index={0} 
          isFromCalendar={true}
          accountData={accountData}
          setCreditWarningMessageOpen={setCreditWarningMessageOpen}
        />}
      </div>
    </div>
  );
};

export default DateSlotPopup;
