import React, { useState, useEffect } from "react";
import "./mini-calendar.css"
import DateSlot from "./date-slot.jsx";
import { get42DaysOfMonth, monthDictionary } from "../../../doctors/dashboard/prototype-calendar/helper-functions.jsx";


const MiniCalendar = ({useExactDate, setPopupOpen, displayedDate, setDisplayedDate, increaseMonth, decreaseMonth , handleNewDateSearch}) => {
  const [miniCalendarDisplayedDate, setMiniCalendarDisplayedDate] = useState(null)

  useEffect(() => {
    setMiniCalendarDisplayedDate(displayedDate)
  }, [])


  const increaseMonthMini = () => {
    const newDate = new Date(miniCalendarDisplayedDate.getTime());
    const originalDay = newDate.getDate();
  
    // Set the date to the first of the month to prevent overflow
    newDate.setDate(1);
  
    // Increment the month by 1
    newDate.setMonth(newDate.getMonth() + 1);
  
    // Set the date to the original day or the last day of the new month if the original day is too large
    const daysInNewMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
    newDate.setDate(Math.min(originalDay, daysInNewMonth));
  
    setMiniCalendarDisplayedDate(newDate);
  };

  const decreaseMonthMini = () => {
    const newDate = new Date(miniCalendarDisplayedDate.getTime());
    newDate.setMonth(newDate.getMonth() - 1);

    setMiniCalendarDisplayedDate(newDate);
  };

  if (miniCalendarDisplayedDate) {
    const daysOfCalendar = get42DaysOfMonth(miniCalendarDisplayedDate.getFullYear(), miniCalendarDisplayedDate.getMonth())

    return (
      <div className="mini-calendar-body">
        <div className="mini-calendar-month-year-container">
          <button
            class="calendar-chevron-button-left"
            onClick={() => decreaseMonthMini()}
          >
            <i class="fas fa-chevron-left"></i>
          </button>
          <h1 className="mini-calendar-month-year">{monthDictionary[miniCalendarDisplayedDate.getMonth()]} {miniCalendarDisplayedDate.getFullYear()}</h1>
          <button
            class="calendar-chevron-button-right"
            onClick={() => increaseMonthMini()}
          >
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
        <div className="mini-calendar-row">
          {daysOfCalendar.slice(0, 7).map((day) => <DateSlot useExactDate={useExactDate} date={day} appointments={[1, 2, 3]} weekIndex={0} setPopupOpen={setPopupOpen} setDisplayedDate={setDisplayedDate} displayedDate={displayedDate} miniCalendarDisplayedDate={miniCalendarDisplayedDate} setMiniCalendarDisplayedDate={setMiniCalendarDisplayedDate} handleNewDateSearch={handleNewDateSearch}/>)}
        </div>
        <div className="mini-calendar-row">
          {daysOfCalendar.slice(7, 14).map((day) => <DateSlot useExactDate={useExactDate} date={day} appointments={[1, 2, 3]} weekIndex={1} setPopupOpen={setPopupOpen} setDisplayedDate={setDisplayedDate} displayedDate={displayedDate} miniCalendarDisplayedDate={miniCalendarDisplayedDate} setMiniCalendarDisplayedDate={setMiniCalendarDisplayedDate} handleNewDateSearch={handleNewDateSearch}/>)}
        </div>
        <div className="mini-calendar-row">
          {daysOfCalendar.slice(14, 21).map((day) => <DateSlot useExactDate={useExactDate} date={day} appointments={[1, 2, 3]} weekIndex={2} setPopupOpen={setPopupOpen} setDisplayedDate={setDisplayedDate} displayedDate={displayedDate} miniCalendarDisplayedDate={miniCalendarDisplayedDate} setMiniCalendarDisplayedDate={setMiniCalendarDisplayedDate} handleNewDateSearch={handleNewDateSearch}/>)}
        </div>
        <div className="mini-calendar-row">
          {daysOfCalendar.slice(21, 28).map((day) => <DateSlot useExactDate={useExactDate} date={day} appointments={[1, 2, 3]} weekIndex={3} setPopupOpen={setPopupOpen} setDisplayedDate={setDisplayedDate} displayedDate={displayedDate} miniCalendarDisplayedDate={miniCalendarDisplayedDate} setMiniCalendarDisplayedDate={setMiniCalendarDisplayedDate} handleNewDateSearch={handleNewDateSearch}/>)}
        </div>
        <div className="mini-calendar-row">
          {daysOfCalendar.slice(28, 35).map((day) => <DateSlot useExactDate={useExactDate} date={day} appointments={[1, 2, 3]} weekIndex={4} setPopupOpen={setPopupOpen} setDisplayedDate={setDisplayedDate} displayedDate={displayedDate} miniCalendarDisplayedDate={miniCalendarDisplayedDate} setMiniCalendarDisplayedDate={setMiniCalendarDisplayedDate} handleNewDateSearch={handleNewDateSearch}/>)}
        </div>
        <div className="mini-calendar-row">
          {daysOfCalendar.slice(35, 42).map((day) => <DateSlot useExactDate={useExactDate} date={day} appointments={[1, 2, 3]} weekIndex={5} setPopupOpen={setPopupOpen} setDisplayedDate={setDisplayedDate} displayedDate={displayedDate} miniCalendarDisplayedDate={miniCalendarDisplayedDate} setMiniCalendarDisplayedDate={setMiniCalendarDisplayedDate} handleNewDateSearch={handleNewDateSearch}/>)}
        </div>
      </div>
    );
  }


};

export default MiniCalendar;
