import React, { useState, useEffect, useRef } from "react";
import "./calendar.css";
import Calendar from "./calendar";
import {
  monthDictionary,
  formatDateMMDDYYYY,
  getFirstDayOfWeek,
  getLastDayOfWeek,
  addWeekToDate,
  subtractWeekFromDate,
} from "./helper-functions.jsx";
import {
  GetNextSchedulePage,
  getAppointmentsByTime,
  getIntakeByID,
  getSchedules,
} from "../../../../../api/patientAPIs.js";
import { refreshToken } from "../../../../../api/auth.js";
import { useDispatch } from "react-redux";
import { setAccountInfo } from "../../../../../hooks/accountSlice.js";
import { useNavigate, useLocation } from "react-router-dom";
import { ensureHttps, findObjectById, findObjectByInternalId, getDoctorProfilePicture } from "../../../../assets/functions/functions.js";
import DateSlotPopup from "./date-slot-popup";
import CalendarWeekly from "./calendar-weekly.jsx";
import { sample_doctor } from "../../../home/image.jsx";
import MiniCalendar from "../../../patients/doctorprofile/mini-calendar/datepicker.jsx";
import CreateSchedulePopup from "../create-schedule-popup.jsx";
import LoadingSpinner from "../../../patients/bookingv2/spinner.jsx";
import OverlayLoadingSpinner from "../../../patients/bookingv2/overlay-spinner.jsx";
import CreateIntakeModal from "../patient-modals/create-intake-modal.jsx";

const CalendarPage = ({ scheduleList, setScheduleList, currentHospitalData, doctorList, appointmentList, setCreateScheduleOpen, setEditModalOpen, accountData, setCreditWarningMessageOpen }) => {
  const today = new Date();
  const firstDayOfThisWeek = getFirstDayOfWeek(today);
  const navigate = useNavigate();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const currentHospitalID = searchParams.get("hospital");

  const dispatch = useDispatch();
  const [displayedDate, setDisplayedDate] = useState(today);
  const [displayedDateWeekly, setDisplayedDateWeekly] = useState(firstDayOfThisWeek);
  const [hospitalData, setHospitalData] = useState(null);
  const [appointmentData, setAppointmentData] = useState(appointmentList);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [activeCalendarType, setActiveCalendarType] = useState("weekly");
  const [appointmentDictionary, setAppointmentDictionary] = useState(null);
  const [popupModalData, setPopupModalData] = useState(null)
  const [filterDictionary, setFilterDictionary] = useState(null)
  const [calendarLoading, setCalendarLoading] = useState(false)
  const [isManageScheduleDropdownOpen, setManageDropdownOpen] = useState(false)
  const manageDropdownRef = useRef(null)

  //Manage Schedule Pagination
  const [schedulePagination, setSchedulePagination] = useState(null)
  const [isPaginationLoading, setIsPaginationLoading] = useState(false)

  //Intake Support
  const [appointmentIntakeDictionary, setAppointmentIntakeDictionary] = useState({})
  const [isCreateIntakeModalOpen, setCreateIntakeModalOpen] = useState(false)
  const [intakeModalData, setIntakeModalData] = useState(null)
  const [isCreateAppointmentModalOpen, setCreateAppointmentModalOpen] = useState(false)

  const increaseMonth = () => {
    const newDate = new Date(displayedDate.getTime());
  const originalDay = newDate.getDate();

  // Set the date to the first of the month to prevent overflow
  newDate.setDate(1);

  // Increment the month by 1
  newDate.setMonth(newDate.getMonth() + 1);

  // Set the date to the original day or the last day of the new month if the original day is too large
  const daysInNewMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
  newDate.setDate(Math.min(originalDay, daysInNewMonth));

  setDisplayedDate(newDate);
  };

  const decreaseMonth = () => {
    const newDate = new Date(displayedDate.getTime());
    newDate.setMonth(newDate.getMonth() - 1);

    setDisplayedDate(newDate);
  };

  const increaseWeek = () => {
    const newDate = addWeekToDate(displayedDateWeekly);
    setDisplayedDateWeekly(newDate);
  };
  const decreaseWeek = () => {
    const newDate = subtractWeekFromDate(displayedDateWeekly);
    setDisplayedDateWeekly(newDate);
  };

  const handleRightChevronClicked = () => {
    if (activeCalendarType === "monthly") {
      increaseMonth()
    } else if (activeCalendarType === "weekly") {
      increaseWeek()
    }
  }

  const handleLeftChevronClicked = () => {
    if (activeCalendarType === "monthly") {
      decreaseMonth()
    } else if (activeCalendarType === "weekly") {
      decreaseWeek()
    }
  }

  const toggleFilter = (key) => {
    const tempFilterDictionary = { ...filterDictionary }
    tempFilterDictionary[key] = !filterDictionary[key]
    //console.log(tempFilterDictionary)
    setFilterDictionary(tempFilterDictionary)

    const filteredAppointmentList = appointmentData.filter(appointment => {
      
      if(appointment.status === "PENDING" && !tempFilterDictionary["pending"]) { return false }
      if(appointment.status === "CONFIRMED" && !tempFilterDictionary["confirmed"]) {return false }
      if(appointment.status === "COMPLETED" && !tempFilterDictionary["completed"]) { return false }
      if(appointment.status === "DECLINED" && !tempFilterDictionary["declined"]) { return false }
      
      return true && tempFilterDictionary[appointment.d_id]
    })
    console.log(filteredAppointmentList)
    const appointmentDataDictionary = {};
    
    filteredAppointmentList.forEach((apt) => {
      const aptKey = formatDateMMDDYYYY(parseInt(apt.schedule_time * 1000));
      if (appointmentDataDictionary[aptKey]) {
        appointmentDataDictionary[aptKey].push(apt);
      } else {
        appointmentDataDictionary[aptKey] = [apt];
      }
    });
    console.log(appointmentDataDictionary)
    setAppointmentDictionary(appointmentDataDictionary);
  }

  const renderDoctorRow = (doctor) => {
    return (
      <div className="calendar-sidebar-providers-row">
        <div className="d-flex align-items-center">
          <div className="calendar-sidebar-providers-row-img">
            <img alt="Profile Pic" src={getDoctorProfilePicture(doctor)} />
          </div>
          <h1>Dr. {doctor.doctor_name_first} {doctor.doctor_name_last}</h1>
        </div>
        <input
          type="checkbox"
          checked={filterDictionary[doctor.external_id]}
          onChange={() => toggleFilter(doctor.external_id)}
        />
      </div>
    )
  }

  const handleShowMoreSchedules = () => {
    setIsPaginationLoading(true)
    GetNextSchedulePage(ensureHttps(schedulePagination.next)).then(response => {
      const tempSchedules = [...scheduleList, ...response.data.results]
      setScheduleList(tempSchedules)
      setSchedulePagination(response.data)
      setIsPaginationLoading(false)
    })
    .catch(err => {
      console.log(err)
      setIsPaginationLoading(false)
    })
  }

  useEffect(() => {
    setCalendarLoading(true)
    const displayedDateInSeconds = parseInt(displayedDate.getTime() / 1000)
    getSchedules(currentHospitalID).then(scheduleResponse => {
      setScheduleList(scheduleResponse.data.results)
      setSchedulePagination(scheduleResponse.data)
    })
    getAppointmentsByTime(currentHospitalID, displayedDateInSeconds)
    .then(response => {
      console.log(response)
        setAppointmentData(response.data)

        const allAppointments = response.data
        const parallelIntakePromises = []
        const tempAppointmentIntakeDictionary = {}
        allAppointments.forEach(apt => {
            parallelIntakePromises.push(getIntakeByID(currentHospitalID, apt.external_id))
        })
        Promise.all(parallelIntakePromises).then(parallelResponses => {
            parallelResponses.forEach(response => {
                if(response.status >= 200 && response.status < 300 && response.data.results.length === 1){
                    tempAppointmentIntakeDictionary[response.data.results[0].appointment_id] = response.data.results[0]
                }
            })
            setAppointmentIntakeDictionary(tempAppointmentIntakeDictionary)
            console.log("Dictionary: ",tempAppointmentIntakeDictionary )
        })
        setCalendarLoading(false)
    })
    .catch(err => {
      console.log(err)
      setCalendarLoading(false)
    })
  }, [displayedDate])

  useEffect(() => {
    setCalendarLoading(true)
    const displayedDateInSeconds = parseInt(displayedDateWeekly.getTime() / 1000)
    getAppointmentsByTime(currentHospitalID, displayedDateInSeconds)
    .then(response => {
      console.log(response)
        setAppointmentData(response.data)
        const allAppointments = response.data
        const parallelIntakePromises = []
        const tempAppointmentIntakeDictionary = {}
        allAppointments.forEach(apt => {
            parallelIntakePromises.push(getIntakeByID(currentHospitalID, apt.external_id))
        })
        Promise.all(parallelIntakePromises).then(parallelResponses => {
            parallelResponses.forEach(response => {
                if(response.status >= 200 && response.status < 300 && response.data.results.length === 1){
                    tempAppointmentIntakeDictionary[response.data.results[0].appointment_id] = response.data.results[0]
                }
            })
            setAppointmentIntakeDictionary(tempAppointmentIntakeDictionary)
            console.log("Dictionary: ",tempAppointmentIntakeDictionary )
        })
        setCalendarLoading(false)
    })
    .catch(err => {
      console.log(err)
      setCalendarLoading(false)
    })

  }, [displayedDateWeekly])

  useEffect(() => {
    setCalendarLoading(true)
    var displayedDateInSeconds
    if(activeCalendarType === "weekly") {
      displayedDateInSeconds = parseInt(displayedDateWeekly.getTime() / 1000)
    } else {
      displayedDateInSeconds = parseInt(displayedDate.getTime() / 1000)
    }
    
    getAppointmentsByTime(currentHospitalID, displayedDateInSeconds)
    .then(response => {
      console.log(response)
        setAppointmentData(response.data)
        setCalendarLoading(false)
    })
    .catch(err => {
      console.log(err)
      setCalendarLoading(false)
    })
  }, [activeCalendarType])

  useEffect(() => {

    //Set up dictionary to track which filters are activated
    const tempFilterDictionary = {
      "pending": true,
      "confirmed": true,
      "completed": true,
      "declined": true,
    }
    doctorList.forEach(doctor => {
      tempFilterDictionary[doctor.external_id] = true
    })
    setFilterDictionary(tempFilterDictionary)

    const filteredAppointmentList = appointmentData.filter(appointment => {
      
      if(appointment.status === "PENDING" && !tempFilterDictionary["pending"]) { return false }
      if(appointment.status === "CONFIRMED" && !tempFilterDictionary["confirmed"]) {return false }
      if(appointment.status === "COMPLETED" && !tempFilterDictionary["completed"]) { return false }
      if(appointment.status === "DECLINED" && !tempFilterDictionary["declined"]) { return false }
      
      return tempFilterDictionary[appointment.d_id]
    })
   
    //Map appointments according to their dates. Appointment dictionary used to quickly get appointments from a certain date.
    const appointmentDataDictionary = {};
    
    filteredAppointmentList.forEach((apt) => {
      const aptKey = formatDateMMDDYYYY(parseInt(apt.schedule_time * 1000));
      if (appointmentDataDictionary[aptKey]) {
        appointmentDataDictionary[aptKey].push(apt);
      } else {
        appointmentDataDictionary[aptKey] = [apt];
      }
    });
    setAppointmentDictionary(appointmentDataDictionary);
    console.log("Dictionary", appointmentDataDictionary);
  }, [doctorList, appointmentData]);

  useEffect(() => {
    const handleClickOutsideEditDropdown = (event) => {
      if (
        manageDropdownRef.current &&
        !manageDropdownRef.current.contains(event.target)
      ) {
        setManageDropdownOpen(false);
      }
    };

    //window.addEventListener("click", handleClickOutsideEditDropdown);
    return () => {
      //window.removeEventListener("click", handleClickOutsideEditDropdown);
    };
  }, [])

  if (filterDictionary) {
    return (
      <div className="calendar-page-container">
        {isCreateIntakeModalOpen && <CreateIntakeModal currentHospitalData={currentHospitalData} onRequestClose={setCreateIntakeModalOpen} intakeModalData={intakeModalData} hospitalData={currentHospitalData} isCreateAppointmentModalOpen={isCreateAppointmentModalOpen} setCreateAppointmentModalOpen={setCreateAppointmentModalOpen}/>}
        <DateSlotPopup 
          setIntakeModalData={setIntakeModalData} 
          setCreateIntakeModalOpen={setCreateIntakeModalOpen} 
          appointmentIntakeDictionary={appointmentIntakeDictionary}
          isOpen={isPopupOpen} 
          onClose={() => setPopupOpen(false)} 
          currentHospitalData={currentHospitalData} 
          modalData={popupModalData}
          accountData={accountData} 
          setCreditWarningMessageOpen={setCreditWarningMessageOpen}

        />

        <div className="calendar-page-subcontainer">
          <div className="calendar-sidebar-container">
            <button className="calendar-sidebar-container-button" onClick={() => setCreateScheduleOpen(true)}>
              <i className="far fa-calendar-alt"></i> Create Schedule
            </button>
            <div className="booker-reason-input-container align-self-center" ref={manageDropdownRef} style={{width: '86%'}}>
              {currentHospitalData.schedules && currentHospitalData.schedules.length > 0 &&<button className="calendar-sidebar-container-button2" onClick={() => setManageDropdownOpen(!isManageScheduleDropdownOpen)}>
                <i className="far fa-edit"></i> Manage Schedule
              </button>}
              {isManageScheduleDropdownOpen && (
                <ul className="booker-dropdown-menu blue d-flex flex-column">
                  {scheduleList.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => setEditModalOpen(true, option)}
                    >
                      <h1>{option.schdeulde_name} ({findObjectById(option.d_id, doctorList).doctor_name_first})</h1>
                      <i className="fas fa-chevron-right"></i>
                      
                    </li>
                  ))}
                  {isPaginationLoading ? 
                    <LoadingSpinner/> 
                    :
                    (schedulePagination.next && <button className="plan-container-show-more-button " onClick={() => handleShowMoreSchedules()}>Show more</button>)
                  }
                </ul>
              )}
            </div>
            
            
            <div className="calendar-mini-container">
              {activeCalendarType === "monthly" ?
                <MiniCalendar displayedDate={displayedDate} setDisplayedDate={setDisplayedDate} increaseMonth={increaseMonth} decreaseMonth={decreaseMonth} setPopupOpen={() => { }} /> :
                <MiniCalendar displayedDate={displayedDateWeekly} setDisplayedDate={setDisplayedDateWeekly} increaseMonth={increaseMonth} decreaseMonth={decreaseMonth} setPopupOpen={() => { }} />
              }
            </div>
            <h4 className="calendar-sidebar-container-h4">Providers</h4>
            <div className="calendar-sidebar-providers">
              {doctorList.map(doctor => renderDoctorRow(doctor))}
            </div>
            <h4 className="calendar-sidebar-container-h4">Status</h4>
            <div className="calendar-sidebar-filters">
              <div className="calendar-sidebar-filter-row">
                <div className="d-flex align-items-center">
                  <div className="calendar-sidebar-status-indicator pending"></div>
                  <h5>Pending Clinic Response</h5>
                </div>
                <input
                  type="checkbox"
                  checked={filterDictionary.hasOwnProperty("pending") ? filterDictionary["pending"] : true}
                  onChange={() => toggleFilter("pending")}
                />
              </div>
              <div className="calendar-sidebar-filter-row">
                <div className="d-flex align-items-center">
                  <div className="calendar-sidebar-status-indicator confirmed"></div>
                  <h5>Confirmed</h5>
                </div>
                <input
                  type="checkbox"
                  checked={filterDictionary.hasOwnProperty("confirmed") ? filterDictionary["confirmed"] : true}
                  onChange={() => toggleFilter("confirmed")}
                />
              </div>
              <div className="calendar-sidebar-filter-row">
                <div className="d-flex align-items-center">
                  <div className="calendar-sidebar-status-indicator completed"></div>
                  <h5>Completed</h5>
                </div>
                <input
                  type="checkbox"
                  checked={filterDictionary.hasOwnProperty("completed") ? filterDictionary["completed"] : true}
                  onChange={() => toggleFilter("completed")}
                />
              </div>
              <div className="calendar-sidebar-filter-row">
                <div className="d-flex align-items-center">
                  <div className="calendar-sidebar-status-indicator declined"></div>
                  <h5>Declined</h5>
                </div>
                <input
                  type="checkbox"
                  checked={filterDictionary.hasOwnProperty("declined") ? filterDictionary["declined"] : true}
                  onChange={() => toggleFilter("declined")}
                />
              </div>
            </div>
          </div>
          <div className="calendar-calendar-container">
            <div className="calendar-page-header">
              <div className="d-flex align-items-center">

                <div className="d-flex flex-col align-items-center">
                  {activeCalendarType === "monthly" && <h2 className="calendar-date-txt d-flex">
                    {monthDictionary[displayedDate.getMonth()]}{" "}
                    {displayedDate.getFullYear()}{" "}
                  </h2>}
                  {activeCalendarType === "weekly" && <h2 className="calendar-date-txt d-flex">
                    {monthDictionary[displayedDateWeekly.getMonth()]}{" "}
                    {displayedDateWeekly.getDate()}{", "}
                    {displayedDateWeekly.getFullYear()}{" "}

                    -
                    {" "}
                    {monthDictionary[getLastDayOfWeek(displayedDateWeekly).getMonth()]}{" "}
                    {getLastDayOfWeek(displayedDateWeekly).getDate()}{", "}
                    {getLastDayOfWeek(displayedDateWeekly).getFullYear()}{" "}

                  </h2>}
                  <button
                    class="calendar-chevron-button-left"
                    onClick={() => handleLeftChevronClicked()}
                  >
                    <i class="fas fa-chevron-left"></i>
                  </button>
                  <button
                    class="calendar-chevron-button-right"
                    onClick={() => handleRightChevronClicked()}
                  >
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <button
                  class="calendar-today-btn"
                  onClick={() => setDisplayedDate(new Date())}
                >
                  Today
                </button>
                <select
                  className="calendar-choose-type"
                  value={activeCalendarType}
                  onChange={(e) => setActiveCalendarType(e.target.value)}
                >
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>

              </div>

            </div>
              {calendarLoading && 
                <div style={{marginBottom: '20px'}}><LoadingSpinner/></div>
              }
              {activeCalendarType === "monthly" && (
                <Calendar
                  setIntakeModalData={setIntakeModalData} 
                  setCreateIntakeModalOpen={setCreateIntakeModalOpen}
                  appointmentIntakeDictionary={appointmentIntakeDictionary}
                  year={displayedDate.getFullYear()}
                  month={displayedDate.getMonth()}
                  setPopupOpen={setPopupOpen}
                  appointmentDataDictionary={appointmentDictionary}
                  setPopupModalData={setPopupModalData}
                />
              )}
              {activeCalendarType === "weekly" &&
                <CalendarWeekly
                  setIntakeModalData={setIntakeModalData} 
                  setCreateIntakeModalOpen={setCreateIntakeModalOpen} 
                  appointmentIntakeDictionary={appointmentIntakeDictionary}
                  appointmentDataDictionary={appointmentDictionary}
                  appointmentList={appointmentData}
                  displayedDateWeekly={displayedDateWeekly}
                  setPopupOpen={setPopupOpen}
                  setPopupModalData={setPopupModalData}
                />
              }
            
            
          </div>
        </div>
      </div>
    );
  }

};

export default CalendarPage;
