import React, { useState, useEffect } from 'react';
import { appointment_calendar, check_circle_blue, check_circle_green, check_circle_red, check_circle_yellow, chevron_down_circle, x_close } from '../../home/image';
import { extractTimeInfo2, formatTimestamp, getInsuranceById, getPlanName } from '../../../assets/functions/functions';
import { acceptAppointment } from '../../../../api/patientAPIs';
import ConfirmationModal from '../../doctors/dashboard/patient-modals/action-confirmation-modal';
import WriteReviewModal from './write-review-modal';
import insuranceData from '../../../../sampledata/insurance.json'
const PatientAppointmentView = ({ appointmentData, hospitalData, index, isFromCalendar, reviewData, reviewDictionary, setReviewDictionary }) => {
    const [isExpanded, setIsExpanded] = useState(isFromCalendar ? true : false)
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
    const [confirmationModalType, setConfirmationModalType] = useState(null)
    const [confirmationModalData, setConfirmationModalData] = useState(null)

    const [reviewModalOpen, setReviewModalOpen] = useState(false)

    const handleReviewModalOpen = (e) => {
        e.stopPropagation()
        setReviewModalOpen(true)
    }

    const handleConfirmationModal = (modalData, type) => {
        setConfirmationModalOpen(true); 
        setConfirmationModalType(type)
        setConfirmationModalData(modalData)
    }

    const toggleExpand = () => {
        if(!isFromCalendar){
            setIsExpanded(!isExpanded)
        }
    }

    const handleGetDirections = () => {
        // Construct the Google Maps URL with the hospital's address
        const destinationAddress = `${hospitalData.location_info.street_address}, ${hospitalData.location_info.street_address_2}, ${hospitalData.location_info.city}, ${hospitalData.location_info.state} ${hospitalData.location_info.zip}`;
        const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
            destinationAddress
        )}`;

     // Open a new tab with the Google Maps URL
        window.open(mapsUrl, "_blank");
    };

    const formattedTimeString = extractTimeInfo2(new Date(appointmentData.schedule_time * 1000))

    const renderPending = () => {
        return (
            <div className='appointment-view-container'>
                <div className='appointment-view-header' onClick={() => toggleExpand()}>
                    <div className='appointment-view-header-left'>
                        <div className='appointment-view-header-id'>
                            {index}
                        </div>
                        <div className='appointment-view-header-date'>
                            <h1>{appointmentData.patient_info.reason}</h1>
                            <h2>{appointmentData.patient_info.patient_name_first} {appointmentData.patient_info.patient_name_last}</h2>
                        </div>
                        <div className='d-flex flex-column'>
                            <h3>{formattedTimeString.fullDateTime}</h3>
                        </div>
                    </div>
                    <div className='appointment-view-header-right'>
                        <h4>STATUS:</h4>
                        <h5>Pending Confirmation</h5>
                        <img alt='chevron' src={chevron_down_circle}/>
                    </div>
                </div>
                <div className={`appointment-view-body ${isExpanded ? 'expanded' : ''}`}>
                    <div className='appointment-view-left-container'>
                        <div className='appointment-view-left-top-section'>
                            <div className='appointment-view-title-section'>
                                <img
                                    alt='Check'
                                    src={check_circle_yellow}
                                />
                                <div className='d-flex flex-column'>
                                    <h1 style={{color:"#ff9900"}}>Appointment Requested!</h1>
                                    <h2>Please wait for the clinic to respond to your request.</h2>
                                    <div className='appointment-view-status-banner yellow'>
                                        Pending Clinic Response
                                    </div>
                                </div>
                            </div>
                            <div className='appointment-view-date-section'>
                                <img
                                    alt='Calendar'
                                    src={appointment_calendar}
                                />
                                <div className='d-flex flex-column'>
                                    <h1>Date & Time</h1>
                                    <h2>{formattedTimeString.fullDateTime}</h2>
                                </div>
                                <div className='d-flex flex-column align-items-center'>
                                    <button onClick={() => handleConfirmationModal(appointmentData, "MODIFY")}>
                                        Reschedule
                                    </button>
                                    <button className='appointment-view-date-section-btn-decline' onClick={() => handleConfirmationModal(appointmentData, "DECLINE")}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='appointment-view-left-info-section'>
                         <div className='appointment-view-left-info-row'>
                                <h1>Request Service / Care</h1>
                                <h1>{appointmentData.patient_info.reason}</h1>
                            </div>
                            <div className='appointment-view-left-info-row'>
                                <h1>Message to Clinic</h1>
                                <h1>{appointmentData.patient_info.message}</h1>
                            </div>
                        </div>
                        <div className='appointment-view-left-bottom-section'>
                            <div className='appointment-view-left-bottom-info'>
                                <h1>Appointment Location:</h1>
                                <h2>{hospitalData.hospital_name}</h2>
                                <h3>{hospitalData.location_info.street_address}, {hospitalData.location_info.street_address_2}, {hospitalData.location_info.city}, {hospitalData.location_info.state} {hospitalData.location_info.zip}</h3>
                            </div>
                            <button onClick={handleGetDirections}>
                                <u>GET DIRECTIONS</u>
                            </button>
                        </div>
                    </div>
    
                    <div className='appointment-view-right-container'>
                        <div className='appointment-view-right-top'>
                            <h1>Patient Details</h1>
                            <h2>Package / Tests Added</h2>
                            <div className='appointment-view-right-row'>
                                <h3>First Name</h3>
                                <h4>{appointmentData.patient_info.patient_name_first}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Last Name</h3>
                                <h4>{appointmentData.patient_info.patient_name_last}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Phone</h3>
                                <h4>{appointmentData.patient_info.phone}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Email</h3>
                                <h4>{appointmentData.patient_info.email}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Insurance Carrier</h3>
                                <h4>{getInsuranceById(appointmentData.patient_info.insurance, insuranceData) && getInsuranceById(appointmentData.patient_info.insurance, insuranceData).name}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Insurance Plan</h3>
                                <h4>{getPlanName(appointmentData.patient_info.insurance, appointmentData.patient_info.plan, insuranceData) && getPlanName(appointmentData.patient_info.insurance, appointmentData.patient_info.plan, insuranceData).title}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Member ID</h3>
                                <h4>{appointmentData.patient_info.member_id}</h4>
                            </div>
                            
                        </div>
                        {/* <div className='appointment-view-right-bottom'>
                            <h1>Achew Fee</h1>
                            <h2>$150.00</h2>
                        </div> */}
                        
    
                    </div>
                </div>
    
            </div>
        );
    }
    const renderConfirmed = () => {
        return (
            <div className='appointment-view-container'>
                <div className='appointment-view-header' onClick={() => setIsExpanded(!isExpanded)}>
                    <div className='appointment-view-header-left'>
                        <div className='appointment-view-header-id'>
                            {index}
                        </div>
                        <div className='appointment-view-header-date'>
                            <h1>{appointmentData.patient_info.reason}</h1>
                            <h2>{appointmentData.patient_info.patient_name_first} {appointmentData.patient_info.patient_name_last}</h2>
                        </div>
                        <div className='d-flex flex-column'>
                            <h3>{formattedTimeString.fullDateTime}</h3>
                        </div>
                    </div>
                    <div className='appointment-view-header-right'>
                        <h4>STATUS:</h4>
                        <h5>Confirmed</h5>
                        <img alt='chevron' src={chevron_down_circle}/>
                    </div>
                </div>
                <div className={`appointment-view-body ${isExpanded ? 'expanded' : ''}`}>
                    <div className='appointment-view-left-container'>
                        <div className='appointment-view-left-top-section'>
                            <div className='appointment-view-title-section'>
                                <img
                                    alt='Check'
                                    src={check_circle_green}
                                />
                                <div className='d-flex flex-column'>
                                    <h1 style={{color:"#12b76a"}}>Appointment Confirmed!</h1>
                                    <h2>The clinic has approved your appointment request. You're all set.</h2>
                                    <div className='appointment-view-status-banner green'>
                                        Confirmed
                                    </div>
                                </div>
                            </div>
                            <div className='appointment-view-date-section'>
                                <img
                                    alt='Calendar'
                                    src={appointment_calendar}
                                />
                                <div className='d-flex flex-column'>
                                    <h1>Date & Time</h1>
                                    <h2>{formattedTimeString.fullDateTime}</h2>
                                </div>
                                <div className='d-flex flex-column align-items-center'>
                                    <button onClick={() => handleConfirmationModal(appointmentData, "MODIFY")}>
                                        Reschedule
                                    </button>
                                    <button className='appointment-view-date-section-btn-decline' onClick={() => handleConfirmationModal(appointmentData, "DECLINE")}>
                                        Cancel
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                        <div className='appointment-view-left-info-section'>
                         <div className='appointment-view-left-info-row'>
                                <h1>Request Service / Care</h1>
                                <h1>{appointmentData.patient_info.reason}</h1>
                            </div>
                            <div className='appointment-view-left-info-row'>
                                <h1>Message to Clinic</h1>
                                <h1>{appointmentData.patient_info.message}</h1>
                            </div>
                        </div>
                        <div className='appointment-view-left-bottom-section'>
                            <div className='appointment-view-left-bottom-info'>
                                <h1>Appointment Location:</h1>
                                <h2>{hospitalData.hospital_name}</h2>
                                <h3>{hospitalData.location_info.street_address}, {hospitalData.location_info.street_address_2}, {hospitalData.location_info.city}, {hospitalData.location_info.state} {hospitalData.location_info.zip}</h3>
                            </div>
                            <button onClick={handleGetDirections}>
                                <u>GET DIRECTIONS</u>
                            </button>
                        </div>
                    </div>
    
                    <div className='appointment-view-right-container'>
                        <div className='appointment-view-right-top'>
                            <h1>Patient Details</h1>
                            <h2>Package / Tests Added</h2>
                            <div className='appointment-view-right-row'>
                                <h3>First Name</h3>
                                <h4>{appointmentData.patient_info.patient_name_first}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Last Name</h3>
                                <h4>{appointmentData.patient_info.patient_name_last}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Phone</h3>
                                <h4>{appointmentData.patient_info.phone}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Email</h3>
                                <h4>{appointmentData.patient_info.email}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Insurance Carrier</h3>
                                <h4>{getInsuranceById(appointmentData.patient_info.insurance, insuranceData) && getInsuranceById(appointmentData.patient_info.insurance, insuranceData).name}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Insurance Plan</h3>
                                <h4>{getPlanName(appointmentData.patient_info.insurance, appointmentData.patient_info.plan, insuranceData) && getPlanName(appointmentData.patient_info.insurance, appointmentData.patient_info.plan, insuranceData).title}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Member ID</h3>
                                <h4>{appointmentData.patient_info.member_id}</h4>
                            </div>
                            
                        </div>
                        {/* <div className='appointment-view-right-bottom'>
                            <h1>Achew Fee</h1>
                            <h2>$150.00</h2>
                        </div> */}
                        
    
                    </div>
                </div>
    
            </div>
        );
    }
    const renderCompleted = () => {
        return (
            <div className='appointment-view-container'>
                <div className='appointment-view-header' onClick={() => setIsExpanded(!isExpanded)}>
                    <div className='appointment-view-header-left'>
                        <div className='appointment-view-header-id'>
                            {index}
                        </div>
                        <div className='appointment-view-header-date'>
                            <h1>{appointmentData.patient_info.reason}</h1>
                            <h2>{appointmentData.patient_info.patient_name_first} {appointmentData.patient_info.patient_name_last}</h2>
                        </div>
                        <div className='d-flex flex-column'>
                            <h3>{formattedTimeString.fullDateTime}</h3>
                        </div>
                    </div>
                    <div className='appointment-view-header-right'>
                        {/* <h4>STATUS:</h4>
                        <h5>Completed</h5> */}
                        <button onClick={(e) => handleReviewModalOpen(e)}>{appointmentData.reviewID === "" ? "Write a Review" : "Edit Review"}</button>
                        <img alt='chevron' src={chevron_down_circle}/>
                    </div>
                </div>
                <div className={`appointment-view-body ${isExpanded ? 'expanded' : ''}`}>
                    <div className='appointment-view-left-container'>
                        <div className='appointment-view-left-top-section'>
                            <div className='appointment-view-title-section'>
                                <img
                                    alt='Check'
                                    src={check_circle_blue}
                                />
                                <div className='d-flex flex-column'>
                                    <h1 style={{color:"#044a9f"}}>Appointment Completed!</h1>
                                    <h2>This appointment is completed.</h2>
                                    <div className='appointment-view-status-banner blue'>
                                        Complete
                                    </div>
                                </div>
                            </div>
                            <div className='appointment-view-date-section'>
                                <img
                                    alt='Calendar'
                                    src={appointment_calendar}
                                />
                                <div className='d-flex flex-column'>
                                    <h1>Date & Time</h1>
                                    <h2>{formattedTimeString.fullDateTime}</h2>
                                </div>

                                
                                
                                
                            </div>
                        </div>
                        <div className='appointment-view-left-info-section'>
                         <div className='appointment-view-left-info-row'>
                                <h1>Request Service / Care</h1>
                                <h1>{appointmentData.patient_info.reason}</h1>
                            </div>
                            <div className='appointment-view-left-info-row'>
                                <h1>Message to Clinic</h1>
                                <h1>{appointmentData.patient_info.message}</h1>
                            </div>
                        </div>
                        <div className='appointment-view-left-bottom-section'>
                            <div className='appointment-view-left-bottom-info'>
                                <h1>Appointment Location:</h1>
                                <h2>{hospitalData.hospital_name}</h2>
                                <h3>{hospitalData.location_info.street_address}, {hospitalData.location_info.street_address_2}, {hospitalData.location_info.city}, {hospitalData.location_info.state} {hospitalData.location_info.zip}</h3>
                            </div>
                            <button onClick={handleGetDirections}>
                                <u>GET DIRECTIONS</u>
                            </button>
                        </div>
                    </div>
    
                    <div className='appointment-view-right-container'>
                        <div className='appointment-view-right-top'>
                            <h1>Patient Details</h1>
                            <h2>Package / Tests Added</h2>
                            <div className='appointment-view-right-row'>
                                <h3>First Name</h3>
                                <h4>{appointmentData.patient_info.patient_name_first}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Last Name</h3>
                                <h4>{appointmentData.patient_info.patient_name_last}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Phone</h3>
                                <h4>{appointmentData.patient_info.phone}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Email</h3>
                                <h4>{appointmentData.patient_info.email}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Insurance Carrier</h3>
                                <h4>{getInsuranceById(appointmentData.patient_info.insurance, insuranceData) && getInsuranceById(appointmentData.patient_info.insurance, insuranceData).name}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Insurance Plan</h3>
                                <h4>{getPlanName(appointmentData.patient_info.insurance, appointmentData.patient_info.plan, insuranceData) && getPlanName(appointmentData.patient_info.insurance, appointmentData.patient_info.plan, insuranceData).title}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Member ID</h3>
                                <h4>{appointmentData.patient_info.member_id}</h4>
                            </div>
                            
                        </div>
                        {/* <div className='appointment-view-right-bottom'>
                            <h1>Achew Fee</h1>
                            <h2>$150.00</h2>
                        </div> */}
                        
    
                    </div>
                </div>
    
            </div>
        );
    }
    const renderDeclined = () => {
        return (
            <div className='appointment-view-container'>
                <div className='appointment-view-header' onClick={() => setIsExpanded(!isExpanded)}>
                    <div className='appointment-view-header-left'>
                        <div className='appointment-view-header-id'>
                            {index}
                        </div>
                        <div className='appointment-view-header-date'>
                            <h1>{appointmentData.patient_info.reason}</h1>
                            <h2>{appointmentData.patient_info.patient_name_first} {appointmentData.patient_info.patient_name_last}</h2>
                        </div>
                        <div className='d-flex flex-column'>
                            <h3>{formattedTimeString.fullDateTime}</h3>
                        </div>
                    </div>
                    <div className='appointment-view-header-right'>
                        <h4>STATUS:</h4>
                        <h5>Declined</h5>
                        <img alt='chevron' src={chevron_down_circle}/>
                    </div>
                </div>
                <div className={`appointment-view-body ${isExpanded ? 'expanded' : ''}`}>
                    <div className='appointment-view-left-container'>
                        <div className='appointment-view-left-top-section'>
                            <div className='appointment-view-title-section'>
                                <img
                                    alt='Check'
                                    src={x_close}
                                />
                                <div className='d-flex flex-column'>
                                    <h1 style={{color:"#e52317"}}>Appointment Declined!</h1>
                                    <h2>This appointment was cancelled or the clinic has declined your request.</h2>
                                    <div className='appointment-view-status-banner red'>
                                        Declined
                                    </div>
                                </div>
                            </div>
                            <div className='appointment-view-date-section'>
                                <img
                                    alt='Calendar'
                                    src={appointment_calendar}
                                />
                                <div className='d-flex flex-column'>
                                    <h1>Date & Time</h1>
                                    <h2>{formattedTimeString.fullDateTime}</h2>
                                </div>
                                
                            </div>
                        </div>
                        <div className='appointment-view-left-info-section'>
                         <div className='appointment-view-left-info-row'>
                                <h1>Request Service / Care</h1>
                                <h1>{appointmentData.patient_info.reason}</h1>
                            </div>
                            <div className='appointment-view-left-info-row'>
                                <h1>Message to Clinic</h1>
                                <h1>{appointmentData.patient_info.message}</h1>
                            </div>
                        </div>
                        <div className='appointment-view-left-bottom-section'>
                            <div className='appointment-view-left-bottom-info'>
                                <h1>Appointment Location:</h1>
                                <h2>{hospitalData.hospital_name}</h2>
                                <h3>{hospitalData.location_info.street_address}, {hospitalData.location_info.street_address_2}, {hospitalData.location_info.city}, {hospitalData.location_info.state} {hospitalData.location_info.zip}</h3>
                            </div>
                            <button onClick={handleGetDirections}> 
                                <u>GET DIRECTIONS</u>
                            </button>
                        </div>
                    </div>
    
                    <div className='appointment-view-right-container'>
                        <div className='appointment-view-right-top'>
                            <h1>Patient Details</h1>
                            <h2>Package / Tests Added</h2>
                            <div className='appointment-view-right-row'>
                                <h3>First Name</h3>
                                <h4>{appointmentData.patient_info.patient_name_first}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Last Name</h3>
                                <h4>{appointmentData.patient_info.patient_name_last}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Phone</h3>
                                <h4>{appointmentData.patient_info.phone}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Email</h3>
                                <h4>{appointmentData.patient_info.email}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Insurance Carrier</h3>
                                <h4>{getInsuranceById(appointmentData.patient_info.insurance, insuranceData) && getInsuranceById(appointmentData.patient_info.insurance, insuranceData).name}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Insurance Plan</h3>
                                <h4>{getPlanName(appointmentData.patient_info.insurance, appointmentData.patient_info.plan, insuranceData) && getPlanName(appointmentData.patient_info.insurance, appointmentData.patient_info.plan, insuranceData).title}</h4>
                            </div>
                            <div className='appointment-view-right-row'>
                                <h3>Member ID</h3>
                                <h4>{appointmentData.patient_info.member_id}</h4>
                            </div>
                            
                        </div>
                        {/* <div className='appointment-view-right-bottom'>
                            <h1>Achew Fee</h1>
                            <h2>$150.00</h2>
                        </div> */}
                        
    
                    </div>
                </div>
    
            </div>
        );
    }

    if(appointmentData.status === "PENDING"){
        return (
        <>
            {confirmationModalOpen && <ConfirmationModal onRequestClose={() => setConfirmationModalOpen(false)} appointmentData={appointmentData} patient={false} modalType={confirmationModalType}/>}
            {renderPending()}
        </>
        )
    } else if (appointmentData.status === "CONFIRMED") {
        return (
            <>
                {confirmationModalOpen && <ConfirmationModal onRequestClose={() => setConfirmationModalOpen(false)} appointmentData={appointmentData} patient={false} modalType={confirmationModalType}/>}
                {renderConfirmed()}
            </>
            )
    } else if (appointmentData.status === "COMPLETED") {
        return (
            <>
                {confirmationModalOpen && <ConfirmationModal onRequestClose={() => setConfirmationModalOpen(false)} appointmentData={appointmentData} patient={false} modalType={confirmationModalType}/>}
                {reviewModalOpen && <WriteReviewModal reviewDictionary={reviewDictionary} setReviewDictionary={setReviewDictionary} onRequestClose={() => setReviewModalOpen(false) } appointmentData={appointmentData} hospitalData={hospitalData} reviewData={reviewData}/>}
                {renderCompleted()}
            </>
            )
    } else if (appointmentData.status === "DECLINED") {
        return (
            <>
                {confirmationModalOpen && <ConfirmationModal onRequestClose={() => setConfirmationModalOpen(false)} appointmentData={appointmentData} patient={false} modalType={confirmationModalType}/>}
                {renderDeclined()}
            </>
            )
    }
    
}

export default PatientAppointmentView;

