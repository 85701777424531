import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const MobileDropdownBottomSheet = ({ dropdownComponent, isMobileBottomSheetOpen, setMobileBottomSheetOpen, isFullScreen=false }) => {
  useEffect(() => {}, []);

  const toggleBottomSheet = () => {
    setMobileBottomSheetOpen(!isMobileBottomSheetOpen);
  };

  return (
    <div className={`mobile-bottomsheet ${isMobileBottomSheetOpen ? "open" : ""} ${isFullScreen ? "fullscreen" : ""}`}>
      <div className="mobile-bottomsheet-content">
        {dropdownComponent}
      </div>
    </div>
  );
};

export default MobileDropdownBottomSheet;
