import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import "yet-another-react-lightbox/styles.css";
import img1 from "../../../assets/images/features/feature-01.jpg";
import {
  getHospitalInfoByExternalID,
  testapi,
  getHospitalReviews,
  GetDoctor,
  GetNextHospitalReviewPage,
} from "../../../../api/patientAPIs";
import { useQuery } from "react-query";
import ReactModal from "react-modal";
import AppointmentModal from "../appointment-modal/appointment-modal.jsx";
import ImageModal from "../doctorprofile/profile-image-modal.jsx"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loader } from "@googlemaps/js-api-loader";
import Loading from "../../loading.jsx";
import {
  findObjectById,
  countRatings,
  getAvailableSlots,
  getInsuranceById,
  getPlanName,
  formatDateToLongString,
  getClinicProfilePicture,
  getHospitalPromotions,
  groupByInsuranceID,
  ensureHttps,
} from "../../../assets/functions/functions.js";
import InsuranceElement from "./insurance-element.jsx";
import AchewHeader from "../../achew-header.jsx";
import {
  chevron_down,
  filled_star,
  geo_black,
  green_check,
  highly_recomended,
  in_network,
  insurance_doctor,
  new_patient,
  sample_doctor,
  service_check,
  wait_time,
  placeholder_image
} from "../../home/image.jsx";
import Booker from "./booker.jsx";
import AchewFooter from "../../achew-footer.jsx";
import InsurancePicker from "./doctor-profile-insurance-picker.jsx";
import DoctorCard from "./doctor-card.jsx";
import ProfileDoctorModal from "./profile-doctor-modal.jsx";
import { useMediaQuery } from 'react-responsive'
import LoadingSpinner from "../bookingv2/spinner.jsx";
import InsuranceAccordian from "../../doctors/dashboard/patient-modals/insurance-accordian.jsx";
import Error from "../../error.jsx";

const DoctorProfile = (props) => {
  const today = new Date();
  const consecutiveDates = Array.from({ length: 14 }, (_, index) => {
    const date = new Date();
    date.setDate(today.getDate() + index);
    return date;
  });
  const daysOfWeek = {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
  };
  const months = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const pathParams = useParams();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 886px)' })
  const isSmallDesktop = useMediaQuery({ query: '(max-width: 1050px)' })
  const [isPageErrored, setPageErrored] = useState(false)

  const currentHospitalID = pathParams.hospitalID;
  const currentLocationID = searchParams.get("location");
  const isPreview = searchParams.get("preview");
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [isDoctorModalOpen, setDoctorModalOpen] = useState(false);
  const [currentLocationData, setCurrentLocationData] = useState({});
  const [currentImage, setCurrentImage] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(null)
  const [currentDoctor, setCurrentDoctor] = useState("");
  const [insuranceID, setInsuranceID] = useState(null)
  const [planID, setPlanID] = useState(null)

  const [reviewRatings, setReviewRatings] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  const [currentHospitalData, setCurrentHospitalData] = useState(null);
  const [doctorList, setDoctorList] = useState([])

  const [isBookerFixed, setBookerFixed] = useState(false);
  const [isFullText, setIsFullText] = useState(false);
  const [bookerPosition, setBookerPosition] = useState({
    xPosition: 0,
    yPosition: 0,
  });

  //Reviews
  const [reviewFilter, setReviewFilter] = useState("All")
  const [isReviewDropdownOpen, setIsReviewDropdownOpen] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [originalReviews, setOriginalReviews] = useState([])
  const [isPaginationLoading, setIsPaginationLoading] = useState(false)
  const [paginationData, setPaginationData] = useState(null)


  //Refs
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const aboutRef = useRef(null);
  const providersRef = useRef(null);
  const serviceRef = useRef(null);
  const locationRef = useRef(null);
  const reviewsRef = useRef(null);
  const insurancesRef = useRef(null);
  const reviewsDropdownRef = useRef(null);

  const renderImageModal = () => {
    if (isImageModalOpen) {
      return (
        <ImageModal
          currentHospitalData={currentHospitalData}
          currentImage={currentImage}
          setCurrentImage={setCurrentImage}
          setImageModalOpen={setImageModalOpen}
          currentImageIndex={currentImageIndex}
        />
      )
    }
  }

  const renderCurrentModal = (index) => {
    setCurrentImage(currentHospitalData.hospital_images[index])
    setCurrentImageIndex(index)
    setImageModalOpen(true)
  }

  const scrollToComponent = (componentRef) => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const googleMap = () => {
    return <div ref={mapRef} style={{ width: "100%", height: "248px" }}></div>;
  };

  const handleDoctorClicked = (doc) => {
    setDoctorModalOpen(true);
    setCurrentDoctor(doc);
  };

  const handleShowMoreReviews = () => {
    setIsPaginationLoading(true)
    GetNextHospitalReviewPage(ensureHttps(paginationData.next)).then(response => {
      const tempReviews = [...originalReviews, ...response.data.results]
      setOriginalReviews(tempReviews)
      setReviews(tempReviews)
      setPaginationData(response.data)
      setIsPaginationLoading(false)
    })
    .catch(err => {
      console.log(err)
      setIsPaginationLoading(false)
    })
  }
  const handleGetDirections = () => {
    // Construct the Google Maps URL with the hospital's address
    const destinationAddress = `${currentHospitalData.location_info.street_address}, ${currentHospitalData.location_info.street_address_2}, ${currentHospitalData.location_info.city}, ${currentHospitalData.location_info.state} ${currentHospitalData.location_info.zip}`;
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
        destinationAddress
    )}`;

 // Open a new tab with the Google Maps URL
    window.open(mapsUrl, "_blank");
  };

  const isInsuranceAccepted = () => {
    currentHospitalData.accepted_insurance.forEach(insurance => {
      if (insurance.insuranceID === insuranceID && insurance.planID === planID) { return true }
    })
    return false
  }

  const toggleReviewDropdown = () => {
    setIsReviewDropdownOpen(!isReviewDropdownOpen)
  };

  const handleReviewDropdownSelected = (doctor) => {
    setReviewFilter(doctor)
    if (doctor === "All") {
      setReviews(originalReviews)
    } else {
      const filteredReviews = reviews.filter(review => {
        return review.doctor_id === doctor.external_id
      })
      setReviews(filteredReviews)
    }
    setIsReviewDropdownOpen(false)
  };

  const renderInsuranceStatus = () => {
    if (insuranceID === null) {
      return null
    } else if (isInsuranceAccepted()) {
      return (
        <div className="doc-profile-insurance-picker-status">
          <i class="fas fa-check"></i>
          <h5>This insurance plan is accepted at this clinic!</h5>
        </div>
      )
    } else {
      return (
        <div className="doc-profile-insurance-picker-status2">
          <i class="fas fa-times"></i>
          <h5>This plan might not be accepted at this clinic. Please contact the clinic.</h5>
        </div>
      )
    }
  }

  const renderServices = (service, index) => {
    return (
      <div className="doc-profile-service-tag">
        <img alt="check" src={service_check} />
        <h1>{service}</h1>
      </div>
    );
  };

  const renderReview = (review) => {
    return (
      <div className="doc-profile-review">
        {renderStars(review.rating)}
        <h5>{review.content}</h5>
        <div className="doc-profile-review-metadata">
          <h2>{review.patient_info.patient_name_first} {review.patient_info.patient_name_last[0]}</h2>
          <i className="fas fa-circle"></i>
          <h3>{formatDateToLongString(new Date())}</h3>
          <i className="fas fa-circle"></i>
          <img alt="check" src={green_check} />
          <h4>Verified Patient</h4>
        </div>
      </div>
    );
  };
  const renderStars = (rating) => {
    const roundedRating = Math.round(rating);
    const stars = [];

    for (let i = 0; i < roundedRating; i++) {
      stars.push(<i key={i} className="fas fa-star filled"></i>);
    }
    for (let i = roundedRating; i < 5; i++) {
      stars.push(<i key={i} className="fas fa-star"></i>);
    }
    return <div className="profile-stars-group">{stars}</div>;
  };
  const renderStarsHeader = (rating) => {
    const roundedRating = Math.round(rating);
    const stars = [];

    for (let i = 0; i < roundedRating; i++) {
      stars.push(<i key={i} className="fas fa-star filled"></i>);
    }
    for (let i = roundedRating; i < 5; i++) {
      stars.push(<i key={i} className="fas fa-star"></i>);
    }
    return <div className="profile-stars-group">{stars}</div>;
  };

  useEffect(() => {
    getHospitalInfoByExternalID(currentHospitalID, true).then((response) => {
      const tempCurrentLocationData = response.data.location_info;
      const tempHospitalData = response.data
      setCurrentHospitalData(tempHospitalData);
      setCurrentLocationData(tempCurrentLocationData);

      var doctorPromises = [];
      tempHospitalData.doctors.forEach((doc) => {
        doctorPromises.push(GetDoctor(doc))
      })

      Promise.all(doctorPromises).then((docResults) => {
        const tempDoctorList = [];
        docResults.forEach((docResults) => tempDoctorList.push(docResults.data));
        setDoctorList(tempDoctorList);
      })
    }).catch(err => {
      setPageErrored(true)
    });

    getHospitalReviews(currentHospitalID).then((response) => {
      setReviews(response.data.results);
      setOriginalReviews(response.data.results)
      setReviewRatings(countRatings(response.data.results));
      setPaginationData(response.data)
    }).catch(err => {
      setPageErrored(true)
    });

    const handleClickReviewOutside = (event) => {
      if (reviewsDropdownRef.current && !reviewsDropdownRef.current.contains(event.target)) {
        setIsReviewDropdownOpen(false);
      }
    };
    // Attach the event listener
    window.addEventListener('click', handleClickReviewOutside);
    return () => {
      window.removeEventListener("click", handleClickReviewOutside);
    };
  }, []);

  useEffect(() => {
    if(currentHospitalData && currentLocationData) {
      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_KEY, // Replace with your Google Maps API key
        version: "weekly", // Specify the version of the API (e.g., "weekly" or a specific version)
        libraries: ["geometry", "places"],
      });
      const center = {
        lat: currentLocationData.coordinates.latitude,
        lng: currentLocationData.coordinates.longitude,
      };
      loader.load().then((google) => {
        const map = new google.maps.Map(mapRef.current, {
          center: center,
          zoom: 15,
        });
  
        const marker = new google.maps.Marker({
          position: map.getCenter(), // Set the marker position to the center of the map
          map: map, // Assign the marker to the map
        });
        markerRef.current = marker;
      });
    }
    
  }, [currentHospitalData, currentLocationData])

  const ratingBoxContainerRef = useCallback((node) => {
    if (node !== null) {
      const { top, left, width } = node.getBoundingClientRect();
      console.log(top);
      setBookerPosition({
        xPosition: left + width + 50,
        yPosition: top,
      });
    }
    const handleBookerScroll = () => {
      if (node) {
        const { top, left, width } = node.getBoundingClientRect();
        const scrollPosition = window.scrollY;
        const threshold = 50;

        if (scrollPosition > threshold) {
          setBookerFixed(true);
          setBookerPosition({
            xPosition: left + width + 50,
            yPosition: threshold,
          });
        } else {
          setBookerFixed(false);
          setBookerPosition({
            xPosition: left + width + 50,
            yPosition: top,
          });
        }
      };
    }

    window.addEventListener("scroll", handleBookerScroll);
    window.addEventListener("resize", handleBookerScroll)
    return () => {
      window.removeEventListener("scroll", handleBookerScroll);
      window.removeEventListener("resize", handleBookerScroll);
    };
  }, []); // Empty dependency array ensures the effect runs once after the initial render
  if(isPageErrored) {
    return <Error/>
  } else if (
    currentHospitalData == {} ||
    currentLocationData == {} ||
    currentHospitalData == null
  ) {
    return <Loading />;
  } else {
    

    const promotions = getHospitalPromotions(currentHospitalData)
    const categorizedInsurances = Object.values(groupByInsuranceID(currentHospitalData.accepted_insurance))

    return (
      <div className="doc-profile-container">
        {!isTabletOrMobile && !isSmallDesktop && <Booker isBookerFixed={isBookerFixed} bookerPosition={bookerPosition} currentHospitalData={currentHospitalData} doctorList={doctorList} />}
        <div className="doc-profile-header">
          <AchewHeader />
          <div className="doc-profile-clinic-info-left">
            <div className="d-flex align-items-center">
              <div className="doc-profile-clinic-info-left-img">
                <img
                  alt="Profile"
                  src={getClinicProfilePicture(currentHospitalData)}
                />
              </div>
              <div className="doc-profile-clinic-info-left-txt">
                <div className="doc-profile-clinic-info-left-specialty">
                  {currentHospitalData.main_specialty_title}
                </div>
                <h1>{currentHospitalData.hospital_name}</h1>

                <div className="doc-profile-location-container1">
                  <img alt="Location Icon" src={geo_black} />
                  <h2>{currentHospitalData.location_info.city}, </h2>
                  <h3>
                    {currentHospitalData.location_info.state}
                  </h3>
                </div>
                <div className="doc-profile-location-container2">
                  <h3>{currentHospitalData.location_info.street_address} {currentHospitalData.location_info.street_address_2.length > 0 && `(${currentHospitalData.location_info.street_address_2})`}, {currentHospitalData.location_info.zip}</h3>
                </div>
                

              </div>
            </div>
            <div
              className="doc-profile-header-rating tablet-only small-desktop-only"
              ref={ratingBoxContainerRef}
            >
              {reviews.length > 0 && (
                <h1>{reviewRatings.averageRating}</h1>
              )}
              {reviews.length === 0 && (
                <h3>No Reviews</h3>
              )}
              <div>{renderStars(reviewRatings.averageRating)}</div>
              <h2 onClick={() => scrollToComponent(reviewsRef)}>SEE ALL {reviews.length} REVIEWS</h2>
            </div>
          </div>

          {/* <div className="doc-profile-clinic-info-tags-container">
            <div className="doc-profile-clinic-info-tag-row">
              <div className="doc-profile-clinic-info-tag">
                <img alt="" src={highly_recomended} />
                <div className="d-flex flex-column">
                  <h1>{promotions[0].slogan}</h1>
                  <h2>{promotions[0].description}</h2>
                </div>
              </div>
              <div className="doc-profile-clinic-info-tag">
                <img alt="" src={wait_time} />
                <div className="d-flex flex-column">
                  <h1>{promotions[1].slogan}</h1>
                  <h2>{promotions[1].description}</h2>
                </div>
              </div>
            </div>
            <div className="doc-profile-clinic-info-tag-row">
              <div className="doc-profile-clinic-info-tag">
                <img alt="" src={new_patient} />
                <div className="d-flex flex-column">
                  <h1>{promotions[2].slogan}</h1>
                  <h2>{promotions[2].description}</h2>
                </div>
              </div>
              <div className="doc-profile-clinic-info-tag">
                <img alt="" src={in_network} />
                <div className="d-flex flex-column">
                  <h1>{promotions[3].slogan}</h1>
                  <h2>{promotions[3].description}</h2>
                </div>
              </div>
            </div>
          </div> */}
          {(isTabletOrMobile || isSmallDesktop) && <div className="doc-profile-sections-container">
            <button onClick={() => scrollToComponent(aboutRef)}>About</button>
            <button onClick={() => scrollToComponent(providersRef)}>
              Providers
            </button>
            <button onClick={() => scrollToComponent(serviceRef)}>
              Service and Care
            </button>
            <button onClick={() => scrollToComponent(insurancesRef)}>
              Insurances
            </button>
            <button onClick={() => scrollToComponent(locationRef)}>
              Location
            </button>
            <button onClick={() => scrollToComponent(reviewsRef)}>Reviews</button>
          </div>}

          {(isTabletOrMobile || isSmallDesktop) && <Booker isBookerFixed={false} bookerPosition={bookerPosition} currentHospitalData={currentHospitalData} doctorList={doctorList} />}
        
        </div>
        {!isTabletOrMobile &&  !isSmallDesktop && <div className="doc-profile-sections-container">
          <button onClick={() => scrollToComponent(aboutRef)}>About</button>
          <button onClick={() => scrollToComponent(providersRef)}>
            Providers
          </button>
          <button onClick={() => scrollToComponent(serviceRef)}>
            Service
          </button>
          <button onClick={() => scrollToComponent(insurancesRef)}>
            Insurances
          </button>
          <button onClick={() => scrollToComponent(locationRef)}>
            Location
          </button>
          <button onClick={() => scrollToComponent(reviewsRef)}>Reviews</button>
        </div>}


        <div className="doc-profile-about-container" ref={aboutRef}>
          <h1>About</h1>
          <h2>
            {isFullText
              ? currentHospitalData.about
              : currentHospitalData.about.slice(0, 300) + "..."}
          </h2>
        {currentHospitalData.about.length > 300 &&  <h3 onClick={() => setIsFullText(!isFullText)}>
            {isFullText ? "SHOW LESS" : "SHOW MORE"}
          </h3>}
        </div>

        <div className="doc-profile-doctor-container" ref={providersRef}>
          <h1>Healthcare Providers</h1>
          <div className="doc-profile-doctor-carousel">
            {doctorList.map(doc => <DoctorCard handleDoctorClicked={() => handleDoctorClicked(doc)} doctorData={doc} />)}

            {isDoctorModalOpen && <ProfileDoctorModal currentDoctor={currentDoctor} currentHospitalData={currentHospitalData} setCurrentDoctor={setCurrentDoctor} setDoctorModalOpen={setDoctorModalOpen} doctorList={doctorList} />}
          </div>
        </div>

        <div className="doc-profile-service-container" ref={serviceRef}>
          <h1>Service and Care</h1>
          <div className="doc-profile-service-list">
            {doctorList.map(doctor =>
              <div className="doc-profile-service-list-container">
                <h3>Services Offered by <b>Dr. {doctor.doctor_name_first} {doctor.doctor_name_last}</b></h3>
                <div className="doc-profile-service-list-inner">
                  {doctor.services.length > 0 ? doctor.services.map(service => renderServices(service)) : <h4></h4>}
                </div>

              </div>

            )}
          </div>
          {currentHospitalData.services.map((service, index) =>
            renderServices(service, index)
          )}
        </div>

        <div className="doc-profile-insurance-picker-container desktop-only">
          <img alt="Insurance" src={insurance_doctor} className="small-desktop-only tablet-only doc-profile-insurance-image" />
          <div className="doc-profile-insurance-picker-txt ">
            <h3>Is this provider in your insurance network? </h3>
            <h2>Pick your insurance and plan</h2>
            <InsurancePicker
              setInsuranceID={setInsuranceID}
              setPlanID={setPlanID}
              setSelectedInsurance={() => { }}
              setSelectedPlan={() => { }}
              handleInsuranceChosen={() => { }}
            />
            {renderInsuranceStatus()}
          </div>
        </div>

        <div className="doc-profile-insurances-container" ref={insurancesRef}>
          <h1>In-network insurances</h1>
          {currentHospitalData.accepted_insurance.length > 0 && <div className="d-flex align-items-center doc-profile-insurance-txt">
            <h5>99% of patients</h5>{" "}
            <h3>have success booking with these insurances</h3>
          </div>}
          <div className="doc-profile-insurances-list">
            {
              categorizedInsurances.map((insuranceGroup) => {
                return (
                  <InsuranceAccordian insuranceGroup={insuranceGroup} canDeleteInsurance={false}/>
                )
              })
            }
            {currentHospitalData.accepted_insurance.length === 0 && <h3>This clinic hasn't published their accepted insurances.</h3>}
          </div>
        </div>

        <div className="doc-profile-location-container-sub" ref={locationRef}>
          <h1>Location and Pictures</h1>
          <div className="doc-profile-location-subcontainer">
            <div className="d-flex flex-column doc-profile-clinic-images-container">
              {currentHospitalData.hospital_images[0] ? (
                <img
                  alt="Error"
                  src={currentHospitalData.hospital_images[0]}
                  className="hospital-image-first"
                  onClick={() => renderCurrentModal(0)}
                />
              ) : (
                <img
                  alt="Image 0"
                  src={placeholder_image}
                  className="hospital-image-placeholder-first"
                />
              )}
              <div className="office-location-pictures">
                {[...Array(5)].map((_, index) => {
                  const isLastImage = currentHospitalData.hospital_images.length > 6 && index === 4;

                  return (

                    <div class="position-relative hospital-image-rest-whole">
                      {currentHospitalData.hospital_images[index + 1] ? (
                        <>
                          <img
                            alt={`Image ${index + 1}`}
                            src={currentHospitalData.hospital_images[index + 1]}
                            className={`hospital-image-rest ${isLastImage ? 'hospital-image-last-blur' : ''}`}
                            onClick={() => renderCurrentModal(index + 1)}
                          />
                          {isLastImage && (
                            <div className="text-overlay" onClick={() => renderCurrentModal(index + 1)}>
                              +{currentHospitalData.hospital_images.length - 6}
                            </div>
                          )}
                        </>
                      ) : (
                        <img
                          alt={`Image ${index + 1}`}
                          src={placeholder_image}
                          className="hospital-image-placeholder-rest"
                        />
                      )}
                    </div>
                  );
                })}
              </div>

              {renderImageModal()}
            </div>
            <div className="doc-profile-location">
              {googleMap()}
              <h2>{currentHospitalData.hospital_name}</h2>
              <h3>
                {currentHospitalData.location_info.street_address},{" "}
                {currentHospitalData.location_info.street_address_2},{" "}
                {currentHospitalData.location_info.city},{" "}
                {currentHospitalData.location_info.state},{" "}
                {currentHospitalData.location_info.zip}
              </h3>
              <button onClick={handleGetDirections}>Get Directions</button>
            </div>
            <div className="doc-profile-images"></div>
          </div>
        </div>

        <div className="doc-profile-reviews" ref={reviewsRef}>
          <h1>Patient reviews</h1>
          <h2>
            All reviews have been submitted by patients after seeing the
            provider.
          </h2>
          <div
            className="doc-profile-reviews-rating"
          >
            {reviews.length > 0 ? <h1>{reviewRatings.averageRating}</h1> : <h3>No Reviews</h3>}
            <div>{renderStars(reviewRatings.averageRating)}</div>
          </div>
          <div className="doc-profile-reviews-toolbar">
            <h1>{reviews.length} reviews</h1>
            <div
              className="doc-profile-review-input-container"
              ref={reviewsDropdownRef}
            >
              <div
                className="doc-profile-review-dropdown"
                onClick={toggleReviewDropdown}
              >
                <h2>
                  {reviewFilter != "All"
                    ? reviewFilter.doctor_name_first + " " + reviewFilter.doctor_name_last
                    : "All"}
                </h2>
                <img alt="chevron down" src={chevron_down} />
              </div>
              {isReviewDropdownOpen && (
                <ul className="doc-profile-review-dropdown-menu">
                  <li
                    onClick={() => handleReviewDropdownSelected("All")}
                  >
                    All
                  </li>
                  {doctorList.map((doctor, index) => (
                    <li
                      key={index}
                      onClick={() => handleReviewDropdownSelected(doctor)}
                    >
                      Dr. {doctor.doctor_name_first} {doctor.doctor_name_last}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          {reviews.length > 0 ? reviews.map(review => renderReview(review)) : <div className="doc-profile-reviews-rating-noreviews">There are no reviews for <b>{currentHospitalData.hospital_name}</b></div>}
          {isPaginationLoading ? 
            <LoadingSpinner/> 
            :
            (paginationData.next && <button className="plan-container-show-more-button" onClick={() => handleShowMoreReviews()}>Show more</button>)
          }
        </div>
        <AchewFooter />
      </div>
    );
  }
};

export default DoctorProfile;
