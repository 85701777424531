import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  GetDoctor,
  getHospitalInfoByExternalID,
  requestSMSCode,
  setAppointments,
  setAppointmentsWithAuth,
  testapi,
  verifySMSCode,
} from "../../../../api/patientAPIs";
import { useSelector, useDispatch } from "react-redux";
import {
  setHospitalDataRedux,
} from "../../../../hooks/appointmentSlice.js";
import {
  convertDateStringToDateObject,
  convertTo12HourFormat,
  convertTo12HourFormatFromSlot,
  extractTimeInfo,
  formatDateToLongString,
  formatPhoneNumber2,
  formatPhoneNumber3,
  formatTimestamp,
  getDoctorProfilePicture,
  getLast4DigitsAsString,
  getMidnightUTCTimestamp,
  usStatesAbbreviations,
} from "../../../assets/functions/functions.js";
import Loading from "../../loading.jsx";
import AchewHeader from "./booking-header.jsx";
import {
  appointment_background,
  booking_doctor,
  chevron_down,
  sample_doctor,
} from "../../home/image.jsx";
import AchewFooter from "./booking-footer.jsx";
import Carousel from "react-bootstrap/Carousel";
import { convertDOBToDateObject } from "../../doctors/dashboard/prototype-calendar/helper-functions.jsx";
import InsurancePicker from "./insurance-dropdown-picker.jsx";
import LoadingSpinner from "./spinner.jsx";
import { useMediaQuery } from 'react-responsive'
import Error from "../../error.jsx";

const Booking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 886px)' })

  const currentHospitalID = location.state ? location.state.did : null;
  const dateParam = location.state ? location.state.s_id : null;
  const timeParam = location.state ? location.state.t_id : null;
  const isNewPatientParam = location.state ? (location.state.isNewPatient ? true : false) : null
  const reasonParam = location.state ? location.state.reason : null
  const scheduleIDParam = location.state ? location.state.schedule_id : null
  const doctorIDParam = location.state ? location.state.d_id : null
  const durationParam = location.state ? location.state.duration : null
  const appointmentDate = new Date(parseInt(dateParam));

  const stateDropdownRef = useRef(null);
  const reasonDropdownRef = useRef(null);
  const [isStateDropdownOpen, setStateDropdownOpen] = useState(false)
  const [isValidEma, setIsValidEma] = useState(true);
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [gender, setGender] = useState(null);
  const [reason, setReason] = useState(reasonParam);
  const [isReasonOpen, setReasonOpen] = useState(false);
  const [isNewPatient, setIsNewPatient] = useState(isNewPatientParam);
  const [hasReferral, setHasReferral] = useState(false)
  const [authorization, setAuthorization] = useState("")
  const [missingFields, setMissingFields] = useState([]);
  const [hospitalData, setHospitalData] = useState(null);
  const [currentDoctorData, setCurrentDoctorData] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [currentPage, setCurrentPage] = useState(0);
  const [dob, setDob] = useState("");
  const [isGenderDropdownOpen, setIsGenderDropdownOpen] = useState(false);
  const [phone, setPhone] = useState(null);
  const [code, setCode] = useState(null);
  const [fartyAuthToken, setFartyAuthToken] = useState("");
  const genderDropdownRef = useRef(null);
  const [address, setAddress] = useState("");
  const [apt, setApt] = useState("");
  const [city, setCity] = useState("");
  const [state, setStatee] = useState("");
  const [zip, setZip] = useState(null);
  const [message, setMessage] = useState("");
  const [memberID, setMemberID] = useState(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [smsChecked, setSMSChecked] = useState(true)
  const [insuranceID, setInsuranceID] = useState(null);
  const [planID, setPlanID] = useState(null);
  const [isBooking, setIsBooking] = useState(false);
  const [isConfirmingSMS, setIsConfirmingSMS] = useState(false)

  const toggleGenderDropdown = () => {
    setIsGenderDropdownOpen(!isGenderDropdownOpen);
  };

  const handleGenderDropdownSelected = (gender) => {
    setGender(gender);
    setIsGenderDropdownOpen(false);
  };

  const toggleReasonDropdown = () => {
    setReasonOpen(!isReasonOpen)
  };

  const handleReasonSelected = (reason) => {
    setReason(reason)
    setReasonOpen(false)
  };

  const openNewTab = (path) => {
    window.open(path, '_blank');
  };

  useEffect(() => {
    GetDoctor(doctorIDParam).then(response => {
      setCurrentDoctorData(response.data)
      console.log(doctorIDParam, response)
    }).catch(err => {

    })
    getHospitalInfoByExternalID(currentHospitalID).then((response) => {

      setHospitalData(response.data);
      dispatch(setHospitalDataRedux(response.data));
    });

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    const handleClickGenderOutside = (event) => {
      if (
        genderDropdownRef.current &&
        !genderDropdownRef.current.contains(event.target)
      ) {
        setIsGenderDropdownOpen(false);
      }
    };

    const handleClickStateOutside = (event) => {
      if (
        stateDropdownRef.current &&
        !stateDropdownRef.current.contains(event.target)
      ) {
        setStateDropdownOpen(false);
      }
    };


    // Attach the event listener
    window.addEventListener("resize", handleResize);
    window.addEventListener("click", handleClickGenderOutside);
    window.addEventListener("click", handleClickStateOutside);

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("click", handleClickGenderOutside);
      window.removeEventListener("click", handleClickStateOutside);
    };
  }, []);

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    // Basic email format validation
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const isValidEmail = emailRegex.test(inputValue);

    setIsValidEma(isValidEmail);
  };

  const handlePhoneChange = (e) => {
    // Remove non-numeric characters and limit to 10 digits
    const cleanedInput = e.target.value.replace(/\D/g, "").substring(0, 10);

    // Format the phone number
    let formattedPhoneNumber = "";

    if (cleanedInput.length >= 1) {
      formattedPhoneNumber = `(${cleanedInput.substring(0, 3)}`;

      if (cleanedInput.length >= 4) {
        formattedPhoneNumber += `) ${cleanedInput.substring(3, 6)}`;

        if (cleanedInput.length >= 7) {
          formattedPhoneNumber += `-${cleanedInput.substring(6, 10)}`;
        }
      }
    }

    setPhone(formattedPhoneNumber);
  };

  const handleDobChange = (event) => {
    let inputValue = event.target.value;

    // Remove non-digit characters
    inputValue = inputValue.replace(/\D/g, "");

    // Format the date as MM/DD/YYYY
    if (inputValue.length <= 2) {
      setDob(inputValue);
    } else if (inputValue.length <= 4) {
      setDob(`${inputValue.slice(0, 2)}/${inputValue.slice(2)}`);
    } else {
      setDob(
        `${inputValue.slice(0, 2)}/${inputValue.slice(2, 4)}/${inputValue.slice(
          4,
          8
        )}`
      );
    }
  };
  const handleCodeChange = (e) => {
    // Remove non-numeric characters using a regular expression
    const numericValue = e.target.value.replace(/\D/g, "");
    setCode(numericValue);
  };

  const shiftCarouselLeft = () => {
    setCurrentPage(currentPage - 1);
    setMissingFields([])
  };

  const shiftCarouselRight = () => {
    setCurrentPage(currentPage + 1);
    setMissingFields([])
  };

  const renderDoctorTab = () => {
    return (
      <div className="booking-doctor-tab">
        <div className="booking-doctor-tab-img">
          <img alt="Sample doctor" src={getDoctorProfilePicture(currentDoctorData)} />
        </div>
        <div className="booking-doctor-tab-txt">
          <h1>{hospitalData.hospital_name}</h1>
          <h3>with <b>Dr. {currentDoctorData.doctor_name_first} {currentDoctorData.doctor_name_last}</b></h3>
          <h2> {convertTo12HourFormatFromSlot(String(timeParam))} - {formatDateToLongString(dateParam)}</h2>
          
          <h3>{reasonParam}</h3>
        </div>
      </div>
    );
  };

  const firstPage = () => {
    return (
      <div className="booking-modal-container">
        <div className="booking-container-header">
          <div className="booking-container-header-txt">
            <h1 className="booking-intro-label">
              Book an appointment for free
            </h1>
            <p className="booking-intro-labelp">
              The office partners with Achew to schedule appointments
            </p>
          </div>
          <img alt="Booking" src={booking_doctor} className="desktop-only"/>
          {renderDoctorTab()}
        </div>
        <form
          className="booking-basic-info-section"
          onSubmit={submitRegistrationOne}
        >
          <h1 className="booking-intro-label">Tell us a bit about you.</h1>
          <p className="booking-intro-labelp">
            To book your appointment, we need to verify a few things for{" "}
            {hospitalData.hospital_name} office.
          </p>
          <h3 className="booking-email-label">Email</h3>
          <input
            type="email"
            className="email-input"
            name="email"
            value={email}
            placeholder="Enter your email"
            onChange={handleEmailChange}
          />
          <h3 className="booking-email-label">Phone number</h3>
          <input
            className="email-input"
            name="phone"
            value={phone}
            placeholder="(012) 345-6789"
            onChange={handlePhoneChange}
          />
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Legal first name</h3>
              <input
                className="name-input"
                name="first"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(c) => setFirstName(c.currentTarget.value)}
              />
            </div>

            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Legal last name</h3>
              <input
                className="name-input2"
                name="last"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(c) => setLastName(c.currentTarget.value)}
              />
            </div>
          </span>
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Date of birth</h3>
              <input
                className="name-input"
                name="dob"
                placeholder="MM/DD/YYYY"
                value={dob}
                onChange={handleDobChange}
              />
            </div>
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Gender</h3>

              <div className="booker-input-container" ref={genderDropdownRef}>
                <div className="booker-dropdown" onClick={toggleGenderDropdown}>
                  <h2>{gender ? gender : "Choose"}</h2>
                  <img alt="chevron down" src={chevron_down} />
                </div>
                {isGenderDropdownOpen && (
                  <ul className="booker-dropdown-menu">
                    {["Male", "Female", "Other"].map((option, index) => (
                      <li
                        key={index}
                        onClick={() => handleGenderDropdownSelected(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </span>
          {missingFields.length > 0 && (
            <h1 className="missingFields">
              * Please check these fields for errors: {missingFields.toString()}
            </h1>
          )}
          <button className="continue-booking-btn">Continue</button>
        </form>
      </div>
    );
  };

  const secondPage = () => {
    return (
      <div className="booking-modal-container">
        <div className="booking-container-header">
          <div className="booking-container-header-txt">
            <h1 className="booking-intro-label">
              Let's verify your phone number
            </h1>
            <p className="booking-intro-labelp">
              The office partners with Achew to schedule appointments
            </p>
          </div>
          <img alt="Booking" src={booking_doctor} className="desktop-only"/>
          {renderDoctorTab()}
        </div>
        <div className="booking-basic-info-section">
          <h1 className="booking-intro-label">
            We sent a text message to XXX-XXXX-{getLast4DigitsAsString(phone)}.
          </h1>
          <p className="booking-intro-labelp">
            Please enter the 6 digit confirmation code in the text.
          </p>
          <h3 className="booking-email-label">Confirmation code</h3>
          <input
            className="email-input"
            name="code"
            value={code}
            placeholder="XXXXXX"
            onChange={handleCodeChange}
            maxLength={6}
          />
          {missingFields.length > 0 && (
            <h1 className="missingFields">
              * Please check these fields for errors: {missingFields.toString()}
            </h1>
          )}
          <button
            className="continue-booking-btn"
            onClick={() => submitRegistrationTwo()}
          >
            {isConfirmingSMS ? <LoadingSpinner/> : "Continue"}
          </button>
          <button
            className="back-booking-btn"
            onClick={() => shiftCarouselLeft()}
          >
            Back
          </button>
        </div>
      </div>
    );
  };

  const thirdPage = () => {
    return (
      <div className="booking-modal-container">
        <div className="booking-container-header">
          <div className="booking-container-header-txt">
            <h1 className="booking-intro-label">Review and book</h1>
            <p className="booking-intro-labelp">
              The office partners with Achew to schedule appointments
            </p>
          </div>
          <img alt="Booking" src={booking_doctor} className="desktop-only"/>
          {renderDoctorTab()}
        </div>
        <div className="booking-basic-info-section">
          <h1 className="booking-intro-label">Patient Info</h1>
          <p className="booking-intro-labelp">
            Confirm that your information is correct
          </p>
          <h2 style={{marginTop:"15px"}}>
            {firstName} {lastName}
          </h2>
          <h2>{phone}</h2>
          <h2>{email}</h2>
          <h2>{reason}</h2>
          <h3 className="booking-email-label">
            Reason for Visit
          </h3>
          <div
          className="booker-reason-input-container"
          ref={reasonDropdownRef}
        >
          <div className="booker-body-dropdown" onClick={toggleReasonDropdown}>
            <h1>
              {reason && reason !== ""
                ? reason
                : "Choose a reason for your visit"}
            </h1>
            <img alt="chevron down" src={chevron_down} />
          </div>
          {isReasonOpen && (
            <ul className="booker-dropdown-menu">
              {currentDoctorData.services.map((option, index) => (
                <li key={index} onClick={() => handleReasonSelected(option)}>
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="booker-new-patient-container">
            <div className="booker-new-patient">
                <input
                    type="radio"
                    onChange={() => setIsNewPatient(true)}
                    checked={isNewPatient}
                />
                <h1>New patient</h1>
            </div>
            <div className="booker-new-patient">
                <input
                    type="radio"
                    onChange={() => setIsNewPatient(false)}
                    checked={isNewPatient === false}
                />
                <h1>Returning patient</h1>
            </div>
        </div>
        <h3 className="booking-email-label">
          Referral
        </h3>
        <div className="booker-new-patient-container" style={{marginTop:'10px'}}>
            <div className="booker-new-patient">
                <input
                    type="radio"
                    onChange={() => setHasReferral(true)}
                    checked={hasReferral}
                />
                <h1>I have a referral</h1>
            </div>
            <div className="booker-new-patient">
                <input
                    type="radio"
                    onChange={() => setHasReferral(false)}
                    checked={hasReferral === false}
                />
                <h1>I don't have a referral</h1>
            </div>
        </div>
        {
          hasReferral &&  
          <input
            className="email-input"
            type="text"
            name="authorization"
            value={authorization}
            placeholder="Enter your authorization code"
            onChange={(c) => setAuthorization(c.currentTarget.value)}
        />
        }
        </div>
        <form
          className="booking-basic-info-section-secondary"
          onSubmit={submitRegistrationOne}
        >
          <h1 className="booking-intro-label">Contact Information</h1>
          <p className="booking-intro-labelp">
            Let's finalize your appointment
          </p>
          <h3 className="booking-email-label">Street address</h3>
          <input
            className="email-input"
            type="text"
            name="address-line1"
            autoComplete="address-line1"
            value={address}
            placeholder="Enter your street address"
            onChange={(c) => setAddress(c.currentTarget.value)}
          />
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">
                Apt, suite, building (optional)
              </h3>
              <input
                className="name-input"
                name="address-line2"
                autoComplete="address-line2"
                placeholder="Enter apt, suite, or building"
                value={apt}
                onChange={(c) => setApt(c.currentTarget.value)}
              />
            </div>

            <div className="email-input-wrapper">
              <h3 className="booking-email-label">City</h3>
              <input
                className="name-input2"
                name="city"
                autoComplete="address-level2"
                placeholder="Enter city"
                value={city}
                onChange={(c) => setCity(c.currentTarget.value)}
              />
            </div>
          </span>
          <span className="d-flex flex-col justify-content-between">
            <div className="email-input-wrapper">
              <h3 className="booking-email-label">State</h3>

              <div className="booker-input-container" ref={stateDropdownRef}>
                <div className="booker-dropdown" onClick={() => setStateDropdownOpen(!isStateDropdownOpen)}>
                  <h2>{state ? state : "Choose"}</h2>
                  <img alt="chevron down" src={chevron_down} />
                </div>
                {isStateDropdownOpen && (
                  <ul className="booker-dropdown-menu">
                    {usStatesAbbreviations.map(
                      (option, index) => (
                        <li
                          key={index}
                          onClick={() => { setStatee(option); setStateDropdownOpen(false) }}
                        >
                          {option}
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
            </div>

            <div className="email-input-wrapper">
              <h3 className="booking-email-label">Zip code</h3>
              <input
                className="name-input2"
                name="postal-code"
                autoComplete="postal-code"
                placeholder="Enter your zip code"
                value={zip}
                onChange={(c) => setZip(c.currentTarget.value)}
              />
            </div>
          </span>
        </form>
        <form
          className="booking-basic-info-section-third"
          onSubmit={submitRegistrationOne}
        >
          <h1 className="booking-intro-label">Insurance information</h1>
          <p className="booking-intro-labelp">
            To book your appointment, we need to verify a few things for{" "}
            {hospitalData.hospital_name} office.
          </p>
          <InsurancePicker 
            setInsuranceID={setInsuranceID}
            setPlanID={setPlanID}
            setSelectedInsurance={() => { }}
            setSelectedPlan={() => { }}
            handleInsuranceChosen={() => { }}
          />
          <h3 className="booking-email-label">Insurance member ID</h3>
          <input
            className="email-input"
            name="email"
            value={memberID}
            placeholder="Enter your insurance member ID"
            onChange={(c) => setMemberID(c.currentTarget.value)}
          />
        </form>

        <form className="booking-basic-info-section-messages">
          <h1>Additional notes for the office (optional)</h1>
          <textarea
            className="email-input "
            name="email"
            value={message}
            placeholder="Enter a message (optional)"
            onChange={(c) => setMessage(c.currentTarget.value)}
          />
        </form>

        <div className="booking-basic-info-section-banner">
          <h1>
            Reminder: Appointments made on Achew must first be approved by the
            provider
          </h1>
          <h2>
            Please review Achew's FAQ to read about the full appointment process
          </h2>
        </div>
        <div className="booking-basic-info-section-sms-terms">
          <input
            type="checkbox"
            checked={smsChecked}
            onChange={(c) => setSMSChecked(!smsChecked)}
          />
          <h1>
            By checking this box, I agree to receive SMS text messages from Achew for reminders and important updates to your appointments.
          </h1>
        </div>
        <div className="booking-basic-info-section-terms">
          <input
            type="checkbox"
            checked={termsChecked}
            onChange={(c) => setTermsChecked(!termsChecked)}
          />
          <h1>
            By checking this box, I agree to Achew's<button onClick={() => openNewTab('/support/terms')} className="interal-link-button">Terms and Conditions</button>and<button onClick={() => openNewTab('/support/private-policy')} className="interal-link-button">Privacy Policy</button>.
          </h1>
        </div>
        {missingFields.length > 0 && (
          <div style={{marginLeft:"40px", marginRight:"40px"}}>
            <h1 className="missingFields">
              * Please check these fields for errors: {missingFields.toString()}
            </h1>
          </div>
        )}
        <button className="finish-booking-btn" onClick={() => submitRegistrationThree()}>
          {isBooking ? <LoadingSpinner /> : "Book Appointment"}
        </button>
        <button
          className="back-booking-btn"
          onClick={() => shiftCarouselLeft()}
        >
          Back
        </button>
      </div>
    );
  };
  const fourthPage = () => {
    console.log(new Date(getMidnightUTCTimestamp(new Date(parseInt(dateParam)))).getUTCDate())
    return (
      <div className="booking-modal-container">
        <div className="booking-container-header">
          <div className="booking-container-header-txt">
            <h1 className="booking-intro-label">
              You are all set!
            </h1>
            <p className="booking-intro-labelp">
              Your appointment with {hospitalData.hospital_name} has been
              booked.
            </p>
          </div>
          <img alt="Booking" src={booking_doctor} className="desktop-only"/>
        </div>
        <div className="booking-basic-info-section">
          <h1 className="booking-intro-label">
            Once the provider has approve your appointment, you will receive
            an update sent to {email}
          </h1>
          <p className="booking-intro-labelp">
            Your appointment confirmation will be sent to your email.
          </p>

          <button
            className="continue-booking-btn"
            onClick={() => navigate(`/appointment-search`)}
          >
            View Appointment Status
          </button>
        </div>
      </div>
    );
  };
  const submitRegistrationOne = (e) => {
    e.preventDefault();
    const tempDob = convertDOBToDateObject(dob);
    const tempMissingFields = [];
    if (email == null || !isValidEma) {
      tempMissingFields.push(" Email");
    }
    if (firstName === "" || firstName == null) {
      tempMissingFields.push(" First Name");
    }
    if (lastName === "" || firstName == null) {
      tempMissingFields.push(" Last Name");
    }
    if (dob == null || !tempDob) {
      tempMissingFields.push(" DOB");
    }
    if (gender == null || gender === "") {
      tempMissingFields.push("  Sex");
    }
    if (phone == null || phone === "") {
      tempMissingFields.push("  Phone");
    }
    if (tempMissingFields.length !== 0) {
      setMissingFields(tempMissingFields);
      return;
    } else {
      setMissingFields([]);
    }
    shiftCarouselRight();
    requestSMSCode(phone).then((response) => {
      if (response.status === 200) {
      }
    });
  };

  const submitRegistrationTwo = () => {
    setIsConfirmingSMS(true)
    verifySMSCode(formatPhoneNumber3(phone), code, lastName).then((response) => {
      console.log(response)
      const status = response.data.msg;
      if (status === "approved") {
        setFartyAuthToken(response.data.token)
        shiftCarouselRight();
      } else {
        setMissingFields(["Confirmation Code is incorrect"]);
      }
      setIsConfirmingSMS(false)
    });
  };

  const submitRegistrationThree = (e) => {
    const tempDob = convertDOBToDateObject(dob);
    const tempMissingFields = [];
    if (address == null || address.length == 0) {
      tempMissingFields.push(" address");
    }
    if (city === "" || city == null) {
      tempMissingFields.push(" city");
    }
    if (state === "" || state == null) {
      tempMissingFields.push(" state");
    }
    if (zip == null || !zip) {
      tempMissingFields.push(" zip");
    }
    if (reason == null || reason === "") {
      tempMissingFields.push("  visit reason");
    }
    if (termsChecked == null || termsChecked === false) {
      tempMissingFields.push("  Please agree to Achew's terms and conditions");
    }
    if (tempMissingFields.length !== 0) {
      setMissingFields(tempMissingFields);
      return;
    } else {
      setMissingFields([]);
    }
    const schedule_time = Math.floor(parseInt(dateParam) / 1000);
    const slot_time = parseInt(timeParam)
    const slot_date = (getMidnightUTCTimestamp(new Date(parseInt(dateParam))) / 1000)
    

    const appointmentData = {
      h_id: currentHospitalID,
      d_id: doctorIDParam,
      locationID: 1697847429996,
      reviewID: "",
      patient_info: {
        patient_name_first: firstName,
        patient_name_last: lastName,
        dob: convertDateStringToDateObject(dob),
        email: email,
        phone: formatPhoneNumber2(phone),
        reason_visit: reason,
        insurance: insuranceID,
        plan: planID,
        member_id: memberID,
        gender: gender,
        message: message,
        street_address: address,
        apt: apt,
        city: city,
        state: state,
        zip: zip,
        reason: reason,
        date: extractTimeInfo(new Date(parseInt(dateParam)), slot_time, hospitalData.timezone),
      },
      has_referral: hasReferral,
      auth_code: authorization,
      schedule_time: schedule_time,
      slot_time: slot_time,
      slot_date: slot_date,
      schedule_rule: parseInt(scheduleIDParam),
      duration: durationParam,
      status: "PENDING",
      price: 0.0,
      reminders_sent: 0,
      insurance: "Ins",
      id_card: "id",
      last_updated: new Date(),
      isNewPatient: isNewPatient,
      appointment_result: "Res",
      timezone: hospitalData.timezone,
      timeline: {},
      allow_sms: smsChecked,
    };

    setIsBooking(true);
    setAppointmentsWithAuth(currentHospitalID, appointmentData, fartyAuthToken).then((response) => {
        localStorage.setItem("fartyAuth", fartyAuthToken)
        if(response.status >= 200 && response.status < 300) {
          navigate('/booking-success', {state: {
            hospitalData: JSON.stringify(hospitalData),
            currentDoctorData: JSON.stringify(currentDoctorData),
            appointmentTime: convertTo12HourFormatFromSlot(String(timeParam)) + " - " + formatDateToLongString(dateParam)
          }})
        } else {
          setIsBooking(false);
          navigate('/error')
        }
    })
  };

  if(timeParam == null ||
    isNewPatientParam == null || 
    scheduleIDParam == null ||
    doctorIDParam == null ||
    durationParam == null){
        return <Error/>
  } else if (!hospitalData || !currentDoctorData) {
    return <Loading />;
  } 

  return (
    <div className="booking-container">
      <div className="booking-background-container tablet-only">
        <img alt="background" src={appointment_background} />
      </div>
      <Carousel controls={false} indicators={false} activeIndex={currentPage}>
        <Carousel.Item>{firstPage()}</Carousel.Item>
        <Carousel.Item>{secondPage()}</Carousel.Item>
        <Carousel.Item>{thirdPage()}</Carousel.Item>
        <Carousel.Item>{fourthPage()}</Carousel.Item>
      </Carousel>
      {!isTabletOrMobile && <AchewFooter />}
    </div>
  );
};

export default Booking;
