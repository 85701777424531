import React, {useState} from 'react';
import './mini-calendar.css'
import { dayMapping, isToday, sampleAppointmentDictionary,formatDateMMDDYYYY, getFirstDayOfWeek } from "../../../doctors/dashboard/prototype-calendar/helper-functions.jsx";
import { areDatesEqual, getFirstDayOfGivenWeek } from '../../../../assets/functions/functions.js';

const DateSlot = ({useExactDate, date, appointmentDataDictionary, weekIndex, setPopupOpen, setDisplayedDate, displayedDate, miniCalendarDisplayedDate, setMiniCalendarDisplayedDate, handleNewDateSearch}) => {
  
    const dateKey = formatDateMMDDYYYY(date)
    const handleDateClicked = () => {
        setMiniCalendarDisplayedDate(date)
        if(useExactDate){
          setDisplayedDate(date)
        } else {
          setDisplayedDate(getFirstDayOfGivenWeek(date))
        }
        if(handleNewDateSearch){
          console.log()
          handleNewDateSearch(getFirstDayOfGivenWeek(date).getTime())
        }
        setPopupOpen(false)
    }
    const isThisMonth = miniCalendarDisplayedDate.getMonth() === date.getMonth()
  return(
    <div className="mini-date-slot-container">
        {weekIndex === 0 && <h1>{dayMapping[date.getDay()]}</h1>}
        {isThisMonth ? 
          <h2 className={isToday(date) ? "mini-calendar-todayDate" : "mini-date-slot-container-notToday"} onClick={() => handleDateClicked()}>{date.getDate()}</h2>
           : 
           <h2 className= "mini-calendar-notThisMonth" >{date.getDate()}</h2>
           }
    </div>
  )
};
export default DateSlot;