import React, { useEffect, useCallback, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

const FooterBottomSheetContent = ({setFooterModalOpen, searchWithSpecialty}) => {

    const navigate = useNavigate()

    return (
      <div className="date-dropdown-menu bottomsheet">
        <div className="mobile-footer">
          <div className="mobile-footer-header">
            <div className="search-results-mobile-logo">Achew</div>
            <i className="fa fa-times" onClick={() => setFooterModalOpen(false)}></i>
          </div>
          <div className="mobile-footer-body">
            <button className="mobile-footer-body-button-main" onClick={() => navigate("/")}> Achew</button>
            <button onClick={() => searchWithSpecialty("Primary%20Care%20Physician%20(PCP)")}>Primary Care</button>
            <button onClick={() => searchWithSpecialty("Dentist")}>Dentist</button>
            <button onClick={() => searchWithSpecialty("OB-GYN%20(Obstetrician-Gynecologist)")}>OB-GYN</button>
            <button onClick={() => searchWithSpecialty("Dermatologist")}>Dermatologist</button>
            <button onClick={() => searchWithSpecialty("Psychiatrist")}>Psychiatrist</button>
            <button onClick={() => searchWithSpecialty("Eye%20Doctor")}>Eye Doctor</button>

            <h2 onClick={() => navigate("/support/contact")}>Contact us</h2>
            <h3 onClick={() => navigate("/support/terms")}>Terms and conditions</h3>
            <h3 onClick={() => navigate("/support/private-policy")}>Privacy Policy</h3>
            <h3 onClick={() => navigate("/support/faqs")}>Frequently asked questions</h3>

          </div>
        </div>
      </div>
    );
  };

  export default FooterBottomSheetContent