import React, { useState, useEffect, useRef } from 'react';
import { enable_auto_charge_bank_account, onboarding_dashboard_overview } from '../../../home/image';
import { render } from 'react-dom';

const OnboardingTypes = Object.freeze({
    AUTOPAY:   "autoPayReminder",
    BALANCE: "lowBalanceReminder",
    DASHBOARD: "dashboardOverview"
});

const OnboardingModal = ({onboardingInfoToShow}) => {

    const [dontShowAgain, setDontShowAgain] = useState(false)
    const [onboardingPagesList, setOnboardingPagesList] = useState([])
    const [tabIndex, setTabIndex] = useState(0)
    const [isVisible, setIsVisible] = useState(true)


    useEffect(() => {
        console.log(localStorage.getItem("onboardingHistoryConfig"))
        const tempOnboardingList = JSON.parse(localStorage.getItem("onboardingHistoryConfig"))
        console.log(tempOnboardingList, onboardingInfoToShow)
        if(Array.isArray(tempOnboardingList)) {
            setOnboardingPagesList(
                tempOnboardingList
            )
        } else {
            setOnboardingPagesList([])
        }

        if(tempOnboardingList && tempOnboardingList.indexOf(onboardingInfoToShow[tabIndex]) !== -1) {
            setIsVisible(false)
        }
    }, []);

    const handleCloseModal = () => {
        if(dontShowAgain){
            const tempOnboardingHistoryConfig = [...onboardingPagesList]
            tempOnboardingHistoryConfig.push(onboardingInfoToShow[tabIndex])
            console.log(tempOnboardingHistoryConfig, onboardingPagesList)
            localStorage.setItem("onboardingHistoryConfig", JSON.stringify(tempOnboardingHistoryConfig))
        }
        setIsVisible(false)
    }

    const renderOnboardingType = (type) => {
        var modalBody
        var modalPicture
        if(type === OnboardingTypes.DASHBOARD) {
            modalPicture = onboarding_dashboard_overview
            modalBody = (
                <div className="onboarding-modal-textcontainer1">
                    <h1>Welcome to your Dashboard!</h1>

                    <div className="onboarding-modal-textcontainer-group">
                        <h2>Clinic summary</h2>
                        <h3>View important statistics about your clinic such as the number of bookings, impressions, and more.</h3>
                    </div>
                    <div className="onboarding-modal-textcontainer-group">
                        <h2>Calendar</h2>
                        <h3>Visualize your weekly and monthly schedule across all your staff</h3>
                    </div>
                    <div className="onboarding-modal-textcontainer-group">
                        <h2>Appointment manager</h2>
                        <h3>Accept, reschedule, and decline appointments. View patient information and bookings</h3>
                    </div>
                </div>
            )
        } else if (type === OnboardingTypes.AUTOPAY) {
            modalPicture = enable_auto_charge_bank_account
            modalBody = (
                <div className="onboarding-modal-textcontainer1">
                    <h1>Set up AutoCharge</h1>
                    <h5>Enable auto pay to refill your balance whenever it's low. Always have your slots open.</h5>
                </div>
            )
        } else if (type === OnboardingTypes.BALANCE) {
            modalPicture = enable_auto_charge_bank_account
            modalBody = (
                <div className="onboarding-modal-textcontainer1">
                    <h1>Low Balance</h1>
                    <h5>Enable auto pay to refill your balance whenever it's low. Always have your slots open.</h5>
                </div>
            )
        }
        return {modalBody, modalPicture}
    }

    if(!isVisible) {
        return
    }

    return (
        <div className="onboarding-modal-overlay">
            <div className="onboarding-modal">
                <div className="onboarding-modal-body">
                    <div className="onboarding-modal-image-container">
                        <img alt="onboarding image" src={renderOnboardingType(onboardingInfoToShow[tabIndex]).modalPicture}/>
                    </div>
                    {renderOnboardingType(onboardingInfoToShow[tabIndex]).modalBody}

                    
                </div>
                <div className="onboarding-modal-footer">
                    <div className="d-flex align-items-center">
                        <input
                            type='checkbox'
                            checked={dontShowAgain}
                            onChange={() => setDontShowAgain(!dontShowAgain)}
                        />
                        <h4>Don't show this message again</h4>
                    </div>
                    <button onClick={() => handleCloseModal()}>Get Started</button>
                </div>
            </div>
        </div>
    );
};

export {OnboardingTypes, OnboardingModal}