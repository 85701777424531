import React, { useState, useEffect, useRef } from "react";
import { getAppointments, getHospitalReviewByID, getHospitalReviewByIDWithFartyToken, requestPatientAppointment, requestPatientAppointmentV2, requestPatientToken } from "../../../../api/patientAPIs";
import { getAccountInfo, getHospitalInfoByInternalID, getHospitalInfoByExternalID } from '../../../../api/patientAPIs';
import { useNavigate, useLocation, json } from 'react-router-dom';
import { setAccountInfo } from '../../../../hooks/accountSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../loading';
import {
    formatDateToLongString,
    findObjectById
} from '../../../assets/functions/functions'
import Pagination from "../../search/pagination";
import AchewHeader from "../../achew-header";
import AchewFooter from "../../achew-footer";
import AppointmentView from "./patient-appointment-view";
import WriteReviewModal from "./write-review-modal";
import AppointmentPagination from "../../doctors/dashboard/appointment-pagination";
import PatientAppointmentPagination from "./patient-appointment-pagination";
import PatientAppointmentView from "./patient-appointment-view";


const PatientAppointment = () => {
    const navigate = useNavigate()
    const location = useLocation( )
    const searchParams = new URLSearchParams(location.search);
    const pageNumParam = searchParams.get('page_num') ? parseInt(searchParams.get('page_num')) : 1
    const authToken = localStorage.getItem("fartyAuth")
    /* Appointment Lists */
    const [hospitalDataDictionary, setHospitalDataDictionary] = useState({})
    //Pagination
    const [paginationData, setPaginationData] = useState(null)
    const [refresh, setRefresh] = useState(false)

    /* Reviews */
    const [reviewDictionary , setReviewDictionary] = useState(null)

    const [currentIndex, setCurrentIndex] = useState(0)

    const [didFinishLoadingHospitals, setDidFinishLoadingHospitals] = useState(false)
    const [didFinishLoadingReviews, setDidFinishLoadingReviews] = useState(false)
    const [currentAppointmentData, setCurrentAppointmentData] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0);
        setDidFinishLoadingHospitals(false)
        setDidFinishLoadingReviews(false)
        requestPatientAppointmentV2(pageNumParam, authToken)
            .then(appointmentResponse => {
                const tempCurrentAppointmentData = appointmentResponse.data.results
                console.log(appointmentResponse)
                //Get all hospitals needed to support current appointments
                const tempHosptialDictionary = {}
                const hospitalPromises = []
                tempCurrentAppointmentData.forEach(apt => {
                    if(!(apt in tempHosptialDictionary)){
                        tempHosptialDictionary[apt.h_id] = true
                        hospitalPromises.push(getHospitalInfoByExternalID(apt.h_id))
                    }
                })

                Promise.all(hospitalPromises).then((hospitalResults) => {
                    hospitalResults.forEach(hospitalResponse => {
                        tempHosptialDictionary[hospitalResponse.data.external_id] = hospitalResponse.data
                    })
                    setDidFinishLoadingHospitals(true)
                    setDidFinishLoadingReviews(true)
                })
                setHospitalDataDictionary(tempHosptialDictionary)
    
                const tempPast = tempCurrentAppointmentData.filter(apt => apt.status === "COMPLETED" || apt.status === "DECLINED")
                setPaginationData(appointmentResponse.data)
                setCurrentAppointmentData(tempCurrentAppointmentData)


                //Get all reviews needed to support current appointments
                var reviewPromises = [];
                const tempReviewDictionary = {}
                tempPast.forEach((apt) => {
                    if(apt.reviewID != ""){
                        reviewPromises.push(getHospitalReviewByIDWithFartyToken(apt.reviewID))
                    }
                })
                
                Promise.all(reviewPromises).then((reviewResults) => {
                    reviewResults.forEach(reviewResponse => {
                        tempReviewDictionary[reviewResponse.data.external_id] = reviewResponse.data
                    })
                })
                console.log(tempReviewDictionary)
                setReviewDictionary(tempReviewDictionary)
            })
            .catch(err => {
                console.log(err)
                localStorage.removeItem("fartyAuth")
                navigate('/appointment-search')
            })
    }, [refresh]);

    const handleLogOut = () => {
        localStorage.removeItem("fartyAuth")
        navigate('/appointment-search')
    }

    if (!didFinishLoadingHospitals) {
        return (<Loading />)
    }
    return (
        <div className='apt-grey-supercontainer-patient'>
            <AchewHeader useSignOut={true}/>
            <div className="apt-supercontainer-patient" style={{minHeight:"100vh"}}>
                <div className="appointment-view-list">
                    <div className="appointment-view-list-group">
                        <div className="appointment-view-list-group-title-group">
                            <p className="appointment-view-list-title">
                                Your Appointments
                            </p>
                        </div>
                        <div className="appointment-view-list-views">
                            {currentAppointmentData.map((appointmentData, index) => <PatientAppointmentView hospitalData={hospitalDataDictionary[appointmentData.h_id]} appointmentData={appointmentData} index={index} reviewData={reviewDictionary[appointmentData.reviewID]} reviewDictionary={reviewDictionary} setReviewDictionary={setReviewDictionary}/>)}
                        </div>
                        
                        <div className="appointment-view-list-pagination-container">
                            <PatientAppointmentPagination paginationData={paginationData} setRefresh={setRefresh} refresh={refresh}/>
                        </div>
                    </div>
                    
                </div>
            </div>
            <AchewFooter/>
        </div>
    )
}

export default PatientAppointment