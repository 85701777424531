import { createSlice } from '@reduxjs/toolkit';

export const appointmentSlice = createSlice({
    name: 'appointment',
    initialState: {
        first_name: null,
        last_name: null,
        email: null,
        month: null,
        day: null,
        year: null,
        phone: null,
        gender: null,
        isPhoneVerified: false,
        reason: null,
        date: null,
        hospital_data: null,
        doctor_data: null,
        newPatient: null,
    },
    reducers: {
        setFirstNameRedux: (state, action) => {
            state.first_name = action.payload;
        },
        setLastNameRedux: (state, action) => {
            state.last_name = action.payload;
        },
        setEmailRedux: (state, action) => {
            state.email = action.payload;
        },
        setMonthRedux: (state, action) => {
            state.month = action.payload;
        },
        setDayRedux: (state, action) => {
            state.day = action.payload;
        },
        setYearRedux: (state, action) => {
            state.year = action.payload;
        },
        setPhoneRedux: (state, action) => {
            state.phone = action.payload;
        },
        setGenderRedux: (state, action) => {
            state.gender = action.payload;
        },
        setPhoneVerifiedRedux: (state, action) => {
            state.isPhoneVerified = action.payload;
        },
        setReasonRedux: (state, action) => {
            state.reason = action.payload;
        },
        setNewPatientRedux: (state, action) => {
            state.newPatient = action.payload;
        },
        setDateRedux: (state, action) => {
            state.date = action.payload;
        },
        setHospitalDataRedux: (state, action) => {
            state.hospital_data = action.payload;
        },
        setDoctorDataRedux: (state, action) => {
            state.doctor_data = action.payload
        }
    },
});

// Action creators are generated for each case reducer function
export const {
    setFirstNameRedux,
    setLastNameRedux,
    setEmailRedux,
    setMonthRedux,
    setDayRedux,
    setYearRedux,
    setPhoneRedux,
    setNewPatientRedux,
    setGenderRedux,
    setPhoneVerifiedRedux,
    setReasonRedux,
    setDateRedux,
    setHospitalDataRedux,
    setDoctorDataRedux,
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
