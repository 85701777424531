import React, { useState } from "react";
import img1 from "../../../../assets/images/features/feature-01.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import { 
    convertTimestampsToTime,
    getAvailableSlots,
    formatTimeDate
} from "../../../../assets/functions/functions";


const RescheduleAppointmentModal = (props) => {
    const { closeAppointmentModal, appointmentModalData, appointment, setActionModelOpen, setActionModalType, setSelectedRescheduleDate } = props
    const appointmentModalFirstDate = new Date(appointment.date)
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const currentLocationID = searchParams.get("location")
    const isPreview = searchParams.get("preview")

    const handleSelectedDate = (date) => {
        closeAppointmentModal(true)
        setActionModelOpen(true)
        setSelectedRescheduleDate(date)
        setActionModalType("MODIFY")
    }

    const slot = (date) => {
        return (
            <button
                onClick={() => handleSelectedDate(date)}
            >
                {formatTimeDate(date)}
            </button>
        )
    }

    const day = (d) => {
        const openSlots = getAvailableSlots(appointmentModalData, d)
        return (
            <div>
                <h5>{daysOfWeek[d.getDay()]}, {months[d.getMonth()]} {d.getDate()}</h5>
                <div className="apt-modal-slots-container">

                    {openSlots.map(s => slot(s))}
                </div>
            </div>
        )
    }

    const today = appointmentModalFirstDate
    const consecutiveDates = Array.from({ length: 14 }, (_, index) => {
        const date = new Date();
        date.setDate(today.getDate() + index);
        return date
    })
    const [dateRange, setDateRange] = useState(consecutiveDates);
    const lastDate = dateRange[dateRange.length - 1];
    const daysOfWeek = {
        0: 'Sun',
        1: 'Mon',
        2: 'Tue',
        3: 'Wed',
        4: 'Thu',
        5: 'Fri',
        6: 'Sat'
    };
    const months = {
        0: 'Jan',
        1: 'Feb',
        2: 'Mar',
        3: 'Apr',
        4: 'May',
        5: 'Jun',
        6: 'Jul',
        7: 'Aug',
        8: 'Sep',
        9: 'Oct',
        10: 'Nov',
        11: 'Dec'
    };

    const shiftDatesLeft = () => {
        const shiftedDateArray = dateRange.map(date => {
            const newDate = new Date(date);
            newDate.setDate(date.getDate() - 14); // Add 14 days
            return newDate;
        });

        setDateRange(shiftedDateArray)
    }

    const shiftDatesRight = () => {
        const shiftedDateArray = dateRange.map(date => {
            const newDate = new Date(date);
            newDate.setDate(date.getDate() + 14); // Add 14 days
            return newDate;
        });

        setDateRange(shiftedDateArray)
    }


    return (
        <div className="appointment-modal-container">
            <button className="modal-close-btn" onClick={closeAppointmentModal}><i className="fa fa-times" /></button>
            <div className="appointment-modal-top-section">
                <h1>Choose a date and time to reschedule this appointment</h1>
                <div className="d-flex flex-col align-items-center mt-3">
                    <button class="chevron-button-left" onClick={() => shiftDatesLeft()}>
                        <i class="fas fa-chevron-left"></i>
                    </button>
                    <h2 className="reschedule-date-switcher-txt">{daysOfWeek[dateRange[0].getDay()]}, {months[dateRange[0].getMonth()]} {dateRange[0].getDate()} {dateRange[0].getFullYear()} - {daysOfWeek[lastDate.getDay()]}, {months[lastDate.getMonth()]} {lastDate.getDate()} {lastDate.getFullYear()}</h2>
                    <button class="chevron-button-right" onClick={() => shiftDatesRight()}>
                        <i class="fas fa-chevron-right"></i>
                    </button>
                </div>
                
            </div>

            <div className="appointment-modal-list">
                {dateRange.map(date => {
                    return (day(date))
                })}
            </div>
        </div>
    )
}

export default RescheduleAppointmentModal;