import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
const DonutChart = ({ percentages }) => {
  const data = {
    datasets: [
      {
        data: percentages,
        backgroundColor: ['#ff9900', '#12b76a', '#044a9f', '#e52317'],
        borderWidth: 0, // Remove border around sections
      },
    ],
  };

  const options = {
    cutout: '60%', // Adjust the hole size
  };

  return(
    <div style={{ width: '70%',height:'auto', background:'white', borderRadius:'50%' }}>
      <Doughnut data={data} options={options} />
    </div>
  )
};

export default DonutChart;