import React, { useState, useEffect, useRef } from 'react';
import { postNewIntake } from '../../../../../api/patientAPIs';
import LoadingSpinner from '../../../patients/bookingv2/spinner';
import { formatPhoneNumber2, formatPhoneNumber5 } from '../../../../assets/functions/functions';

const CreateIntakeModal = ({currentHospitalData, onRequestClose, toggleAlertWithMessage, intakeModalData=null, setAppointmentIntakeDictionary= ()=>{}, appointmentIntakeDictionary={} }) => {

    //Patient Info
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [phone, setPhone] = useState(null)
    const [email, setEmail] = useState(null)
    const [isSpecialtyDropdownOpen, setSpecialtyDropdownOpen] = useState(false);
    const specialtyDropdownRef = useRef(null);
    const [specialty, setSpecialty] = useState(null)

    //Request settings
    const [isIDRequired, setIDRequired] = useState(true)
    const [isInsuranceRequired, setInsuranceRequired] = useState(true)


    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showValidationError, setShowValidationError] = useState(false)
    useEffect(() => {

        if(intakeModalData) {
            setFirstName(intakeModalData.patient_name_first)
            setLastName(intakeModalData.patient_name_last)
            setPhone(formatPhoneNumber5(intakeModalData.phone))
            setEmail(intakeModalData.email)
        }

        const handleClickOutside = (event) => {
            if (specialtyDropdownRef.current && !specialtyDropdownRef.current.contains(event.target)) {
                setSpecialtyDropdownOpen(false);
            }
        }
        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const submitIntake = () => {
        setIsSubmitting(true)
        if(
            !firstName || firstName.length === 0 ||
            !lastName || lastName.length === 0 ||
            !phone || phone.length === 0 ||
            !email || email.length === 0
            
          ) {
            setShowValidationError(true)
            setIsSubmitting(false)
          } else {
            const formatAppointmentID = intakeModalData ? intakeModalData.appointment_id : ""
            const intakeRequest = {
                "hospital_id": currentHospitalData.external_id,
                "patient_name_first": firstName,
                "patient_name_middle": "",
                "patient_name_last": lastName,
                "appointment_id": formatAppointmentID,
                "phone": formatPhoneNumber2(phone),
                "email": email,
                "is_insurance_required": isInsuranceRequired,
                "is_id_required": isIDRequired,
                "didUploadInsurance": false,
                "didUploadID": false,
                "status": "pending"
            }
            postNewIntake(intakeRequest).then(result => {
                const tempAppointmentIntakeDictionary = {...appointmentIntakeDictionary}
                tempAppointmentIntakeDictionary[result.data.appointment_id] = result.data
                console.log(tempAppointmentIntakeDictionary, result)
                setAppointmentIntakeDictionary(tempAppointmentIntakeDictionary)
                onRequestClose()
                toggleAlertWithMessage("Successfully sent document request.")
            }).catch(err => {
                setShowValidationError(true)
                console.log(err)
            })
          }
    }

    const handlePhoneChange = (e) => {
        // Remove non-numeric characters and limit to 10 digits
        const cleanedInput = e.target.value.replace(/\D/g, "").substring(0, 10);

        // Format the phone number
        let formattedPhoneNumber = "";

        if (cleanedInput.length >= 1) {
            formattedPhoneNumber = `(${cleanedInput.substring(0, 3)}`;

            if (cleanedInput.length >= 4) {
                formattedPhoneNumber += `) ${cleanedInput.substring(3, 6)}`;

                if (cleanedInput.length >= 7) {
                    formattedPhoneNumber += `-${cleanedInput.substring(6, 10)}`;
                }
            }
        }

        setPhone(formattedPhoneNumber);
    };
    
    return (
        <div className="confirmation-modal-overlay">
            <div className="confirmation-modal" style={{width:"400px"}}>
                <h2 className="confirmation-modal-title">Request patient documents</h2>
                <p className="confirmation-modal-description">Achew will text and email your patient a link to upload their insurance and ID card.</p>
                <h3 className="booking-email-label">Patient information</h3>
                <span className="d-flex flex-col justify-content-between" style={{marginBottom:"10px"}}>
                
                    <div className="email-input-wrapper">
                        <input
                            className="name-input"
                            name="first"
                            placeholder="First name"
                            value={firstName}
                            onChange={(c) => setFirstName(c.currentTarget.value)}
                        />
                    </div>

                    <div className="email-input-wrapper">
                        <input
                            className="name-input2"
                            name="last"
                            placeholder="Last name"
                            value={lastName}
                            onChange={(c) => setLastName(c.currentTarget.value)}
                        />
                    </div>
                </span>
                <input
                    className="email-input"
                    name="first"
                    placeholder="Phone number"
                    value={phone}
                    onChange={handlePhoneChange}
                    style={{marginBottom:"10px"}}
                />
                <input
                    className="email-input"
                    name="first"
                    placeholder="Email address"
                    value={email}
                    onChange={(c) => setEmail(c.currentTarget.value)}
                />
                <h3 className="booking-email-label">Required documents</h3>
                <div className='intake-modal-requested'>
                    <h3>Insurance Card</h3>
                    <input
                        type='checkbox'
                        checked={isInsuranceRequired}
                        onChange={() => setInsuranceRequired(!isInsuranceRequired)}
                    />
                </div>
                <div className='intake-modal-requested'>
                    <h3>Photo ID Card</h3>
                    <input
                        type='checkbox'
                        checked={isIDRequired}
                        onChange={() => setIDRequired(!isIDRequired)}
                    />
                </div>

              {showValidationError && <h2 className='achew-validation-message-text'>* Please ensure all fields are filled.</h2>}
                <div className="confirmation-modal-buttons">
                    <button className="confirmation-modal-button cancel" onClick={() => onRequestClose()}>
                        Cancel
                    </button>
                    <button className="confirmation-modal-button accept" onClick={() => submitIntake()}>
                        {isSubmitting ? <LoadingSpinner/> : "Confirm"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateIntakeModal;