import React, {useState} from 'react';
import { black_chevron_left, black_chevron_right, double_chevron_left, double_chevron_right } from '../home/image';
import { useNavigate } from 'react-router-dom';

const Pagination = ({numPages, currentPage, setCurrentPage, navLink, refresh=false, setRefresh = ()=>{}, alternativeNav=null}) => {
    const navigate = useNavigate()

    const handlePageClicked = (pageNumber) => {
        if(numPages <= 1 || pageNumber === currentPage) {
            return
        }
        if(alternativeNav) {
            alternativeNav(pageNumber)
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
            setRefresh(!refresh)
            navigate(navLink + `&page_num=${pageNumber}`)
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
        
    }
    const renderPage = (pageNumber) => {
        return(
            <button onClick={() => handlePageClicked(pageNumber)} className={pageNumber === currentPage ? 'achew-pagination-selected' : ''}>
                {pageNumber}
            </button>
        )
    }
    
    const renderPages = () => {
        const distanceFromLastPage = numPages - currentPage
        if(distanceFromLastPage === 1){
            if(numPages === 2){
                return(
                    <div className="d-flex align-items-center">
                        {renderPage(currentPage)}
                        {renderPage(currentPage + 1)}
                    </div>
                )
            } else if(numPages > 2){
                return(
                    <div className="d-flex align-items-center">
                        {renderPage(1)}
                        <h1>...</h1>
                        {renderPage(currentPage)}
                        {renderPage(currentPage + 1)}
                    </div>
                )
            }
            
        } else if(distanceFromLastPage === 0){
            if(numPages === 1){
                return(
                    <div className="d-flex align-items-center">
                        {renderPage(currentPage)}
                    </div>
                )
            } else if (numPages === 2){
                return(
                    <div className="d-flex align-items-center">
                        {renderPage(currentPage - 1)}
                        {renderPage(currentPage)}
                    </div>
                )
            } else if (numPages > 2){
                return(
                    <div className="d-flex align-items-center">
                        {renderPage(1)}
                        <h1>...</h1>
                        {renderPage(currentPage - 1)}
                        {renderPage(currentPage)}
                    </div>
                )
            }
            
        } else if (distanceFromLastPage > 1){
            return(
                <div className="d-flex align-items-center">
                    {renderPage(currentPage)}
                    {renderPage(currentPage + 1)}
                    <h1>...</h1>
                    {renderPage(numPages)}
                </div>
            )
        }
    }

  return (
    <div className="achew-pagination-container">
      <button onClick={() => handlePageClicked(1)}><img alt="double left" src={double_chevron_left}/></button>
      <button onClick={() => handlePageClicked(Math.max(currentPage - 1, 1))}><img alt="single left" src={black_chevron_left}/></button>

        {renderPages()}

      <button onClick={() => handlePageClicked(Math.min(numPages, currentPage + 1))}><img alt="single right" src={black_chevron_right}/></button>
      <button onClick={() => handlePageClicked(numPages)}><img alt="double right" src={double_chevron_right}/></button>
    </div>
  );
};

export default Pagination;