import React, { useState, useEffect, useRef } from "react";
import CircularSlider from "../../register/registerv2/circular-slider";

const PricingModal = ({
    isPricingModalOpen,
    setPricingModalOpen,
}) => {

    const [sliderPrice, setSliderPrice] = useState(0)

    const handleOverlayClicked = (e) => {
        e.stopPropagation()
        setPricingModalOpen(false)
        setSliderPrice(0)
    }

    if (!isPricingModalOpen) {
        return null;
    }
    return (
        <div className="create-appointment-modal-overlay" onClick={handleOverlayClicked}>
            <form
                className="booking-basic-info-section-fourth"
                onClick={(e) => e.stopPropagation()}
            >
                <h1 className="booking-intro-label">Pricing</h1>
                <p className="booking-intro-labelp">
                    Use the tool below to estimate your monthly fee based the number of appointments you service with Achew.
                </p>
                <h2><p>$</p>{sliderPrice}</h2>
                <h3>Number of patients per month</h3>
                <CircularSlider sliderPrice={sliderPrice} setSliderPrice={setSliderPrice} />
                <h4>Below is a breakdown of all our rates.</h4>
                <div className="booking-rate-container">
                    <div className="booking-rate-row">
                        <h5>Clinic Profile</h5>
                        <h5>Free</h5>
                    </div>
                    <div className="booking-rate-row">
                        <h5>Appointment Booking (SMS verification/reminders included)</h5>
                        <h5>1.00$</h5>
                    </div>
                    <div className="booking-rate-row">
                        <h5>Document Intake</h5>
                        <h5>0.50$</h5>
                    </div>
                </div>


            </form>
        </div>

    );
};

export default PricingModal;