import React, { useState, useRef,useEffect } from 'react';
import AchewHeader from '../patients/bookingv2/booking-header';
import { achew_logo_side, appointment_screenshot, arrow_top_right_white, calendar_art, calendar_screenshot, connect_art, form_art, intake_screenshot, landing_art, landing_art2, mesh_arrow_left, mesh_arrow_right, profile_screenshot, settings_screenshot, summary_screenshot, welcome_main } from '../home/image';
import AchewFooter from '../achew-footer';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useMediaQuery } from 'react-responsive';
const DoctorLanding = () => {

    const navigate = useNavigate()
    const featureRef = useRef(null)
    const pricingRef = useRef(null)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })

    useEffect(() => {
        AOS.init({
          duration: 700, // Animation duration
          easing: 'ease-in-out', // Easing options
          once: true, // Whether animation should happen only once - while scrolling down
          // Other configuration options...
        });
      }, []);

    const renderSolutionCard = (image, title, description, price) => {
        return(
            <div className="doctor-landing-solution-card">
                <div className="doctor-landing-free-banner">
                    {price}
                </div>
                
                <img alt="Arrow" src={image}/>
                <div>
                    <h3>{title}</h3>
                    <h4>{description}</h4>
                </div>
                
            </div>
        )
    }

    const renderDisplay = (image, title, message, order, price) => {
        if(isTabletOrMobile) {
            return renderDisplayBoxMobile(image, title, message, order, price)
        } else {
            return renderDisplayBox(image, title, message, order, price)
        }
    }

    const renderDisplayBox = (image, title, message, order, price) => {
        return(
            <div className='doctor-landing-display-box' style={{flexDirection: order}} data-aos="fade-up">
                <div className='doctor-landing-display-box-txt'>
                    <h1>{title}</h1>
                    <h2>{message}</h2>
                    {price && price !== "" && <h3>Pricing: {price}</h3>}
                </div>
                <div className='doctor-landing-display-box-img'>
                    <img alt='promotion' src={image}/>
                </div>
                
            </div>
        )
    }

    const renderDisplayBoxMobile = (image, title, message, order, price) => {
        return(
            <div className='doctor-landing-display-box-mobile' data-aos="fade-up">
                <div className='doctor-landing-display-box-img-mobile'>
                    <img alt='promotion' src={image}/>
                </div>
                <div className='doctor-landing-display-box-txt-mobile'>
                    <h1>{title}</h1>
                    <h2>{message}</h2>
                    {price && price !== "" && <h3>Pricing: {price}</h3>}
                </div> 
            </div>
        )
    }

    const renderFeature = (message, time = "") => {
        return (
            <div className='doctor-landing-feature-container' data-aos="fade-up">
                <div className='d-flex align-items-center'>
                    <i className='fas fa-check'></i>
                    <h3>{message}</h3>
                </div>
                <h4>{time}</h4>
            </div>
        )
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };    
      const scrollToRef = (ref) => {
        if (ref && ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    return(
        <div className="doctor-landing-container">
            {
                isTabletOrMobile ?
                <>
                    <div className="achew-header-container achew-header-container-welcome">
                        <img
                        alt='Achew Logo'
                        src={achew_logo_side}
                        className='achew-header-logo'
                        onClick={() => navigate("/")}
                        />
                    </div>
                </>

                :
                <>
                    <div className="achew-header-container achew-header-container-welcome">
                        <img
                        alt='Achew Logo'
                        src={achew_logo_side}
                        className='achew-header-logo'
                        onClick={() => navigate("/")}
                        />
                        <div className="achew-header-link-container">
                        <button className="achew-header-link" onClick={() => scrollToRef(featureRef)}>
                            Features
                        </button>
                        <button className="achew-header-link" onClick={() => scrollToRef(pricingRef)}>
                            Pricing
                        </button>
                        <button className="achew-header-link" onClick={() => navigate('/doctor-registrationv3')}>
                            Get Started
                        </button>
                        <button className="achew-header-link-sign-up tablet-only" onClick={() => navigate("/doctor-login")}>
                            Clinic Login
                        </button>
                        </div>
                    </div>
                </>


            }
            <div className="doctor-landing-start">
                <div className="doctor-landing-start-left" data-aos="fade-up">
                    {
                        isTabletOrMobile ? 
                        <>
                            <h2>The easiest way to grow your practice</h2>
                        </>

                        :

                        <>
                            <h1>The easiest way to</h1>
                            <h2>grow your practice</h2>
                        </>
                    }
                    
                    <h3>Achew is a cloud-based software built to help you host your practice's profile, open appointment slots, and manage your bookings. Join Achew today and connect with patients.</h3>
                    <button  onClick={() => navigate('/doctor-registrationv3')}>
                        Get started with Achew <img alt="Arrow" src={arrow_top_right_white}/>
                    </button>
                
                </div>
                {/* <div class="doctor-landing-start-right">
                    <img alt="Landing" src={landing_art}/>
                </div> */}
            </div>

            <div class="doctor-landing-main-image" data-aos="fade-up">
                <img alt="Landing" src={welcome_main}/>
            </div>

            {!isTabletOrMobile && <div class="doctor-landing-start-right " data-aos="fade-up">
                <img alt="Landing" src={landing_art}/>
            </div>}
            
            <div className='doctor-landing-solutions' ref={featureRef}>
                <h1 data-aos="fade-up">Achew Solutions</h1>
                <h2 data-aos="fade-up">Achew streamlines the most critical profile management and appointment booking workflows into one easy-to-use platform. </h2>
                {renderDisplay(summary_screenshot, "Easy to Manage", "Use our appointment manager to see patients who are requesting an appointment with your clinic. View patient information and based on that, easily approve, reschedule, or decline appointments in one comprehensive interface.", "row")}
                {renderDisplay(calendar_screenshot, "Organized and Intuitive", "We organize all your appointments into one concise calendar so you can keep up to date and informed on which patients are visiting this week. Manage your schedule by openning up appointment slots and customizing them without hassle.", "row-reverse")}
                {renderDisplay(profile_screenshot, "Tons of Customizations", "Achew provides all clinics with a free, highly customizable profile page for both your clinic and your providers. Write a description of your clinic, specify accept insurance carriers, define services that are offered, and much more!", "row")}
            </div>

            {!isTabletOrMobile && <div class="doctor-landing-start-right light" data-aos="fade-up">
                <img alt="Landing" src={landing_art2}/>
            </div>}
            
            <div className="doctor-landing-solutions" ref={pricingRef}>
                <h1 data-aos="fade-up">Your Achew Profile is Free!</h1>
                <h2 data-aos="fade-up">Hosting your profile on Achew is free. Only pay if you want to use our appointment booking system.</h2>
                <div className='doctor-landing-solutions-card-list'>
                    {renderDisplay(intake_screenshot, "Document Intake", "Save time retrieving patient documents with Achew's automated patient document intake system. Achew will automatically request for patients to upload their insurance and ID in a easy-to-use interface.", "row-reverse", "$0.50 per request")}
                    {renderDisplay(settings_screenshot, "Clinic Profile", "Hosting and customizing your clinic profile with Achew is completely free! Your profile will be visible in our search engine and on the web for patients to find, without risk.", "row", "Free")}
                    {renderDisplay(appointment_screenshot, "Online Appointment Slots", "Openning up appointment slots takes advantage of Achew's modern, intuitive booking system. We charge $3 whenever you approve an appointment that the patient has made with Achew. This premium feature is completely optional, and we provide all clinics that makes an account with us $50 free trial credit.", "row-reverse", "$1.00 per appointment")}
                    
                </div>
            </div>

            <div className="doctor-landing-solutions">
                <h1 data-aos="fade-up">Our Feature Timeline</h1>
                <h2 data-aos="fade-up">Achew is a young, fast-growing platform with many new features coming in the near future.</h2>
                <div className='doctor-landing-features-list'>
                    {renderFeature("Clinic Profile")}
                    {renderFeature("Online Appointment Booking")}
                    {renderFeature("Document Intake")}
                    {renderFeature("Appointment Manager and Calendar")}
                    {renderFeature("Patient Invoices", "Coming in Late 2024")}
                    {renderFeature("Achew Mobile (iOS / Android) Manager for Providers", "Coming in Late 2024")}
                    {renderFeature("Achew Mobile (iOS / Android) for Patients", "Coming in Late 2024")}
                    {renderFeature("Digital Medical History and Forms", "Coming in Late 2024")}
                </div>
            </div>

            {/* <div className="home-find-appointment-mesh-container">
                <img alt="mesh left" src={mesh_arrow_left} />
                <img alt="mesh right" src={mesh_arrow_right} />
            </div>
            <div className="doctor-landing-pricing">
                <h1>Achew solutions are free!</h1>
                <h2>No risk to get started.</h2>
                <div className='doctor-landing-pricing-subcontainer'>
                    <div className='doctor-landing-pricing-info'>
                        <div className='doctor-landing-pricing-info-left'>
                            <h5>Achew Solutions</h5>
                            <div className='doctor-landing-pricing-info-txt-container'>
                                <div className="d-flex flex-column">
                                    <h3>Appointment Manager</h3>
                                    <h4>Accept, reschedule, decline appointment requests in an organized interface.</h4>
                                </div>
                                <i class="fas fa-check"></i>
                            </div>
                            <div className='doctor-landing-pricing-info-txt-container'>
                                <div className="d-flex flex-column">
                                    <h3>Calendar</h3>
                                    <h4>View all your upcoming appointments and keep up to date with your busy schedule.</h4>
                                </div>
                                <i class="fas fa-check"></i>
                            </div>
                            <div className='doctor-landing-pricing-info-txt-container'>
                                <div className="d-flex flex-column">
                                    <h3>Practice Statistics</h3>
                                    <h4>View detailed statistics about your practice.</h4>
                                </div>
                                <i class="fas fa-check"></i>
                            </div>
                        </div>
                        <div className='doctor-landing-pricing-info-right'>
                        <h5>Achew Marketplace</h5>
                        <div className='doctor-landing-pricing-info-txt-container'>
                                <div className="d-flex flex-column">
                                    <h3>Online Patient Booking</h3>
                                    <h4>Patients find timeslots and book their next appointment with your practice.</h4>
                                </div>
                                <i class="fas fa-check"></i>
                            </div>
                            <div className='doctor-landing-pricing-info-txt-container'>
                                <div className="d-flex flex-column">
                                    <h3>Clinic Profiles</h3>
                                    <h4>Increase your digital presence and improve search engine optimization.</h4>
                                </div>
                                <i class="fas fa-check"></i>
                            </div>
                            <div className='doctor-landing-pricing-info-txt-container'>
                                <div className="d-flex flex-column">
                                    <h3>Achew Search Engine</h3>
                                    <h4>Patients search from a variety of options such as specialization, insurance, and location.</h4>
                                </div>
                                <i class="fas fa-check"></i>
                            </div>
                        </div>
                    </div>
                    <button onClick={() => navigate('/doctor-registrationv3')}>
                        Get Started <img alt="Arrow" src={arrow_top_right_white}/>
                    </button>
                </div>
            </div> */}

            {/* <div className='doctor-landing-marketing'>
                <h1>Why you'll love Achew</h1>
                <h2>A glimpse at Achew's solutions.</h2>
                <div className="doctor-landing-info-row">
                    <div className="doctor-landing-info-row-txt">
                        <h3>Get the right patients for your practice</h3>
                        <h4>Sed faucibus faucibus enim. Pellentesque semper dignissim tortor, vitae pharetra eros varius in. Suspendisse a posuere ipsum, id pretium sem.</h4>
                    </div>
                    <img alt="" src=""/>
                </div>
                <div className="doctor-landing-info-row">
                    <div className="doctor-landing-info-row-txt">
                        <h3>Get the right patients for your practice</h3>
                        <h4>Sed faucibus faucibus enim. Pellentesque semper dignissim tortor, vitae pharetra eros varius in. Suspendisse a posuere ipsum, id pretium sem.</h4>
                    </div>
                    <img alt="" src=""/>
                </div>
                <div className="doctor-landing-info-row">
                    <div className="doctor-landing-info-row-txt">
                        <h3>Get the right patients for your practice</h3>
                        <h4>Sed faucibus faucibus enim. Pellentesque semper dignissim tortor, vitae pharetra eros varius in. Suspendisse a posuere ipsum, id pretium sem.</h4>
                    </div>
                    <img alt="" src=""/>
                </div>
                <div className='doctor-landing-contact'>
                    <div className='doctor-landing-contact-txt'>
                        <h3>Want to speak to us?</h3>
                        <button onClick={() => navigate("/support/contact")}>Contact us  <img alt="Arrow" src={arrow_top_right_white}/></button>
                    </div>
                    <img alt="Landing2" src={landing_art2}/>
                </div>
            </div> */}
            <div className="doctor-landing-copyright-container">
                <h2>© 2024 Achew Inc. All Rights Reserved.</h2>
                <div className="d-flex align-items-center">
                <h1 onClick={() => {navigate("/support/terms"); scrollToTop();}}>Terms & Conditions</h1>
                <h1 onClick={() => {navigate("/support/private-policy"); scrollToTop();}}>Privacy Policy</h1>
                </div>

            </div>
        </div>
    )
}

export default DoctorLanding