import React, { useState, useEffect } from 'react';
import { achew_logo_main, achew_logo_side } from './home/image';
import { useNavigate } from 'react-router-dom';
const AchewHeader = ({backgroundColor, useSignOut}) => {
  const navigate = useNavigate()

  useEffect(() => {

  }, );

  const handleSignOutPatientAppointment = () => {
    localStorage.removeItem("fartyAuth")
    navigate("/appointment-search")
  }

  return (
    <div className="achew-header-container" style={{backgroundColor: `${backgroundColor}`}}>
        <img
          alt='Achew Logo'
          src={achew_logo_side}
          className='achew-header-logo'
          onClick={() => navigate("/")}
        />
        <div className="achew-header-link-container">
          <button className="achew-header-link desktop-only" onClick={() => navigate("/appointment-search")}>
            Manage your Appointment
          </button>
          <button className="achew-header-link desktop-only" onClick={() => navigate("/support/contact")}>
            Help
          </button>
          <button className="achew-header-link desktop-only" onClick={() => navigate("/welcome")}>
            List your practice on Achew
          </button>
          {useSignOut ? 
            <button className="achew-header-link-sign-up" onClick={handleSignOutPatientAppointment}>
              Sign out
            </button>
          :
            <button className="achew-header-link-sign-up tablet-only" onClick={() => navigate("/doctor-login")}>
              Clinic Login
            </button>
          }
        </div>
    </div>
  );
};

export default AchewHeader;