import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice'
import accountSlice from './accountSlice'
import onboardSlice from './onboardSlice'
import hospitalSlice from './hospitalSlice'
import appointmentSlice from './appointmentSlice'
export default configureStore({
  reducer: combineReducers({
    auth: authSlice,
    account: accountSlice,
    onboard: onboardSlice,
    hospital: hospitalSlice,
    appointment: appointmentSlice, 
  })
})