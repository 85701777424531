import React from 'react';
import {createRoot} from 'react-dom/client';
import AppRouter from './AppRouter.jsx';
import reportWebVitals from './reportWebVitals';
import Home from "./client/components/home/home.jsx"
import './client/assets/css/bootstrap.min.css'
// boostrap
import 'bootstrap/dist/css/bootstrap.min.css';
//fontawesome

import "react-datepicker/dist/react-datepicker.css";
//carousel
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
root.render(<AppRouter/>)

if(!window.location.pathname.includes("admin") && !window.location.pathname.includes("pharmacyadmin")) {
    require('./client/assets/css/all.css')
    require('./client/assets/css/all.min.css')
    require('./client/assets/css/fontawesome.min.css')
    require('./client/assets/css/theme.css')
    require('./client/assets/css/style.css')
    require('./client/assets/js/popper.min.js')
   
}

// enables hot module replacement if plugin is installed
if (module.hot) {
  module.hot.accept();
 }



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
