import React, { useState, useEffect, useRef } from "react";
import { chevron_down, sample_doctor } from "../../home/image";
import Calendar from "./prototype-calendar/calendar";
import AchewHeader from "../../achew-header";
import MiniCalendar from "../../patients/doctorprofile/mini-calendar/datepicker";
import {
  formatDateMMDDYYYY,
  monthDictionary,
} from "./prototype-calendar/helper-functions";
import {
  convertTimeToInt,
  formatDateToLongString,
  getClinicProfilePicture,
  getDoctorProfilePicture,
  getMidnightUTCTimestamp,
  halfHourTimeIntervals,
} from "../../../assets/functions/functions";
import LoadingSpinner from "../../patients/bookingv2/spinner";
import { RegisterSchedule } from "../../../../api/patientAPIs";
const CreateSchedulePopup = ({
  doctorList,
  setCreateScheduleOpen,
  currentHospitalData,
  toggleAlertWithMessage,
  scheduleList,
  setScheduleList,
}) => {
  const frequencyDropdownRef = useRef(null);
  const timeDropdownRef = useRef(null);
  const today = new Date();
  const [displayedDate, setDisplayedDate] = useState(today);
  const [isFrequencyDropdownOpen, setFrequencyDropdownOpen] = useState(false);
  const startDateDropdownRef = useRef(null);
  const [isStartDateDropdownOpen, setStartDateDropdownOpen] = useState(false);
  const dayDropdownRef = useRef(null);
  const [isDayDropdownOpen, setDayDropdownOpen] = useState(false);
  const endDateDropdownRef = useRef(null);
  const [isEndDateDropdownOpen, setEndDateDropdownOpen] = useState(false);
  const [name, setName] = useState(null);
  const [startDate, setStartDate] = useState(displayedDate);
  const [endDate, setEndDate] = useState(displayedDate);
  const [frequency, setFrequency] = useState(null);
  const [timeSlots, setTimeSlots] = useState("");
  const [timeSlotsList, setTimeSlotsList] = useState([]);
  const [ampm, setAMPM] = useState("AM");
  const [dayOfWeek, setDayOfWeek] = useState(null);
  const [schedulePreviewConfig, setSchedulePreviewConfig] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(null);
  const [assignedDoctors, setAssignedDoctors] = useState([]);
  const [duration, setDuration] = useState(null);
  const [isAssigningSchedule, setIsAssigningSchedule] = useState(false);
  const [isTimeOpen, setIsTimeOpen] = useState(false);

  // Which days are open
  const [daysSchedule, setDaysSchedule] = useState({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  });

  const frequencyList = ["Once", "Daily", "Weekly"];
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const frequencyDictionary = {
    Once: "O",
    Daily: "D",
    Weekly: "W",
  };
  useEffect(() => {
    const handleClickStartOutside = (event) => {
      if (
        startDateDropdownRef.current &&
        !startDateDropdownRef.current.contains(event.target)
      ) {
        setStartDateDropdownOpen(false);
      }
    };
    const handleClickEndOutside = (event) => {
      if (
        endDateDropdownRef.current &&
        !endDateDropdownRef.current.contains(event.target)
      ) {
        setEndDateDropdownOpen(false);
      }
    };
    const handleClickTimeOutside = (event) => {
      if (
        timeDropdownRef.current &&
        !timeDropdownRef.current.contains(event.target)
      ) {
        setIsTimeOpen(false);
      }
    };
    // Attach the event listener
    window.addEventListener("click", handleClickEndOutside);
    window.addEventListener("click", handleClickStartOutside);
    window.addEventListener("click", handleClickTimeOutside);

    return () => {
      window.removeEventListener("click", handleClickEndOutside);
      window.removeEventListener("click", handleClickStartOutside);
      window.removeEventListener("click", handleClickTimeOutside);
    };
  });

  useEffect(() => {
    updateAppointmentDictionary();
  }, [startDate, endDate, daysSchedule, frequency, dayOfWeek]);

  const toggleDay = (day) => {
    setDaysSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: !prevSchedule[day],
    }));
  };

  const increaseMonthCalendar = () => {
    const newDate = new Date(displayedDate.getTime());
  const originalDay = newDate.getDate();

  // Set the date to the first of the month to prevent overflow
  newDate.setDate(1);

  // Increment the month by 1
  newDate.setMonth(newDate.getMonth() + 1);

  // Set the date to the original day or the last day of the new month if the original day is too large
  const daysInNewMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
  newDate.setDate(Math.min(originalDay, daysInNewMonth));

  setDisplayedDate(newDate);
  };

  const decreaseMonthCalendar = () => {
    const newDate = new Date(displayedDate.getTime());
    newDate.setMonth(newDate.getMonth() - 1);

    setDisplayedDate(newDate);
  };

  const increaseMonth = () => {
    const newDate = new Date(startDate.getTime());
    newDate.setMonth(newDate.getMonth() + 1);

    setStartDate(newDate);
  };

  const decreaseMonth = () => {
    const newDate = new Date(startDate.getTime());
    newDate.setMonth(newDate.getMonth() - 1);

    setStartDate(newDate);
  };

  const increaseMonthEnd = () => {
    const newDate = new Date(endDate.getTime());
    newDate.setMonth(newDate.getMonth() + 1);

    setEndDate(newDate);
  };

  const decreaseMonthEnd = () => {
    const newDate = new Date(endDate.getTime());
    newDate.setMonth(newDate.getMonth() - 1);

    setEndDate(newDate);
  };

  const handleDoctorChecked = (did) => {
    const tempAssignedDoctors = [...assignedDoctors];
    const index = tempAssignedDoctors.indexOf(did);

    if (index !== -1) {
      // Element found, remove it
      tempAssignedDoctors.splice(index, 1);
    } else {
      // Element not found, add it
      tempAssignedDoctors.push(did);
    }
    setAssignedDoctors(tempAssignedDoctors);
  };

  const validateSchedule = () => {
    if (
      !name ||
      name === "" ||
      !duration ||
      duration === undefined ||
      duration === "" ||
      !frequency ||
      frequency === undefined ||
      frequency === "" ||
      !startDate ||
      !endDate ||
      (frequency !== "Once" && startDate.getTime() > endDate.getTime()) ||
      (frequency !== "Once" &&
        (!timeSlotsList || timeSlotsList.length === 0)) ||
      (frequency === "Weekly" && (dayOfWeek == null || dayOfWeek === ""))
    ) {
      return false;
    }

    return true;
  };

  const submitSchedule = () => {
    if (!validateSchedule()) {
      toggleAlertWithMessage(
        "Error creating schedule: Please check each field carefully"
      );
      return;
    }

    setIsAssigningSchedule(true);

    const midnightStartDate = getMidnightUTCTimestamp(startDate) / 1000;
    const midnightEndDate =
      frequency === "Once"
        ? getMidnightUTCTimestamp(startDate) / 1000 + 86400
        : getMidnightUTCTimestamp(endDate) / 1000;
    const durationInSeconds = parseInt(duration) * 60;
    var freqInternval = 7;
    if(frequency === "Daily") {
      if(!daysSchedule["monday"]) { freqInternval += "0" }
      if(!daysSchedule["tuesday"]) { freqInternval += "1" }
      if(!daysSchedule["wednesday"]) { freqInternval += "2" }
      if(!daysSchedule["thursday"]) { freqInternval += "3" }
      if(!daysSchedule["friday"]) { freqInternval += "4" }
      if(!daysSchedule["saturday"]) { freqInternval += "5" }
      if(!daysSchedule["sunday"]) { freqInternval += "6" }
    } else if (frequency === "Weekly") {
      freqInternval = dayOfWeek ? dayOfWeek : 0
    } else if (freqInternval === "Once"){
      freqInternval = 0
    }

    var schedulePromises = [];
    assignedDoctors.forEach((doc) => {
      const formattedTimeSlotsList = timeSlotsList.map((time) => convertTimeToInt(time));
      const scheduleData = {
        d_id: doc,
        schdeulde_name: name,
        is_enabled: true,
        start_date: midnightStartDate,
        end_date: midnightEndDate,
        appointment_type: "General",
        frequency_type: frequencyDictionary[frequency],
        timeslots: formattedTimeSlotsList,
        frequency_interval: freqInternval,
        duration: durationInSeconds,
        owner: currentHospitalData.id,
      };
      console.log(scheduleData);
      schedulePromises.push(RegisterSchedule(scheduleData));
    });
    Promise.all(schedulePromises)
      .then((scheduleResults) => {
        const tempScheduleList = [...scheduleList];
        scheduleResults.forEach((result) => {
          tempScheduleList.push(result.data);
        });
        setScheduleList(tempScheduleList);
        setCreateScheduleOpen(false);
        toggleAlertWithMessage("Successfully created new schedule");
        setIsAssigningSchedule(false);
      })
      .catch((err) => {
        setConfirmationModalOpen(false);
        setCreateScheduleOpen(false);
        toggleAlertWithMessage("Error occurred while creating new schedule");
        setIsAssigningSchedule(false);
      });
  };

  const updateAppointmentDictionary = (ts) => {
    const tempTimeSlots = ts ? ts : timeSlotsList;
    var tempConfig = null;
    if (frequency === "Once" && timeSlots != null) {
      tempConfig = {
        frequency: "Once",
        time: timeSlots,
        date: startDate,
      };
    } else if (
      frequency === "Daily" &&
      timeSlots != null &&
      tempTimeSlots.length > 0
    ) {
      tempConfig = {
        frequency: "Daily",
        time: tempTimeSlots,
        startDate: startDate,
        endDate: endDate,
        daysOfWeeks: daysSchedule,
      };
      console.log(tempConfig);
    } else if (
      frequency === "Weekly" &&
      timeSlots != null &&
      tempTimeSlots.length > 0 &&
      dayOfWeek != null
    ) {
      tempConfig = {
        frequency: "Weekly",
        time: tempTimeSlots,
        day: dayOfWeek,
        startDate: startDate,
        endDate: endDate,
      };
    }
    setSchedulePreviewConfig(tempConfig);
  };

  const updateAppointmentDictionaryWithCustomTimeSlot = (ts, timeSlots) => {
    const tempTimeSlots = ts ? ts : timeSlotsList;
    var tempConfig = null;
    if (frequency === "Once" && timeSlots != null) {
      tempConfig = {
        frequency: "Once",
        time: timeSlots,
        date: startDate,
      };
    } else if (
      frequency === "Daily" &&
      timeSlots != null &&
      tempTimeSlots.length > 0
    ) {
      tempConfig = {
        frequency: "Daily",
        time: tempTimeSlots,
        startDate: startDate,
        endDate: endDate,
        daysOfWeeks: daysSchedule,
      };
      console.log(tempConfig);
    } else if (
      frequency === "Weekly" &&
      timeSlots != null &&
      tempTimeSlots.length > 0 &&
      dayOfWeek != null
    ) {
      tempConfig = {
        frequency: "Weekly",
        time: tempTimeSlots,
        day: dayOfWeek,
      };
    }
    setSchedulePreviewConfig(tempConfig);
  };

  const handleTimeSlotList = (selectedTimeSlot) => {

    if(frequency === "Once") {
        const tempTimeSlotList = [selectedTimeSlot]
        setTimeSlotsList(tempTimeSlotList);
        updateAppointmentDictionary(tempTimeSlotList);
        setIsTimeOpen(false)
        return
    }

    if (timeSlotsList.includes(selectedTimeSlot)) {
      handleDeleteTimeSlot(selectedTimeSlot);
    } else {
      handleAddTimeSlots(selectedTimeSlot);
    }
  };

  const handleAddTimeSlots = (selectedTimeSlot) => {
    const tempTimeSlots = [...timeSlotsList];
    tempTimeSlots.push(selectedTimeSlot);
    setTimeSlotsList(tempTimeSlots);
    updateAppointmentDictionary(tempTimeSlots);
  };

  const handleDeleteTimeSlot = (time) => {
    const tempTimeSlots = [...timeSlotsList];
    const index = tempTimeSlots.indexOf(time);

    if (index !== -1) {
      tempTimeSlots.splice(index, 1);
    }

    setTimeSlotsList(tempTimeSlots);
    updateAppointmentDictionary(tempTimeSlots);
  };

  const handleDurationChanged = (event) => {
    // Remove non-numeric characters
    const newValue = event.target.value.replace(/[^0-9]/g, "");
    setDuration(newValue);
  };
  const renderTimeSlot = (time) => {
    return (
      <div className="create-schedule-popup-time-slot">
        <h1>{time}</h1>
      </div>
    );
  };

  const renderTimeSlotDropdownItem = (selectedTimeSlot) => {
    const isSlotSelected = timeSlotsList.includes(selectedTimeSlot);
    return (
      <div
        className={`confirmation-timeslot withCheck ${
          isSlotSelected && "selected-confirmation-slot"
        }`}
        onClick={() => {
          handleTimeSlotList(selectedTimeSlot);
        }}
      >
        <h3>{selectedTimeSlot}</h3>
        {isSlotSelected && <i className="fa fa-check"></i>}
      </div>
    );
  };

  const renderEditorOnce = () => {
    return (
      <>
        <span
          className="d-flex justify-content-between"
          style={{ marginTop: "40px" }}
        >
          <div className="email-input-wrapper">
            <h3>Time Slots</h3>
            <h4>Add time slots to this schedule.</h4>

            <div
              ref={timeDropdownRef}
              className="position-relative booker-reason-input-container create-schedule-popup-padding"
            >
              <div
                className="confirmation-modal-date-dropdown"
                onClick={() => setIsTimeOpen(!isTimeOpen)}
              >
                <i className="far fa-clock"></i>
                <div className="d-flex flex-column">
                  <h4>Time</h4>
                  <h5>
                    {timeSlotsList && timeSlotsList.length === 1
                      ? timeSlotsList[0]
                      : "Choose time slot"}
                  </h5>
                </div>
              </div>
              {isTimeOpen && (
                <div className="confirmation-time-dropdown-menu ">
                  {halfHourTimeIntervals.map((elem) =>
                    renderTimeSlotDropdownItem(elem)
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="email-input-wrapper">
            <h3>Start Date</h3>
            <h4>
              Choose a date that your appointment slots series will start on.
            </h4>
            <div
              ref={startDateDropdownRef}
              className="booker-reason-input-container create-schedule-popup-padding"
              style={{ marginTop: "-10px" }}
            >
              <div
                className="confirmation-modal-date-dropdown"
                onClick={() =>
                  setStartDateDropdownOpen(!isStartDateDropdownOpen)
                }
              >
                <i className="far fa-calendar"></i>
                <div className="d-flex flex-column">
                  <h4>Date</h4>
                  <h5>
                    {formatDateToLongString(startDate) != null
                      ? formatDateToLongString(startDate)
                      : "Choose the slot date."}
                  </h5>
                </div>
              </div>
              {isStartDateDropdownOpen && (
                <ul className="create-schedule-dropdown-menu">
                  <MiniCalendar
                    useExactDate={true}
                    displayedDate={startDate}
                    setDisplayedDate={setStartDate}
                    increaseMonth={increaseMonth}
                    decreaseMonth={decreaseMonth}
                    setPopupOpen={() => setStartDateDropdownOpen(false)}
                  />
                </ul>
              )}
            </div>
          </div>
        </span>
        <button
          className="pe-left-save-btn"
          onClick={() => setConfirmationModalOpen(true)}
        >
          {isSaving ? <LoadingSpinner /> : "Continue"}
        </button>
      </>
    );
  };

  const renderEditorDaily = () => {
    return (
      <>
        <h3>Time Slots</h3>
        <h4>Add time slots to this schedule.</h4>

        <div ref={timeDropdownRef} className="position-relative">
          <div
            className="confirmation-modal-date-dropdown"
            onClick={() => setIsTimeOpen(!isTimeOpen)}
          >
            <i className="far fa-clock"></i>
            <div className="d-flex flex-column">
              <h4>Time</h4>
              <h5>
                {timeSlotsList && timeSlotsList.length > 0
                  ? `(${timeSlotsList.length} time slots)`
                  : "Choose time slots"}
              </h5>
            </div>
          </div>
          {isTimeOpen && (
            <div className="confirmation-time-dropdown-menu ">
              {halfHourTimeIntervals.map((elem) =>
                renderTimeSlotDropdownItem(elem)
              )}
            </div>
          )}
        </div>

        {timeSlotsList.length > 0 && (
          <div className="create-schedule-popup-time-slots-list">
            {timeSlotsList.map((time) => renderTimeSlot(time))}
          </div>
        )}

        <span
          className="d-flex flex-col justify-content-between"
          style={{ marginTop: "40px" }}
        >
          <div className="email-input-wrapper">
            <h3>Start Date</h3>
            <h4>
              Choose a date that your appointment slots series will start on.
            </h4>
            <div
              ref={startDateDropdownRef}
              className="booker-reason-input-container create-schedule-popup-padding"
              style={{ marginTop: "-10px" }}
            >
              <div
                className="confirmation-modal-date-dropdown"
                onClick={() =>
                  setStartDateDropdownOpen(!isStartDateDropdownOpen)
                }
              >
                <i className="far fa-calendar"></i>
                <div className="d-flex flex-column">
                  <h4>Date</h4>
                  <h5>
                    {formatDateToLongString(startDate) != null
                      ? formatDateToLongString(startDate)
                      : "Choose how frequently your slots are open"}
                  </h5>
                </div>
              </div>
              {isStartDateDropdownOpen && (
                <ul className="create-schedule-dropdown-menu">
                  <MiniCalendar
                    useExactDate={true}
                    displayedDate={startDate}
                    setDisplayedDate={setStartDate}
                    increaseMonth={increaseMonth}
                    decreaseMonth={decreaseMonth}
                    setPopupOpen={() => setStartDateDropdownOpen(false)}
                  />
                </ul>
              )}
            </div>
          </div>
          <div className="email-input-wrapper">
            <h3>End Date</h3>
            <h4>
              Choose a date that your appointment slots series will end on.
            </h4>
            <div
              className="booker-reason-input-container create-schedule-popup-padding"
              ref={endDateDropdownRef}
              style={{ marginTop: "-10px" }}
            >
              <div
                className="confirmation-modal-date-dropdown"
                onClick={() => setEndDateDropdownOpen(!isEndDateDropdownOpen)}
              >
                <i className="far fa-calendar"></i>
                <div className="d-flex flex-column">
                  <h4>Date</h4>
                  <h5>
                    {formatDateToLongString(endDate) != null
                      ? formatDateToLongString(endDate)
                      : "Choose how frequently your slots are open"}
                  </h5>
                </div>
              </div>
              {isEndDateDropdownOpen && (
                <ul className="create-schedule-dropdown-menu">
                  <MiniCalendar
                    useExactDate={true}
                    displayedDate={endDate}
                    setDisplayedDate={setEndDate}
                    increaseMonth={increaseMonth}
                    decreaseMonth={decreaseMonth}
                    setPopupOpen={() => setEndDateDropdownOpen(false)}
                  />
                </ul>
              )}
            </div>
          </div>
        </span>

        <h3 style={{ marginTop: "30px" }}>Days of Week</h3>
        <h4>Exclude days when your clinic is closed.</h4>
        <div className="create-schedule-popup-daysofweek-list">
          {daysOfWeek.map((day) => (
            <div key={day} className="create-schedule-popup-daysofweek">
              <input
                type="checkbox"
                checked={daysSchedule[day.toLowerCase()]}
                onChange={() => toggleDay(day.toLowerCase())}
              />
              <h4>{day}</h4>
            </div>
          ))}
        </div>

        <button
          className="pe-left-save-btn"
          onClick={() => setConfirmationModalOpen(true)}
        >
          {isSaving ? <LoadingSpinner /> : "Continue"}
        </button>
      </>
    );
  };

  const renderEditorWeekly = () => {
    return (
      <>
        <h3>Day of Week</h3>
        <h4>Add time slots to this schedule.</h4>
        <div
          className="booker-reason-input-container create-schedule-popup-padding"
          ref={dayDropdownRef}
          style={{ marginTop: "-10px" }}
        >
          <div
            className="booker-body-dropdown"
            onClick={() => setDayDropdownOpen(!isDayDropdownOpen)}
          >
            <h5>
              {dayOfWeek != null
                ? daysOfWeek[dayOfWeek]
                : "Choose how frequently your slots are open"}
            </h5>
            <img alt="chevron down" src={chevron_down} />
          </div>
          {isDayDropdownOpen && (
            <ul className="booker-dropdown-menu">
              {daysOfWeek.map((option, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setDayOfWeek(daysOfWeek.indexOf(option));
                    setDayDropdownOpen(false);
                    console.log(option);
                  }}
                >
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>

        <h3>Time Slots</h3>
        <h4>Add time slots to this schedule.</h4>

        <div ref={timeDropdownRef} className="position-relative">
          <div
            className="confirmation-modal-date-dropdown"
            onClick={() => setIsTimeOpen(!isTimeOpen)}
          >
            <i className="far fa-clock"></i>
            <div className="d-flex flex-column">
              <h4>Time</h4>
              <h5>
                {timeSlotsList && timeSlotsList.length > 0
                  ? `(${timeSlotsList.length} time slots)`
                  : "Choose time slots"}
              </h5>
            </div>
          </div>
          {isTimeOpen && (
            <div className="confirmation-time-dropdown-menu ">
              {halfHourTimeIntervals.map((elem) =>
                renderTimeSlotDropdownItem(elem)
              )}
            </div>
          )}
        </div>

        {timeSlotsList.length > 0 && (
          <div className="create-schedule-popup-time-slots-list">
            {timeSlotsList.map((time) => renderTimeSlot(time))}
          </div>
        )}

        <span
          className="d-flex flex-col justify-content-between"
          style={{ marginTop: "40px" }}
        >
          <div className="email-input-wrapper">
            <h3>Start Date</h3>
            <h4>
              Choose a date that your appointment slots series will start on.
            </h4>
            <div
              ref={startDateDropdownRef}
              className="booker-reason-input-container create-schedule-popup-padding"
              style={{ marginTop: "-10px" }}
            >
              <div
                className="confirmation-modal-date-dropdown"
                onClick={() =>
                  setStartDateDropdownOpen(!isStartDateDropdownOpen)
                }
              >
                <i className="far fa-calendar"></i>
                <div className="d-flex flex-column">
                  <h4>Date</h4>
                  <h5>
                    {formatDateToLongString(startDate) != null
                      ? formatDateToLongString(startDate)
                      : "Choose how frequently your slots are open"}
                  </h5>
                </div>
              </div>
              {isStartDateDropdownOpen && (
                <ul className="create-schedule-dropdown-menu">
                  <MiniCalendar
                    useExactDate={true}
                    displayedDate={startDate}
                    setDisplayedDate={setStartDate}
                    increaseMonth={increaseMonth}
                    decreaseMonth={decreaseMonth}
                    setPopupOpen={() => setStartDateDropdownOpen(false)}
                  />
                </ul>
              )}
            </div>
          </div>
          <div className="email-input-wrapper">
            <h3>End Date</h3>
            <h4>
              Choose a date that your appointment slots series will end on.
            </h4>
            <div
              className="booker-reason-input-container create-schedule-popup-padding"
              ref={endDateDropdownRef}
              style={{ marginTop: "-10px" }}
            >
              <div
                className="confirmation-modal-date-dropdown"
                onClick={() => setEndDateDropdownOpen(!isEndDateDropdownOpen)}
              >
                <i className="far fa-calendar"></i>
                <div className="d-flex flex-column">
                  <h4>Date</h4>
                  <h5>
                    {formatDateToLongString(endDate) != null
                      ? formatDateToLongString(endDate)
                      : "Choose how frequently your slots are open"}
                  </h5>
                </div>
              </div>
              {isEndDateDropdownOpen && (
                <ul className="create-schedule-dropdown-menu">
                  <MiniCalendar
                    useExactDate={true}
                    displayedDate={endDate}
                    setDisplayedDate={setEndDate}
                    increaseMonth={increaseMonth}
                    decreaseMonth={decreaseMonth}
                    setPopupOpen={() => setEndDateDropdownOpen(false)}
                  />
                </ul>
              )}
            </div>
          </div>
        </span>
        <button
          className="pe-left-save-btn"
          onClick={() => setConfirmationModalOpen(true)}
        >
          {isSaving ? <LoadingSpinner /> : "Continue"}
        </button>
      </>
    );
  };

  const renderDoctorRow = (doctor) => {
    return (
      <div className="create-schedule-providers-row">
        <div className="d-flex align-items-center">
          <div className="create-schedule-providers-row-img">
            <img alt="Profile Pic" src={getDoctorProfilePicture(doctor)} />
          </div>
          <h1>
            Dr. {doctor.doctor_name_first} {doctor.doctor_name_last}
          </h1>
        </div>
        <input
          type="checkbox"
          checked={assignedDoctors.indexOf(doctor.external_id) != -1}
          onChange={() => handleDoctorChecked(doctor.external_id)}
        />
      </div>
    );
  };
  const renderConfirmationModal = () => {
    return (
      <div className="confirmation-modal-overlay">
        <div className="confirmation-modal">
          <div className="confirmation-modal-title">
            Assign this Schedule to Providers
          </div>
          <div className="confirmation-modal-doctor-list">
            {doctorList.map((doctor) => renderDoctorRow(doctor))}
          </div>
          <div className="confirmation-modal-buttons">
            <button
              className="confirmation-modal-button cancel"
              onClick={() => setConfirmationModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className={assignedDoctors.length === 0 ? "confirmation-modal-button disabled" : "confirmation-modal-button accept"}
              onClick={() => submitSchedule()}
              disabled={assignedDoctors.length === 0}
            >
              {isAssigningSchedule ? <LoadingSpinner /> : "Confirm"}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="create-schedule-overlay">
      {confirmationModalOpen && renderConfirmationModal()}
      <div className="create-calendar-left">
        <button
          className="create-schedule-back-button"
          onClick={() => setCreateScheduleOpen(false)}
        >
          <i class="fas fa-arrow-left back-arrow"></i>
          <h1>Back to Calendar</h1>
        </button>
        <div className="create-schedule-popup">
          <div className="create-schedule-popup-header">
            <h1>Create Schedule</h1>
            <h2>
              To open time slots for online appointments, please fill out the
              following information.
            </h2>
          </div>
          <div className="create-schedule-popup-body">
            <h3>Name</h3>
            <h4>Give a name to identify your schedule.</h4>
            <input
              className="email-input create-schedule-popup-padding"
              name="email"
              value={name}
              placeholder="Ex. Normal Weekday Schedule"
              onChange={(e) => setName(e.currentTarget.value)}
            />
            <h3>Duration</h3>
            <h4>
              Approximately, how long will each appointment take in minutes?
            </h4>
            <input
              className="email-input create-schedule-popup-padding"
              value={duration}
              placeholder="Enter a time length in minutes"
              onChange={handleDurationChanged}
            />
            <h3>Slot Frequency</h3>
            <h4>How often should your appointment slots repeat?</h4>
            <div
              className="booker-reason-input-container create-schedule-popup-padding"
              ref={frequencyDropdownRef}
              style={{ marginTop: "-10px" }}
            >
              <div
                className="booker-body-dropdown"
                onClick={() =>
                  setFrequencyDropdownOpen(!isFrequencyDropdownOpen)
                }
              >
                <h5>{frequency != null ? frequency : "Schedule Type"}</h5>
                <img alt="chevron down" src={chevron_down} />
              </div>
              {isFrequencyDropdownOpen && (
                <ul className="booker-dropdown-menu">
                  {frequencyList.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setFrequency(option);
                        setFrequencyDropdownOpen(false);
                        setSchedulePreviewConfig(null);
                      }}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {frequency === "Once" && renderEditorOnce()}
            {frequency === "Daily" && renderEditorDaily()}
            {frequency === "Weekly" && renderEditorWeekly()}
          </div>
        </div>
      </div>

      <div className="create-schedule-calendar">
        <div className="calendar-page-header">
          <div className="d-flex align-items-center">
            <div className="d-flex flex-col align-items-center">
              <h2 className="calendar-date-txt d-flex">
                {monthDictionary[displayedDate.getMonth()]}{" "}
                {displayedDate.getFullYear()}{" "}
              </h2>
              <button
                class="calendar-chevron-button-left"
                onClick={() => decreaseMonthCalendar()}
              >
                <i class="fas fa-chevron-left"></i>
              </button>
              <button
                class="calendar-chevron-button-right"
                onClick={() => increaseMonthCalendar()}
              >
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
        <Calendar
          year={displayedDate.getFullYear()}
          month={displayedDate.getMonth()}
          setPopupOpen={null}
          appointmentDataDictionary={{}}
          setPopupModalData={null}
          schedulePreviewConfig={schedulePreviewConfig}
        />
      </div>
    </div>
  );
};

export default CreateSchedulePopup;
