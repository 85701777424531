import React, { useState, useEffect, useRef } from 'react';
import { addHospitalReviews, getHospitalInfoByExternalID, updateHospitalReview } from '../../../../api/patientAPIs';
import { green_check } from '../../home/image';
import { formatDateToLongString } from '../../../assets/functions/functions';
import LoadingSpinner from '../bookingv2/spinner';
const WriteReviewModal = ({ onRequestClose, appointmentData, hospitalData, reviewData, reviewDictionary, setReviewDictionary }) => {

    const [numStars, setNumStars] = useState(0)
    const [starHover, setStarHover] = useState(-1)
    const [reviewMessage, setReviewMessage] = useState("")
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        console.log(reviewData, appointmentData.reviewID)
        if(appointmentData.reviewID && appointmentData.reviewID !== ""){
            setNumStars(reviewData.rating)
            setReviewMessage(reviewData.content)
        }  
    }, []);

    const renderStarsWithValue = () => {
        const roundedRating = numStars
        const stars = [];

        for (let i = 0; i < roundedRating ; i++) {
            stars.push(<i key={i} className="fas fa-star write-review-modal-fillerstar" onClick={() => setNumStars(i + 1)}></i>);
        }
        for (let i = roundedRating ; i < 5; i++) {
            stars.push(<i key={i} className="fas fa-star write-review-modal-unfillerstar" onClick={() => setNumStars(i + 1)}></i>);
        }
        return <div className="profile-stars-group">{stars}</div>;
    };

    const handleSetStars = (i) => {
        if(numStars === 0) {
            setNumStars(i + 1)
        } else {
            setNumStars(i)
        }
    }

    const renderStarsWithoutValue = () => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(<i key={i} className={starHover >= i ? "fas fa-star write-review-modal-fillerstar" : "fas fa-star write-review-modal-unfillerstar"} onMouseEnter={() => setStarHover(i)} onMouseLeave={() => setStarHover(-1)} onClick={() => handleSetStars(i)}></i>);
        }
        return <div className="profile-stars-group">{stars}</div>;
    };

    const submitReview = () => {
        if(numStars) {
            setIsSubmitting(true)
            console.log(appointmentData, hospitalData.external_id)
            const reviewData = {
                "patient_info": appointmentData.patient_info,
                "hospital_id": hospitalData.external_id,
                "doctor_id": appointmentData.d_id,
                "rating": numStars,
                "likes": 0,
                "dislikes": 0,
                "content": reviewMessage,
                "date_posted": formatDateToLongString(new Date()),
                "replies": [],
                "appointment_id": appointmentData.external_id,
            }

            if(appointmentData.reviewID === ""){
                addHospitalReviews(reviewData).then(response => {
                    if(response.status === 200 || response.status === 201){
                        const tempReviewDictionary = {...reviewDictionary}
                        tempReviewDictionary[appointmentData.reviewID] = response.data
                        setReviewDictionary(tempReviewDictionary)
                        setIsSubmitting(false)
                        onRequestClose()
                    }
                })
            } else {
                updateHospitalReview(reviewData, appointmentData.reviewID).then(response => {
                    if(response.status === 200 || response.status === 201){
                        const tempReviewDictionary = {...reviewDictionary}
                        tempReviewDictionary[appointmentData.reviewID] = response.data
                        setReviewDictionary(tempReviewDictionary)
                        setIsSubmitting(false)
                        onRequestClose()
                    }
                })
            }
        } else {
            setShowErrorMessage(true)
        }
    }

    

    if (hospitalData) {
        return (
            <div className="write-review-modal-overlay">
                <div className="write-review-modal">
                    <h2 className="write-review-modal-title">Write a review for {hospitalData.hospital_name}</h2>
                    <p className="write-review-modal-description">How was your {appointmentData.patient_info.reason} at {hospitalData.hospital_name}?</p>

                    <div className="doc-profile-review">
                        <h5>Rate your visit</h5>
                        {numStars ? renderStarsWithValue() : renderStarsWithoutValue()}
                        <h5>Leave your feedback (optional)</h5>
                        <textarea
                            placeholder='Tell us about your visit!'
                            onChange={(e) => setReviewMessage(e.currentTarget.value)}
                            value={reviewMessage}
                            maxLength={1000}
                        />
                        <div className="doc-profile-review-metadata">
                            <h2>{appointmentData.patient_info.patient_name_first} {appointmentData.patient_info.patient_name_last[0]}</h2>
                            <i className="fas fa-circle"></i>
                            <h3>{formatDateToLongString(new Date())}</h3>
                            <i className="fas fa-circle"></i>
                            <img alt="" src={green_check} />
                            <h4>Verified Patient</h4>
                        </div>
                    </div>
                    {showErrorMessage && <div className='write-review-modal-error'>* Please leave a rating</div>}
                    <div className="write-review-modal-buttons">
                        <button className="write-review-modal-button cancel" onClick={() => onRequestClose()}>
                            Cancel
                        </button>
                        <button className="write-review-modal-button complete" onClick={() => submitReview()}>
                            {isSubmitting ? <LoadingSpinner/> : "Publish Review"}
                        </button>
                    </div>
                </div>
            </div>
        );
    }


};

export default WriteReviewModal;