import React ,{createContext, useState} from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from "./client/components/home/home"
import {QueryClientProvider, QueryClient} from 'react-query'
import {Provider} from 'react-redux'
import store from './hooks/store.js'
import Register from './client/components/register/register.jsx';
import Search from './client/components/search/search.jsx';

import DoctorProfile from "./client/components/patients/doctorprofile"
import PatientBookingV2_1 from './client/components/patients/bookingv2/booking-1.jsx'
import BookingSuccess from './client/components/patients/bookingv2/booking-success.jsx';

import DoctorDashboardSummary from './client/components/doctors/dashboard/summary';
import DoctorDashboardCalendar from './client/components/doctors/dashboard/calendar';
import DoctorDashboardAppointments from './client/components/doctors/dashboard/appointment-manager.jsx';
import DoctorDashBoardIntake from './client/components/doctors/dashboard/intake.jsx';
import DoctorDashboardSettings from './client/components/doctors/dashboard/settings';
import DoctorDashboardProfile from './client/components/doctors/dashboard/profile-edit'
import DoctorDashboardAppointment from './client/components/doctors/dashboard/appointment'
import DoctorDashboardPlan from './client/components/doctors/dashboard/plan-billing'
import PrototypeCalendar from './client/components/doctors/dashboard/prototype-calendar'
import Payment from './client/components/doctors/dashboard/payment'
import PaymentConfirmed from './client/components/doctors/dashboard/payment-confirmed.jsx';

import DoctorRegistrationV3 from './client/components/doctors/register/registerv2/doctor-register-v2.jsx';
import DoctorLogin from './client/components/doctors/login/login';
import NewPassword from './client/components/doctors/login/new-password.jsx';
import ResetPasswordSuccess from './client/components/doctors/login/reset-password-success.jsx';

import CreateHospitalV2 from './client/components/doctors/create-hospital/create-hospital-v2.jsx'

import AppointmentRetrieval from './client/components/patients/appointment/apt-retrieval';
import PatientAppointment from './client/components/patients/appointment/patient-appointment'
import PatientIntake from './client/components/patients/intake/patient-intake.jsx';
import PatientIntakeSuccess from './client/components/patients/intake/patient-intake-success.jsx';

import DoctorLanding from './client/components/doctors/doctor-landing.jsx';
import ContactUs from './client/components/home/contact-us';
import Support from './client/components/home/support.jsx'
import Loading from './client/components/loading';
import Error from './client/components/error';

import { GoogleOAuthProvider } from '@react-oauth/google';

const queryClient = new QueryClient()

const AppRouter = () => {

    return(
        <Provider store = {store}>
            <GoogleOAuthProvider clientId="448905398059-6tlj3n08l0fsllr5bu08kgippi0epntt.apps.googleusercontent.com">
                <QueryClientProvider client = {queryClient}>
                    <BrowserRouter>
                        <Routes>
                            <Route path = "/" element={<Home/>}/>
                            <Route path = "/search" element={<Search/>}/>
                            <Route path = "/register" element={<Register/>}/>
                            
                            
                            <Route path="/profile/:hospitalID" element={<DoctorProfile/>}/>
                            <Route path="/booking" element={<PatientBookingV2_1/>}/>
                            <Route path="/booking-success" element={<BookingSuccess/>}/>
                            
                            <Route path="/doctor-registrationv3" element={<DoctorRegistrationV3/>}/>
                            <Route path="/welcome" element={<DoctorLanding/>}/>
                            <Route path="/create-hospital" element={<CreateHospitalV2/>}/>

                            <Route path="/summary" element={<DoctorDashboardSummary/>}/>
                            <Route path="/calendar" element={<DoctorDashboardCalendar/>}/>
                            <Route path="/appointment-manager" element={<DoctorDashboardAppointments/>}/>
                            <Route path="/intake" element={<DoctorDashBoardIntake/>}/>
                            <Route path="/profile-edit" element={<DoctorDashboardProfile/>}/>
                            <Route path="/settings" element={<DoctorDashboardSettings/>}/>
                            <Route path="/appointment" element={<DoctorDashboardAppointment/>}/>
                            <Route path="/plan" element={<DoctorDashboardPlan/>}/>
                            <Route path="/payment" element={<Payment/>}/>
                            <Route path="/payment-success" element={<PaymentConfirmed/>}/>
                            <Route path="/pc" element={<PrototypeCalendar/>}/>
                            <Route path="/doctor-login" element={<DoctorLogin/>}/> 
                            <Route path="/reset-password/:token" element={<NewPassword/>}/>
                            <Route path="/reset-password/success/:token" element={<ResetPasswordSuccess/>}/>
                            

                            <Route path="/appointment-search" element={<AppointmentRetrieval/>}/>
                            <Route path="/patient-appointment" element={<PatientAppointment/>}/>
                            <Route path="/patient-intake" element={<PatientIntake/>}/>
                            <Route path="/patient-intake/success" element={<PatientIntakeSuccess/>}/>

                            <Route path = "/contactus" element={<ContactUs/>}/>
                            <Route path = "/support/:tabname" element={<Support/>}/>

                            <Route path="/loading" element={<Loading/>}/>
                            <Route path='*' exact={true} element={<Error/>}/>
                            
                        </Routes>
                    </BrowserRouter>
                </QueryClientProvider>
            </GoogleOAuthProvider>
        </Provider>
    );
    
}


export default AppRouter;