import React, { useEffect, useState } from 'react';

const DownloadButton = ({onDownload}) => {

    const [isDownloading, setIsDownloading] = useState(false)
    const [downloadProgress, setDownloadProgress] = useState(0.0)
    const handleDownload = () => {
        setIsDownloading(true)
        onDownload(setDownloadProgress)
    }

    useEffect(() => {
        console.log(downloadProgress)
        if(downloadProgress === 1.0){
            setIsDownloading(false)
            setDownloadProgress(0.0)
        }
    }, [downloadProgress])
    if(isDownloading) {
        return(
            <>
                <h1>Downloading...</h1>
                <div className='download-progress-container'>
                    <div className='download-progress' style={{width: `${downloadProgress * 100}%` }}>
                    </div>
                </div>
            </>
            
        )
        
    } else {
        return(
            <>
                <h1>Document ready</h1>
                <button onClick={() => handleDownload()} className="intake-aesthetic-link">Download</button>
            </>
            
        )
    }
}

export default DownloadButton;