import React, { useState } from 'react';
import './calendar.css'
import { dayMapping, isToday, sampleAppointmentDictionary, formatDateMMDDYYYY } from './helper-functions';
import { isDateBeforeToday, isInBetween } from '../../../../assets/functions/functions';




const DateSlot = ({ date, appointmentDataDictionary, weekIndex, setPopupOpen, setPopupModalData, schedulePreviewConfig }) => {

    const dateKey = formatDateMMDDYYYY(date)
    const numAppointments = appointmentDataDictionary[dateKey] ? appointmentDataDictionary[dateKey].length : 0
    const [showingMore, setShowingMore] = useState(false)
    const dayCorrectionMapping = {
        "0": 1,
        "1": 2,
        "2": 3,
        "3": 4,
        "4": 5,
        "5": 6,
        "6": 0,
    }
    const dayNumberToNames = {
        0: "sunday",
        1:"monday",
        2: "tuesday",
        3: "wednesday",
        4: "thursday",
        5: "friday",
        6: "saturday",

    }

    var dateStyle = "calendar-todayDate"
    var dateContainerStyle = "date-slot-container"
    if (isToday(date)) {
        dateStyle = "calendar-todayDate"
        dateContainerStyle = "date-slot-container date-slot-container-today"
    } else if (isDateBeforeToday(date)) {
        dateStyle = "date-slot-container-beforeToday"
    } else {
        dateStyle = "date-slot-container-notToday"
    }
    if (showingMore) {
        dateContainerStyle += " date-slot-container-showing-more"
    }

    const handleRenderScheduleConfig = () => {
        const configDateKey = formatDateMMDDYYYY(schedulePreviewConfig.date)
        if (schedulePreviewConfig.frequency === "Once") {
            if (configDateKey === dateKey) {
                return (
                    <div className={dateContainerStyle}>
                        <h2 className={dateStyle}>{date.getDate()}</h2>
                        {RenderSampleSlot(schedulePreviewConfig.time)}
                    </div>
                )
            } else {
                return (
                    <div className={dateContainerStyle}>
                        <h2 className={dateStyle}>{date.getDate()}</h2>
                    </div>
                )
            }
        } else if (schedulePreviewConfig.frequency === "Daily") {
            if(isInBetween(schedulePreviewConfig.startDate, schedulePreviewConfig.endDate, date) && schedulePreviewConfig.daysOfWeeks[dayNumberToNames[date.getDay()]]){
                return (
                    <div className={dateContainerStyle}>
                        <h2 className={dateStyle}>{date.getDate()}</h2>
                        {schedulePreviewConfig.time.map(time => RenderSampleSlot(time))}
                    </div>
                )
            } else {
                return (
                    <div className={dateContainerStyle}>
                        <h2 className={dateStyle}>{date.getDate()}</h2>
                    </div>
                )
            }
            
        } else if (schedulePreviewConfig.frequency === "Weekly") {
            if (dayCorrectionMapping[schedulePreviewConfig.day] === date.getDay() && isInBetween(schedulePreviewConfig.startDate, schedulePreviewConfig.endDate, date)) {
                return (
                    <div className={dateContainerStyle}>
                        <h2 className={dateStyle}>{date.getDate()}</h2>
                        {schedulePreviewConfig.time.map(time => RenderSampleSlot(time))}
                    </div>
                )
            } else {
                return (
                    <div className={dateContainerStyle}>
                        <h2 className={dateStyle}>{date.getDate()}</h2>
                    </div>
                )
            }
        }
    }

    const RenderAppointmentSlot = (appointmentData, setPopupOpen) => {
        const appointmentDate = new Date(parseInt(appointmentData.schedule_time * 1000))
        var buttonBackgroundColor = "pending"
        if (appointmentData.status === "PENDING") {
            buttonBackgroundColor = "pending"
        } else if (appointmentData.status === "CONFIRMED") {
            buttonBackgroundColor = "confirmed"
        } else if (appointmentData.status === "COMPLETED") {
            buttonBackgroundColor = "completed"
        } else if (appointmentData.status === "DECLINED") {
            buttonBackgroundColor = "declined"
        }

        return (
            <button className={`appointment-slot-btn ${buttonBackgroundColor}`} onClick={() => { setPopupOpen(true); setPopupModalData(appointmentData) }}>
                {appointmentDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })} {appointmentData.patient_info.patient_name_first} {appointmentData.patient_info.patient_name_last}
            </button>
        )
    }

    const RenderSampleSlot = (time) => {
        return (
            <button className={`appointment-slot-btn pending`}>
                {time} Open Slot
            </button>
        )
    }

    if (schedulePreviewConfig) {
        return(
            handleRenderScheduleConfig()
        )
        
    } else {
        return (
            <div className={dateContainerStyle}>
                <h2 className={dateStyle}>{date.getDate()}</h2>
                {numAppointments > 0 && !showingMore && appointmentDataDictionary[dateKey].slice(0, 4).map(appointmentSlot => RenderAppointmentSlot(appointmentSlot, setPopupOpen))}
                {numAppointments > 0 && showingMore && appointmentDataDictionary[dateKey].map(appointmentSlot => RenderAppointmentSlot(appointmentSlot, setPopupOpen))}
                {numAppointments > 4 && !showingMore && <div className='date-slot-container-show-more' onClick={() => setShowingMore(true)}><u>+ {numAppointments - 4} more</u></div>}
                {numAppointments > 4 && showingMore && <div className='date-slot-container-show-more' onClick={() => setShowingMore(false)}><u>show less</u></div>}
            </div>
        )
    }

};

export default DateSlot;