import React, { useEffect } from 'react';
import AchewHeader from './achew-header';
import AchewFooter from './achew-footer';
import { useNavigate } from 'react-router-dom';
const Error = (props) => {
    const navigate = useNavigate()
    return (
        <div className="error-page">
            <AchewHeader/>
            <div className='error-page-body'>
                <h1>404</h1>
                <h2>We couldn't find the page you were looking for :( </h2>
                <button onClick={() => navigate('/')}>Return to homepage</button>
            </div>
            
        </div>
    );
};

export default Error;
