import React, { useState, useEffect, useRef } from 'react';
import { acceptAppointment, modifyAppointment, declineAppointment, addEvent, getHospitalInfoByExternalID } from '../../../../../api/patientAPIs';
import reasons_to_cancel from '../../../../../sampledata/reasons_to_cancel.json'
import { chevron_down } from '../../../home/image';
import specialtiesData from '../../../../../sampledata/specialties.json'
import LoadingSpinner from '../../../patients/bookingv2/spinner';

const AddDoctorModal = ({ onRequestClose, currentHospitalData, addDoctor }) => {

    const [isSpecialtyDropdownOpen, setSpecialtyDropdownOpen] = useState(false);
    const specialtyDropdownRef = useRef(null);
    const [specialty, setSpecialty] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showValidationError, setShowValidationError] = useState(false)
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (specialtyDropdownRef.current && !specialtyDropdownRef.current.contains(event.target)) {
                setSpecialtyDropdownOpen(false);
            }
        }
        window.addEventListener('click', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleAddDoctor = (firstName, lastName, specialty, setIsSubmitting) => {
        if(
            !firstName || firstName.length === 0 ||
            !lastName || lastName.length === 0 ||
            !specialty || !specialty.length === 0
          ) {
            setShowValidationError(true)
          } else {
            setShowValidationError(false)
            addDoctor(firstName, lastName, specialty, setIsSubmitting)
          }
    }
    
    return (
        <div className="confirmation-modal-overlay">
            <div className="confirmation-modal">
                <h2 className="confirmation-modal-title">Add a new doctor</h2>
                <p className="confirmation-modal-description">New doctors are hidden by default until set to visible. Please remember to create a schedule for this doctor.</p>
                <span className="d-flex flex-col justify-content-between">
                    <div className="email-input-wrapper">
                        <h3 className="booking-email-label">Legal first name</h3>
                        <input
                            className="name-input"
                            name="first"
                            placeholder="John"
                            value={firstName}
                            onChange={(c) => setFirstName(c.currentTarget.value)}
                        />
                    </div>

                    <div className="email-input-wrapper">
                        <h3 className="booking-email-label">Legal last name</h3>
                        <input
                            className="name-input2"
                            name="last"
                            placeholder="Smith"
                            value={lastName}
                            onChange={(c) => setLastName(c.currentTarget.value)}
                        />
                    </div>
                </span>
                <div
                className="booker-reason-input-container"
                ref={specialtyDropdownRef}
                style={{ marginBottom: '20px' }}
              >
                <div className="booker-body-dropdown" onClick={() => setSpecialtyDropdownOpen(!isSpecialtyDropdownOpen)}>
                  <h1>
                    {specialty != null
                      ? specialty
                      : "Choose this provider's specialty"}
                  </h1>
                  <img alt="chevron down" src={chevron_down} />
                </div>
                {isSpecialtyDropdownOpen && (
                  <ul className="booker-dropdown-menu">
                    {specialtiesData.all_specialties.map((option, index) => (
                      <li key={index} onClick={() => { setSpecialty(option); setSpecialtyDropdownOpen(false) }}>
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {showValidationError && <h2 className='achew-validation-message-text'>* Please ensure all fields are filled.</h2>}
                <div className="confirmation-modal-buttons">
                    <button className="confirmation-modal-button cancel" onClick={() => onRequestClose()}>
                        Cancel
                    </button>
                    <button className="confirmation-modal-button accept" onClick={() => handleAddDoctor(firstName, lastName, specialty, setIsSubmitting)}>
                        {isSubmitting ? <LoadingSpinner /> : "Confirm"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddDoctorModal;