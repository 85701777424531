import React, { useState, useEffect, useRef } from "react";
import { chevron_down } from "../../../home/image";
import InsuranceElement from "../../../patients/doctorprofile/insurance-element";
import { getInsuranceById } from "../../../../assets/functions/functions";
import insuranceSampleData from '../../../../../sampledata/insurance.json'
const InsuranceAccordian = ({ insuranceGroup, removeInsurance, isNewInsurances=false, canDeleteInsurance=true }) => {
    // Insurance Information
const insuranceGroupData = getInsuranceById(insuranceGroup[0].insuranceID, insuranceSampleData)

    //Accordian State
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {}, []);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div
      id={insuranceGroupData.id}
      className={`ins-accordian-item ${isExpanded && "selected-item"}`}
      onClick={() => toggleExpand()}
    >
      <div className="ins-accordian-question">
        <h2>{isNewInsurances ? `Recently Added` : insuranceGroupData.name} ({insuranceGroup.length})</h2>
        <i className={isExpanded ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i>
      </div>
      <div
        className={
          isExpanded
            ? "ins-accordian-answer show"
            : "ins-accordian-answer"
        }
      >
        <div className="settings-insurance-row-container">
          <div className="doc-profile-insurances-list">
            {insuranceGroup.map((insurance) => (
              <InsuranceElement
                insuranceID={insurance.insuranceID}
                planID={insurance.planID}
                onDeleteInsurance={() =>
                  removeInsurance(insurance.insuranceID, insurance.planID)
                }
                canDeleteInsurance={canDeleteInsurance}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceAccordian;
