import React, { useEffect } from 'react';
import ReactLoading from "react-loading";
import { achew_logo_side } from './home/image';
import LoadingSpinner from './patients/bookingv2/spinner';

const Loading = (props) => {

    return (
        <div className="loading-page">
            
            <LoadingSpinner/>
            <img alt='Logo' src={achew_logo_side} className='achew-header-logo'/>
            
        </div>
    );
};

export default Loading;
