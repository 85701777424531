import React, { useState, useEffect, useRef } from "react";
import { location, shield_icon } from "./image.jsx";
import insuranceSampleData from "./../../../sampledata/insurance.json";
import { useMediaQuery } from 'react-responsive'

const InsuranceDropdown = ({
  selectedInsurance,
  setSelectedInsurance,
  selectedPlan,
  setSelectedPlan,
  setInsuranceID,
  setPlanID,
  isBottomSheet,
  isInsuranceOpen,
  setInsuranceOpen,
  filterValue,
  setFilterValue
}) => {
  const dropdownRef = useRef(null);
  const [filteredInsuranceData, setFilteredInsuranceData] =
    useState(insuranceSampleData);
  const [validInsuranceChosen, setValidInsuranceChosen] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  useEffect(() => {
    const closeDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setInsuranceOpen(false);
      }
    };

    window.addEventListener('click', closeDropdown);
    return () => {
      window.removeEventListener('click', closeDropdown);
    };
  }, []);

  const handleInsuranceSelect = (insurance) => {
    setSelectedInsurance(insurance);
  };

  const handlePlanSelect = (plan) => {
    
    setSelectedPlan(plan.title);
    setInsuranceID(selectedInsurance.id);
    setPlanID(plan.id);
    setInsuranceOpen(false);
    setFilterValue(selectedInsurance.name + " - " + plan.title);
    setValidInsuranceChosen(true);
    console.log("selected plan: ", plan, selectedInsurance)
  };

  const filterProvidersByNamePrefix = (data, prefix) => {
    // Filter popular providers by name
    const filteredPopular = data.popular.filter((provider) =>
      provider.name.toLowerCase().startsWith(prefix.toLowerCase())
    );

    // Filter all providers by name
    const filteredAll = data.all.filter((provider) =>
      provider.name.toLowerCase().startsWith(prefix.toLowerCase())
    );

    // Return the filtered results
    return {
      popular: filteredPopular,
      all: filteredAll,
    };
  };

  const handleFilterChanged = (event) => {
    event.preventDefault();
    setValidInsuranceChosen(false);
    const newValue = event.currentTarget.value;
    setFilterValue(newValue);
    setFilteredInsuranceData(
      filterProvidersByNamePrefix(insuranceSampleData, newValue)
    );
  };
  const handleBackToInsurance = () => {
    setFilterValue("");
    setSelectedPlan(null);
    setSelectedInsurance(null);
    setInsuranceID("");
    setPlanID("");
    setValidInsuranceChosen(false);
    setFilteredInsuranceData(insuranceSampleData);
  };

  const renderScreen = () => {
    if (!selectedInsurance) {
      return (
        <ul className={`insurance-dropdown-content ${isBottomSheet ? "bottomsheet" : ""}`}>
          {/* <button
            className="insurance-dropdown-header"
            onClick={() => handleCloseDropdown()}
          >
            <i class="fas fa-times"></i>
            <p className="insurance-dropdown-section-label">Close</p>
          </button> */}
          <p className="insurance-dropdown-section-label">Popular</p>
          {filteredInsuranceData.popular.map((option, index) => (
            <li key={index} onClick={() => handleInsuranceSelect(option)}>
              {option.name}
            </li>
          ))}

          <p className="insurance-dropdown-section-label">All insurances</p>
          {filteredInsuranceData.all.map((option, index) => (
            <li key={index} onClick={() => handleInsuranceSelect(option)}>
              {option.name}
            </li>
          ))}
        </ul>
      );
    } else {
      return (
        <ul className={`insurance-dropdown-content ${isBottomSheet ? "bottomsheet" : ""}`}>
          <div
            className="insurance-dropdown-header"
            onClick={() => handleBackToInsurance()}
          >
            <i class="fas fa-arrow-left"></i>
            <p className="insurance-dropdown-section-label">
              Back to Insurance Carriers
            </p>
          </div>
          <p className="insurance-dropdown-section-label">
            {selectedInsurance.name}
          </p>
          {selectedInsurance.plans.map((plan, index) => (
            <li key={index} onClick={() => handlePlanSelect(plan)}>
              {plan.title}
            </li>
          ))}
        </ul>
      );
    }
  };

  const handleSetInputOpen = () => {
    if(!isInsuranceOpen){
      setInsuranceID("")
      setPlanID("")
      setFilterValue("");
      setSelectedPlan(null);
      setSelectedInsurance(null);
      setValidInsuranceChosen(false);
    }
    setInsuranceOpen(!isInsuranceOpen)
  }
  
  if(isBottomSheet && isInsuranceOpen) {
    return renderScreen()
  }

  return (
    <div
      className={isTabletOrMobile ? "home-search-bar-input-container-tablet home-search-bar-input-container-last" : "home-search-bar-input-container home-search-bar-input-container-last"}
    >
      <div className="insurance-picker-search-container2">
        <div className={isTabletOrMobile ? "home-search-bar-img-container-tablet" : "home-search-bar-img-container"}>
          <img alt="Shield" src={shield_icon} />
        </div>
        <input
          type="text"
          placeholder="Insurance"
          onClick={() => handleSetInputOpen()}
          value={filterValue}
          onChange={handleFilterChanged}
        />
      </div>

      {isInsuranceOpen && renderScreen()}
    </div>
  );
};

export default InsuranceDropdown;
